import { SiteGlobalDataApiKey } from '@/apis';
import { biLogger, guidUtils } from '@/util';
import * as stateManagement from '@/stateManagement';
import {
  aiPageWriterUndoRedoSuccessBackToPreviousContentVersion,
  aiTextGeneratorErrorEvent,
} from '@wix/bi-logger-editor/v2';
import {
  AI_PAGE_WRITER_CONTEXT,
  ERROR_STATE_PANEL_PATH,
  PANEL_TYPE,
} from './constants';
import { getBusinessData } from './utils/general';

import type { AiWriterPanelScope } from './aiWriterPanelScope';
import type {
  AiWriterPanelStateProps,
  AiWriterPanelDispatchProps,
  QueryOutput,
} from './aiWriterPanelTypes';

export const mapStateToProps = (
  scope: AiWriterPanelScope,
): AiWriterPanelStateProps => {
  const pageId = scope.editorAPI.dsRead.pages.getFocusedPageId();
  const pageData = scope.editorAPI.pages.data.get(pageId);
  const isIrrelevantPage = !!(
    pageData.managingAppDefId || pageData.tpaApplicationId
  );
  const origin = scope.store.getOrigin();
  const isPreInjection =
    scope.editorAPI.history.getUndoLastSnapshotLabel() !==
    AI_PAGE_WRITER_CONTEXT;

  return {
    defaultFormState: {
      ...getBusinessData(scope),
    },
    origin,
    isIrrelevantPage,
    isPreInjection,
  };
};

export const mapDispatchToProps = (
  scope: AiWriterPanelScope,
): AiWriterPanelDispatchProps => {
  const pageId = scope.editorAPI.pages.getCurrentPageId();

  return {
    onClick: (
      businessDescription: string,
      toneOfVoiceValue?: string | null,
    ) => {
      const guid = guidUtils.getGUID();
      const { businessType, businessName } = getBusinessData(scope);
      const toneOfVoice = toneOfVoiceValue ? toneOfVoiceValue : '';

      scope.editorAPI.store.dispatch(
        stateManagement.ai.actions.setIsAiTermsAndConditionsShown(true),
      );

      return new Promise((res, rej) => {
        //@ts-expect-error
        scope.editorAPI.ai.content.updatePageContent(
          pageId,
          businessType,
          businessName,
          businessDescription,
          (...queryOutput: QueryOutput[]) => {
            if (queryOutput[0].originalError) {
              const error: object = queryOutput[0].originalError;
              rej({ error, queryOutput });
            }
            res({
              queryOutput: queryOutput[0].suggestionResults,
              guid,
            });
            scope.editorAPI.history.add(AI_PAGE_WRITER_CONTEXT, {
              component_id: guid,
            });
          },
          (error: any, queryOutput: QueryOutput[]): void => {
            rej({ error, queryOutput });
          },
          {},
          toneOfVoice,
        );
      });
    },
    onUndo: async () => {
      await scope.editorAPI.history.performUndoUntilLabel(
        AI_PAGE_WRITER_CONTEXT,
        true,
      );
    },
    sendUndoRedoBI: (originParams: any, targetParams: any) => {
      const isOriginAiWritten = originParams?.label === AI_PAGE_WRITER_CONTEXT;
      const isTargetAiWritten = targetParams?.label === AI_PAGE_WRITER_CONTEXT;

      if (!isOriginAiWritten && !isTargetAiWritten) return;

      const targetGuid = targetParams?.component_id;
      const originGuid = originParams?.component_id;

      if (originGuid === targetGuid || (!originGuid && !targetGuid)) return;

      biLogger.report(
        aiPageWriterUndoRedoSuccessBackToPreviousContentVersion({
          output_id_origin: isOriginAiWritten
            ? originGuid
            : 'pre-injection state',
          output_id_target: isTargetAiWritten
            ? targetGuid
            : 'pre-injection state',
        }),
      );
    },
    onLoading: (isLoading: boolean) => {
      if (isLoading) {
        scope.editorAPI.store.dispatch(
          stateManagement.stageLoader.actions.startStageLoading(),
        );
      } else {
        scope.editorAPI.store.dispatch(
          stateManagement.stageLoader.actions.endStageLoading(),
        );
      }
    },
    onWrongPageClick: () => {
      scope.editorAPI.setForceOpenPagesQuickNavigationEventCounter();
    },
    showFailStateModal: (
      { tokens, completionText, sessionId, ...query }: AnyFixMe,
      errorMessage: string,
    ) => {
      const siteGlobalDataApi =
        scope.editorAPI.host.getAPI(SiteGlobalDataApiKey);
      const businessType =
        siteGlobalDataApi.getBusinessType()?.displayName ?? '';
      const businessName = siteGlobalDataApi.getBusinessName() ?? '';

      biLogger.report(
        aiTextGeneratorErrorEvent({
          query: JSON.stringify({
            ...query,
            businessType,
            businessName,
          }),
          query_output: JSON.stringify({
            completionText,
          }),
          panelType: PANEL_TYPE,
          errorMessage,
          tokens,
          sessionId,
        }),
      );

      scope.editorAPI.panelManager.openPanel(
        ERROR_STATE_PANEL_PATH,
        {
          closePanel: () =>
            scope.editorAPI.panelManager.closePanelByName(
              ERROR_STATE_PANEL_PATH,
            ),
        },
        true,
      );
    },
    businessData: getBusinessData(scope),
  };
};
