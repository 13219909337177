import type { EditorAPI } from '@/editorAPI';
import type { FlattenedObject } from './types';
import constants from '@/constants';

import { HttpClient } from '@wix/http-client';
import { recommendTemplateInjectionImage } from '@wix/ambassador-genie-v1-image-recommendation/http';

import type { CompRef, CompData } from '@wix/document-services-types';

export const flattenObject = (obj: any): FlattenedObject => {
  const flattened: FlattenedObject = {};

  const flattenRecursive = (cur: any, prop = ''): void => {
    if (typeof cur === 'object' && cur !== null) {
      Object.entries(cur).forEach(([key, value]) => {
        flattenRecursive(value, key);
      });
    } else {
      flattened[prop] = cur;
    }
  };

  flattenRecursive(obj);

  return flattened;
};

interface ImageData {
  ref: CompRef;
  data?: CompData;
  parentData?: CompData;
  newUrl?: string;
}

const MAX_IMAGE_WIDTH = 200;
const MAX_IMAGE_HEIGHT = 200;

const composeImageUrl = (uri: string): string =>
  uri.includes('http') ? uri : `https://static.wixstatic.com/media/${uri}`;

const getImageToReplace = async (
  httpClient: HttpClient,
  image: ImageData,
): Promise<ImageData> => {
  const { data, parentData } = image;
  const result = await httpClient.request(
    recommendTemplateInjectionImage({
      imageUrl: composeImageUrl(data.uri),
      imageProperties: {
        width: data.width.toString(),
        height: data.height.toString(),
        sectionName: parentData.sectionName,
        description: data.title,
        alt: data.alt,
      },
    }),
  );

  return {
    ...image,
    newUrl: result.data.image.url,
  };
};

const isImageReplacable = (image: { data: Record<string, any> }): boolean =>
  image.data.width >= MAX_IMAGE_WIDTH && image.data.height >= MAX_IMAGE_HEIGHT;

const populateImagesWithParentData = (
  editorAPI: EditorAPI,
  images: ImageData[],
): ImageData[] =>
  images.map((image) => {
    const parentSection = editorAPI.sections.getClosestSection(image.ref);
    const sectionName = editorAPI.sections.getName(parentSection);

    return {
      ...image,
      parentData: {
        sectionName,
      },
    };
  });

export const replaceImagesWithFunnelSuggestions = async (
  editorAPI: EditorAPI,
): Promise<void> => {
  const imageToReplace: ImageData[] = editorAPI.components.get
    .byType(constants.COMP_TYPES.PHOTO)
    .map((imageRef) => {
      const data = editorAPI.components.data.get(imageRef);
      return {
        ref: imageRef,
        data,
      };
    })
    .filter(isImageReplacable);

  if (imageToReplace.length === 0) return;

  const imagesWithParentData: ImageData[] = populateImagesWithParentData(
    editorAPI,
    imageToReplace,
  );

  const httpClient = new HttpClient({
    getAppToken: () =>
      editorAPI.documentServices.platform.getAppDataByApplicationId('-666')
        ?.instance,
  });

  const populatedImagesData = await Promise.all(
    imagesWithParentData.map((image) => getImageToReplace(httpClient, image)),
  );

  populatedImagesData.forEach(({ ref, newUrl }) => {
    editorAPI.components.data.update(ref, {
      uri: newUrl,
    });
  });

  return;
};
