import React from 'react';
import * as platform from '@/platform';
import {
  Rename,
  Delete,
  Settings,
  Copy,
  Duplicate,
  Hide,
  Seo,
  SocialShare,
  Background,
  Data,
  PageBackgroundData,
  UrlLink,
  Home,
  PageDynamic,
  Show,
  Child,
  Parent,
  PageAdd,
  ExternalLink,
  Users,
  Globe,
  HelpCircle,
  UrlUnlink,
  Edit,
  Router,
} from '@wix/wix-ui-icons-common/classic-editor';

const getActionSymbolByName = (actionName: string) => {
  interface SymbolObject {
    symbol: React.FC;
    symbolName: string;
  }

  const symbols: Record<string, SymbolObject> = {
    connectToData_action: {
      symbol: Data,
      symbolName: 'pageData',
    },
    connectToBackgroundData_action: {
      symbol: PageBackgroundData,
      symbolName: 'pageBackgroundData',
    },
    rename_action: {
      symbol: Rename,
      symbolName: 'renameAction',
    },
    background_action: {
      symbol: Background,
      symbolName: 'pageBackground',
    },
    hide_action: {
      symbol: Hide,
      symbolName: 'hideAction',
    },
    show_action: {
      symbol: Show,
      symbolName: 'unhideAction',
    },
    subpage_action: {
      symbol: Child,
      symbolName: 'subpageAction',
    },
    main_page_action: {
      symbol: Parent,
      symbolName: 'unsubpageAction',
    },
    duplicate_action: {
      symbol: Duplicate,
      symbolName: 'duplicateAction',
    },
    set_as_homepage_action: {
      symbol: Home,
      symbolName: 'home-page-icon',
    },
    copy_action: {
      symbol: Copy,
      symbolName: 'copyPaste',
    },
    delete_action: {
      symbol: Delete,
      symbolName: 'deleteAction',
    },
    change_link_action: {
      symbol: UrlLink,
      symbolName: 'linkAction',
    },
    convert_to_dynamic_page_action: {
      symbol: PageDynamic,
      symbolName: 'dynamicPageType',
    },
    seo_action: {
      symbol: Seo,
      symbolName: 'seoSettingsAction',
    },
    social_share_action: {
      symbol: SocialShare,
      symbolName: 'seoSocialShareAction',
    },
    settings_action: {
      symbol: Settings,
      symbolName: 'settingsAction',
    },
    mobile_hide_action: {
      symbol: Hide,
      symbolName: 'quickActionsHide',
    },
    mobile_show_action: {
      symbol: Show,
      symbolName: 'quickActionsShow',
    },
    learn_more_action: {
      symbol: HelpCircle,
      symbolName: 'appManager_helpAction',
    },
    delete_app_action: {
      symbol: Delete,
      symbolName: 'appManager_removeAction',
    },
  };

  return {
    symbol: symbols[actionName]?.symbol,
    symbolName: symbols[actionName]?.symbolName,
  };
};

const getActionSymbolByType = (action: any) => {
  const symbols: Record<string, React.FC> = {
    [platform.constants.pages.page_settings.pageInfo.type]: Settings,
    [platform.constants.pages.page_settings.seo.type]: Seo,
    [platform.constants.pages.page_actions.copy.type]: Copy,
    [platform.constants.pages.page_actions.delete.type]: Delete,
    [platform.constants.pages.page_actions.duplicate.type]: Duplicate,
    [platform.constants.pages.page_actions.rename.type]: Rename,
    [platform.constants.RENAME_TYPE]: Rename,
    [platform.constants.SET_AS_HOMEPAGE_TYPE]: Home,
  };

  return {
    symbol:
      symbols[action.type] ??
      getCustomSymbolAction(action.icon).symbol ??
      Settings,
    symbolName: action.icon,
  };
};

const getCustomSymbolAction = (iconName: string) => {
  const symbols: Record<string, React.FC> = {
    delete: Delete,
    delete_icon: Delete,
    deleteRadio: Delete,
    deleteAction: Delete,
    settingsAction: Settings,
    renameAction: Rename,
    addPagePagesPanel: PageAdd,
    goToURLAction: ExternalLink,
    unhideAction: Show,
    membersAction: Users,
    globe: Globe,
    page_dynamic_icon: PageDynamic,
    seoSettingsAction: Seo,
    add_new_item: PageAdd,
    duplicateAction: Duplicate,
    unlink_icon: UrlUnlink,
    edit_icon: Edit,
    router_icon: Router,
    'home-page-icon': Home,
  };

  return { symbol: symbols[iconName] ?? Settings, symbolName: iconName };
};

export { getActionSymbolByName, getActionSymbolByType, getCustomSymbolAction };
