import React from 'react';
import { Button } from '@wix/wix-base-ui';
import type { IMenuItem } from '@/baseUI';
import type { MenuItem as DSMenuItem } from 'types/documentServices';
import { translate } from '@/i18n';
import { TRANSLATIONS_MAP } from './translations';

export const SLOT_PREFIX = 'SLOT';

export const createSlotItem = (
  parentItem: DSMenuItem,
  edit?: (item: DSMenuItem) => void,
): IMenuItem => ({
  type: 'BasicMenuItem',
  id: `${parentItem.id}__${SLOT_PREFIX}`,
  label: translate(TRANSLATIONS_MAP.ITEM.CONTAINER.label),
  isVisible: true,
  isVisibleMobile: false,
  symbol: 'containerPageType',
  customButton: (
    <Button
      className="btn-confirm-secondary btn-sm"
      onClick={() => edit?.(parentItem)}
    >
      {translate(TRANSLATIONS_MAP.ITEM.CONTAINER.edit)}
    </Button>
  ),
  items: [],
  blockDragAndDrop: true,
});

export const removeSlotItem = (item: DSMenuItem) =>
  item.slot ? { ...item, items: [] } : item;

export const isSlotItem = (item: DSMenuItem) => item.id.endsWith(SLOT_PREFIX);

export const isSlotContainer = (item: Partial<DSMenuItem>) => !!item.slot;
