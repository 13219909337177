import * as stateManagement from '@/stateManagement';

import type { MapStateToProps, MapDispatchToProps } from 'types/redux';
import type { EditorAPI } from '@/editorAPI';
import type { BiEventDefinition, BiEventFields } from 'types/bi';

export interface PublishPanelDispatchProps {
  openPanel: (panelName: string) => void;
  sendBi: (event: BiEventDefinition, parameters: BiEventFields) => void;
}

const mapStateToProps: MapStateToProps = ({ editorAPI }) => ({
  canUserPublish: editorAPI.savePublish.canUserPublish(),
  isSitePublished: editorAPI?.dsRead.generalInfo.isSitePublished() || false,
  sitePublicUrl: editorAPI.site.getSitePublicUrl() || '',
});

const getEditorAPI = (
  dispatch: AnyFixMe,
  getState: AnyFixMe,
  { editorAPI }: AnyFixMe,
): EditorAPI => editorAPI;

export const mapDispatchToProps: MapDispatchToProps<
  PublishPanelDispatchProps,
  unknown
> = (dispatch) => {
  const editorAPI = dispatch(getEditorAPI);

  return {
    openPanel: (panelName: string) => {
      dispatch(stateManagement.panels.actions.updateOrOpenPanel(panelName));
    },
    sendBi: editorAPI.bi.event,
  };
};

export { mapStateToProps };
