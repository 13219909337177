import _ from 'lodash';
import constants from '@/constants';
import * as topBarData from './topBarData';
import * as util from '@/util';
import keyboardShortcuts from '@/keyboardShortcuts';

const {
  EDIT_MODE_LAST_BUTTON_BUBBLE_STYLE_OVERRIDES,
  BACK_TO_ADI_BUBBLE_STYLE_OVERRIDE,
} = topBarData;
const AUTOSAVE_ON_OFF_SHORTCUT_LABEL =
  keyboardShortcuts.topBar.AUTOSAVE_ON_OFF.label;

const getCustomDropPanels = (props: AnyFixMe) => [
  {
    key: constants.ROOT_COMPS.TOPBAR.DROP_PANELS.CUSTOM
      .MOBILE_OPTIMIZE_LAYOUT_UNDO,
    targetButtonKey: constants.ROOT_COMPS.TOPBAR.BUTTONS.UNDO,
    dropPanelData: {
      title: 'TOPBAR_UNDO_TOOLTIP_TITLE',
      body: 'TOPBAR_MOBILE_TOOLTIP_UNDO_BODY',
      linkText: null as AnyFixMe,
      linkAction: null as AnyFixMe,
    },
  },
  {
    key: constants.ROOT_COMPS.TOPBAR.DROP_PANELS.CUSTOM.AUTO_SAVE,
    targetButtonKey: constants.ROOT_COMPS.TOPBAR.BUTTONS.SAVE,
    closeOnMouseLeave: true,
    dropPanelData: {
      title: 'TOPBAR_SITE_AUTOSAVE_TOOLTIP_TITLE',
      body: 'TOPBAR_SITE_AUTOSAVE_TOOLTIP_BODY_TEXT',
      keyText: AUTOSAVE_ON_OFF_SHORTCUT_LABEL,
      linkText: 'TOPBAR_SITE_AUTOSAVE_TOOLTIP_SITE_HISTORY',
      linkAction: props.openRevisions,
    },
    bubbleStyleOverrides: null as AnyFixMe,
  },
  {
    key: constants.ROOT_COMPS.TOPBAR.DROP_PANELS.CUSTOM.BACK_TO_ADI,
    targetButtonKey: constants.ROOT_COMPS.TOPBAR.DROP_PANELS.SITE,
    closeOnMouseLeave: true,
    dropPanelData: {
      title: 'TOPBAR_SITE_BACK_ADI_TOOLTIP_TITLE',
      body: 'TOPBAR_SITE_BACK_ADI_TOOLTIP_BODY',
      linkText: null as AnyFixMe,
      linkAction: null as AnyFixMe,
    },
    bubbleStyleOverrides: BACK_TO_ADI_BUBBLE_STYLE_OVERRIDE,
  },
  {
    key: constants.ROOT_COMPS.TOPBAR.DROP_PANELS.CUSTOM.BACK_TO_CHAT,
    targetButtonKey: constants.ROOT_COMPS.TOPBAR.DROP_PANELS.HELP,
    closeOnMouseLeave: true,
    dropPanelData: {
      title: 'Topbar_LiveChat_Tooltip_Title',
      body: 'Topbar_LiveChat_Tooltip_Text',
      linkText: null as AnyFixMe,
      linkAction: null as AnyFixMe,
    },
  },
  {
    key: constants.ROOT_COMPS.TOPBAR.DROP_PANELS.CUSTOM.PUBLISH_RC,
    targetButtonKey: constants.ROOT_COMPS.TOPBAR.DROP_PANELS.PUBLISH_RC,
    closeOnMouseLeave: false,
    dynamicPanelPath: 'topBar.dropPanels.publishRC',
    bubbleStyleOverrides: EDIT_MODE_LAST_BUTTON_BUBBLE_STYLE_OVERRIDES,
  },
  {
    key: constants.ROOT_COMPS.TOPBAR.DROP_PANELS.CUSTOM.DISABLE_AUTO_SAVE,
    targetButtonKey: constants.ROOT_COMPS.TOPBAR.BUTTONS.SAVE,
    closeOnMouseLeave: true,
    dropPanelData: {
      title: 'Topbar_Site_autosave_off_tooltip_title',
      body: 'Topbar_Site_autosave_off_tooltip_text',
      keyText: AUTOSAVE_ON_OFF_SHORTCUT_LABEL,
      linkText: null as AnyFixMe,
      linkAction: null as AnyFixMe,
    },
    bubbleStyleOverrides: null as AnyFixMe,
  },
  {
    key: constants.ROOT_COMPS.TOPBAR.DROP_PANELS.CUSTOM.ENABLE_AUTO_SAVE,
    targetButtonKey: constants.ROOT_COMPS.TOPBAR.BUTTONS.SAVE,
    closeOnMouseLeave: true,
    dropPanelData: {
      title: 'Topbar_Site_autosave_on_tooltip_title',
      body: 'Topbar_Site_autosave_on_tooltip_text',
      keyText: AUTOSAVE_ON_OFF_SHORTCUT_LABEL,
      linkText: null as AnyFixMe,
      linkAction: null as AnyFixMe,
    },
    bubbleStyleOverrides: null as AnyFixMe,
  },
];

const mapTopBarPropsToCustomDropPanels = (props: AnyFixMe) =>
  _(props)
    .thru(getCustomDropPanels)
    .thru((item) =>
      util.overrideUtils.applyOverrides(item, props.overriddenCustomDropPanels),
    )
    .value();

export default mapTopBarPropsToCustomDropPanels;
