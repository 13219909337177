import constants from '@/constants';
import * as helpIds from '@/helpIds';
import gfppDataUtils from '../../utils/gfppDataUtils';
import type { EditorAPI } from '@/editorAPI';
import type { CompRef } from '@wix/document-services-types';

const { ACTIONS } = constants.ROOT_COMPS.GFPP;

const manageLanguagesPanelName =
  'compPanels.panels.LanguageSelector.managePanel';

const manageLanguages = {
  label: 'gfpp_mainaction_LangMenu',
  isSelected: gfppDataUtils.getPanelStateFn(manageLanguagesPanelName),
  onClick(editorAPI: EditorAPI, compRef: CompRef, origin: string) {
    gfppDataUtils.toggleComponentPanel(editorAPI, manageLanguagesPanelName, {
      selectedComponent: compRef,
      origin,
    });
  },
};

const navigate = {
  label: 'gfpp_secondaryaction_LangMenu',
  onClick(editorAPI: AnyFixMe) {
    editorAPI.closeCompPanelIfExists();
    editorAPI.topBarMenuBar.openDropDown(
      constants.ROOT_COMPS.TOPBAR.DROP_PANELS.LANGUAGE_DROP_PANEL,
    );
  },
};

export default {
  untranslatable: true,
  mainActions: [manageLanguages, navigate],
  enabledActions: [
    ACTIONS.LAYOUT,
    ACTIONS.DESIGN,
    ACTIONS.ANIMATION,
    ACTIONS.HELP,
  ],
  mobileMainActions: [
    ACTIONS.SCALE_UP,
    ACTIONS.SCALE_DOWN,
    ACTIONS.LAYOUT,
    ACTIONS.ANIMATION,
  ],
  mobileEnabledActions: [ACTIONS.HIDE, ACTIONS.HELP],
  presetActions: {
    help: {
      helpId: helpIds.GFPP.LANGUAGE_SELECTOR,
      mobileHelpId: helpIds.GFPP.LANGUAGE_SELECTOR_MOBILE,
    },
  },
};
