import constants from '@/constants';

import bgImageStrip from './components/bgImageStrip';
import footer from './components/footer';
import header from './components/header';
import screenWidthContainer from './components/screenWidthContainer';
import stripShowcase from './components/stripShowcase';
import stripSlideshowGallery from './components/stripSlideshowGallery';
import blogComponents from './components/blogComponents';
import appPart2Components from './components/appPart2Components';
import rssButton from './components/rssButton';
import tpaGluedWidget from './components/tpaGluedWidget';
import page from './components/page';
import boxSlideShow from './components/boxSlideShow';
import stripSlideShow from './components/stripSlideShowMetaData';
import boxSlideShowSlide from './components/boxSlideShowSlide';
import containerMetaData from './components/containerMetaData';
import stripSlideShowSlide from './components/stripSlideShowSlide';
import stateBox from './components/stateBox';
import stateStrip from './components/stateStrip';
import stateBoxState from './components/stateBoxState';
import stateBoxFormState from './components/stateBoxFormState';
import stateStripState from './components/stateStripState';
import anchor from './components/anchor';
import collageMetaData from './components/collage';
import wRichText from './components/wRichText';
import group from './components/group';
import stripContainer from './components/stripContainer';
import stripColumnsContainer from './components/stripColumnsContainer';
import documentMediaMetaData from './components/documentMediaMetaData';
import payPalButtonMetaData from './components/payPalButtonMetaData';
import customElementComponentMetaData from './components/customElementComponentMetaData';
import tpaSection from './components/tpaSection';
import columnMetaData from './components/column';
import popupCloseIconButton from './components/popupCloseIconButton';
import popupCloseTextButton from './components/popupCloseTextButton';
import popupContainerMetaData from './components/popupContainerMetaData';
import mediaBox from './components/mediaBoxMetaData';
import mediaControls from './components/mediaControlsContainer';
import mediaOverlayControls from './components/mediaOverlayControls';
import mediaPlayer from './components/mediaPlayer';
import fiveGridLine from './components/fiveGridLineMetaData';
import verticalLine from './components/verticalLineMetaData';
import socialBar from './components/socialBarMetaData';
import htmlComponent from './components/htmlComponentMetaData';
import quickActionBarItem from './components/quickActionBarItem';
import quickActionBar from './components/quickActionBar';
import tinyMenu from './components/tinyMenu';
import repeaterMetaData from './components/repeaterMetaData';
import siteRegionContainerMetaData from './components/siteRegionContainerMetaData';
import radioGroupMetaData from './components/radioGroupMetaData';
import pageGroup from './components/pageGroup';
import pagesContainer from './components/pagesContainer';
import appWidgetMetaData from './components/appWidgetMetaData';
import checkbox from './components/checkbox';
import siteButtonMetaData from './components/siteButtonMetaData';
import textInputMetaData from './components/textInputMetaData';
import textAreaInputMetaData from './components/textAreaInputMetaData';
import videoMetaData from './components/videoMetaData';
import TPA3DGalleryMetaData from './components/TPA3DGalleryMetaData';
import TPA3DCarouselMetaData from './components/TPA3DCarouselMetaData';
import accordionMetaData from './components/accordionMetaData';
import slideShowGalleryMetaData from './components/slideShowGalleryMetaData';
import datePickerMetaData from './components/datePickerMetaData';
import fileUploaderMetaData from './components/fileUploaderMetaData';
import freeStyleMetaData from './components/freeStyleMetaData';
import gridMetaData from './components/gridMetaData';
import honeyCombMetaData from './components/honeyCombMetaData';
import imageMetaData from './components/imageMetaData';
import imageButtonMetaData from './components/imageButtonMetaData';
import legacyImageButtonMetaData from './components/legacyImageButtonMetaData';
import optionsListInputMetaData from './components/optionsListInputMetaData';
import radioButtonMetaData from './components/radioButtonMetaData';
import wPhotoMetaData from './components/wPhotoMetaData';
import comboBoxInputMetaData from './components/comboBoxInputMetaData';
import richTextBoxMetaData from './components/richTextBoxMetaData';
import toggleSwitchMetaData from './components/toggleSwitchMetaData';
import impressMetaData from './components/impressMetaData';
import masonryMetaData from './components/masonryMetaData';
import thumbnailsMetaData from './components/thumbnailsMetaData';
import matrixGalleryMetaData from './components/matrixGalleryMetaData';
import paginatedGridGalleryMetaData from './components/paginatedGridGalleryMetaData';
import sliderGalleryMetaData from './components/sliderGalleryMetaData';
import tpaWidget from './components/tpaWidget';
import stylableButtonMetaData from './components/stylableButtonMetaData';
import clipArtMetaData from './components/clipArtMetaData';
import svgShapeMetaData from './components/svgShapeMetaData';
import vectorImageMetaData from './components/vectorImageMetaData';
import videoPlayerMetaData from './components/videoPlayerMetaData';
import tagsMetaData from './components/tagsMetaData';
import mediaContainerMetaData from './components/mediaContainer';
import backToTopButtonMetaData from './components/backToTopButtonMetaData';
import menuContainerMetaData from './components/menuContainerMetaData';
import menuToggleMetaData from './components/menuToggleMetaData';
import checkboxGroupMetaData from './components/checkboxGroupMetaData';
import googleMapMetaData from './components/googleMap';
import refComponentMetaData from './components/refComponentMetaData';
import ghostRefComponentMetaData from './components/ghostRefComponentMetaData';
import appControllerMetaData from './components/appControllerMetaData';
import responsiveHeader from './components/responsiveHeader';
import classicSectionMetadata from './components/classicSectionMetaData';
import facebookLikeMetadata from './components/facebookLikeMetaData';
import facebookCommentsMetadata from './components/facebookCommentsMetadata';
import tweeterTweetMetadata from './components/tweeterTweetMetadata';
import tweeterFollowMetadata from './components/tweeterFollowMetadata';
import pinterestPinItMetadata from './components/pinterestPinItMetadata';
import pinterestWidgetMetadata from './components/pinterestWidgetMetadata';
import languageSelectorMetadata from './components/languageSelectorMetadata';
import anchorsMenuMetadata from './components/anchorsMenuMetadata';
import loginSocialBarMetaData from './components/loginSocialBarMetaData';

import type { ComponentEditorMetaDataDefinition } from './types';

const internalMetaDataMap: Record<string, ComponentEditorMetaDataDefinition> = {
  'responsive.components.HeaderSection': responsiveHeader,
  'wysiwyg.viewer.components.BgImageStrip': bgImageStrip,
  'wysiwyg.viewer.components.FooterContainer': footer,
  'wysiwyg.viewer.components.HeaderContainer': header,
  'wysiwyg.viewer.components.ScreenWidthContainer': screenWidthContainer,
  'tpa.viewer.components.StripSlideshow': stripSlideshowGallery,
  'tpa.viewer.components.StripShowcase': stripShowcase,
  'wixapps.integration.components.AppPart': blogComponents,
  'wixapps.integration.components.AppPart2': appPart2Components,
  'wysiwyg.common.components.rssbutton.viewer.RSSButton': rssButton,
  'wysiwyg.viewer.components.tpapps.TPAGluedWidget': tpaGluedWidget,
  'mobile.core.components.Page': page,
  'wysiwyg.viewer.components.BoxSlideShow': boxSlideShow,
  'wysiwyg.viewer.components.StripContainerSlideShow': stripSlideShow,
  'wysiwyg.viewer.components.BoxSlideShowSlide': boxSlideShowSlide,
  'wysiwyg.viewer.components.StripContainerSlideShowSlide': stripSlideShowSlide,
  'wysiwyg.viewer.components.StateBox': stateBox,
  'wysiwyg.viewer.components.StateStrip': stateStrip,
  'wysiwyg.viewer.components.StateBoxState': stateBoxState,
  'wysiwyg.viewer.components.StateBoxFormState': stateBoxFormState,
  'wysiwyg.viewer.components.StateStripState': stateStripState,
  'wysiwyg.common.components.anchor.viewer.Anchor': anchor,
  'tpa.viewer.components.Collage': collageMetaData,
  'wysiwyg.viewer.components.WRichText': wRichText,
  'wysiwyg.viewer.components.FiveGridLine': fiveGridLine,
  'wysiwyg.viewer.components.VerticalLine': verticalLine,
  'wysiwyg.viewer.components.LinkBar': socialBar,
  'wysiwyg.viewer.components.HtmlComponent': htmlComponent,
  'wysiwyg.viewer.components.Group': group,
  'wysiwyg.viewer.components.StripContainer': stripContainer,
  'wysiwyg.viewer.components.StripColumnsContainer': stripColumnsContainer,
  'wysiwyg.viewer.components.documentmedia.DocumentMedia':
    documentMediaMetaData,
  'wysiwyg.viewer.components.PayPalButton': payPalButtonMetaData,
  'wysiwyg.viewer.components.tpapps.TPASection': tpaSection,
  'wysiwyg.viewer.components.tpapps.TPAMultiSection': tpaSection,
  'wysiwyg.viewer.components.Column': columnMetaData,
  'wysiwyg.viewer.components.PopupCloseIconButton': popupCloseIconButton,
  'wysiwyg.viewer.components.PopupCloseTextButton': popupCloseTextButton,
  'wysiwyg.viewer.components.PopupContainer': popupContainerMetaData,
  'wysiwyg.viewer.components.MediaBox': mediaBox,
  'wysiwyg.viewer.components.MediaControls': mediaControls,
  'wysiwyg.viewer.components.MediaOverlayControls': mediaOverlayControls,
  'wysiwyg.viewer.components.MediaPlayer': mediaPlayer,
  'wysiwyg.viewer.components.QuickActionBarItem': quickActionBarItem,
  'wysiwyg.viewer.components.QuickActionBar': quickActionBar,
  'wysiwyg.viewer.components.mobile.TinyMenu': tinyMenu,
  'wysiwyg.viewer.components.Repeater': repeaterMetaData,
  'wysiwyg.viewer.components.SiteRegionContainer': siteRegionContainerMetaData,
  'wysiwyg.viewer.components.inputs.RadioGroup': radioGroupMetaData,
  'wysiwyg.viewer.components.PageGroup': pageGroup,
  'wysiwyg.viewer.components.PagesContainer': pagesContainer,
  'platform.components.AppWidget': appWidgetMetaData,
  'wysiwyg.viewer.components.inputs.Checkbox': checkbox,
  'wysiwyg.viewer.components.Video': videoMetaData,
  'wysiwyg.viewer.components.SiteButton': siteButtonMetaData,
  'wysiwyg.viewer.components.tpapps.TPA3DGallery': TPA3DGalleryMetaData,
  'wysiwyg.viewer.components.tpapps.TPA3DCarousel': TPA3DCarouselMetaData,
  'tpa.viewer.components.Accordion': accordionMetaData,
  'wysiwyg.viewer.components.SlideShowGallery': slideShowGalleryMetaData,
  'wysiwyg.viewer.components.inputs.DatePicker': datePickerMetaData,
  'wysiwyg.viewer.components.inputs.FileUploader': fileUploaderMetaData,
  'tpa.viewer.components.Freestyle': freeStyleMetaData,
  'wysiwyg.viewer.components.Grid': gridMetaData,
  'tpa.viewer.components.Honeycomb': honeyCombMetaData,
  'core.components.Image': imageMetaData,
  'wixapps.integration.components.ImageButton': legacyImageButtonMetaData,
  'wysiwyg.common.components.imagebutton.viewer.ImageButton':
    imageButtonMetaData,
  'wysiwyg.common.components.inputs.OptionsListInput': optionsListInputMetaData,
  'wysiwyg.viewer.components.inputs.TextInput': textInputMetaData,
  'wysiwyg.viewer.components.inputs.TextAreaInput': textAreaInputMetaData,
  'wysiwyg.viewer.components.inputs.RadioButton': radioButtonMetaData,
  'wysiwyg.viewer.components.WPhoto': wPhotoMetaData,
  'wysiwyg.viewer.components.inputs.ComboBoxInput': comboBoxInputMetaData,
  'wixui.RichTextBox': richTextBoxMetaData,
  'wixui.ToggleSwitch': toggleSwitchMetaData,
  'tpa.viewer.components.Impress': impressMetaData,
  'tpa.viewer.components.Masonry': masonryMetaData,
  'tpa.viewer.components.Thumbnails': thumbnailsMetaData,
  'wysiwyg.viewer.components.MatrixGallery': matrixGalleryMetaData,
  'wysiwyg.viewer.components.PaginatedGridGallery':
    paginatedGridGalleryMetaData,
  'wysiwyg.viewer.components.SliderGallery': sliderGalleryMetaData,
  'wysiwyg.viewer.components.tpapps.TPAWidget': tpaWidget,
  'wixui.CustomElementComponent': customElementComponentMetaData,
  'wysiwyg.viewer.components.ClipArt': clipArtMetaData,
  'wysiwyg.viewer.components.svgshape.SvgShape': svgShapeMetaData,
  'wysiwyg.viewer.components.VectorImage': vectorImageMetaData,
  'wixui.VideoPlayer': videoPlayerMetaData,
  'wixui.Tags': tagsMetaData,
  'wixui.StylableButton': stylableButtonMetaData,
  'mobile.core.components.Container': containerMetaData,
  'wysiwyg.viewer.components.MediaContainer': mediaContainerMetaData,
  'wysiwyg.viewer.components.BackToTopButton': backToTopButtonMetaData,
  'wysiwyg.viewer.components.MenuContainer': menuContainerMetaData,
  'wysiwyg.viewer.components.MenuToggle': menuToggleMetaData,
  'wysiwyg.viewer.components.inputs.CheckboxGroup': checkboxGroupMetaData,
  'wysiwyg.viewer.components.GoogleMap': googleMapMetaData,
  'wysiwyg.viewer.components.RefComponent': refComponentMetaData,
  GHOST_REF_COMP: ghostRefComponentMetaData,
  'platform.components.AppController': appControllerMetaData,
  [constants.COMP_TYPES.SECTION]: classicSectionMetadata,
  [constants.COMP_TYPES.FACEBOOK_LIKE]: facebookLikeMetadata,
  [constants.COMP_TYPES.FACEBOOK_COMMENTS]: facebookCommentsMetadata,
  [constants.COMP_TYPES.TWITTER_TWEET]: tweeterTweetMetadata,
  [constants.COMP_TYPES.TWITTER_FOLLOW]: tweeterFollowMetadata,
  [constants.COMP_TYPES.PINTERESET_PIN_IT]: pinterestPinItMetadata,
  [constants.COMP_TYPES.PINTERESET_WIDGET]: pinterestWidgetMetadata,
  [constants.COMP_TYPES.LANGUAGE_SELECTOR]: languageSelectorMetadata,
  [constants.COMP_TYPES.ANCHORS_MENU]: anchorsMenuMetadata,
  'wysiwyg.viewer.components.LoginSocialBar': loginSocialBarMetaData,
};

const externalMetaDataMap: Map<string, ComponentEditorMetaDataDefinition> =
  new Map();

function registerComponentMetaData(
  componentType: string,
  componentMetaDataDefinition: ComponentEditorMetaDataDefinition,
): void {
  externalMetaDataMap.set(componentType, componentMetaDataDefinition);
}

function getComponentMetaData(
  componentType: string,
): ComponentEditorMetaDataDefinition {
  if (externalMetaDataMap.has(componentType)) {
    return {
      ...(internalMetaDataMap[componentType] ?? {}), //because not all metadata was moved to editor elements. wixui.StylableButton allowedMobileOnly for example
      ...externalMetaDataMap.get(componentType),
    };
  }

  return internalMetaDataMap[componentType];
}

/**
 * Temporary method to get unsafe component metadata.
 * Don't use before discussion with editor infra team
 * Details: https://github.com/wix-private/santa-editor/issues/44229#issuecomment-1034024100
 */
function __getUnsafeComponentMetaData(componentType: string) {
  return getComponentMetaData(componentType)?.__unsafe;
}

export {
  getComponentMetaData,
  registerComponentMetaData,
  __getUnsafeComponentMetaData,
};
