import React, { useEffect } from 'react';
import * as util from '@/util';
import * as santaEditorUtils from '@wix/santa-editor-utils';
import experiment from 'experiment';
import {
  mapStateToProps,
  type WixFormsCollectionsListStateProps,
} from './wixFormsCollectionsList.mapper';
import CollectionsList from '../collectionsList/collectionsList';
import type { CollectionsListOwnProps } from '../collectionsList/collectionsList.mapper';
import { useRedirectToUserCollectionsTab } from '../../hooks/useRedirectToUserCollectionsTab';
import { CollectionsGroup } from '../utils/collections-group';

interface WixFormsCollectionsListProps
  extends CollectionsListOwnProps,
    WixFormsCollectionsListStateProps {
  setFormsMigrationHelperSeen: (value: boolean) => void;
}

const WixFormsCollectionsList: React.FC<WixFormsCollectionsListProps> = ({
  collections,
  selectTabByName,
  setFormsMigrationHelperSeen,
}) => {
  useRedirectToUserCollectionsTab({ collections, selectTabByName });
  useEffect(() => {
    setFormsMigrationHelperSeen(true);
  }, [setFormsMigrationHelperSeen]);

  const titlesInSubTitles =
    santaEditorUtils.isOnEditorX() &&
    experiment.isOpen('specs.wixDataClient.6thButtonPanelTitleInSubtitle');
  const title = titlesInSubTitles
    ? undefined
    : 'CONTENT_MANAGER_6_BUTTON_FORM_COLLECTIONS_TITLE';
  const subtitle = titlesInSubTitles
    ? 'EDITORX_CONTENT_MANAGER_6_BUTTON_FORM_COLLECTIONS_SUBTITLE_WITH_TITLE'
    : 'CONTENT_MANAGER_6_BUTTON_FORM_COLLECTIONS_SUBTITLE';
  return (
    <CollectionsList
      collections={collections}
      title={title}
      subtitle={subtitle}
      showActionButtons={false}
      showVideoTooltip={false}
      selectTabByName={selectTabByName}
      collectionsGroup={CollectionsGroup.APPS}
      ignoreGrouping
    />
  );
};

const WixFormsCollectionsListMapped = util.hoc.connect(
  util.hoc.STORES.EDITOR_API,
  mapStateToProps,
)(WixFormsCollectionsList);

export default WixFormsCollectionsListMapped;

interface WixFormsCollectionListExtendedProps {
  setFormsMigrationHelperSeen: (value: boolean) => void;
}

export const getWixFormsCollectionList = (
  additionalProps: WixFormsCollectionListExtendedProps,
) => {
  return function WixFormsCollectionListExtended(
    props: WixFormsCollectionsListProps,
  ) {
    return <WixFormsCollectionsListMapped {...props} {...additionalProps} />;
  };
};
