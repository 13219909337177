// @ts-nocheck
import React from 'react';
import createReactClass from 'create-react-class';
import _ from 'lodash';
import PropTypes from 'prop-types';
import experiment from 'experiment';
import { isResponsiveEditor } from '@wix/santa-editor-utils';
import * as symbols from '@wix/santa-editor-symbols';
import { TextButton, TextLabel, Bubble } from '@wix/wix-base-ui';
import * as core from '@/core';
import * as util from '@/util';
import { translate } from '@/i18n';
import * as addPanelData from '@/addPanelData';
import * as stateManagement from '@/stateManagement';
import * as coreBi from '@/coreBi';
import * as mixins from '@/mixins';
import * as panels from '@/panels';
import * as leftBar from '@/leftBar';
import addPanelDataConsts from '@/addPanelDataConsts';
import AddPanelCategoryViewWrapper from '../categoryView/categoryViewWrapper';
import addPanelStyleManager from './addPanelStyleManager';

function setSubCategoryIndexToUserPrefs(
  editorAPI,
  categoryId,
  subCategoryIndex,
) {
  editorAPI.store.dispatch(
    stateManagement.userPreferences.actions.setSessionUserPreferences(
      `category_${categoryId}_topSubCategory`,
      subCategoryIndex,
    ),
  );
}

function getTopSubCategoryFromUserPrefs(editorAPI, categoryId) {
  return (
    stateManagement.userPreferences.selectors.getSessionUserPreferences(
      `category_${categoryId}_topSubCategory`,
    )(editorAPI.store.getState()) || 0
  );
}

const SESSION_KEY_ADD_PANEL_CATEGORY_ID_ON_CLOSE = 'addPanelCategoryIdOnClose';

const groupDividerObject = { type: addPanelDataConsts.SECTION_DIVIDER };
function getSectionGroupHeaderAndDividerItem(group, index) {
  const groupHeader = (withoutDividerAbove = false) => ({
    type: addPanelDataConsts.SECTION_HEADER,
    title: group.title,
    withoutDividerAbove,
    shouldTranslate:
      group.shouldTranslate === undefined ? true : group.shouldTranslate,
  });

  if (!group.title && (index === 0 || group.noDivider)) {
    return [];
  } else if (!group.title) {
    return [groupDividerObject];
  } else if (index === 0) {
    return [groupHeader()];
  } else if (group.noDivider) {
    return [groupHeader(true)];
  }
  return [groupDividerObject, groupHeader()];
}

interface EditorSearchProps {
  onClick: () => void;
  label: string;
}

const EditorSearchHeaderButton: React.FunctionComponent<EditorSearchProps> = ({
  onClick,
  label,
}) => (
  <div className="add-panel-editor-search-header-button-wrapper">
    <TextButton
      onClick={onClick}
      dataHook="add-panel-editor-search-button"
      shouldTranslate={false}
      className="add-panel-editor-search-header-button"
      size="small"
      weight="thin"
      prefixIcon={
        <span className="add-panel-editor-search-header-icon">
          <symbols.symbol name="editorSearchButton_NewWorkspace" />
        </span>
      }
    >
      {label}
    </TextButton>
  </div>
);

const EditorSearchPanel: React.FunctionComponent<EditorSearchProps> = ({
  onClick,
}) => (
  <>
    <div
      className="add-panel-editor-search-button"
      data-hook="add-panel-editor-search-button"
      onClick={onClick}
    >
      <symbols.symbol name="editorSearchButton" />
      <TextLabel
        className="add-panel-editor-search-label"
        ellipsisProps={{ disabled: true }}
        value="Editor_Search_Add_Panel_Search_Label"
        type="T07"
      />
      <Bubble
        className="add-panel-editor-search-bubble"
        arrowAlignment="left"
        arrowStyle={{
          position: 'absolute',
          top: '14px',
          left: '-8px',
        }}
      >
        <TextLabel
          value="Editor_Search_Add_Panel_Title_Text"
          type="T03"
          enableEllipsis={false}
        />
        <TextLabel
          value="Editor_Search_Add_Panel_Description_Text"
          enableEllipsis={false}
        />
      </Bubble>
    </div>
    <hr className="add-panel-editor-search-separator" />
  </>
);

// eslint-disable-next-line react/prefer-es6-class
const AddPanel = createReactClass({
  displayName: 'addPanel',
  mixins: [
    util.addPanelHoverMixin,
    mixins.dragToStageMixin,
    core.mixins.editorAPIMixin,
  ],
  propTypes: {
    openPanelInteraction: PropTypes.object,
    overriddenCategories: PropTypes.array,
    overrideStartDrag: PropTypes.func,
    SectionDragSourceWrapper: PropTypes.func,
    transformStructureToResponsive: PropTypes.func,
    mediaManagerCallbackOverride: PropTypes.func,
    ignoreStyleMutations: PropTypes.bool,
    installAppOverride: PropTypes.func,
    disableAnchorsNavigation: PropTypes.bool,
    onClickOverride: PropTypes.func,
    disableHoverCategories: PropTypes.bool,
    disableHoverSubCategories: PropTypes.bool,
    overrideClose: PropTypes.func,
    sectionId: PropTypes.string,
    categoryHoverDelay: PropTypes.number,
    hideSingleSectionsBar: PropTypes.bool,
    useSubCategoryTitle: PropTypes.bool,
    onComponentAddedToStage: PropTypes.func,
  },

  getInitialState() {
    const editorAPI = this.getEditorAPI();

    this.applyOverrideForCategories(this.props.overriddenCategories);

    this.isNewWorkspace = util.workspace.isNewWorkspaceEnabled();
    this.currentPage = editorAPI.dsRead.pages.getFocusedPageId();
    const selectedIndex = this.getSelectedIndex(this.props);
    this.selectedSectionItems =
      this.getVisibleSectionsForCategoryView(selectedIndex);
    this.saveOriginalIndexesForSubNavigationOmits();
    const selectedCategoryId = this.categories[selectedIndex]?.id;
    return {
      selectedIndex,
      selectedCategoryId,
      subCategorySectionIndex: this.props.sectionId
        ? this.getOriginalSubNavigationIndexBySectionId(this.props.sectionId)
        : getTopSubCategoryFromUserPrefs(editorAPI, selectedIndex),
      isDragging: false,
    };
  },
  applyOverrideForCategories(overriddenCategories) {
    const editorAPI = this.getEditorAPI();
    const allCategories = addPanelData.getCategories(editorAPI);
    this.categories = addPanelData.applyOverrideForCategories(
      allCategories,
      overriddenCategories,
    );
  },
  getSubCategoryKey(sectionTitle) {
    return _.uniqueId(sectionTitle);
  },
  notifySelectedCategoryEvent(key, category, origin = null) {
    const params = {
      category: category.id,
      origin,
    };

    this.getEditorAPI().bi.event(coreBi.events.addPanel[key], params);
  },
  notifySelectedSubCategoryEvent(key, category, subCategory) {
    this.getEditorAPI().bi.event(coreBi.events.addPanel[key], {
      category: category.id,
      sub_category: subCategory.sectionName,
      sub_category_id: subCategory.sectionId,
    });
  },
  saveOriginalIndexesForSubNavigationOmits() {
    this.originalIndexesSubNavigation = this.selectedSectionItems.reduce(
      (acc, value) => {
        acc.push(value.id);
        return acc;
      },
      [],
    );

    this.groupedSectionMap = this.selectedSectionItems.reduce(
      (acc, value, key) => {
        if (!value.subNavigationHide) {
          acc[value.id] = key;
        } else {
          acc[value.id] = acc[this.originalIndexesSubNavigation[key - 1]];
        }
        return acc;
      },
      {},
    );
  },
  getGroupedSectionIndex(index) {
    return this.groupedSectionMap[this.originalIndexesSubNavigation[index]];
  },
  getOriginalSubNavigationIndexBySectionId(sectionId) {
    return this.originalIndexesSubNavigation.indexOf(sectionId);
  },
  doesSubCategoryHaveSubNavigation(sectionIndex) {
    return !this.selectedSectionItems[sectionIndex]?.subNavigationHide;
  },
  getSelectedIndex(props) {
    const categoryFromProps = props.category?.toLowerCase();

    const id =
      categoryFromProps ||
      stateManagement.userPreferences.selectors.getSessionUserPreferences(
        SESSION_KEY_ADD_PANEL_CATEGORY_ID_ON_CLOSE,
      )(this.getEditorAPI().store.getState()) ||
      '';

    const selectedIndex = this.categories.findIndex(function (category) {
      return category.id.toLowerCase() === id.toLowerCase();
    });

    return selectedIndex !== -1 ? selectedIndex : 0;
  },
  saveCategoryOnPanelClose(categoryIndex) {
    const { id } = this.categories[categoryIndex];
    this.getEditorAPI().store.dispatch(
      stateManagement.userPreferences.actions.setSessionUserPreferences(
        SESSION_KEY_ADD_PANEL_CATEGORY_ID_ON_CLOSE,
        id,
      ),
    );
  },
  clearTimeoutBISelectCategoryEvent() {
    window.clearTimeout(this.addPanelHoverTimeoutId);
  },
  componentWillUnmount() {
    this.clearTimeoutBISelectCategoryEvent();
    this.saveCategoryOnPanelClose(this.state.selectedIndex);
    setSubCategoryIndexToUserPrefs(
      this.getEditorAPI(),
      this.state.selectedIndex,
      this.state.subCategorySectionIndex,
    );
  },
  UNSAFE_componentWillMount() {
    this.selectedSectionItems = this.getVisibleSectionsForCategoryView(
      this.state.selectedIndex,
    );
  },
  componentDidMount() {
    this.props.openPanelInteraction?.end();
    this.logCategoryChange(this.categories[this.state.selectedIndex]);
  },
  UNSAFE_componentWillUpdate(nextProps, nextState) {
    if (this.state.selectedIndex !== nextState.selectedIndex) {
      setSubCategoryIndexToUserPrefs(
        this.getEditorAPI(),
        this.state.selectedIndex,
        this.state.subCategorySectionIndex,
      );
      this.selectedSectionItems = this.getVisibleSectionsForCategoryView(
        nextState.selectedIndex,
      );
      this.saveOriginalIndexesForSubNavigationOmits();

      const isCategoryIdChanged =
        this.state.selectedCategoryId !== nextState.selectedCategoryId;
      const subCategorySectionIndex = isCategoryIdChanged
        ? getTopSubCategoryFromUserPrefs(
            this.getEditorAPI(),
            nextState.selectedIndex,
          )
        : nextState.subCategorySectionIndex;
      this.setState({
        subCategorySectionIndex,
      });
    } else if (
      !_.isEqual(
        this.props.overriddenCategories,
        nextProps.overriddenCategories,
      )
    ) {
      this.applyOverrideForCategories(nextProps.overriddenCategories);

      const selectedIndex = this.getSelectedIndex(nextProps);

      this.selectedSectionItems =
        this.getVisibleSectionsForCategoryView(selectedIndex);
      this.saveOriginalIndexesForSubNavigationOmits();
      const subCategorySectionIndex = nextProps.sectionId
        ? this.getOriginalSubNavigationIndexBySectionId(nextProps.sectionId)
        : getTopSubCategoryFromUserPrefs(this.getEditorAPI(), selectedIndex);
      this.setState({
        selectedIndex,
        selectedCategoryId: this.categories[selectedIndex]?.id,
        subCategorySectionIndex,
      });
    }

    if (nextProps.sectionId && nextProps.sectionId !== this.props.sectionId) {
      const subCategorySectionIndex =
        this.getOriginalSubNavigationIndexBySectionId(nextProps.sectionId);
      this.setState({ subCategorySectionIndex });
    }
  },
  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.category && newProps.category !== this.props.category) {
      const categoryFromProps =
        newProps.category && newProps.category.toLowerCase();
      const selectedIndex = this.categories.findIndex(function (category) {
        return category.id.toLowerCase() === categoryFromProps;
      });
      if (selectedIndex !== -1 && selectedIndex !== this.state.selectedIndex) {
        const nextCategory = this.categories[selectedIndex];

        this.setState({
          selectedIndex,
          selectedCategoryId: nextCategory?.id,
        });

        if (nextCategory) {
          this.logCategoryChange(nextCategory);
        }
      }
    }
  },
  getVisibleSectionsForCategoryView(selectedIndex) {
    const editorAPI = this.getEditorAPI();
    function rejectSections(sectionsArray) {
      return _.reject(sectionsArray, function (sectionDefinition) {
        if (sectionDefinition.hide !== false) {
          return true;
        }
        switch (sectionDefinition.type) {
          case addPanelDataConsts.SECTIONS_TYPES.THEME:
            const themeStyles = addPanelStyleManager.getThemeStylesArr(
              sectionDefinition.props.compTypes,
              editorAPI.theme,
              editorAPI.components,
            );
            return _.isEmpty(themeStyles);
          case addPanelDataConsts.SECTIONS_TYPES.PERSONALIZED:
            const myStyles = addPanelStyleManager.getPersonalizeStylesArr(
              editorAPI.theme,
              editorAPI.components,
              sectionDefinition.props.compTypes,
            );
            return _.isEmpty(myStyles);
          case 'default':
            return false;
        }
      });
    }

    const categoryItems = this.categories[selectedIndex].items;
    const filteredSectionGroups = categoryItems
      .map((sectionsGroup) =>
        _.defaults(
          { sections: rejectSections(sectionsGroup.sections) },
          sectionsGroup,
        ),
      )
      .filter((group) => !_.isEmpty(group.sections));

    this.sectionsWithHeaders = filteredSectionGroups.reduce(
      (acc, group, index) => [
        ...acc,
        ...getSectionGroupHeaderAndDividerItem(group, index),
        ...group.sections,
      ],
      [],
    );

    return this.sectionsWithHeaders.filter(this.isItemSubSection);
  },
  isItemSubSection(item) {
    return (
      item.type !== addPanelDataConsts.SECTION_HEADER &&
      item.type !== addPanelDataConsts.SECTION_DIVIDER
    );
  },
  getSelectedCategory() {
    let categoryData = Object.assign(
      {},
      this.categories[this.state.selectedIndex],
    );
    const items = categoryData.renderSingleSection
      ? this.selectedSectionItems.filter(
          (item, index) =>
            this.getGroupedSectionIndex(index) ===
            this.state.subCategorySectionIndex,
        )
      : this.selectedSectionItems;

    categoryData = Object.assign(categoryData, {
      onClose: this.props.overrideClose || this.closePanel,
      onItemDrag: this.props.overrideStartDrag || this.startItemDrag,
      SectionDragSourceWrapper: this.props.SectionDragSourceWrapper,
      transformStructureToResponsive: this.props.transformStructureToResponsive,
      installAppOverride: this.props.installAppOverride,
      disableAnchorsNavigation: this.props.disableAnchorsNavigation,
      onClickOverride: this.props.onClickOverride,
      mediaManagerCallbackOverride: this.props.mediaManagerCallbackOverride,
      allCategories: this.categories,
      items,
      ignoreStyleMutations: this.props.ignoreStyleMutations,
      isDragging: this.state.isDragging,
      section: this.props.section,
      currentPage: this.currentPage,
      subCategoryChangeHandler: this.subCategoryChangeHandler,
      doesSubCategoryHaveSubNavigation: this.doesSubCategoryHaveSubNavigation,
      currentSubSectionTitle:
        this.selectedSectionItems[this.state.subCategorySectionIndex]?.topTitle,
      subCategorySectionIndex: this.state.subCategorySectionIndex,
      getGroupedSectionIndex: this.getGroupedSectionIndex,
      setNewTopTitle: this.setNewTopTitle,
      sectionId: this.props.sectionId,
      getPastePosition: this.props.getPastePosition,
      onComponentAddedToStage: this.props.onComponentAddedToStage,
    });

    if (this.props.useSubCategoryTitle) {
      categoryData.title =
        this.selectedSectionItems[this.state.subCategorySectionIndex]
          ?.subNavigationTitle ?? categoryData.title;
      categoryData.shouldTranslate =
        this.selectedSectionItems[this.state.subCategorySectionIndex]
          ?.shouldTranslate ?? categoryData.shouldTranslate;
    }

    return categoryData;
  },
  getClasses(category, subCategory = {}) {
    const obj = { category: true };
    obj[category.id] = true;
    obj.disabled = category.disabled;
    obj[addPanelDataConsts.CATEGORY_DIVIDER] =
      category.type === addPanelDataConsts.CATEGORY_DIVIDER;
    if (subCategory.type === addPanelDataConsts.SECTION_HEADER) {
      obj.category = false;
      obj[addPanelDataConsts.SECTION_HEADER] = true;
      obj.withoutDividerAbove = subCategory.withoutDividerAbove;
    } else if (subCategory.type === addPanelDataConsts.SECTION_DIVIDER) {
      obj.category = false;
      obj[addPanelDataConsts.SECTION_DIVIDER] = true;
    }
    return obj;
  },
  getCategoryItemStyle(category, categoryHeight) {
    const margin = this.isNewWorkspace ? 0 : 2;
    return category.type === addPanelDataConsts.CATEGORY_DIVIDER
      ? {}
      : {
          height: `calc(${categoryHeight}% - ${margin}px)`,
        };
  },
  shouldComponentUpdate(nextProps, nextState) {
    const currPageId = this.getEditorAPI().dsRead.pages.getFocusedPageId();
    if (currPageId !== this.currentPage) {
      this.currentPage = currPageId;
    }
    return !(
      _.isEqual(this.props, nextProps) && _.isEqual(this.state, nextState)
    );
  },
  closePanel() {
    this.getEditorAPI().panelManager.closePanelByName(this.props.panelName);
  },
  logCategoryChange(category) {
    window.clearTimeout(this.addPanelHoverTimeoutId);

    this.addPanelHoverTimeoutId = window.setTimeout(() => {
      this.notifySelectedCategoryEvent(
        'ADD_MENU_CATEGORY_SELECT',
        category,
        this.props.origin,
      );

      const subCategory =
        this.sectionsWithHeaders[this.state.subCategorySectionIndex];

      this.notifySelectedSubCategoryEvent(
        'ADD_MENU_SUB_CATEGORY_SELECT',
        category,
        subCategory,
      );
    }, 1500);
  },
  getMouseActionsHandlers(category, categoryIndex) {
    const self = this;

    if (category.disabled) {
      return {};
    }

    const handlers = {
      onMouseEnter(e) {
        self.enter(
          e,
          category,
          categoryIndex,
          function () {
            if (self.state.selectedIndex !== categoryIndex) {
              self.logCategoryChange(category);
            }
            self.setState({
              selectedIndex: categoryIndex,
              selectedCategoryId: self.categories[categoryIndex]?.id,
            });
            this.addPanelHoverTimeoutId = self.addPanelHoverTimeoutId;
          },
          self.props.categoryHoverDelay,
        );
      },
      onMouseLeave(e) {
        self.leave(e, categoryIndex);
      },
      onClick() {
        self.notifySelectedCategoryEvent('ADD_MENU_CATEGORY_CLICK', category);
        self.setState({
          selectedIndex: categoryIndex,
          selectedCategoryId: self.categories[categoryIndex]?.id,
        });
      },
    };

    if (this.props.disableHoverCategories) {
      delete handlers.onMouseEnter;
      delete handlers.onMouseLeave;
    }
    return handlers;
  },
  setNewTopTitle(newSubCategoryIndex) {
    const newTitle = this.selectedSectionItems[newSubCategoryIndex]?.topTitle;
    _.invoke(this.refs.categoryViewWrapper, 'updateTopTitle', newTitle);
  },
  subCategoryChangeHandler(newSubCategoryIndex) {
    const newTitle = this.selectedSectionItems[newSubCategoryIndex]?.topTitle;
    _.invoke(this.refs.categoryViewWrapper, 'updateTopTitle', newTitle);
    this.setState({ subCategorySectionIndex: newSubCategoryIndex });
  },
  setSection(subCategoryIndex) {
    this.setState({ subCategorySectionIndex: subCategoryIndex });
    this.refs.categoryViewWrapper.refs.categoryView.setTopSection(
      subCategoryIndex,
      true,
    );
  },
  getMouseActionsHandlersSubCategory(subCategory, subCategoryIndex) {
    const self = this;

    const handlers = {
      onMouseEnter(e) {
        const HOVER_DELAY = 300;

        self.enter(
          e,
          subCategory,
          subCategoryIndex,
          function () {
            if (self.state.subCategoryIndex !== subCategoryIndex) {
              window.clearTimeout(self.addPanelHoverTimeoutId);
              self.addPanelHoverTimeoutId = window.setTimeout(function () {
                self.notifySelectedSubCategoryEvent(
                  'ADD_MENU_SUB_CATEGORY_SELECT',
                  self.categories[self.state.selectedIndex],
                  subCategory,
                );
              }, 1500);
            }
            self.setSection(subCategoryIndex);
            this.addPanelHoverTimeoutId = self.addPanelHoverTimeoutId;
          },
          HOVER_DELAY,
        );
      },
      onMouseLeave(e) {
        self.leave(e, subCategoryIndex);
      },
      onClick() {
        self.notifySelectedSubCategoryEvent(
          'ADD_MENU_SUB_CATEGORY_CLICK',
          self.categories[self.state.selectedIndex],
          subCategory,
        );
        self.setState({ subCategoryIndex });
        self.setSection(subCategoryIndex);
      },
    };

    if (this.props.disableHoverSubCategories) {
      delete handlers.onMouseEnter;
      delete handlers.onMouseLeave;
    }
    return handlers;
  },
  handleEditorSearchButtonClick() {
    this.props.openSearch();
    const editorAPI = this.getEditorAPI();
    editorAPI.bi.event(coreBi.events.editorSearch.addPanelButtonClicked);
  },

  shouldShowEditorSearchButton() {
    return !isResponsiveEditor();
  },

  shouldShowSubCategory() {
    return (
      this.categories[this.state.selectedIndex].hasSubCategory &&
      (!this.props.hideSingleSectionsBar ||
        this.sectionsWithHeaders.filter(
          (item) => !item.subNavigationHide && this.isItemSubSection(item),
        ).length > 1)
    );
  },

  render() {
    const categoryHeight = 100 / this.categories.length;
    const helpIdForCurrentTab = this.getSelectedCategory()?.help?.helpId;

    const withEditorSearchPanel =
      this.shouldShowEditorSearchButton() && !this.isNewWorkspace;
    const withEditorSearchHeaderButton =
      this.shouldShowEditorSearchButton() && this.isNewWorkspace;
    const LeftPanelFrame = this.isNewWorkspace
      ? leftBar.LeftPanelFrame
      : panels.frames.LeftPanelFrame;

    const addPanelTitle = util.sections.isSectionsEnabled()
      ? 'add_elements_panel_title'
      : 'add_panel_title';

    return (
      <LeftPanelFrame
        panelName={this.props.panelName}
        panelClass={util.cx({
          'add-panel': true,
          'add-panel-new-workspace': this.isNewWorkspace,
        })}
        panelIndex={this.props.panelIndex}
        styleOverride={{ overflow: 'visible' }}
        label={this.isNewWorkspace ? translate(addPanelTitle) : undefined}
        helpId={this.isNewWorkspace ? helpIdForCurrentTab : undefined}
        onContextMenu={(e) => {
          //eslint-disable-next-line @wix/santa/no-falsy-experiment
          if (!experiment.isOpen('allowAddPanelRightClick')) {
            e.preventDefault();
          }
        }}
        dynamicWidth
        headerActionsBar={
          withEditorSearchHeaderButton ? (
            <EditorSearchHeaderButton
              onClick={this.handleEditorSearchButtonClick}
              label={translate('Topbar_Searchbar_Text')}
            />
          ) : null
        }
      >
        <div
          className={util.cx('add-panel-categories-container', {
            'with-editor-search': withEditorSearchPanel,
          })}
        >
          {withEditorSearchPanel && (
            <EditorSearchPanel onClick={this.handleEditorSearchButtonClick} />
          )}
          <ul onMouseLeave={this.reset} className="add-panel-category-list">
            {this.categories.map((category, categoryIndex) => (
              <li
                key={`${category.id}_${categoryIndex}`}
                style={this.getCategoryItemStyle(category, categoryHeight)}
                className={util.cx(this.getClasses(category))}
              >
                {category.type !== addPanelDataConsts.CATEGORY_DIVIDER ? (
                  <span
                    key={`${category.id}_label`}
                    className={util.cx({
                      'category-name-wrapper': true,
                      'is-selected': this.state.selectedIndex === categoryIndex,
                    })}
                    {...this.getMouseActionsHandlers(category, categoryIndex)}
                  >
                    <TextLabel
                      ellipsisProps={{ marginTop: 8 }}
                      type="T07"
                      value={category.label}
                      shouldTranslate={category?.shouldTranslate ?? true}
                      className="category-name"
                    />
                  </span>
                ) : null}
                {category.type === addPanelDataConsts.CATEGORY_DIVIDER ? (
                  <hr
                    align="left"
                    key={`divider_${categoryIndex}`}
                    className="add-panel-category-divider"
                  />
                ) : null}
              </li>
            ))}
          </ul>
        </div>
        {this.shouldShowSubCategory() ? (
          <ul
            key="add-panel-sub-category"
            onMouseLeave={this.reset}
            className="add-panel-sub-category-list"
          >
            {this.sectionsWithHeaders
              .filter((item) => !item.subNavigationHide)
              .map((subCategory, subCategoryIndex) => (
                <li
                  key={this.getSubCategoryKey(subCategory.title)}
                  style={this.getCategoryItemStyle(subCategory, categoryHeight)}
                  className={util.cx(
                    this.getClasses(
                      this.categories[this.state.selectedIndex],
                      subCategory,
                    ),
                  )}
                >
                  {subCategory.type === addPanelDataConsts.SECTION_HEADER ? (
                    <span
                      key={`sub-category-group-header-${subCategoryIndex}`}
                      className="category-group-title"
                    >
                      {subCategory.shouldTranslate
                        ? translate(subCategory.title)
                        : subCategory.title}
                    </span>
                  ) : null}
                  {this.isItemSubSection(subCategory) ? (
                    <span
                      key={`sub-category-label-${subCategoryIndex}`}
                      data-automation-id={`${subCategory.automationId}-nav`}
                      className={util.cx({
                        'category-name-wrapper': true,
                        'is-selected':
                          this.state.subCategorySectionIndex ===
                          this.getOriginalSubNavigationIndexBySectionId(
                            subCategory.id,
                          ),
                      })}
                      {...this.getMouseActionsHandlersSubCategory(
                        subCategory,
                        this.getOriginalSubNavigationIndexBySectionId(
                          subCategory.id,
                        ),
                      )}
                    >
                      <TextLabel
                        ellipsisProps={{ marginTop: 8 }}
                        type="T07"
                        value={subCategory.subNavigationTitle}
                        shouldTranslate={subCategory?.shouldTranslate ?? true}
                        className="category-name"
                      />
                    </span>
                  ) : null}
                  {subCategory.type === addPanelDataConsts.SECTION_DIVIDER ? (
                    <hr
                      key={`sub-category-group-divider-${subCategoryIndex}`}
                      className="sub-category-group-divider"
                    />
                  ) : null}
                </li>
              ))}
          </ul>
        ) : null}
        <AddPanelCategoryViewWrapper
          ref="categoryViewWrapper"
          {...this.getSelectedCategory()}
        />
      </LeftPanelFrame>
    );
  },
});

const mapDispatchToProps = (dispatch) => ({
  openSearch() {
    dispatch(
      stateManagement.editorSearch.actions.openEditorSearchPanel({
        origin: 'add_panel',
        shouldLeavePanelsOpen: true,
      }),
    );
  },
});

const mapStateToProps = ({ state }, props) => ({
  openPanelInteraction: props.openPanelInteraction,
  overriddenCategories:
    props.overriddenCategories ||
    stateManagement.addPanel.selectors.getOverriddenCategories(state),
  overrideStartDrag: props.overrideStartDrag,
  overrideClose: props.overrideClose,
  SectionDragSourceWrapper: props.SectionDragSourceWrapper,
  disableHoverSubCategories: props.disableHoverSubCategories,
  disableHoverCategories: props.disableHoverCategories,
  mediaManagerCallbackOverride: props.mediaManagerCallbackOverride,
  onClickOverride: props.onClickOverride,
  ignoreStyleMutations: props.ignoreStyleMutations,
  installAppOverride: props.installAppOverride,
  disableAnchorsNavigation: props.disableAnchorsNavigation,
  transformStructureToResponsive: props.transformStructureToResponsive,
  hideSingleSectionsBar: props.hideSingleSectionsBar,
  useSubCategoryTitle: props.useSubCategoryTitle,
});

const Connected = util.hoc.connect(
  util.hoc.STORES.STATE_ONLY,
  mapStateToProps,
  mapDispatchToProps,
)(AddPanel);

export default Connected;
