import React from 'react';
import * as util from '@/util';
import { isResponsiveEditor } from '@wix/santa-editor-utils';
import experiment from 'experiment';
import {
  mapStateToProps,
  type UserCollectionsListStateProps,
} from './userCollectionsList.mapper';
import CollectionsList from '../collectionsList/collectionsList';
import type { CollectionsListOwnProps } from '../collectionsList/collectionsList.mapper';
import { CollectionsGroup } from '../utils/collections-group';

interface UserCollectionsListProps
  extends CollectionsListOwnProps,
    UserCollectionsListStateProps {}

const UserCollectionsList: React.FC<UserCollectionsListProps> = ({
  collections,
  selectTabByName,
  showFormsMigrationHelper,
}) => {
  const contentManagerUsageLimitsOpen = experiment.isOpen(
    'specs.wixCode.ContentManagerUsageLimits',
  );

  const collectionCount = contentManagerUsageLimitsOpen
    ? collections.length
    : undefined;

  const titlesInSubTitles =
    isResponsiveEditor() &&
    experiment.isOpen('specs.wixDataClient.6thButtonPanelTitleInSubtitle');
  const title = titlesInSubTitles
    ? undefined
    : 'CONTENT_MANAGER_6_BUTTON_USER_COLLECTIONS_TITLE';
  const subtitle =
    titlesInSubTitles && contentManagerUsageLimitsOpen
      ? 'EDITORX_CONTENT_MANAGER_6_BUTTON_USER_COLLECTIONS_SUBTITLE_WITH_TITLE'
      : 'CONTENT_MANAGER_6_BUTTON_USER_COLLECTIONS_SUBTITLE';

  return (
    <CollectionsList
      collections={collections}
      collectionCount={collectionCount}
      title={title}
      subtitle={subtitle}
      showActionButtons={true}
      showVideoTooltip={true}
      selectTabByName={selectTabByName}
      isUserCollectionsList={true}
      collectionsGroup={CollectionsGroup.USER}
      showFormsMigrationHelper={showFormsMigrationHelper}
    />
  );
};

const UserCollectionsListMapped = util.hoc.connect(
  util.hoc.STORES.EDITOR_API,
  mapStateToProps,
)(UserCollectionsList);

export default UserCollectionsListMapped;

interface UserCollectionListExtendedProps {
  showFormsMigrationHelper: boolean;
}

export const getUserCollectionList = (
  additionalProps: UserCollectionListExtendedProps,
) => {
  return function UserCollectionListExtended(props: UserCollectionsListProps) {
    return <UserCollectionsListMapped {...props} {...additionalProps} />;
  };
};
