// @ts-nocheck
import gfppDataUtils from '../../utils/gfppDataUtils';
import constants from '@/constants';

const { ACTIONS } = constants.ROOT_COMPS.GFPP;

export default {
  mainActions: [
    {
      label: 'gfpp_mainaction_socialbar',
      isApplied: gfppDataUtils.getDataDefinedFn('items'),
      onClick(editorAPI, compRef) {
        editorAPI.panelHelpers.openOrganizeSocialIconsPanel(function (
          newImageDataList,
        ) {
          const data = editorAPI.components.data.get(compRef);
          data.items = newImageDataList;
          editorAPI.components.data.update(compRef, data);
        });
      },
    },
  ],
  enabledActions: [ACTIONS.LAYOUT, ACTIONS.ANIMATION, ACTIONS.HELP],
  mobileEnabledActions: [
    ACTIONS.LAYOUT,
    ACTIONS.HIDE,
    ACTIONS.HELP,
    ACTIONS.ANIMATION,
  ],
  presetActions: {
    help: {
      helpId: '87538995-bdc2-4c77-9214-519f76222e51',
    },
  },
};
