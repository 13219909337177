import React from 'react';
import _ from 'lodash';
import * as util from '@/util';
import { deriveTestSitePanelDataFromProps } from '../../topBarComponent/mapTopBarPropsToButtons';
import {
  mapStateToProps,
  mapDispatchToProps,
  type TestSitePanelDispatchProps,
} from './testSitePanelMappers';
import TextAndLinkPanel from '../textAndLinkPanel';

const {
  connect,
  STORES: { EDITOR_API },
} = util.hoc;

interface TestSitePanelProps extends TestSitePanelDispatchProps {
  sitePublicUrl: string;
}

class TestSitePanel extends React.Component<TestSitePanelProps> {
  state = {
    panelData: null as AnyFixMe,
  };

  static getDerivedStateFromProps(props: AnyFixMe) {
    const panelData = deriveTestSitePanelDataFromProps(props);

    return { panelData };
  }

  render() {
    const { panelData } = this.state;

    return <TextAndLinkPanel panelData={panelData} />;
  }
}

const ConnectedTestSitePanel = _.flow(
  connect(EDITOR_API, mapStateToProps, mapDispatchToProps),
)(TestSitePanel);

export default ConnectedTestSitePanel;
