import { interactionsActions, interactionsSelectors } from '@/stateManagement';

import type {
  CompRef,
  CompStructure,
  CompVariantPointer,
} from 'types/documentServices';
import type { EditorAPI } from '@/editorAPI';
import type { ComponentsStyleApi } from '../componentsStyleApi';
import {
  createEmptyStyleStylable,
  removeRefIfEmptyStyle as removeRefIfEmptyStyleInner,
} from './stylableStyleWrapper';

export const hasResponsiveLayout = (editorAPI: EditorAPI, compRef: CompRef) =>
  Boolean(
    compRef && editorAPI.dsRead.components.responsiveLayout?.get(compRef),
  );

export function createComponentsStylableApi({
  editorAPI,
  componentsStyleApi,
}: {
  editorAPI: EditorAPI;
  componentsStyleApi: ComponentsStyleApi;
}) {
  const { isInInteractionMode, isShownOnlyOnVariant, getCompVariantPointer } =
    interactionsSelectors;

  function update(
    compRef: CompRef,
    styleData: Exclude<CompStructure['style'], string>,
  ): void {
    let res;
    const state = editorAPI.store.getState();
    const { id, ...styleValueWithoutId } = styleData;

    if (
      isInInteractionMode(state) &&
      !isShownOnlyOnVariant(editorAPI, compRef)
    ) {
      const pointerWithVariant = getCompVariantPointer(state);
      res = editorAPI.dsActions.components.stylable.update(
        pointerWithVariant,
        styleValueWithoutId,
      );
      interactionsActions.setDefaultTransition(editorAPI, compRef);
    } else if (
      editorAPI.isMobileEditor() &&
      !editorAPI.mobile.mobileOnlyComponents.isMobileOnlyNonNativeComponent(
        compRef,
      ) &&
      hasResponsiveLayout(editorAPI, compRef)
    ) {
      componentsStyleApi.updateStyleInMobile(
        compRef,
        styleData,
        // @ts-expect-error
        editorAPI.dsActions.components.stylable.update,
      );
    } else {
      res = editorAPI.dsActions.components.stylable.update(compRef, styleData);
      componentsStyleApi.cascadeStyleToMobileIfNeeded(
        compRef,
        styleData,
        // @ts-expect-error
        editorAPI.dsActions.components.stylable.update,
      );
    }

    return res;
  }

  function createEmptyStyle(compRef: CompRef) {
    return createEmptyStyleStylable(editorAPI, compRef);
  }

  function removeRefIfEmptyStyle(
    compRef: CompRef,
    variantPointer: CompVariantPointer,
  ) {
    return removeRefIfEmptyStyleInner(editorAPI, compRef, variantPointer);
  }

  return {
    update,
    createEmptyStyle,
    removeRefIfEmptyStyle,
  };
}
