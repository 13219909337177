import experiment from 'experiment';
import constants from '@/constants';
import * as stateManagement from '@/stateManagement';
import {
  type ColorName,
  isNewColorPaletteOpen,
  unwrapColors,
  isColorFromOldPalette,
} from '@/theme';
import colorPickerConstants from '../constants';
import type {
  ColorPickerPanelOwnProps,
  ColorPickerPanelStateProps,
  ColorPickerPanelDispatchProps,
  ColorPickerValue,
} from './types';
import type {
  MapStateToProps,
  MapDispatchToProps,
  ThunkAction,
} from 'types/redux';
import type { ColorPalette } from 'types/documentServices';
import _ from 'lodash';

const stylableEditorActions = stateManagement.stylableEditor.actions;

export const mapStateToProps: MapStateToProps<
  ColorPickerPanelStateProps,
  ColorPickerPanelOwnProps
> = ({ editorAPI }, props) => {
  const compRef = editorAPI.selection.getSelectedComponents()[0];
  const componentsSelectors = stateManagement.components.selectors;
  const supportNewColorPalette = isNewColorPaletteOpen(editorAPI);
  const unwrappedValue =
    typeof props.value === 'string' ? unwrapColors(props.value) : props.value;

  // If accent color was replaced and removed,
  // color picker should show the color it was linked to
  const updatedValue =
    editorAPI.theme.colors.getReplacedAccentColor(
      unwrappedValue as ColorName,
    ) ?? unwrappedValue;

  const isAddThemeColorButtonExperimentOpen = experiment.isOpen(
    'se_addThemeColorButtonInColorPicker',
  );

  const handleThemeColorAdd = (color: string) => {
    const palette = editorAPI.theme.colors.getAll();

    const emptyAccentColorKey = _.findKey(palette, (_, key) =>
      editorAPI.theme.colors.isAccentColorEmpty(key as ColorName),
    );

    editorAPI.theme.colors.update({
      [emptyAccentColorKey]: color,
    });
  };

  return {
    shouldExtendDefaultShortcuts: experiment.isOpen('se_colorPickerUndo'),
    keyboardShortcuts: experiment.isOpen('se_colorPickerUndo') && {
      'ctrl + z, command + z': () => editorAPI.history.undo(),
      'ctrl + shift + z, command + shift + z': () => editorAPI.history.redo(),
    },
    componentId: compRef?.id || '',
    componentType:
      componentsSelectors.getCompType(compRef, editorAPI.dsRead) || '',
    msid: editorAPI.dsRead.generalInfo.getMetaSiteId(),
    editorViewMode: editorAPI.viewMode.get(),
    colorResolver: editorAPI.theme.colors.get,
    focusedPageId: editorAPI.pages.getFocusedPageId(),
    isDraft: editorAPI.dsRead.generalInfo.isDraft(),
    isZoomOut: editorAPI.zoomMode.isInZoomMode(),
    viewerName: editorAPI.dsRead.env.viewer.getViewerName(),
    openThemeManager: props.openThemeManager,
    supportNewColorPalette,
    updatedValue,
    onChange: props.onChange,
    /**
     * The order of the color swatches in the custom color panel,
     * ONE DOES NOT SIMPLY CHANGE THE ORDER OF COLORS
     * 1st - white and black (initial values injected in colorPickerPanel internally)
     * 2nd - passed value
     * 3rd - show the user saved colors
     * 4th and beyond - show specific components colors.
     * @returns {string[]}
     */
    getCustomColors(value?: string): string[] {
      const {
        customUserAddedSessionColors = [],
        userCustomAddedSiteColorsTemplateMigration = [],
        userCustomAddedSiteColors = [],
        customCompsAddedColors = [],
        customInstanceColors = [],
      } = props;

      const colors = _.uniq([
        ...customUserAddedSessionColors,
        ...userCustomAddedSiteColorsTemplateMigration,
        ...userCustomAddedSiteColors,
        ...customCompsAddedColors,
        ...customInstanceColors,
      ]);

      if (value && !colors.includes(value)) {
        colors.unshift(value);
      }

      return colors;
    },
    handleAddCustomColor(newCustomColor: string) {
      if (props.shouldNotAddCustomColor) return;
      const customColorsAddedByUser = [
        ...(props.userCustomAddedSiteColors || []),
      ];
      customColorsAddedByUser.unshift(newCustomColor);
      props.setUserCustomAddedSiteColors(customColorsAddedByUser);
      props.stylableEditorSetUserColors?.();
    },
    handleAddGradient(newGradientColor) {
      const customGradientsAdded = [...(props.customAddedGradients || [])];
      customGradientsAdded.unshift(newGradientColor);
      props.setCustomAddedGradients(customGradientsAdded);
    },
    handleAddMesh(newMesh) {
      const customMeshAdded = [...(props.customAddedMeshes || [])];
      customMeshAdded.unshift(newMesh);
      props.setCustomAddedMeshes(customMeshAdded);
    },
    handleThemeColorAdd: isAddThemeColorButtonExperimentOpen
      ? handleThemeColorAdd
      : undefined,
    getThemeColors() {
      const themeColorsMap = supportNewColorPalette
        ? editorAPI.theme.colors.getVisibleThemeColors()
        : editorAPI.theme.colors.getAll('onlyOld');
      const themeColors = Object.keys(themeColorsMap);

      return themeColors
        .filter((item) => item !== props.hiddenColors)
        .map((item) => ({
          id: item,
          value: themeColorsMap[item as keyof ColorPalette],
        }));
    },
    getColorDefaults() {
      const themeColorsMap = editorAPI.theme.colors.getAdvancedColors();
      const themeColors = Object.keys(themeColorsMap);

      return themeColors.map((item) => ({
        id: item,
        value: themeColorsMap[item as keyof ColorPalette],
      }));
    },
    getLinkedThemeColorFromColorDefault(colorName: string) {
      const linkedColors = editorAPI.theme.colors.getAllLinkedColors() ?? {};
      const colorNumber = Number(colorName?.match(/color_(\d{2})/)?.[1]);
      const isAccentColor = colorNumber >= 41 && colorNumber <= 44;
      const isOldPaletteColor = isColorFromOldPalette(colorNumber);
      if (isAccentColor || isOldPaletteColor) return;
      return linkedColors[colorName as ColorName] as string;
    },
    addColorHistoryEntry(value: ColorPickerValue) {
      const isNewValue = JSON.stringify(value) !== JSON.stringify(props.value);
      if (props.enableHistory && isNewValue) {
        const entryValue = getEntryValue(value);
        editorAPI.history.add(`new color chosen - ${entryValue}`);
      }
    },
    openColorDefaultsHelp: () =>
      editorAPI.panelManager.openHelpCenter(
        colorPickerConstants.COLOR_DEFAULTS_HELP_ID,
      ),
  };
};

function getEntryValue(value: ColorPickerValue) {
  if (typeof value === 'string') {
    return value;
  }
  if (Array.isArray(value)) {
    return 'Mesh';
  }
  return 'Gradient';
}

const openDesignPanelView =
  (view: string): ThunkAction =>
  (dispatch, _, { editorAPI }) => {
    const isLightboxMode = editorAPI.dsRead.pages.popupPages.isPopupOpened();
    if (isLightboxMode) {
      editorAPI.pages.popupPages.close();
    }

    dispatch(
      stateManagement.panels.actions.openLeftPanel(
        constants.ROOT_COMPS.LEFTBAR.DESIGN_PANEL_NAME,
        {
          selectedView: view,
          origin: 'ColorPicker',
        },
      ),
    );
  };

export const mapDispatchToProps: MapDispatchToProps<
  ColorPickerPanelDispatchProps,
  ColorPickerPanelOwnProps
> = {
  stylableEditorSetUserColors: stylableEditorActions.setUserColors,
  openDesignPanelColorView: () =>
    openDesignPanelView(constants.DESIGN_PANEL.VIEWS.COLOR),
  openDesignPanelAdvancedSettingsView: () =>
    openDesignPanelView(constants.DESIGN_PANEL.VIEWS.ADVANCED_SETTINGS),
};
