:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._emptyStateContainer_7h4s7_7 {
  display: flex;
  height: 100%;
  margin: 20px 0;
  align-content: center;
  justify-content: center;
  flex-direction: column;
  pointer-events: all;
  z-index: 100; }
  ._emptyStateContainer_7h4s7_7 ._linksContainer_7h4s7_16,
  ._emptyStateContainer_7h4s7_7 > .control-text {
    color: #3b4057; }
  ._emptyStateContainer_7h4s7_7._light_7h4s7_19 ._linksContainer_7h4s7_16,
  ._emptyStateContainer_7h4s7_7._light_7h4s7_19 > .control-text {
    color: #fff; }
  ._emptyStateContainer_7h4s7_7 ._linksContainer_7h4s7_16 {
    display: inline-block;
    margin-top: 3px;
    line-height: 18px; }
    ._emptyStateContainer_7h4s7_7 ._linksContainer_7h4s7_16 > .control-text-button {
      vertical-align: bottom; }
  ._emptyStateContainer_7h4s7_7 ._presetsContainer_7h4s7_28 {
    margin-top: 18px; }
