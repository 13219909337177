const ascendReferralInfo = 'ascend-editor-panel';
const analyticsTrafficReport = 'reports/0b0118c7-98d3-4ec8-8b42-9d45bc145138';

export const tabToUrlMap: {
  [key: string]: {
    name: string;
    additionalParams?: { [key: string]: any };
  };
} = {
  business: {
    name: 'settings/business-info',
  },
  favicon: {
    name: 'settings/',
    additionalParams: {
      focus: 'favicon',
    },
  },
  'release-manager': { name: 'release-manager-client/' },
  Forms: {
    name: 'wix-forms/',
    additionalParams: { referralInfo: ascendReferralInfo },
  },
  ChatInbox: {
    name: 'chat/',
    additionalParams: { referralInfo: ascendReferralInfo },
  },
  Chat: {
    name: 'chat/',
    additionalParams: { referralInfo: ascendReferralInfo },
  },
  Inbox: {
    name: 'inbox/',
    additionalParams: { referralInfo: ascendReferralInfo },
  },
  ContactList: {
    name: 'contacts/',
    additionalParams: { referralInfo: ascendReferralInfo },
  },
  Tasks: {
    name: 'tasks/',
    additionalParams: { referralInfo: ascendReferralInfo },
  },
  Workflows: {
    name: 'workflows/',
    additionalParams: { referralInfo: ascendReferralInfo },
  },
  Automations: {
    name: 'triggers/',
    additionalParams: { referralInfo: ascendReferralInfo },
  },
  Members: {
    name: 'member-permissions/members/',
    additionalParams: { referralInfo: ascendReferralInfo },
  },
  PriceQuotes: {
    name: 'price-quotes/',
    additionalParams: { referralInfo: ascendReferralInfo },
  },
  Invoices: {
    name: 'invoices/',
    additionalParams: { referralInfo: ascendReferralInfo },
  },
  SEO: {
    name: 'seo-tools/',
    additionalParams: { referralInfo: ascendReferralInfo },
  },
  FacebookAds: {
    name: 'paid-ads/',
    additionalParams: { referralInfo: ascendReferralInfo },
  },
  EmailMarketing: {
    name: 'shoutout/',
    additionalParams: { referralInfo: ascendReferralInfo },
  },
  Coupons: {
    name: 'coupons/',
    additionalParams: { referralInfo: ascendReferralInfo },
  },
  SocialPosts: {
    name: 'social-posts/',
    additionalParams: { referralInfo: ascendReferralInfo },
  },
  VideoMaker: {
    name: 'video-maker-home/',
    additionalParams: { referralInfo: ascendReferralInfo },
  },
  MarketingIntegrations: {
    name: 'marketing-integration/',
    additionalParams: { referralInfo: ascendReferralInfo },
  },
  Analytics: {
    name: `analytics/${analyticsTrafficReport}`,
    additionalParams: { referralInfo: ascendReferralInfo },
  },
  FinancialIntegrations: {
    name: 'integration/',
    additionalParams: { referralInfo: ascendReferralInfo },
  },
  crmHome: {
    name: 'crm-home/',
    additionalParams: { referralInfo: ascendReferralInfo },
  },
  marketingHome: {
    name: 'promote-home/',
    additionalParams: { referralInfo: ascendReferralInfo },
  },
  virtualPhoneNumber: {
    name: 'virtual-numbers/',
    additionalParams: { referralInfo: ascendReferralInfo },
  },
};

interface IGetSettingsIframeUrlParams {
  baseDomain: string;
  metaSiteId: string;

  category?: string;
  tab?: string;
  excludeCategory?: boolean;

  path?: string;

  extraParams?: string[];
}

const convertPath = (
  rawPath: string,
): { path: string; searchParams: URLSearchParams } => {
  const stripSlashRe = /^\/+|\/+$/g;
  const [path, searchParamsFromPath] = rawPath
    .replace(stripSlashRe, '')
    .split('?');
  const searchParams = new URLSearchParams(searchParamsFromPath);

  return {
    path,
    searchParams,
  };
};

const convertTab = (
  tab: string,
  category: string,
  excludeCategory?: boolean,
): { path: string; searchParams: URLSearchParams } => {
  const tabUrl = tabToUrlMap[tab];
  const searchParams = new URLSearchParams();
  if (!tabUrl) {
    const categoryWithTrailingPath = excludeCategory ? '' : `${category}/`;
    return { path: `${categoryWithTrailingPath}${tab}/`, searchParams };
  }

  if (tabUrl.additionalParams) {
    Object.keys(tabUrl.additionalParams).forEach((key) => {
      searchParams.append(key, tabUrl.additionalParams[key]);
    });
  }

  return { path: tabUrl.name, searchParams };
};

export const getSettingsIframeUrl = (
  params: IGetSettingsIframeUrlParams,
): string => {
  const { path, searchParams } = params.path
    ? convertPath(params.path)
    : convertTab(params.tab, params.category, params.excludeCategory);

  searchParams.append('viewMode', 'editor');

  const search = [searchParams.toString(), ...(params.extraParams || [])].join(
    '&',
  );

  return `${params.baseDomain}/${params.metaSiteId}/${path}?${search}`;
};
