import { SafeInjectHtml } from '@/util';
import PropTypes from 'prop-types';
import * as util from '@/util';
import { translate } from '@/i18n';
import * as stateManagement from '@/stateManagement';
import React from 'react';
import { FocusPanelFrame } from '../frames';
import {
  Button,
  Composites,
  Illustration,
  PanelHeader,
  RichText,
} from '@wix/wix-base-ui';
import * as symbols from '@wix/santa-editor-symbols';
import type {
  MapStateToProps,
  MapDispatchToProps,
  ThunkAction,
} from 'types/redux';
import type { CompData, BIEventFields } from 'types/documentServices';
import * as coreBi from '@/coreBi';
import type { EditorAPI } from '@/editorAPI';

type BiFields = BIEventFields<
  typeof coreBi.events.googleTranslate.smthWentWrongPopupDisplayed['fields']
>;

interface GoogleTranslateFailedOwnProps {
  panelName: string;
  compForBi?: CompData;
  biFields?: Partial<BiFields>;
  intent: 'manual' | 'auto' | 'toolong';
}

interface GoogleTranslateFailedStateProps {
  siteId: string;
  currentLanguage: string;
  editorAPI: EditorAPI;
}

interface GoogleTranslateFailedDispatchProps {
  closePanel(): void;
  callBiDisplayed(): void;
  callBiClosed(button: 'x' | 'ok' | 'escape'): void;
}

type GoogleTranslateFailedProps = GoogleTranslateFailedStateProps &
  GoogleTranslateFailedDispatchProps &
  GoogleTranslateFailedOwnProps;

class GoogleTranslateFailed extends React.Component<GoogleTranslateFailedProps> {
  static propTypes = {
    panelName: PropTypes.string,
    compForBi: PropTypes.object,
    intent: PropTypes.oneOf(['manual', 'auto', 'toolong']),
  };

  componentDidMount() {
    this.props.callBiDisplayed();
  }

  componentWillUnmount() {
    util.fedopsLogger.interactionEnded(
      util.fedopsLogger.INTERACTIONS.CODE_ADD_PANEL_FIRST_INSTALL,
    );
  }

  getTitle() {
    switch (this.props.intent) {
      case 'toolong':
        return translate('Multilingual_Error_Google_ToolLong_Title');
      default:
        return translate('Multlingual_error_popup_GoogleNotResponding_title');
    }
  }

  getMessage() {
    switch (this.props.intent) {
      case 'toolong':
        return translate('Multilingual_Error_Google_TooLong_Description');
      default:
        return (
          <SafeInjectHtml
            tag="div"
            html={translate(
              'Multilingual_error_popup_GoogleNotResponding_message',
            )}
          />
        );
    }
  }

  getConfirmButtonLabel() {
    switch (this.props.intent) {
      case 'toolong':
        return translate('Multilingual_Error_Google_TooLong_CTA');
      default:
        return translate('Multlingual_error_popup_GoogleNotResponding_CTA');
    }
  }

  onClose = (button: 'x' | 'ok' | 'escape') => {
    this.props.callBiClosed(button);
    util.fedopsLogger.interactionStarted(
      util.fedopsLogger.INTERACTIONS.GOOGLE_TRANSLATE.FAILED_POPUP.CLOSE,
    );
    this.props.closePanel();
  };

  getKeyboardShortcuts() {
    return {
      esc: () => this.onClose('escape'),
    };
  }

  render() {
    return (
      <FocusPanelFrame
        ref="panelFrame"
        shouldHideHeader
        shouldNotCloseOnBlur
        keyboardShortcuts={this.getKeyboardShortcuts()}
        panelName={this.props.panelName}
      >
        <Composites.PopupSmallSize>
          <PanelHeader
            noHelpBtn
            onClose={() => this.onClose('x')}
            className="dark"
          >
            <span>{this.getTitle()}</span>
          </PanelHeader>

          <Composites.PanelContent>
            <Composites.RichTextWithIllustration>
              {this.props.intent !== 'toolong' && (
                <Illustration>
                  <symbols.symbol name={'googleTranslateFailedUpdate'} />
                </Illustration>
              )}

              <Composites.RichText>
                <RichText type="T01">{this.getMessage()}</RichText>
              </Composites.RichText>
            </Composites.RichTextWithIllustration>
          </Composites.PanelContent>

          <Composites.ActionSetHorizontal>
            <Button onClick={() => this.onClose('ok')} className="primary">
              <span>{this.getConfirmButtonLabel()}</span>
            </Button>
          </Composites.ActionSetHorizontal>
        </Composites.PopupSmallSize>
      </FocusPanelFrame>
    );
  }
}

const mapStateToProps: MapStateToProps<
  GoogleTranslateFailedStateProps,
  GoogleTranslateFailedOwnProps
> = ({ dsRead, editorAPI }) => {
  return {
    siteId: dsRead.generalInfo.getSiteId(),
    currentLanguage: dsRead.language.current.get(),
    editorAPI,
  };
};

const getEditorAPI: ThunkAction = (_dispatch, _getState, { editorAPI }) =>
  editorAPI;

const mapDispatchToProps: MapDispatchToProps<
  GoogleTranslateFailedDispatchProps,
  GoogleTranslateFailedOwnProps
> = (dispatch, { panelName, biFields, intent, compForBi }) => {
  const editorAPI: EditorAPI = dispatch(getEditorAPI);
  return {
    callBiDisplayed: () => {
      if (intent === 'auto') {
        editorAPI.bi.event(
          coreBi.events.googleTranslate.smthWentWrongPopupDisplayed,
          biFields,
        );
      } else {
        stateManagement.multilingual.services.multilingualBi.smthWentWrongPopupDisplayed(
          editorAPI,
          compForBi,
        );
      }
    },
    callBiClosed: (button: 'x' | 'ok' | 'escape') => {
      if (intent === 'auto') {
        editorAPI.bi.event(
          coreBi.events.googleTranslate.smthWentWrongPopupClosed,
          {
            process_type: 'auto_translate',
            button,
          },
        );
      } else {
        stateManagement.multilingual.services.multilingualBi.smthWentWrongPopupClosed(
          editorAPI,
          compForBi,
        );
      }
    },
    closePanel: () =>
      dispatch(stateManagement.panels.actions.closePanelByName(panelName)),
  };
};

export default util.hoc.connect(
  util.hoc.STORES.EDITOR_API,
  mapStateToProps,
  mapDispatchToProps,
)(GoogleTranslateFailed as React.ComponentType<GoogleTranslateFailedProps>);
