import type { Scope } from './scope';
import type { CompRef } from 'types/documentServices';
import type { ResizeType } from './types';

class ResizeHandler {
  private store;
  private resizeService;

  constructor(scope: Scope) {
    this.store = scope.store;
    this.resizeService = scope.resizeService;
  }

  onResizeStart = ({ compRef: parentCompRef }: { compRef: CompRef }) => {
    const childrensCompsPositions =
      this.resizeService.getChildrensCompsPositions(parentCompRef);

    this.store.startResize({ parentCompRef, childrensCompsPositions });
  };

  onResizeMove = ({ resizeType }: { resizeType: ResizeType }) => {
    const parentCompRef = this.store.getParentCompRef();
    const childrensCompsPositions = this.store.getChildrensCompsPositions();

    const childrensBlockingResize =
      this.resizeService.getChildrensBlockingResize(
        parentCompRef,
        childrensCompsPositions,
        { resizeType },
      );

    this.store.addHighlightedComponents(childrensBlockingResize);
  };

  onResizeEnd = () => {
    this.store.endResize();
  };
}

export const createResizeHandler = (scope: Scope) => new ResizeHandler(scope);
