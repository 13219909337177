import type { PremiumQuotaStatus } from '../../premium';

export const getTranslationKey = (
  premiumQuotaStatus: PremiumQuotaStatus,
  isMaxPremium: boolean,
) => {
  const { collectionsQuotaStatus, itemsQuotaStatus } = premiumQuotaStatus;

  const ENFORCMENT_ENABLED = false;

  if (
    collectionsQuotaStatus === 'EXCEEDED' &&
    itemsQuotaStatus === 'EXCEEDED'
  ) {
    if (isMaxPremium) {
      return 'CONTENT_MANAGER_6_BUTTON_MANAGE_CONTENT_BOTH_LIMITS_EXCEEDED_MAX_PLAN';
    }

    return ENFORCMENT_ENABLED
      ? 'CONTENT_MANAGER_6_BUTTON_MANAGE_CONTENT_BOTH_LIMITS_EXCEEDED'
      : 'CONTENT_MANAGER_6_BUTTON_MANAGE_CONTENT_BOTH_LIMITS_EXCEEDED_BEFORE_ENFORCEMENT';
  }

  if (collectionsQuotaStatus === 'EXCEEDED') {
    if (isMaxPremium) {
      return 'CONTENT_MANAGER_6_BUTTON_MANAGE_CONTENT_COLLECTIONS_LIMIT_EXCEEDED_MAX_PLAN';
    }
    return ENFORCMENT_ENABLED
      ? 'CONTENT_MANAGER_6_BUTTON_MANAGE_CONTENT_COLLECTIONS_LIMIT_EXCEEDED'
      : 'CONTENT_MANAGER_6_BUTTON_MANAGE_CONTENT_COLLECTIONS_LIMIT_EXCEEDED_BEFORE_ENFORCEMENT';
  }

  if (itemsQuotaStatus === 'EXCEEDED') {
    if (isMaxPremium) {
      return 'CONTENT_MANAGER_6_BUTTON_MANAGE_CONTENT_ITEMS_LIMIT_EXCEEDED_MAX_PLAN';
    }
    return ENFORCMENT_ENABLED
      ? 'CONTENT_MANAGER_6_BUTTON_MANAGE_CONTENT_ITEMS_LIMIT_EXCEEDED'
      : 'CONTENT_MANAGER_6_BUTTON_MANAGE_CONTENT_ITEMS_LIMIT_EXCEEDED_BEFORE_ENFORCEMENT';
  }

  if (
    collectionsQuotaStatus === 'APPROACHING' &&
    itemsQuotaStatus === 'APPROACHING'
  ) {
    return 'CONTENT_MANAGER_6_BUTTON_MANAGE_CONTENT_BOTH_LIMITS_APPROACHING';
  }

  if (collectionsQuotaStatus === 'APPROACHING') {
    return 'CONTENT_MANAGER_6_BUTTON_MANAGE_CONTENT_COLLECTIONS_LIMIT_APPROACHING';
  }

  if (itemsQuotaStatus === 'APPROACHING') {
    return 'CONTENT_MANAGER_6_BUTTON_MANAGE_CONTENT_ITEMS_LIMIT_APPROACHING';
  }
};
