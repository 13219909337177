import React from 'react';
import _ from 'lodash';
import * as util from '@/util';
import { translate } from '@/i18n';
import * as coreBi from '@/coreBi';
import {
  Button,
  ContextMenu,
  ContextMenuAction,
  Divider,
  InfoIcon,
  Text,
  TextInput,
  Tooltip,
  TooltipOnEllipsis,
} from '@wix/wix-base-ui';
import * as symbols from '@wix/santa-editor-symbols';
import { cx } from '@/util';
import { platformBiEvents } from '@/platform';
import type { BiEventDefinition, BiEventFields } from '../../../../../types/bi';
import type { PageAction } from '../../types';

// Created based on the propTypes
interface PageProps {
  dataSource: {
    id: string;
    title: string;
    managingAppDefId?: string;
    replacerOf: string;
    replacers?: string[];
    onClick: (pageId: string) => void;
    renameEnabled: boolean;
    biEvent: (event: BiEventDefinition, parameters: BiEventFields) => void;
    biCategory: string;
    subtitle?: string;
    inBracketsTitle: string;
    typeSymbol?: string;
    permissionSymbol?: string;
    isSelected?: boolean;
    info?: string;
    mountInRenameMode?: boolean;
    isDeactivated?: boolean;
    actions?: PageAction[];
  };
}

interface PageState {
  inputValue: string;
}

class Page extends React.Component<PageProps, PageState> {
  static displayName = 'Page';

  constructor(props: PageProps) {
    super(props);
    this.state = {
      inputValue: props.dataSource.mountInRenameMode
        ? props.dataSource.title
        : null,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps: PageProps) {
    const beforeRenaming =
      this.state.inputValue === this.props.dataSource.title;
    const titleUpdatedByProps =
      nextProps.dataSource.title !== this.props.dataSource.title;
    const alreadyInTheRenameMode = this.state.inputValue !== null;
    if (
      (beforeRenaming && titleUpdatedByProps) ||
      (nextProps.dataSource.mountInRenameMode !==
        this.props.dataSource.mountInRenameMode &&
        !alreadyInTheRenameMode)
    ) {
      this.setState({ inputValue: nextProps.dataSource.title });
    }
  }

  handleClickAction = (action: PageAction) => {
    if (action.type) {
      this.props.dataSource.biEvent(platformBiEvents.site_pages_actions, {
        target: action.type,
        page_id: this.props.dataSource.id,
        page_name: this.props.dataSource.title,
        app_id: this.props.dataSource.managingAppDefId,
        page_type: this.getPageType(),
        host_app_id: this.props.dataSource.biCategory,
      });
    }

    if (action.isRename) {
      this.openRenameMode();
    } else {
      action.onClick(this.props.dataSource.id, this.props.dataSource.title);
    }
  };

  getPageType = () => {
    if (this.props.dataSource.replacerOf) {
      return this.props?.dataSource?.managingAppDefId
        ? 'app_replacer_page'
        : 'custom_page';
    }
    return 'default_page';
  };

  handleContextMenuToggle = (isOpen: boolean) => {
    if (isOpen) {
      this.props.dataSource.biEvent(platformBiEvents.site_pages_details_open, {
        page_id: this.props.dataSource.id,
        page_name: this.props.dataSource.title,
        app_id: this.props.dataSource.managingAppDefId,
        page_type: this.getPageType(),
        host_app_id: this.props.dataSource.biCategory,
      });
      this.props.dataSource.biEvent(
        coreBi.events.pages.pagesPanel.page_actions_click,
        {
          page_id: this.props.dataSource.id,
          source: this.props.dataSource.biCategory,
          type: 'page',
        },
      );
    }
  };

  openRenameMode = () =>
    this.setState({ inputValue: this.props.dataSource.title });

  onDoubleClick = () => {
    const renameAction =
      this.props.dataSource.actions &&
      this.props.dataSource.actions.filter((a) => a.isRename)[0];
    if (renameAction && this.props.dataSource.renameEnabled) {
      this.openRenameMode();
    }
  };

  handleChangeInput = (inputValue: string) => this.setState({ inputValue });

  handleBlurInput = () => {
    const isValid = !util.validate.legacyPageNameValidator(
      this.state.inputValue,
    );
    const renamedValue = isValid
      ? this.state.inputValue
      : this.props.dataSource.title;

    const renameAction = this.props.dataSource.actions.filter(
      (a) => a.isRename,
    )[0];
    renameAction.onClick(this.props.dataSource.id, renamedValue);

    this.setState({ inputValue: null });
  };

  getSettingsActions = () =>
    this.props.dataSource.actions.filter((a) => a.isSettings);

  getActions = () => _.reject(this.props.dataSource.actions, 'isSettings');

  getActionSymbol = (action: PageAction) => {
    if (action?.symbol) {
      return (
        <action.symbol
          className="symbol"
          data-hook={action.symbolName ?? action.type}
        />
      );
    }
    if (action?.symbolName) {
      return <symbols.symbol name={action.symbolName} />;
    }
    return null;
  };

  render() {
    return (
      <div
        className={cx('menu-item-pp page-pp', {
          selected: this.props.dataSource.isSelected,
          'hidden-page': this.props.dataSource.isDeactivated,
        })}
      >
        {this.state.inputValue === null ? (
          <div
            key="title-container-menu-item-pp"
            onClick={() => {
              this.props.dataSource.onClick(this.props.dataSource.id);
            }}
            onDoubleClick={this.onDoubleClick}
            className="title-action-container"
          >
            <div className="left-container">
              {this.props.dataSource.typeSymbol ? (
                <symbols.symbol
                  name={this.props.dataSource.typeSymbol}
                  key="typeSymbol-pp"
                  className={cx('page-entry-symbol', {
                    deactivated: this.props.dataSource.isDeactivated,
                  })}
                />
              ) : null}
              <span className="title-text-container menu-item-title">
                <TooltipOnEllipsis marginTop={5} contentClassName="title">
                  {this.props.dataSource.inBracketsTitle
                    ? `${this.props.dataSource.title} (${this.props.dataSource.inBracketsTitle})`
                    : this.props.dataSource.title}
                </TooltipOnEllipsis>
              </span>
            </div>
            <div className="right-container">
              {this.props.dataSource.subtitle ? (
                <div className="subtitle">{this.props.dataSource.subtitle}</div>
              ) : null}
              {this.props.dataSource.permissionSymbol ? (
                <div className="permission-symbol-container">
                  <symbols.symbol
                    name={this.props.dataSource.permissionSymbol}
                    key="permissionSymbol-pp"
                  />
                </div>
              ) : null}
              {this.props.dataSource.isDeactivated ? (
                <Tooltip
                  interactive={true}
                  content="PLATFORM_flow_customization_pages_panel_inactive_page_tooltip_text"
                  className="deactivated-symbol-tooltip"
                >
                  <symbols.symbol
                    name="BlockSmall"
                    key="deactivatedSymbol-pp"
                  />
                </Tooltip>
              ) : null}
              {this.props.dataSource.info ? (
                <InfoIcon
                  className="info-icon"
                  shouldTranslate={false}
                  text={this.props.dataSource.info}
                />
              ) : null}
              {this.props.dataSource.actions &&
              this.props.dataSource.actions.length > 1
                ? (() => {
                    const pageActions = this.getActions();
                    const pageSettings = this.getSettingsActions();

                    return (
                      <ContextMenu
                        key="page-context-menu"
                        automationId="page-context-menu"
                        onToggle={this.handleContextMenuToggle}
                        className="context-menu flat page-actions"
                      >
                        {pageSettings.map((action) => (
                          <ContextMenuAction
                            onClick={() => {
                              this.handleClickAction(action);
                            }}
                            key={`action-${action.title}`}
                            automationId={`action-${action.title}`}
                            className="page-settings-action-pp"
                          >
                            {this.getActionSymbol(action)}
                            <Text
                              enableEllipsis={true}
                              shouldTranslate={false}
                              size="small"
                              weight="thin"
                              skin="secondary"
                            >
                              {action.title}
                            </Text>
                          </ContextMenuAction>
                        ))}

                        {!_.isEmpty(pageActions) && !_.isEmpty(pageSettings) ? (
                          <Divider key="divider" />
                        ) : null}

                        {pageActions.map((action) => (
                          <ContextMenuAction
                            disabled={action.disabled}
                            onClick={() => {
                              this.handleClickAction(action);
                            }}
                            key={`action-${action.title}`}
                            automationId={`action-${action.title}`}
                            className="page-custom-action-pp"
                          >
                            {this.getActionSymbol(action)}
                            <Tooltip
                              content={action.disabledText}
                              disabled={!action.disabled}
                              dataHook="disabled-action-tooltip"
                            >
                              <Text
                                enableEllipsis={true}
                                shouldTranslate={false}
                                size="small"
                                weight="thin"
                                skin="secondary"
                              >
                                <div
                                  className={
                                    action.disabled
                                      ? 'page-disabled-action-text-pp'
                                      : ''
                                  }
                                >
                                  {action.title}
                                </div>
                              </Text>
                            </Tooltip>
                          </ContextMenuAction>
                        ))}
                      </ContextMenu>
                    );
                  })()
                : null}
              {this.props.dataSource.actions &&
              this.props.dataSource.actions.length === 1
                ? (() => {
                    const action = this.props.dataSource.actions[0];

                    return (
                      <Button
                        onClick={() => {
                          this.handleClickAction(action);
                        }}
                        key="page-single-action"
                        className="single-action page-actions btn-inverted"
                      >
                        {this.getActionSymbol(action)}
                      </Button>
                    );
                  })()
                : null}
            </div>
          </div>
        ) : null}
        {this.state.inputValue !== null
          ? (() => {
              const validationInvalidMsg = translate(
                util.validate.legacyPageNameValidator(this.state.inputValue),
              );

              return (
                <div
                  key="input-container-menu-item-pp"
                  className="input-button-container"
                >
                  <TextInput
                    value={this.state.inputValue}
                    onChange={this.handleChangeInput}
                    isValid={!validationInvalidMsg}
                    invalidMessage={validationInvalidMsg}
                    shouldTranslate={false}
                    selectOnFocus={true}
                    maxLength={40}
                    onBlur={this.handleBlurInput}
                    autoSelect={true}
                  />
                  <Button
                    // @ts-expect-error
                    disabled={validationInvalidMsg}
                    className="done-button"
                  >
                    {translate('Pages_Actions_Rename_Button')}
                  </Button>
                </div>
              );
            })()
          : null}
      </div>
    );
  }
}

export default Page;
