import React, { useState, useEffect, useContext } from 'react';
import type { Dispatch, MapDispatchToProps } from 'types/redux';
import * as util from '@/util';
import { fetchAppReadme } from '../../utils/fetchAppReadme';
import { usePrivateApps } from '../privateAppsProvider/privateAppsProvider';
import type { AppData } from '../privateAppsProvider/privateAppsProvider';
import { TEST_VERSION } from '../../utils/appDetailsUtils';
import { devCenterFacade } from '@/serverFacade';

type FetchReadmeParams = Parameters<typeof fetchAppReadme>;

interface OwnProps {
  initialAppDefId?: string;
  children: React.ReactNode;
}

interface DispatchProps {
  fetchReadme: (...params: FetchReadmeParams) => Promise<string>;
}

type Props = OwnProps & DispatchProps;

interface SelectedApp {
  app?: AppData;
  codePackage?: CodePackage;
  readme?: string;
  listNotes?: string[];
  description?: string;
  isLoading: boolean;
  selectedAppReady: boolean;
  selectedVersion?: string;
}

interface CodePackage {
  id: string;
  name: string;
}

const SelectedAppContext = React.createContext<{
  selectedApp?: SelectedApp;
  setSelectedApp?: (app: AppData, version?: string) => Promise<void>;
}>({});

const getAppCodePackage = ({
  components,
}: AppData): CodePackage | undefined => {
  const codePackageData = components?.find(
    ({ type }) => type === 'CODE_PACKAGE',
  )?.data as any;
  return (
    codePackageData && {
      id: codePackageData.id,
      name: codePackageData.importName,
    }
  );
};

const SelectedAppProviderComponent: React.FC<Props> = ({
  children,
  fetchReadme,
  initialAppDefId,
}) => {
  const cancelUpdate = React.useRef(false);
  const [selectedApp, _setSelectedApp] = useState<SelectedApp>({
    isLoading: false,
    selectedAppReady: false,
  });
  const { installedApps, availableApps } = usePrivateApps();

  const getAppData = (app: AppData) =>
    devCenterFacade.getAppByVersion(
      app.appDefinitionId,
      app.version || app.latestVersion,
      app.latestVersion,
    );

  const getReadme = (app: AppData, codePackage: CodePackage) =>
    codePackage
      ? fetchReadme({
          id: app.appDefinitionId,
          version: app.version || app.latestVersion,
        }).catch(() => '')
      : '';

  const setSelectedApp = async (app: AppData, version?: string) => {
    if (cancelUpdate.current) {
      return;
    }
    const codePackage = getAppCodePackage(app);
    _setSelectedApp({
      codePackage,
      isLoading: true,
      app,
      selectedAppReady: false,
      selectedVersion: version || app.version,
    });

    try {
      const [{ listNotes, data }, readme] = await Promise.all([
        getAppData({ ...app, version }),
        getReadme({ ...app, version }, codePackage),
      ]);

      const { description } =
        data?.components.find(({ compType }) => compType === 'CODE_PACKAGE')
          ?.compData?.codePackageComponentData || {};

      _setSelectedApp((currentSelectedApp) => {
        if (currentSelectedApp.app !== app) {
          return currentSelectedApp;
        }
        return {
          app,
          listNotes,
          description,
          codePackage,
          readme,
          isLoading: false,
          selectedAppReady: !!app?.appDefinitionId,
          selectedVersion: version,
        };
      });
    } catch (e) {
      console.error(e);
      _setSelectedApp((currentSelectedApp) => {
        if (currentSelectedApp.app !== app) {
          return currentSelectedApp;
        }
        return {
          app,
          description: 'Error fetching data',
          isLoading: false,
          selectedAppReady: true,
        };
      });
    }
  };

  const setInitialApp = () => {
    const apps = [...installedApps, ...availableApps];

    const [app] = initialAppDefId
      ? apps.filter((app) => app.appDefinitionId === initialAppDefId)
      : apps;

    if (app) {
      setSelectedApp(
        app,
        app?.appFields?.installedVersion === TEST_VERSION
          ? TEST_VERSION
          : app.version,
      );
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(setInitialApp, [availableApps.length, installedApps.length]);
  useEffect(
    () => () => {
      cancelUpdate.current = true;
    },
    [],
  );

  return (
    <SelectedAppContext.Provider value={{ selectedApp, setSelectedApp }}>
      {children}
    </SelectedAppContext.Provider>
  );
};

export const useSelectedApp = () => useContext(SelectedAppContext);

const mapDispatchToProps: MapDispatchToProps<DispatchProps, {}> = (
  dispatch: Dispatch,
): DispatchProps => ({
  fetchReadme: (...fetchReadmeArgs: FetchReadmeParams) =>
    dispatch(fetchAppReadme(...fetchReadmeArgs)),
});

export const SelectedAppProvider = util.hoc.connect(
  util.hoc.STORES.EDITOR_API,
  null,
  mapDispatchToProps,
)(SelectedAppProviderComponent);
