import React from 'react';
import _ from 'lodash';
import experiment from 'experiment';
import { hoc, panelUtils, link as linkUtils, sections } from '@/util';
import { translate } from '@/i18n';
import { events } from '@/coreBi';
import { Tooltip } from '@wix/wix-base-ui';
import { ToolPanelFrame } from '../../frames';
import * as baseUI from '@/baseUI';
import type { CompRef } from 'types/documentServices';

import { NoLink } from './components/NoLink';
import { EmailLink } from './components/EmailLink/EmailLink';
import {
  ExternalLink,
  type TExternalLink,
} from './components/ExternalLink/ExternalLink';
import { PhoneLink } from './components/PhoneLink/PhoneLink';
import { WhatsAppLink } from './components/WhatsAppLink/WhatsAppLink';
import { AddressLink } from './components/AddressLink/AddressLink';
import { DocumentLink } from './components/DocumentLink/DocumentLink';
import { PopupLink } from './components/PopupLink/PopupLink';
import { EdgeAnchorLink } from './components/EdgeAnchorLink/EdgeAnchorLink';
import { PageLink } from './components/PageLink/PageLink';
import { AnchorLink } from './components/AnchorLink/AnchorLink';
import { LoginToWixLink } from './components/LoginToWixLink';
import { mapDispatchToProps, mapStateToProps } from './LinkPanel.mapper';
import {
  addProtocolToExternalUrl,
  getAnchorType,
  getLinkTargetPage,
  getPageLinkManagingAppDefId,
} from './utils';
import { LINK_TYPES } from './constants';
import type { TPageLink } from './types';

import type {
  Link as DSLink,
  AddressLink as TAddressLink,
  PagesData,
} from 'types/documentServices';
import type { TAnchorLink } from './components/AnchorLink/AnchorLink';
import type { TEdgeAnchorLink } from './components/EdgeAnchorLink/EdgeAnchorLink';
import type { TDocumentLink } from './components/DocumentLink/DocumentLink';
import type {
  LinkPanelComponentStateProps,
  LinkPanelComponentDispatchProps,
} from './LinkPanel.mapper';
import withLinkUpdate from './hoc/withLinkUpdate';

interface LinkPanelBIParams {
  targetPage: string;
  category: string;
  anchor_where_on_page: string;
  open_method: string;
  urlAddress: string;
  appDefinitionId: string;
  component_type: string;
  component_id: string;
  target_component_id: string;
  target_component_type: string;
  rel_value?: string[];
  rel_is_changed?: boolean;
  is_changed: boolean;
  origin: string;
  selection?: string;
}

const {
  connect,
  STORES: { EDITOR_API },
} = hoc;
const { linkTypeValidators } = linkUtils;

export type Link =
  | DSLink
  | TAnchorLink
  | TDocumentLink
  | TEdgeAnchorLink
  | TPageLink
  | TExternalLink;

interface LinkPanelComponentState {
  prevLink: Link;
  selectedTab: LINK_TYPES;
  link: Link;
  isValid: boolean;
  doneBtnTooltip?: string;
}

export type LinkPanelBiCategories = 'lightbox' | 'page' | 'anchor' | 'section';

export interface LinkPanelComponentProps
  extends LinkPanelComponentOwnProps,
    LinkPanelComponentStateProps,
    LinkPanelComponentDispatchProps {}

export interface LinkPanelComponentOwnProps {
  biOrigin?: string;
  actionType: string;
  showAllPopups: boolean;
  panelName: string;
  origin?: string;
  pageTabPageDropDownTitle: string;
  hideLinkTarget: boolean;
  headerTitle: string;
  hidePageLinks: boolean;
  selectedComponents: CompRef[];
  callback: () => string;
  onCancelCallback: () => void;
  defaultLinkType: LINK_TYPES;
  visibleSections: { [key in LINK_TYPES]?: boolean };
  initialTabToDisplay: LINK_TYPES;
  pagesForAnchors: PagesData[];
  pages: PagesData[];
  link: Link;
  removeOverlay?: boolean;
  onUnmountCallback?: () => void;
  expandSelection?: () => void;
  doneBtnDisabled?: boolean;
  doneBtnTooltip?: string;
  section_correlation_id?: string;
  panel_correlation_id?: string;
  showVerticalsDynamicPages?: boolean;
}

export class LinkPanelComponent extends React.Component<
  LinkPanelComponentProps,
  LinkPanelComponentState
> {
  static displayName = 'linkPanel';

  static defaultProps = {
    panelName: 'Link Panel',
    selectedComponentType: '',
  };

  private isNewLinkPanelAnchorsFlowEnabled =
    panelUtils.isNewLinkPanelAnchorsFlowEnabled();

  constructor(props: LinkPanelComponentProps) {
    super(props);
    const { initialTabToDisplay, link, doneBtnTooltip } = this.props;

    this.state = {
      prevLink: link,
      selectedTab: initialTabToDisplay,
      link,
      isValid: true,
      doneBtnTooltip,
    };
  }

  private selectTab = (newTab: LINK_TYPES) => {
    const state: LinkPanelComponentState = {
      prevLink: this.state.prevLink,
      selectedTab: newTab,
      isValid: true,
      link: null,
    };

    if (this.state.selectedTab !== newTab) {
      this.setState(state);
    }

    this.props.sendBI(events.linkPanel.link_panel_radio_button_click, {
      category: newTab,
      origin: this.props.origin,
    });
  };

  static getDerivedStateFromProps(
    props: LinkPanelComponentProps,
    state: LinkPanelComponentState,
  ) {
    const prevLink = state.prevLink || ({} as Link);

    const shouldUseState = prevLink?.id === props.link?.id;

    if (state.link === null && shouldUseState) {
      props.expandSelection?.();
      return {
        ...state,
        prevProps: null,
      } as LinkPanelComponentState;
    }

    const nextLink = shouldUseState ? state.link : props.link;
    const nextTab = shouldUseState
      ? state.selectedTab
      : props.initialTabToDisplay;
    const doneBtnTooltip = shouldUseState
      ? state.doneBtnTooltip
      : props.doneBtnTooltip;

    return {
      ...state,
      prevLink: props.link,
      selectedTab: nextTab,
      link: nextLink,
      doneBtnTooltip,
    };
  }

  private getCategory = (link: Link) => {
    if (this.state.selectedTab === LINK_TYPES.PopupLink) {
      return LINK_TYPES.PopupLink;
    }

    if (
      sections.isSectionsEnabled() &&
      this.state.selectedTab === LINK_TYPES.AnchorLink
    ) {
      return 'SectionAnchorLink';
    }

    if (
      this.isNewLinkPanelAnchorsFlowEnabled &&
      this.state.selectedTab === LINK_TYPES.AnchorLink &&
      link.type === LINK_TYPES.DynamicPageLink
    ) {
      return 'AnchorDynamicPageLink';
    }
    return link.type || 'NoLink';
  };

  private getBIParams(link: Link, prevLink: Link) {
    const { pages } = this.props;

    link = link || ({} as Link);

    let whereOnPage = '';
    let openMethod = '';
    let urlAddress = '';

    if (linkTypeValidators.isExternalLink(link)) {
      urlAddress = link.url;
      if (link.target === '_blank') {
        openMethod = 'new window';
      } else if (link.target === '_self') {
        openMethod = 'current window';
      }
    }

    if (linkTypeValidators.isAnchorLink(link)) {
      if (['SCROLL_TO_BOTTOM', 'SCROLL_TO_TOP'].includes(link.anchorDataId)) {
        whereOnPage = link.anchorDataId === 'SCROLL_TO_TOP' ? 'top' : 'bottom';
      } else {
        whereOnPage = 'anchor';
      }
    }

    const targetComp = this.props.getTargetComp(link);
    const category = this.getCategory(link);

    const biParams: LinkPanelBIParams = {
      targetPage: getLinkTargetPage(link),
      category,
      anchor_where_on_page: whereOnPage,
      open_method: openMethod,
      urlAddress,
      appDefinitionId: getPageLinkManagingAppDefId(link, pages),
      component_type: this.props.selectedComponentType,
      component_id: this.props.selectedComponents[0]?.id,
      target_component_id: targetComp?.id,
      target_component_type: targetComp?.type,
      is_changed: !_.isEqual(link, prevLink),
      origin: this.props.origin,
      ...('selection' in link && { selection: link.selection }),
    };

    if (category === 'ExternalLink' || category === 'PageLink') {
      const rel = (link as TExternalLink | TPageLink)?.rel;
      const previousRel = (prevLink as TExternalLink | TPageLink)?.rel;

      const hasRelValueChanged =
        (rel?.length && !previousRel) ||
        (previousRel?.length && !rel) ||
        (rel && previousRel && rel.length !== previousRel.length) ||
        (rel && previousRel && !rel.every((r) => previousRel.includes(r)));
      const hasRelValue = rel?.length > 0;
      if (hasRelValue) {
        biParams.rel_value = rel;
      } else {
        biParams.rel_value = null;
      }
      biParams.rel_is_changed = hasRelValueChanged;
    }

    return biParams;
  }

  private sendBIOnSave(link: Link, prevLink: Link) {
    this.props.sendBI(
      events.linkPanel.link_panel_done_click,
      this.getBIParams(link, prevLink),
    );
  }

  private logCreateButtonClicked = (category: LinkPanelBiCategories) => {
    const { selectedComponents } = this.props;
    const componentId =
      selectedComponents.length > 0 ? selectedComponents[0].id : undefined;

    this.props.sendBI(
      { src: 38, evid: 1221 },
      {
        category,
        component_type: this.props.selectedComponentType,
        component_id: componentId,
        origin: this.props.origin,
      },
    );
  };

  private cancel = () => {
    const { onCancel, closePanel, link } = this.props;

    onCancel?.(link);
    closePanel();
  };

  private done = () => {
    const { closePanel } = this.props;
    const { link, prevLink } = this.state;

    if (linkTypeValidators.isExternalLink(link)) {
      link.url = addProtocolToExternalUrl(link.url, 'http');
    }
    this.props.onSave?.(link, prevLink);
    this.sendBIOnSave(link, prevLink);
    this.logLinkCreationBI(link);
    closePanel();
  };

  private onLinkCreation = (link: Link) => {
    this.logLinkCreationBI(link);
    this.sendBIOnSave(link, null);
    return this.saveLink(link);
  };

  private sendBIAddCompFlow = (link: Link) => {
    this.logLinkCreationBI(link);
    this.sendBIOnSave(link, null);
  };

  private saveLink = (link: Link) => this.props?.onSave(link);

  private logLinkCreationBI(link: Link) {
    if (!link) {
      return;
    }

    this.props.sendBI(
      events.linkPanel.LINK_PANEL_ADD_CLICK_TO_ACTION,
      this.getBiData(link),
    );
  }

  private getBiData(link: Link) {
    const { selectedComponentType, siteId, actionType, origin } = this.props;

    const biData = {
      action_type: actionType,
      site_id: siteId,
      component_type: selectedComponentType,
      field_type: linkTypeValidators.isAnchorLink(link) ? 'anchor' : '',
      data: '',
      origin,
      ...('selection' in link && { i1: link.selection }),
    };

    if (linkTypeValidators.isPageLink(link)) {
      biData.field_type = 'page';
      biData.data = getPageLinkManagingAppDefId(link, this.props.pages);
    }

    if (linkTypeValidators.isDynamicPageLink(link)) {
      biData.field_type = 'dynamicPage';
      biData.data = getPageLinkManagingAppDefId(link, this.props.pages);
    }

    if (linkTypeValidators.isAnchorLink(link)) {
      biData.field_type = getAnchorType(link);
    }

    if (linkTypeValidators.isExternalLink(link)) {
      biData.field_type = 'url';
      biData.data = link.url;
    }

    if (linkTypeValidators.isAddressLink(link)) {
      biData.field_type = 'address';
      biData.data = link.address;
    }

    if (linkTypeValidators.isEmailLink(link)) {
      biData.field_type = 'mail';
    }

    if (linkTypeValidators.isPhoneLink(link)) {
      biData.field_type = 'phone';
      biData.data = link.phoneNumber;
    }

    if (linkTypeValidators.isWhatsAppLink(link)) {
      biData.field_type = 'whatsapp';
      biData.data = link.phoneNumber;
    }

    if (linkTypeValidators.isDocumentLink(link)) {
      biData.field_type = 'document';
    }

    return biData;
  }

  private getHelpId() {
    const { isDevModeEnabled } = this.props;
    return isDevModeEnabled
      ? '049671da-19be-4453-8f1e-a53d949a0110'
      : '650e4ba4-4aa8-4484-957a-cc9db36be6d9';
  }

  private setLink = (link: Link) => {
    this.setState({ link });
  };

  private setValidationStatus = (isValid: boolean, doneBtnTooltip?: string) =>
    this.setState({
      isValid,
      doneBtnTooltip: doneBtnTooltip || this.state.doneBtnTooltip,
    });

  componentDidMount(): void {
    this.props.fetchAppPagesInnerRoutesToStore();
  }

  componentWillUnmount() {
    this.props.onUnmountCallback?.();
  }

  render() {
    const { isValid, link, doneBtnTooltip } = this.state;
    const {
      panelName,
      origin,
      pages,
      anchors,
      biOrigin,
      pagesForAnchors,
      pageTabPageDropDownTitle,
      visibleSectionsData,
      headerTitle,
      showAllPopups,
      hideLinkTarget,
      doneBtnDisabled,
    } = this.props;

    const shouldDisableDoneBtn = doneBtnDisabled || !isValid;

    const shouldHideTabsHeader =
      visibleSectionsData.length <= 1 && experiment.isOpen('se_customMenus');

    return (
      <ToolPanelFrame
        helpId={this.getHelpId()}
        panelName={this.props.panelName}
        selectedComponentType={this.props.selectedComponentType}
        headerTitle={headerTitle || translate('LINK_PANEL_HEADER_PANE_LABEL')}
        ref="frame"
        onClose={() => {
          this.props.onCancel?.(this.props.link);
        }}
        contentWrapperClass="new-link-panel"
        automationId="link-panel"
        isPositionCentered
        removeOverlay={this.props.removeOverlay}
      >
        <baseUI.tabs.holder
          value={this.state.selectedTab}
          onChange={this.selectTab}
          ref="tabs"
          className="left radio"
        >
          {!shouldHideTabsHeader && (
            <baseUI.tabs.header>
              {visibleSectionsData.map((header) => (
                <baseUI.tabs.label
                  htmlFor={header.for}
                  key={header.key}
                  automationId={header.automationId}
                >
                  {translate(header.label)}
                </baseUI.tabs.label>
              ))}
            </baseUI.tabs.header>
          )}

          <baseUI.tabs.content>
            <baseUI.tabs.tab name="NoLink" key="NoLinkTab" className="none">
              <NoLink />
            </baseUI.tabs.tab>

            <baseUI.tabs.tab name="PageLink" key="pageLinkTab" className="page">
              <PageLink
                origin={origin}
                pageTabPageDropDownTitle={pageTabPageDropDownTitle}
                pages={pages}
                hideLinkTarget={hideLinkTarget}
                link={link}
                onLinkChange={this.setLink}
                onValidationUpdate={this.setValidationStatus}
                onCreateButtonClick={() => this.logCreateButtonClicked('page')}
                onPageAdded={this.onLinkCreation}
              />
            </baseUI.tabs.tab>

            <baseUI.tabs.tab
              name="AnchorLink"
              key="anchorLinkTab"
              className="anchor"
            >
              <AnchorLink
                anchors={anchors}
                origin={origin}
                pages={pagesForAnchors}
                pageTabPageDropDownTitle={pageTabPageDropDownTitle}
                link={link as TAnchorLink}
                panelName={panelName}
                biOrigin={biOrigin}
                onLinkChange={this.setLink}
                onValidationUpdate={this.setValidationStatus}
                onCreateButtonClick={this.logCreateButtonClicked}
                saveLink={this.saveLink}
                sendBIAddCompFlow={this.sendBIAddCompFlow}
              />
            </baseUI.tabs.tab>
            <baseUI.tabs.tab name="ExternalLink" className="web">
              <ExternalLink
                origin={this.props.origin}
                hideLinkTarget={hideLinkTarget}
                link={link}
                onLinkChange={this.setLink}
                onValidationUpdate={this.setValidationStatus}
              />
            </baseUI.tabs.tab>
            <baseUI.tabs.tab name="EmailLink" className="email">
              <EmailLink
                link={link}
                onLinkChange={this.setLink}
                onValidationUpdate={this.setValidationStatus}
              />
            </baseUI.tabs.tab>
            <baseUI.tabs.tab name="PhoneLink" className="phone">
              <PhoneLink
                link={link}
                panelName={panelName}
                onLinkChange={this.setLink}
                onValidationUpdate={this.setValidationStatus}
              />
            </baseUI.tabs.tab>
            <baseUI.tabs.tab
              name="WhatsAppLink"
              key="whatsapp"
              className="whatsapp"
            >
              <WhatsAppLink
                link={link}
                panelName={panelName}
                onLinkChange={this.setLink}
                onValidationUpdate={this.setValidationStatus}
              />
            </baseUI.tabs.tab>
            <baseUI.tabs.tab
              name="AddressLink"
              key="address"
              className="address"
            >
              <AddressLink
                link={link as TAddressLink}
                onLinkChange={this.setLink}
                onValidationUpdate={this.setValidationStatus}
              />
            </baseUI.tabs.tab>
            <baseUI.tabs.tab name="DocumentLink" className="document">
              <DocumentLink
                link={link as TDocumentLink}
                onLinkChange={this.setLink}
                onValidationUpdate={this.setValidationStatus}
              />
            </baseUI.tabs.tab>
            <baseUI.tabs.tab
              key="edge-anchors"
              name="EdgeAnchorLinks"
              className="edge-anchors"
            >
              <EdgeAnchorLink
                link={link as TEdgeAnchorLink}
                onLinkChange={this.setLink}
                onValidationUpdate={this.setValidationStatus}
              />
            </baseUI.tabs.tab>
            <baseUI.tabs.tab
              name="PopupLink"
              key="popupLinkTab"
              className="popup"
            >
              <PopupLink
                origin={origin}
                showAllPopups={showAllPopups}
                link={link as TPageLink}
                onLinkChange={this.setLink}
                onValidationUpdate={this.setValidationStatus}
                onCreateButtonClick={() =>
                  this.logCreateButtonClicked('lightbox')
                }
                onPopupAdded={this.onLinkCreation}
              />
            </baseUI.tabs.tab>
            <baseUI.tabs.tab
              name="LoginToWixLink"
              key="loginToWixTab"
              className="loginToWix"
            >
              <LoginToWixLink
                link={link}
                onLinkChange={this.setLink}
                onValidationUpdate={this.setValidationStatus}
              />
            </baseUI.tabs.tab>
          </baseUI.tabs.content>
        </baseUI.tabs.holder>
        <footer>
          <baseUI.button
            label="LINK_PANEL_CANCEL_BUTTON"
            ref="cancelButton"
            onClick={this.cancel}
            className="btn-confirm-secondary"
          />
          <Tooltip
            content={doneBtnTooltip}
            disabled={!shouldDisableDoneBtn && !doneBtnTooltip}
            isHint={true}
            interactive={true}
            closeOnMouseClick={true}
            className="link-panel-done-button-tooltip"
          >
            <baseUI.button
              label="LINK_PANEL_DONE_BUTTON"
              automationId="link-panel-done-button"
              ref="okButton"
              onClick={this.done}
              disabled={shouldDisableDoneBtn}
              className="btn-confirm-primary"
            />
          </Tooltip>
        </footer>
      </ToolPanelFrame>
    );
  }
}

export const LinkPanel = _.flow(
  connect(EDITOR_API, mapStateToProps, mapDispatchToProps),
)(LinkPanelComponent);

LinkPanel.pure = LinkPanelComponent;

export default withLinkUpdate(LinkPanel);
