// @ts-nocheck
import React from 'react';
import PropTypes from 'prop-types';
import * as util from '@/util';
import { translate } from '@/i18n';
import {
  Button,
  DropDown,
  DropDownOption,
  DropDownStickyFooter,
  SectionDivider,
  Tooltip,
  Text,
} from '@wix/wix-base-ui';
import * as quickNavigationMapper from './quickNavigationMapper';
import * as coreBi from '@/coreBi';
import * as symbols from '@wix/santa-editor-symbols';

const TooltipContent: React.FunctionComponent = () => (
  <div className="quick-navigation-pp__tooltip-content">
    <Text
      skin="secondary"
      enableEllipsis={false}
      size="medium"
      weight="bold"
      className="bubble-title-quick-navigation-pp"
    >
      {'TOPBAR_NEWPAGES_TOOLTIP_TITLE'}
    </Text>

    <Text skin="secondary" enableEllipsis={false} size="small">
      {'TOPBAR_NEWPAGES_TOOLTIP_BODY'}
    </Text>
  </div>
);

function template() {
  return (
    <Tooltip
      content={<TooltipContent />}
      marginBottom={5}
      maxWidth={220}
      closeOnMouseClick={true}
      className={util.cx('quick-navigation-pp', {
        minimized: this.props.shouldShrink,
      })}
      // it has to switch off the onMouseEnter event when the dropdown is opened because of FF issue
      openOnMouseEnter={this.state.withTooltip}
    >
      <div
        onMouseEnter={this.handleMouseEnter}
        onClick={this.onClick}
        className="quick-navigation-pp-content-wrapper"
      >
        {util.topBar.isNewTopBarEnabled() ? null : (
          <div
            onClick={this.props.closePopupPage}
            key="exit-lightbox"
            className={util.cx('exit-lightbox', {
              'active-exit-lightbox': this.props.isPopupOpened,
            })}
          >
            <span>{translate('PopUps_TopBar_Exit')}</span>
            <symbols.symbol name="zoomClose" />
          </div>
        )}
        <DropDown
          value={this.getSelectedItemId()}
          onChange={this.handleClickItem}
          shouldTranslate={false}
          searchBox={true}
          onToggle={this.handleToggle}
          forceOpen={this.state.forceOpen}
          dataLabel={translate('NewPages_QuickNav_Label')}
          optionsContainerClassName="dropdown-quick-navigation-pp"
        >
          {this.getContent()}
          {this.props.isManagePagesButtonVisible && (
            <DropDownStickyFooter closeOnClick={true}>
              <Button
                onClick={this.props.onClickManagePages}
                className="btn-text"
              >
                <span className="manage-pages-text">
                  {translate('NewPages_QuickNav_Pages_Manage_Button')}
                </span>
              </Button>
            </DropDownStickyFooter>
          )}
        </DropDown>
      </div>
    </Tooltip>
  );
}

const {
  connect,
  renderWhenMutated,
  STORES: { EDITOR_API },
} = util.hoc;

const isSelectedItemChanged = (item, changedItem) => {
  const isItemNew = !item && changedItem;
  const isItemIdChanged = item && item.id !== changedItem.id;
  const isItemRenamed =
    item && item.id === changedItem.id && item.title !== changedItem.title;
  return isItemNew || isItemIdChanged || isItemRenamed;
};

const getClassName = (item) => {
  if (item.isNested) {
    return 'nested-page';
  }
  if (item.isFolder) {
    return 'folder';
  }
  if (item.isLink) {
    return 'link';
  }
};

const createSectionDivider = (label: string, key: string) =>
  React.createElement(
    SectionDivider,
    { className: 'upper', key },
    label.toUpperCase(),
  );

const createDropDownOption = (item, key) =>
  React.createElement(DropDownOption, {
    value: item.id,
    label: item.title,
    shouldTranslate: false,
    disabled: item.isFolder || item.isLink,
    optionWrapperClassName: getClassName(item),
    key,
  });

function getSectionKey(title: string | undefined, index: number): string {
  return `${title || 'untitled'}-${index}`;
}

class QuickNavigation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: props.selectedItem ? this.props.getItems() : [],
      forceOpen: false,
      withTooltip: true,
    };
    this.handleMouseEnter = () => {
      this.setState({ items: this.props.getItems() });
      if (this.props.backToAdiTooltipVisibility) {
        this.props.closeBackToAdiTooltip();
      }
    };
    this.getContent = () => {
      const content = [];

      let sectionIndex = 0;
      let sectionKey = getSectionKey(undefined, sectionIndex);
      for (const item of this.state.items) {
        if (item.id) {
          content.push(
            createDropDownOption(item, `option-${sectionKey}-${item.id}`),
          );
        } else {
          sectionIndex += 1;
          sectionKey = getSectionKey(item.title, sectionIndex);
          content.push(
            createSectionDivider(item.title || '', `divider-${sectionKey}`),
          );
        }
      }

      return content;
    };
    this.getSelectedItemId = () => {
      if (!this.props.selectedItem) {
        return null;
      }

      return this.props.selectedItem.id;
    };
    this.handleClickItem = (id) =>
      this.props.onClickItem(this.state.items.find((item) => item.id === id));
    this.onClick = () => {
      util.editorWixRecorder.addLabel(
        `${quickNavigationMapper.PANEL_NAME} top bar clicked`,
      );
      this.props.biEvent(coreBi.events.topbar.top_bar_click, {
        category: quickNavigationMapper.PANEL_NAME,
        is_published: this.props.isSitePublished,
        state: this.props.topBarStateBIParamValue,
      });
      this.props.biEvent(coreBi.events.pages.quick_navigation_clicked, {
        origin: 'top_bar',
      });
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      isSelectedItemChanged(this.props.selectedItem, nextProps.selectedItem)
    ) {
      this.props.updateRecentlyViewedPages(nextProps.selectedItem);
      this.setState({ items: nextProps.getItems() });
    }
    const forceOpen =
      nextProps.forceOpenEventCounter > this.props.forceOpenEventCounter;
    if (forceOpen) {
      this.setState({ items: nextProps.getItems(), forceOpen: true });
    }
  }

  handleToggle = (isOpen) => {
    if (isOpen) {
      this.props.closeAllPanels();
    } else if (this.state.forceOpen) {
      this.setState({ forceOpen: false });
    }

    this.setState({ withTooltip: !isOpen });
  };

  render() {
    return template.call(this);
  }
}

QuickNavigation.displayName = 'QuickNavigation';
QuickNavigation.propTypes = {
  onClickItem: PropTypes.func.isRequired,
  onClickManagePages: PropTypes.func.isRequired,
  getItems: PropTypes.func.isRequired,
  closePopupPage: PropTypes.func,
  isPopupOpened: PropTypes.bool,
  selectedItem: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    isHomePage: PropTypes.bool.isRequired,
  }),
  updateRecentlyViewedPages: PropTypes.func.isRequired,
  forceOpenEventCounter: PropTypes.number,
  biEvent: PropTypes.func,
  closeAllPanels: PropTypes.func,
  backToAdiTooltipVisibility: PropTypes.bool,
  closeBackToAdiTooltip: PropTypes.func,
  currentPageId: PropTypes.string, // here only to force render from topbar after page navigation
  shouldShrink: PropTypes.bool,
};

const Connected = connect(
  EDITOR_API,
  quickNavigationMapper.mapStateToProps,
  quickNavigationMapper.mapDispatchToProps,
)(renderWhenMutated(QuickNavigation));
Connected.pure = QuickNavigation;

export default Connected;
