import React, { useEffect } from 'react';
import * as util from '@/util';
import { translate, i18n } from '@/i18n';
import { Text, Divider, TextButton, InfoIcon } from '@wix/wix-base-ui';
import classNames from 'classnames';
import {
  mapStateToProps,
  mapDispatchToProps,
} from './limitsNotificationBanner.mapper';
import { premiumBannerInteractions } from '../../bi';
import { Trans } from 'react-i18next';
import { getPremiumQuotaStatus } from '../../premium';
import { getTranslationKey } from './limitsNotificationBanner.utils';
import { QuotaStatus } from '../../premium';

import type {
  LimitsNotificationBannerStateProps,
  LimitsNotificationBannerDispatchProps,
} from './limitsNotificationBanner.mapper';

export interface LimitsNotificationBannerOwnProps {
  collectionCount: number;
}

interface LimitsNotificationBannerProps
  extends LimitsNotificationBannerOwnProps,
    LimitsNotificationBannerStateProps,
    LimitsNotificationBannerDispatchProps {}

const LimitsNotificationBanner: React.FC<LimitsNotificationBannerProps> = ({
  openPremiumPackagePicker,
  sendBi,
  isPremium,
  isMaxPremium,
  premiumLimits,
  collectionCount,
  itemCount,
  notificationType,
}) => {
  useEffect(() => {
    sendBiEvent('show');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sendBiEvent = (action: string) =>
    sendBi(premiumBannerInteractions, {
      action,
      environment: 'EDITOR',
      origin: 'editor_6btn_panel',
      collections_limit: collectionCountLimit,
      items_limit: itemCountLimit,
      num_of_collections: collectionCount,
      num_of_items: itemCount,
      isPremium,
      bannerType: notificationType,
      collections_quota_status: Math.round(
        (collectionCount / collectionCountLimit) * 100,
      ),
      items_quota_status: Math.round((itemCount / itemCountLimit) * 100),
    });

  const { collectionCountLimit, itemCountLimit } = premiumLimits;

  const handleUpgradeClick = () => {
    sendBiEvent('click_upgrade');
    openPremiumPackagePicker(notificationType);
  };

  const handleTooltipCTAClick = () => {
    window.open(
      translate(
        'CONTENT_MANAGER_6_BUTTON_MANAGE_CONTENT_LIMIT_ENFORCEMENT_TOOLTIP_LINK',
      ),
      '_blank',
    );
  };

  const premiumQuotaStatus = getPremiumQuotaStatus(
    premiumLimits,
    collectionCount,
    itemCount,
  );

  const getSkinType = () => {
    if (premiumQuotaStatus.itemsQuotaStatus === QuotaStatus.OK) {
      return 'skin-standard';
    } else if (premiumQuotaStatus.itemsQuotaStatus === QuotaStatus.EXCEEDED) {
      return 'skin-alert';
    }
    return 'skin-warning';
  };

  return (
    <>
      <div
        className={classNames(
          'limits-notification-banner-container',
          getSkinType(),
        )}
      >
        <Text enableEllipsis={false} size="small" shouldTranslate={false}>
          <Trans
            i18n={i18n}
            i18nKey={getTranslationKey(premiumQuotaStatus, isMaxPremium)}
            values={{
              upgradeCTA: translate(
                'CONTENT_MANAGER_6_BUTTON_MANAGE_CONTENT_UPGRADE_CTA',
              ),
              SiteApp_WixData_DataManager_collectionsLeft:
                collectionCountLimit - collectionCount,
              collectionLimit: collectionCountLimit,
              SiteApp_WixData_DataManager_itemsLeft: itemCountLimit - itemCount,
              itemsLimit: itemCountLimit,
            }}
          >
            <Text
              enableEllipsis={false}
              weight="bold"
              size="small"
              shouldTranslate={false}
            >
              <></>
            </Text>
            <TextButton
              onClick={handleUpgradeClick}
              skin="dark"
              underline="always"
              size="small"
              shouldTranslate={false}
            />
          </Trans>
        </Text>
        <InfoIcon
          text="CONTENT_MANAGER_6_BUTTON_MANAGE_CONTENT_LIMIT_ENFORCEMENT_TOOLTIP"
          linkText="CONTENT_MANAGER_6_BUTTON_MANAGE_CONTENT_LIMIT_ENFORCEMENT_TOOLTIP_CTA"
          onLinkClick={handleTooltipCTAClick}
          onTooltipOpen={() => sendBiEvent('expand')}
        />
      </div>
      <Divider direction="horizontal" long />
    </>
  );
};

export default util.hoc.connect(
  util.hoc.STORES.EDITOR_API,
  mapStateToProps,
  mapDispatchToProps,
)(LimitsNotificationBanner);
