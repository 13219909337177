// @ts-nocheck
import createReactClass from 'create-react-class';
import _ from 'lodash';
import * as core from '@/core';
import * as util from '@/util';
import { utils as themeUtils } from '@/theme';
import * as baseUI from '@/baseUI';
import * as textControls from '@/textControls';
import { translate } from '@/i18n';
import { panels, userPreferences } from '@/stateManagement';
import constants from '@/constants';
import experiment from 'experiment';

import React from 'react';
import {
  Composites,
  Divider,
  Slider,
  TextFormatting,
  TextLabel,
} from '@wix/wix-base-ui';

const {
  DEFAULT_THEME_LINE_HEIGHT,
  DEFAULT_THEME_LETTER_SPACING,
  DEFAULT_THEME_COLOR,
  DEFAULT_FONT_WEIGHT_VALUE,
  DEFAULT_THEME_FONT_VARIANT,
  DEFAULT_FONT_STYLE_VALUE,
  DEFAULT_THEME_NORMAL_VALUE,
} = textControls.constants;

const SUCCESS_SAVE_INDICATOR_DELAY = 3000;
const isLetterSpacingExperimentOpen = experiment.isOpen(
  'specs.thunderbolt.saveLetterSpacingToTextTheme',
);

const shouldHidePanelKey = constants.USER_PREFS.TEXT.SAVE_THEME.DONT_SHOW_AGAIN;

function isThemeValue(value: string | unknown) {
  return typeof value === 'string' && value.includes('font_');
}

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'fontControl',
  mixins: [baseUI.inputMixin, core.mixins.editorAPIMixin],
  render() {
    const canPerformSaveTheme = this.canPerformSaveTheme();

    return (
      <div className="font-control">
        <Divider long={false} />
        <textControls.textStyle
          ref="myStyle"
          optionsClass="advanced-style-option"
          onChange={this.onStyleChange}
          value={this.getThemeValue()}
          themeFonts={this.getThemeFonts()}
          normalizedFontsSize={this.getNormalizedFontsSize()}
          themeColors={this.getThemeColors()}
          label="CustomDesign_Text_Theme"
          className={canPerformSaveTheme ? 'custom-style' : ''}
        />
        <div
          className={util.cx({
            'save-theme-text-indicator': true,
            visible: canPerformSaveTheme || this.state.saveSuccessIndicator,
            'saving-theme': this.state.saveSuccessIndicator,
          })}
        >
          <span
            onClick={this.handleSaveToThemeClick}
            className="save-theme-button"
          >
            {translate('text_editor_theme_save2')}
          </span>
          <span className={util.cx({ 'save-success-indicator': true })}>
            {translate('text_editor_theme_saved')}
          </span>
        </div>
        <Divider long={false} />
        <textControls.fontFamily
          ref="myFont"
          onChange={this.onFontFamilyChange}
          value={this.state.font.fontFamily}
          label="CustomDesign_Text_Font"
          fonts={this.getCurrentSelectableFontsWithParams()}
        />
        <Divider long={false} />
        <Composites.SliderLabeled>
          <TextLabel value="CustomDesign_Text_FontSize" />
          <Slider
            value={this.state.font.fontSize}
            onChange={this.onFontSizeChange}
            min={this.props.min}
            max={this.props.max}
            step={1}
            unit={this.getFontSizeUnit()}
          />
        </Composites.SliderLabeled>
        <Divider long={false} />
        <Composites.TextFormatting>
          <TextFormatting.bold
            value={this.state.font.bold}
            onChange={this.onBoldClick}
          />
          <TextFormatting.italic
            value={this.state.font.italic}
            onChange={this.onItalicClick}
          />
        </Composites.TextFormatting>
      </div>
    );
  },

  getInitialState() {
    return {
      ...this.getFontStateObject(
        util.valueLink.getValueFromProps(this.props),
        util.valueLink.getOverrideValuesFromProps(this.props),
        this.getThemeFonts(),
        this.getThemeColors(),
      ),
      saveSuccessIndicator: false,
    };
  },

  UNSAFE_componentWillReceiveProps(newProps) {
    const fontStateObject = this.getFontStateObject(
      util.valueLink.getValueFromProps(newProps),
      util.valueLink.getOverrideValuesFromProps(newProps),
      this.getThemeFonts(),
      this.getThemeColors(),
    );
    this.setState(fontStateObject);
  },

  componentDidMount() {
    this.mounted = true;
  },

  componentWillUnmount() {
    this.mounted = false;
  },

  getCurrentSelectableFontsWithParams() {
    return textControls.fontUtils.getCurrentSelectableFontsWithParams(
      this.getEditorAPI(),
    );
  },
  getThemeFonts() {
    return this.props.themeAPI.fonts.getAll();
  },

  getFontObject(value, themeFonts, themeColors) {
    const fontObject = textControls.fontUtils.parseStyleFont(
      value,
      themeFonts,
      themeColors,
    );
    const fontMetaData = textControls.fontUtils.getFont(fontObject.family);
    const normalizedFontSizeMap = this.getNormalizedFontsSize();
    const isSpSupportExperimentOn = experiment.isOpen(
      'specs.responsive-editor.ScaleProportionally',
    );

    return {
      value,
      fontSize: parseInt(
        isSpSupportExperimentOn
          ? normalizedFontSizeMap[value]
          : fontObject.size,
        10,
      ),
      fontFamily: fontMetaData?.fontFamily || fontObject.family,
      bold: fontObject.weight !== DEFAULT_THEME_NORMAL_VALUE,
      italic: fontObject.style !== DEFAULT_THEME_NORMAL_VALUE,
      color: fontObject.color || DEFAULT_THEME_COLOR,
      lineHeight: fontObject.lineHeight || DEFAULT_THEME_LINE_HEIGHT,
    };
  },

  getFontStateObject(fontValue, overrideValues, themeFonts, themeColors) {
    let themeValue = Object.keys(themeFonts)[0];
    let themeValueDefault = true;
    if (themeFonts[fontValue]) {
      themeValue = fontValue;
      themeValueDefault = false;
    }

    const fontObject = this.getFontObject(fontValue, themeFonts, themeColors);

    if (overrideValues.fontSize !== undefined) {
      fontObject.fontSize = parseInt(overrideValues.fontSize, 10);
    }
    if (overrideValues.fontFamily !== undefined) {
      fontObject.fontFamily = overrideValues.fontFamily.replace(/\+/g, ' ');
    }
    if (overrideValues.fontWeight !== undefined) {
      fontObject.bold =
        overrideValues.fontWeight !== DEFAULT_THEME_NORMAL_VALUE;
    }
    if (overrideValues.fontStyle !== undefined) {
      fontObject.italic =
        overrideValues.fontStyle !== DEFAULT_THEME_NORMAL_VALUE;
    }

    return {
      font: fontObject,
      theme: this.getFontObject(themeValue, themeFonts, themeColors),
      themeValueDefault,
    };
  },

  getNormalizedFontsSize() {
    const normalizedFontThemesMap = this.props.themeAPI.fonts.getMap();
    const fontSizeObject = {};
    Object.keys(normalizedFontThemesMap).forEach((key) => {
      const font = normalizedFontThemesMap[key];
      fontSizeObject[key] = font.size;
    });
    return fontSizeObject;
  },

  getThemeColors() {
    return this.props.themeAPI.colors.getAll();
  },
  getThemeValue() {
    return this.state.theme.value;
  },

  getFontFamilyDropDownValue() {
    return this.state.fontFamilyValue && this.state.fontFamilyValue.fontFamily;
  },

  onStyleChange(newStyle) {
    util.valueLink.callOnChangeIfExists(this.props, newStyle);
  },

  onFontFamilyChange(newFamily) {
    const newState = { fontFamily: newFamily };
    const newOverrides = { fontFamily: newFamily };

    this.handleChange(newState, newOverrides);
  },

  getIsFontSizeSpx() {
    const { value, overrideValues } = this.props?.valueLink;
    const isSpxRegex = /^(-?[\d.]+)spx/;
    let fontSize = '';
    if (overrideValues && overrideValues.fontSize) {
      return isSpxRegex.test(overrideValues.fontSize);
    }
    if (isThemeValue(value)) {
      fontSize = this.getNormalizedFontsSize()[value];
    }
    return (fontSize || value)
      .split(' ')
      .map((part: string) => part.split('/'))
      .some((subPart) => isSpxRegex.test(subPart));
  },

  getFontSize(fontSize) {
    if (this.getIsFontSizeSpx()) {
      return `${fontSize}spx`;
    }
    if (`${fontSize}`.includes('px')) {
      return fontSize;
    }
    return `${fontSize}px`;
  },

  getFontSizeUnit() {
    return this.getIsFontSizeSpx() ? 'spx' : 'px';
  },

  onFontSizeChange(newSize) {
    if (newSize === this.state.font.fontSize) {
      return;
    }

    const newState = { fontSize: newSize };
    const newOverrides = { fontSize: this.getFontSize(newSize) };

    this.handleChange(newState, newOverrides);
  },

  onBoldClick() {
    const newState = { bold: !this.state.font.bold };
    const newOverrides = {
      fontWeight: newState.bold
        ? DEFAULT_FONT_WEIGHT_VALUE
        : DEFAULT_THEME_NORMAL_VALUE,
    };

    this.handleChange(newState, newOverrides);
  },

  onItalicClick() {
    const newState = { italic: !this.state.font.italic };
    const newOverrides = {
      fontStyle: newState.italic
        ? DEFAULT_FONT_STYLE_VALUE
        : DEFAULT_THEME_NORMAL_VALUE,
    };

    this.handleChange(newState, newOverrides);
  },

  convertToFontObject(innerState) {
    return {
      italic: innerState.italic,
      variant: DEFAULT_THEME_FONT_VARIANT,
      bold: innerState.bold,
      size: this.getFontSize(innerState.fontSize),
      lineHeight: DEFAULT_THEME_LINE_HEIGHT,
      family: innerState.fontFamily,
    };
  },

  canPerformSaveTheme() {
    const editorAPI = this.getEditorAPI();
    const isMobileEditor = editorAPI.isMobileEditor();
    const isColorPickerClosed = panels.selectors.isColorPickerClosed(
      editorAPI.store.getState(),
    );
    const themeFonts = this.getThemeFonts();
    const isTheme =
      this.stateIsTheme(this.state.font) && !!themeFonts[this.state.font.value];

    return (
      !isMobileEditor &&
      isColorPickerClosed &&
      !isTheme &&
      !this.props.hideSaveThemeButton
    );
  },

  stateIsTheme(font) {
    const { theme } = this.state;

    return ['fontSize', 'fontFamily', 'bold', 'italic'].every(
      (key) => theme[key] === font[key],
    );
  },

  getDefaultOverrides(font) {
    if (!this.state.themeValueDefault) {
      return {};
    }
    return {
      fontFamily: font.fontFamily,
      fontSize: this.getFontSize(font.fontSize),
      fontWeight: font.bold
        ? DEFAULT_FONT_WEIGHT_VALUE
        : DEFAULT_THEME_NORMAL_VALUE,
      fontStyle: font.italic
        ? DEFAULT_FONT_STYLE_VALUE
        : DEFAULT_THEME_NORMAL_VALUE,
    };
  },

  handleChange(newState, newOverrides) {
    const mergedState = _.merge(this.state.font, newState);
    let newVal = textControls.fontUtils.stringifyFontWithoutColor(
      this.convertToFontObject(mergedState),
    );

    const defaultOverrides = this.getDefaultOverrides(mergedState);
    const oldOverrides = util.valueLink.getOverrideValuesFromProps(this.props);

    newVal = {
      overrideValues: {
        ...defaultOverrides,
        ...oldOverrides,
        ...newOverrides,
      },
      value: this.state.theme.value,
      source: 'theme',
    };

    if (newVal.overrideValues.fontFamily) {
      newVal.overrideValues.fontFamily =
        textControls.fontUtils.replaceSpaceFontFamily(
          newVal.overrideValues.fontFamily,
        );
    }

    util.valueLink.callOnChangeIfExists(this.props, newVal);
  },

  getNextTextTheme(font, color) {
    const { stringifyFontSize, stringifyLineHeight, replaceSpaceFontFamily } =
      textControls.fontUtils;

    return {
      fontStyle: font.italic
        ? DEFAULT_FONT_STYLE_VALUE
        : DEFAULT_THEME_NORMAL_VALUE,
      fontVariant: DEFAULT_THEME_FONT_VARIANT,
      fontWeight: font.bold
        ? DEFAULT_FONT_WEIGHT_VALUE
        : DEFAULT_THEME_NORMAL_VALUE,
      fontSize: stringifyFontSize(font.fontSize),
      lineHeight: stringifyLineHeight(font.lineHeight),
      fontFamily: replaceSpaceFontFamily(font.fontFamily),
      color,
      letterSpacing: DEFAULT_THEME_LETTER_SPACING,
    };
  },

  calcThemeFont(font, color) {
    const { stringifyFontSize, replaceSpaceFontFamily, stringifyFont } =
      textControls.fontUtils;

    const fontObject = {
      italic: font.italic
        ? DEFAULT_FONT_STYLE_VALUE
        : DEFAULT_THEME_NORMAL_VALUE,
      variant: DEFAULT_THEME_FONT_VARIANT,
      bold: font.bold ? DEFAULT_FONT_WEIGHT_VALUE : DEFAULT_THEME_NORMAL_VALUE,
      size: stringifyFontSize(font.fontSize),
      lineHeight: DEFAULT_THEME_LINE_HEIGHT,
      family: replaceSpaceFontFamily(font.fontFamily),
      color,
    };
    return stringifyFont(fontObject, true);
  },

  handleSaveToThemeClick() {
    const editorState = this.getEditorAPI().store.getState();
    const isSaveThemeConfirmationEnabled =
      userPreferences.selectors.getSessionUserPreferences(shouldHidePanelKey)(
        editorState,
      ) !== true;

    if (isSaveThemeConfirmationEnabled) {
      this.openSaveThemeConfirmation();
    } else {
      this.saveTheme();
    }
  },

  async updateThemeAndResetTextToTheme(updatedThemeFont) {
    const editorAPI = this.getEditorAPI();

    if (isLetterSpacingExperimentOpen) {
      editorAPI.dsActions.theme.textThemes.update(updatedThemeFont);
    } else {
      // eslint-disable-next-line @wix/santa-editor/deprecatedFontsApi
      editorAPI.dsActions.theme.fonts.update(updatedThemeFont);
    }

    await editorAPI.dsActions.waitForChangesAppliedAsync();

    this.onStyleChange(this.state.theme.value);
  },

  saveTheme() {
    const blockValue = this.state.theme.value;
    const { font } = this.state;
    const color = themeUtils.fixThemeColorKey(font.color);
    const updatedThemeFont = {
      [blockValue]: isLetterSpacingExperimentOpen
        ? this.getNextTextTheme(font, color)
        : this.calcThemeFont(font, color),
    };

    this.updateThemeAndResetTextToTheme(updatedThemeFont);

    this.setState({ saveSuccessIndicator: true }, () => {
      window.setTimeout(() => {
        if (this.mounted) {
          this.setState({ saveSuccessIndicator: false });
        }
      }, SUCCESS_SAVE_INDICATOR_DELAY);
    });
  },

  getCurrentTextStyleName() {
    const currentStyle = Object.values(
      textControls.constants.DEFAULT_STYLES_MAP,
    ).find((style) => style.cssClass === this.state.theme.value);

    return translate(currentStyle.displayName);
  },

  openSaveThemeConfirmation() {
    this.openPanel(
      'textControls.saveTextStyleConfirmation',
      {
        onConfirm: () => {
          this.saveTheme();
        },
        textStyleName: this.getCurrentTextStyleName(),
        dontShowAgainKey: shouldHidePanelKey,
      },
      true,
    );
  },

  openPanel(
    panelName: string,
    panelProps: AnyFixMe,
    leavePanelsOpen?: boolean,
  ) {
    const editorAPI = this.getEditorAPI();
    editorAPI.store.dispatch(
      panels.actions.updateOrOpenPanel(panelName, panelProps, leavePanelsOpen),
    );
  },
});
