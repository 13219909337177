// @ts-nocheck
import constants from '@/constants';

const CONSTANTS = {
  USI_SEO_MAX_LENGTH: 35,
  NAME_MAX_LENGTH: 40,
  TITLE_SEO_MAX_LENGTH: 70,
  TITLE_TOOLTIP_MARGIN_TOP: -6,
  TITLE_TOOLTIP_MARGIN_LEFT: -376,
  KEYWORD_SEO_MAX_LENGTH: 250,
  KEYWORD_SEO_MAX_WORDS: 10,
  DESCRIPTION_SEO_MAX_LENGTH: 320,
  PASSWORD_TEMPLATE: '########',
  PASSWORD_MAX_LENGTH: 15,
  INVALID_CHARACTERS: '[!#$%^&()+=}{";:\\/><~\\]\\[]',
  PERMISSION_STATES: {
    NONE: 'none',
    PASSWORD: 'password',
    MEMBERS: 'members',
  },
  MEMBER_ACCESS_PERMISSIONS: {
    ALL: 'all',
    SPECIFIC: 'specific',
  },
  INPUT_TYPES: {
    PASSWORD: 'password',
    TEXT: 'text',
  },
  STATIC_PAGES_HELP_IDS: {},
  GOOGLE_PREVIEW_HELP_ID: '0aafdc03-cf40-40c1-8e72-fdcbd95d006c',
  AUTH_SETTINGS_PANEL_HELP_ID: {
    signUp: '4e10353d-4091-49fb-8e6e-eb6d26b43468',
    signIn: '24a93274-080f-4caa-b5c8-38df01b1b6d6',
  },
  FIND_OUT_MORE_HELP_ID: {
    signUp: '2e86c42a-98de-42c2-8d02-397fc5944687',
    signIn: '93b1837f-5562-44fa-8675-4b99a4ca3196',
  },
  PRICING_PLANS_APP_DEF_ID: '1522827f-c56c-a5c9-2ac9-00f9e6ae12d3',
  CUSTOM_ERROR_PAGE_HELP_ID: '7ff0e289-623f-4969-a0ca-4e8968096a2d',
};

CONSTANTS.STATIC_PAGES_HELP_IDS[constants.PAGE_SETTINGS_TABS.PAGE_INFO] =
  '04945784-71f2-4312-9fd5-73804fc4556d';
CONSTANTS.STATIC_PAGES_HELP_IDS[constants.PAGE_SETTINGS_TABS.LAYOUT] =
  '9a6cfe92-2d54-4107-8a46-c83ac8a51a39';
CONSTANTS.STATIC_PAGES_HELP_IDS[constants.PAGE_SETTINGS_TABS.MOBILE_LAYOUT] =
  'daaeb4e0-d9e8-4e5a-9894-b5ade0f8bd1a';
CONSTANTS.STATIC_PAGES_HELP_IDS[constants.PAGE_SETTINGS_TABS.PERMISSIONS] =
  'b893d6b5-13b3-44d0-9e11-46eecd862a87';
CONSTANTS.STATIC_PAGES_HELP_IDS[constants.PAGE_SETTINGS_TABS.SEO] =
  '5faf282c-200b-4f6b-ac3c-9cc38feb81b3';

export default Object.freeze(CONSTANTS);
