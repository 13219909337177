import { translate } from '@/i18n';
import * as coreBi from '@/coreBi';
import * as tpa from '@/tpa';
import * as popups from '@/popups';
import * as customSignupUtils from '../../../utils/customSignup';
import {
  AUTH_METHODS,
  authMapper,
  findCompRef,
  type ISettingsData,
} from './authSettingsDesktopMapper';
import type { EditorAPI } from '@/editorAPI';
import type { DSRead } from 'types/documentServices';
import {
  EditorType,
  InstallationOriginType,
  InstallInitiator,
} from '@wix/platform-editor-sdk';
import { CustomAuthenticationApiKey } from '@/apis';
import constants from '@/constants';
import { WIX_FORMS } from '@wix/app-definition-ids';

const { CUSTOM_REGISTRATION_APP_DEF_ID, CUSTOM_LOGIN_APP_DEF_ID } =
  customSignupUtils;

const installFormBuilderIfNeeded = (
  editorAPI: AnyFixMe,
  targetPageRef: AnyFixMe,
) =>
  new Promise((resolve, reject) => {
    const type = tpa.constants.APP.TYPE.PLATFORM_ONLY;
    const biInitiator = tpa.constants.BI.initiator.EDITOR;
    const options = {
      originType: tpa.constants.BI.type.ADD_APP_ADD_PANEL,
      disableAddCompleteSave: true,
    };

    const containerRef = findCompRef(
      editorAPI,
      targetPageRef,
      constants.COMP_TYPES.FORM_CONTAINER,
    );
    const callback = (response: AnyFixMe) =>
      response?.success ? resolve(response) : reject(response);
    const platformOrigin = {
      type: EditorType.Classic,
      initiator: InstallInitiator.Editor,
      info: {
        type: InstallationOriginType.PAGE_SETTINGS_PANEL,
        preset: 'registration-form',
        containerRef,
        targetPageRef,
        select_form: false,
        save_history: false,
      },
    };

    tpa.services.tpaAddAppService.addApp(
      editorAPI,
      WIX_FORMS,
      null,
      null,
      type,
      biInitiator,
      false,
      {},
      options,
      callback,
      platformOrigin,
    );
  });

const createSignupFormInLightbox = (
  { editorAPI }: AnyFixMe,
  name: AnyFixMe,
) => {
  const customAuthenticationAPI = editorAPI.host.getAPI(
    CustomAuthenticationApiKey,
  );
  const lightboxStructure = customAuthenticationAPI.getLightboxStructure();
  const targetPageRef = editorAPI.pages.popupPages.add(name, lightboxStructure);
  editorAPI.pages.navigateTo(targetPageRef.id);
  editorAPI.dsActions.waitForChangesApplied(() => {
    installFormBuilderIfNeeded(editorAPI, targetPageRef).then(() => {
      editorAPI.siteMembers.setCustomSignupPageId(targetPageRef.id);
      popups.actions.openBehaviors.removeOpenBehaviorFromAllPages(editorAPI, {
        disableHistorySave: true,
      });
      editorAPI.history.add('navigate popup- page', {
        undoShouldClosePopup: true,
      });
    });
  });
};

export default (
  {
    editorAPI,
    dsRead,
  }: {
    editorAPI: EditorAPI;
    dsRead: DSRead;
  },
  {
    navigateToVeloPanel,
  }: {
    navigateToVeloPanel: {
      navigate: (settingsData: Partial<ISettingsData>) => void;
      shouldNavigateToVeloPanel: () => boolean;
    };
  },
) => {
  const getCustomSignupPageId = () =>
    editorAPI.siteMembers.getCustomSignupPageId();
  const getCustomSignInPageId = () =>
    editorAPI.siteMembers.getCustomSignInPageId();

  const authSharedAPI = authMapper({
    editorAPI,
    dsRead,
    authMethod: AUTH_METHODS.SIGN_UP,
    getThisMethodCustomAuthPageId: getCustomSignupPageId,
    getSecondMethodCustomAuthPageId: getCustomSignInPageId,
    APP_DEF_IDS: {
      THIS_APP_DEF_ID: CUSTOM_REGISTRATION_APP_DEF_ID,
      SECOND_APP_DEF_ID: CUSTOM_LOGIN_APP_DEF_ID,
    },
    bi_quick_actions_menu_select_source: 'custom_sign_in',
    bi_panel_name: 'sign_in_settings',
    translationKeys: {
      customTitle: 'NewPages_Member_Signup_Custom_Title',
      defaultTitle: 'NewPages_Member_Signup_Default_Title',
      developerTitle: 'NewPages_Member_Signup_Developer_Title',
    },
    theOtherAuthMethod: {
      getTheOtherCustomAuthPageId: getCustomSignInPageId,
    },
    navigateToVeloPanel,
  });

  return {
    authMethod: AUTH_METHODS.SIGN_UP,
    ...authSharedAPI,
    removeCustomSignup: () => {
      editorAPI.siteMembers.setCustomSignupPageId(null);
      editorAPI.pages.navigateTo(editorAPI.dsRead.pages.getPrimaryPageId());
    },
    assignCustomSignup: (pageId: AnyFixMe) =>
      editorAPI.siteMembers.setCustomSignupPageId(pageId),
    createCustomSignup: () => {
      editorAPI.bi.event(coreBi.events.signup.create_custom_signup);
      editorAPI.panelManager.closeAllPanels();
      const customSignupPageName = translate(
        'NewPages_Member_Signup_Custom_Signup_Page_Name',
      );
      return createSignupFormInLightbox({ editorAPI }, customSignupPageName);
    },
    translationKeys: {
      defaultTopBanner: 'NewPages_Member_Signup_Default_Top_Banner',
      dropdownInfoText: 'NewPages_Panel_Signup_Top_Dropdown_Info_Text',
      dropdownTitle: 'NewPages_Panel_Signup_Top_Dropdown_Title',
      topInfoKey: 'NewPages_Member_Signup_Developer_Top_Info',
      bannerTitle: 'NewPages_Member_Signup_Custom_Signup_Banner_Title',
      bannerText: 'NewPages_Member_Signup_Custom_Signup_Banner_Text',
      disclaimer: 'NewPages_Member_Signup_Disclaimer',
    },
    helpId: 'bb75971d-c8cf-4130-bbf6-a333a831932a',
  };
};
