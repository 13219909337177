import _ from 'lodash';
import { EditorAPIKey, SelectionApiKey, ComponentsApiKey } from '@/apis';
import { ErrorReporter } from '@wix/editor-error-reporter';
import * as textControls from '@/textControls';
import constants from '@/constants';
import * as coreBi from '@/coreBi';
import { textUtils } from '@/util';
import { utils as coreUtils } from '@/core';
import { openTextLinkPanel } from './openTextLinkPanel';
import { text as stateManagementText } from '@/stateManagement';
import { TextEditorLocation } from '@/constants';
import experiments from 'experiment';

import { type Shell, Hooks } from '@/apilib';
import type { TextManager } from 'types/data';
import type { CompRef } from 'types/documentServices';
import type { EditorAPI } from '@/editorAPI';

const { selectors: textSelectors, actions: textActions } = stateManagementText;
const { updateComponentSource } = coreUtils.componentSourceFeatureUtils;

let externalTextManager: TextManager = null;

const isHtmlTooLong = (editorAPI: EditorAPI, data: AnyFixMe) => {
  const TEXT_DATA_TYPE = 'StyledText';
  const schema = editorAPI.data.getSchema(TEXT_DATA_TYPE);
  const limit = schema?.text?.maxLength;
  return limit < data.length;
};

export function createTextEditorApi(shell: Shell) {
  const editorAPI = shell.getAPI(EditorAPIKey);
  const selectionApi = shell.getAPI(SelectionApiKey);
  const componentsApi = shell.getAPI(ComponentsApiKey);
  const store = editorAPI.store;

  const hooks = {
    textComponentUpdated: Hooks.createHook<{
      compRef: CompRef;
      originalData: { text: string };
      updatedData: { text: string };
    }>(),
  };

  const {
    setCachedTextReadableLength,
    handleTextLengthExceeded,
    isNewTextLengthLimitExceeded,
  } = textControls.textLimitsUtils;

  function openTextEditor(
    textCompForEdit: CompRef,
    shouldOpenLinkPanel: boolean,
  ): void {
    const styleOnly =
      componentsApi.is.getDataEditOptions(textCompForEdit) ===
      constants.META_DATA.DATA_EDIT_OPTIONS.TEXT.STYLE_ONLY;
    store.dispatch(textActions.openTextEditor(styleOnly, shouldOpenLinkPanel));
  }

  async function runOldTextMigration(compRef: CompRef): Promise<void> {
    const isOldTextComponent = textControls.migrationUtils.isOldTextComponent(
      compRef,
      editorAPI.dsRead,
    );

    if (isOldTextComponent) {
      editorAPI.dsActions.bi.error(coreBi.errors.OLD_TEXT_MIGRATION, {});
      textControls.migrationUtils.migrateComponent(
        compRef,
        editorAPI.dsRead,
        editorAPI.dsActions,
      );
      await editorAPI.waitForChangesAppliedAsync();
    }
  }

  function setCKEditorDM() {
    //set document services in CK so we will be able to send bi errors from ck editor
    if (CKEDITOR && !CKEDITOR.wixDocumentServices) {
      CKEDITOR.wixDocumentServices = editorAPI;
    }
  }

  async function startTextEditing(
    shouldOpenLinkPanel: boolean = false,
  ): Promise<void> {
    if (!editorAPI._stageTextManager) return;

    setCKEditorDM();

    if (!textSelectors.isTextEditorOpen(store.getState())) {
      // TODO: naora 7/14/15 11:57 AM Try to not rely on selected components implementation knowledge
      const selectedComponents = selectionApi.getSelectedComponents();
      const textCompForEdit = selectedComponents[0];

      ErrorReporter.breadcrumb('Text Editor Opened', { textCompForEdit });

      await runOldTextMigration(textCompForEdit);

      openTextEditor(textCompForEdit, shouldOpenLinkPanel);
    } else if (shouldOpenLinkPanel) {
      openTextLinkPanel(editorAPI, { linkUpdate: true });
    }
  }

  function stopTextEditing(): void {
    const state = store.getState();

    if (textSelectors.isLinkPanelOpen(state)) {
      editorAPI.panelManager.closePanelByName(constants.PANEL_NAMES.LINK_PANEL);
    }

    if (textSelectors.isTextEditorOpen(state)) {
      store.dispatch(textActions.closeTextEditor());
    }
  }

  function setCurrentTextEditor(
    textManager: TextManager,
    location: TextEditorLocation = TextEditorLocation.STAGE,
  ): void {
    if (location === TextEditorLocation.STAGE) {
      editorAPI._stageTextManager = textManager;
    } else if (location === TextEditorLocation.QUICKEDIT) {
      externalTextManager = textManager;
    }
  }

  function setCurrentLinksHelper(
    linksHelper: typeof textControls.LinksHelper,
  ): void {
    editorAPI._stageLinksHelper = linksHelper;
  }

  function isEditingText(): boolean {
    return (
      !!externalTextManager || textSelectors.isTextEditorOpen(store.getState())
    );
  }

  function isEditingRepeatedTextComp(): boolean {
    const selectedComponent = selectionApi.getSelectedComponents()[0];
    if (selectionApi.getSelectedComponentType() === 'WRichText') {
      return (
        editorAPI.dsRead.deprecatedOldBadPerformanceApis.components.getRepeatedComponents(
          selectedComponent,
        ).length > 1
      );
    }
  }

  function undoText(): void {
    if (externalTextManager) {
      externalTextManager.undo();
    } else {
      editorAPI._stageTextManager.undo();
    }
  }

  function canUndoText(): boolean {
    return editorAPI._stageTextManager.hasUndo;
  }

  function redoText(): void {
    if (externalTextManager) {
      externalTextManager.redo();
    } else {
      editorAPI._stageTextManager.redo();
    }
  }

  function canRedoText(): boolean {
    return editorAPI._stageTextManager.hasRedo;
  }

  function getCurrentTextManager(): TextManager {
    return editorAPI._stageTextManager;
  }

  function getCurrentLinksHelper(): typeof textControls.LinksHelper {
    return editorAPI._stageLinksHelper;
  }

  function getValue(selectedTextComp: CompRef, scale: number = 1) {
    if (!selectedTextComp) {
      return {
        text: '',
        linkList: [],
      };
    }
    const compData = editorAPI.components.data.get(selectedTextComp);
    let { text } = compData;
    if (
      editorAPI.mobile.mobileOnlyComponents.isMobileOnlyNonNativeComponent(
        selectedTextComp,
      ) &&
      compData.text
    ) {
      // eslint-disable-next-line @wix/santa-editor/deprecatedFontsApi
      const themeFonts = editorAPI.theme.fonts.getAll();
      text = textUtils.adjustTextToCKMobileEditing(
        themeFonts,
        compData.text,
        scale,
      );
    }
    return {
      text,
      linkList: compData.linkList,
    };
  }

  function setComponentData(
    selectedTextComp: CompRef,
    scale: number,
    dataObject: AnyFixMe,
  ) {
    const originalData = editorAPI.components.data.get(selectedTextComp);
    if (originalData) {
      const originalText = originalData.text;
      const updatedData = { ...originalData };
      if (
        editorAPI.mobile.mobileOnlyComponents.isMobileOnlyNonNativeComponent(
          selectedTextComp,
        ) &&
        dataObject.text
      ) {
        // eslint-disable-next-line @wix/santa-editor/deprecatedFontsApi
        const themeFonts = editorAPI.theme.fonts.getAll();
        dataObject.text = textUtils.getTextDataFromCKMobileEditing(
          themeFonts,
          dataObject.text,
          scale,
        );
      }
      const updatedText = textUtils.replaceNbspBetweenTextsWithSpace(
        dataObject.text,
      );
      dataObject.text = updatedText;
      _.forOwn(dataObject, function (value, key) {
        updatedData[key] = value;
      });
      editorAPI.components.data.update(selectedTextComp, updatedData, true);
      if (
        experiments.isOpen('se_siteCompletionSourceData') &&
        originalText !== updatedText
      ) {
        updateComponentSource(editorAPI, {
          compRef: selectedTextComp,
          source: 'user',
        });
      }

      if (!_.isEqual(updatedData, originalData)) {
        hooks.textComponentUpdated.fire({
          compRef: selectedTextComp,
          originalData,
          updatedData,
        });
      }
    }
  }

  function handleDataChange(
    textManager: TextManager,
    selectedTextComp: CompRef,
    data: string,
    scale: number,
    linkList: string[],
  ) {
    if (isHtmlTooLong(editorAPI, data)) {
      textControls.textLimitsUtils.handleTextLengthExceeded(
        editorAPI.panelManager,
        textManager,
      );
    } else {
      setComponentData(selectedTextComp, scale, {
        text: data,
        linkList,
      });

      editorAPI.history.debouncedAdd('text data change');

      const newDataReadableLength =
        textControls.dataUtils.getTextContentFromHtml(data).length;

      if (isNewTextLengthLimitExceeded(newDataReadableLength)) {
        handleTextLengthExceeded(editorAPI.panelManager, textManager);
      }

      setCachedTextReadableLength(newDataReadableLength);
    }
  }

  return {
    startEditing: startTextEditing,
    stopEditing: stopTextEditing,
    setCurrentTextEditor,
    setCurrentLinksHelper,
    isEditingText,
    isEditingRepeatedTextComp,
    undo: undoText,
    canUndo: canUndoText,
    redo: redoText,
    canRedo: canRedoText,
    getCurrentTextManager,
    getCurrentLinksHelper,
    getValue,
    handleDataChange,
    setComponentData,
    runOldTextMigration,
    setCKEditorDM,
    hooks,
  };
}
