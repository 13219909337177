// @ts-nocheck
import _ from 'lodash';
import * as tpa from '@/tpa';
import * as util from '@/util';
import * as blog from '@/blog';
import { ErrorReporter } from '@wix/editor-error-reporter';
import * as pendingAppsService from './pendingAppsService';
import * as tpaUtils from '../utils/tpaUtils';
import tpaConstants from '../constants/constants';
import * as tpaAddRemoveDSService from './tpaAddRemoveDSService';
import * as tpaAlertsService from './tpaAlertsService';
import * as tpaAppInstallingService from './tpaAppInstallingService';
import * as superApps from '../superApps/superApps';
import superAppsConstants from '../superApps/superAppsConstants';
import * as bi from '../bi/bi';
import constants from '@/constants';
import addPanelDataConsts from '@/addPanelDataConsts';
import * as stateManagement from '@/stateManagement';
import { platformBI, PlatformOrigin } from '@/platform';
import type { EditorAPI } from '@/editorAPI';
import { isHeadlessInstallation } from '../appMarket/utils/appMarketUtils';
import experiments from 'experiment';

const platformSelectors = stateManagement.platform.selectors;
const { INSTALLED_APPS } = addPanelDataConsts.CATEGORIES_ID;
const { ADD_PANEL_NAME } = constants.ROOT_COMPS.LEFTBAR;

const platformActions = stateManagement.platform.actions;

const NEW_BLOG_APP_DEF_ID = '14bcded7-0066-7c35-14d7-466cb3f09103';
const handleAddingShoutoutApp = function (editorAPI: EditorAPI) {
  if (editorAPI.dsRead.generalInfo.isFirstSave()) {
    editorAPI.saveManager.saveInBackground(
      function () {
        superApps.openSuperAppPanel(
          'shoutout',
          true,
          constants.SUPER_APPS.SHOUTOUT_PANEL_NAME,
          'SiteApp_ShoutOut_Button_Hover_Caption',
          'SiteApp_ShoutOut_Button_Hover_Caption',
          'shoutout-panel',
          'shoutOutLeftBarIcon',
        );
      },
      function onSaveError() {
        tpaAlertsService.openSaveFailedAlertForAppMarket(editorAPI);
      },
      'handleAddingShoutoutApp',
    );
  } else {
    superApps.openSuperAppPanel(
      'shoutout',
      true,
      constants.SUPER_APPS.SHOUTOUT_PANEL_NAME,
      'SiteApp_ShoutOut_Button_Hover_Caption',
      'SiteApp_ShoutOut_Button_Hover_Caption',
      'shoutout-panel',
      'shoutOutLeftBarIcon',
    );
  }
};

const pageExists = function (editorAPI: EditorAPI, pageId: string) {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/includes
  return _.includes(editorAPI.dsRead.pages.getPageIdList(true), pageId);
};

const addWidgetApp = function (
  editorAPI: EditorAPI,
  appDefId: string,
  options,
  isPermissionsApp,
  biData,
  callback,
  platformOrigin: PlatformOrigin,
) {
  options = options || {};
  if (appDefId === superAppsConstants.SHOUTOUT.APP_DEF_ID) {
    handleAddingShoutoutApp(editorAPI);
  } else {
    options.callback = function (obj) {
      if (callback && obj.onError) {
        callback(obj);
      }
      if (obj.onError) {
        tpaAppInstallingService.onPageAppInstallingCompleted(
          editorAPI,
          null,
          appDefId,
          obj,
        );
        return;
        //TODO - add BI
      }

      const curPageId = editorAPI.dsRead.pages.getFocusedPageId();
      const isFirstAdd = !editorAPI.dsRead.tpa.app.isInstalled(appDefId, true);
      platformBI.reportBIOnPlatformAppAdded(editorAPI, {
        app_id: appDefId,
        app_name:
          editorAPI.dsRead.platform.getAppDataByAppDefId(appDefId)
            .appDefinitionName,
        origin: platformOrigin,
      });
      tpaUtils.bi.reportTpaAddedBiEvent(
        editorAPI,
        appDefId,
        options.widgetId,
        curPageId,
        obj.comp.id,
        isFirstAdd,
        isPermissionsApp,
        biData,
      );
      editorAPI.bi.tryFlushingConditionedEvents();

      if (
        options.shouldNavigate &&
        pageExists(editorAPI, options.pageId) &&
        curPageId !== options.pageId
      ) {
        editorAPI.pages.navigateTo(options.pageId, function () {
          editorAPI.panelManager.closePanelByName('tpa.compPanels.tpaSettings');
        });
      } else if (!options.pageId || curPageId === options.pageId) {
        editorAPI.panelManager.closePanelByName('tpa.compPanels.tpaSettings');
      }

      editorAPI.dsActions.waitForChangesApplied(function () {
        if (callback) {
          const appData = editorAPI.dsRead.tpa.app.getDataByAppDefId(appDefId);
          callback({
            instanceId: appData?.instanceId,
          });
        }
      });
    };
    tpaAddRemoveDSService.preAddComponent(editorAPI, appDefId, options, () => {
      options.origin = platformOrigin;
      if (options.platformOrigin) {
        delete options.platformOrigin;
      }
      options.skipActiveApps = false;
      editorAPI.store.dispatch(platformActions.installApp(appDefId, options));
    });
  }
};

const addSectionApp = function (
  editorAPI: EditorAPI,
  appDefId,
  appData,
  appName,
  options,
  isPermissionsApp,
  biData,
  callback,
  platformOrigin: PlatformOrigin,
) {
  if (!appDefId) return;
  options = options || {};
  const { widgetId } = options;

  if (editorAPI.dsRead.tpa.section.alreadyInstalled(appDefId)) {
    const onProvisionSuccess = function () {
      const sectionData =
        editorAPI.dsRead.tpa.app.getFirstAppCompPageId(appDefId);
      tpaUtils.bi.reportTpaAddedBiEvent(
        editorAPI,
        appDefId,
        widgetId,
        sectionData.pageId,
        sectionData.compId,
        true,
        isPermissionsApp,
        biData,
      );
    };
    const onError = function () {
      tpaAlertsService.openProvisionFailedAlert(
        editorAPI.panelManager.openPanel,
        appDefId,
      );
    };
    if (appData?.notProvisioned || appData?.demoMode) {
      editorAPI.saveManager.saveInBackground(
        onProvisionSuccess,
        onError,
        'addSectionApp',
      );
    }
    const data = editorAPI.dsRead.tpa.app.getFirstAppCompPageId(appDefId);
    if (data) {
      const curPageId = editorAPI.dsRead.pages.getFocusedPageId();
      if (options.shouldNavigate && data.pageId !== curPageId) {
        editorAPI.pages.navigateTo(data.pageId);
      }
    }

    editorAPI.dsRead.tpa.appMarket
      .getDataAsync(appDefId)
      .then(function (marketData) {
        editorAPI.panelManager.openPanel(
          'tpaPanels.message.sectionAlreadyInstalled',
          {
            appName,
            appIcon: marketData?.colorSvgIcon || marketData?.appIcon,
            appDefId,
          },
        );
      });
  } else {
    tpaAppInstallingService.prePageAppInstalling(editorAPI, appName, appDefId, {
      showPageAddedPanel: options.showPageAddedPanel,
    });
    options.callback = function (obj) {
      if (callback && obj.onError) {
        callback(obj);
      }
      if (options.showPageAddedPanel || obj.onError) {
        tpaAppInstallingService.onPageAppInstallingCompleted(
          editorAPI,
          appName,
          appDefId,
          obj,
        );
      }
      if (obj.onError) {
        return;
        //TODO - add BI
      }

      tpaUtils.bi.reportTpaAddedBiEvent(
        editorAPI,
        appDefId,
        options.widgetId,
        obj.page.id,
        obj.sectionId,
        true,
        isPermissionsApp,
        biData,
      );

      if (appDefId === NEW_BLOG_APP_DEF_ID) {
        if (!blog.siteHasOldBlog(editorAPI)) {
          const leftBarState = _.cloneDeep(editorAPI.store.getState().leftBar);
          leftBarState.superApps.desktop = _.reject(
            leftBarState.superApps.desktop,
            { panelName: 'blog.menuPanel' },
          );
          editorAPI.store.dispatch(
            stateManagement.services.actions.setState({
              leftBar: leftBarState,
            }),
          );
        }

        blog.registerNewBlogButton(editorAPI);
      }

      if (options.shouldNavigate) {
        editorAPI.pages.navigateTo(obj.page.id, function () {
          editorAPI.panelManager.closePanelByName('tpa.compPanels.tpaSettings');
          const sectionPointer =
            editorAPI.dsRead.tpa.section.getSectionRefByPageId(obj.page.id);
          editorAPI.selection.selectComponentByCompRef(sectionPointer);
        });
      }

      editorAPI.dsActions.waitForChangesApplied(function () {
        if (callback) {
          appData = editorAPI.dsRead.tpa.app.getDataByAppDefId(appDefId);
          const pageData = editorAPI.dsRead.pages.data.get(obj.page.id);
          callback({
            instanceId: appData?.instanceId,
            pageRef: obj.page,
            pageUriSEO: pageData?.pageUriSEO,
            title: pageData?.title,
          });
        }
      });
    };
    tpaAddRemoveDSService.preAddComponent(editorAPI, appDefId, options, () => {
      options.origin = platformOrigin;
      if (options.platformOrigin) {
        delete options.platformOrigin;
      }
      editorAPI.store.dispatch(platformActions.installApp(appDefId, options));
    });
  }
};

const addApp = function (
  editorAPI,
  appDefId,
  appName,
  appData,
  type,
  biInitiator,
  isPermissionsApp,
  biData,
  options,
  successErrorCallback = _.noop,
  platformOrigin?: PlatformOrigin,
) {
  options = _.pick(options, [
    'widgetId',
    'showPageAddedPanel',
    'x',
    'y',
    'width',
    'height',
    'shouldNavigate',
    'pageId',
    'containerRef',
    'useRelativeToContainerLayout',
    'componentOptions',
    'sourceTemplateId',
    'originType',
    'appVersion',
    'disableAddCompleteSave',
    'disableAddPanel',
    'managingAppDefId',
    'componentToAddRef',
    'resolveBeforeSave',
    'settings',
    'platformProvisionOnly',
    'headlessInstallation',
    'shouldOpenFakeProgressBar',
  ]);

  const isSilentRunning =
    editorAPI.platform.applications.isSilentInstallRunning();
  if (isSilentRunning) {
    options.isSilent = true;
  }

  const addAppCallback = async (appType, data) => {
    if (data && !(data instanceof Error)) {
      let isAutoInstallRunning;

      if (experiments.isOpen('se_wpAppMarketAutoInstall')) {
        const compRef =
          await tpa.services.tpaAutoInstallPluginService.autoInstallAppWidgetPlugin(
            editorAPI,
            data.appDefinitionId,
            data.components,
            true,
          );
        isAutoInstallRunning = !!compRef;
      }

      if (!options.disableAddPanel && !isAutoInstallRunning) {
        const hasCategoryDefinition =
          !!platformSelectors.getAddPanelCategoryDefinitionFromManifest(
            editorAPI.dsRead,
            data.appDefinitionId,
          );
        if (hasCategoryDefinition) {
          editorAPI.panelManager.openPanel(ADD_PANEL_NAME, {
            category: INSTALLED_APPS,
            sectionId: data.appDefinitionId,
          });
        }
      }

      editorAPI.openFirstTimeOrDeprecationPanel(options.componentToAddRef);
      platformBI.reportBIOnPlatformAppAdded(editorAPI, {
        app_id: appDefId,
        app_name: data.appDefinitionName || appName,
        origin: platformOrigin,
      });
      util.fedopsLogger.interactionEnded(appType);
      editorAPI.tpa.notifyAppInstalled(appDefId, {
        platformOrigin,
      });
    } else {
      data = data || {};
      data.message += ` failed installing ${appName} ${appDefId}`;
      ErrorReporter.captureException(data, {
        tags: { tpaInstallation: appName },
        extra: { options, appData, appDefId, platformOrigin },
      });
      platformBI.reportBIOnPlatformAppError(editorAPI, {
        app_name: data.appDefinitionName || appName,
        app_id: appDefId,
        error_desc: data.message,
        errorCode: 'EDITOR_ADD_APP_CALLBACK_ERROR',
        origin: platformOrigin,
      });
    }

    pendingAppsService.updateNotification(editorAPI);

    successErrorCallback(data);
  };
  const provisionPlatformApp = function () {
    util.fedopsLogger.interactionStarted(
      util.fedopsLogger.INTERACTIONS.ADD_TPA_PLATFORM,
      {
        paramsOverrides: {
          app_id: appDefId,
        },
      },
    );
    platformBI.reportBIOnPlatformTryToInstall(editorAPI, {
      app_id: appDefId,
      app_name: appName,
      origin: platformOrigin,
    });
    tpaUtils.bi.reportBIOnAppIntent(
      editorAPI,
      bi.events.APP_INTENT,
      appDefId,
      biInitiator,
      options.originType || tpaConstants.BI.type.ADD_APP,
    );
    tpaAddRemoveDSService.addPlatform(
      editorAPI,
      appDefId,
      // eslint-disable-next-line you-dont-need-lodash-underscore/assign
      _.assign(
        {
          origin: platformOrigin,
          callback: _.partial(
            addAppCallback,
            util.fedopsLogger.INTERACTIONS.ADD_TPA_PLATFORM,
          ),
          biData,
        },
        _.pick(options, [
          'appVersion',
          'disableAddCompleteSave',
          'sourceTemplateId',
          'resolveBeforeSave',
          'isSilent',
          'settings',
          'headlessInstallation',
          'shouldOpenFakeProgressBar',
        ]),
      ),
    );
  };
  if (!appData) {
    appData = editorAPI.platform.getAppDataByAppDefId(appDefId);
  }

  if (options.platformProvisionOnly || isHeadlessInstallation(appData)) {
    provisionPlatformApp();
    return;
  }

  switch (type) {
    case tpaConstants.APP.TYPE.SECTION:
      util.fedopsLogger.interactionStarted(
        util.fedopsLogger.INTERACTIONS.ADD_TPA_SECTION,
        {
          paramsOverrides: {
            app_id: appDefId,
          },
        },
      );
      platformBI.reportBIOnPlatformTryToInstall(editorAPI, {
        app_id: appDefId,
        app_name: appName,
        origin: platformOrigin,
      });
      tpaUtils.bi.reportBIOnAppIntent(
        editorAPI,
        bi.events.APP_INTENT,
        appDefId,
        biInitiator,
        tpaConstants.BI.type.ADD_APP,
      );
      addSectionApp(
        editorAPI,
        appDefId,
        appData,
        appName,
        options,
        isPermissionsApp,
        biData,
        _.partial(
          addAppCallback,
          util.fedopsLogger.INTERACTIONS.ADD_TPA_SECTION,
        ),
        platformOrigin,
      );
      break;
    case tpaConstants.APP.TYPE.WIDGET:
      util.fedopsLogger.interactionStarted(
        util.fedopsLogger.INTERACTIONS.ADD_TPA_WIDGET,
        {
          paramsOverrides: {
            app_id: appDefId,
          },
        },
      );
      platformBI.reportBIOnPlatformTryToInstall(editorAPI, {
        app_id: appDefId,
        app_name: appName,
        origin: platformOrigin,
      });
      tpaUtils.bi.reportBIOnAppIntent(
        editorAPI,
        bi.events.APP_INTENT,
        appDefId,
        biInitiator,
        tpaConstants.BI.type.ADD_APP,
      );
      addWidgetApp(
        editorAPI,
        appDefId,
        options,
        isPermissionsApp,
        biData,
        _.partial(
          addAppCallback,
          util.fedopsLogger.INTERACTIONS.ADD_TPA_WIDGET,
        ),
        platformOrigin,
      );
      break;
    case tpaConstants.APP.TYPE.APP_PART:
      handleAddingOldBlog(
        editorAPI,
        type,
        biData,
        options,
        successErrorCallback,
      );
      break;
    case tpaConstants.APP.TYPE.PLATFORM_ONLY:
      provisionPlatformApp();
      break;
    default:
      //TODO - add bi
      throw new Error(`Error: ${type} is unknown tpa type`);
  }
};

const handleAddingOldBlog = function (
  editorAPI,
  type,
  biData,
  options,
  onSuccess,
) {
  options = options || {};
  if (!blog.siteHasOldBlog(editorAPI)) {
    blog.installApp(
      editorAPI,
      null,
      function onBlogInstallationComplete(pageId) {
        reportBlogInstalledBIEvent(editorAPI, pageId, type, biData);
        blog.unregisterNewBlogButton(editorAPI);
        if (onSuccess) {
          onSuccess();
        }
      },
    );
  } else {
    const blogPageId = blog.getBlogPageId(editorAPI);
    const curPageId = editorAPI.dsRead.pages.getFocusedPageId();
    if (options.shouldNavigate && blogPageId !== curPageId) {
      editorAPI.pages.navigateTo(blogPageId);
    }
  }
};

const reportBlogInstalledBIEvent = function (editorAPI, pageId, type, biData) {
  const blogMainAppPartCompRef = blog.getBlogMainAppPartCompRef(
    editorAPI,
    pageId,
  );
  tpaUtils.bi.reportAddComponentBIEvent(
    editorAPI,
    blogMainAppPartCompRef.id,
    type,
    biData,
  );
};

const isAppInstalled = function (
  editorAPI: EditorAPI,
  appDefinitionId: string,
  includingDemoMode: boolean,
) {
  if (
    appDefinitionId === superAppsConstants.BLOG.APP_DEF_ID &&
    blog.siteHasOldBlog(editorAPI)
  ) {
    return true;
  }
  return editorAPI.dsRead.tpa.app.isInstalled(
    appDefinitionId,
    !includingDemoMode,
  );
};

export { addApp, isAppInstalled };
