import React from 'react';
import { translate } from '@/i18n';
import { RichText, Text } from '@wix/wix-base-ui';

export interface PanelData {
  body: string;
  title?: string;
  keyText?: string;
  linkText?: string;
  linkAction?: () => void;
  secondaryLinkText?: string;
  secondaryLinkAction?: () => void;
}

interface TextAndLinkPanelProps {
  panelData: PanelData;
}

function TextAndLinkPanel(props: TextAndLinkPanelProps) {
  return (
    <div className="drop-panel-content text-and-link-drop-panel">
      <div className="drop-panel-title">
        <Text skin="secondary" enableEllipsis={false} weight="bold">
          {props.panelData.title}
        </Text>
      </div>

      <RichText className="drop-panel-body">
        <span>{translate(props.panelData.body)}</span>
        {props.panelData.keyText ? (
          <span
            key="autosaveTooltipShortcut"
            className="drop-panel-body-autosave-keyText"
          >
            {props.panelData.keyText}
          </span>
        ) : null}
        {props.panelData.linkText ? (
          <a
            key="link"
            onClick={props.panelData.linkAction}
            className="drop-panel-link"
          >
            {translate(props.panelData.linkText)}
          </a>
        ) : null}
        {props.panelData.secondaryLinkText ? (
          <a
            key="secondary-link"
            onClick={props.panelData.secondaryLinkAction}
            className="drop-panel-secondary-link"
          >
            {translate(props.panelData.secondaryLinkText)}
          </a>
        ) : null}
      </RichText>
    </div>
  );
}

export default TextAndLinkPanel;
