import * as util from '@/util';
import * as coreBi from '@/coreBi';
import constants from '@/constants';
import { fedopsLogger, biLogger } from '@/util';
import {
  getFontsUrls,
  getSiteThemeMap,
  getEnrichedCategory,
  getPreviewerPreviewMaxHeight,
  getPreviewComponentsEntryFromStructure,
  convertBusinessTypeToBiString,
} from '../addPresetUtil';
import {
  origin,
  getPanelCorrelationId,
  isCreateSectionWithAIEnabled as createSectionWithAIEnabled,
  getAddSectionPanelLanguage,
} from './addSectionPanelUtil';
import {
  addSectionPreset,
  loadSavedSections,
  getBlankSectionPresetDefinition,
  getBlankSectionCategoryDefinition,
  loadSections,
} from '../addPresetApi';
import {
  CreateWithAICategory,
  SavedSectionCategory,
  SavedSectionCategoryDivider,
  SECTION_SCALE_FACTOR,
} from './consts';
import { ErrorReporter } from '@wix/editor-error-reporter';
import { myElementsAddElementToStage } from '@wix/bi-logger-editor/v2';
import { ADD_SECTION_PANEL_CONTENT_WIDTH } from '../addPresetConsts';

import type { EditorAPI } from '@/editorAPI';
import type { Point } from '@/rEditor';
import type { WithStartItemDrag } from '@/util';
import type { AddPresetScope } from '../addPresetApiEntry';
import type {
  ThemeMap,
  SectionPresetDefinition,
  SectionCategoryDefinition,
  PreviewComponentsEntry,
} from '../types';
import type { CompRef, CompStructure } from 'types/documentServices';
import type { EditorInteractionName } from 'types/fedops';

export type OnSectionDragEndHandler = (
  attachCandidate: CompRef,
  structure: Promise<Response>,
  point: Point,
  categoryDefinition: SectionCategoryDefinition,
  sectionDefinitions: SectionPresetDefinition,
  stageEntryIndex: number,
  compStructure?: CompStructure | null,
) => Promise<void>;

export type OnSectionClickHandler = (
  sectionDefinitions: SectionPresetDefinition,
  categoryDefinition: SectionCategoryDefinition,
  stageEntryIndex: number,
  compStructure?: CompStructure | null,
) => Promise<void>;

export type OnSectionAddFromContextMenuHandler = (
  sectionDefinitions: SectionPresetDefinition,
  categoryDefinition: SectionCategoryDefinition,
  stageEntryIndex: number,
  shouldApplyOriginTheme: boolean,
  compStructure?: CompStructure | null,
) => Promise<void>;

export interface AddSectionPanelOwnProps extends WithStartItemDrag {
  origin?: string;
  category?: string;
  panelName?: string;
  stageEntryIndex?: number;
  emptyStateSectionReplacement?: boolean;
  emptyStateBlankSectionIndex?: number;
  openPanelInteraction?: ReturnType<typeof fedopsLogger.mapInteraction>;
  onSectionAddedToStage?: (sectionRef: CompRef) => void;
  onClose?: () => void;
}

export interface AddSectionPanelStateProps {
  language: string;
  currentSiteThemeMap: ThemeMap;
  currentSiteFontsUrls: string[];
  initialSelectedCategory: number;
  categories: SectionCategoryDefinition[];
  savedSections: SectionPresetDefinition[];
  isCreateSectionWithAIEnabled: boolean;
  previewerStartLoadTime: number;
  isContentInjectionAvailableForSections: boolean;
}

export interface AddSectionPanelDispatchProps {
  loadSections: (
    categoryId: string,
    language: string,
  ) => Promise<SectionPresetDefinition[]>;
  getPreviewComponentsEntry: (
    sections: SectionPresetDefinition[],
    onPreviewReady: (
      sectionId: string,
      fedopsInteractionKey: EditorInteractionName,
      overridePresetReady?: boolean,
    ) => void,
    shouldWaitForImagesToLoad: boolean,
    onPreviewReadyWithImages: (sectionId: string) => void,
    category?: SectionCategoryDefinition,
    overridePresetReady?: boolean,
  ) => Promise<PreviewComponentsEntry>;
  cleanContentLockCategoryContent: (
    presetIds: string[],
    isPage: boolean,
  ) => void;
  openHelpCenter: GetFieldType<EditorAPI, 'panelManager.openHelpCenter'>;
  getBlankSectionStructure: ({
    height,
    width,
    y,
  }: {
    height: number;
    width: number;
    y: number;
  }) => CompStructure;
  sendSectionsLoadedPhaseEvent: (
    duration: number,
    category: string,
    phaseName: string,
  ) => void;
  addBlankSectionStructure: () => Promise<void>;
  onSectionDragEnd: OnSectionDragEndHandler;
  onSectionClick: OnSectionClickHandler;
  onSectionAddFromContextMenu: OnSectionAddFromContextMenuHandler;
  updatePanelOpenCount: () => void;
  sendCategoryLoadBiEvent: (categoryName: string) => void;
  setPanelSelectedCategory: (selectedCategory: number) => void;
  sendContentScrolledEvent: (scrollTop: number, categoryTitle: string) => void;
  sendSectionsTooltipHoverEvent: (duration: number, category: string) => void;
  loadSavedSections: () => Promise<SectionPresetDefinition[]>;
  enableMeasureMaps: () => void;
  waitForZoomOutAndDisableMeasureMaps: () => void;
}

const getCategories = (scope: AddPresetScope, editorAPI: EditorAPI) => {
  const categoriesFromStore = scope.store.getSectionsCategories();

  const createSectionWithAIMenuEntries = createSectionWithAIEnabled(editorAPI)
    ? [CreateWithAICategory]
    : [];

  return [
    ...createSectionWithAIMenuEntries,
    ...categoriesFromStore,
    SavedSectionCategoryDivider,
    SavedSectionCategory,
  ];
};

const getFirstTrueSectionsCategoryIndex = (
  categories: SectionCategoryDefinition[],
): number => categories.findIndex(({ order }) => order >= 0);

export const mapStateToProps = (
  scope: AddPresetScope,
  ownProps: AddSectionPanelOwnProps,
): AddSectionPanelStateProps => {
  const { editorAPI, contentInjectionAPI } = scope;
  const categories = getCategories(scope, editorAPI).map((category, i) =>
    getEnrichedCategory(editorAPI, category, i),
  );
  const categoryPropIndex =
    ownProps.category &&
    categories.findIndex(
      ({ title }) => title?.toLowerCase() === ownProps.category.toLowerCase(),
    );
  const isCreateSectionWithAIEnabled = createSectionWithAIEnabled(editorAPI);
  const defaultSelectedCategoryIndex = isCreateSectionWithAIEnabled
    ? getFirstTrueSectionsCategoryIndex(categories)
    : scope.store.getSectionsSelectedCategory();
  const selectedCategoryIndex =
    Number.isInteger(categoryPropIndex) && categoryPropIndex >= 0
      ? categoryPropIndex
      : defaultSelectedCategoryIndex;
  return {
    categories,
    currentSiteFontsUrls: getFontsUrls(editorAPI),
    initialSelectedCategory: selectedCategoryIndex,
    currentSiteThemeMap: getSiteThemeMap(editorAPI),
    language: getAddSectionPanelLanguage(),
    isCreateSectionWithAIEnabled,
    savedSections: scope.store.getSavedSections(),
    previewerStartLoadTime: performance.now(),
    isContentInjectionAvailableForSections:
      contentInjectionAPI.isContentInjectionAvailableForSections(),
  };
};

export const mapDispatchToProps = (
  scope: AddPresetScope,
  ownProps: AddSectionPanelOwnProps,
): AddSectionPanelDispatchProps => {
  const {
    editorAPI,
    sectionsAPI,
    hooks,
    contentInjectionAPI,
    siteGlobalDataAPI,
  } = scope;
  const blankSectionPresetDefinition = getBlankSectionPresetDefinition();
  const blankSectionCategoryDefinition = getBlankSectionCategoryDefinition();
  const addOrigin = ownProps.origin || origin;

  const { industryId, structureId } = siteGlobalDataAPI.getBusinessType();

  return {
    loadSections: async (
      categoryId: string,
      language: string,
    ): Promise<SectionPresetDefinition[]> =>
      loadSections(scope, categoryId, language),
    getPreviewComponentsEntry: async (
      sections: SectionPresetDefinition[],
      onPreviewReady: (
        sectionId: string,
        fedopsInteractionKey: EditorInteractionName,
        overridePresetReady?: boolean,
      ) => void,
      shouldWaitForImagesToLoad: boolean,
      onPreviewReadyWithImages: (sectionId: string) => void,
      category?: SectionCategoryDefinition,
      overridePresetReady?: boolean,
    ): Promise<PreviewComponentsEntry> => {
      const previewerSections: SectionPresetDefinition[] = sections.filter(
        (section) => section.shouldUsePreviewer,
      );
      if (!previewerSections.length) {
        return {};
      }

      const compStructures = await Promise.all(
        previewerSections.map(({ presetJsonUrl }) =>
          util.http.fetchJson(presetJsonUrl),
        ),
      );

      const compDefsAndStructures = previewerSections.map(
        (section: SectionPresetDefinition, index: number) => {
          return { ...section, injectedStructure: compStructures[index] };
        },
      );

      if (contentInjectionAPI.isContentInjectionAvailableForSections()) {
        contentInjectionAPI.setIsSectionsContentInjected(false);

        util.fedopsLogger.interactionStarted(
          util.fedopsLogger.INTERACTIONS.CONTENT_INJECTION
            .CONTENT_INJECTION_SECTION_CATEGORY_INJECTION,
          {
            customParams: {
              industry_id: industryId,
              structure_id: structureId,
              category_name: category?.title,
              category_id: category?.id,
            },
          },
        );
      }

      const injectedCompDefsAndStructures = compDefsAndStructures.map(
        (compDefAndStructure) => {
          if (
            contentInjectionAPI.isContentInjectionAvailableForSections() &&
            compDefAndStructure.contentInjectionMap?.length
          ) {
            return {
              ...compDefAndStructure,
              injectedStructure: contentInjectionAPI.getInjectedPreset(
                compDefAndStructure.injectedStructure,
                compDefAndStructure.contentInjectionMap,
                compDefAndStructure?._id,
              ),
            };
          }
          return compDefAndStructure;
        },
      );

      if (contentInjectionAPI.isContentInjectionAvailableForSections()) {
        contentInjectionAPI.setIsSectionsContentInjected(true);

        util.fedopsLogger.interactionEnded(
          util.fedopsLogger.INTERACTIONS.CONTENT_INJECTION
            .CONTENT_INJECTION_SECTION_CATEGORY_INJECTION,
          {
            customParams: {
              industry_id: industryId,
              structure_id: structureId,
              category_name: category?.title,
              category_id: category?.id,
            },
          },
        );
      }

      const previewerReactComponents =
        await scope.previewerApi.createPreviewComponents({
          previewsData: injectedCompDefsAndStructures.map(
            (injectedCompDefAndStructure: any, index: number) => {
              return {
                structure: injectedCompDefAndStructure?.injectedStructure,
                displayWidth: ADD_SECTION_PANEL_CONTENT_WIDTH,
                targetDefaultHeight: getPreviewerPreviewMaxHeight(
                  previewerSections[index],
                ),
                onReady: () =>
                  onPreviewReady(
                    previewerSections[index]?._id,
                    util.fedopsLogger.INTERACTIONS.ADD_SECTION_PREVIEW_LOAD
                      .ADD_SECTION_PREVIEWER_PREVIEW_LOAD,
                    overridePresetReady,
                  ),
                shouldWaitForImagesToLoad,
                onReadyWithImages: () =>
                  onPreviewReadyWithImages(previewerSections[index]?._id),
              };
            },
          ),
          scaleValue: SECTION_SCALE_FACTOR,
        });
      const previewComponentsEntry = getPreviewComponentsEntryFromStructure(
        injectedCompDefsAndStructures,
        previewerReactComponents,
      );
      return previewComponentsEntry;
    },
    cleanContentLockCategoryContent: (presetIds: string[], isPage: boolean) => {
      contentInjectionAPI.cleanLockCategoryContent(
        presetIds,
        window.editorModel.metaSiteId,
        isPage,
      );
    },
    loadSavedSections: () => loadSavedSections(scope),
    openHelpCenter: editorAPI.panelManager.openHelpCenter,
    getBlankSectionStructure: sectionsAPI.getBlankSectionStructure,
    onSectionDragEnd: async (
      attachCandidate: CompRef,
      structurePromise: Promise<Response>,
      point: Point,
      categoryDefinition: SectionCategoryDefinition,
      sectionDefinitions: SectionPresetDefinition,
      stageEntryIndex: number,
      compStructure?: CompStructure | null,
      itemIndex?: number,
    ) => {
      const pageRef = editorAPI.pages.getFocusedPage();

      try {
        util.fedopsLogger.interactionStarted(
          util.fedopsLogger.INTERACTIONS.ADD_SECTION_PANEL_ADD_SECTION_DRAG,
        );

        hooks.sections.addPresetStart.fire(true);

        const sectionRef = await addSectionPreset(
          scope,
          pageRef,
          compStructure || structurePromise,
          stageEntryIndex,
          categoryDefinition,
          sectionDefinitions,
          ownProps,
          'drag',
          addOrigin,
          true,
          itemIndex,
        );

        editorAPI.components.hooks.componentAddedToStage.fire({
          compRef: sectionRef,
          origin: 'sectionPanel',
          type: 'drag',
        });

        util.fedopsLogger.interactionEnded(
          util.fedopsLogger.INTERACTIONS.ADD_SECTION_PANEL_ADD_SECTION_DRAG,
        );
      } catch (e) {
        const flow = 'addSectionOnDrag';
        const presetId = sectionDefinitions.title;
        const category = categoryDefinition.title;
        const app_list = sectionDefinitions.appIds.join(',');
        ErrorReporter.captureException(e, {
          tags: { addSectionFlow: true, addSectionOnDrag: true },
          extra: {
            flow,
            structurePromise,
            presetId,
            category,
            appsToInstall: app_list,
          },
        });
      } finally {
        hooks.sections.addPresetEnd.fire();
      }
    },
    onSectionClick: async (
      sectionPresetDefinition: SectionPresetDefinition,
      categoryDefinition: SectionCategoryDefinition,
      stageEntryIndex: number,
      compStructure?: CompStructure | null,
      itemIndex?: number,
    ) => {
      const structurePromise =
        compStructure || util.http.fetch(sectionPresetDefinition.presetJsonUrl);
      const pageRef = editorAPI.pages.getFocusedPage();
      try {
        util.fedopsLogger.interactionStarted(
          util.fedopsLogger.INTERACTIONS.ADD_SECTION_PANEL_ADD_SECTION_CLICK,
        );

        hooks.sections.addPresetStart.fire(true);

        const sectionRef = await addSectionPreset(
          scope,
          pageRef,
          structurePromise,
          stageEntryIndex,
          categoryDefinition,
          sectionPresetDefinition,
          ownProps,
          'click',
          addOrigin,
          true,
          itemIndex,
        );

        editorAPI.components.hooks.componentAddedToStage.fire({
          compRef: sectionRef,
          origin: 'sectionPanel',
          type: 'click',
        });

        util.fedopsLogger.interactionEnded(
          util.fedopsLogger.INTERACTIONS.ADD_SECTION_PANEL_ADD_SECTION_CLICK,
        );
      } catch (e) {
        const flow = 'addSectionOnClick';
        const presetId = sectionPresetDefinition.title;
        const category = categoryDefinition.title;
        const app_list = sectionPresetDefinition.appIds.join(',');
        ErrorReporter.captureException(e, {
          tags: { addSectionFlow: true, addSectionOnClick: true },
          extra: {
            flow,
            structurePromise,
            presetId,
            category,
            appsToInstall: app_list,
          },
        });
      } finally {
        hooks.sections.addPresetEnd.fire();
      }
    },
    sendCategoryLoadBiEvent: (categoryName: string) => {
      editorAPI.bi.event(
        coreBi.events.sections.add_section_panel.category_loaded,
        {
          category: categoryName,
          correlation_id: getPanelCorrelationId(
            scope.store.getSectionPanelOpenCount(),
          ),
          origin: addOrigin,
        },
      );
    },
    setPanelSelectedCategory: (selectedCategory: number) => {
      scope.store.setSectionsSelectedCategory(selectedCategory);
    },
    sendContentScrolledEvent: (scrollTop: number, category: string) => {
      editorAPI.bi.event(
        coreBi.events.sections.add_section_panel.category_scrolled,
        {
          origin: ownProps.origin || origin,
          category,
          scrollTop,
          business_type: convertBusinessTypeToBiString(industryId, structureId),
          is_injected_content:
            contentInjectionAPI.getIsSectionsContentInjected(),
        },
      );
    },
    sendSectionsLoadedPhaseEvent: (
      duration: number,
      category: string,
      phaseName: string,
    ) => {
      editorAPI.bi.event(
        coreBi.events.sections.add_section_panel.sections_loaded_phase,
        {
          phaseName,
          category,
          duration: Math.round(duration),
          correlation_id: getPanelCorrelationId(
            scope.store.getSectionPanelOpenCount(),
          ),
          business_type: convertBusinessTypeToBiString(industryId, structureId),
          is_injected_content:
            contentInjectionAPI.getIsSectionsContentInjected(),
        },
      );
    },
    onSectionAddFromContextMenu: async (
      sectionPresetDefinition,
      categoryDefinition,
      stageEntryIndex,
      shouldApplyOriginTheme,
      compStructure = null,
    ) => {
      const structurePromise =
        compStructure || util.http.fetch(sectionPresetDefinition.presetJsonUrl);
      const pageRef = editorAPI.pages.getFocusedPage();
      const addingMethod = 'context_click';
      const theme = shouldApplyOriginTheme ? 'origin' : 'target';
      const elementId = sectionPresetDefinition._id;
      const reportSavedSectionAddedToStage = (
        elementId: string,
        theme: string,
        success: boolean,
        errMsg?: string,
      ) => {
        biLogger.report(
          myElementsAddElementToStage({
            adding_method: addingMethod,
            content_type: constants.COMP_TYPES.SECTION,
            element_id: elementId,
            theme,
            success,
            error_msg: errMsg,
          }),
        );
      };

      try {
        util.fedopsLogger.interactionStarted(
          util.fedopsLogger.INTERACTIONS
            .ADD_SECTION_PANEL_ADD_SECTION_FROM_CONTEXT_MENU,
        );

        hooks.sections.addPresetStart.fire(true);

        await addSectionPreset(
          scope,
          pageRef,
          structurePromise,
          stageEntryIndex,
          categoryDefinition,
          sectionPresetDefinition,
          ownProps,
          addingMethod,
          addOrigin,
          shouldApplyOriginTheme,
        );

        reportSavedSectionAddedToStage(elementId, theme, true);

        util.fedopsLogger.interactionEnded(
          util.fedopsLogger.INTERACTIONS
            .ADD_SECTION_PANEL_ADD_SECTION_FROM_CONTEXT_MENU,
        );
      } catch (e) {
        const flow = 'addSectionFromContextMenu';
        const presetId = sectionPresetDefinition.title;
        const category = categoryDefinition.title;
        const app_list = sectionPresetDefinition.appIds.join(',');
        ErrorReporter.captureException(e, {
          tags: { addSectionFlow: true, addSectionFromContextMenu: true },
          extra: {
            flow,
            structurePromise,
            presetId,
            category,
            appsToInstall: app_list,
          },
        });
        reportSavedSectionAddedToStage(elementId, theme, false, e.toString());
      } finally {
        hooks.sections.addPresetEnd.fire();
      }
    },
    addBlankSectionStructure: async () => {
      const pageRef = editorAPI.pages.getFocusedPage();
      const structure = scope.sectionsAPI.getBlankSectionStructure({
        height: 500,
        y: 0,
      });
      // should be added AFTER the existing blank section if the panel wasn't opened from the add-section-button
      const addIndex = editorAPI.sections.isEmptyState()
        ? ownProps.emptyStateBlankSectionIndex
        : ownProps.stageEntryIndex;

      try {
        util.fedopsLogger.interactionStarted(
          util.fedopsLogger.INTERACTIONS.ADD_SECTION_PANEL_ADD_SECTION_BLANK,
        );

        hooks.sections.addPresetStart.fire(true);

        await addSectionPreset(
          scope,
          pageRef,
          structure,
          addIndex,
          blankSectionCategoryDefinition,
          blankSectionPresetDefinition,
          { ...ownProps, emptyStateSectionReplacement: false },
          'click',
          ownProps.origin,
        );
        util.fedopsLogger.interactionEnded(
          util.fedopsLogger.INTERACTIONS.ADD_SECTION_PANEL_ADD_SECTION_BLANK,
        );
      } catch (e) {
        const flow = 'addBlankSection';
        const presetId = blankSectionPresetDefinition.title;
        const category = blankSectionCategoryDefinition.title;
        const app_list = blankSectionPresetDefinition.appIds.join(',');
        ErrorReporter.captureException(e, {
          tags: { addSectionFlow: true, addBlankSection: true },
          extra: {
            flow,
            structure,
            presetId,
            category,
            appsToInstall: app_list,
          },
        });
      } finally {
        hooks.sections.addPresetEnd.fire();
      }
    },
    updatePanelOpenCount: () => {
      scope.store.updateSectionPanelOpenCount();
    },
    sendSectionsTooltipHoverEvent: (duration: number, category: string) => {
      editorAPI.bi.event(
        coreBi.events.sections.add_section_panel.tooltip_checked,
        {
          origin: ownProps.origin || origin,
          category,
          duration: Math.round(duration),
        },
      );
    },
    enableMeasureMaps: () => {
      editorAPI.documentMode.enableShouldUpdateJsonFromMeasureMap(true);
    },
    waitForZoomOutAndDisableMeasureMaps: () => {
      Promise.resolve(editorAPI.zoomMode.getZoomModeTransitionPromise()).then(
        () => {
          editorAPI.documentMode.enableShouldUpdateJsonFromMeasureMap(false);
        },
      );
    },
  };
};
