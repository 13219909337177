import React from 'react';
import { hoc, cx, sections } from '@/util';
import LeftBarMenuButton from '../leftBarMenuButton/leftBarMenuButton';
import { PrimaryButtonsSlotPlacement } from '@/extensionSlots';
import {
  mapStateToProps,
  mapDispatchToProps,
  type OwnProps,
  type StateProps,
  type DispatchProps,
} from './leftBarMenuMapper';

import styles from './leftBarMenu.scss';

interface LeftBarMenuProps extends OwnProps, StateProps, DispatchProps {}

class LeftBarMenu extends React.Component<LeftBarMenuProps> {
  componentDidUpdate(prevProps: LeftBarMenuProps) {
    if (this.props.superAppsDesktopCount !== prevProps.superAppsDesktopCount) {
      this.props.sendBIButtonsCount();
    }
  }

  isSelected(panelName: string) {
    const { selectedPanelName } = this.props;

    return selectedPanelName && selectedPanelName === panelName;
  }

  onClick = (panelName: string, panelProps: any) => {
    const isSelected = this.isSelected(panelName);

    if (isSelected) {
      this.props.closePanel();
    } else {
      panelProps?.openPanelInteraction?.start();
      panelProps?.firstPresetSectionLoadInteraction?.start();

      this.props.openPanel(panelName, panelProps);

      this.props.sendBIButtonClick(panelName);

      if (panelProps?.openPanelBiEvent) {
        this.props.sendBI(
          panelProps?.openPanelBiEvent,
          panelProps?.openPanelBiParams,
        );
      }
    }
  };

  onHover = (element_name: string) => {
    this.props.sendBIButtonHover(element_name);
  };

  getNotificationCount = (panelName: AnyFixMe) => {
    return this.props.leftBarNotifications[panelName] ?? 0;
  };

  handleListBackgroundClick: React.MouseEventHandler = (e) => {
    if (e.target !== e.currentTarget) return;
    this.props.deselectComponents();
  };

  render() {
    return (
      <div className={styles.wrapper}>
        <ul className={styles.list} onClick={this.handleListBackgroundClick}>
          {this.props.leftBarMenuItems.map((item) => (
            <li
              key={item.panelName}
              className={cx({
                [styles.item]: true,
                [styles.primary]:
                  !sections.isSectionsEnabled() &&
                  item.className === 'add-panel',
              })}
            >
              <LeftBarMenuButton
                label={item.label}
                panelName={item.panelName}
                panelProps={item.panelProps}
                symbolName={item.symbolName}
                automationId={`leftBarButton-${item.panelName}`}
                isSelected={this.isSelected(item.panelName)}
                isHighlighted={this.props.isHighlighted}
                isEnabled={this.props.isEnabled}
                className={item.className}
                tooltips={item.tooltips}
                notificationCount={this.getNotificationCount(item.panelName)}
                onClick={this.onClick}
                onHover={this.onHover}
              />
            </li>
          ))}

          <PrimaryButtonsSlotPlacement>
            {(_integrationProps, item) => (
              <li className={styles.item}>
                <LeftBarMenuButton
                  label={item.label}
                  panelName="extensions-slot-primary-panel"
                  automationId={`leftBarButton-${item.automationId}`}
                  symbolName={item.symbolName}
                  isSelected={item.isSelected}
                  isHighlighted={this.props.isHighlighted}
                  isEnabled={this.props.isEnabled}
                  className={item.className}
                  onClick={item.onClick}
                  onHover={this.onHover}
                  notificationCount={0}
                  shouldTranslate={false}
                />
              </li>
            )}
          </PrimaryButtonsSlotPlacement>
        </ul>
      </div>
    );
  }
}

export default hoc.connect(
  hoc.STORES.EDITOR_API,
  mapStateToProps,
  mapDispatchToProps,
)(LeftBarMenu);
