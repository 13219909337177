import type { AnySection, PresetSectionItem } from '@wix/add-panel-common';
import { liveChatSection } from '@/tpa';
import type { EditorAPI } from '@/editorAPI';
import type { NewAddPanelUtils } from '@/newAddPanelAPI';
import { liveComponentSectionUtils } from '@/addPanelInfra';
import type { CompStructure } from '@wix/document-services-types';
import type {
  ThunkAction,
  MapStateToProps,
  MapDispatchToPropsFunction,
} from 'types/redux';

interface IAddPanelSectionViewOwnProps {
  index: number;
  section: AnySection;
  selectedItemKey: string;
  onClickHandler: (
    compRef: CompStructure,
    sectionName: string,
    tags: PresetSectionItem['preset']['tags'],
    itemId: string,
  ) => void;
  isSelectedItem: (item: PresetSectionItem['structure'] | string) => boolean;
  onMouseLeaveHandler: (itemId: string) => void;
  onMouseEnterHandler: (compRef: CompStructure, itemId: string) => void;
}

interface IAddPanelSectionStateProps {
  theme: NewAddPanelUtils['theme'];
}

interface IAddPanelSectionDispatchProps {
  hasSectionStyle: NewAddPanelUtils['hasSectionStyle'];
  getSuperAppData: NewAddPanelUtils['getSuperAppData'];
  getLiveChatSectionColors: NewAddPanelUtils['getLiveChatSectionColors'];
}

export interface IAddPanelSectionViewProps
  extends IAddPanelSectionViewOwnProps,
    IAddPanelSectionStateProps,
    IAddPanelSectionDispatchProps {}

const getEditorAPI: ThunkAction<EditorAPI> = (
  _dispatch,
  _getState,
  { editorAPI },
) => editorAPI;

export const mapStateToProps: MapStateToProps<
  IAddPanelSectionStateProps,
  IAddPanelSectionViewOwnProps
> = ({ editorAPI }) => ({
  theme: editorAPI.theme as unknown as NewAddPanelUtils['theme'],
});

export const mapDispatchToProps: MapDispatchToPropsFunction<
  IAddPanelSectionDispatchProps,
  IAddPanelSectionViewOwnProps
> = (dispatch) => {
  const editorAPI = dispatch(getEditorAPI);
  return {
    hasSectionStyle: (section: AnySection) =>
      liveComponentSectionUtils.hasStylesForSection(editorAPI, section),
    getSuperAppData: (appDefinitionId: string) =>
      appDefinitionId
        ? editorAPI.dsRead.tpa.appMarket.getDataAsync(appDefinitionId)
        : null,
    getLiveChatSectionColors: () =>
      liveChatSection.getLiveChatSectionColors(editorAPI),
  };
};
