import React from 'react';
import LayoutPresets from './LayoutPresets';

import type { CompRef } from 'types/documentServices';
import type { Preset } from './LayoutPresets';

const sectionLayoutPresets: Preset[] = [
  {
    id: 'layout1',
    icon: '50_50',
    proportion: [[50, 50]],
    tooltip: 'section_blank_layout_two_columns_tooltip',
  },
  {
    id: 'layout2',
    icon: '33_33_33',
    proportion: [[34, 33, 33]],
    tooltip: 'section_blank_layout_three_columns_tooltip',
  },
  {
    id: 'layout3',
    icon: '33_33_33_50_50',
    tooltip: 'section_blank_layout_five_collage_tooltip',
    proportion: [
      [33, 33, 33],
      [50, 50],
    ],
  },
  {
    id: 'layout4',
    icon: '25_75_75_25',
    tooltip: 'section_blank_layout_four_collage_tooltip',
    proportion: [
      [25, 75],
      [75, 25],
    ],
  },
];

interface SectionLayoutPresetsProps {
  section: CompRef;
}

export const SectionLayoutPresets: React.FC<SectionLayoutPresetsProps> =
  React.memo(({ section }) => (
    <LayoutPresets presets={sectionLayoutPresets} sectionLike={section} />
  ));

SectionLayoutPresets.displayName = 'SectionLayoutPresets';
