import * as stateManagement from '@/stateManagement';

import type { EditorAPI } from '@/editorAPI';
import type { BiEventDefinition } from 'types/bi';
import type { PagesData, SitemapEntry } from 'types/documentServices';
import type { Dispatch } from 'types/redux';

import type { PageSelectionComponentOwnProps } from './PageSelection';
import type { EditorState } from '@/stateManagement';

const getAppPagesSections = (editorAPI: EditorAPI, pages: PagesData[]) => {
  const pagesGroupsByAppDefId = pages.reduce((acc, pageData) => {
    const id = pageData.managingAppDefId ?? 'main';
    acc[id] = acc[id] ?? [];
    acc[id].push(pageData);

    return acc;
  }, {} as Record<string, PagesData[]>);

  return Object.keys(pagesGroupsByAppDefId).map((id) => ({
    id,
    title:
      id === 'main'
        ? 'Main Pages'
        : // @ts-expect-error
          editorAPI.pages.appPages.getApplicationSettings(id)?.displayName ??
          editorAPI.dsRead.platform.getAppDataByAppDefId(id)?.appDefinitionName,
    pages: pagesGroupsByAppDefId[id],
  }));
};

export const mapStateToProps = (
  { state, editorAPI }: { state: EditorState; editorAPI: EditorAPI },
  props: PageSelectionComponentOwnProps,
) => ({
  appPagesSections: getAppPagesSections(editorAPI, props.pages),
  currentPageId: editorAPI.dsRead.pages.getPrimaryPageId(),
  isFetchInProgress:
    stateManagement.tpaDynamicPages.selectors.isInFetchProcess(state),
});

const getEditorAPI = (
  _: never,
  __: never,
  { editorAPI }: { editorAPI: EditorAPI },
) => editorAPI;

export const mapDispatchToProps = (dispatch: Dispatch) => {
  const editorAPI: EditorAPI = dispatch(getEditorAPI);

  return {
    getRouterDataForPageIfExist(pageId: string) {
      return editorAPI.dsRead.routers.getRouterDataForPageIfExist(pageId);
    },

    getRouterInnerRoutes(routerId: string, pageId: string) {
      return new Promise<SitemapEntry[]>((resolve) =>
        editorAPI.dsRead.routers.getRouterInnerRoutes(
          routerId,
          pageId,
          resolve,
        ),
      );
    },
    getSubPages(pageId: string) {
      const appData = editorAPI.pages.data.get(pageId);
      const appDefinitionId =
        appData?.appDefinitionId ?? appData?.managingAppDefId;
      return stateManagement.tpaDynamicPages.selectors
        .getFocusedSubPages(
          editorAPI.store.getState(),
          appDefinitionId,
          pageId,
          'linkPanel',
        )
        .map((key) => ({
          key,
        }));
    },
    getTpaInnerRoutes(
      appDefinitionId: string,
      pageId: string,
      subPage: string,
    ) {
      return (
        stateManagement.tpaDynamicPages.selectors.getSubPageInnerRoute(
          editorAPI.store.getState(),
          appDefinitionId,
          pageId,
          subPage,
        ) ?? []
      );
    },
    getRouterPageType(pageId: string) {
      return editorAPI.pages.dynamicPages.getRouterPageType(pageId);
    },
    sendBI(
      eventType: BiEventDefinition,
      biParams: Record<string, string> = {},
    ) {
      dispatch(stateManagement.bi.actions.event(eventType, biParams));
    },

    fetchTpaInnerRoutes(
      pageId: string,
      setInnerRoutesInState?: (
        innerRoutes: stateManagement.TpaInnerRouteData[],
      ) => void,
    ) {
      dispatch(
        stateManagement.tpaDynamicPages.actions.fetchPageInnerRoutesInState(
          pageId,
          'linkPanel',
          setInnerRoutesInState,
        ),
      );
    },
  };
};
