import {
  colorPickerInput,
  colorPickerInputWithOpacity,
  textAlignment,
  colorScheme,
} from '@/baseUI';
import shadow from '../templates/advancedStyleShadowControl';
import borderPosition from '../templates/borderPositionControl';
import font from '../templates/fontControl';
import coverImage from '../templates/coverImage';

export {
  shadow as shadowControl,
  font as fontControl,
  colorPickerInput as colorPicker,
  colorPickerInputWithOpacity as colorPickerWithOpacity,
  textAlignment,
  colorScheme,
  coverImage,
  borderPosition as borderPositionControl,
};
