import { getCategories as getAddPanelCategories } from '@/addPanelData';
import { CATEGORIES_ID } from '@/addPanelDataConsts';
import constants from '@/constants';
import type { EditorAPI } from '@/editorAPI';
import { pagesAPI } from '@/pages';
import {
  api as platformAPI,
  panelHelpers,
  createMyWidgetsSectionsAddPanelV2 as createMyWidgetsSections,
} from '@/platform';
import { editorSearch } from '@/stateManagement';
import { addPanel as addPanelUtils, sections as sectionsUtils } from '@/util';
import { onceDataModeInitialized } from '@/wixData';
import { DATA_BINDING } from '@wix/app-definition-ids';
import { newAddPanelAPI } from '@/newAddPanelAPI';
import {
  type Feature,
  FEATURE,
  FEATURE_PARAM_VALUES,
  parse,
} from '@wix/deeplink-core';
import {
  check as deeplinkCheck,
  register,
  show as deeplinkShow,
} from '@wix/deeplink-editor';
import type { CompRef } from 'types/documentServices';
import { shouldOpenPanel } from '../utils/openPanelByURL';
import type { AnySectionItem } from '@wix/add-panel-common';
import {
  startAdiToEditorTour,
  startEditorToEditorTour,
  startEditorNewUsersTour,
  TourNames,
  StartOrigin,
} from '@/editorTours';
import type { AssistantOperation } from '@wix/tour-maker';
import { EditorRestrictionsApiKey } from '@/apis';

type AddPanelCategory = ValueOf<typeof CATEGORIES_ID>;
interface AddPanelLocation {
  categoryId: AddPanelCategory | string;
  sectionId: string | null;
}

const ContentManagerTabIdentifiers =
  FEATURE_PARAM_VALUES[FEATURE.contentManager].tab;
type ContentManagerTab = typeof ContentManagerTabIdentifiers[number];

const deeplinkOrigin = 'deeplink';

const categoryGroupsPropName = addPanelUtils.isNewPanelEnabled()
  ? 'groups'
  : 'items';
const sectionIdPropName = addPanelUtils.isNewPanelEnabled()
  ? 'sectionName'
  : 'id';

const toursMap: {
  [key: string]: (editorAPI: EditorAPI) => Promise<AssistantOperation>;
} = {
  [TourNames.ADI_TO_EDITOR]: (editorAPI: EditorAPI) =>
    startAdiToEditorTour(editorAPI, StartOrigin.DeepLink),
  [TourNames.EDITOR_TO_EDITOR]: (editorAPI: EditorAPI) =>
    startEditorToEditorTour(editorAPI, StartOrigin.DeepLink),
  [TourNames.EDITOR_NEW_USERS]: (editorAPI: EditorAPI) =>
    startEditorNewUsersTour(editorAPI, StartOrigin.DeepLink),
};

async function getApplicationAddPanelLocation(
  editorAPI: EditorAPI,
  appDefinitionId: string | null,
): Promise<AddPanelLocation | null> {
  if (!appDefinitionId) {
    return null;
  }

  const addPanelCategories = addPanelUtils.isNewPanelEnabled()
    ? (await newAddPanelAPI.getData()).categories
    : getAddPanelCategories(editorAPI);

  for (const category of addPanelCategories) {
    // prioritize top-level categories over nested sections
    if (category.appDefinitionId === appDefinitionId) {
      return {
        categoryId: category.id,
        sectionId: null,
      };
    }
  }

  for (const category of addPanelCategories) {
    for (const group of category[categoryGroupsPropName]) {
      for (const section of group.sections) {
        if (section.appDefinitionId === appDefinitionId) {
          return {
            categoryId: category.id,
            sectionId: section[sectionIdPropName] || null,
          };
        }

        const sectionHasApplicationItem = section.props?.items?.some(
          (sectionItem: AnySectionItem) =>
            sectionItem.appDefinitionId === appDefinitionId,
        );

        if (sectionHasApplicationItem) {
          return {
            categoryId: category.id,
            sectionId: section[sectionIdPropName] || null,
          };
        }
      }
    }
  }

  const sections = createMyWidgetsSections(editorAPI);
  const sectionExists = sections.some(
    (section) => section.id === appDefinitionId,
  );
  if (sectionExists) {
    return {
      categoryId: CATEGORIES_ID.INSTALLED_APPS,
      sectionId: appDefinitionId,
    };
  }

  return null;
}

async function getComponentAddPanelLocation(
  editorAPI: EditorAPI,
  componentType: string | null,
): Promise<AddPanelLocation | null> {
  // see `santa-editor/packages/rEditor/test/app/deeplink.unit.ts`
  // for current (and, possibly, incomplete) `componentType -> category` mapping
  if (!componentType) {
    return null;
  }

  // overrides for _some_ `componentType`s in cases when automatic guess fails
  switch (componentType) {
    case 'core.components.Image':
    case 'wysiwyg.viewer.components.WPhoto':
      return { categoryId: 'image', sectionId: null };
    case 'wysiwyg.viewer.components.Video':
      return { categoryId: 'media', sectionId: null };
    case 'wysiwyg.viewer.components.SiteButton':
      return { categoryId: 'button', sectionId: null };
    case 'wysiwyg.common.components.rssbutton.viewer.RSSButton':
      return getApplicationAddPanelLocation(
        editorAPI,
        editorAPI.tpa.superApps.consts.NEW_BLOG_APP_DEF_ID,
      );
    case 'wysiwyg.common.components.spotifyplayer.viewer.SpotifyPlayer':
      return getApplicationAddPanelLocation(
        editorAPI,
        editorAPI.tpa.superApps.consts.SPOTIFY.APP_DEF_ID,
      );
  }

  for (const category of getAddPanelCategories(editorAPI)) {
    if (category.appDefinitionId) {
      continue;
    }

    for (const categoryItem of category.items) {
      for (const section of categoryItem.sections) {
        const sectionHasComponentItem = section.props?.items?.some(
          (sectionItem: any) =>
            sectionItem.structure?.componentType === componentType,
        );

        if (sectionHasComponentItem) {
          return {
            categoryId: category.id,
            sectionId: section[sectionIdPropName],
          };
        }
      }
    }
  }

  return null;
}

export const _unittest_export_getComponentAddPanelLocation =
  getComponentAddPanelLocation;

export const _unittest_export_getApplicationAddPanelLocation =
  getApplicationAddPanelLocation;

export async function check(feature: Feature) {
  return deeplinkCheck(feature);
}

export async function show(feature: Feature) {
  return deeplinkShow(feature);
}

const openSwitchModeModal = (
  editorAPI: EditorAPI,
  origin: string,
  callback: () => void,
) => {
  editorAPI.panelManager.openPanel(
    'panels.messagePanels.switchWorkspaceModePanel',
    {
      callback,
      origin,
      actionNameKey: 'lite_editor_app_manager_actions_modal_tool_value',
    },
    true,
  );
};

export function onPreviewReady(editorAPI: EditorAPI) {
  register({
    [FEATURE.appMarket]: {
      show(feature) {
        const { appDefinitionId } = feature.named;

        editorAPI.panelManager.openPanel('tpa.compPanels.appMarketPanel', {
          urlParams: {
            slug: appDefinitionId || null,
          },
        });
      },
    },
    [FEATURE.component]: {
      check(feature) {
        const { id: compId, panelName } = feature.named;

        // check if panelName is valid
        if (panelName && !constants.componentPanels[panelName]) {
          return false;
        }

        // check if component exists on a page
        const pageRef = editorAPI.components.getPage({
          id: compId,
          type: 'DESKTOP',
        });
        if (!pageRef) {
          return false;
        }

        return true;
      },
      show(feature) {
        const { id: compId, panelName } = feature.named;

        const compRef: CompRef = { id: compId, type: 'DESKTOP' };

        panelHelpers.navigateAndOpenPanel(editorAPI, compRef, panelName);
      },
    },
    [FEATURE.accessibility]: {
      async show() {
        await platformAPI.accessibility.openAccessibilityWizard(editorAPI);
      },
    },
    [FEATURE.addPanel]: {
      async check(feature) {
        const { appDefinitionId, componentType } = feature.named;

        if (
          appDefinitionId &&
          !(await getApplicationAddPanelLocation(editorAPI, appDefinitionId))
        ) {
          return false;
        }

        if (
          componentType &&
          !(await getComponentAddPanelLocation(editorAPI, componentType))
        ) {
          return false;
        }

        return true;
      },
      async show(feature) {
        const editorRestrictionsApi = editorAPI.host.getAPI(
          EditorRestrictionsApiKey,
        );
        const { appDefinitionId, componentType } = feature.named;
        const { categoryId, sectionId } =
          (await getApplicationAddPanelLocation(editorAPI, appDefinitionId)) ||
          (await getComponentAddPanelLocation(editorAPI, componentType)) ||
          {};

        const openAddPanelAction = () => {
          openAddPanel(editorAPI, { categoryId, sectionId });
        };

        if (!editorRestrictionsApi.allowed('add-panel_deeplink.interactive')) {
          openSwitchModeModal(editorAPI, 'my business', openAddPanelAction);
          return;
        }

        openAddPanelAction();
      },
    },
    [FEATURE.addSectionPanel]: {
      check: () => {
        return sectionsUtils.isSectionsEnabled();
      },
      show: () => {
        editorAPI.panelManager.openPanel(
          constants.ROOT_COMPS.LEFTBAR.ADD_SECTION_PANEL_NAME,
          {
            origin: deeplinkOrigin,
          },
        );
      },
    },
    [FEATURE.editorTour]: {
      check: () => {
        return sectionsUtils.isSectionsEnabled();
      },
      show: (feature) => {
        const { tourName } = feature.named;

        toursMap[tourName]?.(editorAPI);
      },
    },
    [FEATURE.pagesPanel]: {
      check(feature) {
        const { appDefinitionId } = feature.named;
        if (!appDefinitionId) {
          return true;
        }
        const pagesData = editorAPI.dsRead.pages.getPagesData();
        const appPages = pagesAPI.appPages.getPages(editorAPI, pagesData);
        return appPages.some(({ id }) => id === appDefinitionId);
      },
      show(feature) {
        const { appDefinitionId } = feature.named;
        if (appDefinitionId) {
          return editorAPI.openPagesPanel({
            selectedTabId: appDefinitionId,
            origin: deeplinkOrigin,
          });
        }
        editorAPI.openPagesPanel({ origin: deeplinkOrigin });
      },
    },
    [FEATURE.viewport]: {
      check(feature) {
        return (
          feature.named.size === 'phone' || feature.named.size === 'desktop'
        );
      },
      show(feature) {
        const isMobile = feature.named.size === 'phone';

        if (isMobile !== editorAPI.isMobileEditor()) {
          editorAPI.setEditorMode(isMobile, 'deeplink');
        }
      },
    },
    [FEATURE.contentManager]: {
      async show(feature) {
        if (isWixDataInstalled(editorAPI)) {
          return onceDataModeInitialized(editorAPI).then(async (dataMode) => {
            if (dataMode) {
              editorAPI.panelManager.openPanel(
                constants.ROOT_COMPS.LEFTBAR.WIX_DATA_PANEL_NAME,
                {
                  selectedTabName: getContentManagerTabName(feature.named.tab),
                },
              );
            } else {
              openAddPanel(
                editorAPI,
                await getApplicationAddPanelLocation(editorAPI, DATA_BINDING),
              );
            }
          });
        }
        openAddPanel(
          editorAPI,
          await getApplicationAddPanelLocation(editorAPI, DATA_BINDING),
        );
      },
    },
    [FEATURE.search]: {
      show() {
        editorAPI.store.dispatch(
          editorSearch.actions.openEditorSearchPanel({
            origin: deeplinkOrigin,
          }),
        );
      },
    },
    [FEATURE.themePanel]: {
      show() {
        editorAPI.panelManager.openPanel(
          constants.ROOT_COMPS.LEFTBAR.DESIGN_PANEL_NAME,
          {
            origin: deeplinkOrigin,
          },
        );
      },
    },
  });

  const editorURL = new URL(window.location.href);

  for (const [key, value] of editorURL.searchParams) {
    if (
      key.toLowerCase() === 'openpanel' &&
      shouldOpenPanel(value, editorAPI)
    ) {
      return;
    }
  }

  let feature;

  try {
    feature = parse(editorURL);
  } catch (e) {
    // TODO: add fedops/bilogger tracking of invalid `&deeplink=` URLs
    console.error(e); // eslint-disable-line no-console
  }

  if (feature) {
    void deeplinkShow(feature);
  }
}

function openAddPanel(
  editorAPI: EditorAPI,
  {
    categoryId,
    sectionId,
    origin,
  }: { categoryId: string; sectionId?: string; origin?: string },
) {
  const options = {
    category: categoryId,
    sectionId,
    origin: origin ?? deeplinkOrigin,
  };
  if (addPanelUtils.isNewPanelEnabled()) {
    editorAPI.panelManager.openPanel(
      constants.ROOT_COMPS.LEFTBAR.NEW_ADD_PANEL_NAME,
      options,
    );
  } else {
    editorAPI.panelManager.openPanel(
      constants.ROOT_COMPS.LEFTBAR.ADD_PANEL_NAME,
      options,
    );
  }
}

function getContentManagerTabName(tabIdentifier: ContentManagerTab) {
  const identifierToNameMap = {
    collections: constants.SUPER_APPS.MENU_PANEL_TAB_NAMES.MANAGE,
    elements: constants.SUPER_APPS.MENU_PANEL_TAB_NAMES.ADD,
    learn: constants.SUPER_APPS.MENU_PANEL_TAB_NAMES.LEARN_MORE,
  };
  return identifierToNameMap[tabIdentifier];
}

function isWixDataInstalled(editorAPI: EditorAPI) {
  return editorAPI.dsRead.platform
    .getInstalledEditorApps()
    .some((app) => app.appDefinitionId === DATA_BINDING);
}
