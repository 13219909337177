import constants, { RCM_ORIGIN } from '@/constants';
import { translate } from '@/i18n';
import { TextEditor } from '@/textEditor';
import { panelsAnimation as PanelsAnimation } from '@/uiAnimations';
import {
  cx,
  hoc,
  isRightClickWithCtrlInDebugMode,
  sections,
  uiUtils,
  appStudioUtils,
} from '@/util';
import { isResponsiveEditor } from '@wix/santa-editor-utils';
import React, { Component, type CSSProperties, type MouseEvent } from 'react';
import { connectMouseOps } from '../../utils/connectMouseOps';
import GridLinesContainer from '../gridLines/gridLinesContainer';
import InteractionMode from '../interactionModeBar/interactionModeBar';
import InvisibleTextEditor from '../invisibleTextEditor';
import LanguageChangeOverlay from '../languageChangeOverlay/languageChangeOverlay';
import TranslationMode from '../languageModeBar/languageModeBar';
import LoaderOnStage from '../loaderOnStage/loaderOnStage';
import MouseCatcher from '../mouseCatcher/mouseCatcher';
import PinMode from '../pinMode/pinMode';
import { Rulers } from '@/rulers';
import { DesktopStageSideArea } from '@/sections';
import { SectionsReorganize } from '../sectionsReorganize/sectionsReorganize';
import { BlockLayer } from './blockLayer/blockLayer';
import RecommendedMobileHeaderHeight from '../recommendedMobileHeaderHeight/recommendedMobileHeaderHeight';
import ImageCropWrapper from './imageCropWrapper';
import { ScrollableEditorStage } from './scrollableEditorStage';
import {
  mapDispatchToProps,
  mapStateToProps,
  siteCoverStyleSel,
  type StageContainerDispatchProps,
  type StageContainerStateProps,
} from './stageContainerMapper';
import { StageLayerExtensionSlot } from './stageLayerExtensionSlot/stageLayerExtensionSlot';
import { AiTextCreatorEntryPoint } from '@/baseUI';
import experiment from 'experiment';
import type { MouseMoveAction } from '@/rEditor';
import { AI_TEXT_CREATOR_ENTRY_POINT_ORIGIN } from './stageContainerConstants';

const resizeActionTypes: MouseMoveAction['type'][] = [
  constants.MOUSE_ACTION_TYPES.RESIZE,
  constants.MOUSE_ACTION_TYPES.RESIZE_PUSH,
  constants.MOUSE_ACTION_TYPES.RESIZE_CROP,
  constants.MOUSE_ACTION_TYPES.RESIZE_PAGE,
];

const dragActionTypes: MouseMoveAction['type'][] = [
  constants.MOUSE_ACTION_TYPES.DRAG,
  constants.MOUSE_ACTION_TYPES.DRAG_PUSH,
];

const styleElement = window.document.createElement('style');
styleElement.innerHTML =
  '*:not(input):not(textarea) {-moz-user-select: none; -webkit-user-select: none; -ms-user-select: none; user-select: none; }';
window.document.head.appendChild(styleElement);

// @ts-expect-error
const SiteCover = connectMouseOps({ style: siteCoverStyleSel })('div') as any;

export interface StageContainerOwnProps {
  styleForPushedStageAndPreviewFrame: CSSProperties;
}
interface StageContainerProps
  extends StageContainerOwnProps,
    StageContainerStateProps,
    StageContainerDispatchProps {}
export class StageContainerComponent extends Component<
  StageContainerProps,
  { doubleClicked: boolean }
> {
  static displayName = 'StageContainer';

  state = { doubleClicked: false };

  openEditorModePanel = () => {
    this.props.openPanel('panels.focusPanels.mobileFriendlyPanel');
  };

  isSectionDragging() {
    return (
      this.props.registeredMouseMoveAction &&
      this.props.registeredMouseMoveAction.type ===
        constants.MOUSE_ACTION_TYPES.SECTION_DRAG &&
      this.props.performingMouseMoveAction
    );
  }

  isDragging() {
    return (
      this.props.registeredMouseMoveAction &&
      dragActionTypes.includes(this.props.registeredMouseMoveAction.type) &&
      this.props.performingMouseMoveAction
    );
  }

  isResizing() {
    return (
      this.props.registeredMouseMoveAction &&
      resizeActionTypes.includes(this.props.registeredMouseMoveAction.type)
    );
  }

  isRotating() {
    return (
      this.props.registeredMouseMoveAction &&
      this.props.registeredMouseMoveAction.type ===
        constants.MOUSE_ACTION_TYPES.ROTATE
    );
  }

  getScalableAreaStyle() {
    const { siteScale } = this.props;
    if (siteScale !== 1) {
      return {
        height: '100%',
        transform: `scale(${siteScale})`,
        transformOrigin: 'center 0', // TODO: modify according to scrollbar once we allow editing in scaled mode
      };
    }
  }

  shouldShowSectionReorganize() {
    if (sections.isSectionsEnabled()) {
      return false;
    }

    return (
      this.props.editorIsInit &&
      !this.props.isPinMode &&
      !this.props.isPopUpMode &&
      !this.props.getIsSpotlightStageContainer(this.props.focusedContainer) &&
      (this.props.isMobileEditor || this.props.isInZoomMode)
    );
  }

  shouldShowMouseCatcher() {
    return this.props.editorIsInit && !this.props.imageCrop.isOn;
  }

  shouldDisplayMobileOptimizedTeaser() {
    return (
      this.props.isMobileEditor &&
      !this.props.isMobileOptimizedSite &&
      !this.props.isInZoomMode
    );
  }

  getCompPanel() {
    return this.props.openPanels?.find((panelDef) =>
      panelDef.frameType?.includes(constants.PANEL_TYPES.COMP),
    );
  }

  onContextMenu = (event: MouseEvent) => {
    if (isRightClickWithCtrlInDebugMode(event)) {
      return;
    }
    event.preventDefault();
    event.stopPropagation();

    if (this.props.isInZoomModeAndDisabledRightClickMenu) {
      return;
    }

    this.props.closeOpenedPanels();
    this.props.selectComponentByClick(event);

    const viewerPosition = this.props.translateToViewerCoordinates(event);
    const selectedComponents = this.props.getSelectedComponents();

    if (selectedComponents.length) {
      this.props.openContextMenu({
        editorPositionX: event.clientX,
        editorPositionY: event.clientY,
        viewerPositionX: viewerPosition.clientX,
        viewerPositionY: viewerPosition.clientY,
        origin: RCM_ORIGIN.STAGE,
      });
    }
  };

  shouldShowRulers = () =>
    this.props.rulersEnabled &&
    !this.props.isInZoomMode &&
    this.props.isPreviewReady;

  renderMouseCatcher() {
    return (
      <MouseCatcher
        onContextMenu={this.onContextMenu}
        editorIsInit={this.props.editorIsInit}
        isSectionDragging={this.isSectionDragging()}
        isDragging={this.isDragging()}
        isResizing={this.isResizing()}
        isRotating={this.isRotating()}
        isPinMode={this.props.isPinMode}
        performingMouseMoveAction={this.props.performingMouseMoveAction}
        registeredMouseMoveAction={this.props.registeredMouseMoveAction}
        selectedComponents={this.props.selectedComponents}
        compPanel={this.getCompPanel()}
        previewPosition={this.props.previewPosition}
        siteScale={this.props.siteScale}
        isInteractionMode={this.props.isInteractionMode}
      />
    );
  }

  render() {
    const scrollBarWidth = uiUtils.getScrollbarWidth();
    const isResizing = this.isResizing();
    const stageLayout = this.props.getEditingAreaLayout();
    const shouldShowTextEditorPimple =
      experiment.isOpen('se_aiTextCreatorEntryPoints') &&
      !this.isDragging() &&
      !this.isRotating() &&
      !isResizing &&
      !this.props.isInZoomMode &&
      !this.props.isMobileEditor &&
      !this.props.isStyleOnlyMode &&
      !this.props.isTranslationMode &&
      !isResponsiveEditor() &&
      !appStudioUtils.isAppStudio() &&
      !this.props.isActionsHoveredAndHideSelectionEnabled;

    return (
      <div
        data-hook="editor-stage-container"
        id={constants.ROOT_COMPS.SELECTOR_ID.STAGE_CONTAINER}
        onDoubleClick={() => {
          if (!this.props.isStageInteractive) {
            return;
          }
          this.props.exitZoomMode({ origin: 'stageDoubleClick' });
          this.setState({ doubleClicked: true });
        }}
        onClick={() => {
          this.setState({ doubleClicked: false });
        }}
        className={cx({
          'editor-stage': true,
          'tools-hidden': this.props.isReorganizeHidden,
          zoomOutTransitioning: this.props.isZoomModeTransitionActive,
        })}
        onMouseLeave={this.props.fireMouseLeave}
        style={
          {
            '--site-cover-width': `${this.props.siteCoverWidthForHorizontallyScrollableStage}px`,
          } as CSSProperties
        }
      >
        <ScrollableEditorStage
          styleForPushedStageAndPreviewFrame={
            this.props.styleForPushedStageAndPreviewFrame
          }
        >
          <SiteCover
            className={cx('site-cover', {
              siteShadow:
                !this.props.isStageZoomMode && !this.props.isMobileEditor,
            })}
          >
            {this.props.isExtensionsEnabled && (
              <StageLayerExtensionSlot isMobile={this.props.isMobileEditor} />
            )}
            {this.props.StageComponents.map((slot) => (
              <slot.contribution key={slot.uniqueId} />
            ))}

            {!this.props.isStageZoomMode && !this.props.isStageFixedWidth && (
              <GridLinesContainer />
            )}

            <div style={this.getScalableAreaStyle()} className="scalable">
              {!this.props.isStageZoomMode &&
                this.props.isScalableMouseCatcherVisible &&
                this.shouldShowMouseCatcher() &&
                this.renderMouseCatcher()}
              <ImageCropWrapper />
              <TextEditor
                pimple={
                  shouldShowTextEditorPimple ? (
                    <AiTextCreatorEntryPoint
                      withBigToSmallAppearance={this.state.doubleClicked}
                      onEntryPointClick={this.props.openAiTextGeneratorPanel}
                      origin={AI_TEXT_CREATOR_ENTRY_POINT_ORIGIN}
                    />
                  ) : null
                }
                isResizing={isResizing}
              />
              <InvisibleTextEditor />
              {/* for sections experience horizontal rulers located in stage area  */}
              {!sections.isSectionsEnabled() && this.shouldShowRulers() ? (
                <Rulers rulerDirection="horizontal" />
              ) : null}
              <LanguageChangeOverlay />
            </div>

            {(this.props.isStageZoomMode ||
              !this.props.isScalableMouseCatcherVisible) &&
              this.shouldShowMouseCatcher() &&
              this.renderMouseCatcher()}

            <BlockLayer className="block-layer" />

            <RecommendedMobileHeaderHeight
              isResizing={isResizing}
              stageLayout={stageLayout}
            />

            {this.shouldShowSectionReorganize() && (
              <SectionsReorganize
                isMobileEditor={this.props.isMobileEditor}
                sections={this.props.pageSections.sections}
                selectedSection={this.props.pageSections.selectedSection}
                isSectionDragging={this.isSectionDragging()}
                isCompDragging={this.isDragging()}
                siteScale={this.props.siteScale}
                isHidden={this.props.isReorganizeHidden}
                halfOpacityTools={this.props.halfOpacityTools}
                stageLayout={stageLayout}
                isResizing={isResizing}
                isInZoomMode={this.props.isInZoomMode}
              />
            )}
          </SiteCover>

          <DesktopStageSideArea />
        </ScrollableEditorStage>
        <div
          style={{
            width: `calc(100% - ${
              sections.isSectionsEnabled() ? 0 : scrollBarWidth
            }px)`,
          }}
          className="fixed-editor-stage"
        >
          <div
            className={`fixed-editor-stage-contents-wrapper ${
              this.props.isInZoomMode ? 'no-pointer-events' : ''
            }`}
          >
            <div style={this.getScalableAreaStyle()} className="scalable">
              {this.props.isPinMode ? (
                <PinMode style={this.props.pinPreviewRectStyle} />
              ) : null}
              {this.shouldShowRulers() ? (
                <Rulers rulerDirection="vertical" />
              ) : null}
            </div>

            {this.props.isMobileEditor && (
              <div
                key="mobileOpacityLayer"
                className="mobile-frame-opacity-layer"
              />
            )}
            <PanelsAnimation>
              {this.shouldDisplayMobileOptimizedTeaser() && (
                <div key="teaser" className="mobile-optimized-teaser">
                  <h1>{translate('mobile_view_notification_title')}</h1>
                  {translate('mobile_view_notification_text')}
                  &nbsp;
                  <a onClick={this.openEditorModePanel}>
                    {translate('mobile_view_notification_text_link')}
                  </a>
                  &nbsp;
                  {translate('mobile_view_notification_text2')}
                </div>
              )}
            </PanelsAnimation>
          </div>
        </div>

        {this.props.isStageLoading && <LoaderOnStage />}
        {this.props.isInteractionMode &&
          this.props.shouldShowInteractionModeControls && <InteractionMode />}
        {this.props.isTranslationMode && <TranslationMode />}
      </div>
    );
  }
}

export const StageContainer = hoc.connect(
  hoc.STORES.EDITOR_API,
  mapStateToProps,
  mapDispatchToProps,
)(StageContainerComponent);

StageContainer.pure = StageContainerComponent;
