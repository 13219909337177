import { onComponentModelReady } from './bootstrap';

import {
  getComponentMetaData,
  __getUnsafeComponentMetaData,
} from './componentsMetaData/componentsMetaData';

import referredComponentMetaData from './componentsMetaData/components/referredComponentMetaData';
import {
  hasComponentPanel,
  loadComponentPanel,
  loadComponentPanelDefinition,
} from './componentPanels';
import {
  hasComponentPart,
  loadComponentPart,
  type ComponentPanelType,
  loadComponentsPart,
} from './componentRegistry';
/**
 * TODO: one facade for all component data
 *
 * ```
 * // init
 * await initComponentModel(CONFIG);
 *
 *  // load initial data (gfpp, metadata)
 * await loadComponentsInitialStageData();
 *
 * // get component model part data
 * getComponentGfppData(componentType)
 * getComponentMetaData(componentType)
 * loadComponentPanel(componentType)
 * ```
 */

export type { ComponentPanelType };

export {
  onComponentModelReady,
  getComponentMetaData,
  __getUnsafeComponentMetaData,
  referredComponentMetaData,
  hasComponentPanel,
  hasComponentPart,
  loadComponentPart,
  loadComponentsPart,
  loadComponentPanel,
  loadComponentPanelDefinition,
};
