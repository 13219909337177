/*eslint max-lines: [2, { "max": 1300, "skipComments": true, "skipBlankLines": true}]*/
import _ from 'lodash';
import * as util from '@/util';
import * as gfppData from '@/gfppData';
import constants from '@/constants';
import * as stateManagement from '@/stateManagement';
import gfppDataEnhancer from './gfppDataEnhancer';
import * as textControls from '@/textControls';
import experiment from 'experiment';
import type { EditorAPI } from '@/editorAPI';
import type { CompRef } from 'types/documentServices';
import type {
  GFPPAction,
  GFPPActionOnClick,
  GFPPActionsDictionaryRaw,
  GFPPActionsDictionary,
  GFPPDataRaw,
  GFPPData,
  GFPPValueResolved,
} from '@wix/editor-component-types';
import * as helpIds from '@/helpIds';
import * as platform from '@/platform';
import { designData } from '@/util';
import {
  GFPP_ACTIONS,
  ENABLED_BY_METADATA,
  DEFAULT_GFPP_DATA,
} from './gfppDefaults';

type GFPPDataResolver = <T>(componentData: T) => GFPPValueResolved<T>;

const pinModeActions = stateManagement.pinMode.actions;
const { getFocusedContainer } = stateManagement.selection.selectors;
const componentsSelectors = stateManagement.components.selectors;

const { hasDividersDesign } = designData;
const {
  isInInteractionMode,
  isShownOnlyOnVariant,
  isInteractionsSupported,
  getInteractionTriggerRef,
} = stateManagement.interactions.selectors;

const excludedKeys = ['onClick', 'template', 'onTemplatePropsChange'];

const { onAnimationClick, isAnimationSupported, getModesAnimationTooltip } =
  gfppData.animationGfppUtils;

const { getPanelStateFn } = gfppData.utils;

const getWidgetDesignData = (editorAPI: EditorAPI, widgetRootRef: CompRef) => {
  const isAppWidget = util.appStudioUtils.isAppWidget(editorAPI, widgetRootRef);
  if (isAppWidget) {
    const gfppDataFromApp =
      editorAPI.platform.controllers.getStageData(widgetRootRef)?.gfpp;
    const deviceType = stateManagement.mobile.selectors.getDeviceType(
      editorAPI.store.getState(),
    );
    return gfppDataFromApp?.[deviceType]?.widgetDesign;
  }
};

function getWidgetDesignOnCloseFn(editorAPI: EditorAPI, compRefs: CompRef[]) {
  const compRef = Array.isArray(compRefs) ? compRefs[0] : compRefs;
  return platform.widgetDesignUtils.triggerWidgetDesignNotification.bind(
    null,
    editorAPI,
    compRef,
  );
}

function getSelectedComponentScale(
  editorAPI: EditorAPI,
  compRef: CompRef[],
): number {
  return editorAPI.components.layout.get_scale(compRef);
}

function getSelectedComponentFontSize(
  editorAPI: EditorAPI,
  compRef: CompRef[],
): number {
  const targetRef =
    editorAPI.components.getType(compRef) === 'platform.components.AppWidget'
      ? editorAPI.components.getChildren(compRef)
      : compRef;
  return _.parseInt(
    textControls.fontUtils.getSkinBasedCompFontSize(editorAPI, targetRef),
  );
}

function changeScale(
  isScaleUp: boolean,
  editorAPI: EditorAPI,
  compRef: CompRef[],
): void {
  if (isScalingDisabled(isScaleUp, editorAPI, compRef)) {
    return;
  }
  const fontSize = getSelectedComponentFontSize(editorAPI, compRef);
  const curScale = getSelectedComponentScale(editorAPI, compRef);
  const scaledFontSize = curScale * fontSize;
  const step = isScaleUp ? 1 : -1;
  const newScale = Math.max((scaledFontSize + step) / Math.max(fontSize, 1), 0); // no division by zero in this house!
  editorAPI.components.layout.updateAndAdjustLayout(compRef, {
    scale: newScale,
  });
}

function getScalingTooltip(editorAPI: EditorAPI, compRef: CompRef[]): string {
  const scale = getSelectedComponentScale(editorAPI, compRef);
  const fontSize = getSelectedComponentFontSize(editorAPI, compRef);
  const scaledFontSize = scale * fontSize;
  return `${_.round(scaledFontSize)}px`;
}

function isScalingDisabled(
  isScaleUp: boolean,
  editorAPI: EditorAPI,
  compRef: CompRef[],
): boolean {
  const fontSize = getSelectedComponentFontSize(editorAPI, compRef);
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/values
  const fontDef = _(
    textControls.fontUtils.getSkinBasedCompFontDefs(editorAPI, compRef),
  )
    .values()
    .head();
  const limits = {
    min: fontDef?.min || 0,
    max: fontDef?.max || 100,
  };
  const curScale = getSelectedComponentScale(editorAPI, compRef);
  const scaledFontSize = _.round(curScale * fontSize);
  return (
    (isScaleUp && scaledFontSize >= limits.max) ||
    (!isScaleUp && scaledFontSize <= limits.min)
  );
}

function canReparentIfNecessary(
  editorAPI: EditorAPI,
  compRefs: CompRef[],
): boolean {
  const canParentContainAfterStretch =
    editorAPI.components.is.potentialContainerForScreenWidthComp(
      editorAPI.components.getContainer(compRefs),
    );
  return (
    canParentContainAfterStretch ||
    editorAPI.components.is.containable(
      compRefs,
      editorAPI.pages.getFocusedPage(),
    )
  );
}

const getDesignPanelState = getPanelStateFn('design');
const getAdvancedStylePanelState = getPanelStateFn(
  'advancedStylePanel.widgetDesignAdvancedStylePanel',
);
const getAdvancedStylePanelMultiComponentState = getPanelStateFn(
  'advancedStylePanel.multiComponentAdvancedStylePanel',
);

const isBackgroundVisible = (editorAPI: EditorAPI, compRefs: CompRef[]) => {
  const compRef = Array.isArray(compRefs) ? compRefs[0] : compRefs;
  const isSingleColumn = editorAPI.columns.isSingleColumnStrip(compRef);
  return gfppData.columnsUtils.shouldShowChangeBackgroundAction(
    editorAPI,
    compRef,
    isSingleColumn,
  );
};

const isDesignGfppSelected = (editorAPI: EditorAPI) =>
  getDesignPanelState(editorAPI) ||
  getAdvancedStylePanelState(editorAPI) ||
  getAdvancedStylePanelMultiComponentState(editorAPI);

const isDividerEnabled = (editorAPI: EditorAPI, compRefs: CompRef[]) =>
  isBackgroundVisible(editorAPI, compRefs);

const DEFAULTS: GFPPActionsDictionaryRaw = {
  aiTools: {
    isSelected: gfppData.utils.getPanelStateFn('aiTools'),
    tooltip: 'ai_tools',
    icon: 'sparklesBold',
    onClick: (editorAPI, compRefs, ...args) =>
      gfppData.utils.getTogglePanelFn('aiTools')(
        editorAPI,
        platform.gfppTargetUtils.getSelectedComponents(
          compRefs,
          editorAPI,
          'aiTools',
        ),
        ...args,
      ),
    automationId: 'gfpp-button-ai-tools',
  },
  settings: {
    isSelected: gfppData.utils.getPanelStateFn('settings'),
    tooltip: 'gfpp_tooltip_settings',
    icon: 'settings',
    onClick: (editorAPI, compRefs, ...args) =>
      gfppData.utils.getTogglePanelFn('settings')(
        editorAPI,
        platform.gfppTargetUtils.getSelectedComponents(
          compRefs,
          editorAPI,
          'settings',
        ),
        ...args,
      ),
    automationId: 'gfpp-button-settings',
  },
  background: {
    isSelected: gfppData.utils.getPanelStateFn('background'),
    tooltip: 'gfpp_tooltip_background',
    icon: 'mobileBackgroundSettingsGfpp',
    onClick: togglePanelWithWarning('background'),
    automationId: 'gfpp-button-background',
  },
  design: {
    isSelected: isDesignGfppSelected,
    tooltip: 'gfpp_tooltip_design',
    icon: 'design',
    onClick: (editorAPI, compRefs, ...args) => {
      const panelProps = {};
      const widgetRootRef = editorAPI.components.getContainerOrScopeOwner(
        compRefs[0],
      );
      const widgetDesignData = getWidgetDesignData(editorAPI, widgetRootRef);
      if (widgetDesignData) {
        return platform.widgetDesignUtils.openWidgetLevelWidgetDesign(
          widgetDesignData,
          editorAPI,
          widgetRootRef,
        );
      }
      compRefs = platform.gfppTargetUtils.getSelectedComponents(
        compRefs,
        editorAPI,
        'design',
      );
      const isMobileOnly = compRefs.every(
        editorAPI.mobile.mobileOnlyComponents.isMobileOnlyNonNativeComponent,
      );
      if (
        (componentsSelectors.isStylable(compRefs, editorAPI.dsRead) &&
          editorAPI.isMobileEditor() &&
          !isMobileOnly) ||
        gfppData.utils.interactionNotOnlyOnVariantComp(editorAPI, compRefs) ||
        componentsSelectors.hasResponsiveLayout(compRefs, editorAPI.dsRead)
      ) {
        _.set(panelProps, 'onlyAdvancedStyling', true);
      }

      if (
        componentsSelectors.shouldStartDesignPanelOpened(
          _.head(compRefs),
          editorAPI.dsRead,
        )
      ) {
        _.set(panelProps, 'shouldStartClosed', false);
      }

      if (Array.isArray(compRefs) && compRefs.length > 1) {
        const compRef = _.head(compRefs);
        const compType = editorAPI.components.getType(compRef);
        Object.assign(panelProps, {
          selectedComponents: [compRef],
          selectedComponent: [compRef],
          compType,
          multiSelectedComponents: compRefs,
        });
      }

      const preventNotificationTrigger = (args[0] as AnyFixMe)
        ?.preventNotificationTrigger;
      const onClose = getWidgetDesignOnCloseFn(editorAPI, compRefs);
      if (!preventNotificationTrigger && onClose) {
        _.set(panelProps, 'onClose', onClose);
      }

      return togglePanelWithWarning('design', panelProps)(
        editorAPI,
        compRefs,
        ...args,
      );
    },
    automationId: 'gfpp-button-design',
  },
  presets: {
    isSelected: gfppData.utils.getPanelStateFn(
      'compPanels.panels.Widget.widgetPresetsPanel',
    ),
    tooltip: 'PLATFORM_preset_panel_gfpp_button',
    icon: 'gfpp_widgetPresets',
    onClick: (editorAPI, compRefs) => {
      editorAPI.panelManager.openComponentPanel(
        'compPanels.panels.Widget.widgetPresetsPanel',
        {
          selectedComponent: compRefs,
        },
      );
    },
    automationId: 'gfpp-button-presets',
  },
  [GFPP_ACTIONS.INTERACTIONS]: {
    isApplied: gfppData.utils.isInteractionsApplied,
    isSelected(editorAPI: AnyFixMe) {
      return !!editorAPI.panelManager
        .getOpenPanels()
        .find(
          (panel: AnyFixMe) =>
            panel.name === 'interactions.panels.interactionsPanel',
        );
    },
    isSupported: isInteractionsSupported,
    automationId: 'gfpp-button-interactions',
    tooltip: gfppData.utils.getInteractionsGfppTooltip,
    // @ts-expect-error
    interactiveTooltip: true,
    tooltipMaxWidth: '270px',
    isDisabled: (editorAPI: AnyFixMe) => {
      return editorAPI.isMobileEditor();
    },
    keepTooltipOpenOnClick: true,
    icon: 'interaction_light',
    id: GFPP_ACTIONS.INTERACTIONS,
    onClick: (editorAPI: AnyFixMe, compRefs: CompRef[]) => {
      editorAPI.panelManager.openComponentPanel(
        'interactions.panels.interactionsPanel',
        {
          selectedComponent: compRefs[0],
          selectedComponents: compRefs,
          selectedComponentType: editorAPI.components.getType(compRefs),
        },
      );
    },
  },
  stylableLayout: {
    isSelected: gfppData.utils.getPanelStateFn('design'),
    tooltip: 'gfpp_tooltip_layout',
    icon: 'layout',
    onClick: (...args) =>
      togglePanelWithWarning(
        'design',
        args[0].isMobileEditor()
          ? {
              onlyAdvancedStyling: true,
              initialView: { page: 1, section: 'layout' },
            }
          : {
              startWithAdvanced: true,
              initialView: { page: 1, section: 'layout' },
            },
      )(...args),
    isSupported(editorAPI, compRefs) {
      return componentsSelectors.isStylable(compRefs, editorAPI.dsRead);
    },
    automationId: 'gfpp-button-stylable-layout',
  },
  layout: {
    isSelected: gfppData.utils.getPanelStateFn('layout'),
    tooltip: 'gfpp_tooltip_layout',
    icon: 'layout',
    onClick: (editorAPI, compRefs, ...args) =>
      togglePanelWithWarning('layout')(
        editorAPI,
        platform.gfppTargetUtils.getSelectedComponents(
          compRefs,
          editorAPI,
          'layout',
        ),
        ...args,
      ),
    automationId: 'gfpp-button-layout',
    isSupported(editorAPI, compRefs) {
      const compRefsArray = _.castArray(compRefs);
      return (
        compRefsArray.length === 1 ||
        !compRefsArray.some(editorAPI.components.is.controlledByParent)
      );
    },
  },
  dividers: {
    isApplied(editorAPI, compRefs) {
      return hasDividersDesign(editorAPI, compRefs);
    },
    isSelected: gfppData.utils.getPanelStateFn(GFPP_ACTIONS.DIVIDERS),
    tooltip(editorAPI, compRefs) {
      return isBackgroundVisible(editorAPI, compRefs) ||
        !editorAPI.columns.isStrip(compRefs)
        ? 'shape_dividers_gfpp_dividers_tooltip'
        : 'shape_dividers_gfpp_no_dividers_tooltip';
    },
    icon: 'dividers_gfpp_icon',
    tooltip_active: 'shape_dividers_gfpp_dividers_on_tooltip',
    automationId: 'gfpp-button-dividers',
    isDisabled(editorAPI, compRefs) {
      return editorAPI.columns.isStrip(compRefs)
        ? !isDividerEnabled(editorAPI, compRefs)
        : false;
    },
    onClick: togglePanelWithWarning(GFPP_ACTIONS.DIVIDERS),
  },
  filters: {
    isApplied(editorAPI, compRef) {
      const properties = editorAPI.components.properties.get(compRef);
      const filter = properties.filterEffect;
      return !_.isEmpty(filter) && filter.effectType !== 'none';
    },
    isSelected: gfppData.utils.getPanelStateFn('filters'),
    tooltip: 'gfpp_tooltip_image_filters',
    tooltip_active: 'gfpp_tooltip_applied_image_filters',
    icon: 'filters',
    automationId: 'gfpp-button-filters',
    onClick: (editorAPI, compRefs, ...args) =>
      gfppData.utils.getTogglePanelFn('filters', { origin: 'gfpp' })(
        editorAPI,
        platform.gfppTargetUtils.getSelectedComponents(
          compRefs,
          editorAPI,
          'filters',
        ),
        ...args,
      ),
  },
  behaviors: {
    isSelected: gfppData.utils.getPanelStateFn('behaviors'),
    tooltip: 'gfpp_tooltip_behaviors',
    icon: 'behaviors_gfpp_icon',
    onClick: gfppData.utils.getTogglePanelFn('behaviors', { origin: 'gfpp' }),
  },
  effects: {
    isSelected: gfppData.utils.getPanelStateFn('effects'),
    isDisabled: (editorAPI: EditorAPI, compRef: CompRef) =>
      gfppData.columnsUtils.isBackgroundEffectDisabled(editorAPI, compRef) ||
      gfppData.utils.hasAnimations(editorAPI, compRef),
    isApplied: gfppData.columnsUtils.isBackgroundEffectApplied,
    tooltip: (editorAPI, compRef) => {
      if (gfppData.utils.hasAnimations(editorAPI, compRef)) {
        return 'gfpp_tooltip_effects_disabled_has_animation';
      }
      return gfppData.columnsUtils.getBackgroundEffectTooltip(
        editorAPI,
        compRef,
      );
    },
    rightClickMenuLabel:
      gfppData.columnsUtils.getBackgroundEffectsRightClickLabel,
    icon: 'bgScrollEffects_gfpp',
    onClick(editorAPI, compRef) {
      gfppData.utils.getTogglePanelFn('effects', {
        origin: 'gfpp',
        selectedComponent: gfppData.columnsUtils.getColumnOrStripContainer(
          editorAPI,
          compRef,
        ),
      })(editorAPI);
    },
  },
  stretch: {
    isApplied: gfppData.utils.isStretchApplied,
    isSelected: gfppData.utils.getPanelStateFn('stretching'),
    tooltip(editorAPI: EditorAPI, compRef: CompRef) {
      return editorAPI.components.layout.isHorizontallyStretchedToScreen(
        compRef,
      )
        ? 'gfpp_tooltip_unstretch'
        : 'gfpp_tooltip_stretch';
    },
    rightClickMenuLabel: 'gfpp_tooltip_stretch',
    icon: 'gfpp_stretch',
    automationId: 'gfpp-button-stretch',
    onClick: (editorAPI, compRefs, ...args) =>
      gfppData.utils.getTogglePanelFn('stretching')(
        editorAPI,
        platform.gfppTargetUtils.getSelectedComponents(
          compRefs,
          editorAPI,
          'stretch',
        ),
        ...args,
      ),
    isSupported(editorAPI, compRefs) {
      const state = editorAPI.store.getState();
      const isStretchable =
        editorAPI.components.is.stretchable(compRefs) &&
        !editorAPI.isMobileEditor();
      const isCustomizeTranslateMode =
        isSecondaryLang(editorAPI) &&
        editorAPI.components.is.customizeTranslate(compRefs);
      return (
        isStretchable &&
        !isInInteractionMode(state) &&
        !isCustomizeTranslateMode &&
        canReparentIfNecessary(editorAPI, compRefs) &&
        !editorAPI.componentFocusMode.isEnabled()
      );
    },
  },
  stretchForColumns: {
    isApplied: gfppData.utils.isStretchApplied,
    isSelected: gfppData.utils.getPanelStateFn('stretching'),
    tooltip: 'gfpp_tooltip_unstretch',
    rightClickMenuLabel: 'gfpp_tooltip_stretch',
    icon: 'gfpp_stretch',
    onClick: gfppData.utils.getTogglePanelFn('stretching'),
  },
  upgrade: {
    isSelected: false, // todo
    tooltip: 'gfpp_tooltip_upgrade',
    icon: 'upgrade',
    onClick: (editorAPI, compRefs, ...args) =>
      gfppData.utils.getTogglePanelFn('upgrade')(
        editorAPI,
        platform.gfppTargetUtils.getSelectedComponents(
          compRefs,
          editorAPI,
          'upgrade',
        ),
        ...args,
      ),
    automationId: 'gfpp-button-upgrade',
  },
  crop: {
    isApplied: gfppData.utils.isCropApplied,
    isSelected: false,
    isDisabled(editorAPI, compRef) {
      if (
        experiment.isOpen('specs.responsive-editor.ImageXPlatformization') &&
        componentsSelectors.hasResponsiveLayout(compRef, editorAPI.dsRead)
      ) {
        return false;
      }
      if (editorAPI.components.hasRuntimeDataOrConnected(compRef)) {
        return true;
      }
      const { uri } = editorAPI.components.data.get(compRef);
      return (
        !editorAPI.imageCrop.isCropAllowed(uri) || util.url.isExternalUrl(uri)
      );
    },
    tooltip(editorAPI, compRef) {
      if (
        experiment.isOpen('specs.responsive-editor.ImageXPlatformization') &&
        componentsSelectors.hasResponsiveLayout(compRef, editorAPI.dsRead)
      ) {
        return 'gfpp_tooltip_crop';
      }
      if (editorAPI.components.hasRuntimeDataOrConnected(compRef)) {
        return 'gfpp_tooltip_crop_unsupported';
      }
      const { uri } = editorAPI.components.data.get(compRef);
      if (editorAPI.imageCrop.isCropAllowed(uri)) {
        return 'gfpp_tooltip_crop';
      } else if (util.url.isExternalUrl(uri)) {
        return 'gfpp_tooltip_crop_unsupported';
      }
      return 'gfpp_tooltip_crop_gif_unsupported';
    },
    tooltip_active: 'gfpp_tooltip_applied_crop',
    icon: 'effects',
    className: 'gfpp_button_crop',
    onClick(editorAPI, compRef) {
      compRef = platform.gfppTargetUtils.getSelectedComponents(
        compRef,
        editorAPI,
        'crop',
      );
      const { uri } = editorAPI.components.data.get(compRef);
      if (editorAPI.imageCrop.isCropAllowed(uri)) {
        gfppData.utils.openImageCrop(editorAPI, compRef);
      }
    },
  },
  focalPoint: {
    icon: 'focalPoint',
    tooltip: 'SECTION_BG_IMAGE_ALIGN_LABEL',
  },
  mask: {
    isApplied: gfppData.utils.isMaskApplied,
    isSelected: gfppData.utils.getPanelStateFn('mask'),
    isDisabled(editorAPI, compRef) {
      const mediaFeatures =
        editorAPI.components.design.get(compRef)?.background?.mediaRef
          ?.mediaFeatures;
      return mediaFeatures && mediaFeatures.includes('alpha');
    },
    tooltip(editorAPI, compRef) {
      const mediaFeatures =
        editorAPI.components.design.get(compRef)?.background?.mediaRef
          ?.mediaFeatures;
      if (mediaFeatures && mediaFeatures.includes('alpha')) {
        return 'gfpp_tooltip_mask_transparent_video_unsupported';
      }
      return 'gfpp_tooltip_mask';
    },
    tooltip_active: 'gfpp_tooltip_applied_mask',
    icon: 'gfpp_mask',
    className: 'gfpp_button_mask',
    onClick: gfppData.utils.getTogglePanelFn('mask', {
      origin: 'gfpp',
      showStretchControl: experiment.isOpen('se_imageMaskStretchToggle'),
    }),
  },
  interactionHide: {
    isApplied: gfppData.utils.isInteractionHideApplied,
    tooltip(editorAPI, compRef) {
      const state = editorAPI.store.getState();
      return editorAPI.utils.isSameRef(getInteractionTriggerRef(state), compRef)
        ? 'gfpp_tooltip_interactions_hide_trigger'
        : 'gfpp_tooltip_interactions_hide_element';
    },
    tooltip_active: 'gfpp_tooltip_interactions_show_trigger',
    icon: 'gfpp_hide',
    onClick: (editorAPI, compRefs: CompRef[]) => {
      editorAPI.store.dispatch(
        gfppData.utils.isInteractionHideApplied(editorAPI)
          ? stateManagement.interactions.actions.showComponent(compRefs[0], {
              origin: 'gfpp',
            })
          : stateManagement.interactions.actions.hideComponent({
              origin: 'gfpp',
            }),
      );
    },
    isSupported(editorAPI, compRefs) {
      const state = editorAPI.store.getState();

      return (
        compRefs.length === 1 &&
        isInInteractionMode(state) &&
        !isShownOnlyOnVariant(editorAPI, compRefs[0])
      );
    },
    className: 'gfpp-button-interaction-hide',
    automationId: 'gfpp-button-interaction-hide',
  },
  transition: {
    isSelected: gfppData.utils.getPanelStateFn(
      'interactions.panels.transitionPanel',
    ),
    tooltip: 'gfpp_tooltip_interactions_timing',
    icon: 'gfpp_timing',
    isDisabled(editorAPI) {
      const { transitions } = editorAPI.documentServices.components;
      const compRef = editorAPI.selection.getSelectedComponents()[0];

      // FIXME add transitions to testUtils
      if (!transitions) {
        return true;
      }

      return !transitions.has(compRef);
    },
    onClick(editorAPI) {
      editorAPI.panelManager.openComponentPanel(
        'interactions.panels.transitionPanel',
        {},
      );
    },
    isSupported(editorAPI, compRefs) {
      const state = editorAPI.store.getState();

      if (isInInteractionMode(state)) {
        return !isShownOnlyOnVariant(editorAPI, compRefs[0])
          ? true
          : experiment.isOpen('se_addTransitionPanelToBlueComps');
      }
      return false;
    },
    className: 'gfpp-button-interaction-transition',
    automationId: 'gfpp-button-interaction-transition',
  },
  animation: {
    isSelected: gfppData.utils.getPanelStateFn('animation'),
    isDisabled(editorAPI, compRef) {
      return (
        gfppData.columnsUtils.isBackgroundEffectApplied(editorAPI, compRef) ||
        someAncestorHasDefaultModeActive(editorAPI, compRef)
      );
    },
    isApplied: gfppData.utils.isAnimationGfppApplied,
    tooltip(editorAPI, compRef) {
      const isSomeDefaultModeActive = someAncestorHasDefaultModeActive(
        editorAPI,
        compRef,
      );
      if (gfppData.columnsUtils.isBackgroundEffectApplied(editorAPI, compRef)) {
        return 'gfpp_tooltip_animation_disabled_has_bg_effect';
      }
      return isSomeDefaultModeActive
        ? getModesAnimationTooltip(editorAPI, compRef)
        : 'gfpp_tooltip_animation';
    },
    tooltip_active: 'gfpp_tooltip_applied_animation',
    icon: 'animation',
    //icon_active: 'animationOn',
    onClick: (editorAPI, compRefs, origin, props) =>
      onAnimationClick(
        editorAPI,
        platform.gfppTargetUtils.getSelectedComponents(
          compRefs,
          editorAPI,
          'animation',
        ),
        origin,
        props,
      ),
    isSupported: isAnimationSupported,
    className: 'gfpp-button-animation',
    automationId: 'gfpp-button-animation',
  },
  motion: {
    isSelected: gfppData.utils.getPanelStateFn('motion'),
    isDisabled: gfppData.columnsUtils.isBackgroundEffectDisabled,
    isApplied(editorAPI, compRef) {
      return (
        gfppData.utils.isAnimationGfppApplied(
          editorAPI,
          gfppData.columnsUtils.getColumnOrStripContainer(editorAPI, compRef),
        ) || gfppData.columnsUtils.isBackgroundEffectApplied(editorAPI, compRef)
      );
    },
    tooltip(editorAPI, compRef) {
      const isSomeDefaultModeActive = someAncestorHasDefaultModeActive(
        editorAPI,
        compRef,
      );
      const isBgEffectDisabled =
        gfppData.columnsUtils.isBackgroundEffectDisabled(editorAPI, compRef);
      if (isBgEffectDisabled) {
        return gfppData.columnsUtils.getBackgroundEffectTooltip(
          editorAPI,
          compRef,
        );
      }
      return isSomeDefaultModeActive
        ? getModesAnimationTooltip(editorAPI, compRef)
        : 'gfpp_tooltip_animation';
    },
    tooltip_active: 'gfpp_tooltip_applied_animation',
    icon(editorAPI, compRef) {
      const selectedComp = gfppData.columnsUtils.getColumnOrStripContainer(
        editorAPI,
        compRef,
      );
      const isColumn = editorAPI.columns.isColumn(selectedComp);
      return isColumn ? 'animation' : 'bgScrollEffects_gfpp';
    },
    //icon_active: 'animationOn',
    onClick(editorAPI, compRef) {
      const selectedComponent = gfppData.columnsUtils.getColumnOrStripContainer(
        editorAPI,
        compRef,
      );
      const isColumn = editorAPI.columns.isColumn(selectedComponent);
      editorAPI.panelManager.openComponentPanel(
        'compPanels.dynamicPanels.motionPanel',
        {
          origin: 'gfpp',
          allowEntranceAnimation: isColumn,
          selectedComponent,
        },
      );
    },
    isSupported: isAnimationSupported,
    className: 'gfpp-button-motion',
    automationId: 'gfpp-button-motion',
  },
  link: {
    isSelected: gfppData.utils.getPanelStateFn('link'),
    isApplied: gfppData.utils.getDataDefinedFn('link'),
    isDisabled(editorAPI, compRef) {
      return someAncestorHasDefaultModeActive(editorAPI, compRef);
    },
    tooltip: 'gfpp_tooltip_linkto',
    tooltip_active: 'gfpp_tooltip_applied_linkto',
    icon: 'link',
    onClick: (editorAPI, compRefs) => {
      compRefs = platform.gfppTargetUtils.getSelectedComponents(
        compRefs,
        editorAPI,
        'link',
      );
      return gfppData.utils.openLinkPanel(editorAPI, compRefs);
    },
    automationId: 'gfpp-button-link',
  },
  textLink: {
    isSelected: gfppData.utils.getPanelStateFn('link'),
    isApplied: gfppData.utils.getDataDefinedFn('linkList'),
    isDisabled(editorAPI, compRef) {
      return someAncestorHasDefaultModeActive(editorAPI, compRef);
    },
    tooltip: 'gfpp_tooltip_linkto',
    tooltip_active: 'gfpp_tooltip_applied_linkto',
    icon: 'link',
    onClick: (editorAPI) => {
      editorAPI.text.startEditing(true);
    },
    automationId: 'gfpp-button-text-link',
  },
  hide: {
    isSelected: false,
    tooltip: 'gfpp_tooltip_mobile_hide',
    icon: 'gfpp_hide',
    className: 'gfpp-hide',
    automationId: 'gfpp-button-hide',
    onClick(editorAPI) {
      util.fedopsLogger.interactionStarted(
        util.fedopsLogger.INTERACTIONS.HIDE_COMP,
      );
      editorAPI.selectedComponent.remove().then((removed: AnyFixMe) => {
        if (removed) {
          editorAPI.mobile.hideHiddenItemsCounter();
          editorAPI.history.add('component - remove');
          util.fedopsLogger.interactionEnded(
            util.fedopsLogger.INTERACTIONS.HIDE_COMP,
          );
        }
      });
    },
    isSupported(editorAPI, compRefs) {
      const areAllSelectedHiddenable = compRefs.every(
        editorAPI.components.is.hiddenable,
      );
      return (
        editorAPI.components.is.removable(compRefs) &&
        !util.appStudioUtils.isAppStudio() &&
        areAllSelectedHiddenable
      );
    },
  },
  help: {
    tooltip: 'gfpp_tooltip_help',
    icon: 'gfpp_help',
    mobileHelpId: 'b6d9a270-f9c5-4837-995b-c3818b13a643',
    automationId: 'gfpp-button-help',
  },
  pinMode: {
    isApplied: true,
    isSelected: gfppData.utils.getPanelStateFn('panels.pinnedDockPanel'),
    tooltip: 'gfpp_tooltip_pin_to_screen',
    tooltip_active: 'gfpp_tooltip_applied_pin_to_screen',
    icon: 'gfpp_pin',
    automationId: 'gfpp-button-pin',
    className: 'gfpp-pin',
    onClick(editorAPI, compRef) {
      editorAPI.store.dispatch(
        pinModeActions.open('gfpp', {
          selectedComponents: platform.gfppTargetUtils.getSelectedComponents(
            compRef,
            editorAPI,
            'pinMode',
          ),
        }),
      );
    },
    isSupported(editorAPI, compRefs) {
      return (
        !editorAPI.isMobileEditor() &&
        editorAPI.components.layout.isPinned(compRefs)
      );
    },
  },
  accessibility: {
    isApplied: true,
    isSelected: gfppData.utils.getPanelStateFn(
      'compPanels.dynamicPanels.a11ySettingsPanel',
    ),
    tooltip: 'gfpp_mainaction_accessibility',
    tooltip_active: 'gfpp_mainaction_accessibility',
    icon: 'gfpp_accessibility',
    className: 'gfpp-accessibility',
    onClick(editorAPI, compRefs) {
      const panelProps = {
        selectedComponent: compRefs,
        selectedComponents: compRefs,
        selectedComponentType: editorAPI.components.getType(compRefs),
      };
      gfppData.utils.toggleComponentPanel(
        editorAPI,
        'compPanels.dynamicPanels.a11ySettingsPanel',
        panelProps,
      );
    },
    isSupported(editorAPI: EditorAPI, compRefs: CompRef[]) {
      return editorAPI.accessibility.isAccessibilitySupported(compRefs[0]);
    },
  },
  preview: {
    tooltip: 'gfpp_tooltip_preview',
    icon: 'gfpp_preview',
    onClick(editorAPI) {
      editorAPI.preview.togglePreview(undefined, {
        biParams: { origin: constants.BI.PREVIEW.ORIGIN.GFPP },
      });
    },
  },
  applyToOtherView: {
    isSelected: gfppData.utils.getPanelStateFn('apply'),
    tooltip(editorAPI) {
      const state = editorAPI.store.getState();
      const focusedContainer = getFocusedContainer(state);
      return editorAPI.components.modes.isCompDefaultModeActive(
        focusedContainer,
      )
        ? 'RightClick_Menu_Apply_Regular_ToHover_Label'
        : 'RightClick_Menu_Apply_Hover_ToRegular_Label';
    },
    icon: 'gfpp_applyTo',
    onClick: gfppData.utils.getTogglePanelFn('apply'),
    isSupported(editorAPI, compRefs) {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/size
      if (_.size(compRefs) === 1) {
        const compRef = _.head(compRefs);
        const compModes = editorAPI.components.modes.getModes(compRef);
        const containerComp =
          editorAPI.components.getContainer_DEPRECATED_BAD_PERFORMANCE(compRef);
        const containerModes =
          editorAPI.components.modes.getModes(containerComp);
        return (
          !editorAPI.isMobileEditor() && // TODO: Fix this the next time the file is edited.
          // eslint-disable-next-line you-dont-need-lodash-underscore/size
          (_.size(compModes) > 1 || _.size(containerModes) > 1)
        );
      }
      return false;
    },
  },
  add: {
    isSelected: false,
    tooltip: 'gfpp_tooltip_elements',
    icon: 'gfpp_elements',
    onClick: (editorAPI, compRefs, ...args) =>
      gfppData.utils.getTogglePanelFn('add')(
        editorAPI,
        platform.gfppTargetUtils.getSelectedComponents(
          compRefs,
          editorAPI,
          'add',
        ),
        ...args,
      ),
    automationId: 'gfpp-button-add',
  },
  customConnect: {
    isSelected: false,
    tooltip: 'gfpp_component_connect',
    tooltip_active: 'gfpp_component_connected_to_controllers',
    icon: 'connect',
    automationId: 'gfpp-button-custom-connect',
  },
  scale_up: {
    icon: GFPP_ACTIONS.SCALE_UP,
    shouldTranslate: false,
    tooltip: getScalingTooltip,
    isDisabled: isScalingDisabled.bind(null, true),
    keepTooltipOpenOnClick: true,
    onClick: (editorAPI, compRefs) =>
      changeScale(
        true,
        editorAPI,
        platform.gfppTargetUtils.getSelectedComponents(
          compRefs,
          editorAPI,
          'textSize',
        ),
      ),
    action_name: 'gfpp_text_scale_up',
    automationId: 'gfpp-button-scale-up',
  },
  scale_down: {
    icon: GFPP_ACTIONS.SCALE_DOWN,
    shouldTranslate: false,
    tooltip: getScalingTooltip,
    isDisabled: isScalingDisabled.bind(null, false),
    keepTooltipOpenOnClick: true,
    onClick: (editorAPI, compRefs) =>
      changeScale(
        false,
        editorAPI,
        platform.gfppTargetUtils.getSelectedComponents(
          compRefs,
          editorAPI,
          'textSize',
        ),
      ),
    action_name: 'gfpp_text_scale_down',
    automationId: 'gfpp-button-scale-down',
  },
  widgetPlugins: {
    isApplied: false,
    isSelected: (editorAPI) =>
      editorAPI.panelManager.isPanelOpened('panels.widgetPanels.pluginsPanel'),
    tooltip: 'PLATFORM_Widget_Slots_GFPP_Tooltip',
    icon: 'gfpp_widgetPlugin',
    automationId: 'gfpp-button-widget-plugins',
    onClick: (editorAPI) => {
      editorAPI.panelManager.openPanel('panels.widgetPanels.pluginsPanel');
    },
    isSupported: (editorAPI, [widgetRef]) => {
      const { hasWidgetSlot, getSlotPlaceholderRefByContentRef } =
        editorAPI.platform.widgetPlugins;

      const isSlotWidget =
        experiment.isOpen('se_widgetPlugins_scopedForSlot') &&
        !!getSlotPlaceholderRefByContentRef(widgetRef);

      return isSlotWidget || hasWidgetSlot(widgetRef);
    },
  },
};

const registeredActions: GFPPActionsDictionaryRaw = {};

const customizeDesignDefaults = {
  isSelected: gfppData.utils.getPanelStateFn('customizeDesign'),
  tooltip: 'gfpp_tooltip_customize',
  icon: 'gfpp_customize_design_drop',
  onClick: gfppData.utils.getTogglePanelFn('customizeDesign'),
};

const DEFAULTS_MOBILE = {
  customizeDesign: customizeDesignDefaults,
  [GFPP_ACTIONS.MOBILE_BACKGROUND_SETTINGS]:
    gfppData.backgroundSettingsGfppUtils.getMobileBackgroundSettingsAction,
};

if (experiment.isOpen('fastStyling')) {
  DEFAULTS.customizeDesign = customizeDesignDefaults;
}

function someAncestorHasDefaultModeActive(
  editorAPI: AnyFixMe,
  compRefs: AnyFixMe,
) {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/some
  return _.some(compRefs, function (compRef) {
    const firstAncestorWithMode =
      editorAPI.components.modes.getFirstAncestorWithActiveModes(compRef);
    return editorAPI.components.modes.isCompDefaultModeActive(
      firstAncestorWithMode,
    );
  });
}

function resolveDynamicValues(actions: AnyFixMe, resolveFunction: AnyFixMe) {
  if (!actions) {
    return null;
  }
  const resolveFunctionsIfNeeded = (val: AnyFixMe, key: AnyFixMe) =>
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/includes
    !_.includes(excludedKeys, key) && _.isFunction(val)
      ? resolveFunction(val)
      : val;
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/is-array
  const iterator = _.isArray(actions) ? 'map' : 'mapValues';
  return _(actions) // eslint-disable-line lodash/no-double-unwrap
    [iterator]((action: keyof typeof DEFAULTS) =>
      _.isString(action) && DEFAULTS[action]
        ? _.cloneDeep(DEFAULTS[action])
        : action,
    )
    [iterator]((action: AnyFixMe) =>
      _.isFunction(action) ? resolveFunction(action) : action,
    )
    [iterator]((action: AnyFixMe) =>
      _.isObject(action)
        ? _.mapValues(action, resolveFunctionsIfNeeded)
        : action,
    )
    .value();
}

function resolveAllDynamicValues(
  compGfppData: AnyFixMe,
  actionCategories: AnyFixMe,
  resolveFunction: AnyFixMe,
) {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
  _.forEach(actionCategories, function (category) {
    compGfppData[category] = resolveDynamicValues(
      compGfppData[category],
      resolveFunction,
    );
  });
}

function getMainActions(
  compInfo: GFPPDataRaw,
  isMobile: boolean,
  resolveFunction: GFPPDataResolver,
  isCurrentLanguageSecondary: boolean,
): GFPPAction[] {
  const getMainActionsData = () => {
    const { mobileMainActions, mainActions, secondaryLanguageMainActions } =
      compInfo;
    if (isMobile) {
      return mobileMainActions;
    }
    if (isCurrentLanguageSecondary) {
      return secondaryLanguageMainActions || mainActions;
    }
    return mainActions;
  };
  const mainActions = getMainActionsData();
  return resolveFunction(mainActions);
}

function getExtraActions(
  compInfo: GFPPDataRaw,
  isMobile: boolean,
  resolveFunction: GFPPDataResolver,
): GFPPAction[] {
  const extraActions = isMobile
    ? compInfo.mobileExtraActions
    : compInfo.extraActions;

  return resolveFunction(extraActions);
}

function isSecondaryLang(editorAPI: EditorAPI): boolean {
  const currentLanguageCode = editorAPI.language.current.get();
  const originalLanguage = editorAPI.language.original.get();
  const originalLanguageCode = originalLanguage?.languageCode;
  return (
    originalLanguageCode &&
    currentLanguageCode &&
    originalLanguageCode !== currentLanguageCode
  );
}

function getPresetActions(
  editorAPI: EditorAPI,
  compInfo: GFPPDataRaw,
  isMobile: boolean,
  resolveFunction: GFPPDataResolver,
): GFPPActionsDictionary {
  // NOTE: enabledActions are resolved before this function is called
  const enabledActions = (
    isMobile ? compInfo.mobileEnabledActions : compInfo.enabledActions
  ) as GFPPValueResolved<typeof compInfo.enabledActions>;
  const presetActions = resolveFunction(compInfo.presetActions);

  const resPresetActions = _.merge(
    {},
    _.pick(DEFAULTS, enabledActions),
    isMobile ? _.pick(DEFAULTS_MOBILE, enabledActions) : {},
    _.pick(presetActions, enabledActions),
    _.pick(registeredActions, enabledActions),
    _.pickBy(
      compInfo?.presetActionsData,
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/includes
      (val, key) => !_.isEmpty(val) && _.includes(enabledActions, key),
    ),
  ) as GFPPActionsDictionary;

  return resPresetActions;
}

function getHelpAction(
  editorAPI: EditorAPI,
  helpAction: GFPPAction,
  componentRefs: CompRef[],
): GFPPAction {
  const state = editorAPI.store.getState();

  if (editorAPI.isMobileEditor()) {
    const isContainingMobileOnlyComponents = util.array
      .asArray(componentRefs)
      .some((compRef) =>
        editorAPI.mobile.mobileOnlyComponents.isMobileOnlyNonNativeComponent(
          compRef,
        ),
      );
    helpAction.helpId = isContainingMobileOnlyComponents
      ? helpIds.GFPP_MOBILE.MOBILE_ONLY_COMP
      : helpIds.GFPP_MOBILE.MOBILE_COMP_FROM_DESKTOP;
  }

  if (helpAction.isSelected === undefined) {
    helpAction.isSelected = gfppData.utils.isHelpPanelOpen(
      editorAPI,
      helpAction.helpId,
    );
  }

  if (isInInteractionMode(state)) {
    helpAction.helpId = helpIds.INTERACTIONS.GFPP;
  }

  return helpAction;
}

function augmentCompEnabledActions(
  compGfppData: GFPPDataRaw,
  isMobile: boolean,
  resolveFunction: GFPPDataResolver,
): void {
  const key = isMobile ? 'mobileEnabledActions' : 'enabledActions';
  const enabledActions = _.clone(resolveFunction(compGfppData[key]) || []);

  compGfppData[key] = _.union(enabledActions, ENABLED_BY_METADATA);
}

export function getFullComponentGfppData(
  editorAPI: EditorAPI,
  compRefs: CompRef[],
  origin?: string,
  isAdditionalCompForGfpp: boolean = false,
): GFPPData {
  const componentRefs = platform.gfppTargetUtils.getSelectedComponents(
    compRefs,
    editorAPI,
  );
  const componentType = componentsSelectors.getCompType(
    componentRefs,
    editorAPI.dsRead,
  );
  const isMobile =
    editorAPI.isMobileEditor() &&
    util.array
      .asArray(componentRefs)
      .some(
        (compRef) =>
          !editorAPI.mobile.mobileOnlyComponents.isMobileOnlyNonNativeComponent(
            compRef,
          ),
      );
  const resolveFunction: GFPPDataResolver = gfppData.utils.getGfppDataResolver(
    editorAPI,
    componentRefs,
    origin,
  );

  // TODO: improve `resolveFunction` type to get rid of `as GFPPData`
  const compGfppData: GFPPData = _.cloneDeep(
    (resolveFunction(gfppData.getComponentData(componentType)) as GFPPData) ||
      DEFAULT_GFPP_DATA,
  );
  let additionalGfppData;
  if (compGfppData.additionalCompForGfpp) {
    additionalGfppData = getFullComponentGfppData(
      editorAPI,
      [compGfppData.additionalCompForGfpp],
      undefined,
      true,
    );
  }
  augmentCompEnabledActions(compGfppData, isMobile, resolveFunction);
  compGfppData.mainActions = getMainActions(
    compGfppData,
    isMobile,
    resolveFunction,
    editorAPI.language.isCurrentLanguageSecondary(),
  );
  gfppDataEnhancer.applyPostMainActionsOverrides(
    editorAPI,
    componentRefs,
    compGfppData,
  );
  compGfppData.extraActions = getExtraActions(
    compGfppData,
    isMobile,
    resolveFunction,
  );
  compGfppData.presetActions = getPresetActions(
    editorAPI,
    compGfppData,
    isMobile,
    resolveFunction,
  );
  gfppDataEnhancer.applyPostPresetActionsOverrides(
    editorAPI,
    componentRefs,
    compGfppData,
  );
  resolveAllDynamicValues(
    compGfppData,
    ['mainActions', 'extraActions', 'presetActions'],
    resolveFunction,
  );

  if (compGfppData.presetActions.help) {
    compGfppData.presetActions.help = getHelpAction(
      editorAPI,
      compGfppData.presetActions.help,
      componentRefs,
    );
  }

  compGfppData.presetActions = sanitizePresetActions(
    compGfppData.presetActions,
  );

  compGfppData.isAdditionalCompForGfpp = isAdditionalCompForGfpp;
  if (additionalGfppData) {
    compGfppData.mergeGfpp(compGfppData, additionalGfppData);
  }

  gfppDataEnhancer.applyGfppGlobalEnhancers(editorAPI, compRefs, compGfppData);

  return {
    mainActions: compGfppData.mainActions,
    extraActions: compGfppData.extraActions,
    presetActions: compGfppData.presetActions,
    previewState: compGfppData.previewState,
    untranslatable: compGfppData.untranslatable,
    translateAction: compGfppData.translateAction,
  };
}

function sanitizePresetActions(
  presetActions: GFPPActionsDictionary,
): GFPPActionsDictionary {
  return _.pickBy(presetActions, (action) => action.isSupported !== false);
}

export function registerGfppActions(gfppActions: GFPPActionsDictionary): void {
  _.merge(registeredActions, gfppActions);
}

function togglePanelWithWarning(
  panel: AnyFixMe,
  optionalProps?: Record<string, unknown>,
): GFPPActionOnClick {
  // Disable warning window for now
  return gfppData.utils.getTogglePanelFn(panel, optionalProps);
}

export function getComponentMainActionClick(
  editorAPI: EditorAPI,
  compRef: CompRef | CompRef[],
): GFPPActionOnClick {
  const compData = getFullComponentGfppData(
    editorAPI,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/is-array
    _.isArray(compRef) ? compRef : [compRef],
  );

  // TODO: check if `mainActions` could be unresolved here
  const mainActions: GFPPAction[] = _.isFunction(compData.mainActions)
    ? compData.mainActions(editorAPI, compRef)
    : compData.mainActions;

  if (mainActions?.length) {
    return mainActions[0].onClick;
  }
}
export { getFullComponentGfppData as getData };
