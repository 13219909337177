// @ts-nocheck
import constants from '@/constants';
import experiment from 'experiment';
import _ from 'lodash';
import * as stateManagement from '@/stateManagement';
import { isDataModeOn } from '@/wixData';
import addPanelDataConsts from '@/addPanelDataConsts';
import { array } from '@/util';
import { EditorParamsApiKey } from '@/apis';

const { isInteractionModeAvailable } = stateManagement.interactions.selectors;

interface CategoryDesc {
  sections: string[];
  title?: string;
}

function extendSubcategoryWithSection(
  defaults: CategoryDesc[],
  subcategoryTitle: string,
  sectionName: string,
): CategoryDesc[] {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/find-index
  const index = _.findIndex(
    defaults,
    ({ title }) => title === subcategoryTitle,
  );

  //if category doesn't exist add new category in the end of list
  if (index === -1) {
    const category = {
      sections: [sectionName],
      title: subcategoryTitle,
    };

    return [...defaults, category];
  }

  //otherwise extends existed category and put it on the current place
  const category = defaults[index];
  const extendedCategory = {
    ...category,
    sections: [...category.sections, sectionName],
  };

  return array.immutableSplice(defaults, index, 1, extendedCategory);
}

export default {
  page: {
    defaults: [
      {
        sections: ['addPagesSection', 'adminPagesSection'],
      },
    ],
  },
  text: {
    defaults: [
      {
        sections: [
          'section_1',
          'titlesSection',
          'paragraphsSection',
          'collapsibleTextParagraphsSection',
          'textMaskSection',
        ],
      },
    ],
  },
  image: {
    defaults: [
      {
        sections: [
          'myUploadsSection',
          'imageCollectionsSection',
          'mySocialImagesSection',
        ],
      },
    ],
  },
  [addPanelDataConsts.CATEGORIES_ID.IMAGE_DYNAMIC]: {
    defaults: [
      {
        sections: ['uploadsAndImportsSection', 'siteImagesSection'],
        title: 'add_section_subnav_title_image_my_images',
      },
      {
        sections: ['wixImagesSection', 'wixIllustrationsSection'],
        title: 'add_section_subnav_title_image_explore',
      },
    ],
  },
  gallery: {
    defaults: [
      {
        sections: [
          'gridGalleriesSection',
          'sliderGalleriesSection',
          'moreGalleriesSection',
        ],
      },
    ],
    modifier(editorAPI, defaults) {
      if (experiment.isOpen('se_replaceGalleriesAddPanel')) {
        defaults[0].sections = _.reject(
          defaults[0].sections,
          (section) => section === 'gridGalleriesSection',
        );
      }
      return defaults;
    },
  },
  boxSlideShow: {
    defaults: [
      {
        sections: [
          'stripBoxSlideShowSection',
          'boxSlideShow980Section',
          'boxSlideShowSection',
        ],
        title: 'add_section_subnav_title_interactive_slideshow',
      },
      {
        sections: [
          'popupWelcomeSection',
          'popupSubscribeSection',
          'popupSaleSection',
          'popupContactSection',
        ],
        title: 'add_section_subnav_title_interactive_lightbox',
      },
      {
        sections: ['hoverBoxSection'],
        title: 'add_section_subnav_title_interactive_box',
      },
      {
        sections: [],
        title: 'add_section_subnav_title_interactive_indicators',
      },
    ],
    modifier(editorAPI, defaults) {
      if (!defaults[0].sections.includes('stripBoxSlideShowSection')) {
        defaults[0].sections.unshift('stripBoxSlideShowSection');
      }
      if (editorAPI.dsRead.pages.popupPages.isPopupOpened()) {
        defaults[0].sections =
          editorAPI.pages.popupPages.isFullWidthPopupOpened()
            ? defaults[0].sections
            : _.difference(defaults[0].sections, ['stripBoxSlideShowSection']);
      }
      if (
        editorAPI.developerMode.isEnabled() &&
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/includes
        !_.includes(defaults[2].sections, 'stateBoxSection')
      ) {
        defaults[2].sections.push('stateBoxSection');
      }
      if (editorAPI.developerMode.isEnabled()) {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/includes
        if (!_.includes(defaults[3].sections, 'progressBarSection')) {
          defaults[3].sections.push('progressBarSection');
        }
      } else {
        _.remove(defaults[3].sections, (sec) => sec === 'progressBarSection');
      }
      if (editorAPI.developerMode.isEnabled() || isDataModeOn(editorAPI)) {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/includes
        if (!_.includes(defaults[3].sections, 'paginationSection')) {
          defaults[3].sections.push('paginationSection');
        }
      } else {
        _.remove(defaults[3].sections, (sec) => sec === 'paginationSection');
      }
      if (isInteractionModeAvailable(editorAPI.dsRead)) {
        _.remove(
          defaults[2].sections,
          (sectionName) => sectionName === 'hoverBoxSection',
        );
        if (!defaults[2].sections.includes('interactionsHoverSection')) {
          defaults[2].sections.push('interactionsHoverSection');
        }
      }

      return defaults;
    },
  },
  box: {
    defaults: [
      {
        sections: [
          'section_1',
          'section_2',
          'containerBoxesSection',
          'hoverBoxSection',
        ],
      },
    ],
    modifier(editorAPI, defaults) {
      if (editorAPI.developerMode.isEnabled()) {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/includes
        if (!_.includes(defaults[0].sections, 'stateBoxSection')) {
          defaults[0].sections.push('stateBoxSection');
        }
      }
      if (isInteractionModeAvailable(editorAPI.dsRead)) {
        _.remove(
          defaults[0].sections,
          (sectionName) => sectionName === 'hoverBoxSection',
        );
        if (!defaults[0].sections.includes('interactionsHoverSection')) {
          defaults[0].sections.push('interactionsHoverSection');
        }
      }
      return defaults;
    },
  },
  strip: {
    defaults: [
      {
        sections: [
          'stripContainerAboutSection',
          'stripContainerContactSection',
          'stripContainerWelcomeSection',
          'stripContainerServicesSection',
          'stripContainerTeamSection',
          'stripContainerTestimonialsSection',
          'stripContainerClassicSection',
        ],
      },
    ],
  },
  button: {
    defaults: [
      {
        sections: [
          'cashierButtons',
          'section_1',
          'section_2',
          'textButtonsSection',
          'iconButtonsSection',
          'fileDownloadButtonsSection',
          'iTunesDownloadButtonSection',
        ],
        title: 'add_section_subnav_title_buttons_buttons',
      },
      {
        sections: ['payButtonSection', 'paypalButtonsSection'],
        title: 'add_section_subnav_title_buttons_payment_buttons',
      },
    ],
    modifier(editorAPI, defaults) {
      if (
        (editorAPI.developerMode.isEnabled() || isDataModeOn(editorAPI)) &&
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/includes
        !_.includes(defaults[0].sections, 'fileUploaderSection')
      ) {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/find-index
        const fileUploaderIndex = _.findIndex(
          defaults[0].sections,
          'fileDownloadButtonsSection',
        );
        defaults[0].sections.splice(
          fileUploaderIndex,
          0,
          'fileUploaderSection',
        );
      }
      if (
        experiment.isOpen('se_stylablePanel') &&
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/includes
        !_.includes(defaults[0].sections, 'stylableButtonSection')
      ) {
        defaults[0].sections.splice(3, 0, 'stylableButtonSection');
        if (experiment.isOpen('se_stylableButtonThemes')) {
          defaults[0].sections.splice(3, 0, 'stylableButtonTheme');
          _.remove(defaults[0].sections, (section) => section === 'section_2');
        }
        _.remove(
          defaults[0].sections,
          (section) =>
            section === 'textButtonsSection' || section === 'section_1',
        );
      }
      return defaults;
    },
  },
  repeater: {
    defaults: [
      {
        sections: ['repeaterSection'],
      },
    ],
    modifier(editorAPI, defaults) {
      if (
        !defaults[0].sections.includes('gridSection') &&
        (editorAPI.developerMode.isEnabled() || isDataModeOn(editorAPI))
      ) {
        defaults[0].sections.push('gridSection');
      }

      return defaults;
    },
  },
  social: {
    defaults: [
      {
        sections: [
          'socialBarSection',
          'facebookSection',
          'twitterSection',
          'pinterestSection',
          'vkSection',
          'lineSection',
        ],
        title: 'add_section_subnav_title_social_social_chat',
      },
      {
        sections: ['youtubeSocialSection'],
        title: 'add_section_subnav_title_social_music_video',
      },
    ],
  },
  contact: {
    defaults: [
      {
        sections: [
          'contactSection',
          'paymentSection',
          'registrationsSection',
          'donationsSection',
          'jobApplicationSection',
          'feedbackSection',
          'requestSection',
          'waiverSection',
          'subscribersSection',
        ],
        title: 'add_section_subnav_title_contact_form',
      },
      {
        sections: [
          'googleMapsSquareSection',
          'googleMapsGenericSection',
          'googleMapsStripSection',
        ],
        title: 'add_section_subnav_title_contact_google_maps',
      },
      {
        sections: ['lineSection'],
        title: 'add_section_subnav_title_contact_chat',
      },
    ],
  },
  list: {
    defaults: [
      {
        sections: [],
      },
    ],
  },
  decorative: {
    defaults: [
      {
        sections: [
          'myLinesSection',
          'themedLinesSection',
          'basicShapesSection',
          'arrowsSection',
          'horizontalLinesSection',
          'verticalLinesSection',
        ],
        title: 'add_section_subnav_title_decorative_shapes',
      },
      {
        sections: [
          'featuredSection',
          'iconsSection',
          'animalNatureSection',
          'logosBadgesSection',
          'charactersSection',
        ],
        title: 'add_section_subnav_title_decorative_vector_art',
      },
      {
        sections: [
          'videoBoxSection',
          'videoBoxMaskedSection',
          'videoBoxTransparentSection',
        ],
        title: 'add_section_subnav_title_decorative_video',
      },
    ],
    modifier(editorAPI, defaults) {
      if (
        experiment.isOpen('se_addPanel_vectorArt_featuredSection') &&
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/includes
        !_.includes(defaults[1].sections, 'campaign_featuredSection')
      ) {
        defaults[1].sections[defaults[1].sections.indexOf('featuredSection')] =
          'campaign_featuredSection';
      }

      return defaults;
    },
  },
  navigation: {
    defaults: [
      {
        sections: [
          'menuMySection',
          'menuThemeSection',
          'horizontalMenusSection',
          'verticalMenusSection',
          'verticalAnchorsMenuSection',
          'anchorsSection',
        ],
        title: 'add_section_subnav_title_navigation_menu',
      },
      {
        sections: [],
        title: 'add_section_subnav_title_navigation_search_corvid_off',
      },
    ],
    modifier(editorAPI, defaults) {
      if (
        editorAPI.dsRead.language.multilingual.isEnabled() &&
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/includes
        !_.includes(defaults[0].sections, 'languageSelectorSection')
      ) {
        defaults[0].sections.splice(2, 0, 'languageSelectorSection');
      }
      if (editorAPI.dsRead.pages.popupPages.isPopupOpened()) {
        defaults[0].sections = _.difference(
          defaults[0].sections,
          constants.ADD_PANEL_DATA.POPUP.FORBIDDEN_SECTIONS_OF_OTHER_CATEGORY,
        );
      } else {
        if (!defaults[0].sections.includes('verticalAnchorsMenuSection')) {
          defaults[0].sections.push('verticalAnchorsMenuSection');
        }
        if (!defaults[0].sections.includes('anchorsSection')) {
          defaults[0].sections.push('anchorsSection');
        }
        if (!defaults[0].sections.includes('breadcrumbsSection')) {
          defaults[0].sections.push('breadcrumbsSection');
        }
      }
      if (!defaults[0].sections.includes('stylableHorizontalMenuSection')) {
        const horizontalMenusIndex = defaults[0].sections.findIndex(
          (section: string) => section === 'horizontalMenusSection',
        );
        defaults[0].sections.splice(
          horizontalMenusIndex,
          1,
          'stylableHorizontalMenuSection',
        );
      } else if (
        experiment.isOpen('se_NewHorizontalMenuAddPanel') &&
        !defaults[0].sections.includes('stylableHorizontalMenuSection')
      ) {
        const verticalMenusIndex = defaults[0].sections.findIndex(
          (section: string) => section === 'verticalMenusSection',
        );
        defaults[0].sections.splice(
          verticalMenusIndex + 1,
          0,
          'stylableHorizontalMenuSection',
        );
        defaults[0].sections.splice(
          verticalMenusIndex + 2,
          0,
          'stylableMenuTheme',
        );
      }

      return defaults;
    },
  },
  media: {
    defaults: [
      {
        sections: [
          'socialVideoSection',
          'videoBoxSection',
          'videoBoxMaskedSection',
          'videoBoxTransparentSection',
        ],
        title: 'add_section_subnav_title_media_video',
      },
      {
        sections: ['musicThemeSection', 'iTunesDownloadButtonSection'],
        title: 'add_section_subnav_title_media_music',
      },
    ],
  },
  developer: {
    defaults: [
      {
        sections: ['textInputSection', 'textAreaSection', 'richTextBoxSection'],
        title: 'add_section_subnav_title_input_text',
      },
      {
        sections: [
          'radioGroupSection',
          'checkboxGroupSection',
          'checkboxSection',
          'selectionSection',
          'selectionTagsListSection',
          'datePickerSection',
          'timePickerSection',
          'sliderSection',
          'rangeSliderSection',
        ],
        title: 'add_section_subnav_title_input_selection',
      },
      {
        sections: [
          'fileUploaderSection',
          'ratingsInputSection',
          'ratingsDisplaySection',
          'addressInputSection',
          'toggleSwitchSection',
        ],
        title: 'add_section_subnav_title_input_advanced',
      },
    ],
    modifier(editorAPI, defaults) {
      if (!defaults[2].sections.includes('signatureInputSection')) {
        defaults[2].sections.push('signatureInputSection');
      }

      if (editorAPI.developerMode.isEnabled()) {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/includes
        if (!_.includes(defaults[2].sections, 'captchaSection')) {
          defaults[2].sections.push('captchaSection');
        }
      }

      const isResponsiveEditor =
        editorAPI.host.getAPI(EditorParamsApiKey).isInsideEditorX;

      if (
        (isResponsiveEditor &&
          experiment.isOpen('se_appointmentFieldInAddPanelResponsive')) ||
        (!isResponsiveEditor &&
          experiment.isOpen('se_appointmentFieldInAddPanel'))
      ) {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/includes
        if (!_.includes(defaults[1].sections, 'appointmentFieldSection')) {
          const sliderSectionIndex = defaults[1].sections.findIndex(
            (sectionName) => sectionName === 'sliderSection',
          );
          const appointmentSectionName = 'appointmentFieldSection';
          if (sliderSectionIndex > -1) {
            defaults[1].sections.splice(
              sliderSectionIndex,
              0,
              appointmentSectionName,
            );
          } else {
            defaults[1].sections.push(appointmentSectionName);
          }
        }
      }
      return defaults;
    },
  },
  database: {
    defaults: [
      {
        sections: [
          'presetsSection',
          'collectionsSection',
          'dataBindingSection',
          'richContent',
        ],
      },
    ],
    modifier(editorAPI, defaults) {
      if (!isDataModeOn(editorAPI)) {
        return [
          {
            sections: ['databaseWelcomeSection'],
          },
        ];
      }
      return defaults;
    },
  },
  popup: {
    defaults: [
      {
        sections: [
          'popupWelcomeSection',
          'popupSubscribeSection',
          'popupSaleSection',
          'popupContactSection',
        ],
      },
    ],
  },
  embeds: {
    defaults: [
      {
        sections: ['customEmbedsSection'],
        title: 'add_section_subnav_title_embed_custom',
      },
      {
        sections: ['socialVideoSection'],
        title: 'add_section_subnav_title_embed_video',
      },
      {
        sections: ['iTunesDownloadButtonSection'],
        title: 'add_section_subnav_title_embed_audio',
      },
    ],
    modifier(editorAPI, defaults: CategoryDesc[]) {
      const advancedSubcategoryTitle =
        'add_section_subnav_title_embed_advanced';

      defaults = extendSubcategoryWithSection(
        defaults,
        advancedSubcategoryTitle,
        'marketingIntegrationsSection',
      );

      defaults = extendSubcategoryWithSection(
        defaults,
        advancedSubcategoryTitle,
        'corvidDevModeSection',
      );

      return defaults;
    },
  },
  blog: {
    defaults: [
      {
        sections: [],
      },
    ],
  },
  newBlogWelcome: {
    defaults: [
      {
        sections: ['blogWelcomeSection'],
      },
    ],
  },
  store: {
    defaults: [
      {
        sections: [],
      },
    ],
  },
  onlineProgramsWelcome: {
    defaults: [
      {
        sections: ['onlineProgramsWelcomeSection'],
      },
    ],
  },
  onlinePrograms: {
    defaults: [
      {
        sections: ['programsListWidget'],
        title: 'add_section_subnav_title_online_programs_elements',
      },
    ],
  },
  bookings: {
    defaults: [
      {
        sections: ['serviceWidget', 'offeringListWidget', 'bookButtonSection'],
      },
    ],
    modifier(editorAPI, defaults) {
      defaults[0].title = 'add_section_subnav_title_bookings_elements';
      if (
        stateManagement.platform.selectors.isRefComponentSupported(editorAPI) &&
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/includes
        !_.includes(defaults[0].sections, 'timetableSection')
      ) {
        defaults[0].sections.push('timetableSection');
      }
      if (
        stateManagement.platform.selectors.isRefComponentSupported(editorAPI) &&
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/includes
        !_.includes(defaults[0].sections, 'staffListSection')
      ) {
        defaults[0].sections.push('staffListSection');
      }
      if (
        !defaults.some(
          ({ title }) =>
            title === 'add_section_subnav_title_bookings_related_elements',
        )
      ) {
        defaults.push({
          sections: ['healthWaiversSection'],
          title: 'add_section_subnav_title_bookings_related_elements',
        });
      }

      defaults[0].sections.push('weeklyTimetableWidget');

      if (experiment.isOpen('se_bookingsAddDailyAgendaWidget')) {
        defaults[0].sections.push('dailyAgendaWidget');
      }

      if (experiment.isOpen('se_bookingsAddCalendarWidget')) {
        defaults[0].sections.push('calendarWidget');
      }
      return defaults;
    },
  },
  bookingsWelcome: {
    defaults: [
      {
        sections: ['bookingsWelcomeSection'],
      },
    ],
  },
  events: {
    defaults: [
      {
        sections: [],
      },
    ],
  },
  eventsWelcome: {
    defaults: [
      {
        sections: ['eventsWelcomeSection'],
      },
    ],
  },
  members: {
    defaults: [
      {
        sections: [
          'loginBarSection',
          'memberProfileSection',
          'memberMenuSection',
          'staffWidgetSection',
        ],
      },
    ],
  },
  membersWelcome: {
    defaults: [
      {
        sections: ['membersWelcomeSection'],
      },
    ],
  },
  savedComponents: {
    defaults: [
      {
        sections: ['savedComponentsSection'],
      },
    ],
  },
  savedComponentsWelcome: {
    defaults: [
      {
        sections: ['savedComponentsWelcomeSection'],
      },
    ],
  },
};
