import React from 'react';
import * as util from '@/util';
import { translate } from '@/i18n';
import {
  Text,
  CustomScroll,
  Button,
  ContextMenu,
  ContextMenuAction,
} from '@wix/wix-base-ui';
import { mapStateToProps, mapDispatchToProps } from './manageContent.mapper';
import type {
  ManageContentOwnProps,
  ManageContentStateProps,
  ManageContentDispatchProps,
} from './manageContent.mapper';
import type { Schema } from '@wix/wix-data-schema-types';
import { getGroupedCollectionsSections } from '../utils/collection';
import { isSharedCollection } from '../utils/sharedCollection';
import CollectionItem from '../collectionItem';
import LoadCollectionsPlaceholder from '../loadCollectionsPlaceholder';
import styles from './manageContent.scss';
import * as symbols from '@wix/santa-editor-symbols';
import VideoTooltip from '../videoTooltip/videoTooltip';
import { settingsClicked } from '../../bi';
import { getYoutubeUrl } from '../utils/youtubeVideoUrl';
import { DataTable, Settings } from '@wix/wix-ui-icons-common/classic-editor';
import { isResponsiveEditor } from '@wix/santa-editor-utils';

interface ManageContentProps
  extends ManageContentOwnProps,
    ManageContentDispatchProps,
    ManageContentStateProps {}

const getCollectionsWithSections = (collections: Schema[]) => {
  const { collectionsOutOfSections, collectionsSections } =
    getGroupedCollectionsSections(collections);

  return collectionsOutOfSections.length
    ? [
        {
          collections: collectionsOutOfSections,
          namespace: translate(
            'CONTENT_MANAGER_6_BUTTON_MANAGE_CONTENT_MAIN_TITLE',
          ),
          isEditable: true,
        },
        ...collectionsSections,
      ]
    : collectionsSections;
};

const ManageContent: React.FC<ManageContentProps> = ({
  collections,
  isLoading,
  openCreateCollectionPanel,
  openVideoPanel,
  openContentManagerAdvancedSettings,
  openContentManagerCollectionsList,
  isPermittedToAddCollection,
  sendBi,
}) => {
  const openAdvancedSettingsAndSendBi = (
    actionName: string,
    isEmptyState: boolean,
  ) => {
    sendBi(settingsClicked, {
      actionName,
      isEmptyState,
    });
    openContentManagerAdvancedSettings();
  };

  const settingsContextActions = [
    {
      title:
        'CONTENT_MANAGER_6_BUTTON_MANAGE_CONTENT_SETTINGS_BUTTON_VIEW_COLLECTIONS',
      icon: <DataTable className={styles.contextActionIcon} />,
      action: () => {
        sendBi(settingsClicked, {
          actionName: 'view_all_collections',
          isEmptyState: false,
        });
        openContentManagerCollectionsList();
      },
      dataHook: 'view-collections-option',
    },
    {
      title:
        'CONTENT_MANAGER_6_BUTTON_MANAGE_CONTENT_SETTINGS_BUTTON_COLLECTION_SETTINGS',
      icon: <Settings className={styles.contextActionIcon} />,
      action: () => openAdvancedSettingsAndSendBi('advanced_settings', false),
      dataHook: 'collection-settings-option',
    },
  ];

  return (
    <div className={styles.manageContent}>
      <div>
        <LoadCollectionsPlaceholder
          isLoading={isLoading}
          hasCollections={collections.length > 0}
          emptyStateTitle="CONTENT_MANAGER_6_BUTTON_MANAGE_CONTENT_COLLECTION_EMPTY_STATE_TITLE"
          emptyStateDescription="CONTENT_MANAGER_6_BUTTON_MANAGE_CONTENT_COLLECTION_EMPTY_STATE_SUBTITLE"
          emptyStateIcon="collectionsManagerEmptyState_NewWorkspace"
          dataHook="collections-manager-page"
          buttonTitle="CONTENT_MANAGER_6_BUTTON_MANAGE_CONTENT_COLLECTION_EMPTY_STATE_BUTTON"
          onSettingsIconClick={openAdvancedSettingsAndSendBi}
          videoTooltipProps={
            !isResponsiveEditor() && {
              title:
                'CONTENT_MANAGER_6_BUTTON_MANAGE_CONTENT_COLLECTION_EMPTY_STATE_TOOLTIP_VIDEO_TITLE',
              subtitle:
                'CONTENT_MANAGER_6_BUTTON_MANAGE_CONTENT_COLLECTION_EMPTY_STATE_TOOLTIP_VIDEO_SUBTITLE',
              imageUrl: 'addPanelData/sections/dataBinding/collectionVideo.png',
              openVideoPanel: () => {
                openVideoPanel(
                  translate(
                    'CONTENT_MANAGER_6_BUTTON_MANAGE_CONTENT_COLLECTION_EMPTY_STATE_TOOLTIP_VIDEO_ARTICLEID',
                  ),
                  true,
                );
              },
              sendBi,
              videoUrl: getYoutubeUrl(
                translate(
                  'CONTENT_MANAGER_6_BUTTON_MANAGE_CONTENT_COLLECTION_EMPTY_STATE_TOOLTIP_VIDEO_ARTICLEID',
                ),
              ),
              isEmptyState: true,
              tabName: 'content_manager',
            }
          }
          sendBi={sendBi}
        >
          <div className={styles.sectionList}>
            <CustomScroll heightRelativeToParent="100%">
              {getCollectionsWithSections(collections).map((section, index) => (
                <div
                  key={section.namespace}
                  className={styles.section}
                  data-hook={`${section.namespace}-section`}
                >
                  <div className={styles.sectionHeader}>
                    <Text
                      size="small"
                      shouldTranslate={false}
                      dataHook="section-heading"
                    >
                      {section.namespace}
                    </Text>
                    {index === 0 && (
                      <div className={styles.videoSettingsContainer}>
                        {!isResponsiveEditor() && (
                          <VideoTooltip
                            className={styles.videoTooltip}
                            data-hook="video-tooltip"
                            openVideoPanel={() => {
                              openVideoPanel(
                                translate(
                                  'CONTENT_MANAGER_6_BUTTON_MANAGE_CONTENT_COLLECTIONS_TOOLTIP_VIDEO_ID',
                                ),
                                false,
                              );
                            }}
                            title="CONTENT_MANAGER_6_BUTTON_MANAGE_CONTENT_COLLECTIONS_TOOLTIP_VIDEO_TITLE"
                            subtitle="CONTENT_MANAGER_6_BUTTON_MANAGE_CONTENT_COLLECTIONS_TOOLTIP_VIDEO_SUBTITLE"
                            imageUrl={
                              'addPanelData/sections/dataBinding/contentManagerVideoThumbnail.png'
                            }
                            sendBi={sendBi}
                            videoUrl={getYoutubeUrl(
                              translate(
                                'CONTENT_MANAGER_6_BUTTON_MANAGE_CONTENT_COLLECTIONS_TOOLTIP_VIDEO_ID',
                              ),
                            )}
                            isEmptyState={false}
                            tabName="content_manager"
                          />
                        )}
                        <ContextMenu
                          dataHook="settings-menu"
                          customButton={
                            <div
                              data-hook="context-menu-button"
                              className={styles.contextMenuButton}
                            >
                              <symbols.symbol
                                name="settingsAction"
                                className={styles.groupContextMenuSymbol}
                              />
                            </div>
                          }
                          onToggle={(isOpen: boolean) =>
                            isOpen &&
                            sendBi(settingsClicked, {
                              actionName: 'settings',
                              isEmptyState: false,
                            })
                          }
                        >
                          {settingsContextActions.map(
                            ({ title, icon, action, dataHook }) => (
                              <ContextMenuAction
                                key={dataHook}
                                onClick={action}
                                dataHook={dataHook}
                              >
                                {icon}
                                <span data-hook="action-label">
                                  {translate(title)}
                                </span>
                              </ContextMenuAction>
                            ),
                          )}
                        </ContextMenu>
                      </div>
                    )}
                  </div>
                  {index === 0 && (
                    <div className={styles.sectionSubheader}>
                      <Text
                        size="small"
                        weight="thin"
                        dataHook="manage-content-subtitle"
                        enableEllipsis={false}
                        skin="secondary"
                        shouldTranslate={false}
                      >
                        {translate(
                          'CONTENT_MANAGER_6_BUTTON_MANAGE_CONTENT_COLLECTIONS_SUBTITLE',
                        )}
                      </Text>
                    </div>
                  )}
                  <div className={styles.sectionDivider} />
                  <div className={styles.sectionCollections}>
                    {section.collections.map((collection: AnyFixMe) => (
                      <CollectionItem
                        key={collection.id}
                        collectionId={collection.id}
                        collectionName={collection.displayName}
                        collectionPermissions={collection.permissions}
                        iconName={
                          collection?.plugins?.singleItem
                            ? 'singleItemCollectionItemIcon'
                            : 'userCollectionItemIcon'
                        }
                        isEditable={section.isEditable}
                        isSharedCollection={isSharedCollection(collection)}
                        aid="collection-item"
                        sendBi={sendBi}
                      />
                    ))}
                  </div>
                </div>
              ))}
            </CustomScroll>
          </div>
          {isPermittedToAddCollection && (
            <div className={styles.buttonContainer}>
              <Button
                onClick={openCreateCollectionPanel}
                dataHook="add-new-collection-button"
              >
                {translate(
                  'SiteApp_WixData_DataManager_Tab_NewCollection_Button',
                )}
              </Button>
            </div>
          )}
        </LoadCollectionsPlaceholder>
      </div>
    </div>
  );
};

export default util.hoc.connect(
  util.hoc.STORES.EDITOR_API,
  mapStateToProps,
  mapDispatchToProps,
)(ManageContent);
