import type { Scope } from '@/sections';

import { enforceSectionsLayoutOnHomepage } from './enforceSectionsLayoutOnHomepage';
import { createSectionsEnforcementOnViewerChanges } from './enforcement';
import { createAddBlankSectionOnViewerChanges } from './addBlankSection';
import { createAdjustPageMinHeightOnViewerChanges } from './adjustPageMinHeight';
import { createBeforePageNavigateEnforcement } from './createBeforePageNavigateEnforcement';
import { createAfterPageNavigateEnforcement } from './createAfterPageNavigateEnforcement';

const bootstrapEnforcement = (scope: Scope) => {
  const { editorAPI } = scope;

  const enforceSectionsOnViewerChanges =
    createSectionsEnforcementOnViewerChanges(scope);
  const addBlankSectionIfNeeded = createAddBlankSectionOnViewerChanges(scope);
  const adjustPageMinHeight = createAdjustPageMinHeightOnViewerChanges(scope);
  const enforceSectionContainerBeforeNavigation =
    createBeforePageNavigateEnforcement(scope);

  editorAPI.pages.hooks.beforePageNavigate.tap(
    enforceSectionContainerBeforeNavigation,
  );
  editorAPI.registerPageNavigationCallback(
    createAfterPageNavigateEnforcement(scope),
  );
  editorAPI.registerToViewerChange(enforceSectionsOnViewerChanges);
  editorAPI.registerToViewerChange(addBlankSectionIfNeeded);
  editorAPI.registerToViewerChange(adjustPageMinHeight);

  enforceSectionsLayoutOnHomepage(scope);
};

export { bootstrapEnforcement };
