import _ from 'lodash';
import { createNonThemedAction } from '../utils/nonThemedAction';
import type {
  NonStyleAction,
  NonThemedActionCreationData,
} from '../utils/textAction';
import type { TextManager } from 'types/data';

export const TextAlign: { [key: string]: string } = {
  Left: 'justifyleft',
  Center: 'justifycenter',
  Right: 'justifyright',
  Justify: 'justifyblock',
} as const;

export const CmdDefToStyleDef: { [key: string]: string } = {
  justifyleft: 'left',
  justifycenter: 'center',
  justifyright: 'right',
  justifyblock: 'justify',
} as const;

export type CmdDefToStyleDef =
  typeof CmdDefToStyleDef[keyof typeof CmdDefToStyleDef];

const justifyLeftAction: Readonly<
  NonStyleAction<CmdDefToStyleDef | undefined>
> = {
  execCommand: (textManager, textAlign) => {
    if (textAlign === 'left') {
      textManager.commands.justifyleft();
    }
  },
  getWixRichTextCommandState: (textManager) =>
    textManager.getCommandsStates().justifyleft
      ? CmdDefToStyleDef.justifyleft
      : undefined,
  execRevertCommand: (textManager) => {
    if (textManager.getCommandsStates().justifyleft) {
      textManager.commands.justifyleft();
    }
  },
};

const justifyCenterAction: Readonly<
  NonStyleAction<CmdDefToStyleDef | undefined>
> = {
  execCommand: (textManager, textAlign) => {
    if (textAlign === 'center') {
      textManager.commands.justifycenter();
    }
  },
  getWixRichTextCommandState: (textManager) =>
    textManager.getCommandsStates().justifycenter
      ? CmdDefToStyleDef.justifycenter
      : undefined,
  execRevertCommand: (textManager) => {
    if (textManager.getCommandsStates().justifycenter) {
      textManager.commands.justifycenter();
    }
  },
};

const justifyRightAction: Readonly<
  NonStyleAction<CmdDefToStyleDef | undefined>
> = {
  execCommand: (textManager, textAlign) => {
    if (textAlign === 'right') {
      textManager.commands.justifyright();
    }
  },
  getWixRichTextCommandState: (textManager) =>
    textManager.getCommandsStates().justifyright
      ? CmdDefToStyleDef.justifyright
      : undefined,
  execRevertCommand: (textManager) => {
    if (textManager.getCommandsStates().justifyright) {
      textManager.commands.justifyright();
    }
  },
};

const justifyBlockAction: Readonly<
  NonStyleAction<CmdDefToStyleDef | undefined>
> = {
  execCommand: (textManager, textAlign) => {
    if (textAlign === 'block') {
      textManager.commands.justifyblock();
    }
  },
  getWixRichTextCommandState: (textManager) =>
    textManager.getCommandsStates().justifyblock
      ? CmdDefToStyleDef.justifyblock
      : undefined,
  execRevertCommand: (textManager) => {
    if (textManager.getCommandsStates().justifyblock) {
      textManager.commands.justifyblock();
    }
  },
};

const justifyActions = [
  justifyLeftAction,
  justifyCenterAction,
  justifyRightAction,
  justifyBlockAction,
] as const;

export const getJustifyAction = (_textManager: TextManager) => {
  const justifyActionCreationData: Readonly<
    NonThemedActionCreationData<CmdDefToStyleDef | undefined>
  > = {
    execCommand: (textManager, actionInput) => {
      justifyActions.forEach((action) =>
        action.execCommand(textManager, actionInput),
      );
    },
    getWixRichTextCommandState: (textManager) =>
      _.compact(
        justifyActions.map((action) =>
          action.getWixRichTextCommandState(textManager),
        ),
      )[0],
    execRevertCommand: (textManager) => {
      justifyActions.forEach((action) => action.execRevertCommand(textManager));
    },
    createUpdatedStyleProperties: (textAlign: CmdDefToStyleDef) => ({
      textAlign,
    }),
    getPropertyValue: (styleProperties) => styleProperties.textAlign,
    getDefaultValue: () =>
      _textManager.getCommandsStates().bidiltr
        ? CmdDefToStyleDef.justifyright
        : CmdDefToStyleDef.justifyleft,
  };

  return createNonThemedAction(justifyActionCreationData);
};
