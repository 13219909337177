import { serviceTopology, languages } from '@/util';

import * as helpIds from '@/helpIds';
import constants from '@/constants';
import { TourStartOrigin } from '@/editorTours';

const HELP_ITEMS = {
  DESKTOP: '58406e7a-1ad0-4105-8e37-c053b529f9d8',
  MOBILE: 'f8b24a99-cefd-4c55-adda-aa66ec643259',
  WIXCODE_DESKTOP: 'b06a142e-7796-4fa3-9425-17c84894d10a',
  WIXCODE_MOBILE: 'b31c9491-7c23-45c7-b3b2-5cb7aed2455b',
  KEYBOARD_SHORTCUTS: '5a3238e6-ebe9-4aca-a7a2-1c717598e334',
  CONNECT_DOMAIN: 'a06941d9-7683-4ced-bce3-bd2cc3c7d6e9',
  SEO: 'ab2c2a83-5bc0-435e-ab82-f2c1036580a7',
};

const BI_HELP_CONTEXT_NAME = {
  EDITOR: 'editor',
  KEYBOARD_SHORTCUTS: 'kb_shortcuts',
  SEO: 'seo',
  CONNECT_DOMAIN: 'connect_domain',
};

const WIX_LEARN_LINK =
  'learn/library/web-design?utm_source=editor_header&utm_id=build_with_the_wix_editor';

const { HELP_MENU_ITEMS } = constants.ROOT_COMPS.TOPBAR;
const { EXTERNAL_LINKS } = constants.OVERRIDDEN;

interface GetArticleTypeProps {
  isDeveloperModeEnabled: boolean;
  isMobileEditor: boolean;
}

function getArticleType(props: GetArticleTypeProps) {
  const desktopType = props.isDeveloperModeEnabled
    ? helpIds.HELP_CENTER.PAGE_TYPES.CORVID
    : helpIds.HELP_CENTER.PAGE_TYPES.DESKTOP;
  const mobileType = props.isDeveloperModeEnabled
    ? helpIds.HELP_CENTER.PAGE_TYPES.CORVID_MOBILE
    : helpIds.HELP_CENTER.PAGE_TYPES.MOBILE;
  return props.isMobileEditor ? mobileType : desktopType;
}

export interface HelpMenuAPIDispatchProps {
  openHelpCenter: (
    helpId: string,
    props: Record<string, unknown>,
    biHelpParams: Record<string, unknown>,
  ) => void;
  openHelpCenterHome: (
    helpId: string,
    props: Record<string, unknown>,
    biHelpParams: Record<string, unknown>,
  ) => void;
  openSearchPanel: (origin: string) => void;
  openPanel: (
    panelName: string,
    panelProps?: Record<string, any>,
    leavePanelsOpen?: boolean,
  ) => void;
  showOrHideChat: (visibility: boolean) => void;
  startWelcomeTour: (startOrigin?: TourStartOrigin) => Promise<undefined>;
  startAdiToEditorTour: (startOrigin?: TourStartOrigin) => Promise<undefined>;
  startEditorToEditorTour: (
    startOrigin?: TourStartOrigin,
  ) => Promise<undefined>;
  startEditorNewUsersTour: (
    startOrigin?: TourStartOrigin,
  ) => Promise<undefined>;
  openNewReleasesFeed: () => void;
  openHelpHome: () => void;
}

export interface HelpMenuAPIStateProps {
  isMobileEditor: boolean;
  isDeveloperModeEnabled: boolean;
  overriddenExternalLinks: AnyFixMe;
  isChatVisible: boolean;
}

export interface HelpMenuAPIOwnProps {}

export interface HelpMenuAPIProps
  extends HelpMenuAPIOwnProps,
    HelpMenuAPIDispatchProps,
    HelpMenuAPIStateProps {}

export const getHelpMenuAPI = (props: HelpMenuAPIProps) => ({
  [HELP_MENU_ITEMS.HELP_EDITOR_SEARCH]() {
    props.openSearchPanel('settings');
  },
  [HELP_MENU_ITEMS.HELP_CENTER]() {
    const biHelpParams = {
      panel_name: BI_HELP_CONTEXT_NAME.EDITOR,
      origin: constants.BI.HELP.ORIGIN.TOPBAR,
      learn_more: false,
    };

    props.openHelpCenterHome(getArticleType(props), null, biHelpParams);
  },
  [HELP_MENU_ITEMS.HELP_HOME]() {
    props.openHelpHome();
  },
  [HELP_MENU_ITEMS.HELP_KB_SHORTCUTS]() {
    const biHelpParams = {
      panel_name: BI_HELP_CONTEXT_NAME.KEYBOARD_SHORTCUTS,
      origin: constants.BI.HELP.ORIGIN.TOPBAR,
      learn_more: false,
    };
    props.openHelpCenter(HELP_ITEMS.KEYBOARD_SHORTCUTS, null, biHelpParams);
  },
  [HELP_MENU_ITEMS.HELP_VIDEOS]() {
    props.openPanel('panels.focusPanels.howToVideoGalleryPanel', {
      origin: 'topbar',
    });
  },
  [HELP_MENU_ITEMS.HELP_CONNECT_DOMAIN]() {
    const biHelpParams = {
      panel_name: BI_HELP_CONTEXT_NAME.CONNECT_DOMAIN,
      origin: constants.BI.HELP.ORIGIN.TOPBAR,
      learn_more: false,
    };
    props.openHelpCenter(HELP_ITEMS.CONNECT_DOMAIN, null, biHelpParams);
  },
  [HELP_MENU_ITEMS.HELP_SEO]() {
    const biHelpParams = {
      panel_name: BI_HELP_CONTEXT_NAME.SEO,
      origin: constants.BI.HELP.ORIGIN.TOPBAR,
      learn_more: false,
    };
    props.openHelpCenter(HELP_ITEMS.SEO, null, biHelpParams);
  },
  [HELP_MENU_ITEMS.HELP_TERMS_OF_USE]() {
    const linkOverride =
      props.overriddenExternalLinks?.[EXTERNAL_LINKS.TERM_OF_USE.KEY]?.[
        EXTERNAL_LINKS.TERM_OF_USE.LINK
      ] ?? 'about/terms-of-use';
    const link = `${serviceTopology.basePublicUrl}${linkOverride}`;
    const localLink = languages.getLocalizedLink(link);

    window.open(localLink);
  },
  [HELP_MENU_ITEMS.HELP_PRIVACY_POLICY]() {
    const linkOverride =
      props.overriddenExternalLinks?.[EXTERNAL_LINKS.PRIVACY.KEY]?.[
        EXTERNAL_LINKS.PRIVACY.LINK
      ] ?? 'about/privacy';
    const link = `${serviceTopology.basePublicUrl}${linkOverride}`;
    const localLink = languages.getLocalizedLink(link);

    window.open(localLink);
  },
  [HELP_MENU_ITEMS.HELP_CHAT]() {
    const { isChatVisible, showOrHideChat } = props;
    showOrHideChat(!isChatVisible);
  },
  [HELP_MENU_ITEMS.HELP_WELCOME_TOUR]() {
    props.startWelcomeTour(TourStartOrigin.HelpMenu);
  },
  [HELP_MENU_ITEMS.HELP_EDITOR_NEW_USERS_TOUR]() {
    props.startEditorNewUsersTour(TourStartOrigin.HelpMenu);
  },
  [HELP_MENU_ITEMS.HELP_ADI_TO_EDITOR_TOUR]() {
    props.startAdiToEditorTour(TourStartOrigin.HelpMenu);
  },
  [HELP_MENU_ITEMS.HELP_EDITOR_TO_EDITOR_TOUR]() {
    props.startEditorToEditorTour(TourStartOrigin.HelpMenu);
  },
  [HELP_MENU_ITEMS.HELP_NEW_RELEASES]() {
    props.openNewReleasesFeed();
  },
  [HELP_MENU_ITEMS.HELP_WIX_LEARN]() {
    const linkOverride =
      props.overriddenExternalLinks?.[EXTERNAL_LINKS.LEARN.KEY]?.[
        EXTERNAL_LINKS.LEARN.LINK
      ] ?? WIX_LEARN_LINK;
    const link = `${serviceTopology.basePublicUrl}${linkOverride}`;
    const localLink = languages.getLocalizedLink(link);

    window.open(localLink);
  },
});
