import type { EditorAPI } from '@/editorAPI';
import type { CompRef } from 'types/documentServices';
import experiment from 'experiment';

export const moveWidgetPluginAction = {
  label: 'PLATFORM_Widget_Slots_Move_Plugin_RCM_text',
  enabled: (editorAPI: EditorAPI, widgetCompRef: CompRef) =>
    editorAPI.platform.widgetPlugins.isSlotContentMovable(
      editorAPI.platform.widgetPlugins.getSlotPlaceholderRefByContentRef(
        widgetCompRef,
      ),
    ),
  onClick(editorAPI: EditorAPI, widgetCompRef: CompRef) {
    editorAPI.closeRightClickMenu();

    editorAPI.panelManager.openPanel('panels.widgetPanels.slotSelectionPanel', {
      slotsPlaceholderCompRef:
        editorAPI.platform.widgetPlugins.getSlotPlaceholderRefByContentRef(
          widgetCompRef,
        ),
    });
  },
  automationId: 'widgetPluginMoveToSlot',
  condition: (editorAPI: EditorAPI, widgetCompRef: CompRef) => {
    return (
      experiment.isOpen('se_widgetPlugins') &&
      editorAPI.platform.widgetPlugins.isWidgetPluginComponent(widgetCompRef)
    );
  },
};
