import _ from 'lodash';
import { utils as themeUtils } from '@/theme';
import { fontUtils } from '@/util';

export interface TextThemeStyleMap {
  style: string;
  variant: string;
  weight: string;
  size: string;
  lineHeight: string;
  letterSpacing: string;
  family: string;
  color: string;
  bold: boolean;
  italic: boolean;
  fontWithFallbacks: string;
  cssColor: string;
  foreColor: string;
  fontFamily: string;
  fontSize: number;
}

function parseTextThemeStyleMap(
  theme: AnyFixMe,
  themeColors: AnyFixMe,
): TextThemeStyleMap {
  const fontStyles = fontUtils.parseStyleTextTheme('', theme, themeColors);

  return {
    ...fontStyles,
    foreColor: themeUtils.getThemeColorKey(theme.color),
    family: fontStyles.family?.replace(/[+]/, ' ').toLowerCase(),
    fontFamily: theme.fontFamily?.replace(/[+]/, ' ').toLowerCase(),
    fontSize: parseInt(theme.fontSize, 10),
  } as TextThemeStyleMap;
}

function getAllTextThemesMap(editorAPI: AnyFixMe) {
  const allTextThemes = editorAPI.dsRead.theme.textThemes.getAll();
  const themeColors = editorAPI.theme.colors.getAll();
  return _.mapValues(allTextThemes, (textTheme) =>
    parseTextThemeStyleMap(textTheme, themeColors),
  );
}

export default {
  getAllTextThemesMap,
  parseTextThemeStyleMap,
};
