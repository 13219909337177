import _ from 'lodash';
import { editorDataResolver } from '@wix/editor-component-data-resolver';
import constants from '@/constants';

const { ACTIONS } = constants.ROOT_COMPS.GFPP;
const { editorDataFunction, experiment, isComponentPanelOpened } =
  editorDataResolver;

export default {
  getGfppData: editorDataFunction(
    experiment('se_mobileStyle'),
    isComponentPanelOpened(ACTIONS.SETTINGS),
    (se_mobileStyle, isSettingsPanelOpened) => ({
      mainActions: [
        {
          label: 'gfpp_mainaction_textbutton2',
          isSelected: isSettingsPanelOpened,
          actionType: ACTIONS.SETTINGS,
        },
      ],
      enabledActions: [
        ACTIONS.LINK,
        ACTIONS.DESIGN,
        ACTIONS.LAYOUT,
        ACTIONS.ANIMATION,
        ACTIONS.HELP,
      ],
      mobileMainActions: [],
      mobileEnabledActions: _.compact([
        ACTIONS.SCALE_UP,
        ACTIONS.SCALE_DOWN,
        se_mobileStyle && ACTIONS.DESIGN,
        ACTIONS.LAYOUT,
        ACTIONS.HIDE,
        ACTIONS.HELP,
        ACTIONS.ANIMATION,
      ]),
      helpId: '25402aec-ce28-4040-b589-7286d2eed6ee',
    }),
  ),
};
