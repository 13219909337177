import React from 'react';
import constants from '@/constants';
import { connectWithScope } from '@/apilib';
import { symbol as Symbol } from '@wix/santa-editor-symbols';
import { Tooltip } from '@wix/wix-base-ui';
import { biLogger } from '@/util';
import {
  sectionEmptyStateClick,
  componentAddedToStage,
} from '@wix/bi-logger-editor/v2';
import { Scope } from '../../scope';
import { mapStripsToFillSection } from '../../utils/stripsLayoutPresets';
import styles from './LayoutPresets.scss';
import { highlights } from '@/stateManagement';

import type { CompRef } from 'types/documentServices';
import type { Proportion } from '../../utils/stripsLayoutPresets';

const UNDO_LABEL = 'before-strip-preset-add';
const HIGHLIGHT_TIMEOUT = 3000;

export interface Preset {
  id: string;
  icon: string;
  tooltip: string;
  proportion: Proportion;
}

interface LayoutPresetsOwnProps {
  sectionLike: CompRef;
  presets: Preset[];
}

interface LayoutPresetsDispatchProps {
  applyLayoutPreset: (sectionLike: CompRef, preset: Preset) => void;
  selectComponent: (sectionLike: CompRef) => void;
}

interface LayoutPresetsProps
  extends LayoutPresetsDispatchProps,
    LayoutPresetsOwnProps {}

const LayoutPresets: React.FC<LayoutPresetsProps> = ({
  sectionLike,
  presets,
  applyLayoutPreset,
  selectComponent,
}) => {
  const handlePresetClick = (event: React.MouseEvent, preset: Preset) => {
    event.preventDefault();
    event.stopPropagation();
    selectComponent(sectionLike);
    applyLayoutPreset(sectionLike, preset);
  };

  return (
    <div
      className={styles.container}
      onMouseDown={(event) => event.stopPropagation()}
    >
      {presets.map((preset) => (
        <Tooltip
          key={preset.id}
          alignment="BOTTOM"
          marginBottom={15}
          content={preset.tooltip}
        >
          <div onClick={(e) => handlePresetClick(e, preset)}>
            <Symbol name={preset.icon} className={styles.icon} />
          </div>
        </Tooltip>
      ))}
    </div>
  );
};

const mapDispatchToProps = (scope: Scope): LayoutPresetsDispatchProps => {
  const highlightStrip = (strip: CompRef) => {
    const columns = scope.editorAPI.components.getChildren(strip);
    const columnsToHiglight = columns.length > 1 ? columns : [];

    scope.editorAPI.store.dispatch(
      highlights.actions.addCompsToHighlights(
        [strip, ...columnsToHiglight],
        constants.UI.HIGHLIGHTS.TYPES.OVERLAY_WITH_OPACITY,
      ),
    );
  };
  return {
    selectComponent: (sectionLike) =>
      scope.selection.selectComponentByCompRef(sectionLike),
    applyLayoutPreset: async (sectionLike, { proportion, id }) => {
      biLogger.report(
        sectionEmptyStateClick({
          ...scope.editorAPI.bi.getComponentsBIParams([sectionLike])[0],
          type: id,
          origin: 'stage',
        }),
      );

      const stripPresets = mapStripsToFillSection(
        scope,
        sectionLike,
        proportion,
      );

      scope.history.add(UNDO_LABEL);

      for (const [i, preset] of stripPresets.entries()) {
        const stripRef = await new Promise<CompRef>((resolve) =>
          scope.components.add(sectionLike, preset, null, resolve),
        );

        biLogger.report(
          componentAddedToStage({
            ...scope.editorAPI.bi.getComponentsBIParams([stripRef])[0],
            origin: 'auto_from_empty_state',
            preset_id: id,
          }),
        );

        scope.history.amend();

        if (i === 0) {
          scope.selection.selectComponentByCompRef(stripRef);
        } else {
          highlightStrip(stripRef);
        }
      }

      const hasHighlightedStrips = stripPresets.length > 1;

      if (hasHighlightedStrips) {
        setTimeout(() => {
          scope.editorAPI.store.dispatch(highlights.actions.clearHighlights());
        }, HIGHLIGHT_TIMEOUT);
      }
    },
  };
};

export default connectWithScope(
  () => Scope,
  LayoutPresets,
  null,
  mapDispatchToProps,
);
