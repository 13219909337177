import type { CompRef } from 'types/documentServices';
import type { CSSProperties } from 'react';
import type constants from '@/constants';

export type DirectionName = typeof constants.RESIZE_DIRECTIONS[number];

export type CollisionDirection = 'left' | 'right' | 'top' | 'bottom';

export type PositionDifferences = Record<CollisionDirection, number>;

export type DirectionIndicators = Record<CollisionDirection, boolean>;

export type Position = Record<CollisionDirection, number> & {
  compRef: CompRef;
};

export interface OptionsI {
  resizeType: ResizeType;
  directionName?: DirectionName;
}

export interface HighlightBox {
  id: string;
  style: CSSProperties;
}

export enum ResizeType {
  Resize,
  Push,
}
