// @ts-nocheck
import constants from '@/constants';
import * as helpIds from '@/helpIds';
import gfppDataUtils from '../../utils/gfppDataUtils';

const { ACTIONS } = constants.ROOT_COMPS.GFPP;

export default {
  mainActions: [
    {
      label: 'gfpp_mainaction_dropdown',
      isApplied: true,
      isSelected: gfppDataUtils.getPanelStateFn(ACTIONS.MANAGE),
      onClick: gfppDataUtils.getTogglePanelFn(ACTIONS.MANAGE),
    },
    {
      label: 'gfpp_mainaction_dropdown_settings',
      isApplied: true,
      isSelected: gfppDataUtils.getPanelStateFn(ACTIONS.SETTINGS),
      onClick: gfppDataUtils.getTogglePanelFn(ACTIONS.SETTINGS),
    },
  ],
  enabledActions: [
    ACTIONS.SETTINGS,
    ACTIONS.LAYOUT,
    ACTIONS.DESIGN,
    ACTIONS.ANIMATION,
    ACTIONS.HELP,
  ],
  mobileMainActions: [],
  mobileEnabledActions: [
    ACTIONS.SCALE_UP,
    ACTIONS.SCALE_DOWN,
    ACTIONS.LAYOUT,
    ACTIONS.HIDE,
    ACTIONS.HELP,
    ACTIONS.ANIMATION,
  ],
  presetActions: {
    help: {
      helpId: helpIds.GFPP.COMBOBOX_INPUT_HELP,
      mobileHelpId: helpIds.GFPP.COMBOBOX_INPUT_MOBILE_HELP,
    },
  },
};
