import coreUtilsLib from 'coreUtilsLib';
import * as platformEvents from 'platformEvents';
import * as core from '@/core';
import { arrayUtils } from '@/util';
import { componentSelectors, multilingualActions } from '@/stateManagement';
import * as componentsUtils from './componentsUtils';

import type { CompRef } from 'types/documentServices';
import type { EditorAPI } from '@/editorAPI';

export function createComponentsDesignApi({
  editorAPI,
}: {
  editorAPI: EditorAPI;
}) {
  function get(compRefs: CompRef | CompRef[]) {
    return componentSelectors.getDesign(compRefs, editorAPI.dsRead);
  }

  function updateSingleCompDesignData(
    compRef: CompRef,
    designItem: AnyFixMe,
    retainCharas: AnyFixMe,
    dontAddToUndoRedoStack: boolean,
  ) {
    designItem.type =
      designItem.type || editorAPI.components.design.get(compRef).type;
    designItem = core.utils.updateDataUtil.fixData(designItem);

    const currentData = editorAPI.components.design.get(compRef);
    const isChanged =
      !currentData ||
      !componentsUtils.isContainedObject(currentData, designItem);

    if (isChanged) {
      editorAPI.dsActions.components.design.update(
        compRef,
        designItem,
        // @ts-expect-error
        retainCharas,
      );

      if (!dontAddToUndoRedoStack) {
        editorAPI.history.debouncedAdd('comp design changed');
      }

      const plugin = editorAPI.pluginService.getPlugin('designDataChange', '*');
      if (plugin) {
        plugin(editorAPI, compRef, designItem, retainCharas);
      }

      editorAPI.dsActions.platform.notifyAppsOnCustomEvent(
        platformEvents.factory.componentDesignChanged({ compRef }),
      );

      componentsUtils.sendBiIfRefComponent(
        editorAPI,
        compRef,
        componentsUtils.REF_COMPONENT_CHANGE_TYPE.DESIGN,
      );
      editorAPI.store.dispatch(multilingualActions.componentChanged());

      editorAPI.waitForChangesApplied(() => {
        editorAPI.components.hooks.componentDesignUpdated.fire({
          compRef,
          designItem,
        });
      });
    }
  }

  function update(
    compRefOrRefs: CompRef | CompRef[],
    data: AnyFixMe,
    retainCharas?: AnyFixMe,
    dontAddToUndoRedoStack?: boolean,
  ) {
    coreUtilsLib.wSpy.log('editorUpdateDesign', ['updateDesign', ...arguments]);

    arrayUtils.asArray(compRefOrRefs).forEach((compRef) => {
      updateSingleCompDesignData(
        compRef,
        data,
        retainCharas,
        dontAddToUndoRedoStack,
      );
    });
  }

  function remove(
    compRefOrRefs: CompRef | CompRef[],
    dontAddToUndoRedoStack: boolean,
  ) {
    if (Array.isArray(compRefOrRefs) && compRefOrRefs.length > 1) {
      throw new Error('design.remove does not support multiple components');
    }

    const [compRef] = arrayUtils.asArray(compRefOrRefs);

    // @ts-expect-error
    editorAPI.dsActions.components.design.remove(compRef);

    if (!dontAddToUndoRedoStack) {
      editorAPI.history.add('comp design removed');
    }
  }

  return {
    update,
    get,
    remove,
  };
}
