// @ts-nocheck
import _ from 'lodash';
import {
  getDefaultLabel,
  getControlType,
  getDefaultCategory,
} from './paramsDataUtils';
import experiment from 'experiment';

const DEFAULT_SKIN_ICON = 'defaultSkinIcon';
const DEFAULT_SECTION = 'defaultSection';
const DEFAULT_SELECTED_COMP_PART = 'host';
const ADDITIONAL_PARAMS_TYPE = 'ADDITIONAL_PARAMS';

const SKIN_PARAM_PROPS = {
  SIZE: { min: 0, max: 20 },
  BOX_SHADOW: {},
  SUCCESS_COLOR_SUBSCRIBE: {
    label: 'Subscribe Success',
  },
  COLOR_ALPHA: {},
  FONT: {},
  BG_COLOR: {},
  BORDER_RADIUS: {},
  BORDER_SIZES: {},
};

let skinDataCustomizations = {};
let svgForSkin = {};

const STATES = {
  REGULAR: 'regular',
  HOVER: 'hover',
  DISABLED: 'disabled',
  ACTIVE: 'active',
  FOCUS: 'focus',
  OPEN: 'open',
  CLOSED: 'closed',
  ERROR: 'error',
  SELECTED: 'selected',
  SCROLLED: 'scrolled',
};

const init = (skinDataCustomizationsRawData, _svgForSkin) => {
  skinDataCustomizations = _.mapValues(
    skinDataCustomizationsRawData,
    function (skinData) {
      const filtered = _.omit(skinData, 'experimentOverrides');
      _.forOwn(skinData.experimentOverrides, function (data, experimentName) {
        if (experiment.isOpen(experimentName)) {
          _.merge(filtered, data);
        }
      });

      filtered.aliases = {};
      _.forOwn(skinData.params, function (value, key) {
        if (value.alias) {
          filtered.aliases[value.alias] = key;
        }
      });

      return filtered;
    },
  );

  svgForSkin = _svgForSkin;
};

function shouldOmitState(isDeveloperModeEnabled, isConnectedToBooking, state) {
  return (
    state === STATES.DISABLED &&
    !isDeveloperModeEnabled &&
    !isConnectedToBooking
  );
}

function getAdditionalProps(paramData) {
  const paramType = paramData.type;
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/includes
  if (_.includes(paramType, 'SIZE')) {
    return {
      min: paramData.min || 0,
      max: paramData.max || 15,
    };
    // eslint-disable-next-line you-dont-need-lodash-underscore/includes
  } else if (_.includes(paramType, 'BORDER_RADIUS')) {
    return {
      min: paramData.min,
      max: paramData.max,
    };
    // eslint-disable-next-line you-dont-need-lodash-underscore/includes
  } else if (_.includes(paramType, 'FONT')) {
    return {
      min: paramData.min || 0,
      max: paramData.max || 100,
      stepperMax: paramData.stepperMax || 100,
      stepperMin: paramData.stepperMin || 0,
    };
  } else if (paramType === 'ALPHA') {
    return {
      min: paramData.min || 0,
      max: paramData.max || 1,
      stepperMax: paramData.stepperMax || undefined,
      step: paramData.step || 0.1,
      stepperStep: paramData.stepperStep || undefined,
      stepperFactor: paramData.stepperFactor || 1,
      units: paramData.units || '',
    };
  }

  return SKIN_PARAM_PROPS[paramType];
}

function getSkinParamPropsForControl(paramData) {
  const paramType = paramData.type;
  const paramLabel = paramData.label || getDefaultLabel(paramType);

  const paramHelpText = paramData.helpText;
  return _.merge(
    {
      label: paramLabel,
      shouldTranslate: paramData.shouldTranslate,
      helpText: paramHelpText,
    },
    getAdditionalProps(paramData),
  );
}

function getControlDataForParam(paramData) {
  const paramType = paramData.type;

  return {
    hide: paramData.hide || false,
    controlType: getControlType(paramType),
    paramKey: paramData.paramName,
    type: paramType,
    props: getSkinParamPropsForControl(paramData),
    priority: paramData.priority || -1,
  };
}

function addAdditionalParamsDefinition(skinDefinition) {
  const additionalParamsDefinition = _.transform(
    skinDefinition,
    function (result, paramData, paramName) {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/includes
      if (_.includes(paramData.type, 'COLOR_ALPHA')) {
        result[`alpha-${paramName}`] = {
          type: ADDITIONAL_PARAMS_TYPE,
          defaultValue: '1',
          state: paramData.state,
        };
      }

      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/includes
      if (_.includes(paramData.type, 'BOX_SHADOW')) {
        result[`boxShadowToggleOn-${paramName}`] = {
          type: ADDITIONAL_PARAMS_TYPE,
          defaultValue: 'true',
          state: paramData.state,
        };
      }

      return result;
    },
    {},
  );

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/assign
  _.assign(skinDefinition, additionalParamsDefinition);
}

function extendParamData(skinName, paramData, paramName) {
  paramData.category =
    paramData?.category ?? getDefaultCategory(paramData.type);
  paramData.section =
    skinDataCustomizations?.[skinName]?.params?.[paramName]?.section ??
    DEFAULT_SECTION;
  paramData.paramName = paramName;
}

function mergeCustomData(skinName, skinDefinition) {
  const { params } = skinDataCustomizations?.[skinName] || {};

  _.forOwn(params, (paramCustomData, paramName) => {
    const labelOverride = {};
    if (
      skinDefinition[paramName]?.label &&
      skinDefinition[paramName]?.label !== paramCustomData?.label
    ) {
      labelOverride.label = skinDefinition[paramName]?.label;
    }
    // eslint-disable-next-line you-dont-need-lodash-underscore/assign
    _.assign(skinDefinition[paramName], paramCustomData, labelOverride);
  });
}

function paramComparatorByType(controlData) {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/includes
  const isControlForColor = _.includes(controlData.type, 'COLOR');

  return isControlForColor ? -1 : 0;
}

function sortSectionControls(sectionControlsData) {
  return _(sectionControlsData)
    .sortBy(paramComparatorByType)
    .sortBy('priority')
    .value();
}

function getGroupedStateData(skinName, flatStateData) {
  const category = _.groupBy(flatStateData, 'category');
  return _.mapValues(
    category,
    function getGroupedCategoryData(flatCategoryData) {
      const categorySections = _.groupBy(flatCategoryData, 'section');

      const sectionsArr = [];
      _.forOwn(
        categorySections,
        function setCategory(sectionParams, sectionName) {
          const sectionCustomData =
            skinDataCustomizations?.[skinName]?.sections?.[sectionName] ?? {};
          const definitionForControlsInSection = _.mapValues(
            sectionParams,
            (paramData) => getControlDataForParam(paramData),
          );

          const section = {
            label: sectionCustomData.label,
            priority: sectionCustomData.priority || -1,
            params: sortSectionControls(definitionForControlsInSection),
          };

          sectionsArr.push(section);
        },
      );

      return _.sortBy(sectionsArr, 'priority');
    },
  );
}

function cacheDefaultSkinParams(skinDefinition, skinName) {
  const skinParamsDefaults = _.mapValues(skinDefinition, 'defaultValue');
  cachedSkinsParamsDefaults[skinName] = skinParamsDefaults;
  return skinParamsDefaults;
}

const cachedSkinsParamsDefaults = {};
const cachedSkinsParamsTypes = {};

export default {
  init,
  getSkinDefinition(rawSkinDefinition, skinName, skinParamsToIgnore = []) {
    const rawDef = rawSkinDefinition?.params || rawSkinDefinition;
    const skinDefinition = _.omit(rawDef, skinParamsToIgnore);

    addAdditionalParamsDefinition(skinDefinition);
    _.forOwn(skinDefinition, extendParamData.bind(null, skinName));
    mergeCustomData(skinName, skinDefinition);
    return skinDefinition;
  },
  initMultiComponent(rawSkinDefinition) {
    return (skinDataCustomizations.multiComponentSkin = rawSkinDefinition);
  },
  getPanelData(
    skinName,
    rawSkinDefinition,
    skinParamsToIgnore,
    isDeveloperModeEnabled,
    isConnectedToBooking,
  ) {
    const skinDefinition = this.getSkinDefinition(
      rawSkinDefinition,
      skinName,
      skinParamsToIgnore,
    );
    return _(skinDefinition)
      .pickBy(
        (paramData) =>
          paramData.type !== ADDITIONAL_PARAMS_TYPE && !paramData.hide,
      )
      .groupBy((comp) => comp.compPart || DEFAULT_SELECTED_COMP_PART)
      .mapValues(function (compPart) {
        return _(compPart)
          .groupBy('state')
          .omitBy((value, key) =>
            shouldOmitState(isDeveloperModeEnabled, isConnectedToBooking, key),
          )
          .mapValues(getGroupedStateData.bind(null, skinName))
          .value();
      })
      .value();
  },
  getSkinDefaultState(skinName) {
    return skinDataCustomizations?.[skinName]?.defaultState;
  },
  getSkinCompParts(skinName) {
    return skinDataCustomizations?.[skinName]?.compParts;
  },
  getDefaultCompPart(skinName) {
    return _.chain(skinName)
      .thru(this.getSkinCompParts)
      .find({ defaultSelected: true })
      .get('value')
      .value();
  },
  getPreviewStateWithCustomStates(skinName, compPart, previewState) {
    return _.chain(skinName)
      .thru(this.getSkinCompParts)
      .find({ value: compPart })
      .get('additionalPreviewStates', [])
      .union([previewState])
      .join(' ')
      .value();
  },
  filterSkins(skins, currentSkin) {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/filter
    const filteredSkins = _.filter(skins, function (skin) {
      const skinExperiment = skinDataCustomizations?.[skin]?.experiment;
      return !skinExperiment || experiment.isOpen(skinExperiment);
    });

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/includes
    if (currentSkin && !_.includes(filteredSkins, currentSkin)) {
      filteredSkins.push(currentSkin);
    }

    return filteredSkins;
  },
  getSkinDefaultParams(getSkinDefinitionFunction, skinName) {
    // TODO:
    if (!cachedSkinsParamsDefaults[skinName]) {
      const skinDefinition = this.getSkinDefinition(
        getSkinDefinitionFunction(skinName),
        skinName,
      );
      cacheDefaultSkinParams(skinDefinition, skinName);
    }
    return cachedSkinsParamsDefaults[skinName];
  },
  getSkinParamDefaultValue(getSkinDefinitionFunction, skinName, paramName) {
    return this.getSkinDefaultParams(getSkinDefinitionFunction, skinName)[
      paramName
    ];
  },
  getSkinParamsTypes(getSkinDefinitionFunction, skinName) {
    let skinParamsTypes = cachedSkinsParamsTypes[skinName];

    if (skinParamsTypes) {
      return skinParamsTypes;
    }

    const skinDefinition = this.getSkinDefinition(
      getSkinDefinitionFunction(skinName),
      skinName,
    );
    skinParamsTypes = _.mapValues(skinDefinition, 'type');

    cachedSkinsParamsTypes[skinName] = skinParamsTypes;
    return skinParamsTypes;
  },
  getSvgNameForSkin(skinName) {
    return _.get(svgForSkin, skinName, DEFAULT_SKIN_ICON);
  },
  getSkinDataCustomizations() {
    return skinDataCustomizations;
  },
  propertyForAlias(skinName, alias) {
    return skinDataCustomizations?.[skinName]?.aliases?.[alias] ?? null;
  },

  aliasForProperty(skinName, property) {
    return (
      skinDataCustomizations?.[skinName]?.params?.[property]?.alias ?? null
    );
  },

  STATES,
  DEFAULT_SECTION,
  getDefaultCategory,
};
