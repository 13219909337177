import constants from '@/constants';
import * as util from '@/util';
import * as stateManagement from '@/stateManagement';
import dataProvider from './dataProvider';
import * as wixBookingsBi from './bi/wixBookingsBi';
import { ExposureTestNames } from './bi/wixBookingsBi.const';
import type { EditorAPI } from '@/editorAPI';

export const BOOKINGS_FUNNEL_DEALER_ID = '4d01c1b9-04aa-45f7-afbd-f30ac88fb20c';
export const BOOKINGS_INTENT_DEALER_OFFER_ID =
  '145b8386-9420-4918-907e-6926025d1414';
export const BOOKINGS_PRE_INSTALLATION_HAPPENED_PREF =
  'bookings.introFunnel.preInstallationHappened';
export const BOOKINGS_FIRST_TIME_TOUR_SITE_PREF = `${dataProvider.superAppId}/in_first_time_tour`;

interface DealerOfferDto {
  offerName: string;
  realEstateId: string;
  offerGuid: string;
  asset: { payloadJson: string } | any;
}

function isDealerOfferValid(offer: DealerOfferDto): boolean {
  try {
    const payload = JSON.parse(offer.asset.payloadJson);
    return payload.value === 'true';
  } catch (e) {
    return false;
  }
}

export async function isBookingsIntentDealerOfferAvailable(
  editorAPI: EditorAPI,
): Promise<boolean> {
  const dealerOffers = await getBookingsFunnelDealerOffers(editorAPI);
  const bookingsIntentOffer = dealerOffers.find(
    (offer) => offer.offerGuid === BOOKINGS_INTENT_DEALER_OFFER_ID,
  );
  return !!bookingsIntentOffer && isDealerOfferValid(bookingsIntentOffer);
}

function getMetaSiteInstance(editorAPI: EditorAPI): string {
  return editorAPI.dsRead.platform.getAppDataByApplicationId(
    constants.APPLICATIONS.META_SITE_APPLICATION_ID,
  )?.instance;
}

async function getBookingsFunnelDealerOffers(
  editorAPI: EditorAPI,
): Promise<DealerOfferDto[]> {
  try {
    const dealerOfferingsResponse = await util.http.fetchJson(
      `/_api/dealer-offers-serving-service/v1/dealer/serving/offers?realEstateId=${BOOKINGS_FUNNEL_DEALER_ID}`,
      {
        contentType: 'application/json',
        credentials: 'same-origin',
        headers: new Headers({ Authorization: getMetaSiteInstance(editorAPI) }),
        method: 'GET',
      },
    );
    return dealerOfferingsResponse?.offers ?? [];
  } catch (e) {
    return [];
  }
}

function reportExposureBi(editorAPI: EditorAPI) {
  const bookingsBiEvents = wixBookingsBi.events;
  wixBookingsBi.report(editorAPI, bookingsBiEvents.BOOKINGS_TESTS_EXPOSURE, {
    testName: ExposureTestNames.INTRO_FUNNEL,
  });
}

export async function hasPreInstallationIntent(
  editorAPI: EditorAPI,
): Promise<boolean> {
  const hasBookingsIntent = await isBookingsIntentDealerOfferAvailable(
    editorAPI,
  );
  if (hasBookingsIntent) {
    reportExposureBi(editorAPI);
  }
  const hasPreInstallationHappened = await getPreInstallationHappened(
    editorAPI,
  );
  if (hasPreInstallationHappened) {
    return false;
  }
  if (hasBookingsIntent) {
    setPreInstallationHappened(editorAPI);
  }
  return hasBookingsIntent;
}

function setPreInstallationHappened(editorAPI: EditorAPI) {
  editorAPI.store.dispatch(
    stateManagement.userPreferences.actions.setSiteUserPreferences(
      BOOKINGS_PRE_INSTALLATION_HAPPENED_PREF,
      true,
    ),
  );
}

async function getPreInstallationHappened(
  editorAPI: EditorAPI,
): Promise<boolean> {
  return new Promise((resolve) => {
    editorAPI.registerInitUserPrefsCallback((preferencesReady) => {
      let installationHappenedPrefValue = true;
      if (preferencesReady) {
        installationHappenedPrefValue =
          stateManagement.userPreferences.selectors.getSiteUserPreferences<boolean>(
            BOOKINGS_PRE_INSTALLATION_HAPPENED_PREF,
          )(editorAPI.store.getState());
      }
      resolve(installationHappenedPrefValue === true);
    });
  });
}
