// @ts-nocheck
import _ from 'lodash';
import backOfficeTextGfppData from './backOfficeTextGfppData';
import adminLoginButtonGfppData from './adminLoginButtonGfppData';
import anchorGfppData from './anchorGfppData';
import audioPlayerGfppData from './audioPlayerGfppData';
import bgImageStripGfppData from './bgImageStripGfppData';
import checkboxGfppData from './checkboxGfppData';
import contactFormGfppData from './contactFormGfppData';
import contactFormDynamicFieldsGfppData from './contactFormDynamicFieldsGfppData';
import containerGfppData from './containerGfppData';
import sectionGfppData from './sectionGfppData';
import classicSectionGfppData from './classicSectionGfppData';
import popupContainerGfppData from './popupContainerGfppData';
import groupGfppData from './groupGfppData';
import documentMediaGfppData from './documentMediaGfppData';
import dropDownMenuGfppData from './dropDownMenuGfppData';
import facebookLikeBoxGfppData from './facebookLikeBoxGfppData';
import flashComponentGfppData from './flashComponentGfppData';
import flickrBadgeWidgetGfppData from './flickrBadgeWidgetGfppData';
import footerGfppData from './footerGfppData';
import galleryGfppData from './galleryGfppData';
import galleryGfppDataMap from './galleryGfppDataMap';
import googleMapGfppData from './googleMapGfppData';
import headerGfppData from './headerGfppData';
import screenWidthContainerGfppData from './screenWidthContainerGfppData';
import htmlComponentGfppData from './htmlComponentGfppData';
import imageButtonGfppData from './imageButtonGfppData';
import itunesButtonGfppData from './itunesButtonGfppData';
import linkBarGfppData from './linkBarGfppData';
import loginSocialBarGfppData from './loginSocialBarGfppData';
import pageGfppData from './pageGfppData';
import payPalButtonGfppData from './payPalButtonGfppData';
import pinItPinWidgetGfppData from './pinItPinWidgetGfppData';
import pinterestFollowGfppData from './pinterestFollowGfppData';
import pinterestPinItGfppData from './pinterestPinItGfppData';
import singleAudioPlayerGfppData from './singleAudioPlayerGfppData';
import popupCloseTextButtonGfppData from './popupCloseTextButtonGfppData';
import siteContainerGfppData from './siteContainerGfppData';
import skypeCallButtonGfppData from './skypeCallButtonGfppData';
import soundCloudWidgetGfppData from './soundCloudWidgetGfppData';
import spotifyFollowGfppData from './spotifyFollowGfppData';
import spotifyPlayerGfppData from './spotifyPlayerGfppData';
import subscribeFormGfppData from './subscribeFormGfppData';
import svgShapeGfppData from './svgShapeGfppData';
import vectorImageGfppData from './vectorImageGfppData';
import popupCloseIconButtonGfppData from './popupCloseIconButtonGfppData';
import verticalLineGfppData from './verticalLineGfppData';
import verticalMenuGfppData from './verticalMenuGfppData';
import expandableMenuGfppData from './expandableMenuGfppData';
import videoGfppData from './videoGfppData';
import vkShareButtonGfppData from './vkShareButtonGfppData';
import wFacebookCommentGfppData from './wFacebookCommentGfppData';
import wFacebookLikeGfppData from './wFacebookLikeGfppData';
import disqusCommentsGfppData from './disqusCommentsGfppData';
import wGooglePlusOneGfppData from './wGooglePlusOneGfppData';
import wphotoGfppData from './wphotoGfppData';
import wRichTextGfppData from './wRichTextGfppData';
import wTwitterFollowGfppData from './wTwitterFollowGfppData';
import wTwitterTweetGfppData from './wTwitterTweetGfppData';
import twitterFeedGfppData from './twitterFeedGfppData';
import youTubeSubscribeButtonGfppData from './youTubeSubscribeButtonGfppData';
import appPart2GfppData from './appPart2GfppData';
import tinyMenuGfppData from './tinyMenuGfppData';
import quickActionBarGfppData from './quickActionBarGfppData';
import multiselectGfppData from './multiselectGfppData';
import rssButtonGfppData from './rssButtonGfppData';
import homePageLoginGfppData from './homePageLoginGfppData';
import stripContainerGfppData from './stripContainerGfppData';
import boxSlideShowGfppData from './boxSlideShowGfppData';
import stripSlideShowGfppData from './stripSlideShowGfppData';
import stateBoxGfppData from './stateBoxGfppData';
import refComponentGfppData from './refComponentGfppData';
import textInputGfppData from './textInputGfppData';
import fileUploaderGfppData from './fileUploaderGfppData';
import textAreaGfppData from './textAreaGfppData';
import comboBoxInputGfppData from './comboBoxInputGfppData';
import datePickerGfppData from './datePickerGfppData';
import radioGroupGfppData from './radioGroupGfppData';
import verticalAnchorsMenuGfppData from './verticalAnchorsMenuGfppData';
import stripColumnsContainerGfppData from './stripColumnsContainerGfppData';
import columnGfppData from './columnGfppData';
import hoverBoxGfppData from './hoverBoxGfppData';
import gridGfppData from './gridGfppData';
import mediaPlayerGfppData from './mediaPlayerGfppData';
import mediaContainerGfppData from './mediaContainerGfppData';
import mediaBoxGfppData from './mediaBoxGfppData';
import repeaterGfppData from './repeaterGfppData';
import mediaControlsGfppData from './mediaControlsGfppData';
import mediaOverlayControlsGfppData from './mediaOverlayControlsGfppData';
import languageSelectorGfppData from './languageSelectorGfppData';
import siteRegionContainerGfppData from './siteRegionContainerGfppData';
import richTextBoxGfppData from './richTextBoxGfppData';
import toggleSwitchGfppData from './toggleSwitchGfppData';
import formContainerGfppData from './formContainerGfppData';
import inlinePopupToggleGfppData from './inlinePopupToggleGfppData';
import menuToggleGfppData from './menuToggleGfppData';
import inlinePopupGfppData from './inlinePopupGfppData';

export default {
  BackOfficeText: backOfficeTextGfppData,
  AudioPlayer: audioPlayerGfppData,
  VerticalLine: verticalLineGfppData,
  PageGroup: siteContainerGfppData,
  SingleAudioPlayer: singleAudioPlayerGfppData,
  SvgShape: svgShapeGfppData,
  VectorImage: vectorImageGfppData,
  PopupCloseIconButton: popupCloseIconButtonGfppData,
  GoogleMap: googleMapGfppData,
  BgImageStrip: bgImageStripGfppData,
  Container: containerGfppData,
  /* responsive components */
  Section: sectionGfppData,
  HeaderSection: sectionGfppData,
  FooterSection: sectionGfppData,
  MembersAreaSection: sectionGfppData,
  /* ENDOF responsive components */
  ClassicSection: classicSectionGfppData,
  PopupContainer: popupContainerGfppData,
  Group: groupGfppData,
  ContactForm: contactFormGfppData,
  DynamicContactForm: contactFormDynamicFieldsGfppData,
  SubscribeForm: subscribeFormGfppData,
  Checkbox: checkboxGfppData,
  DropDownMenu: dropDownMenuGfppData,
  VerticalMenu: verticalMenuGfppData,
  ExpandableMenu: expandableMenuGfppData,
  ImageButton: imageButtonGfppData,
  MatrixGallery: galleryGfppData.getGfppDataByType(
    galleryGfppDataMap,
    'wysiwyg.viewer.components.MatrixGallery',
  ),
  SliderGallery: galleryGfppData.getGfppDataByType(
    galleryGfppDataMap,
    'wysiwyg.viewer.components.SliderGallery',
  ),
  PaginatedGridGallery: galleryGfppData.getGfppDataByType(
    galleryGfppDataMap,
    'wysiwyg.viewer.components.PaginatedGridGallery',
  ),
  Impress: galleryGfppData.getGfppDataByType(
    galleryGfppDataMap,
    'tpa.viewer.components.Impress',
  ),
  Masonry: galleryGfppData.getGfppDataByType(
    galleryGfppDataMap,
    'tpa.viewer.components.Masonry',
  ),
  Thumbnails: galleryGfppData.getGfppDataByType(
    galleryGfppDataMap,
    'tpa.viewer.components.Thumbnails',
  ),
  StripSlideshow: galleryGfppData.getGfppDataByType(
    galleryGfppDataMap,
    'tpa.viewer.components.StripSlideshow',
  ),
  StripShowcase: galleryGfppData.getGfppDataByType(
    galleryGfppDataMap,
    'tpa.viewer.components.StripShowcase',
  ),
  Accordion: galleryGfppData.getGfppDataByType(
    galleryGfppDataMap,
    'tpa.viewer.components.Accordion',
  ),
  Freestyle: galleryGfppData.getGfppDataByType(
    galleryGfppDataMap,
    'tpa.viewer.components.Freestyle',
  ),
  Collage: galleryGfppData.getGfppDataByType(
    galleryGfppDataMap,
    'tpa.viewer.components.Collage',
  ),
  Honeycomb: galleryGfppData.getGfppDataByType(
    galleryGfppDataMap,
    'tpa.viewer.components.Honeycomb',
  ),
  SlideShowGallery: galleryGfppData.getGfppDataByType(
    galleryGfppDataMap,
    'wysiwyg.viewer.components.SlideShowGallery',
  ),
  TPA3DGallery: galleryGfppData.getGfppDataByType(
    galleryGfppDataMap,
    'wysiwyg.viewer.components.tpapps.TPA3DGallery',
  ),
  TPA3DCarousel: galleryGfppData.getGfppDataByType(
    galleryGfppDataMap,
    'wysiwyg.viewer.components.tpapps.TPA3DCarousel',
  ),
  PopupCloseTextButton: popupCloseTextButtonGfppData,
  WPhoto: wphotoGfppData,
  WRichText: wRichTextGfppData,
  Video: videoGfppData,
  AdminLoginButton: adminLoginButtonGfppData,
  LoginSocialBar: loginSocialBarGfppData,
  WFacebookLike: wFacebookLikeGfppData,
  FacebookLikeBox: facebookLikeBoxGfppData,
  WTwitterFollow: wTwitterFollowGfppData,
  WTwitterTweet: wTwitterTweetGfppData,
  TwitterFeed: twitterFeedGfppData,
  WGooglePlusOne: wGooglePlusOneGfppData,
  LinkBar: linkBarGfppData,
  VKShareButton: vkShareButtonGfppData,
  PinItPinWidget: pinItPinWidgetGfppData,
  PinterestFollow: pinterestFollowGfppData,
  YouTubeSubscribeButton: youTubeSubscribeButtonGfppData,
  SpotifyFollow: spotifyFollowGfppData,
  FlickrBadgeWidget: flickrBadgeWidgetGfppData,
  DocumentMedia: documentMediaGfppData,
  FlashComponent: flashComponentGfppData,
  PayPalButton: payPalButtonGfppData,
  AppPart2: appPart2GfppData,
  WFacebookComment: wFacebookCommentGfppData,
  DisqusComments: disqusCommentsGfppData,
  PinterestPinIt: pinterestPinItGfppData,
  AppPage: _.clone(pageGfppData),
  ItunesButton: itunesButtonGfppData,
  Anchor: anchorGfppData,
  HtmlComponent: htmlComponentGfppData,
  FooterContainer: footerGfppData,
  HeaderContainer: headerGfppData,
  ScreenWidthContainer: screenWidthContainerGfppData,
  Page: pageGfppData,
  TinyMenu: tinyMenuGfppData,
  QuickActionBar: quickActionBarGfppData,
  SkypeCallButton: skypeCallButtonGfppData,
  MultiSelect: multiselectGfppData,
  SoundCloudWidget: soundCloudWidgetGfppData,
  RSSButton: rssButtonGfppData,
  SpotifyPlayer: spotifyPlayerGfppData,
  HomePageLogin: homePageLoginGfppData,
  StripContainer: stripContainerGfppData,
  BoxSlideShow: boxSlideShowGfppData,
  StripContainerSlideShow: stripSlideShowGfppData,
  StateBox: stateBoxGfppData,
  StateStrip: stateBoxGfppData,
  TextInput: textInputGfppData,
  FileUploader: fileUploaderGfppData,
  TextAreaInput: textAreaGfppData,
  ComboBoxInput: comboBoxInputGfppData,
  DatePicker: datePickerGfppData,
  RadioGroup: radioGroupGfppData,
  VerticalAnchorsMenu: verticalAnchorsMenuGfppData,
  StripColumnsContainer: stripColumnsContainerGfppData,
  Column: columnGfppData,
  HoverBox: hoverBoxGfppData,
  Grid: gridGfppData,
  MediaPlayer: mediaPlayerGfppData,
  MediaBox: mediaBoxGfppData,
  MediaContainer: mediaContainerGfppData,
  Repeater: repeaterGfppData,
  MediaControls: mediaControlsGfppData,
  MediaOverlayControls: mediaOverlayControlsGfppData,
  LanguageSelector: languageSelectorGfppData,
  SiteRegionContainer: siteRegionContainerGfppData,
  RichTextBox: richTextBoxGfppData,
  ToggleSwitch: toggleSwitchGfppData,
  FormContainer: formContainerGfppData,
  InlinePopupToggle: inlinePopupToggleGfppData,
  MenuToggle: menuToggleGfppData,
  InlinePopup: inlinePopupGfppData,
  MenuContainer: inlinePopupGfppData,
  RefComponent: refComponentGfppData,
};
