import type { EditorAPI } from '@/editorAPI';
import constants from '@/constants';
import { addPanel as addPanelUtils, biLogger } from '@/util';
import type { CompLayout, CompRef } from 'types/documentServices';
import { sectionEmptyStateClick } from '@wix/bi-logger-editor/v2';

const AddElementBiOrigin = 'site-segment-add-element_layers-panel';

const scrollToCompAndSelect = (editorAPI: EditorAPI, compRef: CompRef) => {
  const layout = editorAPI.components.layout.getRelativeToStructure(compRef);
  editorAPI.ui.scroll.animateScroll(
    {
      x: layout.x,
      y: Math.max(layout.y - 30, 0),
    },
    0.5,
  );
  editorAPI.selection.selectComponentByCompRef(compRef, {
    origin: 'layers_panel',
  });
  editorAPI.selection.setIsHiddenComponentDraggedNext(true);
};

const openAddPanel = (
  editorAPI: EditorAPI,
  options: { category?: string; sectionId?: string },
) => {
  const panelName = addPanelUtils.isNewPanelEnabled()
    ? constants.ROOT_COMPS.LEFTBAR.NEW_ADD_PANEL_NAME
    : constants.ROOT_COMPS.LEFTBAR.ADD_PANEL_NAME;

  editorAPI.panelManager.openPanel(panelName, {
    origin: AddElementBiOrigin,
    ...options,
  });
};

const reportAddElementClick = (editorAPI: EditorAPI, compRef: CompRef) => {
  const componentDefaultBIParams = editorAPI.bi.getComponentsBIParams([
    compRef,
  ])[0];
  biLogger.report(
    sectionEmptyStateClick({
      ...componentDefaultBIParams,
      type: 'add-element',
      origin: 'layers_panel',
    }),
  );
};

const getAllNodesEmptyStateContent = (
  editorAPI: EditorAPI,
  compRef: CompRef,
  hasChildNodes: boolean,
) => ({
  SITE_HEADER: !hasChildNodes && {
    titleLabel: 'layers_section_header_text',
    ctaLabel: 'layers_section_header_cta',
    onClick: () => {
      scrollToCompAndSelect(editorAPI, compRef);
      openAddPanel(editorAPI, { category: 'navigation' });
      reportAddElementClick(editorAPI, compRef);
    },
  },
  SITE_FOOTER: !hasChildNodes && {
    titleLabel: 'layers_section_footer_text',
    ctaLabel: 'layers_section_footer_cta',
    onClick: () => {
      scrollToCompAndSelect(editorAPI, compRef);
      openAddPanel(editorAPI, {
        category: 'strip',
        sectionId: 'stripContainerContactSection',
      });
      reportAddElementClick(editorAPI, compRef);
    },
  },
});

const getIsComponentInViewPortOnXAxis = (
  componentLayout: CompLayout,
  widthInsideGridlines: number,
  widthOutsideGridlines: number,
) => {
  const minVisibleXCoordinate = 0 - widthOutsideGridlines;
  const maxVisibleXCoordinate = widthInsideGridlines + widthOutsideGridlines;
  return (
    componentLayout.x + componentLayout.width > minVisibleXCoordinate &&
    componentLayout.x < maxVisibleXCoordinate
  );
};

const getIsComponentInViewPortOnYAxis = (
  componentLayout: CompLayout,
  siteHeight: number,
) => {
  const minVisibleYCoordinate = 0;
  return (
    componentLayout.y + componentLayout.height > minVisibleYCoordinate &&
    componentLayout.y < siteHeight
  );
};

const isComponentInViewPort = (
  editorAPI: EditorAPI,
  comp: CompRef,
): boolean => {
  const siteHeight = editorAPI.documentServices.site.getHeight();
  const widthInsideGridlines = editorAPI.documentServices.site.getWidth();
  const widthOutsideGridlines = editorAPI.documentServices.site.getSiteX();
  const componentLayout =
    editorAPI.components.layout.getRelativeToStructure(comp);

  const isComponentInViewPortOnXAxis = getIsComponentInViewPortOnXAxis(
    componentLayout,
    widthInsideGridlines,
    widthOutsideGridlines,
  );

  const isComponentInViewPortOnYAxis = getIsComponentInViewPortOnYAxis(
    componentLayout,
    siteHeight,
  );

  return isComponentInViewPortOnXAxis && isComponentInViewPortOnYAxis;
};

export {
  getAllNodesEmptyStateContent,
  scrollToCompAndSelect,
  isComponentInViewPort,
};
