// @ts-nocheck
import _ from 'lodash';
import advancedStyleDataUtil from './advancedStyleDataUtil';

const ALPHA_PREFIX = 'alpha-';

function isDefaultValue(
  getSkinDefinitionFunction,
  paramName,
  paramValue,
  skinName,
) {
  const defaultParamValue = _.get(
    advancedStyleDataUtil.getSkinDefaultParams(
      getSkinDefinitionFunction,
      skinName,
    ),
    paramName,
  );
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/is-undefined
  return !_.isUndefined(defaultParamValue) && paramValue === defaultParamValue;
}

function getPropertySource(paramValue) {
  return /^(font|color)_[0-9]+$/.test(paramValue) ? 'theme' : 'value';
}

export default {
  getPropertySource,

  getStyleForSkin: function getStyleForSkin(
    getSkinDefinitionFunction,
    skinName,
    cachedStyleProperties,
  ) {
    const skinParams = advancedStyleDataUtil.getSkinParamsTypes(
      getSkinDefinitionFunction,
      skinName,
    );
    const styleProperties = cachedStyleProperties
      ? cachedStyleProperties
      : advancedStyleDataUtil.getSkinDefaultParams(
          getSkinDefinitionFunction,
          skinName,
        );

    const style = { properties: {}, propertiesSource: {} };

    _.forOwn(skinParams, function (paramType, paramName) {
      const newValue = styleProperties[paramName];
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/is-undefined
      if (!_.isUndefined(newValue)) {
        style.properties[paramName] = newValue;
        style.propertiesSource[paramName] = getPropertySource(newValue);
      }
    });

    return style;
  },

  mergeSharedProperties: function mergeSharedColorProperties(
    getSkinDefinitionFunction,
    newStyleProperties,
    oldStyleProperties,
    skinName,
    lastSkinName,
  ) {
    const skinParams = advancedStyleDataUtil.getSkinParamsTypes(
      getSkinDefinitionFunction,
      skinName,
    );
    const propertiesToMerge = _.transform(
      oldStyleProperties,
      function (result, paramValue, paramName) {
        if (
          isDefaultValue(
            getSkinDefinitionFunction,
            paramName,
            paramValue,
            lastSkinName,
          )
        ) {
          return;
        }

        const hasAlphaPrefix = paramName.indexOf(ALPHA_PREFIX) === 0;
        const paramNameWithoutAlpha = hasAlphaPrefix
          ? paramName.substr(ALPHA_PREFIX.length)
          : paramName;
        const alias = advancedStyleDataUtil.aliasForProperty(
          lastSkinName,
          paramNameWithoutAlpha,
        );

        if (!alias) {
          const paramType = skinParams[paramName];
          if (
            hasAlphaPrefix ||
            // eslint-disable-next-line you-dont-need-lodash-underscore/includes
            (_.includes(paramType, 'COLOR') && !_.includes(paramType, 'SHADOW'))
          ) {
            result[paramName] = paramValue;
          }

          return;
        }

        let newParamName = advancedStyleDataUtil.propertyForAlias(
          skinName,
          alias,
        );
        if (!newParamName) {
          return;
        }

        if (hasAlphaPrefix) {
          newParamName = ALPHA_PREFIX + newParamName;
        }

        result[newParamName] = paramValue;
      },
      {},
    );

    return _.merge(
      newStyleProperties,
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/keys
      _.pick(propertiesToMerge, _.keys(newStyleProperties)),
    );
  },
};
