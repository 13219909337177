import React from 'react';
import { IconButton, TextLabel } from '@wix/wix-base-ui';
import { symbol as Symbol } from '@wix/santa-editor-symbols';

import constants from '@/constants';
import { panels } from '@/stateManagement';
import { hoc, cx } from '@/util';

import type { ThunkAction, MapDispatchToProps } from 'types/redux';

import styles from './rightPanelFrame.scss';

interface OwnProps {
  panelName: string;
  panelClass?: string;
  automationId?: string;
  title?: string;
  shouldTranslate?: boolean;
}

interface DispatchProps {
  closeOpenedRightPanel: () => void;
}

interface WithPanelFrameProps {
  close: () => void;
}

interface HeaderProps {
  onClose: () => void;
  title: string;
  shouldTranslate: boolean;
}

const Header: React.FC<HeaderProps> = ({ onClose, title, shouldTranslate }) => (
  <div className={styles.header}>
    <div className={styles.headerTitle}>
      <TextLabel type="T03" value={title} shouldTranslate={shouldTranslate} />
    </div>
    <IconButton size="medium" className={styles.headerClose} onClick={onClose}>
      <Symbol name="close-tab" customDimensions={{ width: 12, height: 12 }} />
    </IconButton>
  </div>
);

interface RightPanelFrameProps
  extends OwnProps,
    WithPanelFrameProps,
    DispatchProps {}

class RightPanelFrameComponent extends React.Component<RightPanelFrameProps> {
  static defaultProps = {
    shouldTranslate: true,
  };

  constructor(props: AnyFixMe) {
    super(props);

    this.props.closeOpenedRightPanel();
  }

  render() {
    return (
      <div
        data-aid={this.props.automationId}
        className={cx(styles.panel, this.props.panelClass, {
          [styles.withHeaderPanel]: Boolean(this.props.title),
        })}
      >
        {this.props.title && (
          <Header
            title={this.props.title}
            shouldTranslate={this.props.shouldTranslate}
            onClose={this.props.close}
          />
        )}

        {this.props.children}
      </div>
    );
  }
}

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = {
  closeOpenedRightPanel: (): ThunkAction => (dispatch, getState) => {
    const openedRightPanels = panels.selectors.selectOpenRightPanels(
      getState(),
    );

    if (openedRightPanels.length > 1) {
      dispatch(panels.actions.closePanelByName(openedRightPanels[0].name));
    }
  },
};

export default hoc.connect(
  hoc.STORES.STATE_ONLY,
  null,
  mapDispatchToProps,
)(
  panels.hoc.panelFrame(constants.PANEL_TYPES.RIGHT)(RightPanelFrameComponent),
) as React.ComponentType<OwnProps>;
