import React from 'react';
import * as util from '@/util';
import constants from '@/constants';
import { translate } from '@/i18n';
import { databaseWelcomePanelView } from '@/addPanelData';
import { isResponsiveEditor } from '@wix/santa-editor-utils';

import upgrade from '../components/upgrade/upgrade';
import manageContent from '../components/manageContent/manageContent';
import welcomePage from '../components/welcomePage/welcomePage';
import dataProvider from './dataProvider';
import { getUserCollectionList } from '../components/userCollectionsList/userCollectionsList';
import experiment from 'experiment';
import wixAppsCollectionsList from '../components/wixAppsCollectionsList/wixAppsCollectionsList';
import externalCollectionsList from '../components/externalCollectionsList/externalCollectionsList';
import { getWixFormsCollectionList } from '../components/wixFormsCollectionsList/wixFormsCollectionsList';
import type { FormsMigrationTooltipProps } from './formCollectionsMigration/formsMigrationTooltip/formsMigrationTooltip';

const moveFormsToNewNamespaceOpen = experiment.isOpen(
  'specs.wixCode.moveFormsToNewNamespace',
);

const databaseWelcomePanelViewWithSuperAppOrigin = (
  props: React.ComponentProps<typeof databaseWelcomePanelView>,
) =>
  React.createElement(databaseWelcomePanelView, {
    ...props,
    renderedInSuperAppPanel: true,
  });

const getExtraTabs = ({
  showUpgradeToPremiumTab,
  hasExternalCollections,
  hasFormsCollections,
  hasWixAppsCollections,
  formsMigrationTooltip,
  formsMigrationHelperState,
}: {
  showUpgradeToPremiumTab: boolean;
  hasWixAppsCollections: boolean;
  hasFormsCollections: boolean;
  hasExternalCollections: boolean;
  formsMigrationTooltip: React.FC<FormsMigrationTooltipProps> | null;
  formsMigrationHelperState: [boolean, (state: boolean) => void];
}) => {
  const seperateCollectionTabs = experiment.isOpen(
    'specs.wixCode.DBDiscoverability',
  );

  const alwaysShowWixAppsTab = experiment.isOpen(
    'specs.wixCode.ShowDisabledWixAppCollections',
  );

  const [formsMigrationHelperSeen, setFormsMigrationHelperSeen] =
    formsMigrationHelperState;

  const showFormsMigrationHelper =
    hasFormsCollections &&
    moveFormsToNewNamespaceOpen &&
    !formsMigrationHelperSeen;

  const extraTabs: {
    name: string;
    title: string;
    helpId?: string;
    template: object;
    index: number;
    isPremiumSkin?: boolean;
    tabWrapper?: React.ReactNode;
    showDotIndicator?: boolean;
  }[] = [
    {
      name: 'ManageContent',
      title: seperateCollectionTabs
        ? 'CONTENT_MANAGER_6_BUTTON_SIDE_PANEL_MENU_USER_COLLECTIONS_LABEL'
        : 'CONTENT_MANAGER_6_BUTTON_SIDE_PANEL_MENU_COLLECTIONS',
      helpId: 'cf15c607-3032-47ca-80b1-2864dbd78ead',
      template: seperateCollectionTabs
        ? getUserCollectionList({
            showFormsMigrationHelper,
          })
        : manageContent,
      index: 1,
    },
  ];

  if (seperateCollectionTabs && hasFormsCollections) {
    extraTabs.push({
      name: 'WixFormCollections',
      title: 'CONTENT_MANAGER_6_BUTTON_SIDE_PANEL_MENU_FORM_COLLECTIONS',
      helpId: 'cf15c607-3032-47ca-80b1-2864dbd78ead',
      template: getWixFormsCollectionList({ setFormsMigrationHelperSeen }),
      index: extraTabs.length + 1,
      showDotIndicator: !!formsMigrationTooltip,
      tabWrapper: formsMigrationTooltip,
    });
  }

  if (
    seperateCollectionTabs &&
    (alwaysShowWixAppsTab || hasWixAppsCollections)
  ) {
    extraTabs.push({
      name: 'WixAppCollections',
      title:
        'CONTENT_MANAGER_6_BUTTON_SIDE_PANEL_MENU_WIX_APPS_COLLECTIONS_LABEL',
      helpId: 'cf15c607-3032-47ca-80b1-2864dbd78ead',
      template: wixAppsCollectionsList,
      index: extraTabs.length + 1,
    });
  }

  if (seperateCollectionTabs && hasExternalCollections) {
    extraTabs.push({
      name: 'ExternalCollections',
      title:
        'CONTENT_MANAGER_6_BUTTON_SIDE_PANEL_MENU_EXTERNAL_COLLECTIONS_LABEL',
      helpId: 'cf15c607-3032-47ca-80b1-2864dbd78ead',
      template: externalCollectionsList,
      index: extraTabs.length + 1,
    });
  }

  if (showUpgradeToPremiumTab) {
    extraTabs.push({
      name: 'upgrade',
      title: 'CONTENT_MANAGER_6_BUTTON_MAIN_MENU_UPGRADE',
      helpId: '8badb6f4-61f1-44ee-81b8-edad7da27641',
      template: upgrade,
      index: 99,
      isPremiumSkin: true,
    });
  }

  return extraTabs;
};

const getTemplate = (isDataModeOn: boolean) => {
  if (!isDataModeOn && !isResponsiveEditor()) {
    return databaseWelcomePanelViewWithSuperAppOrigin;
  }
  return welcomePage;
};

export default ({
  showUpgradeToPremiumTab,
  hasExternalCollections,
  hasFormsCollections,
  hasWixAppsCollections,
  isLoading,
  isDataModeOn,
  formsMigrationTooltip,
  formsMigrationHelperState,
}: {
  showUpgradeToPremiumTab: boolean;
  hasDynamicPages: boolean;
  hasCollections: boolean;
  hasWixAppsCollections: boolean;
  hasFormsCollections: boolean;
  hasExternalCollections: boolean;
  isLoading: boolean;
  isDataModeOn: boolean;
  formsMigrationTooltip: React.FC<FormsMigrationTooltipProps> | null;
  formsMigrationHelperState: [boolean, (state: boolean) => void];
}) => ({
  id: 'wixData',
  appDefId: 'wixData',
  appName: 'WixData',
  firstTimeTour: {
    templateFlow: [] as AnyFixMe[],
    manuallyInstalledFlow: [] as AnyFixMe[],
    promotionalFlow: [] as AnyFixMe[],
  },
  panel: {
    name: constants.SUPER_APPS.WIX_DATA_PANEL_NAME,
    label: util.workspace.isNewWorkspaceEnabled()
      ? translate('SiteApp_WixData_Panel_Title')
      : undefined,
    manage: {
      title: 'CONTENT_MANAGER_6_BUTTON_SIDE_PANEL_MENU_MAIN',
      helpId: '65fbf381-68c0-4470-a31c-d77cba1a5ca3',
      template: getTemplate(isDataModeOn),
    },
    pages: {
      isHidden: true,
    },
    addElements: {
      isHidden: true,
    },
    learnMore: {
      title: 'CONTENT_MANAGER_6_BUTTON_SIDE_PANEL_MENU_LEARN_MORE',
      section1HeaderViewA: 'SiteApp_WixData_LearnMore_Tab_HelpCenter',
      section5HeaderViewA: 'CONTENT_MANAGER_6_BUTTON_LEARN_MORE_WIX_VIDEOS',
      data: dataProvider.helpData,
      rootClass: 'wix-data-learn-more-panel',
    },
  },
  extraTabs: getExtraTabs({
    showUpgradeToPremiumTab,
    hasExternalCollections,
    hasWixAppsCollections,
    hasFormsCollections,
    formsMigrationTooltip,
    formsMigrationHelperState,
  }),
  isAppInstalled: () => true,
  hideTabs: !isDataModeOn,
  isLoading,
});
