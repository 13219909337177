// @ts-nocheck
import publishConfigurationPanel from './config/publishConfigurationPanel';
import publishSuccessPanel from './publishSuccessPanel';
import publishRCSuccessPanel from './publishRCSuccessPanel';
import publishRCPreparePanel from './publishRCPreparePanel';
import shoutoutPanel from './content/shoutoutPanel';
import fourthPublishSuccessPanel from './fourthPublishSuccessPanel/fourthPublishSuccessPanel';

const config = {
  publishConfigurationPanel,
};

export {
  config,
  publishSuccessPanel,
  publishRCSuccessPanel,
  fourthPublishSuccessPanel,
  shoutoutPanel as shoutOut,
  publishRCPreparePanel,
};
