import type { CompRef } from 'types/documentServices';
import {
  ResizeType,
  type Position,
  type PositionDifferences,
  type DirectionIndicators,
  type DirectionName,
  type OptionsI,
} from './types';
import type { EditorAPI } from '@/editorAPI';
import constants from '@/constants';
const { COMP_TYPES } = constants;

class ResizeService {
  private editorAPI;

  constructor({ editorAPI }: { editorAPI: EditorAPI }) {
    this.editorAPI = editorAPI;
  }

  private getPositionDifferences(parent: Position, child: Position) {
    return {
      left: parent.left - child.left,
      right: parent.right - child.right,
      top: parent.top - child.top,
      bottom: parent.bottom - child.bottom,
    };
  }
  private getCollisions(diff: PositionDifferences) {
    const buffer = 1;
    return {
      top: Math.abs(diff.top) <= buffer,
      bottom: Math.abs(diff.bottom) <= buffer,
      left: Math.abs(diff.left) <= buffer,
      right: Math.abs(diff.right) <= buffer,
    };
  }
  private getOverflows(diff: PositionDifferences) {
    return {
      left: diff.left > 0,
      right: diff.right < 0,
      top: diff.top > 0,
      bottom: diff.bottom < 0,
    };
  }
  private getDirectionsHighlightConditions(
    collisions: DirectionIndicators,
    overflows: DirectionIndicators,
  ): Record<DirectionName, boolean> {
    const conditions = {
      left: collisions.left || overflows.left,
      right: collisions.right || overflows.right,
      top: collisions.top || overflows.top,
      bottom: collisions.bottom || overflows.bottom,
    };
    return {
      left: conditions.left,
      right: conditions.right,
      top: conditions.top,
      bottom: conditions.bottom,
      topRight: conditions.top || conditions.right,
      topLeft: conditions.top || conditions.left,
      bottomLeft: conditions.bottom || conditions.left,
      bottomRight: conditions.bottom || conditions.right,
    };
  }

  private checkCollision(
    parent: Position,
    child: Position,
    options: OptionsI,
  ): boolean {
    const diff = this.getPositionDifferences(parent, child);
    const collisions = this.getCollisions(diff);
    const overflows = this.getOverflows(diff);

    const directions = this.getDirectionsHighlightConditions(
      collisions,
      overflows,
    );

    if (options.resizeType === ResizeType.Push) {
      return directions.bottom;
    }

    const { directionName }: { directionName: DirectionName } =
      this.editorAPI.mouseActions.getRegisteredMouseMoveAction()?.params ??
      options;

    return directions[directionName];
  }

  private findCollisionElements(
    parent: Position,
    childrens: Position[],
    options: OptionsI,
  ): CompRef[] {
    return childrens
      .filter((child) => this.checkCollision(parent, child, options))
      .map(({ compRef }) => compRef);
  }

  private mapCompsPositions(compRefs: CompRef[]): Position[] {
    const { components } = this.editorAPI;
    return compRefs.map((comp: any) => {
      const { bounding } = components.layout.stage.getRelativeToScreen(comp);

      const { x, y, width = 0, height = 0 } = bounding;

      return {
        top: y,
        bottom: y + height,
        left: x,
        right: x + width,
        compRef: comp,
      };
    });
  }

  private isRepeaterOrChildrenOfRepeater(compRef: CompRef): boolean {
    const { components } = this.editorAPI;
    const type = components.getType(compRef);
    const isRepeater = COMP_TYPES.REPEATER === type;

    const containerRef = components.getContainer(compRef);
    const containerType = components.getType(containerRef);
    const isChildrenOfRepeater = COMP_TYPES.REPEATER === containerType;

    return isRepeater || isChildrenOfRepeater;
  }

  public getChildrensBlockingResize(
    parentCompRef: CompRef,
    childrensCompsPositions: Position[],
    options: OptionsI,
  ): CompRef[] {
    const [parentPosition] = this.mapCompsPositions([parentCompRef]);

    if (this.isRepeaterOrChildrenOfRepeater(parentCompRef)) {
      const newChildrensCompsPositions =
        this.getChildrensCompsPositions(parentCompRef);

      return this.findCollisionElements(
        parentPosition,
        newChildrensCompsPositions,
        options,
      );
    }

    return this.findCollisionElements(
      parentPosition,
      childrensCompsPositions,
      options,
    );
  }

  public getChildrensCompsPositions(parentCompRef: CompRef): Position[] {
    const { columns, components } = this.editorAPI;
    const type = components.getType(parentCompRef);
    const excludeComponents = [COMP_TYPES.TABS];

    if (excludeComponents.includes(type)) {
      return [];
    }

    const isMultiColumnsStrip = columns.isMultiColumnsStrip(parentCompRef);
    const isStrip = columns.isStrip(parentCompRef);

    const childrensCompsRefs =
      components.getChildrenOrScopedChildren(parentCompRef);

    if (isStrip && isMultiColumnsStrip) {
      const columnsChildrensRefs = childrensCompsRefs.flatMap((compRef) =>
        components.getChildrenOrScopedChildren(compRef),
      );

      return this.mapCompsPositions(columnsChildrensRefs);
    }

    return this.mapCompsPositions(childrensCompsRefs);
  }
}

export const createResizeService = ({ editorAPI }: { editorAPI: EditorAPI }) =>
  new ResizeService({ editorAPI });
