import type { CompRef } from '@wix/document-services-types';
import type { EditorAPI } from '@/editorAPI';

export const isGhostCollapsed = (editorAPI: EditorAPI, compRef: CompRef) => {
  const properties = editorAPI.dsRead.components.properties.get(compRef);
  return properties?.ghost === 'COLLAPSED';
};

export const isRendered = (editorAPI: EditorAPI, compRef: CompRef) => {
  return editorAPI.dsRead.components.is.rendered(compRef);
};

export const hasVolume = (editorAPI: EditorAPI, compRef: CompRef) => {
  const { width, height } =
    editorAPI.dsRead.components.layout.getRelativeToScreen(compRef);
  return width > 0 && height > 0;
};
