import {
  closeDrillInPanel,
  onOpenQuickEditWithInternalDrillIns,
  openComponentPanelDrillInInternal,
  openQuickEditDrillInInternal,
  isDrillInOpen,
  closeAllDrillInPanels,
  discardAllDrillInPanels,
} from '../quickEditEngineUtils';
import type { QuickEditEngineScope } from '../quickEditEngineEntryPoint';
import type { QuickEditPanelOwnProps } from './quickEditPanel';
import type { CompRef } from 'types/documentServices';

export const mapStateToProps = (scope: QuickEditEngineScope) => {
  const { drillInContentSlot, store } = scope;

  return {
    contributedDrillInPanels: store.getContributedDrillInPanels(),
    drillInPanelsContent: drillInContentSlot.getItems(true),
  };
};
export const mapDispatchToProps = (
  scope: QuickEditEngineScope,
  {
    rootControlCompRef,
    getOverrideCompControls,
    onControlClick,
    onComponentChanged,
    isComponentFiltered,
    getPermissionsConfig,
    onLinkAction,
    internalDrillIns,
    allowedRootComponents,
    onSelectedComponentChanged,
  }: QuickEditPanelOwnProps,
) => {
  const componentControlWrapperProps = {
    getOverrideCompControls,
    onControlClick,
    onComponentChanged,
    isComponentFiltered,
    getPermissionsConfig,
    openComponentPanelDrillIn: (title?: string) =>
      openComponentPanelDrillInInternal(scope, title),
    onLinkAction,
  };
  const { editorAPI, store } = scope;
  return {
    onSelectedComponentChanged: (compId: string) => {
      onSelectedComponentChanged?.(compId);
      if (
        internalDrillIns &&
        isDrillInOpen(scope) &&
        allowedRootComponents.includes(
          editorAPI.components.getType(editorAPI.components.get.byId(compId)),
        ) &&
        store.getCurrentEditedCompId() !== compId
      ) {
        closeAllDrillInPanels(scope);
      }
    },
    onPanelOpenInternalDrillIn: () => {
      onOpenQuickEditWithInternalDrillIns(
        scope,
        rootControlCompRef,
        componentControlWrapperProps,
      );
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onPanelReloadWithInternalDrillIns: (rootControlCompRef: CompRef) => {
      if (isDrillInOpen(scope)) {
        closeAllDrillInPanels(scope);
      }
    },
    onPanelCloseWithInternalDrillIns: () => {
      discardAllDrillInPanels(scope);
      scope.store.setCurrentEditedCompId(null);
    },
    openComponentPanelInternalDrillIn: (title?: string) => {
      openComponentPanelDrillInInternal(scope, title);
    },
    openQuickEditInternalDrillIn: (compRef: CompRef) => {
      openQuickEditDrillInInternal(
        scope,
        compRef,
        componentControlWrapperProps,
      );
    },
    onBack: () => {
      closeDrillInPanel(scope);
    },
  };
};
