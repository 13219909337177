import { sections as sectionsUtils } from '@/util';

import { MAX_PAGES_TO_MIGRATE } from '../constants';
import { MIGRATION_VERSION } from '../version';
import { MigrationFlow } from '../types';
import { isForceSectionsMigration } from '../utils';

import type { CompRef } from 'types/documentServices';
import type { SectionsMigrationScope as Scope } from '../scope';

export function migrationFlowValidation(
  scope: Scope,
  migrationFlow: MigrationFlow,
  pagesRefs: CompRef[],
) {
  const { documentServices } = scope.editorAPI;
  const siteSectionsEditorData = sectionsUtils.getSiteSectionsEditorData(
    documentServices as any,
  );

  return {
    isValidOnBoarding:
      migrationFlow === MigrationFlow.ADI
        ? documentServices.generalInfo.isSiteFromOnBoarding()
        : true,
    isValidAmountOfPages:
      migrationFlow === MigrationFlow.Editor
        ? pagesRefs.length < MAX_PAGES_TO_MIGRATE
        : true,
    isValidVersion:
      (migrationFlow === MigrationFlow.ADI ||
        migrationFlow === MigrationFlow.Editor ||
        migrationFlow === MigrationFlow.Editor2Fix ||
        migrationFlow === MigrationFlow.HeavySite) &&
      !isForceSectionsMigration()
        ? siteSectionsEditorData?.migrationVersion !== MIGRATION_VERSION
        : true,
  };
}
