import _ from 'lodash';
import * as stateManagement from '@/stateManagement';
import * as gfppData from '@/gfppData';
import { getMainActionDescriptor } from '../mainActionDescriptors';
import * as util from '@/util';
import type { EditorAPI } from '@/editorAPI';
import type { GFPPData, GFPPActionType } from '@wix/editor-component-types';

import constants from '@/constants';
import type { CompRef } from '@wix/document-services-types';

const { isReferredComponent, isReferredId, isDescendantOfBlocksWidget } =
  stateManagement.components.selectors;

const { isMultiselect } = util.array;

const DISABLED_PRESETS_ACTIONS_FOR_BLOCKS_WIDGET_INNER_COMPONENTS = [
  constants.ROOT_COMPS.GFPP.ACTIONS.ANIMATION,
];

export default function (
  editorAPI: EditorAPI,
  compRef: CompRef | CompRef[],
  compGfppData: GFPPData,
) {
  compRef = _.castArray(compRef);
  if (isReferredComponent(_.head(compRef)) && !isMultiselect(compRef)) {
    const overridesWhenReferredGfpp =
      editorAPI.components.is.overridesWhenReferred(_.head(compRef))?.gfpp;
    const canLeaveMainActionEmpty =
      overridesWhenReferredGfpp?.canLeaveMainActionEmpty;
    const isWithinBlocksWidget =
      isReferredId(_.head(compRef).id) &&
      isDescendantOfBlocksWidget(compRef, editorAPI.dsRead);
    const disableActions = _.union(
      overridesWhenReferredGfpp?.disableActions,
      isWithinBlocksWidget
        ? DISABLED_PRESETS_ACTIONS_FOR_BLOCKS_WIDGET_INNER_COMPONENTS
        : [],
    );
    const disableMainActions =
      overridesWhenReferredGfpp?.disableMainActionsByLabelKey ?? [];
    const presetToBeMainAction =
      overridesWhenReferredGfpp?.presetToBeMainAction;

    compGfppData.presetActions = _.omit(
      compGfppData.presetActions,
      disableActions,
    );

    compGfppData.enabledActions = _.reject(
      compGfppData.enabledActions,
      (action) => disableActions.includes(action),
    );
    compGfppData.mobileEnabledActions = _.reject(
      compGfppData.mobileEnabledActions,
      (action) => disableActions.includes(action),
    );
    compGfppData.mainActions = _.reject(compGfppData.mainActions, (action) =>
      disableMainActions.includes(action?.label),
    );
    if (
      _.isEmpty(compGfppData.mainActions) &&
      !canLeaveMainActionEmpty &&
      compGfppData.enabledActions
    ) {
      const mainActionType = compGfppData.enabledActions.includes(
        presetToBeMainAction as GFPPActionType,
      )
        ? presetToBeMainAction
        : compGfppData.enabledActions.find((action) =>
            getMainActionDescriptor(action),
          );

      if (mainActionType) {
        compGfppData.mainActions =
          gfppData.externalGfppDataWrapper.createMainActions([
            getMainActionDescriptor(mainActionType as GFPPActionType),
          ]);
      }
    }

    compGfppData.mobileMainActions = _.reject(
      compGfppData.mobileMainActions,
      (action) => disableMainActions.includes(action?.label),
    );
  }
}
