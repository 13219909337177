// @ts-nocheck
import constants from '@/constants';
import * as helpIds from '@/helpIds';
import gfppDataUtils from '../../utils/gfppDataUtils';
import type { EditorAPI } from '@/editorAPI';
import type { CompRef } from '@wix/document-services-types';

const { ACTIONS } = constants.ROOT_COMPS.GFPP;
const mediaContainerBackgroundPanelName =
  'compPanels.panels.MediaContainer.backgroundPanel';
const mediaContainerDesignPanelName = 'customizeDesignDataPanel.standalone';

export default {
  mainActions: [
    {
      label: 'gfpp_mainaction_background_listsandgrids',
      isApplied: true,
      isSelected: gfppDataUtils.getPanelStateFn(
        mediaContainerBackgroundPanelName,
      ),
      onClick(editorAPI: EditorAPI, compRef: CompRef, origin: string) {
        gfppDataUtils.toggleComponentPanel(
          editorAPI,
          mediaContainerBackgroundPanelName,
          {
            selectedComponent: compRef,
            prependNoFillPreset: true,
            origin,
          },
        );
      },
    },
  ],
  enabledActions: [ACTIONS.DESIGN, ACTIONS.ANIMATION, ACTIONS.HELP],
  mobileEnabledActions: [ACTIONS.HELP, ACTIONS.ANIMATION],
  presetActions: {
    design: {
      isSelected: gfppDataUtils.getPanelStateFn(mediaContainerDesignPanelName),
      onClick(editorAPI: EditorAPI, compRef: CompRef, origin: string) {
        gfppDataUtils.toggleComponentPanel(
          editorAPI,
          mediaContainerDesignPanelName,
          {
            selectedComponent: compRef,
            overrideTitle: 'lists&grids_design_item_title',
            origin,
          },
        );
      },
    },
    help: {
      helpId: helpIds.GFPP.MEDIA_CONTAINER,
      mobileHelpId: helpIds.GFPP.MEDIA_CONTAINER_MOBILE,
    },
  },
};
