// @ts-nocheck
const CONSTANTS = {
  USI_SEO_MAX_LENGTH: 35,
  NAME_MAX_LENGTH: 40,
  TITLE_SEO_MAX_LENGTH: 70,
  KEYWORD_SEO_MAX_LENGTH: 250,
  KEYWORD_SEO_MAX_WORDS: 10,
  DESCRIPTION_SEO_MAX_LENGTH: 160,
  PASSWORD_TEMPLATE: '########',
  PASSWORD_MAX_LENGTH: 15,
  INVALID_CHARACTERS: '[!#$%^&()+=}{";:\\/><~\\]\\[]',
  PERMISSION_STATES: {
    NONE: 'none',
    PASSWORD: 'password',
    MEMBERS: 'members',
  },
};

export default Object.freeze(CONSTANTS);
