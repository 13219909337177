import constants from '@/constants';
import * as helpIds from '@/helpIds';
import gfppDataUtils from '../../utils/gfppDataUtils';
import { sections } from '@/util';
import {
  QuickEditApiKey,
  DesignerPanelsApiKey,
  EditorRestrictionsApiKey,
} from '@/apis';
import experiment from 'experiment';

import type { CompRef } from 'types/documentServices';
import type { EditorAPI } from '@/editorAPI';
import type { GFPPAction } from '@wix/editor-component-types';

const { ACTIONS } = constants.ROOT_COMPS.GFPP;

const toggleDesignPanel = gfppDataUtils.getTogglePanelFn(ACTIONS.DESIGN);
const toggleLayoutPanel = gfppDataUtils.getTogglePanelFn(ACTIONS.LAYOUT);

function getDesignerScanAction(): GFPPAction[] {
  return experiment.isOpen('se_addDesignerSection')
    ? [
        {
          label: 'Scan Header',
          shouldTranslate: false,
          onClick: (editorAPI: EditorAPI, compRefs: CompRef[]) => {
            const designerPanelsApi =
              editorAPI.host.getAPI(DesignerPanelsApiKey);
            designerPanelsApi.openDesignerScanPresetPanel(compRefs[0]);
          },
        },
      ]
    : [];
}

export default {
  untranslatable: true,
  mainActions: (editorAPI: EditorAPI) => {
    const designAction = {
      label: editorAPI.zoomMode.isStageZoomMode()
        ? 'RightClick_Menu_Header_Zoom_Out_Mode_Zoom_Label'
        : 'gfpp_mainaction_header',
      isApplied: true,
      isSelected: gfppDataUtils.getPanelStateFn(ACTIONS.DESIGN),
      onClick: async (
        editorAPI: EditorAPI,
        compRef?: CompRef[],
        origin?: string,
        optionalPropsOverrides?: Record<string, unknown>,
      ) => {
        if (editorAPI.zoomMode.isStageZoomMode()) {
          await editorAPI.zoomMode.exitZoomMode({
            biParams: { origin },
          });
        }
        toggleDesignPanel(editorAPI, compRef, origin, optionalPropsOverrides);
      },
    };
    const quickEditAction = {
      label: `gfpp_mainaction_edit_section_label`,
      onClick: (editorAPI: EditorAPI, compRefs: CompRef[], origin: string) => {
        const quickEditAPI = editorAPI.host.getAPI(QuickEditApiKey);
        quickEditAPI.openPanel({
          rootControlCompRef: compRefs[0],
          origin,
        });
      },
    };
    const isQuickEditAvailable = editorAPI.host
      .getAPI(QuickEditApiKey)
      .isQuickEditAvailable();
    const quickEditActionIfAvailable = isQuickEditAvailable
      ? [quickEditAction]
      : [];
    const editorRestrictionsApi = editorAPI.host.getAPI(
      EditorRestrictionsApiKey,
    );
    const isHeaderGfppVisible = editorRestrictionsApi.allowed(
      'gfpp_header.visible',
    );
    const designerScanAction = getDesignerScanAction();
    const designActionIfAvailable = isHeaderGfppVisible ? [designAction] : [];
    return [
      ...designerScanAction,
      ...designActionIfAvailable,
      ...quickEditActionIfAvailable,
    ];
  },
  enabledActions: () =>
    sections.isSectionsEnabled()
      ? [ACTIONS.SETTINGS, ACTIONS.HELP]
      : [ACTIONS.SETTINGS, ACTIONS.DESIGN, ACTIONS.HELP],
  mobileEnabledActions: [ACTIONS.HELP],
  mobileMainActions: [
    {
      label: 'mobile_gfpp_mainaction_headerscroll',
      isApplied: true,
      automationId: 'gfpp-button-layout',
      onClick: toggleLayoutPanel,
    },
  ],
  presetActions: () => ({
    help: {
      helpId: helpIds.GFPP.HEADER,
      mobileHelpId: helpIds.GFPP.HEADER_SCROLL_MOBILE,
    },
    settings: {
      tooltip: sections.isSectionsEnabled()
        ? 'desktop_headerscroll_panel_title'
        : 'gfpp_tooltip_settings',
    },
  }),
};
