import _ from 'lodash';
import experiment from 'experiment';
import { SelectionApiKey } from '@/apis';
import * as util from '@/util';
import { translate } from '@/i18n';
import constants from '@/constants';
import { gfppModel } from '@/gfppData';
import * as stateManagement from '@/stateManagement';
import * as coreBi from '@/coreBi';
import repeaterItemDesignDataChangePlugin from './componentPlugins/designDataChange/repeaterItemDesignDataChangePlugin';
import * as upgradeToMenuContainerTeaser from './componentPlugins/selectionChangePlugin/upgradeToMenuContainerTeaser';
import * as moveToTeaser from './componentPlugins/selectionChangePlugin/moveToTeaser';
import * as createUniqueTextForMobile from './componentPlugins/selectionChangePlugin/createUniqueTextForMobile';
import * as platformEvents from 'platformEvents';
import { waitForAddedCompRef } from '@/componentsAddUtils';
import {
  getRefComponentDisplayName,
  getRefComponentAppDefinitionName,
} from '@/documentServices';
import type { EditorAPI } from '@/editorAPI';
import type { CompRef } from 'types/documentServices';

const ACTIONS_TO_CLEAN_ON_MODES_SCOPE_CHANGE = [
  constants.ACTIONS.SCREEN_IN,
  constants.ACTIONS.EXIT,
  constants.ACTIONS.MODE_IN,
  constants.ACTIONS.MODE_EXIT,
  constants.ACTIONS.MODE_CHANGE,
];

function predicateForMoveToTeaser(component: AnyFixMe, editorAPI: AnyFixMe) {
  if (!editorAPI.isMobileEditor()) {
    return false;
  }

  if (
    !editorAPI.mobile.menuContainer.isExist() ||
    !component ||
    editorAPI.components.getType(component) ===
      'wysiwyg.viewer.components.MenuToggle'
  ) {
    return false;
  }

  if (
    editorAPI.components.getType(component) ===
    'wysiwyg.viewer.components.MenuContainer'
  ) {
    return true;
  }
  return editorAPI.components.isDescendantOfComp(
    component,
    editorAPI.siteSegments.getHeader(),
  );
}

function predicateForCreateUniqueTextForMobile(
  component: CompRef | CompRef[],
  editorAPI: EditorAPI,
) {
  const compRef = Array.isArray(component) ? component[0] : component;

  const mobileOnlyComponent =
    editorAPI.mobile.mobileOnlyComponents.isMobileOnlyComponent(compRef.id);

  if (!editorAPI.isMobileEditor() || mobileOnlyComponent) {
    return false;
  }
  return (
    editorAPI.components.getType(compRef) ===
    'wysiwyg.viewer.components.WRichText'
  );
}

function notifyOnSelectionChange(editorAPI: AnyFixMe) {
  editorAPI.dsActions.platform.notifyAppsOnCustomEvent(
    platformEvents.factory.componentSelectionChanged(
      editorAPI.selection.getSelectedComponents(),
    ),
  );
}

/**
 * @deprecated
 * @param editorAPI
 */
function registerComponentsPlugins(editorAPI: EditorAPI) {
  const { pluginService } = editorAPI;

  const selectionApi = editorAPI.host.getAPI(SelectionApiKey);
  const { selectionChangePlugin } = selectionApi;

  pluginService.registerPlugin(
    pluginService.pluginConstants.DOUBLE_CLICK,
    'wysiwyg.viewer.components.WRichText',
    function (
      _editorAPI: EditorAPI,
      { comp: [compRef] }: { comp: CompRef[]; evt: MouseEvent },
    ) {
      const isMobileEditor = editorAPI.isMobileEditor();
      const isMobileOnlyComponent =
        editorAPI.dsRead.mobile?.mobileOnlyComponents?.isMobileOnlyComponent(
          compRef?.id,
        );

      // mobile only components should get all editing options that can be applied in the desktop
      // so we’re opening the desktop panels for them

      // NOTE: use `editorAPI.openComponentPanel` for NOT mobile only components to resolve next bug - https://jira.wixpress.com/browse/WEED-24525
      if (isMobileEditor && !isMobileOnlyComponent) {
        editorAPI.openComponentPanel(constants.componentPanels.settings);
        return;
      }

      editorAPI.bi.event(coreBi.events.textControls.Text_Panel_opened, {
        component_id: compRef?.id,
        origin: constants.USER_ACTIONS.ORIGIN.DOUBLE_CLICK,
      });
      // same as on the gfpp "edit text" click
      // https://github.com/wix-private/santa-editor/blob/a62bf831a16b6508d73679d13d02081b12686e18/santa-editor/packages/gfppData/data/internalComponents/wRichTextGfppData.ts#L189-L193
      editorAPI.text.startEditing();
    },
  );

  pluginService.registerPlugin(
    pluginService.pluginConstants.DOUBLE_CLICK,
    'wysiwyg.viewer.components.Repeater',
    _.noop,
  );

  pluginService.registerPlugin(
    pluginService.pluginConstants.DOUBLE_CLICK,
    'wysiwyg.viewer.components.SiteButton',
    performMainActionIfNotOnMobileEditor,
  );
  pluginService.registerPlugin(
    pluginService.pluginConstants.DOUBLE_CLICK,
    'wysiwyg.viewer.components.inputs.DatePicker',
    performMainActionIfNotOnMobileEditor,
  );
  pluginService.registerPlugin(
    pluginService.pluginConstants.DOUBLE_CLICK,
    'wysiwyg.viewer.components.inputs.FileUploader',
    performMainActionIfNotOnMobileEditor,
  );
  pluginService.registerPlugin(
    pluginService.pluginConstants.DOUBLE_CLICK,
    constants.MULTISELECT.TYPE,
    _.noop,
  );
  pluginService.registerPlugin(
    pluginService.pluginConstants.DOUBLE_CLICK,
    'wysiwyg.viewer.components.Group',
    _.noop,
  );
  pluginService.registerPlugin(
    pluginService.pluginConstants.DOUBLE_CLICK,
    'wysiwyg.viewer.components.inputs.TextInput',
    performMainActionIfNotOnMobileEditor,
  );

  pluginService.registerPlugin(
    pluginService.pluginConstants.DOUBLE_CLICK,
    'mobile.core.components.Page',
    function (api: AnyFixMe, params: AnyFixMe) {
      const pagesContainerLayout =
        editorAPI.siteSegments.getPagesContainerAbsLayout();
      const isOutOfGrid =
        params.evt.pageX < pagesContainerLayout.x ||
        params.evt.pageX > pagesContainerLayout.x + pagesContainerLayout.width;
      const component = params.comp[0];

      if (editorAPI.dsRead.pages.popupPages.isPopup(component.id)) {
        const compMainAction = gfppModel.getComponentMainActionClick(
          editorAPI,
          params.comp,
        );
        if (_.isFunction(compMainAction)) {
          compMainAction(
            editorAPI,
            params.comp,
            constants.USER_ACTIONS.ORIGIN.DOUBLE_CLICK,
          );
        }
        return;
      }

      if (isOutOfGrid) {
        stateManagement.panels.actions.openLeftPanel(
          constants.ROOT_COMPS.LEFTBAR.DESIGN_PANEL_NAME,
          {
            selectedView: constants.DESIGN_PANEL.VIEWS.BACKGROUND,
            origin: 'Page',
          },
        );
        return;
      }

      editorAPI.openComponentPanel(constants.componentPanels.design);
    },
  );

  function performMainActionIfNotOnMobileEditor(
    api: AnyFixMe,
    params: AnyFixMe,
  ) {
    if (!editorAPI.isMobileEditor()) {
      const compMainAction = gfppModel.getComponentMainActionClick(
        editorAPI,
        params.comp,
      );
      if (_.isFunction(compMainAction)) {
        compMainAction(
          editorAPI,
          params.comp,
          constants.USER_ACTIONS.ORIGIN.DOUBLE_CLICK,
        );
      }
    }
  }

  function removeRunCodeBehaviors(
    behaviorsService: AnyFixMe,
    compRefs: AnyFixMe,
  ) {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
    _.forEach(compRefs, function (compRef) {
      _(compRef) // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/bind
        .thru(_.bind(behaviorsService.get, behaviorsService))
        //@ts-expect-error
        .filter(function (behaviorObj) {
          const behaviorType = behaviorObj?.behavior?.type;
          const behaviorName = behaviorObj?.behavior?.name;
          return (
            behaviorType === 'widget' &&
            (behaviorName === 'runCode' || behaviorName === 'runAppCode')
          );
        })
        .forEach(function (behaviorObj: AnyFixMe) {
          behaviorsService.remove(
            compRef,
            behaviorObj.action,
            { id: behaviorObj.behavior.targetId },
            behaviorObj.behavior,
          );
        });
    });
  }

  function removeRelevantBehaviorsWhenChangingModesScope(
    behaviorsService: AnyFixMe,
    componentsService: AnyFixMe,
    compRefs: AnyFixMe,
  ) {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
    _.forEach(compRefs, function (compRef) {
      _(compRef) // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/bind
        .thru(_.bind(behaviorsService.get, behaviorsService))
        //@ts-expect-error
        .filter((behaviorObj) =>
          // TODO: Fix this the next time the file is edited.
          // eslint-disable-next-line you-dont-need-lodash-underscore/includes
          _.includes(
            ACTIONS_TO_CLEAN_ON_MODES_SCOPE_CHANGE,
            behaviorObj.action,
          ),
        )
        .forEach(function (behaviorObj: AnyFixMe) {
          componentsService.behaviors.remove(compRef, behaviorObj);
        });
    });
  }

  function handleCodeBehaviorsOnSetContainer(
    compsRootsInfo: AnyFixMe,
    attachCandidateRef: AnyFixMe,
    behaviorsService: AnyFixMe,
    componentsServices: AnyFixMe,
  ) {
    const newRoot = componentsServices.getPage(attachCandidateRef);
    const otherScopeCompRefs = _(compsRootsInfo)
      .reject((compRefAndRoot) => _.isEqual(compRefAndRoot.root, newRoot))
      .map('ref')
      .value();
    removeRunCodeBehaviors(behaviorsService, otherScopeCompRefs);
  }

  function handleModeRelatedBehaviorsOnSetContainer(
    compsModefulAncestorsInfo: AnyFixMe,
    attachCandidateRef: AnyFixMe,
    behaviorsService: AnyFixMe,
    componentsService: AnyFixMe,
  ) {
    const newModefulAncestor =
      componentsService.modes.getFirstAncestorOrSelfWithModeDefinitions(
        attachCandidateRef,
      );
    const differentModesScopeCompRefs = _(compsModefulAncestorsInfo)
      .reject((refAndAncestor) =>
        _.isEqual(refAndAncestor.ancestor, newModefulAncestor),
      )
      .map('ref')
      .value();
    removeRelevantBehaviorsWhenChangingModesScope(
      behaviorsService,
      componentsService,
      differentModesScopeCompRefs,
    );
  }

  function buildRootInfoArrForCompAndDescendants(
    componentsService: AnyFixMe,
    refAndRoot: AnyFixMe,
  ) {
    return (
      _(refAndRoot)
        .thru(() =>
          componentsService
            .getChildren_DEPRECATED_BAD_PERFORMANCE(refAndRoot.ref, true)
            .concat(refAndRoot.ref),
        )
        //@ts-expect-error
        .map((compRef) => ({ ref: compRef, root: refAndRoot.root }))
        .value()
    );
  }

  function buildModefulAncestorInfoArrForCompAndDescendants(
    componentsService: AnyFixMe,
    refAndAncestor: AnyFixMe,
  ) {
    return (
      _(refAndAncestor)
        .thru(() =>
          componentsService
            .getChildren_DEPRECATED_BAD_PERFORMANCE(refAndAncestor.ref, true)
            .concat(refAndAncestor.ref),
        )
        //@ts-expect-error
        .map((compRef) => ({ ref: compRef, ancestor: refAndAncestor.ancestor }))
        .value()
    );
  }

  function getCompsAndDescendantsRootsInfoArr(
    componentsService: AnyFixMe,
    compRefsAndRoots: AnyFixMe,
  ) {
    return _.flatMap(
      compRefsAndRoots,
      _.partial(buildRootInfoArrForCompAndDescendants, componentsService),
    );
  }

  function getCompsAndDescendantsModefulAncestorInfoArr(
    componentsService: AnyFixMe,
    compsAndModefulAncestors: AnyFixMe,
  ) {
    return _.flatMap(
      compsAndModefulAncestors,
      _.partial(
        buildModefulAncestorInfoArrForCompAndDescendants,
        componentsService,
      ),
    );
  }

  function onComponentContainerChanged(
    attachCandidateRef: AnyFixMe,
    compRootsInfoArr: AnyFixMe,
    compModefulAncestorsInfoArr: AnyFixMe,
    componentsService: AnyFixMe,
    behaviorsService: AnyFixMe,
  ) {
    const compAndDescendantsRootsInfoArr = getCompsAndDescendantsRootsInfoArr(
      componentsService,
      compRootsInfoArr,
    );
    const compsAndDescendantsModefulAncestorsInfoArr =
      getCompsAndDescendantsModefulAncestorInfoArr(
        componentsService,
        compModefulAncestorsInfoArr,
      );
    handleCodeBehaviorsOnSetContainer(
      compAndDescendantsRootsInfoArr,
      attachCandidateRef,
      behaviorsService,
      componentsService,
    );
    handleModeRelatedBehaviorsOnSetContainer(
      compsAndDescendantsModefulAncestorsInfoArr,
      attachCandidateRef,
      behaviorsService,
      componentsService,
    );
  }

  editorAPI.registerCompNamePlugin(
    'wysiwyg.viewer.components.HoverBox',
    function (compRef) {
      const modesTypes = editorAPI.components.modes.getTypes() as AnyFixMe;
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/filter
      const hoverModes = _.filter(
        editorAPI.components.modes.getModes(compRef),
        ['type', modesTypes.HOVER],
      );
      const activeModes =
        editorAPI.components.modes.getComponentActiveModeIds(compRef);
      for (let i = 0; i < hoverModes.length; i++) {
        if (activeModes[hoverModes[i].modeId]) {
          return translate('component_label_hover_box_onhover');
        }
      }
      return translate('component_label_hover_box');
    },
  );

  editorAPI.registerCompNamePlugin(
    'wysiwyg.viewer.components.RefComponent',
    (compRef) =>
      getRefComponentDisplayName(editorAPI.dsRead, compRef) ||
      getRefComponentAppDefinitionName(editorAPI.dsRead, compRef) ||
      translate('component_label_RefComponent'),
  );

  editorAPI.registerCompNamePlugin(
    'wysiwyg.viewer.components.ClipArt',
    function () {
      return translate('component_label_clipart');
    },
  );

  editorAPI.registerCompNamePlugin(
    'wysiwyg.viewer.components.Column',
    function (compRef) {
      const label = _(compRef)
        .thru(editorAPI.components.getType)
        .thru((str) => str.split('.')) // TODO: replace with _.split when lodash version 4
        .last();

      const index =
        _(compRef)
          .thru(editorAPI.components.getContainer)
          .thru(editorAPI.components.getChildren)
          .findIndex(compRef) + 1;

      return `${translate(`component_label_${label}`)} ${index}`;
    },
  );

  editorAPI.registerCompNamePlugin(
    'mobile.core.components.Page',
    function (compRef) {
      if (editorAPI.dsRead.pages.popupPages.isPopup(compRef.id)) {
        return translate('component_label_Overlay');
      }
      return translate('component_label_Page');
    },
  );

  editorAPI.registerCompNamePlugin(
    'wysiwyg.viewer.components.SiteRegionContainer',
    function () {
      return translate('component_label_members_bar');
    },
  );

  editorAPI.registerRemovePlugin(
    'wysiwyg.viewer.components.Column',
    function (compPointer, allowRemoveMessage, originRemove) {
      return editorAPI.columns.removeColumn(
        compPointer,
        allowRemoveMessage,
        originRemove,
      );
    },
  );

  editorAPI.registerHidePlugin(
    'wysiwyg.viewer.components.QuickActionBar',
    function (compPointer: AnyFixMe) {
      editorAPI.mobile.mobileOnlyComponents.removeQuickActionBar(compPointer);
    },
  );

  editorAPI.registerHidePlugin(
    'wysiwyg.viewer.components.BackToTopButton',
    editorAPI.mobile.mobileOnlyComponents.removeBackToTopButton,
  );

  editorAPI.registerHidePlugin(
    'wysiwyg.viewer.components.MenuToggle',
    function (compRef: AnyFixMe) {
      editorAPI.store.dispatch(
        stateManagement.inlinePopup.actions.close(
          util.inlinePopupUtils.getToggleTarget(editorAPI, compRef),
        ),
      );
      editorAPI.dsActions.mobile.hiddenComponents.hide(compRef);
    },
  );

  editorAPI.registerDuplicatePlugin(
    'wysiwyg.viewer.components.Column',
    function (compPointer: CompRef[]) {
      const potentialParentPointer =
        editorAPI.components.getContainer(compPointer);

      if (
        !editorAPI.components.is.duplicatable(
          compPointer,
          potentialParentPointer,
        )
      ) {
        return [];
      }
      const newColumnAddResult = editorAPI.columns.duplicateColumn(
        _.head(compPointer),
        'duplicate',
      );

      editorAPI.dsActions.waitForChangesApplied(async () => {
        const newColumn = await waitForAddedCompRef(newColumnAddResult);

        editorAPI.selection.selectComponentByCompRef(newColumn);
        editorAPI.openFirstTimeOrDeprecationPanel(newColumn);
      });
    },
  );

  pluginService.registerPlugin(
    'setContainer',
    '*',
    onComponentContainerChanged,
  );

  pluginService.registerPlugin(
    'designDataChange',
    '*',
    repeaterItemDesignDataChangePlugin,
  );

  pluginService.registerPlugin(
    pluginService.pluginConstants.DOUBLE_CLICK,
    'wysiwyg.viewer.components.WPhoto',
    function (editorAPI: AnyFixMe, params: AnyFixMe) {
      const compRef = params.comp[0];
      if (!editorAPI.components.hasRuntimeDataOrConnected(compRef)) {
        editorAPI.imageCrop.toggleCropMode(true);
      }
    },
  );

  if (experiment.isOpen('se_editTextOnMobile')) {
    selectionChangePlugin.register(
      predicateForCreateUniqueTextForMobile,
      createUniqueTextForMobile.open,
      createUniqueTextForMobile.close,
    );
  } else {
    selectionChangePlugin.register(
      predicateForMoveToTeaser,
      moveToTeaser.open,
      moveToTeaser.close,
    );
  }

  selectionChangePlugin.register(
    'wysiwyg.viewer.components.mobile.TinyMenu',
    upgradeToMenuContainerTeaser.open,
    upgradeToMenuContainerTeaser.close,
  );

  selectionChangePlugin.register(() => true, notifyOnSelectionChange);

  if (util.snapToUtils.isNewStageGuidesEnabled()) {
    let marginsDisplayTimeout: NodeJS.Timeout = null;
    const marginsDisplayTime = 1000;

    selectionChangePlugin.register(
      () => true,
      (editorAPI) => {
        const selectedComponents = editorAPI.selection.getSelectedComponents();
        if (selectedComponents.length !== 1) return;

        const [selectedComp] = selectedComponents;
        const container =
          editorAPI.components.getContainerOrScopeOwner(selectedComp);
        const marginsIndicators = util.snapDataUtils.getMarginsSnapIndicators(
          editorAPI,
          selectedComp,
          container,
        );
        clearTimeout(marginsDisplayTimeout);
        editorAPI.snapData.set(marginsIndicators);
        marginsDisplayTimeout = setTimeout(
          () => editorAPI.snapData.clear(true),
          marginsDisplayTime,
        );
      },
    );
  }
}

export { registerComponentsPlugins as register };
