// @ts-nocheck
import siteButtonGfppData from './siteButtonGfppData';
import loginButtonGfppData from './loginButtonGfppData';
import backToTopButtonGfppData from './backToTopButtonGfppData';
import clipArtGfppData from './clipArtGfppData';

export default {
  SiteButton: siteButtonGfppData,
  LoginButton: loginButtonGfppData,
  BackToTopButton: backToTopButtonGfppData,
  ClipArt: clipArtGfppData,
};
