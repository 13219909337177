import React from 'react';
import { Heading } from '@wix/wix-base-ui';
import styles from './appDetailsHeader.scss';
import { AvailableAppActionsButton } from '../availableAppActions/availableAppActionsButton';
import { useSelectedApp } from '../selectedAppContext/selectedAppContext';
import { InstalledAppActionsButton } from '../installedAppActions/installedAppActionsButton';
import { InstalledAppIndicator } from '../InstalledAppIndicator/InstalledAppIndicator';
import { InstallAppButton } from '../installAppButton/installAppButton';
import { GreyLabel } from '../helperComponents/helperComponents';
import {
  InstallStatus,
  usePrivateApps,
  ListView,
} from '../privateAppsProvider/privateAppsProvider';
import { VersionedAppActionsButton } from '../versionedAppActions/versionedAppActionsButton';
import { useAppVersionsListContext } from '../AppVersionsList/AppVersionsListContext';
import * as util from '@/util';
import { translate } from '@/i18n';

const AppDetailsActions = () => {
  const { app } = useSelectedApp().selectedApp;
  const { selectedVersionListApp } = useAppVersionsListContext();

  switch (app?.installStatus) {
    case InstallStatus.Installed:
      return (
        <>
          <InstalledAppIndicator />
          {!selectedVersionListApp.app && <InstalledAppActionsButton />}
        </>
      );
    case InstallStatus.Available:
      return (
        <>
          <InstallAppButton />
          <AvailableAppActionsButton />
        </>
      );
    case InstallStatus.Versioned:
      return <VersionedAppActionsButton />;
    default:
      return null;
  }
};

export const AppDetailsHeader: React.FC = () => {
  const { selectedListView } = usePrivateApps();
  const { app, codePackage, selectedVersion } = useSelectedApp().selectedApp;

  const isVersionedView = selectedListView === ListView.AppVersions;

  const buildSelectedAppVersion = () =>
    app
      ? `${util.appStudioUtils.formatVersion(
          selectedVersion || app?.version || app?.latestVersion,
        )} ${
          app.version === app.latestVersion
            ? translate(
                'blocks-private-apps.AppDiscovery_App_Details_Latest_Version_Label',
              )
            : ''
        }`
      : '';

  return (
    <div className={styles.appDetailsHeader}>
      <div className={styles.leftContainer}>
        <Heading shouldTranslate={false} appearance="h1">
          {`${app?.appDefinitionName}${
            isVersionedView && selectedVersion
              ? ` ${util.appStudioUtils.formatVersion(selectedVersion)}`
              : ''
          }`}
        </Heading>
        <GreyLabel>{codePackage?.name}</GreyLabel>
      </div>
      <div className={styles.rightContainer}>
        <div className={styles.versionContainer}>
          <GreyLabel>{!isVersionedView && buildSelectedAppVersion()}</GreyLabel>
        </div>
        <div className={styles.actionsContainer}>
          <AppDetailsActions />
        </div>
      </div>
    </div>
  );
};
