const PanelCloseOrigin = {
  HEADER_CLOSE_BUTTON: 'header_close_button',
  CLICK_OUTSIDE: 'click_outside',
  OUT_OF_FOCUS: 'out_of_focus',
  ESCAPE_PRESS: 'escape_press',
  ENTER_PRESS: 'enter_press',
  DONE_BUTTON: 'done_button',
  CONFIRM_BUTTON: 'confirm_button',
  SECONDARY_ACTION: 'secondary_action',
} as const;

export type Origin = typeof PanelCloseOrigin[keyof typeof PanelCloseOrigin];
export default PanelCloseOrigin;
