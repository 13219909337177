import experiment from 'experiment';

function getSEOPanels() {
  return import('advancedSeoClient').then((advancedSeoClient) => {
    const tabs = experiment.isOpen('specs.promote-seo.ReorderSeoTabs')
      ? advancedSeoClient.NEW_ORDER_SEO_TABS
      : advancedSeoClient.SEO_TABS;
    return { tabs };
  });
}

export { getSEOPanels };
