import { ErrorReporter } from '@wix/editor-error-reporter';
import type { EditorAPI } from '@/editorAPI';

function handleEditorDataFixing(editorAPI: EditorAPI): void {
  handleInvalidFooterDataIfNeeded(editorAPI);
}

function handleInvalidFooterDataIfNeeded(editorAPI: EditorAPI): void {
  const footerRef = editorAPI.siteSegments.getFooter();
  const footerData = editorAPI.components.data.get(footerRef);
  if (footerData) {
    ErrorReporter.captureException(new Error('Invalid footer data'), {
      tags: {
        footerHasInvalidData: true,
      },
      extra: {
        footerData,
      },
    });
    editorAPI.components.data.remove([footerRef], true);
  }
}

export { handleEditorDataFixing };
