import type { EntryPoint, Shell } from '@/apilib';
import { EditorAPIKey, EditorCoreApiKey, WorkspaceModesApiKey } from '@/apis';
import { createToursAPI, ToursApiKey } from './api';
import type { EditorAPI } from '@/editorAPI';
import { startEditorNewUsersTour } from './editorNewUsersTour';
import { StartOrigin } from './tours/tours.types';
import {
  getShouldOpenAgainState,
  OpenAgainStates,
  setShouldOpenAgainState,
} from './utils/stageUtils';
import constants from '@/constants';
import { sections, tours } from '@/util';
import { WorkspaceModes } from '@/workspaceModes';
import * as stateManagement from '@/stateManagement';

export const EditorToursEntryPoint: EntryPoint = {
  name: 'editorTours',
  declareAPIs: () => [ToursApiKey],
  getDependencyAPIs: () => [
    EditorAPIKey,
    EditorCoreApiKey,
    WorkspaceModesApiKey,
  ],
  attach(shell) {
    shell.contributeAPI(ToursApiKey, () => createToursAPI(shell));
  },
  extend: async (shell: Shell) => {
    const editorCoreAPI = shell.getAPI(EditorCoreApiKey);
    const editorAPI = shell.getAPI(EditorAPIKey);

    await editorCoreAPI.hooks.initReady.promise;
    await isGlobalPreferencesFetched(editorAPI);

    const shouldShowTour = shouldEnableNewUsersTour(editorAPI);
    if (shouldShowTour) {
      setShouldOpenAgainState(
        editorAPI,
        constants.USER_PREFS.SECTIONS_MIGRATION.SHOW_EDITOR_NEW_USERS_TOUR,
      )(true);
    }

    await editorCoreAPI.hooks.stageIsReadyAndVisible.promise;
    await editorCoreAPI.hooks.appsSilentInstallationReady.promise;
    await editorCoreAPI.hooks.enterZoomModeAtStartup.promise;

    if (window.siteCreationController) {
      await window.siteCreationController.getIsEditorFullyLoadPromise();
      await window.siteCreationController.hooks.isSiteCreationUiVisible.promise;
    }

    if (shouldShowTour) {
      startEditorNewUsersTour(editorAPI, StartOrigin.AppInit);
    }
    editorCoreAPI.hooks.editorNewUsersTourInitializationFinished.resolve({
      hasTourStarted: !!shouldShowTour,
    });
  },
};

const isGlobalPreferencesFetched = (editorAPI: EditorAPI) =>
  new Promise(editorAPI.registerInitUserPrefsCallback);

export const shouldEnableNewUsersTour = (editorAPI: EditorAPI) => {
  const workspaceModesApi = editorAPI.host.getAPI(WorkspaceModesApiKey);
  const editorIsInFullMode = workspaceModesApi.isMode(WorkspaceModes.FULL);
  const { store } = editorAPI;

  const newSite = !editorAPI.dsRead.account.hasSites();

  const shouldShowTourAgain = getShouldOpenAgainState(
    editorAPI,
    constants.USER_PREFS.SECTIONS_MIGRATION.SHOW_EDITOR_NEW_USERS_TOUR,
  );

  const userNeverStartedTheTour =
    newSite && shouldShowTourAgain === OpenAgainStates.NEVER_OPENED;
  const isSchoolMode = stateManagement.schoolMode.selectors.isEnabled(
    store.getState(),
  );

  return (
    tours.isEditorNewUsersTourEnabled() &&
    editorIsInFullMode &&
    !isSchoolMode &&
    sections.isSectionsEnabled() &&
    (shouldShowTourAgain === OpenAgainStates.SHOULD_OPEN_AGAIN ||
      userNeverStartedTheTour)
  );
};

export { ToursApiKey };
