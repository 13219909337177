// @ts-nocheck
import _ from 'lodash';
import * as styles from '@/styles';
const { advancedStyleConstants, advancedStyleDataUtil } = styles.advancedStyle;

const { skinTypeOverrides, compType, skinName, ALPHA_PREFIX, SHADOW_PREFIX } =
  advancedStyleConstants.multiComponents;

const { getDefaultCategory, DEFAULT_SECTION } = advancedStyleDataUtil;

import utils, { PARAM_SEPERATOR } from '../../utils/multiComponentsUtils';

const getCompType = (editorAPI, comp) => editorAPI.components.getType(comp);

const getComponentsTypes = (editorAPI, components) => [
  ...new Set(components.map((component) => getCompType(editorAPI, component))),
];

const getSkin = (editorAPI, comp) => editorAPI.components.skin.get(comp);

const getSkinDef = (editorAPI, comp) =>
  editorAPI.theme.skins.getSkinDefinition(getSkin(editorAPI, comp));

const getCompRole = (editorAPI, comp) =>
  editorAPI.dsRead.platform.controllers.connections.getPrimaryConnection(comp)
    ?.role;

const getCompRoles = (editorAPI, components) => {
  const componentsRoled = components.map((comp) =>
    getCompRole(editorAPI, comp),
  );
  return _(componentsRoled).without(undefined).uniq().value();
};

const getCompStyles = (editorAPI, compsInTab) =>
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/reduce
  _.reduce(
    compsInTab,
    (styleByComp, comp) =>
      _.set(
        styleByComp,
        comp.id,
        getCompStyle(editorAPI, comp)?.style?.properties ?? {},
      ),
    {},
  );

const getCompStyleByTab = (editorAPI, tabs, components) =>
  tabs.reduce(
    (compStyles, tab, index) =>
      _.set(compStyles, index, getCompStyles(editorAPI, components[index])),
    {},
  );

const getSections = (tabs) =>
  tabs.reduce((sections, tab) => {
    tab.sections?.forEach((section) => {
      _.set(
        sections,
        Object.keys(sections || {}).length,
        _.pick(section, ['state', 'category', 'label', 'priority']),
      );
    });
    return sections;
  }, {});

const getSupportedTabs = (editorAPI, tabs, connectedComponents) =>
  Object.values(tabs).filter((tab) => {
    const componentsForTab = getComponentsForTab(
      editorAPI,
      connectedComponents,
      tab,
    );
    const isAllRolesExistInTab = tab.dependents
      ? allRolesExistInTab(editorAPI, tab.dependents, connectedComponents)
      : true;

    return !_.isEmpty(componentsForTab) && isAllRolesExistInTab;
  });

const getCompRoleOverrides = (supportedTabs) =>
  supportedTabs.reduce((overrides, tab) => {
    (tab.sections || []).forEach((section) => {
      (section.roles || []).forEach((role) => {
        overrides[role] = section.roles[0];
      });
    });
    return overrides;
  }, {});

const getCompRoleOverride = (editorAPI, compRoleOverrides, comp) => {
  const compRole = getCompRole(editorAPI, comp);
  return _.get(compRoleOverrides, compRole, compRole);
};

const allRolesExistInTab = (editorAPI, dependents, connectedComponents) => {
  const sortedDependents = [...dependents].sort();
  const compRoles = getCompRoles(editorAPI, connectedComponents);
  const commonRoles = _.intersection(compRoles, dependents).sort();

  return _.isEqual(commonRoles, sortedDependents);
};

const getComponentsForTab = (editorAPI, connectedComponents, tab) => {
  const { compTypes = [], roles = [] } = tab?.groups;

  return Object.values(connectedComponents).filter((comp) => {
    return (
      (compTypes.length > 0 &&
        compTypes.includes(getCompType(editorAPI, comp))) ||
      roles.includes(getCompRole(editorAPI, comp))
    );
  });
};

const getStyleParam = (editorAPI, comp, param, tab) => {
  const styleParamOverrides = _.get(
    tab.styleParamOverrides,
    getCompType(editorAPI, comp),
  );
  return (
    _.findKey(styleParamOverrides, (overrides) =>
      // eslint-disable-next-line you-dont-need-lodash-underscore/includes
      _.includes(overrides, param),
    ) || param
  );
};

const getSkinStyleParamOverrides = (skinDefPerTab, index, skin) =>
  skinDefPerTab[index].skinStyleParamOverrides[skin];

const getSkinDefForComp = (editorAPI, tab, comp) => {
  const customSkinDef =
    styles.advancedStyle.advancedStyleDataUtil.getSkinDataCustomizations()[
      getSkin(editorAPI, comp)
    ]?.params ?? {};

  // eslint-disable-next-line you-dont-need-lodash-underscore/reduce
  return _.reduce(
    _.merge(getSkinDef(editorAPI, comp), customSkinDef),
    (skinDefForComp, { state, type, section, category }, key) => {
      if (!state || !type) {
        return skinDefForComp;
      }

      const styleParam = getStyleParam(editorAPI, comp, key, tab);
      const styleTypeOverride = _.get(
        skinTypeOverrides,
        `${type}.${category || getDefaultCategory(type)}`,
      );

      const styleType =
        styleTypeOverride &&
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/find
        !_.find(skinDefForComp, {
          state,
          type: styleTypeOverride,
        })
          ? styleTypeOverride
          : type;
      const styleSection =
        section && type === styleType ? section : DEFAULT_SECTION;
      return {
        ...skinDefForComp,
        [styleParam]: {
          state,
          type: styleType,
          section: styleSection,
        },
      };
    },
    {},
  );
};

const skinDefByComp = (
  editorAPI,
  supportedTabs,
  components,
  skinDefPerTab,
  tab,
) =>
  components.reduce((skinDefByComp, comp) => {
    const skinDefForComp = getSkinDefForComp(editorAPI, tab, comp);
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/find-index
    const tabIndex = _.findIndex(supportedTabs, 'label');
    const skinStyleParamOverrides = getSkinStyleParamOverrides(
      skinDefPerTab,
      tabIndex,
      getSkin(editorAPI, comp),
    );
    if (!skinStyleParamOverrides) {
      return _.set(skinDefByComp, comp.id, skinDefForComp);
    }

    const skinDefWithOverrides = _.mapKeys(skinDefForComp, (d, param) => {
      return (
        _.findKey(skinStyleParamOverrides, (overrides) =>
          // eslint-disable-next-line you-dont-need-lodash-underscore/includes
          _.includes(overrides, param),
        ) || param
      );
    });

    return _.set(skinDefByComp, comp.id, skinDefWithOverrides);
  }, {});
const castBorderSizePropertiesIfNecessary = (commonSkinDef) => {
  return _.mapValues(commonSkinDef, (skinDef) => {
    if (skinDef.type === 'BORDER_SIZE') {
      return (
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/find
        _.find(commonSkinDef, {
          ...skinDef,
          type: 'BORDER_SIZES',
        }) || skinDef
      );
    }
    return skinDef;
  });
};

const getBaseSkinDefinitionAndMapStyleParams = (editorAPI, components, tab) => {
  const commonSkinDefForTab = components.reduce(
    (commonSkinDef, comp) =>
      Object.assign(commonSkinDef, getSkinDefForComp(editorAPI, tab, comp)),
    {},
  );

  const skinDefForTab =
    castBorderSizePropertiesIfNecessary(commonSkinDefForTab);
  const sectionedStyleParams = tab.sections?.reduce((params, section) => {
    if (section.styleParam) {
      params.push(section.styleParam);
    }

    if (section.subsections) {
      section.subsections.forEach((subsection) => {
        if (subsection.styleParam) {
          params.push(subsection.styleParam);
        }
      });
    }
    return params;
  }, []);

  const skins = [
    ...new Set(components.map((comp) => getSkin(editorAPI, comp))),
  ];

  const skinDefBySkin = skins.reduce((acc, skin) => {
    const skinParams = Object.keys(
      editorAPI.theme.skins.getSkinDefinition(skin) || {},
    );
    if (!_.isEmpty(skinParams)) {
      return { ...acc, [skin]: skinParams };
    }
    return acc;
  }, {});

  const paramOverrides = [];
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/reduce
  const { skinStyleParamOverrides } = _.reduce(
    skinDefForTab,
    (result, skinDef, key) => {
      const param = _.findKey(result.skinDef, skinDef);
      // eslint-disable-next-line you-dont-need-lodash-underscore/includes
      if (param && !_.includes(sectionedStyleParams, key)) {
        paramOverrides.push(param);
        const skinsWithOverrides = _.pickBy(skinDefBySkin, (params) =>
          // eslint-disable-next-line you-dont-need-lodash-underscore/includes
          _.includes(params, key),
        );
        // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
        _.forEach(skinsWithOverrides, (skinParams, skin) => {
          const currentOverridesForSkin = result.skinStyleParamOverrides[skin];
          if (currentOverridesForSkin) {
            // eslint-disable-next-line you-dont-need-lodash-underscore/includes
            const defaultValue = _.includes(skinParams, param) ? [param] : [];
            const overridenParams = _.get(
              currentOverridesForSkin,
              param,
              defaultValue,
            );
            overridenParams.push(key);
            _.set(result.skinStyleParamOverrides[skin], param, overridenParams);
            return result;
          }
          return Object.assign(result.skinStyleParamOverrides, {
            [skin]: {
              [param]: [key],
            },
          });
        });
        return result;
      }
      _.set(result.skinDef, key, skinDef);
      return result;
    },
    {
      skinDef: {},
      skinStyleParamOverrides: {},
    },
  );

  const skinStyleParamOverridesByComp = components.reduce((acc, comp) => {
    const skin = getSkin(editorAPI, comp);
    acc[comp.id] = skinStyleParamOverrides[skin] || {};
    return acc;
  }, {});

  const editableParams = _.union(sectionedStyleParams, paramOverrides);
  const allSkinParams = Object.values(skinDefBySkin);
  const skinIntersection = _.intersection(...allSkinParams);
  const validParams = skinIntersection.concat(editableParams);
  return {
    baseSkinDefForTab: _.pickBy(commonSkinDefForTab, (d, key) =>
      validParams.includes(key),
    ),
    skinStyleParamOverrides,
    skinStyleParamOverridesByComp,
  };
};

const getSkinDefForTab = (editorAPI, components, tab, sections) => {
  const {
    baseSkinDefForTab,
    skinStyleParamOverrides,
    skinStyleParamOverridesByComp,
  } = getBaseSkinDefinitionAndMapStyleParams(editorAPI, components, tab);
  if (_.isEmpty(tab.sections)) {
    return {
      skinStyleParamOverrides,
      skinStyleParamOverridesByComp,
      skinDefinition: {
        compParts: [],
        params: baseSkinDefForTab,
        sections: [],
      },
    };
  }

  const getSectionedParams = ({ state, type }, sectionIndex, label, priority) =>
    _.omitBy(
      {
        state,
        type,
        section: sectionIndex,
        shouldTranslate: false,
        label: label || sections[sectionIndex]?.label,
        category: sections[sectionIndex]?.category,
        priority,
      },
      _.isNil,
    );

  const supportedRolesForTab = tab.sections.reduce((roles, section) => {
    if (!_.isEmpty(section.roles)) {
      roles.push(_.head(section.roles));
    }
    return roles;
  }, []);

  const params = tab.sections.reduce((sectionedSkinDef, section) => {
    const sectionedAccordingToRole = !_.isEmpty(supportedRolesForTab);
    const sectionedAccordingToStyleParam = !_.isEmpty(section.styleParam);
    const getSectionIndex = (label = section.label) =>
      _.findKey(sections, ['label', label]);
    const setSectionParamAtPath = ({ param, label, priority }) =>
      _.set(
        baseSkinDefForTab,
        param,
        getSectionedParams(
          baseSkinDefForTab[param] || {},
          getSectionIndex(),
          label,
          priority,
        ),
      );

    if (sectionedAccordingToRole) {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/reduce
      const keysToOverride = _.reduce(
        baseSkinDefForTab,
        (overrides, def, key) => {
          // eslint-disable-next-line you-dont-need-lodash-underscore/includes
          if (!section.states || _.includes(section.states, def.state)) {
            overrides.push(key);
          }
          return overrides;
        },
        [],
      );

      return keysToOverride.reduce((skinDef, param) => {
        supportedRolesForTab?.forEach((role) => {
          const roleSectionIndex = tab.sections.findIndex((section) =>
            section.roles.includes(role),
          );
          const roleLabel = tab.sections[roleSectionIndex]?.label;
          const { state, type } = baseSkinDefForTab[param];
          _.set(skinDef, `${param}${PARAM_SEPERATOR}${role}`, {
            state,
            type,
            section: getSectionIndex(roleLabel),
          });
        });
        return skinDef;
      }, {});
    }

    if (sectionedAccordingToStyleParam) {
      const validParams = Object.keys(baseSkinDefForTab || {});
      if (validParams.includes(section.styleParam)) {
        setSectionParamAtPath({
          param: section.styleParam,
        });
      }

      if (section.subsections) {
        section.subsections.forEach(({ label, styleParam, priority }) => {
          if (validParams.includes(styleParam)) {
            setSectionParamAtPath({
              param: styleParam,
              label,
              priority,
            });
          }
        });
      }

      return Object.assign(sectionedSkinDef, baseSkinDefForTab);
    }
    return sectionedSkinDef;
  }, {});

  return {
    skinStyleParamOverrides,
    skinStyleParamOverridesByComp,
    skinDefinition: {
      compParts: [],
      params,
      sections,
    },
  };
};

const getDataPerTab = (
  editorAPI,
  supportedTabs,
  connectedComponents,
  sections,
) =>
  supportedTabs.reduce(
    ({ components, skinDefPerTab, compSkinsPerTab }, tab, index) => {
      const component = getComponentsForTab(
        editorAPI,
        connectedComponents,
        tab,
      );
      components[index] = component;
      const skinDef = getSkinDefForTab(editorAPI, component, tab, sections);
      skinDefPerTab[index] = skinDef;
      const compSkins = skinDefByComp(
        editorAPI,
        supportedTabs,
        component,
        skinDefPerTab,
        tab,
      );
      return {
        components: {
          ...components,
        },
        skinDefPerTab: {
          ...skinDefPerTab,
        },
        compSkinsPerTab: {
          ...compSkinsPerTab,
          [index]: compSkins,
        },
      };
    },
    {
      components: {},
      skinDefPerTab: {},
      compSkinsPerTab: {},
    },
  );

const initialize = (editorAPI, tabs, connectedComponents) => {
  const sections = getSections(tabs);
  const supportedTabs = getSupportedTabs(editorAPI, tabs, connectedComponents);
  const compRoleOverrides = getCompRoleOverrides(supportedTabs);
  const dataPerTab = getDataPerTab(
    editorAPI,
    supportedTabs,
    connectedComponents,
    sections,
  );

  return {
    supportedTabs,
    compRoleOverrides,
    ...dataPerTab,
  };
};

const getCompStyle = (editorAPI, comp) => editorAPI.components.style.get(comp);

const calculateCommonStyle = (
  params,
  paramKeys,
  styleByComp,
  skinDefByComp,
  compIdsByRole = {},
  compsStylesOverrides = {},
) => {
  const getCommonGroupValue = (paramKey, commonStyle, prefix = '') => {
    const paramsValuesFrequency = utils.getFrequencyOfParamValue(
      paramKey,
      prefix,
      styleByComp,
      skinDefByComp,
      compIdsByRole,
      compsStylesOverrides,
    );

    if (!_.isEmpty(paramsValuesFrequency)) {
      commonStyle[`${prefix}${paramKey}`] = utils.castParamToNumberIfNeeded(
        params[paramKey],
        utils.maxInFrequency(paramsValuesFrequency),
      );
    }
    return commonStyle;
  };

  return paramKeys.reduce((commonStyle, paramKey) => {
    commonStyle = getCommonGroupValue(paramKey, commonStyle);
    if (utils.groupHasAlpha(params[paramKey])) {
      commonStyle = _.merge(
        commonStyle,
        getCommonGroupValue(paramKey, commonStyle, ALPHA_PREFIX),
      );
    }
    if (utils.groupHasShadow(params[paramKey])) {
      commonStyle = _.merge(
        commonStyle,
        getCommonGroupValue(paramKey, commonStyle, SHADOW_PREFIX),
      );
    }
    return commonStyle;
  }, {});
};

const extractCommonConfiguration = (configurationsList) => {
  if (configurationsList.length == 0) {
    return undefined;
  }

  if (!_.isObject(configurationsList[0])) {
    return configurationsList.every((value) => value === configurationsList[0])
      ? configurationsList[0]
      : undefined;
  }

  const commonKeys = configurationsList.reduce(
    (lastResult, currentObject) =>
      lastResult
        ? Object.keys(currentObject).filter((key) => lastResult.includes(key))
        : Object.keys(currentObject),
    undefined,
  );

  const takenCommon = commonKeys.reduce((commonConfiguration, key) => {
    const deepCommon = extractCommonConfiguration(
      configurationsList.map((obj) => obj[key]),
    );
    if (deepCommon) {
      commonConfiguration[key] = deepCommon;
    }
    return commonConfiguration;
  }, {});

  return _.isEmpty(takenCommon) ? undefined : takenCommon;
};

export {
  initialize,
  getCompStyle,
  getCompStyleByTab,
  getCompType,
  getComponentsTypes,
  getCompRole,
  getSkin,
  getSkinStyleParamOverrides,
  getCompRoleOverride,
  compType,
  skinName,
  SHADOW_PREFIX,
  calculateCommonStyle,
  extractCommonConfiguration,
  getSupportedTabs,
};
