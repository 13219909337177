import constants from '@/constants';
import repeaterGfppUtils from '../../utils/repeaterGfppUtils';
import * as helpIds from '@/helpIds';
import type { GFPPDataRaw } from '@wix/editor-component-types';
import type { CompRef } from 'types/documentServices';
import type { EditorAPI } from '@/editorAPI';

const { ACTIONS } = constants.ROOT_COMPS.GFPP;

const gfppData: GFPPDataRaw = {
  untranslatable: true,
  mainActions(editorAPI: EditorAPI, compRef: CompRef) {
    function isSelectableRepeater(compRef: CompRef): boolean {
      const [firstChild] =
        editorAPI?.documentServices?.components?.getChildren?.(compRef) || [];

      if (!firstChild) {
        return false;
      }

      const childType = editorAPI.components.getType(firstChild);

      return childType === constants.COMP_TYPES.SELECTABLE_CONTAINER;
    }

    if (isSelectableRepeater(Array.isArray(compRef) ? compRef[0] : compRef)) {
      return [];
    }

    return [repeaterGfppUtils.getManageItemAction()];
  },
  enabledActions: [
    ACTIONS.LAYOUT,
    ACTIONS.ANIMATION,
    ACTIONS.STRETCH,
    ACTIONS.HELP,
  ],
  mobileEnabledActions: [ACTIONS.LAYOUT, ACTIONS.HIDE, ACTIONS.HELP],
  secondaryLanguageMainActions: [],
  presetActions: {
    help: {
      helpId: helpIds.GFPP.REPEATER,
      mobileHelpId: helpIds.GFPP.REPEATER_MOBILE,
    },
  },
};

export default gfppData;
