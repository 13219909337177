:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._manageContent_jhksm_7 {
  height: 100%; }
  ._manageContent_jhksm_7 ._sectionList_jhksm_9 {
    height: calc(100% - 84px); }
  ._manageContent_jhksm_7 ._sectionHeader_jhksm_11 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 24px 0 24px;
    padding: 17px 0 7px 0; }
  ._manageContent_jhksm_7 ._sectionSubheader_jhksm_17 {
    margin: 0 24px 10px 24px;
    padding: 5px 0 7px 0; }
  ._manageContent_jhksm_7 ._sectionDivider_jhksm_20 {
    margin: 0 24px 0 24px;
    border-bottom: 1px solid #dfe5eb; }
  ._manageContent_jhksm_7 ._sectionCollections_jhksm_23 {
    margin: 0 24px; }
  ._manageContent_jhksm_7 ._buttonContainer_jhksm_25 {
    height: 84px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid #dfe5eb;
    padding: 0 10px; }
  ._manageContent_jhksm_7 ._group-context-menu-symbol_jhksm_32 path {
    fill: #868aa5; }
  ._manageContent_jhksm_7 ._group-context-menu-symbol_jhksm_32:hover path {
    fill: #116dff; }
  ._manageContent_jhksm_7 ._videoSettingsContainer_jhksm_36 {
    display: flex;
    justify-content: flex-end;
    align-items: center; }
  ._manageContent_jhksm_7 ._videoTooltip_jhksm_40 {
    margin: 1px 16px 0 0; }
  ._manageContent_jhksm_7 ._contextMenuButton_jhksm_42 {
    display: flex;
    align-items: center;
    justify-content: center; }

._contextActionIcon_jhksm_47 {
  margin-right: 5px; }
