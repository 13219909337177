import { createThemedAction } from '../utils/themedAction';
import type { ThemedActionCreationData } from '../utils/textAction';

export const FontStyle = {
  Italic: 'italic',
  Normal: 'normal',
} as const;

export type FontStyle = typeof FontStyle[keyof typeof FontStyle];

const italicActionCreationData: Readonly<ThemedActionCreationData<FontStyle>> =
  {
    execCommand: (textManager, actionInput) => {
      switch (actionInput) {
        case FontStyle.Italic:
          textManager.commands.italic();
          break;
        case FontStyle.Normal:
          textManager.commands.unItalic();
          break;
      }
    },
    getWixRichTextCommandState: (textManager) => {
      if (textManager.getCommandsStates().italic) {
        return FontStyle.Italic;
      }
      if (textManager.getCommandsStates().unItalic) {
        return FontStyle.Normal;
      }

      return;
    },
    execRevertCommand: (textManager) => {
      if (textManager.getCommandsStates().unItalic) {
        textManager.commands.unItalic();
      } else {
        // We do it twice to remove Italic from the middle of the text
        // first unItalic makes all text italic second removes all italic
        textManager.commands.unItalic();
        textManager.commands.unItalic();
      }
    },
    createUpdatedStyleProperties: (actionInput) => ({
      fontStyle: actionInput,
    }),
    getPropertyValue: (styleProperties) => styleProperties.fontStyle,
    getThemeValue: (theme) =>
      theme?.italic ? FontStyle.Italic : FontStyle.Normal,
  };

export const italicAction = createThemedAction(italicActionCreationData);
