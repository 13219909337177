import constants from '@/constants';
import * as coreBi from '@/coreBi';
import {
  manageMenuGfppAutomationId,
  openManageMenu,
} from '../../utils/menuUtils';
//eslint-disable-next-line @wix/santa-editor/scoped-imports
import pageCategories from '@/pages/utils/pageCategories'; // is circular import
import type { CompRef } from 'types/documentServices';
import type { EditorAPI } from '@/editorAPI';
import type { GFPPActionRaw } from '@wix/editor-component-types';
import { isCustomOrAdvancedMenuEnabled, isCustomMenusEnabled } from '@/util';
import gfppDataUtils from '../../utils/gfppDataUtils';

const { ACTIONS } = constants.ROOT_COMPS.GFPP;

function openPagesPanel(
  editorAPI: EditorAPI,
  compRef: CompRef[],
  origin: unknown,
): void {
  const menuData = editorAPI.components.data.get(compRef) || {
    menuRef: pageCategories.MAIN_MENU.id,
  };
  const menuId = menuData.menuRef.id ? menuData.menuRef.id : menuData.menuRef;
  editorAPI.openPagesPanel({
    menuId: menuId.replace('#', ''),
    origin: origin || 'gfpp_hmenu',
  });
  editorAPI.bi.event(coreBi.events.pages.click_on_pages_panel_open, {
    origin: origin || 'gfpp_hmenu',
  });
}

const manageMenu: GFPPActionRaw = {
  label: 'gfpp_mainaction_Hmenu',
  isApplied: true,
  isSelected: (editorAPI) =>
    gfppDataUtils.isPanelOpen(
      editorAPI,
      isCustomOrAdvancedMenuEnabled()
        ? 'menu.panels.MenuManagePanel'
        : constants.ROOT_COMPS.LEFTBAR.MENUS_AND_PAGES_PANEL_NAME,
    ),
  onClick: (editorAPI: EditorAPI, compRef: CompRef[], origin) =>
    isCustomMenusEnabled() || editorAPI?.temporaryAdvancedMenuAPI?.isAdvanced()
      ? openManageMenu(editorAPI, { selectedComponents: compRef, origin })
      : openPagesPanel(editorAPI, compRef, { origin }),
  // TODO when merging experiment
  // editorX has a different datahook for this component somehow. Some tests might fail when remove experiment check on automationId
  // need to understand how they get their datahook and find a workaround
  // https://github.com/wix-private/responsive-editor-packages/blob/cd3376d33648ba1e4b738e65b185f42143a25431/apps/bundle/sled/drivers/gfpp/gfpp.driver.ts#L108
  automationId: () =>
    isCustomOrAdvancedMenuEnabled() ? manageMenuGfppAutomationId : undefined,
};

export default {
  mainActions: [
    manageMenu,
    {
      label: 'gfpp_secondaryaction_Hmenu',
      isApplied: true,
      isSelected: false,
      onClick(editorAPI: EditorAPI, compRef: CompRef, origin: string) {
        editorAPI.openQuickNavigation(editorAPI, origin || 'gfpp_hmenu');
      },
    },
  ],
  enabledActions: [
    ACTIONS.DESIGN,
    ACTIONS.LAYOUT,
    ACTIONS.ANIMATION,
    ACTIONS.HELP,
  ],
  mobileEnabledActions: [ACTIONS.HIDE, ACTIONS.HELP, ACTIONS.ANIMATION],
  presetActions: {
    help: {
      helpId: () =>
        isCustomOrAdvancedMenuEnabled()
          ? '4136a3fa-caa3-4f0f-9ace-8d08c4ecd893'
          : '9b4be2e9-6ec4-4e8c-96b9-31ccad6f5960',
    },
  },
};
