import * as coreBi from '@/coreBi';
import { bi } from '@/stateManagement';
import * as textControls from '@/textControls';

import type { EditorAPI } from '@/editorAPI';
import type { Dispatch, ThunkAction } from 'types/redux';
import type { TextManager, CmdState } from 'types/data';

export interface WithCKEditorOwnProps {
  textManager?: TextManager;
  cmdState: CmdState;
  isEditingRepeatedTextComp: boolean;
  panelName: string;
  defaultBiParams: Record<string, unknown>;
}

const getEditorAPI: ThunkAction<EditorAPI> = (
  dispatch,
  getState,
  { editorAPI },
) => editorAPI;

export const mapDispatchToProps = (
  dispatch: Dispatch,
  ownProps: WithCKEditorOwnProps,
) => {
  const execCommand = (
    commandName: string,
    params?: AnyFixMe,
    cmdOptions?: AnyFixMe,
    isMouseOut?: boolean,
  ) => {
    const editorAPI: EditorAPI = dispatch(getEditorAPI);
    textControls.textEditingUtils.execCommand(
      editorAPI,
      commandName,
      params,
      cmdOptions,
      isMouseOut,
      ownProps.isEditingRepeatedTextComp,
    );
  };

  const execCommandWithoutFocus = (cmdName: AnyFixMe, value: AnyFixMe) => {
    execCommand(cmdName, value, { execWithoutFocus: true });
  };

  const execListCommand = (newListType: any, cmdState: CmdState) => {
    const editorAPI: EditorAPI = dispatch(getEditorAPI);
    textControls.textEditingUtils.execListCommand(
      editorAPI,
      newListType,
      execCommand,
      cmdState,
    );
  };

  const focusCkEditor = () => {
    ownProps.textManager.focus();
  };

  const getFormatBlockCss = () =>
    ownProps.cmdState.formatBlock?.class || 'font_8';

  const sendTextBiEvent = (
    biId: keyof typeof coreBi.events.textControls,
    fields: AnyFixMe,
  ) => {
    dispatch(bi.actions.event(coreBi.events.textControls[biId], fields));
  };

  const sendTextBiEventWithPanelName = (biId: AnyFixMe, fields?: AnyFixMe) => {
    sendTextBiEvent(biId, { panel_name: ownProps.panelName, ...fields });
  };

  const sendTextBiEventWithDefaultParams = (
    biId: AnyFixMe,
    fields?: AnyFixMe,
  ) => {
    sendTextBiEvent(biId, { ...ownProps.defaultBiParams, ...fields });
  };

  return {
    execCommand,
    execCommandWithoutFocus,
    execListCommand,
    focusCkEditor,
    getFormatBlockCss,
    refreshPropsCollector: () => ownProps.textManager.refreshPropsCollector(),

    // bi
    sendTextBiEvent,
    sendTextBiEventWithPanelName,
    sendTextBiEventWithDefaultParams,
  };
};

export type WithCKEditorConnectProps = ReturnType<typeof mapDispatchToProps>;
