// @ts-nocheck
import createReactClass from 'create-react-class';
import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from '@wix/wix-base-ui';
import * as core from '@/core';
import * as higherOrderComponents from '@/higherOrderComponents';
import * as stateManagement from '@/stateManagement';
import constants from '@/constants';
import { frames } from '@/panels';
import * as baseUI from '@/baseUI';
import { translate } from '@/i18n';
import { THEME_MANAGER } from '@/helpIds';
import experiment from 'experiment';

const isLetterSpacingExperimentOpen = experiment.isOpen(
  'specs.thunderbolt.saveLetterSpacingToTextTheme',
);

const helpId = isLetterSpacingExperimentOpen
  ? THEME_MANAGER.SAVE_THEME_MODAL_TEXT_THEME
  : THEME_MANAGER.SAVE_THEME_MODAL;

// eslint-disable-next-line react/prefer-es6-class
const OldSaveTextStyleConfirmation = createReactClass({
  displayName: 'saveTextStyleConfirmation',
  propTypes: {
    panelName: PropTypes.string.isRequired,
    dontShowAgain: PropTypes.bool,
    toggleDontShowAgain: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    textStyleName: PropTypes.string.isRequired,
  },
  mixins: [core.mixins.editorAPIMixin],
  handleOnSave() {
    this.props.onConfirm();
    this.close();
  },
  close() {
    const editorAPI = this.getEditorAPI();
    editorAPI.panelManager.closePanelByName(this.props.panelName);
  },
  openLearnMore() {
    const editorAPI = this.getEditorAPI();
    const biHelpParams = {
      panel_name: this.props.panelName,
      origin: constants.BI.HELP.ORIGIN.PANEL,
      component: editorAPI.selection.getSelectedComponentType(),
      learn_more: true,
    };
    editorAPI.panelManager.openHelpCenter(helpId, null, biHelpParams);
  },
  render() {
    return (
      <frames.FocusPanelFrame
        ref="frame"
        key="saveTextConfirmationPanel"
        helpId={helpId}
        title={translate('text_editor_save_theme_dialog_header')}
        panelName={this.props.panelName}
        className="text-style-save-confirm-panel"
      >
        <div className="text-style-save-confirm-content">
          <div key="save-your-theme-clipart" className="image-container">
            <baseUI.symbol name="save-your-theme-clipart" />
          </div>
          <div className="description-container">
            <span className="description-text">
              {translate('text_editor_save_theme_dialog_text_1')}
            </span>
            <span className="description-text bold">
              {' '}
              '{this.props.textStyleName}'{' '}
            </span>
            <span className="description-text">
              {translate('text_editor_save_theme_dialog_text_2')}
            </span>

            <div className="learn-more-link-container">
              <a onClick={this.openLearnMore} className="learn-more-link">
                {translate('text_editor_save_theme_dialog_learn_more')}
              </a>
            </div>
          </div>
          <div className="footer-buttons">
            <Checkbox
              label="text_editor_save_theme_dialog_dont_show"
              value={this.props.dontShowAgain}
              onChange={this.props.toggleDontShowAgain}
              labelAfterSymbol={true}
            />
            <baseUI.button
              onClick={this.handleOnSave}
              label="text_editor_save_theme_confirm_button"
              className="save-button btn-md"
            />
          </div>
        </div>
      </frames.FocusPanelFrame>
    );
  },
});

const defaultProps = {
  userPrefType: 'session',
};

const LinkedSaveTextStyleConfirmation =
  stateManagement.bi.hoc.reportBiEventOnUiChange(
    higherOrderComponents.linkUserPreferences(defaultProps)(
      OldSaveTextStyleConfirmation,
    ),
  );
LinkedSaveTextStyleConfirmation.pure = OldSaveTextStyleConfirmation;

export default LinkedSaveTextStyleConfirmation;
