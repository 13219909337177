import _ from 'lodash';
import { EditorAPIKey, EditorRestrictionsApiKey } from '@/apis';
import coreUtilsLib from 'coreUtilsLib';
import * as util from '@/util';
import * as baseUI from '@/baseUI';
import * as mobileEditor from '@/mobileEditor';
import constants from '@/constants';
import * as stateManagement from '@/stateManagement';
import * as coreBi from '@/coreBi';
import { biLogger } from '@/util';
import {
  hiddenElementsHide,
  componentAddedToStage,
} from '@wix/bi-logger-editor/v2';
import * as helpIds from '@/helpIds';
import type { CompRef, SerializedCompStructure } from 'types/documentServices';
import { isResponsiveEditor } from '@wix/santa-editor-utils';
import type { Shell } from '@/apilib';
import type { HiddenItemData } from '@/mobileEditor';
import type { CompStructure } from '@wix/document-services-types';
import { waitForAddedCompRef } from '@/componentsAddUtils';

const { tooltipManager } = baseUI;

const SCROLL_MARGIN = 50;

const FADE_IN = {
  action: '',
  duration: 0.3,
  name: 'FadeIn',
};
const { showUserActionNotification } = stateManagement.notifications.actions;
//TODO: fix mobile reducers

const USER_WAS_IN_MOBILE_EDITOR_KEY =
  constants.USER_PREFS.MOBILE_EDITOR.USER_WAS_IN_MOBILE_EDITOR;
const USER_WAS_IN_MOBILE_WIZARD_KEY =
  constants.USER_PREFS.MOBILE_EDITOR.USER_WAS_IN_MOBILE_WIZARD_KEY;
const MOBILE_WIZARD_DONT_SHOW_AGAIN_KEY =
  constants.USER_PREFS.MOBILE_EDITOR.MOBILE_WIZARD.DONT_SHOW_AGAIN;
const { MOBILE_DONT_SHOW_AGAIN } = constants.USER_PREFS.PUBLISH;
const FIRST_TIME_PANEL_DONT_SHOW_AGAIN_KEY =
  constants.USER_PREFS.MOBILE_EDITOR.FIRST_TIME_PANEL.DONT_SHOW_AGAIN;

export function createMobileEditorApi(shell: Shell) {
  const editorAPI = shell.getAPI(EditorAPIKey);
  let tooltipTimeout: AnyFixMe = null;
  const { store } = editorAPI;
  const { dispatch } = store;

  const getSiteUserPreferences = (key: string) =>
    stateManagement.userPreferences.selectors.getSiteUserPreferences(key)(
      store.getState(),
    );
  const setSiteUserPreferences = (key: string, value: AnyFixMe) =>
    dispatch(
      stateManagement.userPreferences.actions.setSiteUserPreferences(
        key,
        value,
      ),
    );
  const getGlobalUserPreferences = (key: string) =>
    stateManagement.userPreferences.selectors.getGlobalUserPreferences(key)(
      store.getState(),
    );
  const setGlobalUserPreferences = (key: string, value: AnyFixMe) =>
    dispatch(
      stateManagement.userPreferences.actions.setGlobalUserPreferences(
        key,
        value,
      ),
    );

  const isAllowedByQA = (qaParameterName: AnyFixMe) => {
    const isQA = util.url.isQA();
    const canShowForQA =
      util.url.getParameterByName(qaParameterName) === 'true';
    return canShowForQA || !isQA;
  };

  function hasUserSeenMobileEditor() {
    const seenPref = getSiteUserPreferences(USER_WAS_IN_MOBILE_EDITOR_KEY);
    return Boolean(seenPref);
  }

  function setReAddedHiddenElement(compRef: AnyFixMe, addingPhase: AnyFixMe) {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/assign
    const mobileState = _.assign({}, store.getState().mobile, {
      reAddedHiddenElement: {
        compRef,
        addingPhase,
      },
    });
    editorAPI.updateState({ mobile: mobileState });
  }

  function shouldShowHiddenItemsCounter() {
    checkInteractionsHiddenItems();
    return store.getState().mobile.isHiddenItemsCounterVisible;
  }

  function hideHiddenItemsCounter() {
    if (shouldShowHiddenItemsCounter()) {
      editorAPI.updateState(
        {
          mobile: _.defaults(
            { isHiddenItemsCounterVisible: false },
            store.getState().mobile,
          ),
        },
        function () {
          dispatch(
            stateManagement.leftBar.actions.setNotificationsCount(
              'mobileEditor.hiddenItemsPanel',
              0,
            ),
          );
        },
      );
    }
  }

  function updateLeftBarNotificationsCount() {
    const focusedPageId = editorAPI.dsRead.pages.getFocusedPageId();
    if (focusedPageId) {
      const hiddenItemsCount = shouldShowHiddenItemsCounter()
        ? mobileEditor.hiddenItemsUtils.getFocusedPageHiddenComps(
            editorAPI,
            editorAPI.dsRead.pages.getFocusedPageId(),
          ).length + getInteractionHiddenElementsCount()
        : 0;
      dispatch(
        stateManagement.leftBar.actions.setNotificationsCount(
          'mobileEditor.hiddenItemsPanel',
          hiddenItemsCount,
        ),
      );
    }
  }

  function getInteractionHiddenElementsCount() {
    return mobileEditor.hiddenItemsUtils.getHiddenInteractionsItems(editorAPI)
      .length;
  }

  function checkInteractionsHiddenItems() {
    const prevInteractionsHiddenElementsIds = new Set(
      mobileEditor.hiddenItemsUtils
        .getPreviousHiddenInteractionsItems(editorAPI)
        .map((elem: HiddenItemData) => elem.compId),
    );
    const currInteractionsHiddenElements =
      mobileEditor.hiddenItemsUtils.getHiddenInteractionsItems(editorAPI);
    const hasNewElements = currInteractionsHiddenElements.some(
      (elem: HiddenItemData) =>
        !prevInteractionsHiddenElementsIds.has(elem.compId),
    );
    if (hasNewElements) {
      editorAPI.updateState({
        mobile: _.defaultsDeep(
          {
            isHiddenItemsCounterVisible: true,
            hiddenItems: {
              interactionsHiddenComponents: currInteractionsHiddenElements,
            },
          },
          store.getState().mobile,
        ),
      });
    }
  }

  function getReAddedHiddenElement() {
    return store.getState().mobile.reAddedHiddenElement;
  }

  function clearReaddedElementTooltip() {
    setReAddedHiddenElement(
      null,
      constants.LEFT_BAR.MOBILE_HIDDEN_ELEMENTS.PROGRESS.IDLE,
    );
  }

  function selectComponentByCompRef(compRef: AnyFixMe) {
    editorAPI.selection.selectComponentByCompRef(compRef);
    editorAPI.dsActions.documentMode.setComponentVisibility(compRef.id, true);
    setReAddedHiddenElement(
      compRef,
      constants.LEFT_BAR.MOBILE_HIDDEN_ELEMENTS.PROGRESS.FINISH,
    );
    window.clearTimeout(tooltipTimeout);
    tooltipTimeout = window.setTimeout(
      clearReaddedElementTooltip,
      constants.LEFT_BAR.MOBILE_HIDDEN_ELEMENTS.TOOLTIP_TIMEOUT,
    );
  }

  function fadeInComp(compRef: AnyFixMe) {
    const fadeInAnimation = _.defaults({ targetId: compRef.id }, FADE_IN);
    editorAPI.components.behaviors.previewAnimation(
      compRef,
      fadeInAnimation,
      () => selectComponentByCompRef(compRef),
    );
  }

  function scrollToComp(compId: AnyFixMe) {
    const compRef = editorAPI.components.get.byId(compId);
    setReAddedHiddenElement(
      compRef,
      constants.LEFT_BAR.MOBILE_HIDDEN_ELEMENTS.PROGRESS.IN_PROGRESS,
    );
    const compLayout = editorAPI.components.layout.getRelativeToScreen(compRef);
    const targetY = compLayout.y - SCROLL_MARGIN;
    const scrollDuration = coreUtilsLib.scrollUtils.calcScrollDuration(
      editorAPI.site.getScroll().y,
      targetY,
      editorAPI.isMobileEditor(),
    );
    editorAPI.scroll.animateScrollTo({ y: targetY }, scrollDuration, () =>
      fadeInComp(compRef),
    );
  }

  function enableMobileBackToTopButton(value: AnyFixMe) {
    editorAPI.dsActions.mobile.mobileOnlyComponents.enableBackToTopButton(
      value,
    );
  }

  function getHiddenMobileComponents(pageId: AnyFixMe) {
    return editorAPI.dsRead.mobile.hiddenComponents.get(pageId);
  }

  function showHiddenMobileComponent(compId: AnyFixMe, pageId: AnyFixMe) {
    editorAPI.dsActions.documentMode.setComponentVisibility(compId, false);
    editorAPI.dsActions.mobile.hiddenComponents.show(compId, pageId);
    editorAPI.dsActions.waitForChangesApplied(() => scrollToComp(compId));
  }

  function optimizeLayout() {
    const currPageId = editorAPI.dsRead.pages.getFocusedPageId();
    editorAPI.mobile.reLayoutPage(currPageId);
    editorAPI.history.add('relayout');
    editorAPI.bi.event(
      coreBi.events.mobileEditor.hiddenElements
        .HIDDEN_ELEMENTS_REFRESH_LAYOUT_CLICK,
      { current_page_name: currPageId },
    );

    if (isAllowedByQA('qa_magicLayoutTooltip')) {
      showUndoTooltip();
    }
  }

  function showUndoTooltip() {
    const undoPanelKey =
      constants.ROOT_COMPS.TOPBAR.DROP_PANELS.CUSTOM
        .MOBILE_OPTIMIZE_LAYOUT_UNDO;

    editorAPI.topBarMenuBar.openDropDown(undoPanelKey);
    window.setTimeout(
      editorAPI.topBarMenuBar.closeDropDown.bind(editorAPI, undoPanelKey),
      5000,
    );
  }

  /**
   * Function returns whether the component lives in mobile only, but not native mobile comp (like QAB, tinyMenu etc..)
   * @param compRef - CompRef
   */
  function isMobileOnlyNonNativeComponent(compRef: CompRef): boolean {
    if (
      !editorAPI.dsRead?.mobile?.mobileOnlyComponents?.isMobileOnlyComponent
    ) {
      return false;
    }
    return (
      Boolean(compRef) &&
      editorAPI.dsRead?.mobile.mobileOnlyComponents.isMobileOnlyComponent(
        compRef.id,
      ) &&
      !editorAPI.dsRead?.mobile.mobileOnlyComponents.isNativeMobileOnlyComponent(
        compRef.id,
      )
    );
  }

  function areAnyMobileOnlyNonNativeComponent(compRefs: CompRef[]): boolean {
    return (
      editorAPI.isMobileEditor() &&
      compRefs.some(isMobileOnlyNonNativeComponent)
    );
  }

  function removeQuickActionBar() {
    editorAPI.dsActions.mobile.qab.clearActions();
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/some
    const mobileToolsPanelIsOpen = _.some(
      editorAPI.panelManager.getPanelsOfType('leftPanelFrame'),
      { name: 'mobileEditor.mobileElementsPanel' },
    );
    if (mobileToolsPanelIsOpen) {
      return;
    }

    tooltipManager.show('mobileElementsPanel_quickActionBar_afterRemove');
  }

  function removeBackToTopButton() {
    editorAPI.dsActions.mobile.mobileOnlyComponents.toggleBackToTopButton(
      false,
    );
    tooltipManager.show('BacktoTop_Button_MobileTools_Postdelete_Tooltip');
  }

  const isMobileWizardEnabled = () =>
    stateManagement.mobile.selectors.mobileWizard.getIsMobileWizardEnabled(
      store.getState(),
    );

  const enableMobileWizard = () => {
    dispatch(stateManagement.mobile.actions.mobileWizard.enableMobileWizard());
  };

  const disableMobileWizard = () => {
    dispatch(stateManagement.mobile.actions.mobileWizard.disableMobileWizard());
  };

  const isMobileAnimationsEnabled = () =>
    editorAPI.dsRead.data.getById('masterPage')?.mobileSettings
      ?.animationsEnabled;

  const openMobileWizard = () => {
    const unregisterViewerChange = editorAPI.registerToViewerChange(() => {
      if (editorAPI.isMobileEditor()) {
        unregisterViewerChange();
        setSiteUserPreferences(MOBILE_WIZARD_DONT_SHOW_AGAIN_KEY, true);
        setGlobalUserPreferences(USER_WAS_IN_MOBILE_WIZARD_KEY, true);
        enableMobileWizard();
      }
    });
  };

  const hasUserSeenTheMobileWizard = () =>
    getSiteUserPreferences(MOBILE_WIZARD_DONT_SHOW_AGAIN_KEY);

  const shouldOpenMobileWizard = (isPreview: AnyFixMe) => {
    const isDockedPanelOpen =
      stateManagement.panels.selectors.isDockedSectionOpen(store.getState());
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/some
    const siteHasAtLeastOneNoneLandingPage = _.some(
      editorAPI.pages.getPagesData(),
      (page) => !page.isLandingPage,
    );
    const mobileWizardFlagIsDefined = getGlobalUserPreferences(
      USER_WAS_IN_MOBILE_WIZARD_KEY,
    );

    const mobileFlagIsDefined = !mobileWizardFlagIsDefined;
    return (
      !isDockedPanelOpen &&
      mobileFlagIsDefined &&
      !isPreview &&
      isAllowedByQA('qa_mobileWizard') &&
      !hasUserSeenTheMobileWizard() &&
      siteHasAtLeastOneNoneLandingPage
    );
  };

  const shouldOpenMobileFirstTimePanel = (isPreview: AnyFixMe) => {
    const hasUserSeenMobileFirstTimePanel = getSiteUserPreferences(
      FIRST_TIME_PANEL_DONT_SHOW_AGAIN_KEY,
    );
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/every
    const allPagesAreLandingPages = _.every(
      editorAPI.pages.getPagesData(),
      'isLandingPage',
    );

    return (
      !hasUserSeenMobileFirstTimePanel &&
      isAllowedByQA('qa_1stTimeMobileOn') &&
      !isPreview &&
      allPagesAreLandingPages &&
      !hasUserSeenTheMobileWizard()
    );
  };

  const openMobileFirstTimePanel = () => {
    setSiteUserPreferences(FIRST_TIME_PANEL_DONT_SHOW_AGAIN_KEY, true);
    editorAPI.panelManager.openPanel(
      'panels.focusPanels.mobileEditorFirstTimeVideoPanel',
      {},
      true,
    );
  };

  const areMobileIntrosEnabled = () =>
    stateManagement.mobile.selectors.intros.areEnabled(store.getState());

  const afterMovingToMobileEditorCallback = (isPreview: AnyFixMe) => {
    if (!isPreview) {
      updateLeftBarNotificationsCount();
      if (areMobileIntrosEnabled()) {
        if (shouldOpenMobileWizard(isPreview)) {
          openMobileWizard();
        } else if (shouldOpenMobileFirstTimePanel(isPreview)) {
          openMobileFirstTimePanel();
        }
      }

      setSiteUserPreferences(USER_WAS_IN_MOBILE_EDITOR_KEY, true); // should happen last in this scope!
      editorAPI.bi.event(coreBi.events.mobileEditor.MOBILE_EDITOR_READY, {
        is_first: editorAPI.mobile.mobileEditor.wasSeenBefore(),
      });
    }
  };

  const initMobileEditorFlag = () => {
    const isFirstSave = editorAPI.dsRead.generalInfo.isFirstSave();
    const isDraftMode = editorAPI.dsRead.generalInfo.isDraft();
    const userHaveSeenMobileFirstTimePopup = getSiteUserPreferences(
      FIRST_TIME_PANEL_DONT_SHOW_AGAIN_KEY,
    );

    if (userHaveSeenMobileFirstTimePopup) {
      setSiteUserPreferences(USER_WAS_IN_MOBILE_EDITOR_KEY, true);
    } else if (isFirstSave || isDraftMode) {
      setSiteUserPreferences(
        USER_WAS_IN_MOBILE_EDITOR_KEY,
        editorAPI.isMobileEditor(),
      ); // usually false, because normally the Editor opens in Desktop view
    }
  };

  // This is needed to test modal which is hidden when isqa=true
  const shouldMobileDiscoverabilityIgnoreNOQA = () =>
    Boolean(
      coreUtilsLib.urlUtils.getParameterByName(
        'forceMobileDiscoverabilityIngoreNOQA',
      ),
    );

  function showMobileDiscoverabilityModal() {
    const mobileDiscoverabilityDisabled =
      isResponsiveEditor() ||
      !editorAPI.host
        .getAPI(EditorRestrictionsApiKey)
        .allowed('mobile-editor_mobile-discoverability.visible') ||
      (util.url.isQA() && !shouldMobileDiscoverabilityIgnoreNOQA()) ||
      editorAPI.isMobileDiscoverabilityModalShownInCurrentSession() ||
      getSiteUserPreferences(MOBILE_DONT_SHOW_AGAIN) ||
      editorAPI.mobile.mobileEditor.wasSeenBefore();

    if (mobileDiscoverabilityDisabled) {
      return;
    }

    util.fedopsLogger.interactionStarted(
      util.fedopsLogger.INTERACTIONS.MOBILE_DISCOVERABILITY.MODAL_SHOW,
    );
    editorAPI.panelManager.openPanel('rEditor.panels.discoverMobile', {}, true);
  }

  function notifyCantCopyNonMobileOnly() {
    editorAPI.store.dispatch(
      showUserActionNotification({
        message: 'Notifications_Mobile_CantCopyElements_Text',
        title: 'Notifications_Mobile_CantCopyElements_Text',
        type: constants.NOTIFICATIONS.TYPES.WARNING,
        link: {
          caption: 'Notifications_Learn_More_Link',
          onNotificationLinkClick: () => {
            editorAPI.panelManager.openHelpCenter(
              helpIds.MOBILE_ONLY.CANT_PERFORM_COPY_PASTE_CUT,
            );
          },
        },
      }),
    );
  }

  function notifyCantCutNonMobileOnly() {
    editorAPI.store.dispatch(
      showUserActionNotification({
        message: 'Notifications_Mobile_CantCutElements_Text',
        title: 'Notifications_Mobile_CantCutElements_Text',
        type: constants.NOTIFICATIONS.TYPES.WARNING,
        link: {
          caption: 'Notifications_Learn_More_Link',
          onNotificationLinkClick: () => {
            editorAPI.panelManager.openHelpCenter(
              helpIds.MOBILE_ONLY.CANT_PERFORM_COPY_PASTE_CUT,
            );
          },
        },
      }),
    );
  }

  const getMobileVariant = () => {
    return editorAPI.variants.mobile.get();
  };

  function toggleMobileMenu(target: CompRef | CompRef[]) {
    const isOpen = util.inlinePopupUtils.isOpen(editorAPI, target);
    editorAPI.store.dispatch(
      stateManagement.panels.actions.closeOpenedPanels(),
    );
    if (isOpen) {
      editorAPI.store.dispatch(
        stateManagement.selection.actions.setFocusedContainer(null),
      );
    } else if (util.sections.isSectionsEnabled()) {
      editorAPI.store.dispatch(
        stateManagement.sections.actions.setHoveredSectionLike(null),
      );
    }
    editorAPI.store
      .dispatch(stateManagement.inlinePopup.actions.toggle(target))
      .then(() => {
        if (!isOpen) {
          const expandableMenuRef = editorAPI.components.get.byId(
            editorAPI.mobile.mobileOnlyComponents.mobileOnlyComps
              ?.MENU_AS_CONTAINER_EXPANDABLE_MENU,
          );
          editorAPI.selection.selectComponentByCompRef(expandableMenuRef);
        }
      });
  }

  async function replaceComponentByMobileOnly(
    compRef: CompRef,
  ): Promise<CompRef> {
    const whiteListedProps = [
      'style',
      'data',
      'componentType',
      'layout',
      'type',
      'skin',
      'mobileHints',
    ];

    const serialisedCompToAdd: SerializedCompStructure =
      // eslint-disable-next-line @wix/santa-editor/dsReadSerializeIsTooExpensive
      editorAPI.components.serialize(compRef);

    const parent = editorAPI.components.get.byId(serialisedCompToAdd.parent);

    const compToAdd = _.pick(serialisedCompToAdd, whiteListedProps);

    const addedCompRef = await waitForAddedCompRef(
      editorAPI.components.add(parent, compToAdd as CompStructure),
    );
    biLogger.report(
      componentAddedToStage({
        component_type: 'wysiwyg.viewer.components.WRichText',
        component_id: addedCompRef.id,
        origin: 'add_mobile_text',
      }),
    );
    editorAPI.mobile.hiddenComponents.hide(compRef);
    biLogger.report(
      hiddenElementsHide({
        component_type: editorAPI.components.getType(compRef),
        component_id: compRef.id,
        origin: 'add_mobile_text',
      }),
    );

    await editorAPI.documentServices.waitForChangesAppliedAsync();

    return addedCompRef;
  }

  return {
    mobileEditor: {
      wasSeenBefore: hasUserSeenMobileEditor,
    },
    mobileWizard: {
      isEnabled: isMobileWizardEnabled,
      enable: enableMobileWizard,
      disable: disableMobileWizard,
    },
    mobileOnlyComponents: {
      enableBackToTopButton: enableMobileBackToTopButton,
      isMobileOnlyNonNativeComponent,
      areAnyMobileOnlyNonNativeComponent,
      removeQuickActionBar,
      removeBackToTopButton,
      replaceComponentByMobileOnly,
    },
    hiddenComponents: {
      get: getHiddenMobileComponents,
      show: showHiddenMobileComponent,
      getReAddedHiddenElement,
    },
    mobileDiscoverabilityModal: {
      show: showMobileDiscoverabilityModal,
    },
    initMobileEditorFlag,
    afterMovingToMobileEditorCallback,
    hideHiddenItemsCounter,
    optimizeLayout,
    updateLeftBarNotificationsCount,
    isMobileAnimationsEnabled,
    notifyCantCopyNonMobileOnly,
    notifyCantCutNonMobileOnly,
    getMobileVariant,
    toggleMobileMenu,
  };
}
