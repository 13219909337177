import type { EditorState } from '@/stateManagement';
import { bi } from '@/stateManagement';
import { premiumPackagePickerLoad } from '../../bi';
import type { BiEventDefinition, BiEventFields } from 'types/bi';
import { selectTotalCollectionItemCount } from '../../collections';
import type {
  MapStateToProps,
  MapDispatchToProps,
  Dispatch,
  DispatchMapperArgs,
} from 'types/redux';
import type { PremiumLimits } from '../../types';
import {
  selectHasPremium,
  selectPremiumLimits,
  selectHasMaxPremium,
  selectPremiumNotificationType,
  QuotaStatus,
} from '../../premium';
import type { QuotaStatus as QuotaStatusType } from '../../premium';
import type { LimitsNotificationBannerOwnProps } from './limitsNotificationBanner';

export interface LimitsNotificationBannerStateProps {
  isPremium: boolean;
  isMaxPremium: boolean;
  premiumLimits: PremiumLimits;
  itemCount: number;
  notificationType?: QuotaStatusType;
}

export interface LimitsNotificationBannerDispatchProps {
  sendBi: (event: BiEventDefinition, params: BiEventFields) => void;
  openPremiumPackagePicker: (notificationType: QuotaStatusType) => void;
}

export const mapStateToProps: MapStateToProps<
  LimitsNotificationBannerStateProps,
  LimitsNotificationBannerOwnProps
> = ({ state }) => ({
  isPremium: selectHasPremium(state),
  isMaxPremium: selectHasMaxPremium(state),
  premiumLimits: selectPremiumLimits(state),
  itemCount: selectTotalCollectionItemCount(state),
  notificationType: selectPremiumNotificationType(state),
});

const biReferralInfo = {
  limitsApproaching: 'editor_6btn_panel_approaching_limits_banner',
  limitsExceeded: 'editor_6btn_panel_exceeded_limits_banner',
};

export const mapDispatchToProps: MapDispatchToProps<
  LimitsNotificationBannerDispatchProps,
  LimitsNotificationBannerOwnProps
> = (dispatch) => {
  const { editorAPI } = dispatch(
    (
      dispatch: Dispatch,
      getState: () => EditorState,
      args: DispatchMapperArgs,
    ) => args,
  );

  const openPackagePicker = (referralAdditionalInfo: string) => {
    editorAPI.account.upgrade(referralAdditionalInfo);
    dispatch(
      bi.actions.event(premiumPackagePickerLoad, {
        referralAdditionalInfo,
      }),
    );
  };

  return {
    openPremiumPackagePicker: (notificationType: QuotaStatusType) => {
      if (notificationType === QuotaStatus.APPROACHING) {
        openPackagePicker(biReferralInfo.limitsApproaching);
      } else if (notificationType === QuotaStatus.EXCEEDED) {
        openPackagePicker(biReferralInfo.limitsExceeded);
      }
    },
    sendBi: (event: BiEventDefinition, params: BiEventFields) =>
      dispatch(bi.actions.event(event, params)),
  };
};
