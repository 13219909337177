import experiment from 'experiment';
import { BasePublicApi } from '@/apilib';
import { ErrorReporter } from '@wix/editor-error-reporter';
import type { Scope } from './wizardEntryPoint';

const isWizardActive = ({ store }: Scope) => {
  return store.getIsWizardActive();
};

const attemptAutoPublish = async (
  { savePublishApi }: Scope,
  origin: string,
) => {
  try {
    await savePublishApi.publish({
      origin,
      publish_type: 'auto_publish',
      isAutoPublish: true,
    });
  } catch (e) {
    console.error(e);
    ErrorReporter.captureException(e, {
      tags: {
        wizardPublish: true,
      },
      extra: {
        origin,
      },
    });
  }
};

export const onWizardFinish = async (
  scope: Scope,
  wizardType: string,
  shouldRedirectToDashboard?: boolean,
  domainName?: string,
) => {
  const { store, panelManagerApi, accountApi } = scope;

  store.setWizardActive(false);
  await attemptAutoPublish(scope, wizardType);

  if (shouldRedirectToDashboard) {
    accountApi.manage(true);
    return;
  }

  if (domainName) {
    accountApi.openSettings({
      path: `/add-domain?domainName=${domainName}`,
    });
  }

  setTimeout(() => {
    if (!domainName && experiment.isOpen('se_sgFeedbackModal')) {
      panelManagerApi.openPanel('savePublish.panels.save.feedbackPanel', {
        origin: wizardType,
        translationsKeysOverrides: {
          modalTitle: 'site_generator_feedback_modal_title',
          ratingLabel: 'site_generator_feedback_modal_rating_label',
          textareaLabel: 'site_generator_feedback_modal_review_label',
          textareaPlaceholder:
            'site_generator_feedback_modal_review_placeholder',
          submitButtonText: 'site_generator_feedback_modal_main_cta',
          cancelButtonText: 'site_generator_feedback_modal_secondary_cta',
          ratingDescriptions: [
            'site_generator_feedback_modal_complete_rating_strong_neg_label',
            'site_generator_feedback_modal_complete_rating_neutral_label',
            'site_generator_feedback_modal_complete_rating_strong_pos_label',
          ],
          notificationMessage:
            'site_generator_feedback_modal_success_notification_text',
        },
      });
    }
  }, 1000 * 60);
};

export class WizardApi extends BasePublicApi<Scope> {
  isWizardActive = this.bindScope(isWizardActive);
}
