import { BaseEntryScope, createEntryPoint } from '@/apilib';
import { FixedStageApi } from './api';
import { FixedStageApiKey } from './publicApiKey';
import { PreviewApiKey, ScrollApiKey, EditorAPIKey } from '@/apis';
import { FixedStageStore } from './store';

export class Scope extends BaseEntryScope {
  editorAPI = this.useDependency(EditorAPIKey);
  scroll = this.useDependency(ScrollApiKey);
  preview = this.useDependency(PreviewApiKey);
  store = this.declareStore(FixedStageStore);
}

export const FixedStageEntryPoint = createEntryPoint({
  name: 'FixedStage',
  Scope,
  publicApi({ contributeApi }) {
    contributeApi(FixedStageApiKey, FixedStageApi);
  },
  initialize() {},
});
