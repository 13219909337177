import type { CompRef } from '@wix/document-services-types';

import { array } from '@/util';

import type { AnimationsScope } from '../../scope';

import { EntranceAnimationAPI } from './entranceApi';
import type { EffectData } from '../types';
import type { AnimationAPI } from './baseAnimationsApi';

type AnimationType = 'entrance';

export const createAnimationsApiV2 = (scope: AnimationsScope) => {
  const { editorAPI } = scope;

  const entrance = new EntranceAnimationAPI(editorAPI);

  const apis = {
    entrance,
  };

  return {
    findExistingAnimation: (compRef: CompRef, animationType: AnimationType) =>
      apis[animationType].findExistingAnimation(compRef),
    getEffectData: (compRef: CompRef, animationType: AnimationType) =>
      apis[animationType].getEffectData(compRef),
    setAnimation: (
      compRef: CompRef | CompRef[],
      params: { animationType: AnimationType; effectData: EffectData },
    ) =>
      array.applyForAllAsync(
        (comp) =>
          apis[params.animationType].setAnimation(comp, params.effectData),
        compRef,
      ),
    removeAnimation: (
      compRef: CompRef | CompRef[],
      animationType: AnimationType,
    ) =>
      array.applyForAllAsync(
        (comp) => apis[animationType].removeAnimation(comp),
        compRef,
      ),
    removeMobileOverrides: (compRef: CompRef | CompRef[]) =>
      array.applyForAllAsync(
        (comp) => apis.entrance.removeMobileOverrides(comp),
        compRef,
      ),
    isSplit: (compRef: CompRef) =>
      apis.entrance.isSplit(
        apis.entrance.getCompRefs(compRef).mobileCompPointer,
      ),

    // @TODO change preview functions:
    // 1. They don't care about animationType
    // 2. Separate fns for start/stop/update Scroll animation, as they work in a different way
    previewAnimation: (
      compRef: CompRef | CompRef[],
      params: { animationType: AnimationType; effectData: EffectData },
    ) =>
      array.applyForAll(
        (comp) =>
          (
            apis[params.animationType]
              .previewAnimation as AnimationAPI['previewAnimation']
          )(comp, params.effectData),
        compRef,
      ),
    stopPreviewAnimation: (
      compRef: CompRef | CompRef[],
      animationType: AnimationType,
    ) =>
      array.applyForAll(
        (comp) => apis[animationType].stopPreviewAnimation(comp),
        compRef,
      ),
  };
};
