// @ts-nocheck
import _ from 'lodash';
import * as helpIds from '@/helpIds';
import columnsGfppUtils from '../../utils/columnsGfppUtils';
import gfppDataUtils from '../../utils/gfppDataUtils';
import constants from '@/constants';
import type { EditorAPI } from '@/editorAPI';
import type { CompRef } from '@wix/document-services-types';
import { fixedStage, animations as animationsUtils } from '@/util';

const { ACTIONS } = constants.ROOT_COMPS.GFPP;
const backgroundPanelName = 'compPanels.panels.Column.backgroundPanel';

const changeBackgroundAction = {
  label: 'gfpp_mainaction_columns',
  onClick(editorAPI: EditorAPI, compRef: CompRef, origin) {
    gfppDataUtils.toggleComponentPanel(editorAPI, backgroundPanelName, {
      selectedComponent: compRef,
      prependNoFillPreset: true,
      origin,
    });
  },
  automationId: 'gfpp-button-background',
};

function getMainActions(editorAPI, columnPtr) {
  const columnsContainerPtr = editorAPI.components.getContainer(columnPtr);

  const actions = [
    columnsGfppUtils.getManageColumnsActionIfApplicable(
      editorAPI,
      columnsContainerPtr,
      columnPtr,
    ),
    editorAPI.isDesktopEditor() ? changeBackgroundAction : null,
  ];

  return _.compact(actions);
}

export default {
  untranslatable: true,
  mainActions: getMainActions,
  enabledActions(editorAPI, compRef) {
    const columnsContainerPtr = editorAPI.components.getContainer(compRef);
    const columnsContainerProperties =
      editorAPI.components.properties.get(columnsContainerPtr);
    let actions;
    if (
      columnsContainerProperties.fullWidth &&
      !fixedStage.isFixedStageEnabled()
    ) {
      actions = [ACTIONS.EFFECTS, ACTIONS.LAYOUT, ACTIONS.HELP];
    } else {
      actions = [ACTIONS.EFFECTS, ACTIONS.HELP];
    }
    return actions;
  },
  mobileMainActions: getMainActions,
  mobileEnabledActions: animationsUtils.isNewAnimationsEnabled()
    ? [
        ACTIONS.ANIMATION,
        ACTIONS.EFFECTS,
        ACTIONS.HIDE,
        ACTIONS.HELP,
        ACTIONS.MOBILE_BACKGROUND_SETTINGS,
      ]
    : [
        ACTIONS.MOTION,
        ACTIONS.HIDE,
        ACTIONS.HELP,
        ACTIONS.MOBILE_BACKGROUND_SETTINGS,
      ],
  presetActions: {
    help: {
      helpId: helpIds.GFPP.COLUMN,
      mobileHelpId: helpIds.GFPP.COLUMN_MOBILE,
    },
  },
};
