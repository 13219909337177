import _ from 'lodash';
import constants from '@/constants';
import * as helpIds from '@/helpIds';
import { animations as animationsUtils } from '@/util';
import gfppDataUtils from './gfppDataUtils';
import experiment from 'experiment';
import type { EditorAPI } from '@/editorAPI';
import * as stateManagement from '@/stateManagement';
import type { CompRef } from 'types/documentServices';
import { AnimationsApiKey } from '@/apis';

const { ACTIONS } = constants.ROOT_COMPS.GFPP;

const CONTAINER_ANIMATION_TYPES = [
  'wysiwyg.viewer.components.BoxSlideShow',
  'wysiwyg.viewer.components.StateBox',
  'wysiwyg.viewer.components.MediaContainer',
];

const CONTAINER_FULL_WIDTH_ANIMATION_TYPES = [
  'wysiwyg.viewer.components.StripContainerSlideShow',
  'wysiwyg.viewer.components.StateStrip',
  'wysiwyg.viewer.components.FooterContainer',
];

const acceptableModes = ['HOVER', 'DEFAULT'];

const { isInInteractionMode } = stateManagement.interactions.selectors;

function getModesAnimationTooltip(editorAPI: EditorAPI, compRef: CompRef[]) {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/map
  const compDisplayTypes = _.map(
    compRef,
    getModesDisplayType.bind(null, editorAPI),
  );
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/every
  const areCompsInNonDefaultMode = _.every(compDisplayTypes, function (type) {
    return type === constants.COMPONENT_DISPLAY.IN_MODE_AND_DEFAULT;
  });
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/every
  const areCompsInDefaultModeOnly = _.every(compDisplayTypes, function (type) {
    return type === constants.COMPONENT_DISPLAY.ONLY_IN_DEFAULT_MODE;
  });

  if (areCompsInNonDefaultMode) {
    return 'gfpp_tooltip_hover_animation_disabled_linked_element_in_regular';
  } else if (areCompsInDefaultModeOnly) {
    return 'gfpp_tooltip_hover_animation_disabled_single_element_in_regular';
  }
}

function isAllContainers(editorAPI: EditorAPI, compRefs: CompRef[]) {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/every
  return _.every(compRefs, (compRef) => isContainerType(editorAPI, compRef));
}

function isFullWidthContainerType(editorAPI: EditorAPI, compRef: CompRef) {
  const compType = editorAPI.components.getType(compRef);
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/includes
  const isContainer = _.includes(
    CONTAINER_FULL_WIDTH_ANIMATION_TYPES,
    compType,
  );
  const isSingleColumn = editorAPI.columns.isSingleColumnStrip(compRef);
  const isColumn = editorAPI.columns.isColumn(compRef);
  return isContainer || isSingleColumn || isColumn;
}

function isContainerType(editorAPI: EditorAPI, compRef: CompRef) {
  const compType = editorAPI.components.getType(compRef);
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/includes
  const isContainer = _.includes(CONTAINER_ANIMATION_TYPES, compType);
  return isContainer || isFullWidthContainerType(editorAPI, compRef);
}

/**
 * Pass override key for specific components.
 * Currently handles only Columns
 */
function getOverridesKeyByComp(editorAPI: EditorAPI, compRefs: CompRef[]) {
  const overrideColumnAnimation =
    isAllContainers(editorAPI, compRefs) && editorAPI.isMobileEditor();

  if (overrideColumnAnimation) {
    return {
      dataOverridesKey: 'Containers',
    };
  }
  return {};
}

function getModesDisplayType(editorAPI: EditorAPI, compRef: CompRef) {
  const containerComp =
    editorAPI.components.getContainer_DEPRECATED_BAD_PERFORMANCE(compRef);
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/filter
  const containerModes = _.filter(
    editorAPI.components.modes.getModes(containerComp),
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/includes
    (mode) => _.includes(acceptableModes, mode.type),
  );
  if (containerModes && containerModes.length > 0) {
    const firstAncestorWithMode =
      editorAPI.components.modes.getFirstAncestorWithActiveModes(compRef);

    if (experiment.isOpen('widthModes') || experiment.isOpen('scrollModes')) {
      return isCompDisplayedInSomeModes(editorAPI, compRef, containerModes)
        ? constants.COMPONENT_DISPLAY.IN_MODE_AND_DEFAULT
        : constants.COMPONENT_DISPLAY.ONLY_IN_NON_DEFAULT_MODES;
    }

    if (
      editorAPI.components.modes.isCompDefaultModeActive(firstAncestorWithMode)
    ) {
      return isCompDisplayedInSomeModes(editorAPI, compRef, containerModes)
        ? constants.COMPONENT_DISPLAY.IN_MODE_AND_DEFAULT
        : constants.COMPONENT_DISPLAY.ONLY_IN_DEFAULT_MODE;
    }
    const isDisplayedInDefault =
      // @ts-expect-error
      editorAPI.components.modes.isDisplayedByDefault(compRef);
    return isDisplayedInDefault
      ? constants.COMPONENT_DISPLAY.IN_MODE_AND_DEFAULT
      : constants.COMPONENT_DISPLAY.ONLY_IN_NON_DEFAULT_MODES;
  }
  return constants.COMPONENT_DISPLAY.OUT_OF_MODES_BOX;
}

function getAnimationPanelPropsForModeInAnimations(modeIds: string[]) {
  return {
    panelAction: constants.ACTIONS.MODE_IN,
    panelExitAction: constants.ACTIONS.MODE_EXIT,
    shouldAddExit: true,
    dataOverridesKey: constants.ACTIONS.MODE_IN,
    allowOnlyShowOnceUI: false,
    extraBehaviorParams: {
      modeIds,
    },
  };
}

function getAnimationPanelPropsForTransitionAnimations(modeIds: string[]) {
  return {
    panelAction: constants.ACTIONS.MODE_CHANGE,
    dataOverridesKey: constants.ACTIONS.MODE_CHANGE,
    explanationText: 'ANIMATION_PANEL_HOVER_BOX_DESCRIPTION_TEXT',
    allowPreviewUI: false,
    allowOnlyShowOnceUI: false,
    helpId: helpIds.ANIMATION.TRANSITION,
    extraBehaviorParams: {
      modeIds,
    },
  };
}

function isCompDisplayedInSomeModes(
  editorAPI: EditorAPI,
  compRef: CompRef,
  containerModes: AnyFixMe,
) {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/some
  return _.some(containerModes, function (mode) {
    return (
      mode.type !== 'DEFAULT' &&
      editorAPI.components.modes.isComponentDisplayedInModes(compRef, [
        mode.modeId,
      ])
    );
  });
}

function getModesAnimationPanelProps(
  editorAPI: EditorAPI,
  compRefs: CompRef[],
) {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/map
  const compAnimationTypes = _.map(
    compRefs,
    getModesDisplayType.bind(null, editorAPI),
  );

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/every
  const allCompsShouldHaveModeInAnimations = _.every(
    compAnimationTypes,
    function (compAnimationType) {
      return (
        compAnimationType ===
        constants.COMPONENT_DISPLAY.ONLY_IN_NON_DEFAULT_MODES
      );
    },
  );

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/every
  const allCompsShouldHaveTransition = _.every(
    compAnimationTypes,
    function (compAnimationType) {
      return (
        compAnimationType === constants.COMPONENT_DISPLAY.IN_MODE_AND_DEFAULT
      );
    },
  );

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/is-array
  const compRefsArr = _.isArray(compRefs) ? compRefs : [compRefs];
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/keys
  const modeIds = _.keys(
    // @ts-expect-error
    editorAPI.components.modes.getFirstAncestorActiveModes(compRefsArr[0]),
  );
  if (allCompsShouldHaveModeInAnimations) {
    return getAnimationPanelPropsForModeInAnimations(modeIds);
  } else if (allCompsShouldHaveTransition) {
    return getAnimationPanelPropsForTransitionAnimations(modeIds);
  }
  return {};
}

function onAnimationClick(
  editorAPI: EditorAPI,
  compRefs: CompRef[],
  origin: string,
  props: Record<string, unknown>,
) {
  if (animationsUtils.isNewAnimationsEnabled()) {
    editorAPI.host.getAPI(AnimationsApiKey).toggleAnimationPanel({
      origin,
      ...props,
      selectedComponent: compRefs,
    });
    return;
  }
  const panelProps = {};
  const modesProps = getModesAnimationPanelProps(editorAPI, compRefs);
  const mobileCompOverrides = getOverridesKeyByComp(editorAPI, compRefs);
  // If modes overrides, ignore component overrides
  if (!_.isEmpty(modesProps)) {
    _.merge(panelProps, modesProps, props);
  } else {
    // Regular Screen In, force split animation for mobile
    _.merge(panelProps, mobileCompOverrides, props);
  }
  gfppDataUtils.getTogglePanelFn(ACTIONS.ANIMATION, panelProps)(
    editorAPI,
    compRefs,
    origin || 'gfpp',
    panelProps,
  );
}

function isAnimationSupported(editorAPI: EditorAPI, compRefs: CompRef[]) {
  const state = editorAPI.store.getState();
  if (isInInteractionMode(state)) {
    return false;
  }
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/some
  const isAnimatable = _.some(
    constants.ROOT_COMPS.GFPP.ANIMATION_TYPES,
    (action) => editorAPI.components.is.animatable(compRefs, action),
  );
  if (isAnimatable && editorAPI.components.areComponentsOfSamePage(compRefs)) {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/map
    const compDisplayTypes = _.map(
      compRefs,
      getModesDisplayType.bind(null, editorAPI),
    );
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/every
    const areAllCompDisplayTypesEqual = _.every(
      compDisplayTypes,
      _.isEqual.bind(_, compDisplayTypes[0]),
    );
    return (
      areAllCompDisplayTypesEqual &&
      editorAPI.components.modes.areComponentsOfSameModefulContainer(compRefs)
    );
  }
  return false;
}

export default {
  onAnimationClick,
  isAnimationSupported,
  getModesAnimationTooltip,
  isContainerType,
  isFullWidthContainerType,
  getModesDisplayType,
};
