// @ts-nocheck
import createReactClass from 'create-react-class';
import * as core from '@/core';
import _ from 'lodash';
import liveComponentSectionMixin from './liveComponentSectionMixin';
import componentsDefaultData from '../../addPanel/componentsDefaultData';
import React from 'react';
import SectionWrapper from '../sectionWrapper';
import * as util from '@/util';
import { translate } from '@/i18n';
import * as symbols from '@wix/santa-editor-symbols';
import * as santaPreview from '@/santaPreview';
import * as liveComponentStyleAdjuster from './liveComponentStyleAdjuster';
import { cx } from '@/util';

const SELECTED_ITEM_ICON_SIZE = 24;
const SELECTED_ITEM_OFFSET = (-1 * SELECTED_ITEM_ICON_SIZE) / 2;
const ITEM_TEXT_FONT_SIZE = 14;

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'designPanelLiveComponentSection',
  mixins: [liveComponentSectionMixin, core.mixins.editorAPIMixin],
  onItemClick(itemKey) {
    const compData = this.getCompDataByItemKey(itemKey);
    if (compData) {
      this.props.onClick(compData, this.props.title, '', itemKey);
    }
  },
  onItemHover(itemKey) {
    const compData = this.getCompDataByItemKey(itemKey);
    if (compData && this.props.onMouseEnter) {
      this.props.onMouseEnter(compData, itemKey);
    }
  },
  onMouseLeave(itemKey) {
    if (this.props.onMouseLeave) {
      this.props.onMouseLeave(itemKey);
    }
  },
  getSectionItemsKeys() {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/keys
    return _.keys(this.elementsPositionInSection);
  },
  getItemStyle(itemKey) {
    const elem = this.elementsPositionInSection[itemKey];
    const compDef = componentsDefaultData.getComponentDefault(
      elem.key,
      this.props.parentType,
    );
    return {
      left:
        elem.compPosition.x -
        (compDef.addClickArea ? compDef.addClickArea / 2 : 0),
      top: elem.compPosition.y,
      width: elem.width + (compDef.addClickArea ? compDef.addClickArea : 0),
      height: elem.height,
    };
  },
  getSelectedItemSelectBoxStyle(itemKey) {
    const scale = this.props.props.scaleProportion.x;
    const elem = this.elementsPositionInSection[itemKey];
    const compDef = componentsDefaultData.getComponentDefault(
      elem.key,
      this.props.parentType,
    );
    return {
      top: SELECTED_ITEM_OFFSET / scale,
      right:
        SELECTED_ITEM_OFFSET / scale +
        (compDef.addClickArea ? compDef.addClickArea / 2 : 0),
      width: SELECTED_ITEM_ICON_SIZE / scale,
      height: SELECTED_ITEM_ICON_SIZE / scale,
    };
  },
  getItemProps(itemKey) {
    const compDef = this.elementsPositionInSection[itemKey];
    return (
      this.props.getAdditionalElementProps &&
      this.props.getAdditionalElementProps(compDef)
    );
  },
  getItemClass(itemKey) {
    const additionalProps = this.getItemProps(itemKey);
    const classes = {
      'section-live-site-item': true,
      'design-panel-selected-item': this.isSelectedItem(itemKey),
      'section-scaled-in-half': this.props.props.scaleProportion.x === 0.5,
    };
    if (additionalProps?.className) {
      classes[additionalProps.className] = true;
    }
    return classes;
  },
  getItemText(itemKey) {
    const properties = this.getItemProps(itemKey);
    return properties?.text;
  },
  getItemTextStyle(itemKey) {
    return {
      lineHeight: `${this.elementsPositionInSection[itemKey].height}px`,
      fontSize: ITEM_TEXT_FONT_SIZE / this.props.props.scaleProportion.x,
    };
  },
  getStyleId(itemKey) {
    const itemNameAsArr = itemKey.split('.');
    const lastPart = itemNameAsArr[itemNameAsArr.length - 1];
    return lastPart ? lastPart.substring(lastPart.indexOf('_') + 1) : '';
  },
  isSelectedItem(itemKey) {
    if (this.props.multiSelectedComponents) {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/map
      const compStyles = _.map(
        this.props.multiSelectedComponents,
        (compRef) => {
          const style = this.getEditorAPI().components.style.get(compRef);
          return style?.style?.properties;
        },
      );

      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/every
      if (!_.every(compStyles, (style) => _.isEqual(style, compStyles[0]))) {
        return false;
      }
    }

    if (this.props.selectedItemKey) {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/includes
      return _.includes(this.props.selectedItemKey, itemKey);
    }

    const compDef = this.elementsPositionInSection[itemKey];
    if (this.props.isSelectedItem) {
      return this.props.isSelectedItem(compDef);
    }
    return false;
  },
  render() {
    return !_.isEmpty(this.props.stylesForSection) && !this.props.hide ? (
      <SectionWrapper
        key="liveComps"
        sectionFooter={false}
        className="live-comps"
        {...this.props}
        style={{
          height: `${this.calculateTotalSectionHeight()}px`,
          ...this.props?.style,
        }}
      >
        <div
          key={this.props.id}
          ref={`sectionLiveSiteWrapper${this.props.id}`}
          style={this.getStyleProps()}
          onClickCapture={this.onClick}
          className="section-live-site-wrapper"
        >
          <santaPreview.santaPreviewContainer
            rootUrl={window.santaBase}
            languageCode={util.languages.getLanguageCode()}
            ref={`santaPreview${this.props.id}`}
            key={`santaPreview${this.props.id}`}
            preview={this.getLiveCompsComponentsDef}
            onSiteReady={this.onSiteReadyUpdateLayout}
            getColorToReplaceIfNeeded={
              liveComponentStyleAdjuster.getColorTeReplaceIfNeeded
            }
          />

          {this.state.layoutDone ? (
            <div key="blockLayer" className="section-live-site-block-layer">
              {/* TODO: Fix this the next time the file is edited. */}
              {/* eslint-disable-next-line you-dont-need-lodash-underscore/map */}
              {_.map(this.getSectionItemsKeys(), (itemKey) => (
                <div
                  key={itemKey}
                  data-styleid={this.getStyleId(itemKey)}
                  style={this.getItemStyle(itemKey)}
                  onMouseEnter={() => {
                    this.onItemHover(itemKey);
                  }}
                  onMouseLeave={() => {
                    this.onMouseLeave(itemKey);
                  }}
                  onClick={() => {
                    this.onItemClick(itemKey);
                  }}
                  className={cx(this.getItemClass(itemKey))}
                >
                  {this.isSelectedItem(itemKey) ? (
                    <symbols.symbol
                      key="selectedItem"
                      name="selectedItemDesignPanel"
                      style={this.getSelectedItemSelectBoxStyle(itemKey)}
                    />
                  ) : null}
                  {this.getItemText(itemKey)
                    ? (() => {
                        const itemText = this.getItemText(itemKey);

                        return (
                          <div
                            key="itemText"
                            style={this.getItemTextStyle(itemKey)}
                          >
                            <span>{translate(itemText)}</span>
                          </div>
                        );
                      })()
                    : null}
                </div>
              ))}
            </div>
          ) : null}
        </div>
      </SectionWrapper>
    ) : null;
  },
});
