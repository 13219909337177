import * as util from '@/util';
import * as blog from '@/blog';
import { translate } from '@/i18n';
import * as coreBi from '@/coreBi';
import experiment from 'experiment';
import { appMarketHandlers, constants as tpaConstants } from '@/tpa';
import * as platformUtils from '@wix/santa-platform-utils';
import { getBookingsEditorActionService } from '@/wixBookings';
import {
  wixStoreDataProvider,
  addStoreAppToSite,
  addStorePageToSite,
} from '@/wixStore';
import {
  WIX_BLOG,
  WIX_ECOM,
  WIX_NEW_STORES,
  WIX_BOOKINGS,
  DATA_BINDING,
  PRICING_PLANS,
} from '@wix/app-definition-ids';

import type { EditorAPI } from '@/editorAPI';
import type { WixStoreApi } from '@/wixStore';
import type { ExternalPresetOptions } from '../types';
import type {
  CompRef,
  BIEventFields,
  BIEventDefinition,
} from 'types/documentServices';
import type { AppInstallOrigin } from '@wix/editor-platform-host-integration-apis';
import {
  EditorType,
  InstallInitiator,
  InstallationOriginType,
} from '@wix/platform-editor-sdk';
import type { ImageTransformTarget } from '@/util';

export const origin = 'addPagePanel';

export const isAddPageProductionMode =
  !experiment.isOpen('se_addDesignerPage') && !util.url.isQA();

export function getPagesExternalPresetOptions(
  editorAPI: EditorAPI,
): ExternalPresetOptions[] {
  return [
    {
      label: 'Dynamic Pages',
      value: DATA_BINDING,
      biCategory: 'dynamic page',
      ids: [
        'blank',
        'team',
        'projects',
        'news',
        'portfolio',
        'realEstateListings',
        'recipes',
        'jobs',
        'courses',
        'services',
      ],
      handler: async (
        presetId: string,
        sendBI: (definition: BIEventDefinition, fields: BIEventFields) => void,
      ) => {
        if (!editorAPI.wixCode.isProvisioned()) {
          await new Promise((resolve, reject) =>
            editorAPI.wixCode.provision({
              onSuccess: resolve,
              onError: reject,
            }),
          );
          sendBI(coreBi.events.addPanel.DATA_MODE_ON_ACTIVATION_CLICK, {
            status: true,
            origin: 'manual',
            entry_point: origin,
          });
        }
        const appPublicAPI = await editorAPI.platform.getAppPublicApi(
          DATA_BINDING,
        );
        util.fedopsLogger.interactionStarted(
          util.fedopsLogger.INTERACTIONS.ADD_PAGE_ADD_DYNAMIC_PAGE_PRESET,
        );
        const presetData = await appPublicAPI.addPresetById(presetId, {
          origin,
        });
        util.fedopsLogger.interactionEnded(
          util.fedopsLogger.INTERACTIONS.ADD_PAGE_ADD_DYNAMIC_PAGE_PRESET,
        );
        return presetData.pageRefs.map(({ id }: CompRef) => id);
      },
    },
  ];
}

export function isApplicationInstalled(
  editorAPI: EditorAPI,
  appDefinitionId: string,
): boolean {
  switch (appDefinitionId) {
    case WIX_BLOG:
      return blog.siteHasNewBlog(editorAPI);
    case WIX_NEW_STORES:
      return wixStoreDataProvider.isAppInstalled(editorAPI);
    case WIX_BOOKINGS:
    case PRICING_PLANS:
      return editorAPI.tpa.app.isInstalled(appDefinitionId);
    default:
      console.warn(
        `App ${appDefinitionId} still not supported in add page panel.`,
      );
  }
}

export function shouldDisablePostInstallSecondaryAction(
  wixStoreAPI: WixStoreApi,
  appDefinitionId: string,
): boolean {
  switch (appDefinitionId) {
    case WIX_NEW_STORES:
      return wixStoreAPI.getIsNewStoreCategoryUser();
    default:
      return false;
  }
}

interface AppInstallationMap {
  [appDefId: string]: (editorAPI: EditorAPI) => Promise<string>;
}

export function getAddPagePanelPlatformOrigin(): AppInstallOrigin {
  return {
    type: EditorType.Classic,
    initiator: InstallInitiator.Editor,
    info: {
      type: InstallationOriginType.ADD_PAGE_PANEL,
    },
  };
}

function shouldUpdatedManagingAppDefId(
  pageId: string,
  appDefId: string,
  editorAPI: EditorAPI,
) {
  const { managingAppDefId } = editorAPI.pages.data.get(pageId);
  if (!managingAppDefId) {
    editorAPI.pages.data.update(pageId, {
      managingAppDefId: appDefId,
    });
  }
}

const TPAPageIds = {
  bookings: 'Bookings',
  pricingPlans: 'Pricing Plans',
  blog: 'Blog',
  store: 'Store',
}; // Used to distinguish tpas added

export const appInstallationMap: AppInstallationMap = {
  [WIX_BLOG]: (editorAPI: EditorAPI) => {
    return new Promise<string>((resolve) =>
      blog.addNewBlog(
        editorAPI,
        {
          origin: tpaConstants.BI.type.ADD_APP_ADD_PAGE_PANEL,
          platformOrigin: getAddPagePanelPlatformOrigin(),
        },
        () => {
          blog.registerBlogButton(editorAPI);
          // Taken this from https://github.com/wix-private/santa-editor/pull/38280, should be temporary
          editorAPI.platform.extensions.__dispatchHackyAppInstalledEvent(
            blog.newBlogAppDefId,
          );
          blog.onNewBlogInstalled(editorAPI);
          editorAPI.panelHelpers.openPagesPanel({
            selectedTabId: WIX_BLOG,
            origin,
          });
          resolve(TPAPageIds.blog);
        },
      ),
    );
  },
  [WIX_BOOKINGS]: (editorAPI: EditorAPI) => {
    const bookingsEditorActionService =
      getBookingsEditorActionService(editorAPI);
    return new Promise<string>((resolve) =>
      bookingsEditorActionService.addAppToSite(
        () => {
          editorAPI.panelHelpers.openPagesPanel({
            selectedTabId: WIX_BOOKINGS,
            origin,
          });
          resolve(TPAPageIds.bookings);
        },
        {
          biOrigin: tpaConstants.BI.type.ADD_APP_ADD_PAGE_PANEL,
          platformOrigin: getAddPagePanelPlatformOrigin(),
        },
      ),
    );
  },
  [WIX_NEW_STORES]: (editorAPI: EditorAPI) => {
    return addStoreAppToSite(
      tpaConstants.BI.type.ADD_APP_ADD_PAGE_PANEL,
      false,
      WIX_NEW_STORES,
      getAddPagePanelPlatformOrigin(),
    )
      .then(() => {
        editorAPI.panelHelpers.openPagesPanel({
          selectedTabId: WIX_ECOM,
          origin,
        });
      })
      .then(() => TPAPageIds.store);
  },
  [PRICING_PLANS]: async (editorAPI: EditorAPI) => {
    await appMarketHandlers.addApp(editorAPI, {
      appDefId: PRICING_PLANS,
      platformOrigin: getAddPagePanelPlatformOrigin(),
    });
    return TPAPageIds.pricingPlans;
  },
};

interface AppPostInstallationMap {
  [appDefId: string]: (editorAPI: EditorAPI) => void;
}

export const appPostInstallationMap: AppPostInstallationMap = {
  [WIX_BLOG]: (editorAPI: EditorAPI) => {
    editorAPI.panelHelpers.openPagesPanel({ selectedTabId: WIX_BLOG, origin });
  },
  [WIX_BOOKINGS]: (editorAPI: EditorAPI) => {
    editorAPI.panelHelpers.openPagesPanel({
      selectedTabId: WIX_BOOKINGS,
      origin,
    });
  },
  [WIX_NEW_STORES]: (editorAPI: EditorAPI) => {
    editorAPI.panelHelpers.openPagesPanel({ selectedTabId: WIX_ECOM, origin });
  },
  [PRICING_PLANS]: (editorAPI: EditorAPI) => {
    const { applicationId } =
      editorAPI.platform.getAppDataByAppDefId(PRICING_PLANS);
    editorAPI.pages.navigateTo(
      editorAPI.pages
        .getPagesData()
        .find(({ tpaApplicationId }) => tpaApplicationId === applicationId).id,
      () => {
        editorAPI.panelHelpers.openPagesPanel({ origin });
      },
    );
  },
};

interface AppPostInstallationSecondaryActionMap {
  [appDefId: string]: (editorAPI: EditorAPI) => Promise<string>;
}

export const appPostInstallationSecondaryActionMap: AppPostInstallationSecondaryActionMap =
  {
    [WIX_NEW_STORES]: (editorAPI: EditorAPI) => {
      editorAPI.panelHelpers.openProgressBar({
        title: translate('Installation_Popup_Loader_shop_page_Header'),
        totalSteps: 2,
        currentStep: 1,
        taskDisplayName: translate('Installation_Popup_Loader_shop_page_Text1'),
      });
      return addStorePageToSite(WIX_NEW_STORES, origin)
        .then((newPageId) => {
          editorAPI.panelHelpers.updateProgressBar(
            2,
            translate('Installation_Popup_Loader_shop_page_Text2'),
            translate('Installation_Popup_Loader_shop_page_Header'),
          );
          shouldUpdatedManagingAppDefId(newPageId, WIX_NEW_STORES, editorAPI);
          editorAPI.panelHelpers.closeProgressBar();
        })
        .then(() => {
          editorAPI.panelHelpers.openPagesPanel({
            origin,
            renameEnabled: true,
            selectedTabId: WIX_NEW_STORES,
          });
        })
        .then(() => TPAPageIds.store);
    },
  };

export function getImageUrl(
  image: string,
  target: ImageTransformTarget,
): string {
  const mediaItem = platformUtils.mediaItemUtils.parseMediaItemUri(image);
  return `${window.serviceTopology.staticMediaUrl}/${
    util.imageTransform.getData(
      util.imageTransform.fittingTypes.SCALE_TO_FIT,
      {
        id: mediaItem.mediaId,
        width: mediaItem.width,
        height: mediaItem.height,
      },
      target,
    ).uri
  }`;
}
