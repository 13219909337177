import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Text, TextButton } from '@wix/wix-base-ui';
import { cx, hoc } from '@/util';
import { withRestrictions } from '@/editorRestrictions';
import { AUTOMATION_IDS } from '../constants';
import { mapStateToProps } from './SectionEmptyState.mapper';
import { SectionLayoutPresets } from '../../LayoutPresets';
import styles from './SectionsEmptyState.scss';

import type { SectionEmptyStateStateProps } from './SectionEmptyState.mapper';
import type { CompRef } from 'types/documentServices';

export interface SectionEmptyStateOwnProps {
  section: CompRef;
  onAddElementsClick: React.MouseEventHandler;
  onAddSectionClick: React.MouseEventHandler;
}

interface SectionEmptyStateProps
  extends SectionEmptyStateOwnProps,
    SectionEmptyStateStateProps {}

const SectionEmptyStateComponent: React.FC<SectionEmptyStateProps> = ({
  section,
  onAddSectionClick,
  onAddElementsClick,
  isLightTextColor,
  isEmptyStateAddPanelVisible,
  isEmptyStateTitleTextVisible,
  isLayoutPresetsVisible,
  style,
}) => {
  const { t } = useTranslation();

  const renderLinks = () => {
    if (isLayoutPresetsVisible) {
      return (
        <>
          <Trans i18nKey="section_blank_layouts_empty_state_text_1">
            <TextButton
              size="small"
              skin="standard"
              weight="normal"
              shouldTranslate={false}
              dataHook={AUTOMATION_IDS.EMPTY_STATE.ADD_SECTION}
              onClick={onAddSectionClick}
            />
            <TextButton
              size="small"
              skin="standard"
              weight="normal"
              shouldTranslate={false}
              dataHook={AUTOMATION_IDS.EMPTY_STATE.ADD_ELEMENT}
              onClick={onAddElementsClick}
            />
          </Trans>
          <Text
            size="small"
            skin="standard"
            weight="normal"
            shouldTranslate={false}
          >
            {t('section_blank_layouts_empty_state_text_2')}
          </Text>
        </>
      );
    }

    if (isEmptyStateAddPanelVisible) {
      return (
        <Trans i18nKey="section_blank_empty_state_text_2">
          <TextButton
            size="small"
            skin="standard"
            weight="normal"
            shouldTranslate={false}
            dataHook={AUTOMATION_IDS.EMPTY_STATE.ADD_SECTION}
            onClick={onAddSectionClick}
          />
          <TextButton
            size="small"
            skin="standard"
            weight="normal"
            shouldTranslate={false}
            dataHook={AUTOMATION_IDS.EMPTY_STATE.ADD_ELEMENT}
            onClick={onAddElementsClick}
          />
        </Trans>
      );
    }

    return (
      <TextButton
        size="small"
        skin="standard"
        weight="normal"
        dataHook={AUTOMATION_IDS.EMPTY_STATE.ADD_SECTION}
        onClick={onAddSectionClick}
      >
        lite_editor_blank_section_empty_state_text
      </TextButton>
    );
  };

  return (
    <div
      data-hook={AUTOMATION_IDS.EMPTY_STATE.CONTAINER}
      className={cx(styles.emptyStateContainer, {
        [styles.light]: isLightTextColor,
      })}
      style={style}
    >
      {isEmptyStateTitleTextVisible && (
        <Text
          size="small"
          skin="secondary"
          weight="normal"
          shouldTranslate={false}
        >
          {t('section_blank_empty_state_text_1')}
        </Text>
      )}
      <div
        className={styles.linksContainer}
        onMouseDown={(event) => event.stopPropagation()}
      >
        {renderLinks()}
      </div>
      {isLayoutPresetsVisible && (
        <div className={styles.presetsContainer}>
          <SectionLayoutPresets section={section} />
        </div>
      )}
    </div>
  );
};

export const SectionEmptyState = withRestrictions('sections_empty-state')(
  hoc.connect(
    hoc.STORES.EDITOR_API,
    mapStateToProps,
    null,
  )(SectionEmptyStateComponent),
);
