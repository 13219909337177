import type { CompStructure } from 'types/documentServices';

const getTextCompSerialized = ({
  text,
  position,
}: {
  text: string;
  position?: { x: number; y: number };
}): CompStructure =>
  ({
    componentType: 'wysiwyg.viewer.components.WRichText',
    layout: {
      x: 60,
      y: position?.y ?? 18,
      fixedPosition: false,
      width: 300,
      height: 50,
      scale: 1,
      rotationInDegrees: 0,
    },
    type: 'Component',
    skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
    metaData: {
      pageId: 'c1dmp',
      sig: '0wi-312',
      basedOnSignature: '0wi-293',
    },
    parent: 'comp-kq4zfl7c',
    data: {
      linkList: [],
      text: `<h1 class=\"font_7\">${text}</h1>`,
      stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
      type: 'StyledText',
      metaData: {
        isPreset: false,
        schemaVersion: '1.0',
        isHidden: false,
        pageId: 'c1dmp',
        sig: '0wi-207',
        basedOnSignature: '0wi-206',
      },
    },
    props: {
      type: 'WRichTextProperties',
      packed: true,
      brightness: 1,
      verticalText: false,
      metaData: {
        schemaVersion: '1.0',
        autoGenerated: false,
        pageId: 'c1dmp',
        sig: '0wi-197',
      },
    },
    style: 'txtNew',
  } as CompStructure);

export default getTextCompSerialized;
