import * as styles from '@/styles';

const { advancedStyleDataUtil } = styles.advancedStyle;
const { STATES } = advancedStyleDataUtil;

const horizontalTabsStateTranslatedKeys = {
  regular: 'Image_Button_Settings_Regular_View_Label',
  hover: 'Image_Button_Settings_Hover_View_Label',
  disabled: 'Image_Button_Settings_Disabled_View_Label',
  active: 'Image_Button_Settings_Clicked_View_Label',
  focus: 'CustomDesign_ComponentState_Label_Focus',
  error: 'CustomDesign_ComponentState_Label_Error',
  open: 'CustomDesign_ComponentState_Label_Open',
  closed: 'CustomDesign_ComponentState_Label_Closed',
  selected: 'CustomDesign_ComponentState_Label_Selected',
  scrolled: 'Header_Design_State_Tab_Scroll',
};

const statesDropdownTranslatedKeys = {
  regular: 'CustomDesign_ComponentState_Label_Regular',
  hover: 'CustomDesign_ComponentState_Label_Hover',
  focus: 'CustomDesign_ComponentState_Label_Focus',
  error: 'CustomDesign_ComponentState_Label_Error',
  disabled: 'CustomDesign_ComponentState_Label_Disabled',
  selected: 'CustomDesign_ComponentState_Label_Selected',
};

const categoriesData = {
  fill: {
    symbolName: 'fillDesign',
    displayOrder: 1,
    translationKey: 'CustomDesign_TabLabel_FillColor&Opacity',
  },
  border: {
    symbolName: 'borderDesign',
    displayOrder: 2,
    translationKey: 'CustomDesign_TabLabel_Borders',
  },
  corners: {
    symbolName: 'cornersDesign',
    displayOrder: 3,
    translationKey: 'CustomDesign_TabLabel_Corners',
  },
  shadow: {
    symbolName: 'shadowDesign',
    displayOrder: 4,
    translationKey: 'CustomDesign_TabLabel_Shadow',
  },
  text: {
    symbolName: 'textDesign',
    displayOrder: 5,
    translationKey: 'CustomDesign_TabLabel_Text',
  },
  other: {
    symbolName: 'spacingDesign',
    displayOrder: 6,
    translationKey: 'CustomDesign_TabLabel_Spacing',
  },
  icon: {
    symbolName: 'designSidebar',
    displayOrder: 7,
    translationKey: 'CustomDesign_TabLabel_Icon',
  },
  cover_image: {
    symbolName: 'coverImage',
    displayOrder: 0,
    translationKey: 'Custom.design_video.player_cover.Image_tab_title',
  },
};

const statesOrder = [
  STATES.REGULAR,
  STATES.SCROLLED,
  STATES.HOVER,
  STATES.SELECTED,
  STATES.ACTIVE,
  STATES.FOCUS,
  STATES.ERROR,
  STATES.DISABLED,
  STATES.CLOSED,
  STATES.OPEN,
];

export {
  statesOrder,
  categoriesData,
  horizontalTabsStateTranslatedKeys,
  statesDropdownTranslatedKeys,
};
