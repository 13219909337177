import constants from '@/constants';
import type {
  CompLayout,
  CompRef,
  CompStructure,
  SerializedCompStructure,
} from 'types/documentServices';
import type { Scope } from '../scope';

export type Proportion = number[][];

const getColumnDef = (
  scope: Scope,
  layout: Partial<CompLayout>,
): CompStructure => {
  const defaultColumnStructure =
    scope.editorAPI.components.buildDefaultComponentStructure(
      constants.COMP_TYPES.COLUMN,
    );

  defaultColumnStructure.layout = {
    ...defaultColumnStructure.layout,
    ...layout,
  };

  return defaultColumnStructure;
};

const getBlankStripStructure = (
  scope: Scope,
  stripLayout: { width: number; height: number; y: number },
  columnsProportion: number[],
): CompStructure => {
  const defaultStripStructure =
    scope.editorAPI.components.buildDefaultComponentStructure(
      constants.COMP_TYPES.STRIP_COLUMNS_CONTAINER,
    );

  defaultStripStructure.layout = {
    ...defaultStripStructure.layout,
    ...stripLayout,
  };

  defaultStripStructure.props = {
    ...defaultStripStructure.props,
    columnsMargin: 0,
    rowMargin: 0,
    siteMargin: 0,
  };

  defaultStripStructure.components = columnsProportion.map((proportion) => {
    const width = (stripLayout.width * proportion) / 100;
    return getColumnDef(scope, { width, height: stripLayout.height });
  }) as SerializedCompStructure[];

  return defaultStripStructure;
};

const mapStripsToFillSection = (
  scope: Scope,
  sectionLike: CompRef,
  proportions: Proportion,
) => {
  const { width, height } = scope.layout.get_size(sectionLike);
  const stripHeight = height / proportions.length;
  return proportions.map((proportion, i) =>
    getBlankStripStructure(
      scope,
      { width, height: stripHeight, y: i * stripHeight },
      proportion,
    ),
  );
};

export { mapStripsToFillSection };
