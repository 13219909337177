// @ts-nocheck
import _ from 'lodash';
import {
  editorDataFunction,
  componentInstanceData,
} from '@wix/editor-component-data-resolver';

const LABEL_SKIN_PARAMS = ['txtlbl', 'fntlbl', 'txtlblrq'];

export default {
  skinParamsToIgnore: editorDataFunction(
    componentInstanceData('componentData'),
    (componentData) => {
      const hasLabel = !_.isEmpty(componentData?.label);
      return hasLabel ? [] : LABEL_SKIN_PARAMS;
    },
  ),
  allowConnectToDB: true,
};
