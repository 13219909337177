import React, { type ComponentType } from 'react';
import _ from 'lodash';

import constants from '@/addPanelDataConsts';
import * as tpa from '@/tpa';
import * as util from '@/util';

import {
  mapDispatchToProps,
  mapStateToProps,
} from './eventsWelcomeSectionMapper';
import type {
  EventsWelcomeDispatchProps,
  EventsWelcomeOwnProps,
  EventsWelcomeStateProps,
} from './types';

const textContent = {
  promotionalList: [
    'SiteApp_Events_AddPanel_Bullet1_Empty_State',
    'SiteApp_Events_AddPanel_Bullet2_Empty_State',
    'SiteApp_Events_AddPanel_Bullet3_Empty_State',
    'SiteApp_Events_AddPanel_Bullet4_Empty_State',
  ],
  title: 'SiteApp_Events_AddPanel_Title_Empty_State',
  subtitle: 'SiteApp_Events_AddPanel_Subtitle_Empty_State',
  mainActionLabel: 'SiteApp_Events_AddPanel_Primary_Button_Empty_State',
  secondaryActionLabel: 'SiteApp_Events_AddPanel_Secondary_Link_Empty_State',
};

class EventsWelcomeSection extends React.Component<
  EventsWelcomeOwnProps & EventsWelcomeDispatchProps & EventsWelcomeStateProps
> {
  static displayName = 'EventsWelcomeSection';

  state = {
    text: textContent,
  };

  private isNewWorkspace: boolean = util.workspace.isNewWorkspaceEnabled();

  getOnMainActionClick = () => {
    if (_.isFunction(this.props.installAppOverride)) {
      return _.partial(
        this.props.installAppOverride,
        tpa.superApps.superAppsConstants.WIX_EVENTS.APP_DEF_ID,
        constants.APP_TYPE.TPA_WIDGET,
      );
    }
    return () =>
      this.props.installEventsApp({
        biParams: {
          addingMethod: 'click',
          category: this.props.categoryId,
          section: this.props.sectionName,
          // NOTE: we probably does not have presets here
          presetId: null,
        },
      });
  };

  getSecondaryAction = () => {
    return this.props.shouldOpenHelpCenter
      ? this.props.openHelpCenter
      : this.props.openAppMarket;
  };

  getBannerImgSrc() {
    let { bannerImage } = this.props;

    if (!bannerImage) {
      if (this.isNewWorkspace) {
        bannerImage = util.browserUtil.isRetina()
          ? 'addPanelData/sections/eventsWelcomeSection_en/newWorkspace_eventsWelcomeSection_en@x2.png'
          : 'addPanelData/sections/eventsWelcomeSection_en/newWorkspace_eventsWelcomeSection_en.png';
      } else {
        bannerImage =
          'addPanelData/sections/eventsWelcomeSection_en/eventsWelcomeSection_en.png';
      }
    }

    return util.media.getMediaUrl(bannerImage);
  }

  render() {
    const WelcomeSection = this.props.welcomeSectionTemplate;

    return (
      <div>
        <WelcomeSection
          bannerImgSrc={this.getBannerImgSrc()}
          title={this.state.text.title}
          subtitle={this.state.text.subtitle}
          promotionalListItems={this.state.text.promotionalList}
          mainActionLabel={this.state.text.mainActionLabel}
          secondaryActionLabel={this.state.text.secondaryActionLabel}
          onMainActionClick={this.getOnMainActionClick()}
          onSecondaryActionClick={this.getSecondaryAction()}
        />
      </div>
    );
  }
}

export default util.hoc.connect(
  util.hoc.STORES.EDITOR_API,
  mapStateToProps,
  mapDispatchToProps,
)(
  util.hoc.renderWhenMutated(EventsWelcomeSection),
) as ComponentType<EventsWelcomeOwnProps>;
