// @ts-nocheck
import constants from '@/constants';
import gfppDataUtils from '../../utils/gfppDataUtils';

const { ACTIONS } = constants.ROOT_COMPS.GFPP;
const INPUT_TAB_NAME = 'input';

export default {
  mainActions: [
    {
      label: 'gfpp_mainaction_date_picker',
      isApplied: true,
      isSelected: gfppDataUtils.getPanelStateFn(ACTIONS.SETTINGS),
      onClick: gfppDataUtils.getTogglePanelFn(ACTIONS.SETTINGS, {
        selectedTab: INPUT_TAB_NAME,
      }),
    },
  ],
  enabledActions: [
    ACTIONS.SETTINGS,
    ACTIONS.LAYOUT,
    ACTIONS.DESIGN,
    ACTIONS.ANIMATION,
    ACTIONS.HELP,
  ],
  mobileMainActions: [],
  mobileEnabledActions: [
    ACTIONS.SCALE_UP,
    ACTIONS.SCALE_DOWN,
    ACTIONS.LAYOUT,
    ACTIONS.HIDE,
    ACTIONS.HELP,
    ACTIONS.ANIMATION,
  ],
  presetActions: {
    help: {
      helpId: 'dfe09916-6b3f-44ee-bfc0-0cdd70795a39',
      mobileHelpId: '0592ed19-80ec-46f6-b43d-f984dc9e7f70',
    },
  },
};
