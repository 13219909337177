// @ts-nocheck
import * as helpIds from '@/helpIds';
import constants from '@/constants';
import * as coreBi from '@/coreBi';
import {
  manageMenuGfppAutomationId,
  openManageMenu,
} from '../../utils/menuUtils';
import { isCustomMenusEnabled } from '@/util';
import type { CompRef } from 'types/documentServices';
import type { EditorAPI } from '@/editorAPI';

const { ACTIONS } = constants.ROOT_COMPS.GFPP;

function openMenuPanel(editorAPI, compRef) {
  const menuData = editorAPI.components.data.get(compRef) || {
    menuRef: 'CUSTOM_MAIN_MENU',
  };
  const menuId = menuData.menuRef.id || menuData.menuRef;
  editorAPI.openPagesPanel({
    menuId: menuId.replace('#', ''),
    origin: 'gfpp_expandable_menu',
  });
  editorAPI.bi.event(coreBi.events.pages.click_on_pages_panel_open, {
    origin: 'gfpp_expandable_menu',
  });
}

function openQuickNavigation(editorAPI) {
  editorAPI.setForceOpenPagesQuickNavigationEventCounter();
  editorAPI.bi.event(coreBi.events.pages.quick_navigation_clicked, {
    origin: 'gfpp_expandable_menu',
  });
}

const manageMenu = {
  label: 'gfpp_mainaction_Vmenu',
  isApplied: true,
  isSelected: false,
  onClick: (editorAPI: EditorAPI, compRef: CompRef[]) =>
    isCustomMenusEnabled() || editorAPI?.temporaryAdvancedMenuAPI?.isAdvanced()
      ? openManageMenu(editorAPI, { selectedComponents: compRef })
      : openMenuPanel(editorAPI, compRef),
  automationId: manageMenuGfppAutomationId,
};

const mainActions = [
  manageMenu,
  {
    label: 'gfpp_secondaryaction_Vmenu',
    isApplied: true,
    isSelected: false,
    onClick(editorAPI, compRef) {
      openQuickNavigation(editorAPI, compRef);
    },
  },
];

export default {
  mobileMainActions: mainActions,
  mainActions,
  enabledActions: [
    ACTIONS.DESIGN,
    ACTIONS.LAYOUT,
    ACTIONS.ANIMATION,
    ACTIONS.HELP,
  ],
  mobileEnabledActions: [
    ACTIONS.DESIGN,
    ACTIONS.LAYOUT,
    ACTIONS.ANIMATION,
    ACTIONS.HIDE,
    ACTIONS.HELP,
  ],
  presetActions: {
    help: {
      helpId: helpIds.GFPP.EXPANDABLE_MENU,
      mobileHelpId: helpIds.GFPP.EXPANDABLE_MENU_MOBILE,
    },
  },
};
