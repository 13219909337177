import * as util from '@/util';
import { translate } from '@/i18n';
import * as coreBi from '@/coreBi';
import constants from '@/constants';
import pageSettingsConstants from '../../../utils/constants';
import * as customSignupUtils from '../../../utils/customSignup';
import type { EditorAPI } from '@/editorAPI';
import type {
  DSRead,
  PagesData,
  SignUpPrivacyNoteType,
} from 'types/documentServices';
import type { IPolicy, ISocialVendor } from './signupSettingsDefaultDesktop';
import type { Link } from 'types/documentServices';
import { createJoinCommunityStatusApi } from '../../../utils/joinCommunityStatusApi';
import type { JoinCommunityStatus } from '../../../utils/joinCommunityStatusApi';
import { memberPrivacySettingsFacade } from '@/serverFacade';
import { sendNavigateToPageBiEvent } from '@/pages';
import { createSocialLoginStatusApi } from '../../../utils/socialLoginStatusApi';
import { MEMBERS_AREA } from '@wix/app-definition-ids';

export interface ISettingsData {
  tabType: string;
  pageId: string;
  formType?: 'signUp' | 'signIn';
  menuId?: string;
}

interface AuthMapperProps {
  editorAPI: EditorAPI;
  dsRead: DSRead;
  authMethod: IAuthMethod;
  getThisMethodCustomAuthPageId: () => string;
  getSecondMethodCustomAuthPageId: () => string;
  APP_DEF_IDS: {
    THIS_APP_DEF_ID: string;
    SECOND_APP_DEF_ID: string;
  };
  bi_quick_actions_menu_select_source: string;
  bi_panel_name: string;
  translationKeys: { [key: string]: string };
  theOtherAuthMethod: {
    getTheOtherCustomAuthPageId: () => string;
  };
  navigateToVeloPanel: {
    navigate: (settingsData: Partial<ISettingsData>) => void;
    shouldNavigateToVeloPanel: () => boolean;
  };
}

const {
  AUTH_VALUES,
  AUTO_APPROVAL,
  LOGIN_FIRST_OPTIONS,
  SIGNUP_POLICIES,
  PRIVACY_NOTE_TYPE_OPTIONS,
} = customSignupUtils;

const MY_ACCOUNT_BASE_URL = '//www.wix.com/dashboard/';
const FORM_CONTAINER_TYPE = 'platform.components.AppWidget';

export const AUTH_METHODS = {
  SIGN_IN: 'signIn',
  SIGN_UP: 'signUp',
} as const;
export type IAuthMethod = typeof AUTH_METHODS[keyof typeof AUTH_METHODS];

const getNavigateTo = (
  editorAPI: EditorAPI,
  popupId: string,
  authMethod: IAuthMethod,
) => {
  if (popupId && editorAPI.dsRead.pages.getFocusedPageId() !== popupId) {
    editorAPI.pages.navigateTo(popupId);
    sendNavigateToPageBiEvent(editorAPI, {
      pageId: popupId,
      panelName: authMethod === AUTH_METHODS.SIGN_UP ? 'signup' : 'signIn',
      biCategory: 'lightbox',
    });
  }
};

const getAppAPI = (
  editorAPI: EditorAPI,
  appData: AnyFixMe,
  callback: (params: any) => void,
) =>
  editorAPI.dsRead.platform
    .getAppPrivateApi(appData.appDefinitionId)
    .then(callback)
    .catch(() => callback({ Error: 'Application API not found.' }));

export const findCompRef = (
  editorAPI: EditorAPI,
  ref: AnyFixMe,
  type: string,
) =>
  ref
    ? editorAPI.components
        .getChildren_DEPRECATED_BAD_PERFORMANCE(ref, true)
        .find((child) => type === editorAPI.components.getType(child))
    : null;

const getAuthMethodCurrentState =
  (
    _getCustomAuthPageId: () => string,
    _findManagedCustomAuth: () => string,
    shouldNavigateToVeloPanel: () => boolean,
  ) =>
  () => {
    if (shouldNavigateToVeloPanel()) {
      return AUTH_VALUES.DEV;
    }
    const customAuthPageId = _getCustomAuthPageId();

    if (!customAuthPageId) {
      return AUTH_VALUES.DEFAULT;
    }

    return _findManagedCustomAuth() === customAuthPageId
      ? AUTH_VALUES.CUSTOM
      : AUTH_VALUES.DEV;
  };

const findManagedCustomAuth = (pages: PagesData[], appDefId: string) => () => {
  return pages.find((page) => page.managingAppDefId === appDefId)?.id;
};

export const authMapper = ({
  editorAPI,
  dsRead,
  authMethod,
  getThisMethodCustomAuthPageId,
  getSecondMethodCustomAuthPageId,
  APP_DEF_IDS: { THIS_APP_DEF_ID, SECOND_APP_DEF_ID },
  bi_quick_actions_menu_select_source,
  bi_panel_name,
  translationKeys: { customTitle, developerTitle, defaultTitle },
  theOtherAuthMethod: { getTheOtherCustomAuthPageId },
  navigateToVeloPanel: { navigate, shouldNavigateToVeloPanel },
}: AuthMapperProps) => {
  let isBackendLoginOnlyOnState: boolean;
  const isBackendLoginOnlyOn = (): Promise<boolean> =>
    new Promise<boolean>((resolve, reject) => {
      return isBackendLoginOnlyOnState !== undefined
        ? resolve(isBackendLoginOnlyOnState)
        : editorAPI.siteMembers.isBackendLoginOnlyEnabled(resolve, reject);
    }).then((isOn) => (isBackendLoginOnlyOnState = isOn));
  isBackendLoginOnlyOn();
  const biEvent = editorAPI.bi.event;
  const pagesList = editorAPI.pages.popupPages.getDataList();
  const findThisManagedMethodCustomAuth = findManagedCustomAuth(
    pagesList,
    THIS_APP_DEF_ID,
  );
  const findSecondManagedMethodCustomAuth = findManagedCustomAuth(
    pagesList,
    SECOND_APP_DEF_ID,
  );
  const socialLoginStatusApi = createSocialLoginStatusApi(editorAPI);

  const getThisAuthMethodCurrentState = getAuthMethodCurrentState(
    getThisMethodCustomAuthPageId,
    findThisManagedMethodCustomAuth,
    shouldNavigateToVeloPanel,
  );
  const getSecondAuthMethodCurrentState = getAuthMethodCurrentState(
    getSecondMethodCustomAuthPageId,
    findSecondManagedMethodCustomAuth,
    shouldNavigateToVeloPanel,
  );

  const getTitle = () => {
    switch (getThisAuthMethodCurrentState()) {
      case AUTH_VALUES.CUSTOM:
        return translate(customTitle);
      case AUTH_VALUES.DEV:
        return translate(developerTitle);
      case AUTH_VALUES.DEFAULT:
        return translate(defaultTitle);
    }
  };

  const { pages } = coreBi.events.topbar;
  const { event } = editorAPI.bi;

  const hosting = 'editor';
  const sendBiEvent = {
    selectCustomSignupType: (selection: string) => {
      if (authMethod === AUTH_METHODS.SIGN_UP) {
        event(pages.custom_signup_type_selection, { hosting, selection });
      } else {
        event(coreBi.events.pages.login_type_selection, {
          selection,
          has_custom_signup: !!getTheOtherCustomAuthPageId(),
        });
      }
    },
    clickOnSaveSettings: () => event(pages.custom_signup_panel_save),
    clickOnSaveLinkPanel: () =>
      event(pages.custom_signup_panel_link_panel_save),
    clickOnConnectLink: (action: 'set-link' | 'click') =>
      event(pages.custom_signup_connect_link, {
        hosting,
        action,
        name: 'wixCodeFormLink',
        type: 'lightbox',
      }),
    clickOnCancelLinkPanel: () =>
      event(pages.custom_signup_panel_link_panel_cancel),
    clickOnLinkButton: () => event(pages.custom_signup_panel_link_panel_open),
    toggleLoginDialogFirst: (toggle: boolean) => {
      event(coreBi.events.signup.set_login_dialog_first, {
        hosting,
        selection: toggle ? 'existing member login' : 'new member signup',
      });
      event(coreBi.events.pages.toggle_what_auth_presenting_first, {
        first_option: toggle ? 'login' : 'signup',
      });
    },
    toggleAutoApprove: (toggle: boolean) =>
      event(coreBi.events.signup.set_auto_approval, {
        hosting,
        selection: toggle ? 'everyone' : 'people i approve',
      }),
    toggleSetSocialLogin: (
      toggle_state: boolean,
      vendor: ISocialVendor | 'community',
    ) =>
      event(coreBi.events.signup.set_social_login, {
        hosting,
        platform: vendor,
        toggle_state,
      }),
    toggleShowPolicyLink: (type: IPolicy, toggle_state: boolean) =>
      event(coreBi.events.signup.toggle_policy, {
        hosting,
        type,
        toggle_state,
      }),
    setPolicyLink: (policy: IPolicy, { type }: Link) =>
      event(coreBi.events.signup.link_to_policy, {
        name: policy,
        action: 'set-link',
        type,
      }),
  };

  return {
    sendBiEvent: () => sendBiEvent,
    getCustomSignupPageId: getThisMethodCustomAuthPageId,
    findManagedCustomSignup: findThisManagedMethodCustomAuth,
    isSiteSaved: () => !editorAPI.dsRead.generalInfo.isFirstSave(),
    getJoinCommunityStatus: async (): Promise<JoinCommunityStatus> =>
      createJoinCommunityStatusApi(editorAPI).getJoinCommunityStatus(),
    setJoinCommunityStatus: async (
      joinCommunity: boolean,
      revision?: string,
    ): Promise<JoinCommunityStatus> => {
      const joinCommunityStatus = await createJoinCommunityStatusApi(
        editorAPI,
      ).setJoinCommunityStatus(joinCommunity, revision);
      sendBiEvent.toggleSetSocialLogin(joinCommunity, 'community');
      return joinCommunityStatus;
    },
    sendBi: biEvent,
    title: getTitle(),
    isAutoApproval: () => editorAPI.siteMembers.isAutoApproval(),
    isLoginDialogFirst: () => editorAPI.siteMembers.isLoginDialogFirst(),
    setAutoApproval: (val: boolean) => {
      editorAPI.siteMembers.setAutoApproval(val);
      sendBiEvent.toggleAutoApprove(val);
    },
    getAutoApprovalOptions: () => AUTO_APPROVAL,
    getLoginFirstOptions: () => LOGIN_FIRST_OPTIONS,
    privacyNoteType: () => {
      return editorAPI.siteMembers.getPrivacyNoteType();
    },
    setPrivacyNoteType: (privacyNoteType: SignUpPrivacyNoteType) =>
      editorAPI.siteMembers.setPrivacyNoteType(privacyNoteType),
    getPrivacyNoteTypeOptions: () => PRIVACY_NOTE_TYPE_OPTIONS,
    setLoginDialogFirst: (val: boolean) => {
      editorAPI.siteMembers.setLoginDialogFirst(val);
      sendBiEvent.toggleLoginDialogFirst(val);
    },
    getSocialLoginStatuses: async () => {
      return socialLoginStatusApi.getSocialLoginStatus();
    },
    setSocialLogin: async (vendor: ISocialVendor, val: boolean) => {
      await socialLoginStatusApi.setSocialLoginStatus(vendor, val);
      sendBiEvent.toggleSetSocialLogin(val, vendor);
    },
    isBackendLoginOnlyOn,
    setBackendLoginOnlyStatus: (toggle: boolean) => {
      isBackendLoginOnlyOnState = toggle;
      return new Promise((resolve, reject) =>
        editorAPI.siteMembers.setBackendLoginOnlyStatus(
          toggle,
          resolve,
          reject,
        ),
      );
    },
    isSignupPoliciesFieldEnabled: (policy: IPolicy) =>
      editorAPI.siteMembers.isSignupPoliciesFieldEnabled(policy),
    setSignupPoliciesFieldStatus: (policy: IPolicy, value: boolean) => {
      editorAPI.siteMembers.setSignupPoliciesFieldStatus(policy, value);
      sendBiEvent.toggleShowPolicyLink(policy, value);
    },
    getSignupPoliciesLink: (policy: IPolicy) =>
      editorAPI.siteMembers.getSignupPoliciesLink(policy),
    setSignupPoliciesLink: (policy: IPolicy, link: Link) => {
      editorAPI.siteMembers.setSignupPoliciesLink(policy, link);
      sendBiEvent.setPolicyLink(policy, link);
    },
    isTermsOfUseLinkVisible: () =>
      editorAPI.siteMembers.isSignupPoliciesFieldEnabled(
        SIGNUP_POLICIES.TERMS_OF_USE,
      ),
    isPrivacyPolicyLinkVisible: () =>
      editorAPI.siteMembers.isSignupPoliciesFieldEnabled(
        SIGNUP_POLICIES.PRIVACY,
      ),
    isCodeOfConductLinkVisible: () =>
      editorAPI.siteMembers.isSignupPoliciesFieldEnabled(
        SIGNUP_POLICIES.CODE_OF_CONDUCT,
      ),
    termsOfUseLink: () =>
      editorAPI.siteMembers.getSignupPoliciesLink(SIGNUP_POLICIES.TERMS_OF_USE),
    privacyPolicyLink: () =>
      editorAPI.siteMembers.getSignupPoliciesLink(SIGNUP_POLICIES.PRIVACY),
    codeOfConductLink: () =>
      editorAPI.siteMembers.getSignupPoliciesLink(
        SIGNUP_POLICIES.CODE_OF_CONDUCT,
      ),
    getPolicyLinkData: (policy: string) =>
      editorAPI.data.getById(policy.replace('#', '')),
    getLabelForPolicyPageFromId: (policy: string) =>
      util.link.getLinkValueAsString(
        editorAPI,
        editorAPI.data.getById(policy.replace('#', '')),
      ),
    getLabelForPolicyPageFromLink: (policyLink: Link) =>
      util.link.getLinkValueAsString(editorAPI, policyLink),
    openLinkPanel: (params: any) => {
      editorAPI.openLinkPanel(params);
      editorAPI.bi.event(coreBi.events.signup.link_to_policy, {
        name: params.policy,
        action: 'click',
      });
    },
    openHelpPanel: (helpId: string) => {
      const biHelpParams = {
        learn_more: false,
        panel_name: bi_panel_name,
        origin: constants.BI.HELP.ORIGIN.PAGES,
      };
      editorAPI.panelManager.openHelpCenter(
        helpId || pageSettingsConstants.AUTH_SETTINGS_PANEL_HELP_ID[authMethod],
        null,
        biHelpParams,
      );
    },
    navigateToCustomSignup: () =>
      getNavigateTo(editorAPI, getThisMethodCustomAuthPageId(), authMethod),
    hasCustomSignup: () =>
      editorAPI.pages.popupPages.isPopup(getThisMethodCustomAuthPageId()),
    isPageExists: (pageId: string) => editorAPI.data.getById(pageId),
    focusOnRegistrationForm: () => {
      const popupId = getThisMethodCustomAuthPageId();
      editorAPI.panelManager.closeAllPanels();
      editorAPI.pages.navigateTo(popupId, () => {
        const currentPopup = editorAPI.pages.popupPages.getCurrentPopup();
        editorAPI.selection.selectComponentByCompRef(
          findCompRef(editorAPI, currentPopup, FORM_CONTAINER_TYPE),
        );
      });
      editorAPI.bi.event(
        coreBi.events.pages.pagesPanel.quick_actions_menu_select,
        {
          page_id: popupId,
          source: bi_quick_actions_menu_select_source,
          category: 'edit',
          type: 'page',
        },
      );
      if (authMethod === AUTH_METHODS.SIGN_IN) {
        editorAPI.bi.event(coreBi.events.pages.login_edit_clicked, {
          origin: 'menu',
        });
      }
    },
    getCurrentSignupState: getThisAuthMethodCurrentState,
    getSiteMembersUrl: () => {
      const metaSiteId = dsRead.generalInfo.getMetaSiteId();
      return `${MY_ACCOUNT_BASE_URL}${metaSiteId}/member-permissions/members/`;
    },
    getCustomSignupLinkFromId: (customSignupPageId: string) => {
      const link = dsRead.data.createItem('PageLink');
      if (customSignupPageId) {
        link.pageId = `#${customSignupPageId}`;
      }
      return link;
    },
    getCustomSignupPageLabel: (pageId: string) => {
      if (!pageId || !dsRead.data.getById(pageId)) {
        return translate(
          'Pages_Custom_Signup_Settings_Custom_Signup_Link_Action_Placeholder',
        );
      }

      const pageData = dsRead.data.getById(pageId);
      return pageData?.title ?? '';
    },
    isDeveloperModeEnabled: () => editorAPI.developerMode.isEnabled(),
    isSocialAppInstalled: () => {
      const appData = dsRead.tpa.app.getDataByAppDefId(MEMBERS_AREA);
      return appData
        ? getAppAPI(editorAPI, appData, (api) => api.hasSocialPages())
        : Promise.resolve(false);
    },
    canHavePublicMembers: () => {
      const metaSiteInstance =
        editorAPI.dsRead.platform.getAppDataByApplicationId(
          constants.APPLICATIONS.META_SITE_APPLICATION_ID,
        )?.instance;

      return memberPrivacySettingsFacade.canHavePublicMembers(metaSiteInstance);
    },
    shouldBackendLoginOnlyDisabled: (
      thisAuthMethodCurrentState: string = getThisAuthMethodCurrentState(),
    ) => {
      return (
        getSecondAuthMethodCurrentState() !== AUTH_VALUES.DEV ||
        thisAuthMethodCurrentState !== AUTH_VALUES.DEV
      );
    },
    navigateToVeloPanel: () => {
      navigate({
        pageId: null,
        tabType: 'page',
        formType:
          authMethod === AUTH_METHODS.SIGN_UP
            ? AUTH_METHODS.SIGN_IN
            : AUTH_METHODS.SIGN_UP,
        menuId: AUTH_VALUES.DEV,
      });
    },
    openDashboard: () => {
      editorAPI.account.openSettings({
        path: '/member-permissions/signup-login-settings',
      });
    },
  };
};
