{
  "wysiwyg.viewer.skins.mobile.TinyMenuSkin": {
    "params": {
      "bg": {
        "state": "closed",
        "label": "CustomDesign_TinyMenu_Button_Background"
      },
      "iconcolor": {
        "state": "closed",
        "label": "CustomDesign_TinyMenu_Icons"
      },
      "iconcolorSelected": {
        "state": "open",
        "label": "CustomDesign_TinyMenu_Icons",
        "priority": "1"
      },
      "bgs": {
        "state": "open",
        "label": "CustomDesign_TinyMenu_Selected_Page_Background",
        "priority": "2",
        "alias": "bgs-old"
      },
      "bgOpen": {
        "state": "open",
        "category": "fill",
        "label": "CustomDesign_TinyMenu_Button_Background",
        "section": "",
        "priority": "2"
      },
      "txts": {
        "state": "open",
        "label": "CustomDesign_TinyMenu_Selected_Page_Text",
        "alias": "txts-old"
      },
      "strokewidth": {
        "alias": "strokewidth",
        "state": "closed",
        "label": "CustomDesign_TinyMenu_Line Stroke",
        "min": 1,
        "max": 3,
        "priority": 1
      },
      "linewidth": {
        "state": "closed",
        "alias": "linewidth",
        "label": "CustomDesign_TinyMenu_Line_Width",
        "min": 15,
        "max": 26,
        "priority": 2
      },
      "borderwidth": {
        "state": "closed",
        "min": 0,
        "max": 3
      },
      "borderWidthSelected": {
        "state": "open",
        "min": 0,
        "max": 3
      },
      "borderColorSelected": {
        "state": "open"
      },
      "separatorWidthFull": {
        "label": "CustomDesign_TinyMenu_Separator_Length",
        "category": "other",
        "state": "open",
        "min": 0,
        "max": 320,
        "alias": "separatorWidthFull",
        "priority": 4
      },
      "separatorWidth": {
        "label": "CustomDesign_TinyMenu_Separator_Length",
        "alias": "separatorWidth",
        "state": "open",
        "category": "other",
        "min": 0,
        "max": 156,
        "priority": 4
      },
      "bgDrop": {
        "label": "CustomDesign_TinyMenu_Menu_Background",
        "state": "open",
        "priority": 3
      },
      "separatorHeight": {
        "label": "CustomDesign_TinyMenu_Separator_Width",
        "state": "open",
        "category": "other",
        "min": 0,
        "alias": "separatorHeight",
        "max": 3,
        "priority": 5
      },
      "separatorColor": {
        "state": "open",
        "category": "other",
        "label": "CustomDesign_TinyMenu_SeparatorColor",
        "section": "",
        "priority": 3
      },
      "rd": {
        "state": "closed",
        "alias": "rd"
      },
      "bordercolor": {
        "state": "closed"
      },
      "txt": {
        "state": "open"
      },
      "fnt": {
        "state": "open",
        "alias": "fnt"
      },
      "textAlignment": {
        "state": "open"
      },
      "fillcolor": {
        "state": "open",
        "label": "CustomDesign_Fill_Color_LoginSocialBar_Svg",
        "priority": 4
      },
      "badge-bg": {
        "state": "open",
        "label": "CustomDesign_Fill_Color_LoginSocialBar_Badge",
        "priority": 5
      },
      "badge-txt": {
        "state": "open",
        "category": "fill",
        "label": "CustomDesign_Text_Color_LoginSocialBar_Badge",
        "priority": 6
      },
      "counterColor": {
        "state": "open",
        "priority": 7
      },
      "fixStrokeBlur": {
        "state": "open",
        "priority": 8
      },
      "iconcolorselected": {
        "hide": true
      }
    },
    "defaultState": "open"
  },
  "wysiwyg.viewer.skins.mobile.TinyMenuFullScreenSkin": {
    "params": {
      "bg": {
        "state": "closed",
        "label": "CustomDesign_TinyMenu_Button_Background"
      },
      "iconcolor": {
        "state": "closed",
        "label": "CustomDesign_TinyMenu_Icons"
      },
      "iconcolorSelected": {
        "state": "open",
        "label": "CustomDesign_TinyMenu_Icons",
        "priority": "1"
      },
      "bgs": {
        "state": "open",
        "category": "fill",
        "label": "CustomDesign_TinyMenu_Selected_Page_Background",
        "section": "",
        "priority": "2"
      },
      "bgOpen": {
        "state": "open",
        "label": "CustomDesign_TinyMenu_Button_Background",
        "priority": "2"
      },
      "txts": {
        "state": "open",
        "label": "CustomDesign_TinyMenu_Selected_Page_Text",
        "alias": "highlight"
      },
      "strokewidth": {
        "alias": "strokewidth",
        "state": "closed",
        "label": "CustomDesign_TinyMenu_Line Stroke",
        "min": 1,
        "max": 3,
        "priority": 1
      },
      "linewidth": {
        "state": "closed",
        "alias": "linewidth",
        "label": "CustomDesign_TinyMenu_Line_Width",
        "min": 15,
        "max": 26,
        "priority": 2
      },
      "borderwidth": {
        "state": "closed",
        "min": 0,
        "max": 3
      },
      "borderWidthSelected": {
        "state": "open",
        "min": 0,
        "max": 3
      },
      "borderColorSelected": {
        "state": "open"
      },
      "separatorWidthFull": {
        "label": "CustomDesign_TinyMenu_Separator_Length",
        "category": "other",
        "state": "open",
        "min": 0,
        "max": 320,
        "alias": "separatorWidthFull",
        "priority": 4
      },
      "separatorWidth": {
        "label": "CustomDesign_TinyMenu_Separator_Length",
        "alias": "separatorWidth",
        "state": "open",
        "category": "other",
        "min": 0,
        "max": 156,
        "priority": 4
      },
      "bgDrop": {
        "label": "CustomDesign_TinyMenu_Menu_Background",
        "state": "open",
        "priority": 3
      },
      "separatorHeight": {
        "label": "CustomDesign_TinyMenu_Separator_Width",
        "state": "open",
        "category": "other",
        "min": 0,
        "alias": "separatorHeight",
        "max": 3,
        "priority": 5
      },
      "separatorColor": {
        "state": "open",
        "category": "other",
        "label": "CustomDesign_TinyMenu_SeparatorColor",
        "priority": 3
      },
      "rd": {
        "state": "closed",
        "alias": "rd"
      },
      "bordercolor": {
        "state": "closed"
      },
      "txt": {
        "state": "open"
      },
      "fnt": {
        "state": "open",
        "alias": "fnt"
      },
      "textAlignment": {
        "state": "open"
      },
      "fillcolor": {
        "state": "open",
        "label": "CustomDesign_Fill_Color_LoginSocialBar_Svg",
        "priority": 4
      },
      "badge-bg": {
        "state": "open",
        "label": "CustomDesign_Fill_Color_LoginSocialBar_Badge",
        "priority": 5
      },
      "badge-txt": {
        "state": "open",
        "category": "fill",
        "label": "CustomDesign_Text_Color_LoginSocialBar_Badge",
        "priority": 6
      },
      "counterColor": {
        "state": "open",
        "priority": 7
      },
      "fixStrokeBlur": {
        "state": "open",
        "priority": 8
      }
    },
    "defaultState": "open"
  },
  "wysiwyg.viewer.skins.mobile.TinyMenuPullFromLeftSkin": {
    "params": {
      "bg": {
        "state": "closed",
        "label": "CustomDesign_TinyMenu_Button_Background"
      },
      "iconcolor": {
        "state": "closed",
        "label": "CustomDesign_TinyMenu_Icons"
      },
      "iconcolorSelected": {
        "state": "open",
        "label": "CustomDesign_TinyMenu_Icons",
        "priority": "1"
      },
      "bgs": {
        "state": "open",
        "category": "fill",
        "label": "CustomDesign_TinyMenu_Selected_Page_Background",
        "section": "",
        "priority": "2"
      },
      "bgOpen": {
        "state": "open",
        "label": "CustomDesign_TinyMenu_Button_Background",
        "priority": "2"
      },
      "txts": {
        "state": "open",
        "label": "CustomDesign_TinyMenu_Selected_Page_Text",
        "alias": "highlight"
      },
      "strokewidth": {
        "alias": "strokewidth",
        "state": "closed",
        "label": "CustomDesign_TinyMenu_Line Stroke",
        "min": 1,
        "max": 3,
        "priority": 1
      },
      "linewidth": {
        "state": "closed",
        "alias": "linewidth",
        "label": "CustomDesign_TinyMenu_Line_Width",
        "min": 15,
        "max": 26,
        "priority": 2
      },
      "borderwidth": {
        "state": "closed",
        "min": 0,
        "max": 3
      },
      "borderWidthSelected": {
        "state": "open",
        "min": 0,
        "max": 3
      },
      "borderColorSelected": {
        "state": "open"
      },
      "separatorWidthFull": {
        "label": "CustomDesign_TinyMenu_Separator_Length",
        "category": "other",
        "state": "open",
        "min": 0,
        "max": 320,
        "alias": "separatorWidthFull",
        "priority": 4
      },
      "separatorWidth": {
        "label": "CustomDesign_TinyMenu_Separator_Length",
        "alias": "separatorWidth",
        "state": "open",
        "category": "other",
        "min": 0,
        "max": 156,
        "priority": 4
      },
      "bgDrop": {
        "label": "CustomDesign_TinyMenu_Menu_Background",
        "state": "open",
        "priority": 3
      },
      "separatorHeight": {
        "label": "CustomDesign_TinyMenu_Separator_Width",
        "state": "open",
        "category": "other",
        "min": 0,
        "alias": "separatorHeight",
        "max": 3,
        "priority": 5
      },
      "separatorColor": {
        "state": "open",
        "category": "other",
        "label": "CustomDesign_TinyMenu_SeparatorColor",
        "priority": 3
      },
      "rd": {
        "state": "closed",
        "alias": "rd"
      },
      "bordercolor": {
        "state": "closed"
      },
      "txt": {
        "state": "open"
      },
      "fnt": {
        "state": "open",
        "alias": "fnt"
      },
      "textAlignment": {
        "state": "open"
      },
      "fillcolor": {
        "state": "open",
        "label": "CustomDesign_Fill_Color_LoginSocialBar_Svg",
        "priority": 4
      },
      "badge-bg": {
        "state": "open",
        "label": "CustomDesign_Fill_Color_LoginSocialBar_Badge",
        "priority": 5
      },
      "badge-txt": {
        "state": "open",
        "category": "fill",
        "label": "CustomDesign_Text_Color_LoginSocialBar_Badge",
        "priority": 6
      },
      "counterColor": {
        "state": "open",
        "priority": 7
      },
      "fixStrokeBlur": {
        "state": "open",
        "priority": 8
      }
    },
    "defaultState": "open"
  },
  "wysiwyg.viewer.skins.mobile.TinyMenuPullFromTopSkin": {
    "params": {
      "bg": {
        "state": "closed",
        "label": "CustomDesign_TinyMenu_Button_Background"
      },
      "iconcolor": {
        "state": "closed",
        "label": "CustomDesign_TinyMenu_Icons"
      },
      "iconcolorSelected": {
        "state": "open",
        "label": "CustomDesign_TinyMenu_Icons",
        "priority": "1"
      },
      "bgs": {
        "state": "open",
        "category": "fill",
        "label": "CustomDesign_TinyMenu_Selected_Page_Background",
        "section": "",
        "priority": "2"
      },
      "bgOpen": {
        "state": "open",
        "label": "CustomDesign_TinyMenu_Button_Background",
        "priority": "2"
      },
      "txts": {
        "state": "open",
        "label": "CustomDesign_TinyMenu_Selected_Page_Text",
        "alias": "highlight"
      },
      "strokewidth": {
        "alias": "strokewidth",
        "state": "closed",
        "label": "CustomDesign_TinyMenu_Line Stroke",
        "min": 1,
        "max": 3,
        "priority": 1
      },
      "linewidth": {
        "state": "closed",
        "alias": "linewidth",
        "label": "CustomDesign_TinyMenu_Line_Width",
        "min": 15,
        "max": 26,
        "priority": 2
      },
      "borderwidth": {
        "state": "closed",
        "min": 0,
        "max": 3
      },
      "borderWidthSelected": {
        "state": "open",
        "min": 0,
        "max": 3
      },
      "borderColorSelected": {
        "state": "open"
      },
      "separatorWidthFull": {
        "label": "CustomDesign_TinyMenu_Separator_Length",
        "category": "other",
        "state": "open",
        "min": 0,
        "max": 320,
        "alias": "separatorWidthFull",
        "priority": 4
      },
      "separatorWidth": {
        "label": "CustomDesign_TinyMenu_Separator_Length",
        "alias": "separatorWidth",
        "state": "open",
        "category": "other",
        "min": 0,
        "max": 156,
        "priority": 4
      },
      "bgDrop": {
        "label": "CustomDesign_TinyMenu_Menu_Background",
        "state": "open",
        "priority": 3
      },
      "separatorHeight": {
        "label": "CustomDesign_TinyMenu_Separator_Width",
        "state": "open",
        "category": "other",
        "min": 0,
        "alias": "separatorHeight",
        "max": 3,
        "priority": 5
      },
      "separatorColor": {
        "state": "open",
        "category": "other",
        "label": "CustomDesign_TinyMenu_SeparatorColor",
        "priority": 3
      },
      "rd": {
        "state": "closed",
        "alias": "rd"
      },
      "bordercolor": {
        "state": "closed"
      },
      "txt": {
        "state": "open"
      },
      "fnt": {
        "state": "open",
        "alias": "fnt"
      },
      "textAlignment": {
        "state": "open"
      },
      "fillcolor": {
        "state": "open",
        "label": "CustomDesign_Fill_Color_LoginSocialBar_Svg",
        "priority": 4
      },
      "badge-bg": {
        "state": "open",
        "label": "CustomDesign_Fill_Color_LoginSocialBar_Badge",
        "priority": 5
      },
      "badge-txt": {
        "state": "open",
        "category": "fill",
        "label": "CustomDesign_Text_Color_LoginSocialBar_Badge",
        "priority": 6
      },
      "counterColor": {
        "state": "open",
        "priority": 7
      },
      "fixStrokeBlur": {
        "state": "open",
        "priority": 8
      }
    },
    "defaultState": "open"
  },
  "wysiwyg.viewer.skins.mobile.TinyMenuPullFromRightSkin": {
    "params": {
      "bg": {
        "state": "closed",
        "label": "CustomDesign_TinyMenu_Button_Background"
      },
      "iconcolor": {
        "state": "closed",
        "label": "CustomDesign_TinyMenu_Icons"
      },
      "iconcolorSelected": {
        "state": "open",
        "label": "CustomDesign_TinyMenu_Icons",
        "priority": "1"
      },
      "bgs": {
        "state": "open",
        "category": "fill",
        "label": "CustomDesign_TinyMenu_Selected_Page_Background",
        "section": "",
        "priority": "2"
      },
      "bgOpen": {
        "state": "open",
        "label": "CustomDesign_TinyMenu_Button_Background",
        "priority": "2"
      },
      "txts": {
        "state": "open",
        "label": "CustomDesign_TinyMenu_Selected_Page_Text",
        "alias": "highlight"
      },
      "strokewidth": {
        "alias": "strokewidth",
        "state": "closed",
        "label": "CustomDesign_TinyMenu_Line Stroke",
        "min": 1,
        "max": 3,
        "priority": 1
      },
      "linewidth": {
        "state": "closed",
        "alias": "linewidth",
        "label": "CustomDesign_TinyMenu_Line_Width",
        "min": 15,
        "max": 26,
        "priority": 2
      },
      "borderwidth": {
        "state": "closed",
        "min": 0,
        "max": 3
      },
      "borderWidthSelected": {
        "state": "open",
        "min": 0,
        "max": 3
      },
      "borderColorSelected": {
        "state": "open"
      },
      "separatorWidthFull": {
        "label": "CustomDesign_TinyMenu_Separator_Length",
        "category": "other",
        "state": "open",
        "min": 0,
        "max": 320,
        "alias": "separatorWidthFull",
        "priority": 4
      },
      "separatorWidth": {
        "label": "CustomDesign_TinyMenu_Separator_Length",
        "alias": "separatorWidth",
        "state": "open",
        "category": "other",
        "min": 0,
        "max": 156,
        "priority": 4
      },
      "bgDrop": {
        "label": "CustomDesign_TinyMenu_Menu_Background",
        "state": "open",
        "priority": 3
      },
      "separatorHeight": {
        "label": "CustomDesign_TinyMenu_Separator_Width",
        "state": "open",
        "category": "other",
        "min": 0,
        "alias": "separatorHeight",
        "max": 3,
        "priority": 5
      },
      "separatorColor": {
        "state": "open",
        "category": "other",
        "label": "CustomDesign_TinyMenu_SeparatorColor",
        "priority": 3
      },
      "rd": {
        "state": "closed",
        "alias": "rd"
      },
      "bordercolor": {
        "state": "closed"
      },
      "txt": {
        "state": "open"
      },
      "fnt": {
        "state": "open",
        "alias": "fnt"
      },
      "textAlignment": {
        "state": "open"
      },
      "fillcolor": {
        "state": "open",
        "label": "CustomDesign_Fill_Color_LoginSocialBar_Svg",
        "priority": 4
      },
      "badge-bg": {
        "state": "open",
        "label": "CustomDesign_Fill_Color_LoginSocialBar_Badge",
        "priority": 5
      },
      "badge-txt": {
        "state": "open",
        "category": "fill",
        "label": "CustomDesign_Text_Color_LoginSocialBar_Badge",
        "priority": 6
      },
      "counterColor": {
        "state": "open",
        "priority": 7
      },
      "fixStrokeBlur": {
        "state": "open",
        "priority": 8
      }
    },
    "defaultState": "open"
  },
  "wysiwyg.viewer.skins.inlinePopupToggle.inlinePopupToggleSkin12": {
    "params": {
      "lineColor": {
        "state": "closed",
        "label": "CustomDesign_Mobile_Menu_Icon"
      },
      "lineColorOpen": {
        "state": "open",
        "label": "CustomDesign_Mobile_Menu_Icon"
      },
      "bg": {
        "state": "closed",
        "label": "CustomDesign_Mobile_Menu_Button_Background"
      },
      "borderwidth": {
        "state": "closed"
      },
      "bordercolor": {
        "state": "closed"
      },
      "rd": {
        "state": "closed"
      },
      "shd": {
        "state": "closed"
      },
      "bgOpen": {
        "state": "open",
        "label": "CustomDesign_Mobile_Menu_Button_Background"
      },
      "borderwidthOpen": {
        "state": "open"
      },
      "bordercolorOpen": {
        "state": "open"
      },
      "rdOpen": {
        "state": "open"
      },
      "shdOpen": {
        "state": "open"
      }
    },
    "defaultState": "open"
  },
  "wysiwyg.viewer.skins.inlinePopupToggle.inlinePopupToggleSkin10": {
    "params": {
      "lineColor": {
        "state": "closed",
        "label": "CustomDesign_Mobile_Menu_Icon"
      },
      "lineColorOpen": {
        "state": "open",
        "label": "CustomDesign_Mobile_Menu_Icon"
      },
      "bg": {
        "state": "closed",
        "label": "CustomDesign_Mobile_Menu_Button_Background"
      },
      "borderwidth": {
        "state": "closed"
      },
      "bordercolor": {
        "state": "closed"
      },
      "rd": {
        "state": "closed"
      },
      "shd": {
        "state": "closed"
      },
      "bgOpen": {
        "state": "open",
        "label": "CustomDesign_Mobile_Menu_Button_Background"
      },
      "borderwidthOpen": {
        "state": "open"
      },
      "bordercolorOpen": {
        "state": "open"
      },
      "rdOpen": {
        "state": "open"
      },
      "shdOpen": {
        "state": "open"
      }
    },
    "defaultState": "open"
  },
  "wysiwyg.viewer.skins.inlinePopupToggle.inlinePopupToggleSkin11": {
    "params": {
      "lineColor": {
        "state": "closed",
        "label": "CustomDesign_Mobile_Menu_Icon"
      },
      "lineColorOpen": {
        "state": "open",
        "label": "CustomDesign_Mobile_Menu_Icon"
      },
      "bg": {
        "state": "closed",
        "label": "CustomDesign_Mobile_Menu_Button_Background"
      },
      "borderwidth": {
        "state": "closed"
      },
      "bordercolor": {
        "state": "closed"
      },
      "rd": {
        "state": "closed"
      },
      "shd": {
        "state": "closed"
      },
      "bgOpen": {
        "state": "open",
        "label": "CustomDesign_Mobile_Menu_Button_Background"
      },
      "borderwidthOpen": {
        "state": "open"
      },
      "bordercolorOpen": {
        "state": "open"
      },
      "rdOpen": {
        "state": "open"
      },
      "shdOpen": {
        "state": "open"
      }
    },
    "defaultState": "open"
  },
  "wysiwyg.viewer.skins.inlinePopupToggle.inlinePopupToggleSkin5": {
    "params": {
      "lineColor": {
        "state": "closed",
        "label": "CustomDesign_Mobile_Menu_Icon"
      },
      "lineColorOpen": {
        "state": "open",
        "label": "CustomDesign_Mobile_Menu_Icon"
      },
      "bg": {
        "state": "closed",
        "label": "CustomDesign_Mobile_Menu_Button_Background"
      },
      "borderwidth": {
        "state": "closed"
      },
      "bordercolor": {
        "state": "closed"
      },
      "rd": {
        "state": "closed"
      },
      "shd": {
        "state": "closed"
      },
      "bgOpen": {
        "state": "open",
        "label": "CustomDesign_Mobile_Menu_Button_Background"
      },
      "borderwidthOpen": {
        "state": "open"
      },
      "bordercolorOpen": {
        "state": "open"
      },
      "rdOpen": {
        "state": "open"
      },
      "shdOpen": {
        "state": "open"
      }
    },
    "defaultState": "open"
  },
  "wysiwyg.viewer.skins.inlinePopupToggle.inlinePopupToggleSkin9": {
    "params": {
      "lineColor": {
        "state": "closed",
        "label": "CustomDesign_Mobile_Menu_Icon"
      },
      "lineColorOpen": {
        "state": "open",
        "label": "CustomDesign_Mobile_Menu_Icon"
      },
      "bg": {
        "state": "closed",
        "label": "CustomDesign_Mobile_Menu_Button_Background"
      },
      "borderwidth": {
        "state": "closed"
      },
      "bordercolor": {
        "state": "closed"
      },
      "rd": {
        "state": "closed"
      },
      "shd": {
        "state": "closed"
      },
      "bgOpen": {
        "state": "open",
        "label": "CustomDesign_Mobile_Menu_Button_Background"
      },
      "borderwidthOpen": {
        "state": "open"
      },
      "bordercolorOpen": {
        "state": "open"
      },
      "rdOpen": {
        "state": "open"
      },
      "shdOpen": {
        "state": "open"
      }
    },
    "defaultState": "open"
  },
  "wysiwyg.viewer.skins.inlinePopupToggle.inlinePopupToggleSkin1": {
    "params": {
      "lineColor": {
        "state": "closed",
        "label": "CustomDesign_Mobile_Menu_Icon"
      },
      "lineColorOpen": {
        "state": "open",
        "label": "CustomDesign_Mobile_Menu_Icon"
      },
      "bg": {
        "state": "closed",
        "label": "CustomDesign_Mobile_Menu_Button_Background"
      },
      "borderwidth": {
        "state": "closed"
      },
      "bordercolor": {
        "state": "closed"
      },
      "rd": {
        "state": "closed"
      },
      "shd": {
        "state": "closed"
      },
      "bgOpen": {
        "state": "open",
        "label": "CustomDesign_Mobile_Menu_Button_Background"
      },
      "borderwidthOpen": {
        "state": "open"
      },
      "bordercolorOpen": {
        "state": "open"
      },
      "rdOpen": {
        "state": "open"
      },
      "shdOpen": {
        "state": "open"
      }
    },
    "defaultState": "open"
  },
  "wysiwyg.viewer.skins.inlinePopupToggle.inlinePopupToggleSkin4": {
    "params": {
      "lineColor": {
        "state": "closed",
        "label": "CustomDesign_Mobile_Menu_Icon"
      },
      "lineColorOpen": {
        "state": "open",
        "label": "CustomDesign_Mobile_Menu_Icon"
      },
      "bg": {
        "state": "closed",
        "label": "CustomDesign_Mobile_Menu_Button_Background"
      },
      "borderwidth": {
        "state": "closed"
      },
      "bordercolor": {
        "state": "closed"
      },
      "rd": {
        "state": "closed"
      },
      "shd": {
        "state": "closed"
      },
      "bgOpen": {
        "state": "open",
        "label": "CustomDesign_Mobile_Menu_Button_Background"
      },
      "borderwidthOpen": {
        "state": "open"
      },
      "bordercolorOpen": {
        "state": "open"
      },
      "rdOpen": {
        "state": "open"
      },
      "shdOpen": {
        "state": "open"
      }
    },
    "defaultState": "open"
  },
  "wysiwyg.viewer.skins.inlinePopupToggle.inlinePopupToggleSkin8": {
    "params": {
      "lineColor": {
        "state": "closed",
        "label": "CustomDesign_Mobile_Menu_Icon"
      },
      "lineColorOpen": {
        "state": "open",
        "label": "CustomDesign_Mobile_Menu_Icon"
      },
      "bg": {
        "state": "closed",
        "label": "CustomDesign_Mobile_Menu_Button_Background"
      },
      "borderwidth": {
        "state": "closed"
      },
      "bordercolor": {
        "state": "closed"
      },
      "rd": {
        "state": "closed"
      },
      "shd": {
        "state": "closed"
      },
      "bgOpen": {
        "state": "open",
        "label": "CustomDesign_Mobile_Menu_Button_Background"
      },
      "borderwidthOpen": {
        "state": "open"
      },
      "bordercolorOpen": {
        "state": "open"
      },
      "rdOpen": {
        "state": "open"
      },
      "shdOpen": {
        "state": "open"
      }
    },
    "defaultState": "open"
  },
  "wysiwyg.viewer.skins.inlinePopupToggle.inlinePopupToggleSkin6": {
    "params": {
      "lineColor": {
        "state": "closed",
        "label": "CustomDesign_Mobile_Menu_Icon"
      },
      "lineColorOpen": {
        "state": "open",
        "label": "CustomDesign_Mobile_Menu_Icon"
      },
      "bg": {
        "state": "closed",
        "label": "CustomDesign_Mobile_Menu_Button_Background"
      },
      "borderwidth": {
        "state": "closed"
      },
      "bordercolor": {
        "state": "closed"
      },
      "rd": {
        "state": "closed"
      },
      "shd": {
        "state": "closed"
      },
      "bgOpen": {
        "state": "open",
        "label": "CustomDesign_Mobile_Menu_Button_Background"
      },
      "borderwidthOpen": {
        "state": "open"
      },
      "bordercolorOpen": {
        "state": "open"
      },
      "rdOpen": {
        "state": "open"
      },
      "shdOpen": {
        "state": "open"
      }
    },
    "defaultState": "open"
  },
  "wysiwyg.viewer.skins.inlinePopupToggle.inlinePopupToggleSkin2": {
    "params": {
      "lineColor": {
        "state": "closed",
        "label": "CustomDesign_Mobile_Menu_Icon"
      },
      "lineColorOpen": {
        "state": "open",
        "label": "CustomDesign_Mobile_Menu_Icon"
      },
      "bg": {
        "state": "closed",
        "label": "CustomDesign_Mobile_Menu_Button_Background"
      },
      "borderwidth": {
        "state": "closed"
      },
      "bordercolor": {
        "state": "closed"
      },
      "rd": {
        "state": "closed"
      },
      "shd": {
        "state": "closed"
      },
      "bgOpen": {
        "state": "open",
        "label": "CustomDesign_Mobile_Menu_Button_Background"
      },
      "borderwidthOpen": {
        "state": "open"
      },
      "bordercolorOpen": {
        "state": "open"
      },
      "rdOpen": {
        "state": "open"
      },
      "shdOpen": {
        "state": "open"
      }
    },
    "defaultState": "open"
  },
  "wysiwyg.viewer.skins.inlinePopupToggle.inlinePopupToggleSkin3": {
    "params": {
      "lineColor": {
        "state": "closed",
        "label": "CustomDesign_Mobile_Menu_Icon"
      },
      "lineColorOpen": {
        "state": "open",
        "label": "CustomDesign_Mobile_Menu_Icon"
      },
      "bg": {
        "state": "closed",
        "label": "CustomDesign_Mobile_Menu_Button_Background"
      },
      "borderwidth": {
        "state": "closed"
      },
      "bordercolor": {
        "state": "closed"
      },
      "rd": {
        "state": "closed"
      },
      "shd": {
        "state": "closed"
      },
      "bgOpen": {
        "state": "open",
        "label": "CustomDesign_Mobile_Menu_Button_Background"
      },
      "borderwidthOpen": {
        "state": "open"
      },
      "bordercolorOpen": {
        "state": "open"
      },
      "rdOpen": {
        "state": "open"
      },
      "shdOpen": {
        "state": "open"
      }
    },
    "defaultState": "open"
  },
  "wysiwyg.viewer.skins.inlinePopupToggle.inlinePopupToggleSkin7": {
    "params": {
      "lineColor": {
        "state": "closed",
        "label": "CustomDesign_Mobile_Menu_Icon"
      },
      "lineColorOpen": {
        "state": "open",
        "label": "CustomDesign_Mobile_Menu_Icon"
      },
      "bg": {
        "state": "closed",
        "label": "CustomDesign_Mobile_Menu_Button_Background"
      },
      "borderwidth": {
        "state": "closed"
      },
      "bordercolor": {
        "state": "closed"
      },
      "rd": {
        "state": "closed"
      },
      "shd": {
        "state": "closed"
      },
      "bgOpen": {
        "state": "open",
        "label": "CustomDesign_Mobile_Menu_Button_Background"
      },
      "borderwidthOpen": {
        "state": "open"
      },
      "bordercolorOpen": {
        "state": "open"
      },
      "rdOpen": {
        "state": "open"
      },
      "shdOpen": {
        "state": "open"
      }
    },
    "defaultState": "open"
  },
  "wysiwyg.viewer.skins.inlinePopupToggle.inlinePopupToggleSkin14": {
    "params": {
      "lineColor": {
        "state": "closed",
        "label": "CustomDesign_Mobile_Menu_Icon"
      },
      "lineColorOpen": {
        "state": "open",
        "label": "CustomDesign_Mobile_Menu_Icon"
      },
      "bg": {
        "state": "closed",
        "label": "CustomDesign_Mobile_Menu_Button_Background"
      },
      "borderwidth": {
        "state": "closed"
      },
      "bordercolor": {
        "state": "closed"
      },
      "rd": {
        "state": "closed"
      },
      "shd": {
        "state": "closed"
      },
      "bgOpen": {
        "state": "open",
        "label": "CustomDesign_Mobile_Menu_Button_Background"
      },
      "borderwidthOpen": {
        "state": "open"
      },
      "bordercolorOpen": {
        "state": "open"
      },
      "rdOpen": {
        "state": "open"
      },
      "shdOpen": {
        "state": "open"
      }
    },
    "defaultState": "open"
  },
  "wysiwyg.viewer.skins.inlinePopupToggle.inlinePopupToggleSkin16": {
    "params": {
      "lineColor": {
        "state": "closed",
        "label": "CustomDesign_Mobile_Menu_Icon"
      },
      "lineColorOpen": {
        "state": "open",
        "label": "CustomDesign_Mobile_Menu_Icon"
      },
      "bg": {
        "state": "closed",
        "label": "CustomDesign_Mobile_Menu_Button_Background"
      },
      "borderwidth": {
        "state": "closed"
      },
      "bordercolor": {
        "state": "closed"
      },
      "rd": {
        "state": "closed"
      },
      "shd": {
        "state": "closed"
      },
      "bgOpen": {
        "state": "open",
        "label": "CustomDesign_Mobile_Menu_Button_Background"
      },
      "borderwidthOpen": {
        "state": "open"
      },
      "bordercolorOpen": {
        "state": "open"
      },
      "rdOpen": {
        "state": "open"
      },
      "shdOpen": {
        "state": "open"
      }
    },
    "defaultState": "open"
  },
  "wysiwyg.viewer.skins.inlinePopupToggle.inlinePopupToggleSkin15": {
    "params": {
      "lineColor": {
        "state": "closed",
        "label": "CustomDesign_Mobile_Menu_Icon"
      },
      "lineColorOpen": {
        "state": "open",
        "label": "CustomDesign_Mobile_Menu_Icon"
      },
      "bg": {
        "state": "closed",
        "label": "CustomDesign_Mobile_Menu_Button_Background"
      },
      "borderwidth": {
        "state": "closed"
      },
      "bordercolor": {
        "state": "closed"
      },
      "rd": {
        "state": "closed"
      },
      "shd": {
        "state": "closed"
      },
      "bgOpen": {
        "state": "open",
        "label": "CustomDesign_Mobile_Menu_Button_Background"
      },
      "borderwidthOpen": {
        "state": "open"
      },
      "bordercolorOpen": {
        "state": "open"
      },
      "rdOpen": {
        "state": "open"
      },
      "shdOpen": {
        "state": "open"
      }
    },
    "defaultState": "open"
  },
  "wysiwyg.viewer.skins.inlinePopupToggle.inlinePopupToggleSkin13": {
    "params": {
      "lineColor": {
        "state": "closed",
        "label": "CustomDesign_Mobile_Menu_Icon"
      },
      "lineColorOpen": {
        "state": "open",
        "label": "CustomDesign_Mobile_Menu_Icon"
      },
      "bg": {
        "state": "closed",
        "label": "CustomDesign_Mobile_Menu_Button_Background"
      },
      "borderwidth": {
        "state": "closed"
      },
      "bordercolor": {
        "state": "closed"
      },
      "rd": {
        "state": "closed"
      },
      "shd": {
        "state": "closed"
      },
      "bgOpen": {
        "state": "open",
        "label": "CustomDesign_Mobile_Menu_Button_Background"
      },
      "borderwidthOpen": {
        "state": "open"
      },
      "bordercolorOpen": {
        "state": "open"
      },
      "rdOpen": {
        "state": "open"
      },
      "shdOpen": {
        "state": "open"
      }
    },
    "defaultState": "open"
  },
  "wysiwyg.viewer.skins.inlinePopup.DefaultInlinePopup": {
    "params": {
      "bg": {
        "label": "CustomDesign_Mobile_Menu_Box_OverlayColor"
      }
    }
  },
  "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin": {
    "params": {
      "rd": {
        "hide": true
      },
      "brd": {
        "hide": true
      },
      "brw": {
        "hide": true
      }
    }
  },
  "wysiwyg.viewer.skins.FileUploaderDefaultSkin": {
    "params": {
      "btn_txt": {
        "section": "section1"
      },
      "btn_fnt": {
        "section": "section1",
        "min": 6,
        "max": 48
      },
      "x_file_txt": {
        "section": "section2"
      },
      "file_fnt": {
        "section": "section2",
        "min": 6,
        "max": 48
      },
      "btn_bg_h": {
        "section": "section1"
      },
      "file_bg_h": {
        "section": "section2"
      },
      "btn_txt_h": {
        "section": "section1"
      },
      "x_file_txt_h": {
        "section": "section2"
      },
      "txt_d": {
        "section": "section1"
      },
      "x_file_txt_d": {
        "section": "section2"
      },
      "txt_f": {
        "section": "section1"
      },
      "x_file_txt_f": {
        "category": "text",
        "section": "section2"
      },
      "txt_e": {
        "section": "section1"
      },
      "x_file_txt_e": {
        "section": "section2"
      },
      "fntlbl": {
        "section": "label"
      },
      "txtlbl": {
        "label": "CustomDesign_Text_LabelTextColor",
        "section": "label"
      },
      "txtlblrq": {
        "category": "text",
        "label": "CustomDesign_Text_RequiredTextColor&Opacity",
        "section": "label"
      }
    },
    "sections": {
      "section1": {
        "label": "CustomDesign_Text_Section_Upload_Button_Label",
        "priority": 2
      },
      "section2": {
        "label": "CustomDesign_Text_Section_Upload_Button_Secondary_Label",
        "priority": 3
      },
      "label": {
        "state": "active",
        "category": "text",
        "label": "CustomDesign_Input_Label_Section",
        "priority": 1
      }
    }
  },
  "wysiwyg.viewer.skins.ResponsiveFileUploaderDefaultSkin": {
    "params": {
      "btn_txt": {
        "section": "section1"
      },
      "btn_fnt": {
        "section": "section1",
        "min": 6,
        "max": 48
      },
      "x_file_txt": {
        "section": "section2"
      },
      "file_fnt": {
        "section": "section2",
        "min": 6,
        "max": 48
      },
      "btn_bg_h": {
        "section": "section1"
      },
      "file_bg_h": {
        "section": "section2"
      },
      "btn_txt_h": {
        "section": "section1"
      },
      "x_file_txt_h": {
        "section": "section2"
      },
      "txt_d": {
        "section": "section1"
      },
      "x_file_txt_d": {
        "section": "section2"
      },
      "txt_f": {
        "section": "section1"
      },
      "x_file_txt_f": {
        "category": "text",
        "section": "section2"
      },
      "txt_e": {
        "section": "section1"
      },
      "x_file_txt_e": {
        "section": "section2"
      },
      "fntlbl": {
        "state": "regular",
        "category": "text",
        "label": "CustomDesign_Text_Font",
        "section": "label",
        "priority": ""
      },
      "txtlbl": {
        "state": "regular",
        "category": "text",
        "label": "CustomDesign_Text_LabelTextColor",
        "section": "label",
        "priority": ""
      },
      "txtlblrq": {
        "state": "regular",
        "category": "text",
        "label": "CustomDesign_Text_RequiredTextColor&Opacity",
        "section": "label",
        "priority": ""
      }
    },
    "sections": {
      "section1": {
        "label": "CustomDesign_Text_Section_Upload_Button_Label",
        "priority": 2
      },
      "section2": {
        "label": "CustomDesign_Text_Section_Upload_Button_Secondary_Label",
        "priority": 3
      },
      "label": {
        "state": "active",
        "category": "text",
        "label": "CustomDesign_Input_Label_Section",
        "priority": 1
      }
    }
  },
  "wixui.skins.FileUploaderNew": {
    "params": {
      "btn_txt": {
        "section": "section1"
      },
      "btn_fnt": {
        "section": "section1",
        "min": 6,
        "max": 48
      },
      "x_file_txt": {
        "section": "section2"
      },
      "file_fnt": {
        "section": "section2",
        "min": 6,
        "max": 48
      },
      "btn_bg_h": {
        "section": "section1"
      },
      "file_bg_h": {
        "section": "section2"
      },
      "btn_txt_h": {
        "section": "section1"
      },
      "x_file_txt_h": {
        "section": "section2"
      },
      "txt_d": {
        "section": "section1"
      },
      "x_file_txt_d": {
        "section": "section2"
      },
      "txt_f": {
        "section": "section1"
      },
      "x_file_txt_f": {
        "category": "text",
        "section": "section2"
      },
      "txt_e": {
        "section": "section1"
      },
      "x_file_txt_e": {
        "section": "section2"
      },
      "fntlbl": {
        "state": "regular",
        "category": "text",
        "label": "CustomDesign_Text_Font",
        "section": "label",
        "priority": ""
      },
      "txtlbl": {
        "state": "regular",
        "category": "text",
        "label": "CustomDesign_Text_LabelTextColor",
        "section": "label",
        "priority": ""
      },
      "txtlblrq": {
        "state": "regular",
        "category": "text",
        "label": "CustomDesign_Text_RequiredTextColor&Opacity",
        "section": "label",
        "priority": ""
      }
    },
    "sections": {
      "section1": {
        "label": "CustomDesign_Text_Section_Label_Button",
        "priority": 2
      },
      "section2": {
        "label": "CustomDesign_Text_Section_Label_File_Label",
        "priority": 3
      },
      "label": {
        "state": "active",
        "category": "text",
        "label": "CustomDesign_Input_Label_Section",
        "priority": 1
      }
    }
  },
  "wysiwyg.common.components.boxSlideShow.viewer.skins.thinArrowsLargeSelectedCircleSkin": {
    "params": {
      "arrowColor": {
        "label": "CustomDesign_Slide_Show_Navigation_Color&Opacity"
      },
      "dotsColor": {
        "label": "CustomDesign_Slide_Show_Buttons_Color&Opacity"
      }
    }
  },
  "wysiwyg.common.components.boxSlideShow.viewer.skins.thinArrowsSkin": {
    "params": {
      "arrowColor": {
        "label": "CustomDesign_Slide_Show_Navigation_Color&Opacity"
      },
      "dotsColor": {
        "label": "CustomDesign_Slide_Show_Buttons_Color&Opacity"
      },
      "dotsSelectedColor": {
        "label": "CustomDesign_Slide_Show_Buttons_Selected_Color&Opacity"
      }
    }
  },
  "wysiwyg.common.components.boxSlideShow.viewer.skins.squareButtonsSkin": {
    "params": {
      "arrowColor": {
        "label": "CustomDesign_Slide_Show_Navigation_Color&Opacity"
      },
      "arrowContainerColor": {
        "label": "CustomDesign_Slide_Show_Navigation_Background_Color&Opacity"
      },
      "dotsColor": {
        "label": "CustomDesign_Slide_Show_Buttons_Color&Opacity"
      },
      "dotsSelectedColor": {
        "label": "CustomDesign_Slide_Show_Buttons_Selected_Color&Opacity"
      }
    }
  },
  "wysiwyg.common.components.boxSlideShow.viewer.skins.longArrowsLargeSelectedCircleSkin": {
    "params": {
      "arrowColor": {
        "label": "CustomDesign_Slide_Show_Navigation_Color&Opacity"
      },
      "dotsColor": {
        "label": "CustomDesign_Slide_Show_Buttons_Color&Opacity"
      }
    }
  },
  "wysiwyg.common.components.stripSlideShow.viewer.skins.thinArrowsLargeSelectedCircleSkin": {
    "params": {
      "arrowColor": {
        "label": "CustomDesign_Slide_Show_Navigation_Color&Opacity"
      },
      "dotsColor": {
        "label": "CustomDesign_Slide_Show_Buttons_Color&Opacity"
      }
    }
  },
  "wysiwyg.common.components.stripSlideShow.viewer.skins.thinArrowsSkin": {
    "params": {
      "arrowColor": {
        "label": "CustomDesign_Slide_Show_Navigation_Color&Opacity"
      },
      "dotsColor": {
        "label": "CustomDesign_Slide_Show_Buttons_Color&Opacity"
      },
      "dotsSelectedColor": {
        "label": "CustomDesign_Slide_Show_Buttons_Selected_Color&Opacity"
      }
    }
  },
  "wysiwyg.common.components.stripSlideShow.viewer.skins.squareButtonsSkin": {
    "params": {
      "arrowColor": {
        "label": "CustomDesign_Slide_Show_Navigation_Color&Opacity"
      },
      "arrowContainerColor": {
        "label": "CustomDesign_Slide_Show_Navigation_Background_Color&Opacity"
      },
      "dotsColor": {
        "label": "CustomDesign_Slide_Show_Buttons_Color&Opacity"
      },
      "dotsSelectedColor": {
        "label": "CustomDesign_Slide_Show_Buttons_Selected_Color&Opacity"
      }
    }
  },
  "wysiwyg.common.components.stripSlideShow.viewer.skins.longArrowsLargeSelectedCircleSkin": {
    "params": {
      "arrowColor": {
        "label": "CustomDesign_Slide_Show_Navigation_Color&Opacity"
      },
      "dotsColor": {
        "label": "CustomDesign_Slide_Show_Buttons_Color&Opacity"
      }
    }
  },
  "wysiwyg.common.components.cloudwidget.viewer.skins.CloudWidgetSkin": {
    "params": {},
    "sections": {}
  },
  "wysiwyg.viewer.skins.InputWithValidationSkin": {},
  "wysiwyg.viewer.skins.dialogs.enterPasswordDialogSkin": {
    "params": {
      "txt": {
        "state": "regular",
        "category": "text",
        "label": "CustomDesign_Text_TextColor",
        "section": "",
        "priority": ""
      },
      "bg": {
        "state": "regular",
        "category": "fill",
        "label": "CustomDesign_FillColor_BGColor&Opacity",
        "section": "",
        "priority": ""
      },
      "bg-block": {
        "state": "regular",
        "category": "fill",
        "label": "CustomDesign_FillColor_BGColor",
        "section": "",
        "priority": ""
      },
      "bg-border": {
        "state": "regular",
        "category": "fill",
        "label": "CustomDesign_FillColor_BGColor",
        "section": "",
        "priority": ""
      }
    },
    "sections": {}
  },
  "wysiwyg.viewer.skins.button.AdminLoginButtonSkin": {
    "params": {
      "bg": {
        "state": "regular",
        "category": "fill",
        "label": "CustomDesign_FillColor_BGColor&Opacity",
        "section": "",
        "priority": "",
        "hide": true
      },
      "bg-block": {
        "state": "regular",
        "category": "fill",
        "label": "CustomDesign_FillColor_BGColor",
        "section": "",
        "priority": "",
        "hide": true
      },
      "bg-border": {
        "state": "regular",
        "category": "fill",
        "label": "CustomDesign_FillColor_BGColor",
        "section": "",
        "priority": "",
        "hide": true
      }
    }
  },
  "wysiwyg.viewer.skins.audioplayer.Audio3DPlayer": {
    "params": {
      "bg": {
        "priority": 1
      },
      "iconh": {
        "category": "fill",
        "label": "CustomDesign_MiniPlayer_IconColor",
        "priority": 2
      },
      "bgh": {
        "state": "hover",
        "priority": 1
      },
      "icon": {
        "category": "fill",
        "label": "CustomDesign_MiniPlayer_IconColor",
        "priority": 2
      },
      "shd1": {
        "state": "regular",
        "hide": true
      },
      "shd2": {
        "hide": true
      }
    }
  },
  "wysiwyg.viewer.skins.audioplayer.BoldPlayer": {
    "params": {
      "brw": {
        "state": "regular"
      }
    }
  },
  "wysiwyg.viewer.skins.audioplayer.ShinyPlayer": {
    "params": {
      "bg": {
        "priority": 1
      },
      "iconh": {
        "category": "fill",
        "label": "CustomDesign_MiniPlayer_IconColor",
        "priority": 2
      },
      "bgh": {
        "state": "hover",
        "priority": 1
      },
      "icon": {
        "category": "fill",
        "label": "CustomDesign_MiniPlayer_IconColor",
        "priority": 2
      },
      "shd1": {
        "state": "regular",
        "hide": true
      }
    }
  },
  "wysiwyg.viewer.skins.audioplayer.SimplePlayer": {
    "params": {
      "bg": {
        "priority": 1
      },
      "bgh": {
        "priority": 1
      },
      "iconh": {
        "category": "fill",
        "label": "CustomDesign_MiniPlayer_IconColor",
        "priority": 2
      },
      "icon": {
        "category": "fill",
        "label": "CustomDesign_MiniPlayer_IconColor",
        "priority": 2
      }
    }
  },
  "wysiwyg.viewer.skins.backtotopbutton.BackToTopButtonSkin": {
    "params": {
      "zoom": {
        "state": "regular",
        "category": "unknownType",
        "label": "unknownType",
        "section": "",
        "priority": ""
      }
    }
  },
  "skins.viewer.bgimagestrip.BevelScreenSkin": {
    "params": {
      "brwt": {
        "label": "CustomDesign_Strip_TopBorderWidth"
      },
      "brwb": {
        "label": "CustomDesign_Strip_BottomBorderWidth"
      }
    }
  },
  "skins.viewer.bgimagestrip.BgImageStripSkin": {
    "params": {
      "brwt": {
        "label": "CustomDesign_Strip_TopBorderWidth"
      },
      "brwb": {
        "label": "CustomDesign_Strip_BottomBorderWidth"
      }
    }
  },
  "skins.viewer.bgimagestrip.DoubleBorderScreenSkin": {
    "params": {
      "bgPosition": {
        "label": "CustomDesign_Strip_BackgroundOffset",
        "priority": 2
      },
      "bordersPosition": {
        "label": "CustomDesign_Strip_BordersOffset",
        "priority": 1
      },
      "outerLineSize": {
        "section": "section2",
        "priority": 2
      },
      "brd": {
        "section": "section1",
        "priority": 1
      },
      "lineGap": {
        "label": "CustomDesign_Strip_BordersSpacing",
        "priority": 3
      },
      "innerLineSize": {
        "section": "section1",
        "priority": 2
      },
      "brd2": {
        "section": "section2",
        "priority": 1
      }
    },
    "sections": {
      "section1": {
        "label": "CustomDesign_Strip_InnerBorderSection",
        "priority": 1
      },
      "section2": {
        "label": "CustomDesign_Strip_OuterBorderSection",
        "priority": 2
      }
    }
  },
  "skins.viewer.bgimagestrip.IronScreenSkin": {
    "params": {
      "shadow": {
        "hide": true
      }
    }
  },
  "wysiwyg.viewer.skins.area.ArrowRightRibbon": {
    "params": {
      "rb": {
        "category": "fill",
        "label": "CustomDesign_Box_FoldLeftColor&Opacity",
        "priority": 2
      },
      "bg": {
        "category": "fill",
        "label": "CustomDesign_FillColor_BGColor&Opacity",
        "priority": 1
      }
    }
  },
  "wysiwyg.viewer.skins.area.CenterRibbon": {
    "params": {
      "bg": {
        "priority": 1
      },
      "rb2": {
        "category": "fill",
        "label": "CustomDesign_Box_FoldRightColor&Opacity",
        "priority": 3
      },
      "rb1": {
        "category": "fill",
        "label": "CustomDesign_Box_FoldLeftColor&Opacity",
        "priority": 2
      }
    }
  },
  "wysiwyg.viewer.skins.area.CircleArea": {
    "params": {
      "rd": {
        "state": "regular",
        "category": "corners",
        "label": "CustomDesign_Corners_CornerRadius",
        "section": "",
        "priority": "",
        "hide": true
      }
    }
  },
  "wysiwyg.viewer.skins.FormContainerSkin": {},
  "wysiwyg.viewer.skins.area.DBDefaultAreaSkin": {
    "params": {
      "sz1": {
        "section": "section2",
        "priority": 2
      },
      "brd": {
        "section": "section2",
        "priority": 1
      },
      "bg2": {
        "label": "CustomDesign_Box_Background2Color&Opacity",
        "priority": 2
      },
      "sz2": {
        "label": "CustomDesign_Box_BorderSpacing"
      },
      "sz3": {
        "section": "section1",
        "priority": 2
      },
      "brd2": {
        "section": "section1",
        "priority": 1
      },
      "bg": {
        "priority": 1
      }
    },
    "sections": {
      "section1": {
        "label": "CustomDesign_Box_InnerBorderSection",
        "priority": 1
      },
      "section2": {
        "label": "CustomDesign_Box_OuterBorderSection",
        "priority": 2
      }
    }
  },
  "wysiwyg.viewer.skins.area.ForkedRibbonArea": {
    "params": {
      "bg": {
        "priority": 1
      },
      "rb3": {
        "category": "fill",
        "label": "CustomDesign_Box_InnerFoldLeftColor&Opacity",
        "priority": 4
      },
      "rb4": {
        "category": "fill",
        "label": "CustomDesign_Box_InnerFoldRightColor&Opacity",
        "priority": 5
      },
      "rb1": {
        "category": "fill",
        "label": "CustomDesign_Box_FoldLeftColor&Opacity",
        "priority": 2
      },
      "rb2": {
        "category": "fill",
        "label": "CustomDesign_Box_FoldRightColor&Opacity",
        "priority": 3
      }
    }
  },
  "wysiwyg.viewer.skins.area.InnerShadowAreaSkin": {
    "params": {
      "shd": {
        "hide": true
      }
    }
  },
  "wysiwyg.viewer.skins.area.IronBox": {
    "params": {
      "ishd": {
        "hide": true
      }
    }
  },
  "wysiwyg.viewer.skins.area.LeftTriangleArea": {
    "params": {
      "bg": {
        "category": "fill",
        "label": "CustomDesign_FillColor_BGColor&Opacity"
      }
    }
  },
  "wysiwyg.viewer.skins.area.PhotoArea": {
    "params": {
      "shd": {
        "hide": true
      }
    }
  },
  "wysiwyg.viewer.skins.area.RectangleArea": {
    "params": {
      "bg-scrl": {
        "state": "regular",
        "category": "fill",
        "label": "CustomDesign_FillColor_BGColor&Opacity",
        "section": "",
        "priority": "",
        "hide": true
      }
    }
  },
  "wysiwyg.viewer.skins.area.RibbonAreaSkin": {
    "params": {
      "bg": {
        "priority": 1
      },
      "els": {
        "label": "CustomDesign_Box_FoldSize"
      },
      "elm": {
        "label": "CustomDesign_Box_FoldRightColor&Opacity",
        "priority": 3
      },
      "elm2": {
        "label": "CustomDesign_Box_FoldLeftColor&Opacity",
        "priority": 2
      }
    }
  },
  "wysiwyg.viewer.skins.area.RightTriangleArea": {
    "params": {
      "bg": {
        "category": "fill",
        "label": "CustomDesign_FillColor_BGColor&Opacity"
      }
    }
  },
  "wysiwyg.viewer.skins.area.SandClockArea": {
    "params": {
      "bg": {
        "category": "fill",
        "label": "CustomDesign_FillColor_BGColor&Opacity"
      }
    }
  },
  "wysiwyg.viewer.skins.area.ThreeDeeAreaSkin": {},
  "wysiwyg.viewer.skins.area.VerticalArrowArea": {
    "params": {
      "bg": {
        "category": "fill",
        "label": "CustomDesign_FillColor_BGColor&Opacity"
      }
    }
  },
  "wysiwyg.viewer.skins.area.VerticalRibbonArea": {
    "params": {
      "bg": {
        "category": "fill",
        "label": "CustomDesign_FillColor_BGColor&Opacity"
      }
    }
  },
  "wysiwyg.viewer.skins.screenwidthcontainer.AfterScroll": {
    "params": {
      "bg-scrl": {
        "state": "scrolled"
      },
      "brd-scrl": {
        "state": "scrolled"
      },
      "bgd-scrl": {
        "state": "scrolled"
      },
      "brwt": {
        "label": "CustomDesign_Header_TopBorderWidth"
      },
      "brwt-scrl": {
        "state": "scrolled",
        "label": "CustomDesign_Header_TopBorderWidth"
      },
      "brwb": {
        "label": "CustomDesign_Header_BottomBorderWidth"
      },
      "brwb-scrl": {
        "state": "scrolled",
        "label": "CustomDesign_Header_BottomBorderWidth"
      },
      "shd-scrl": {
        "state": "scrolled"
      }
    }
  },
  "wysiwyg.viewer.skins.screenwidthcontainer.BevelScreen": {
    "params": {
      "shd": {
        "hide": true
      },
      "brwt": {
        "category": "border",
        "label": "CustomDesign_Header_TopBorderWidth"
      },
      "brwb": {
        "category": "border",
        "label": "CustomDesign_Header_BottomBorderWidth"
      }
    }
  },
  "wysiwyg.viewer.skins.screenwidthcontainer.DefaultScreen": {
    "params": {
      "bg": {
        "priority": 1
      },
      "brwt": {
        "category": "border",
        "label": "CustomDesign_Header_TopBorderWidth"
      },
      "brwb": {
        "category": "border",
        "label": "CustomDesign_Header_BottomBorderWidth"
      },
      "bgctr": {
        "label": "CustomDesign_Header_CenterBGColor&Opacity",
        "priority": 2
      }
    }
  },
  "wysiwyg.viewer.skins.screenwidthcontainer.DoubleBorderScreen": {
    "params": {
      "bgPosition": {
        "label": "CustomDesign_Header_BackgroundOffset",
        "priority": 1
      },
      "bordersPosition": {
        "label": "CustomDesign_Header_BordersOffset",
        "priority": 2
      },
      "outerLineSize": {
        "section": "section2",
        "priority": 2
      },
      "brd": {
        "section": "section2",
        "priority": 1
      },
      "lineGap": {
        "label": "CustomDesign_Header_BordersSpacing",
        "priority": 3
      },
      "innerLineSize": {
        "section": "section1",
        "priority": 2
      },
      "brd2": {
        "section": "section1",
        "priority": 1
      }
    },
    "sections": {
      "section1": {
        "label": "CustomDesign_Header_InnerBorderSection",
        "priority": 1
      },
      "section2": {
        "label": "CustomDesign_Header_OuterBorderSection",
        "priority": 2
      }
    }
  },
  "wysiwyg.viewer.skins.screenwidthcontainer.InnerShadowScreen": {
    "params": {
      "brd": {
        "category": "border",
        "label": "CustomDesign_Borders_Color&Opacity"
      },
      "brw": {
        "category": "border",
        "label": "CustomDesign_Borders_Width"
      },
      "shd": {
        "hide": true
      }
    }
  },
  "wysiwyg.viewer.skins.screenwidthcontainer.IronScreen": {
    "params": {
      "shadow": {
        "hide": true
      }
    }
  },
  "wysiwyg.viewer.skins.screenwidthcontainer.LiftedBottomScreen": {
    "params": {
      "brd": {
        "category": "border",
        "label": "CustomDesign_Borders_Color&Opacity"
      },
      "brw": {
        "category": "border",
        "label": "CustomDesign_Borders_Width"
      }
    }
  },
  "wysiwyg.viewer.skins.screenwidthcontainer.LiftedTopScreen": {
    "params": {
      "brd": {
        "category": "border",
        "label": "CustomDesign_Borders_Color&Opacity"
      },
      "brw": {
        "category": "border",
        "label": "CustomDesign_Borders_Width"
      }
    }
  },
  "wysiwyg.viewer.skins.screenwidthcontainer.ShadowBottomScreen": {
    "params": {
      "bg": {
        "priority": 1
      },
      "brwt": {
        "label": "CustomDesign_Header_TopBorderWidth",
        "priority": 2
      },
      "brd": {
        "priority": 1
      },
      "brwb": {
        "label": "CustomDesign_Header_BottomBorderWidth",
        "priority": 3
      },
      "bgctr": {
        "label": "CustomDesign_Header_CenterBGColor&Opacity",
        "priority": 2
      }
    }
  },
  "wysiwyg.viewer.skins.screenwidthcontainer.ShadowTopScreen": {
    "params": {
      "bg": {
        "priority": 1
      },
      "brwt": {
        "category": "border",
        "label": "CustomDesign_Footer_TopBorderWidth",
        "priority": 2
      },
      "brd": {
        "priority": 1
      },
      "brwb": {
        "category": "border",
        "label": "CustomDesign_Footer_BottomBorderWidth",
        "priority": 3
      },
      "bgctr": {
        "label": "CustomDesign_Footer_CenterBGColor&Opacity",
        "priority": 2
      }
    }
  },
  "wysiwyg.viewer.skins.screenwidthcontainer.ThreeDeeScreen": {
    "params": {
      "brd": {
        "category": "border",
        "label": "CustomDesign_Borders_Color&Opacity"
      },
      "brw": {
        "category": "border",
        "label": "CustomDesign_Borders_Width"
      }
    }
  },
  "wysiwyg.viewer.skins.screenwidthcontainer.TransparentHalfScreen": {
    "params": {
      "bgc1": {
        "state": "regular",
        "category": "fill",
        "label": "CustomDesign_FillColor_BGColor",
        "section": "",
        "priority": ""
      },
      "bgc2": {
        "state": "regular",
        "category": "fill",
        "label": "CustomDesign_FillColor_BGColor",
        "section": "",
        "priority": ""
      }
    }
  },
  "skins.viewer.deadcomp.DeadCompPreviewSkin": {
    "params": {},
    "sections": {}
  },
  "skins.viewer.deadcomp.DeadCompPublicSkin": {
    "params": {},
    "sections": {}
  },
  "wysiwyg.viewer.skins.dialogs.creditsDialogSkin": {
    "params": {
      "txt": {
        "state": "regular",
        "category": "text",
        "label": "CustomDesign_Text_TextColor",
        "section": "",
        "priority": ""
      },
      "bg": {
        "state": "regular",
        "category": "fill",
        "label": "CustomDesign_FillColor_BGColor&Opacity",
        "section": "",
        "priority": ""
      },
      "bg-block": {
        "state": "regular",
        "category": "fill",
        "label": "CustomDesign_FillColor_BGColor",
        "section": "",
        "priority": ""
      },
      "bg-border": {
        "state": "regular",
        "category": "fill",
        "label": "CustomDesign_FillColor_BGColor",
        "section": "",
        "priority": ""
      }
    },
    "sections": {}
  },
  "wysiwyg.viewer.skins.dialogs.notificationDialogSkin": {
    "params": {
      "txt": {
        "state": "regular",
        "category": "text",
        "label": "CustomDesign_Text_TextColor",
        "section": "",
        "priority": ""
      },
      "bg": {
        "state": "regular",
        "category": "fill",
        "label": "CustomDesign_FillColor_BGColor&Opacity",
        "section": "",
        "priority": ""
      },
      "bg-block": {
        "state": "regular",
        "category": "fill",
        "label": "CustomDesign_FillColor_BGColor",
        "section": "",
        "priority": ""
      },
      "bg-border": {
        "state": "regular",
        "category": "fill",
        "label": "CustomDesign_FillColor_BGColor",
        "section": "",
        "priority": ""
      }
    },
    "sections": {}
  },
  "wysiwyg.viewer.skins.dialogs.siteMembersDialogs.memberLoginDialogSkin": {
    "params": {
      "txt": {
        "state": "regular",
        "category": "text",
        "label": "CustomDesign_Text_TextColor",
        "section": "",
        "priority": ""
      },
      "bg": {
        "state": "regular",
        "category": "fill",
        "label": "CustomDesign_FillColor_BGColor&Opacity",
        "section": "",
        "priority": ""
      },
      "bg-block": {
        "state": "regular",
        "category": "fill",
        "label": "CustomDesign_FillColor_BGColor",
        "section": "",
        "priority": ""
      },
      "bg-border": {
        "state": "regular",
        "category": "fill",
        "label": "CustomDesign_FillColor_BGColor",
        "section": "",
        "priority": ""
      }
    },
    "sections": {}
  },
  "wysiwyg.viewer.skins.dialogs.siteMembersDialogs.requestPasswordResetDialogSkin": {
    "params": {
      "txt": {
        "state": "regular",
        "category": "text",
        "label": "CustomDesign_Text_TextColor",
        "section": "",
        "priority": ""
      },
      "bg": {
        "state": "regular",
        "category": "fill",
        "label": "CustomDesign_FillColor_BGColor&Opacity",
        "section": "",
        "priority": ""
      },
      "bg-block": {
        "state": "regular",
        "category": "fill",
        "label": "CustomDesign_FillColor_BGColor",
        "section": "",
        "priority": ""
      },
      "bg-border": {
        "state": "regular",
        "category": "fill",
        "label": "CustomDesign_FillColor_BGColor",
        "section": "",
        "priority": ""
      }
    },
    "sections": {}
  },
  "wysiwyg.viewer.skins.dialogs.siteMembersDialogs.resetPasswordDialogSkin": {
    "params": {
      "txt": {
        "state": "regular",
        "category": "text",
        "label": "CustomDesign_Text_TextColor",
        "section": "",
        "priority": ""
      },
      "bg": {
        "state": "regular",
        "category": "fill",
        "label": "CustomDesign_FillColor_BGColor&Opacity",
        "section": "",
        "priority": ""
      },
      "bg-block": {
        "state": "regular",
        "category": "fill",
        "label": "CustomDesign_FillColor_BGColor",
        "section": "",
        "priority": ""
      },
      "bg-border": {
        "state": "regular",
        "category": "fill",
        "label": "CustomDesign_FillColor_BGColor",
        "section": "",
        "priority": ""
      }
    },
    "sections": {}
  },
  "wysiwyg.viewer.skins.dialogs.siteMembersDialogs.signUpDialogSkin": {
    "params": {
      "txt": {
        "state": "regular",
        "category": "text",
        "label": "CustomDesign_Text_TextColor",
        "section": "",
        "priority": ""
      },
      "bg": {
        "state": "regular",
        "category": "fill",
        "label": "CustomDesign_FillColor_BGColor&Opacity",
        "section": "",
        "priority": ""
      },
      "bg-block": {
        "state": "regular",
        "category": "fill",
        "label": "CustomDesign_FillColor_BGColor",
        "section": "",
        "priority": ""
      },
      "bg-border": {
        "state": "regular",
        "category": "fill",
        "label": "CustomDesign_FillColor_BGColor",
        "section": "",
        "priority": ""
      }
    },
    "sections": {}
  },
  "skins.viewer.displayers.SlideIronDisplayer": {
    "params": {
      "ishd": {
        "hide": true
      }
    }
  },
  "skins.viewer.displayers.SlideScotchTapeDisplayer": {},
  "skins.viewer.gallerymatrix.MatrixDisplayerIronSkin": {
    "params": {
      "brw": {
        "hide": true
      },
      "ishd": {
        "hide": true
      }
    }
  },
  "wysiwyg.common.components.matrixgallery.viewer.skins.MatrixDisplayerSeparateTextBoxSkin": {
    "params": {
      "imgHeightDiff": {
        "label": "CustomDesign_HEIGHT_SIZE"
      }
    }
  },
  "wysiwyg.common.components.matrixgallery.viewer.skins.MatrixDisplayerTextOnCenterSkin": {
    "params": {
      "linkColor": {
        "category": "fill",
        "label": "CustomDesign_FillColor_BGColor&Opacity"
      }
    }
  },
  "wysiwyg.viewer.skins.displayers.SlideTextFloatingDisplayer": {},
  "wysiwyg.viewer.skins.gallerymatrix.MatrixDisplayerCircleSkin": {
    "params": {
      "fntt": {
        "state": "hover"
      },
      "ttl": {
        "state": "hover"
      },
      "rd": {
        "state": "regular",
        "category": "corners",
        "label": "CustomDesign_Corners_CornerRadius",
        "section": "",
        "priority": "",
        "hide": true
      }
    }
  },
  "wysiwyg.viewer.skins.gallerymatrix.MatrixDisplayerLiftedShadow": {},
  "wysiwyg.viewer.skins.gallerymatrix.MatrixDisplayerSloopy": {
    "params": {
      "bg": {
        "hide": true
      }
    }
  },
  "wysiwyg.viewer.skins.gallerymatrix.MatrixDisplayerTextSlideUpSkin": {},
  "wysiwyg.viewer.skins.gallerymatrix.PolaroidDisplayerCustomHeightSkin": {},
  "wysiwyg.viewer.skins.gallerymatrix.TextBottomDisplayerCustomHeightSkin": {},
  "wysiwyg.viewer.skins.galleryslider.SliderDisplayerIronSkin": {
    "params": {
      "ishd": {
        "hide": true
      }
    }
  },
  "wysiwyg.viewer.skins.dropmenubutton.ArrowRightMenuButtonNSkin": {},
  "wysiwyg.common.components.dropdownmenu.viewer.skins.ArrowRightMenuButtonSkin": {
    "params": {
      "pad": {
        "label": "CustomDesign_HorizontalMenu_ButtonsSpacing"
      },
      "bgDrop": {
        "label": "CustomDesign_HorizontalMenu_DropDownBackground",
        "priority": 2
      },
      "rdDrop": {
        "label": "CustomDesign_HorizontalMenu_DropDownCornerRadius",
        "priority": 2
      },
      "bg": {
        "category": "fill",
        "priority": 1
      },
      "bgh": {
        "state": "hover",
        "category": "fill"
      },
      "bgs": {
        "state": "active",
        "category": "fill"
      },
      "txth": {
        "state": "hover"
      },
      "txts": {
        "state": "active"
      },
      "menuTotalBordersX": {
        "hide": true
      },
      "padLeft": {
        "hide": true
      },
      "rd": {
        "label": "CustomDesign_HorizontalMenu_MenuCornersRadius",
        "priority": 1
      }
    },
    "compParts": [
      {
        "label": "CustomDesign_ComponentState_Label_Input_Tab",
        "value": "host",
        "additionalPreviewStates": [
          "open"
        ]
      }
    ]
  },
  "wysiwyg.common.components.dropdownmenu.viewer.skins.IndentedMenuButtonSkin": {
    "params": {
      "bg": {
        "priority": 1
      },
      "rd": {
        "label": "CustomDesign_HorizontalMenu_MenuCornersRadius"
      },
      "bgDrop": {
        "label": "CustomDesign_HorizontalMenu_DropDownBackground",
        "priority": 2
      },
      "rdDrop": {
        "label": "CustomDesign_HorizontalMenu_DropDownCornerRadius"
      },
      "sep": {
        "label": "CustomDesign_HorizontalMenu_SeparatorsColor",
        "priority": 3
      },
      "pad": {
        "label": "CustomDesign_HorizontalMenu_ButtonsSpacing",
        "hide": true
      },
      "bgh": {
        "state": "hover"
      },
      "txth": {
        "state": "hover"
      },
      "bgs": {
        "state": "active"
      },
      "txts": {
        "state": "active"
      },
      "menuTotalBordersX": {
        "hide": true
      }
    },
    "compParts": [
      {
        "label": "CustomDesign_ComponentState_Label_Input_Tab",
        "value": "host",
        "additionalPreviewStates": [
          "open"
        ]
      }
    ]
  },
  "wysiwyg.viewer.skins.dropmenubutton.LinesMenuButtonNBorderRadiusFixSkin": {
    "params": {
      "bgh": {
        "state": "regular",
        "category": "fill",
        "label": "CustomDesign_FillColor_BGColor&Opacity",
        "section": "",
        "priority": ""
      }
    }
  },
  "wysiwyg.common.components.dropdownmenu.viewer.skins.LinesMenuButtonBorderRadiusFixSkin": {
    "params": {
      "bgh": {
        "state": "regular",
        "category": "fill",
        "label": "CustomDesign_FillColor_BGColor&Opacity",
        "section": "",
        "priority": ""
      },
      "menuTotalBordersX": {
        "hide": true
      }
    },
    "compParts": [
      {
        "label": "CustomDesign_ComponentState_Label_Input_Tab",
        "value": "host",
        "additionalPreviewStates": [
          "open"
        ]
      }
    ]
  },
  "wysiwyg.viewer.skins.dropmenubutton.LinesMenuButtonNSkin": {
    "params": {
      "bgh": {
        "state": "regular",
        "category": "fill",
        "label": "CustomDesign_FillColor_BGColor&Opacity",
        "section": "",
        "priority": ""
      }
    }
  },
  "wysiwyg.common.components.dropdownmenu.viewer.skins.LinesMenuButtonSkin": {
    "params": {
      "bgDrop": {
        "label": "CustomDesign_HorizontalMenu_DropDownBackground"
      },
      "rd": {
        "label": "CustomDesign_HorizontalMenu_DropDownCornerRadius"
      },
      "pad": {
        "label": "CustomDesign_HorizontalMenu_ButtonsSpacing",
        "hide": true
      },
      "bgh": {
        "state": "hover",
        "category": "fill",
        "label": "CustomDesign_FillColor_BGColor&Opacity",
        "section": "",
        "priority": ""
      },
      "txth": {
        "state": "hover"
      },
      "bgs": {
        "state": "active"
      },
      "txts": {
        "state": "active"
      },
      "menuTotalBordersX": {
        "hide": true
      }
    },
    "compParts": [
      {
        "label": "CustomDesign_ComponentState_Label_Input_Tab",
        "value": "host",
        "additionalPreviewStates": [
          "open"
        ]
      }
    ]
  },
  "wysiwyg.viewer.skins.dropmenubutton.OverlineMenuButtonHorizontalMenuAdaptationSkin": {
    "params": {
      "brd": {
        "category": "border"
      },
      "brdh": {
        "category": "border"
      },
      "brds": {
        "category": "border"
      }
    }
  },
  "wysiwyg.common.components.dropdownmenu.viewer.skins.OverlineMenuButtonHorizontalMenuAdaptationSkin": {
    "params": {
      "brd": {
        "category": "border"
      },
      "brdh": {
        "category": "border"
      },
      "brds": {
        "category": "border"
      },
      "menuTotalBordersX": {
        "hide": true
      }
    },
    "compParts": [
      {
        "label": "CustomDesign_ComponentState_Label_Input_Tab",
        "value": "host",
        "additionalPreviewStates": [
          "open"
        ]
      }
    ]
  },
  "wysiwyg.viewer.skins.dropmenubutton.OverlineMenuButtonNSkin": {
    "params": {
      "brd": {
        "category": "border"
      },
      "brdh": {
        "category": "border"
      },
      "brds": {
        "category": "border"
      }
    }
  },
  "wysiwyg.common.components.dropdownmenu.viewer.skins.OverlineMenuButtonSkin": {
    "params": {
      "bgDrop": {
        "label": "CustomDesign_HorizontalMenu_DropDownBackground"
      },
      "rd": {
        "label": "CustomDesign_HorizontalMenu_MenuCornersRadius"
      },
      "pad": {
        "label": "CustomDesign_HorizontalMenu_ButtonsSpacing"
      },
      "brd": {
        "category": "border"
      },
      "brdh": {
        "state": "hover",
        "category": "border"
      },
      "txth": {
        "state": "hover"
      },
      "brds": {
        "state": "active",
        "category": "border"
      },
      "txts": {
        "state": "active"
      },
      "menuTotalBordersX": {
        "hide": true
      }
    },
    "compParts": [
      {
        "label": "CustomDesign_ComponentState_Label_Input_Tab",
        "value": "host",
        "additionalPreviewStates": [
          "open"
        ]
      }
    ]
  },
  "wysiwyg.viewer.skins.dropmenubutton.PointerMenuButtonHorizontalMenuAdaptationSkin": {
    "params": {
      "clrh": {
        "state": "regular",
        "category": "fill",
        "label": "CustomDesign_FillColor_Color&Opacity",
        "section": "",
        "priority": ""
      }
    }
  },
  "wysiwyg.common.components.dropdownmenu.viewer.skins.PointerMenuButtonHorizontalMenuAdaptationSkin": {
    "params": {
      "brd": {
        "category": "border"
      },
      "menuTotalBordersX": {
        "hide": true
      },
      "clrh": {
        "state": "regular",
        "category": "fill",
        "label": "CustomDesign_FillColor_Color&Opacity",
        "section": "",
        "priority": ""
      }
    },
    "compParts": [
      {
        "label": "CustomDesign_ComponentState_Label_Input_Tab",
        "value": "host",
        "additionalPreviewStates": [
          "open"
        ]
      }
    ]
  },
  "wysiwyg.viewer.skins.dropmenubutton.PointerMenuButtonNSkin": {
    "params": {
      "clrh": {
        "state": "regular",
        "category": "fill",
        "label": "CustomDesign_FillColor_Color&Opacity",
        "section": "",
        "priority": ""
      }
    }
  },
  "wysiwyg.common.components.dropdownmenu.viewer.skins.PointerMenuButtonSkin": {
    "params": {
      "brd": {
        "category": "border",
        "label": "CustomDesign_Borders_Color&Opacity"
      },
      "bgDrop": {
        "label": "CustomDesign_HorizontalMenu_DropDownBackground"
      },
      "rd": {
        "label": "CustomDesign_HorizontalMenu_DropDownCornerRadius"
      },
      "pad": {
        "label": "CustomDesign_HorizontalMenu_ButtonsSpacing",
        "hide": true
      },
      "bgh": {
        "state": "hover"
      },
      "txth": {
        "state": "hover"
      },
      "bgs": {
        "state": "active"
      },
      "txts": {
        "state": "active"
      },
      "menuTotalBordersX": {
        "hide": true
      },
      "clrh": {
        "state": "regular",
        "category": "fill",
        "label": "CustomDesign_FillColor_Color&Opacity",
        "section": "",
        "priority": ""
      }
    },
    "compParts": [
      {
        "label": "CustomDesign_ComponentState_Label_Input_Tab",
        "value": "host",
        "additionalPreviewStates": [
          "open"
        ]
      }
    ]
  },
  "wysiwyg.common.components.dropdownmenu.viewer.skins.RibbonsMenuButtonSkin": {
    "params": {
      "els": {
        "category": "other",
        "label": "CustomDesign_HorizontalMenu_FoldsSize"
      },
      "bg": {
        "priority": 1
      },
      "elm": {
        "label": "CustomDesign_HorizontalMenu_LeftFoldColor&Opacity",
        "priority": 3
      },
      "elm2": {
        "label": "CustomDesign_HorizontalMenu_RightFoldColor&Opacity",
        "priority": 4
      },
      "bgDrop": {
        "label": "CustomDesign_HorizontalMenu_DropDownBackground",
        "priority": 2
      },
      "pad": {
        "label": "CustomDesign_HorizontalMenu_ButtonsSpacing",
        "hide": true
      },
      "bgh": {
        "state": "hover"
      },
      "txth": {
        "state": "hover"
      },
      "bgs": {
        "state": "active"
      },
      "txts": {
        "state": "active"
      },
      "menuTotalBordersX": {
        "hide": true
      }
    },
    "compParts": [
      {
        "label": "CustomDesign_ComponentState_Label_Input_Tab",
        "value": "host",
        "additionalPreviewStates": [
          "open"
        ]
      }
    ]
  },
  "wysiwyg.viewer.skins.dropmenubutton.SeparateBasicMenuButtonNSkin": {
    "params": {
      "noshd": {
        "hide": true
      },
      "nord": {
        "hide": true
      }
    }
  },
  "wysiwyg.common.components.dropdownmenu.viewer.skins.SeparateBasicMenuButtonSkin": {
    "params": {
      "rdDrop": {
        "label": "CustomDesign_HorizontalMenu_DropDownCornerRadius",
        "priority": 2
      },
      "bgDrop": {
        "label": "CustomDesign_HorizontalMenu_DropDownBackground",
        "priority": 2
      },
      "pad": {
        "label": "CustomDesign_HorizontalMenu_ButtonsSpacing"
      },
      "rd": {
        "label": "CustomDesign_HorizontalMenu_MenuCornersRadius",
        "priority": 1
      },
      "bg": {
        "priority": 1
      },
      "txth": {
        "state": "hover"
      },
      "bgh": {
        "state": "hover"
      },
      "brdh": {
        "state": "hover"
      },
      "txts": {
        "state": "active"
      },
      "bgs": {
        "state": "active"
      },
      "brds": {
        "state": "active"
      },
      "menuTotalBordersX": {
        "hide": true
      },
      "noshd": {
        "hide": true
      },
      "nord": {
        "hide": true
      }
    },
    "compParts": [
      {
        "label": "CustomDesign_ComponentState_Label_Input_Tab",
        "value": "host",
        "additionalPreviewStates": [
          "open"
        ]
      }
    ]
  },
  "wysiwyg.viewer.skins.dropmenubutton.SeparateIndentedMenuButtonNSkin": {
    "params": {
      "no_rd": {
        "hide": true
      },
      "noshd": {
        "hide": true
      }
    }
  },
  "wysiwyg.common.components.dropdownmenu.viewer.skins.SeparateIndentedMenuButtonSkin": {
    "params": {
      "pad": {
        "label": "CustomDesign_HorizontalMenu_ButtonsSpacing"
      },
      "bgDrop": {
        "label": "CustomDesign_HorizontalMenu_DropDownBackground",
        "priority": 2
      },
      "rdDrop": {
        "label": "CustomDesign_HorizontalMenu_DropDownCornerRadius",
        "priority": 2
      },
      "bg": {
        "priority": 1
      },
      "rd": {
        "label": "CustomDesign_HorizontalMenu_MenuCornersRadius",
        "priority": 1
      },
      "bgh": {
        "state": "hover"
      },
      "txth": {
        "state": "hover"
      },
      "bgs": {
        "state": "active"
      },
      "txts": {
        "state": "active"
      },
      "menuTotalBordersX": {
        "hide": true
      },
      "no_rd": {
        "hide": true
      },
      "noshd": {
        "hide": true
      }
    },
    "compParts": [
      {
        "label": "CustomDesign_ComponentState_Label_Input_Tab",
        "value": "host",
        "additionalPreviewStates": [
          "open"
        ]
      }
    ]
  },
  "wysiwyg.common.components.dropdownmenu.viewer.skins.SeparateLinesMenuButtonHorizontalMenuAdaptationSkin": {
    "params": {
      "menuTotalBordersX": {
        "hide": true
      }
    },
    "compParts": [
      {
        "label": "CustomDesign_ComponentState_Label_Input_Tab",
        "value": "host",
        "additionalPreviewStates": [
          "open"
        ]
      }
    ]
  },
  "wysiwyg.common.components.dropdownmenu.viewer.skins.SeparateLinesMenuButtonSkin": {
    "params": {
      "pad": {
        "label": "CustomDesign_HorizontalMenu_ButtonsSpacing"
      },
      "bgDrop": {
        "label": "CustomDesign_HorizontalMenu_DropDownBackground"
      },
      "rd": {
        "label": "CustomDesign_HorizontalMenu_DropDownCornerRadius"
      },
      "brdh": {
        "state": "hover"
      },
      "bgh": {
        "state": "hover"
      },
      "txth": {
        "state": "hover"
      },
      "brds": {
        "state": "active"
      },
      "bgs": {
        "state": "active"
      },
      "txts": {
        "state": "active"
      },
      "menuTotalBordersX": {
        "hide": true
      }
    },
    "compParts": [
      {
        "label": "CustomDesign_ComponentState_Label_Input_Tab",
        "value": "host",
        "additionalPreviewStates": [
          "open"
        ]
      }
    ]
  },
  "wysiwyg.viewer.skins.dropmenubutton.SeparateShinyIIMenuButtonNBorderRadiusFixSkin": {
    "params": {
      "noshd": {
        "hide": true
      },
      "no_rd": {
        "hide": true
      }
    }
  },
  "wysiwyg.common.components.dropdownmenu.viewer.skins.SeparateShinyIIMenuButtonBorderRadiusFixSkin": {
    "params": {
      "menuTotalBordersX": {
        "hide": true
      },
      "noshd": {
        "hide": true
      },
      "no_rd": {
        "hide": true
      }
    },
    "compParts": [
      {
        "label": "CustomDesign_ComponentState_Label_Input_Tab",
        "value": "host",
        "additionalPreviewStates": [
          "open"
        ]
      }
    ]
  },
  "wysiwyg.viewer.skins.dropmenubutton.SeparateShinyIIMenuButtonNSkin": {
    "params": {
      "noshd": {
        "hide": true
      },
      "no_rd": {
        "hide": true
      }
    }
  },
  "wysiwyg.common.components.dropdownmenu.viewer.skins.SeparateShinyIIMenuButtonSkin": {
    "params": {
      "pad": {
        "label": "CustomDesign_HorizontalMenu_ButtonsSpacing"
      },
      "bgDrop": {
        "label": "CustomDesign_HorizontalMenu_DropDownBackground",
        "priority": 2
      },
      "rdDrop": {
        "label": "CustomDesign_HorizontalMenu_DropDownCornerRadius",
        "priority": 2
      },
      "bg": {
        "priority": 1
      },
      "rd": {
        "label": "CustomDesign_HorizontalMenu_MenuCornersRadius",
        "priority": 1
      },
      "bgh": {
        "state": "hover"
      },
      "txth": {
        "state": "hover"
      },
      "bgs": {
        "state": "active"
      },
      "txts": {
        "state": "active"
      },
      "menuTotalBordersX": {
        "hide": true
      },
      "noshd": {
        "hide": true
      },
      "no_rd": {
        "hide": true
      }
    },
    "compParts": [
      {
        "label": "CustomDesign_ComponentState_Label_Input_Tab",
        "value": "host",
        "additionalPreviewStates": [
          "open"
        ]
      }
    ]
  },
  "wysiwyg.viewer.skins.dropmenubutton.SeparateShinyIMenuButtonNSkin": {
    "params": {
      "noshd": {
        "hide": true
      },
      "no_rd": {
        "hide": true
      }
    }
  },
  "wysiwyg.common.components.dropdownmenu.viewer.skins.SeparateShinyIMenuButtonSkin": {
    "params": {
      "pad": {
        "label": "CustomDesign_HorizontalMenu_ButtonsSpacing"
      },
      "bgDrop": {
        "label": "CustomDesign_HorizontalMenu_DropDownBackground",
        "priority": 2
      },
      "rdDrop": {
        "label": "CustomDesign_HorizontalMenu_DropDownCornerRadius",
        "priority": 2
      },
      "bg": {
        "priority": 1
      },
      "rd": {
        "label": "CustomDesign_HorizontalMenu_MenuCornersRadius",
        "priority": 1
      },
      "bgs": {
        "state": "active"
      },
      "bgh": {
        "state": "hover"
      },
      "txth": {
        "state": "hover"
      },
      "txts": {
        "state": "active"
      },
      "menuTotalBordersX": {
        "hide": true
      },
      "noshd": {
        "hide": true
      },
      "no_rd": {
        "hide": true
      }
    },
    "compParts": [
      {
        "label": "CustomDesign_ComponentState_Label_Input_Tab",
        "value": "host",
        "additionalPreviewStates": [
          "open"
        ]
      }
    ]
  },
  "wysiwyg.common.components.dropdownmenu.viewer.skins.ShinyMenuIButtonSkin": {
    "params": {
      "bg": {
        "priority": 1
      },
      "rd": {
        "label": "CustomDesign_HorizontalMenu_MenuCornersRadius"
      },
      "bgDrop": {
        "label": "CustomDesign_HorizontalMenu_DropDownBackground",
        "priority": 2
      },
      "rdDrop": {
        "label": "CustomDesign_HorizontalMenu_DropDownCornerRadius"
      },
      "sep": {
        "label": "CustomDesign_HorizontalMenu_SeparatorsColor",
        "priority": 3
      },
      "pad": {
        "label": "CustomDesign_HorizontalMenu_ButtonsSpacing",
        "hide": true
      },
      "bgh": {
        "state": "hover"
      },
      "txth": {
        "state": "hover"
      },
      "bgs": {
        "state": "active"
      },
      "txts": {
        "state": "active"
      },
      "menuTotalBordersX": {
        "hide": true
      }
    },
    "compParts": [
      {
        "label": "CustomDesign_ComponentState_Label_Input_Tab",
        "value": "host",
        "additionalPreviewStates": [
          "open"
        ]
      }
    ]
  },
  "wysiwyg.common.components.dropdownmenu.viewer.skins.ShinyMenuIIButtonSkin": {
    "params": {
      "bg": {
        "priority": 1
      },
      "rd": {
        "label": "CustomDesign_HorizontalMenu_MenuCornersRadius"
      },
      "bgDrop": {
        "label": "CustomDesign_HorizontalMenu_DropDownBackground",
        "priority": 2
      },
      "rdDrop": {
        "label": "CustomDesign_HorizontalMenu_DropDownCornerRadius"
      },
      "sep": {
        "label": "CustomDesign_HorizontalMenu_SeparatorsColor",
        "priority": 3
      },
      "bgh": {
        "state": "hover"
      },
      "txth": {
        "state": "hover"
      },
      "bgs": {
        "state": "active"
      },
      "txts": {
        "state": "active"
      },
      "menuTotalBordersX": {
        "hide": true
      }
    },
    "compParts": [
      {
        "label": "CustomDesign_ComponentState_Label_Input_Tab",
        "value": "host",
        "additionalPreviewStates": [
          "open"
        ]
      }
    ]
  },
  "wysiwyg.common.components.dropdownmenu.viewer.skins.SloppyBorderMenuButtonSkin": {
    "params": {
      "bg": {
        "priority": 1
      },
      "bgDrop": {
        "label": "CustomDesign_HorizontalMenu_DropDownBackground",
        "priority": 2
      },
      "pad": {
        "label": "CustomDesign_HorizontalMenu_ButtonsSpacing",
        "hide": true
      },
      "sep": {
        "label": "CustomDesign_HorizontalMenu_SeparatorsColor",
        "priority": 3
      },
      "bgh": {
        "state": "hover"
      },
      "txth": {
        "state": "hover"
      },
      "bgs": {
        "state": "active"
      },
      "txts": {
        "state": "active"
      },
      "menuTotalBordersX": {
        "hide": true
      }
    },
    "compParts": [
      {
        "label": "CustomDesign_ComponentState_Label_Input_Tab",
        "value": "host",
        "additionalPreviewStates": [
          "open"
        ]
      }
    ]
  },
  "wysiwyg.common.components.dropdownmenu.viewer.skins.SolidColorMenuButtonSkin": {
    "params": {
      "bg": {
        "priority": 1
      },
      "rd": {
        "label": "CustomDesign_HorizontalMenu_MenuCornersRadius"
      },
      "brw": {
        "priority": 2
      },
      "brd": {
        "priority": 1
      },
      "bgDrop": {
        "label": "CustomDesign_HorizontalMenu_DropDownBackground",
        "priority": 2
      },
      "rdDrop": {
        "label": "CustomDesign_HorizontalMenu_DropDownCornerRadius"
      },
      "pad": {
        "label": "CustomDesign_HorizontalMenu_ButtonsSpacing",
        "hide": true
      },
      "sep": {
        "category": "fill",
        "label": "CustomDesign_HorizontalMenu_SeparatorsColor",
        "priority": 3
      },
      "bgh": {
        "state": "hover"
      },
      "txth": {
        "state": "hover"
      },
      "bgs": {
        "state": "active"
      },
      "txts": {
        "state": "active"
      }
    },
    "compParts": [
      {
        "label": "CustomDesign_ComponentState_Label_Input_Tab",
        "value": "host",
        "additionalPreviewStates": [
          "open"
        ]
      }
    ]
  },
  "wysiwyg.common.components.dropdownmenu.viewer.skins.TextOnlyMenuButtonBgFixSkin": {
    "params": {
      "menuTotalBordersX": {
        "hide": true
      }
    },
    "compParts": [
      {
        "label": "CustomDesign_ComponentState_Label_Input_Tab",
        "value": "host",
        "additionalPreviewStates": [
          "open"
        ]
      }
    ]
  },
  "wysiwyg.common.components.dropdownmenu.viewer.skins.TextOnlyMenuButtonSkin": {
    "params": {
      "bgDrop": {
        "label": "CustomDesign_HorizontalMenu_DropDownBackground"
      },
      "rd": {
        "label": "CustomDesign_HorizontalMenu_DropDownCornerRadius"
      },
      "pad": {
        "label": "CustomDesign_HorizontalMenu_ButtonsSpacing",
        "hide": true
      },
      "txth": {
        "state": "hover"
      },
      "txts": {
        "state": "active"
      },
      "menuTotalBordersX": {
        "hide": true
      }
    },
    "compParts": [
      {
        "label": "CustomDesign_ComponentState_Label_Input_Tab",
        "value": "host",
        "additionalPreviewStates": [
          "open"
        ]
      }
    ]
  },
  "wysiwyg.common.components.dropdownmenu.viewer.skins.TextSeparatorsMenuButtonSkin": {
    "params": {
      "bgDrop": {
        "label": "CustomDesign_HorizontalMenu_DropDownBackground",
        "priority": 1
      },
      "rd": {
        "label": "CustomDesign_HorizontalMenu_MenuCornersRadius"
      },
      "sep": {
        "category": "fill",
        "label": "CustomDesign_HorizontalMenu_SeparatorsColor",
        "priority": 2
      },
      "pad": {
        "label": "CustomDesign_HorizontalMenu_ButtonsSpacing",
        "hide": true
      },
      "txth": {
        "state": "hover"
      },
      "txts": {
        "state": "active"
      },
      "menuTotalBordersX": {
        "hide": true
      }
    },
    "compParts": [
      {
        "label": "CustomDesign_ComponentState_Label_Input_Tab",
        "value": "host",
        "additionalPreviewStates": [
          "open"
        ]
      }
    ]
  },
  "wysiwyg.viewer.skins.dropmenubutton.VerticalRibbonsMenuButtonNSkin": {
    "params": {
      "els": {
        "hide": true
      },
      "ribbonShd": {
        "hide": true
      }
    }
  },
  "wysiwyg.common.components.dropdownmenu.viewer.skins.VerticalRibbonsMenuButtonSkin": {
    "params": {
      "bg": {
        "priority": 1
      },
      "bgDrop": {
        "label": "CustomDesign_HorizontalMenu_DropDownBackground",
        "priority": 2
      },
      "pad": {
        "label": "CustomDesign_HorizontalMenu_ButtonsSpacing"
      },
      "els": {
        "hide": true
      },
      "bgh": {
        "state": "hover"
      },
      "bgs": {
        "state": "active"
      },
      "txth": {
        "state": "hover"
      },
      "txts": {
        "state": "active"
      },
      "menuTotalBordersX": {
        "hide": true
      },
      "ribbonShd": {
        "hide": true
      }
    },
    "compParts": [
      {
        "label": "CustomDesign_ComponentState_Label_Input_Tab",
        "value": "host",
        "additionalPreviewStates": [
          "open"
        ]
      }
    ]
  },
  "wysiwyg.viewer.skins.line.ArrowLine": {
    "params": {
      "brd": {
        "label": "CustomDesign_LineGeneric_LineColor&Opacity"
      }
    }
  },
  "wysiwyg.viewer.skins.line.ArrowRightLine": {
    "params": {
      "brd": {
        "label": "CustomDesign_LineGeneric_LineColor&Opacity"
      }
    }
  },
  "wysiwyg.viewer.skins.line.DashedLine": {
    "params": {
      "lnw": {
        "label": "CustomDesign_LineGeneric_LineWidth",
        "priority": 2
      },
      "brd": {
        "category": "border",
        "label": "CustomDesign_LineGeneric_LineColor&Opacity",
        "priority": 1
      }
    }
  },
  "wysiwyg.viewer.skins.line.DottedLine": {
    "params": {
      "lnw": {
        "label": "CustomDesign_LineGeneric_LineWidth",
        "priority": 2
      },
      "brd": {
        "category": "border",
        "label": "CustomDesign_LineGeneric_LineColor&Opacity",
        "priority": 1
      }
    }
  },
  "wysiwyg.viewer.skins.line.DoubleLine": {
    "params": {
      "lnw1": {
        "label": "CustomDesign_HorizontalLine_Line1Width",
        "priority": 2
      },
      "brd": {
        "label": "CustomDesign_LineGeneric_LineColor&Opacity",
        "priority": 1
      },
      "lnw2": {
        "label": "CustomDesign_HorizontalLine_Line2Width",
        "priority": 3
      },
      "dst": {
        "label": "CustomDesign_HorizontalLine_LineSpacing"
      }
    }
  },
  "wysiwyg.viewer.skins.line.IronLine": {
    "params": {
      "lnw": {
        "category": "border",
        "label": "CustomDesign_LineGeneric_LineWidth",
        "priority": 2
      },
      "clr": {
        "category": "border",
        "label": "CustomDesign_LineGeneric_LineColor&Opacity",
        "priority": 1
      }
    }
  },
  "wysiwyg.viewer.skins.line.SolidLine": {
    "params": {
      "lnw": {
        "label": "CustomDesign_LineGeneric_LineWidth",
        "priority": 2
      },
      "brd": {
        "category": "border",
        "label": "CustomDesign_LineGeneric_LineColor&Opacity",
        "priority": 1
      }
    }
  },
  "wysiwyg.viewer.skins.line.ZigzagLineFlipSkin": {
    "params": {
      "zigzagsize": {
        "category": "border",
        "label": "CustomDesign_LineGeneric_LineWidth",
        "priority": 2
      },
      "brd": {
        "category": "border",
        "label": "CustomDesign_LineGeneric_LineColor&Opacity",
        "priority": 1
      }
    }
  },
  "wysiwyg.viewer.skins.line.ZigzagLineSkin": {
    "params": {
      "zigzagsize": {
        "category": "border",
        "label": "CustomDesign_LineGeneric_LineWidth",
        "priority": 2
      },
      "brd": {
        "category": "border",
        "label": "CustomDesign_LineGeneric_LineColor&Opacity",
        "priority": 1
      }
    }
  },
  "wysiwyg.viewer.skins.contactform.BasicContactFormSkin": {
    "params": {
      "txt1": {
        "label": "CustomDesign_ContactForm_InputTextColor",
        "section": "section1",
        "priority": 2
      },
      "txtSuccess": {
        "label": "CustomDesign_ContactForm_SuccessTextColor",
        "section": "section3",
        "priority": 1
      },
      "txtError": {
        "category": "text",
        "label": "CustomDesign_ContactForm_ErrorTextColor",
        "section": "section3",
        "priority": 2
      },
      "fnt": {
        "section": "section1",
        "priority": 3
      },
      "bg1": {
        "label": "CustomDesign_ContactForm_FieldBackground"
      },
      "labelTxt": {
        "label": "CustomDesign_ContactForm_LabelTextColor",
        "section": "section1",
        "priority": 1
      },
      "bg2": {
        "label": "CustomDesign_ContactForm_ButtonBackground"
      },
      "fnt2": {
        "section": "section2",
        "priority": 2
      },
      "txt2": {
        "section": "section2",
        "priority": 1
      }
    },
    "sections": {
      "section1": {
        "label": "CustomDesign_ContactForm_FieldTextSection",
        "priority": 1
      },
      "section2": {
        "label": "CustomDesign_ContactForm_ButtonTextSection",
        "priority": 2
      },
      "section3": {
        "label": "CustomDesign_ContactForm_MessageTextSection",
        "priority": 3
      }
    }
  },
  "wysiwyg.viewer.skins.contactform.DefaultContactForm": {
    "params": {
      "txt1": {
        "label": "CustomDesign_ContactForm_InputTextColor",
        "section": "section1",
        "priority": 2
      },
      "txtSuccess": {
        "label": "CustomDesign_ContactForm_SuccessTextColor",
        "section": "section3",
        "priority": 1
      },
      "txtError": {
        "category": "text",
        "label": "CustomDesign_ContactForm_ErrorTextColor",
        "section": "section3",
        "priority": 2
      },
      "bg1": {
        "label": "CustomDesign_ContactForm_FieldBackground"
      },
      "fnt": {
        "section": "section1",
        "priority": 3
      },
      "txt2": {
        "section": "section2",
        "priority": 1
      },
      "bg2": {
        "label": "CustomDesign_ContactForm_ButtonBackground"
      },
      "fnt2": {
        "section": "section2",
        "priority": 2
      },
      "labelTxt": {
        "label": "CustomDesign_ContactForm_LabelTextColor",
        "section": "section1",
        "priority": 1
      }
    },
    "sections": {
      "section1": {
        "label": "CustomDesign_ContactForm_FieldTextSection",
        "priority": 1
      },
      "section2": {
        "label": "CustomDesign_ContactForm_ButtonTextSection",
        "priority": 2
      },
      "section3": {
        "label": "CustomDesign_ContactForm_MessageTextSection",
        "priority": 3
      }
    }
  },
  "wysiwyg.viewer.skins.contactform.VerticalForm": {
    "params": {
      "txt3": {
        "label": "CustomDesign_ContactForm_LabelTextColor",
        "section": "section1",
        "priority": 1
      },
      "fnt": {
        "section": "section1",
        "priority": 3
      },
      "txt1": {
        "label": "CustomDesign_ContactForm_InputTextColor",
        "section": "section1",
        "priority": 2
      },
      "txtSuccess": {
        "label": "CustomDesign_ContactForm_SuccessTextColor",
        "section": "section3",
        "priority": 1
      },
      "txtError": {
        "category": "text",
        "label": "CustomDesign_ContactForm_ErrorTextColor",
        "section": "section3",
        "priority": 2
      },
      "bg1": {
        "label": "CustomDesign_ContactForm_FieldBackground",
        "priority": 1
      },
      "bg2": {
        "label": "CustomDesign_ContactForm_ButtonBackground",
        "priority": 2
      },
      "fnt2": {
        "section": "section2",
        "priority": 2
      },
      "txt2": {
        "section": "section2",
        "priority": 1
      }
    },
    "sections": {
      "section1": {
        "label": "CustomDesign_ContactForm_FieldTextSection",
        "priority": 1
      },
      "section2": {
        "label": "CustomDesign_ContactForm_ButtonTextSection",
        "priority": 2
      },
      "section3": {
        "label": "CustomDesign_ContactForm_MessageTextSection",
        "priority": 3
      }
    }
  },
  "wysiwyg.viewer.skins.contactform.VerticalFormLabelsLeft": {
    "params": {
      "txt3": {
        "label": "CustomDesign_ContactForm_LabelTextColor",
        "section": "section1",
        "priority": 1
      },
      "fnt": {
        "section": "section1",
        "priority": 3
      },
      "bg2": {
        "label": "CustomDesign_ContactForm_ButtonBackground",
        "priority": 2
      },
      "fnt2": {
        "section": "section2",
        "priority": 2
      },
      "txt2": {
        "section": "section2",
        "priority": 1
      },
      "txtSuccess": {
        "label": "CustomDesign_ContactForm_SuccessTextColor",
        "section": "section3",
        "priority": 1
      },
      "txtError": {
        "category": "text",
        "label": "CustomDesign_ContactForm_ErrorTextColor",
        "section": "section3",
        "priority": 2
      },
      "txt1": {
        "label": "CustomDesign_ContactForm_InputTextColor",
        "section": "section1",
        "priority": 2
      },
      "bg1": {
        "label": "CustomDesign_ContactForm_FieldBackground",
        "priority": 1
      }
    },
    "sections": {
      "section1": {
        "label": "CustomDesign_ContactForm_FieldTextSection",
        "priority": 1
      },
      "section2": {
        "label": "CustomDesign_ContactForm_ButtonTextSection",
        "priority": 2
      },
      "section3": {
        "label": "CustomDesign_ContactForm_MessageTextSection",
        "priority": 3
      }
    }
  },
  "contactform.OverlappingButtonSkin": {
    "params": {
      "txt3": {
        "state": "regular",
        "category": "text",
        "label": "CustomDesign_ContactForm_LabelTextColor",
        "section": "section1",
        "priority": 1
      },
      "labelTxt": {
        "label": "CustomDesign_ContactForm_LabelTextColor",
        "section": "section1",
        "priority": 1
      },
      "fnt": {
        "section": "section1",
        "priority": 3
      },
      "rd": {
        "state": "regular",
        "category": "corners",
        "label": "CustomDesign_Corners_CornerRadius",
        "section": "",
        "priority": ""
      },
      "bg2": {
        "label": "CustomDesign_ContactForm_ButtonBackground",
        "priority": 2
      },
      "fnt2": {
        "section": "section2",
        "priority": 2
      },
      "txt2": {
        "section": "section2",
        "priority": 1
      },
      "txtSuccess": {
        "label": "CustomDesign_ContactForm_SuccessTextColor",
        "section": "section3",
        "priority": 1
      },
      "txtError": {
        "label": "CustomDesign_ContactForm_ErrorTextColor",
        "section": "section3",
        "priority": 2
      },
      "brw": {
        "label": "CustomDesign_ContactForm_Field_Border_Width"
      },
      "brw2": {
        "label": "CustomDesign_ContactForm_Button_Border_Width"
      },
      "txt1": {
        "label": "CustomDesign_ContactForm_InputTextColor",
        "section": "section1",
        "priority": 2
      },
      "bg1": {
        "label": "CustomDesign_ContactForm_FieldBackground",
        "priority": 1
      }
    },
    "sections": {
      "section1": {
        "label": "CustomDesign_ContactForm_FieldTextSection",
        "priority": 1
      },
      "section2": {
        "label": "CustomDesign_ContactForm_ButtonTextSection",
        "priority": 2
      },
      "section3": {
        "label": "CustomDesign_ContactForm_MessageTextSection",
        "priority": 3
      }
    }
  },
  "wysiwyg.common.components.subscribeform.viewer.skins.SubscribeFormBoxLayoutEnvelope": {
    "params": {
      "bg3": {
        "priority": 1
      },
      "tfnt": {
        "section": "section1",
        "priority": 2
      },
      "titleColor": {
        "section": "section1",
        "priority": 1
      },
      "efnt": {
        "section": "section4",
        "priority": 3
      },
      "txtError": {
        "category": "text",
        "label": "CustomDesign_SubscribeForm_ErrorMessageColor",
        "section": "section4",
        "priority": 2
      },
      "txt1": {
        "label": "CustomDesign_SubscribeForm_LabelTextColor",
        "section": "section2",
        "priority": 1
      },
      "bg1": {
        "label": "CustomDesign_SubscribeForm_FieldBackground",
        "priority": 2
      },
      "fnt": {
        "section": "section2",
        "priority": 3
      },
      "labelTxt": {
        "label": "CustomDesign_SubscribeForm_InputTextColor",
        "section": "section2",
        "priority": 2
      },
      "txt2": {
        "section": "section3",
        "priority": 1
      },
      "fnt2": {
        "section": "section3",
        "priority": 2
      },
      "bg2": {
        "label": "CustomDesign_SubscribeForm_ButtonBackground",
        "priority": 3
      },
      "txt3": {
        "label": "CustomDesign_SubscribeForm_ButtonTextColor"
      },
      "bg4": {
        "label": "CustomDesign_SubscribeForm_ButtonBackground"
      },
      "txtSuccess": {
        "label": "CustomDesign_SubscribeForm_SuccessMessageColor",
        "section": "section4",
        "priority": 1
      }
    },
    "sections": {
      "section1": {
        "label": "CustomDesign_SubscribeForm_TitleTextSection",
        "priority": 1
      },
      "section2": {
        "label": "CustomDesign_SubscribeForm_FieldTextSection",
        "priority": 2
      },
      "section3": {
        "label": "CustomDesign_SubscribeForm_ButtonTextSection",
        "priority": 3
      },
      "section4": {
        "label": "CustomDesign_SubscribeForm_MessagesTextSection",
        "priority": 4
      }
    }
  },
  "wysiwyg.common.components.subscribeform.viewer.skins.SubscribeFormBoxLayoutFlat": {
    "params": {
      "bg3": {
        "priority": 1
      },
      "tfnt": {
        "section": "section1",
        "priority": 2
      },
      "titleColor": {
        "section": "section1",
        "priority": 1
      },
      "txt1": {
        "label": "CustomDesign_SubscribeForm_InputTextColor",
        "section": "section2",
        "priority": 2
      },
      "bg1": {
        "label": "CustomDesign_SubscribeForm_FieldBackground",
        "priority": 2
      },
      "fnt": {
        "section": "section2",
        "priority": 3
      },
      "txtError": {
        "category": "text",
        "label": "CustomDesign_SubscribeForm_ErrorMessageColor",
        "section": "section4",
        "priority": 2
      },
      "labelTxt": {
        "label": "CustomDesign_SubscribeForm_LabelTextColor",
        "section": "section2",
        "priority": 1
      },
      "txt2": {
        "section": "section3",
        "priority": 1
      },
      "fnt2": {
        "section": "section3",
        "priority": 2
      },
      "bg2": {
        "label": "CustomDesign_SubscribeForm_ButtonBackground",
        "priority": 3
      },
      "txt3": {
        "label": "CustomDesign_SubscribeForm_ButtonTextColor"
      },
      "bg4": {
        "label": "CustomDesign_SubscribeForm_ButtonBackground"
      },
      "efnt": {
        "section": "section4",
        "priority": 4
      },
      "txtSuccess": {
        "label": "CustomDesign_SubscribeForm_SuccessMessageColor",
        "section": "section4",
        "priority": 2
      }
    },
    "sections": {
      "section1": {
        "label": "CustomDesign_SubscribeForm_TitleTextSection",
        "priority": 1
      },
      "section2": {
        "label": "CustomDesign_SubscribeForm_FieldTextSection",
        "priority": 2
      },
      "section3": {
        "label": "CustomDesign_SubscribeForm_ButtonTextSection",
        "priority": 3
      },
      "section4": {
        "label": "CustomDesign_SubscribeForm_MessagesTextSection",
        "priority": 4
      }
    }
  },
  "wysiwyg.common.components.subscribeform.viewer.skins.SubscribeFormBoxLayoutShiny": {
    "params": {
      "bls_bg3": {
        "priority": 1
      },
      "bls_tfnt": {
        "section": "section1",
        "priority": 2
      },
      "bls_titleColor": {
        "section": "section1",
        "priority": 1
      },
      "bls_txt1": {
        "label": "CustomDesign_SubscribeForm_InputTextColor",
        "section": "section2",
        "priority": 2
      },
      "bls_bg1": {
        "label": "CustomDesign_SubscribeForm_FieldBackground",
        "priority": 2
      },
      "bls_fnt": {
        "section": "section2",
        "priority": 3
      },
      "bls_txtError": {
        "category": "text",
        "label": "CustomDesign_SubscribeForm_ErrorMessageColor",
        "section": "section4",
        "priority": 2
      },
      "bls_labelTxt": {
        "label": "CustomDesign_SubscribeForm_LabelTextColor",
        "section": "section2",
        "priority": 1
      },
      "bls_txt2": {
        "section": "section3",
        "priority": 1
      },
      "bls_fnt2": {
        "section": "section3",
        "priority": 2
      },
      "bls_bg2": {
        "label": "CustomDesign_SubscribeForm_ButtonBackground",
        "priority": 3
      },
      "bls_txt3": {
        "label": "CustomDesign_SubscribeForm_ButtonTextColor"
      },
      "bls_bg4": {
        "label": "CustomDesign_SubscribeForm_ButtonBackground"
      },
      "bls_efnt": {
        "section": "section4",
        "priority": 4
      },
      "bls_txtSuccess": {
        "label": "CustomDesign_SubscribeForm_SuccessMessageColor",
        "section": "section4",
        "priority": 1
      }
    },
    "sections": {
      "section1": {
        "label": "CustomDesign_SubscribeForm_TitleTextSection",
        "priority": 1
      },
      "section2": {
        "label": "CustomDesign_SubscribeForm_FieldTextSection",
        "priority": 2
      },
      "section3": {
        "label": "CustomDesign_SubscribeForm_ButtonTextSection",
        "priority": 3
      },
      "section4": {
        "label": "CustomDesign_SubscribeForm_MessagesTextSection",
        "priority": 4
      }
    }
  },
  "wysiwyg.common.components.subscribeform.viewer.skins.SubscribeFormLineLayoutFlat": {
    "params": {
      "llf_bg3": {
        "priority": 1
      },
      "llf_fnt2": {
        "section": "section3",
        "priority": 2
      },
      "llf_txt2": {
        "label": "CustomDesign_SubscribeForm_ButtonTextColor",
        "section": "section3",
        "priority": 1
      },
      "llf_bg2": {
        "label": "CustomDesign_SubscribeForm_ButtonBackground",
        "priority": 3
      },
      "llf_bg4": {
        "label": "CustomDesign_SubscribeForm_ButtonBackground"
      },
      "llf_tfnt": {
        "section": "section1",
        "priority": 2
      },
      "llf_titleColor": {
        "section": "section1",
        "priority": 1
      },
      "llf_txt1": {
        "label": "CustomDesign_SubscribeForm_InputTextColor",
        "section": "section2",
        "priority": 2
      },
      "llf_bg1": {
        "label": "CustomDesign_SubscribeForm_FieldBackground",
        "priority": 2
      },
      "llf_fnt": {
        "section": "section2",
        "priority": 3
      },
      "llf_labelTxt": {
        "label": "CustomDesign_SubscribeForm_LabelTextColor",
        "section": "section2",
        "priority": 1
      },
      "llf_txtError": {
        "category": "text",
        "label": "CustomDesign_SubscribeForm_ErrorMessageColor",
        "section": "section4",
        "priority": 2
      },
      "llf_efnt": {
        "section": "section4",
        "priority": 4
      },
      "llf_txtSuccess": {
        "label": "CustomDesign_SubscribeForm_SuccessMessageColor",
        "section": "section4",
        "priority": 2
      }
    },
    "sections": {
      "section1": {
        "label": "CustomDesign_SubscribeForm_TitleTextSection",
        "priority": 1
      },
      "section2": {
        "label": "CustomDesign_SubscribeForm_FieldTextSection",
        "priority": 2
      },
      "section3": {
        "label": "CustomDesign_SubscribeForm_ButtonTextSection",
        "priority": 3
      },
      "section4": {
        "label": "CustomDesign_SubscribeForm_MessagesTextSection",
        "priority": 4
      }
    }
  },
  "wysiwyg.common.components.subscribeform.viewer.skins.SubscribeFormLineLayoutTransparentWithIcon": {
    "params": {
      "llf_bg3": {
        "priority": 1
      },
      "llf_fnt2": {
        "section": "section3",
        "priority": 2
      },
      "llf_txt2": {
        "section": "section3",
        "priority": 1
      },
      "llf_bg2": {
        "label": "CustomDesign_SubscribeForm_ButtonBackground",
        "priority": 2
      },
      "llf_bg4": {
        "label": "CustomDesign_SubscribeForm_ButtonTextColor"
      },
      "llf_icon_color": {
        "label": "CustomDesign_SubscribeForm_IconColor",
        "priority": 4
      },
      "llf_tfnt": {
        "section": "section1",
        "priority": 2
      },
      "llf_titleColor": {
        "section": "section1",
        "priority": 1
      },
      "llf_txt1": {
        "label": "CustomDesign_SubscribeForm_InputTextColor",
        "section": "section2",
        "priority": 2
      },
      "llf_bg1": {
        "label": "CustomDesign_SubscribeForm_FieldBackground",
        "priority": 3
      },
      "llf_fnt": {
        "section": "section2",
        "priority": 3
      },
      "llf_labelTxt": {
        "label": "CustomDesign_SubscribeForm_LabelTextColor",
        "section": "section2",
        "priority": 1
      },
      "llf_txtError": {
        "category": "text",
        "label": "CustomDesign_SubscribeForm_ErrorMessageColor",
        "section": "section4",
        "priority": 2
      },
      "llf_efnt": {
        "section": "section4",
        "priority": 4
      },
      "llf_txtSuccess": {
        "label": "CustomDesign_SubscribeForm_SuccessMessageColor",
        "section": "section4",
        "priority": 1
      }
    },
    "sections": {
      "section1": {
        "label": "CustomDesign_SubscribeForm_TitleTextSection",
        "priority": 1
      },
      "section2": {
        "label": "CustomDesign_SubscribeForm_FieldTextSection",
        "priority": 2
      },
      "section3": {
        "label": "CustomDesign_SubscribeForm_ButtonTextSection",
        "priority": 3
      },
      "section4": {
        "label": "CustomDesign_SubscribeForm_MessagesTextSection",
        "priority": 4
      }
    }
  },
  "wysiwyg.common.components.subscribeform.viewer.skins.SubscribeFormPlaceholderSkin": {
    "params": {
      "tfnt": {
        "section": "section1",
        "priority": 2
      },
      "titleColor": {
        "section": "section1",
        "priority": 1
      },
      "fnt": {
        "section": "section2",
        "priority": 3
      },
      "txt1": {
        "label": "CustomDesign_SubscribeForm_InputTextColor",
        "section": "section2",
        "priority": 2
      },
      "bg2": {
        "label": "CustomDesign_SubscribeForm_ButtonBackground",
        "priority": 2
      },
      "fnt2": {
        "section": "section3",
        "priority": 2
      },
      "txt2": {
        "section": "section3",
        "priority": 1
      },
      "phs_txt3": {
        "label": "CustomDesign_SubscribeForm_ButtonTextColor"
      },
      "phs_bg4": {
        "label": "CustomDesign_SubscribeForm_ButtonBackground"
      },
      "efnt": {
        "section": "section4",
        "priority": 3
      },
      "txtSuccess": {
        "label": "CustomDesign_SubscribeForm_SuccessMessageColor",
        "section": "section4",
        "priority": 1
      },
      "txtError": {
        "category": "text",
        "label": "CustomDesign_SubscribeForm_ErrorMessageColor",
        "section": "section4",
        "priority": "2"
      },
      "bg1": {
        "label": "CustomDesign_SubscribeForm_FieldBackground",
        "priority": 1
      },
      "labelTxt": {
        "label": "CustomDesign_SubscribeForm_LabelTextColor",
        "section": "section2",
        "priority": 1
      }
    },
    "sections": {
      "section1": {
        "label": "CustomDesign_SubscribeForm_TitleTextSection",
        "priority": 1
      },
      "section2": {
        "label": "CustomDesign_SubscribeForm_FieldTextSection",
        "priority": 2
      },
      "section3": {
        "label": "CustomDesign_SubscribeForm_ButtonTextSection",
        "priority": 3
      },
      "section4": {
        "label": "CustomDesign_SubscribeForm_MessagesTextSection",
        "priority": 4
      }
    }
  },
  "wysiwyg.common.components.subscribeform.viewer.skins.SubscribeFormSkin": {},
  "wysiwyg.common.components.subscribeform.viewer.skins.SubscribeFormSkinTopLabels": {},
  "wysiwyg.viewer.skins.map.GoogleMapDefault": {
    "params": {
      "brd": {
        "category": "border",
        "label": "CustomDesign_Borders_Color&Opacity"
      },
      "brw": {
        "category": "border",
        "label": "CustomDesign_Borders_Width"
      }
    }
  },
  "wysiwyg.viewer.skins.map.GoogleMapLiftedShadow": {
    "params": {
      "brd": {
        "category": "border",
        "label": "CustomDesign_Borders_Color&Opacity"
      },
      "brw": {
        "category": "border",
        "label": "CustomDesign_Borders_Width"
      }
    }
  },
  "wysiwyg.viewer.skins.MediaZoomSkin": {
    "params": {
      "bg": {
        "state": "regular",
        "category": "fill",
        "label": "CustomDesign_FillColor_BGColor",
        "section": "",
        "priority": ""
      }
    }
  },
  "wysiwyg.skins.ImageZoomSkin": {
    "params": {
      "bg": {
        "state": "regular",
        "category": "fill",
        "label": "CustomDesign_FillColor_BGColor",
        "section": "",
        "priority": ""
      }
    }
  },
  "wysiwyg.skins.MobileImageZoomDisplayerSkin": {
    "params": {
      "zoom": {
        "state": "regular",
        "category": "unknownType",
        "label": "unknownType",
        "section": "",
        "priority": ""
      }
    },
    "sections": {}
  },
  "wysiwyg.viewer.skins.MobileMediaZoomSkin": {
    "params": {
      "zoom": {
        "state": "regular",
        "category": "unknownType",
        "label": "unknownType",
        "section": "",
        "priority": ""
      },
      "bg": {
        "state": "regular",
        "category": "fill",
        "label": "CustomDesign_FillColor_BGColor",
        "section": "",
        "priority": ""
      }
    },
    "sections": {}
  },
  "wysiwyg.skins.MobileImageZoomSkin": {
    "params": {
      "zoom": {
        "state": "regular",
        "category": "unknownType",
        "label": "unknownType",
        "section": "",
        "priority": ""
      },
      "bg": {
        "state": "regular",
        "category": "fill",
        "label": "CustomDesign_FillColor_BGColor",
        "section": "",
        "priority": ""
      }
    },
    "sections": {}
  },
  "wysiwyg.viewer.skins.LazySocialPanelSkin": {
    "params": {},
    "sections": {}
  },
  "skins.viewer.gallerymatrix.MatrixGalleryIronSkin": {
    "params": {
      "txt": {
        "label": "CustomDesign_GridGallery_DescriptionColor",
        "section": "section2",
        "priority": 1
      },
      "fntds": {
        "section": "section2",
        "priority": 2
      },
      "bgh": {
        "state": "hover",
        "label": "CustomDesign_GridGallery_MouseoverOverlay"
      },
      "fntt": {
        "section": "section1",
        "priority": 2
      },
      "ttl": {
        "label": "CustomDesign_GridGallery_TitleColor",
        "section": "section1",
        "priority": 1
      },
      "brw": {
        "hide": true
      },
      "ishd": {
        "hide": true
      }
    },
    "sections": {
      "section1": {
        "label": "CustomDesign_GridGallery_TitleSection",
        "priority": 1
      },
      "section2": {
        "label": "CustomDesign_GridGallery_DescriptionSection",
        "priority": 2
      }
    }
  },
  "skins.viewer.gallerymatrix.MatrixGalleryScotchTapeSkin": {
    "params": {
      "txt": {
        "label": "CustomDesign_GridGallery_DescriptionColor",
        "section": "section2",
        "priority": 1
      },
      "fntds": {
        "section": "section2",
        "priority": 2
      },
      "bgh": {
        "state": "hover",
        "label": "CustomDesign_GridGallery_MouseoverOverlay"
      },
      "fntt": {
        "section": "section1",
        "priority": 2
      },
      "ttl": {
        "label": "CustomDesign_GridGallery_TitleColor",
        "section": "section1",
        "priority": 1
      }
    },
    "sections": {
      "section1": {
        "label": "CustomDesign_GridGallery_TitleSection",
        "priority": 1
      },
      "section2": {
        "label": "CustomDesign_GridGallery_DescriptionSection",
        "priority": 2
      }
    }
  },
  "wysiwyg.common.components.matrixgallery.viewer.skins.MatrixGallerySeparateTextBoxSkin": {
    "params": {
      "showMoreClr": {
        "label": "CustomDesign_GridGallery_ShowMoreColor",
        "section": "section3",
        "priority": 1
      },
      "fntds": {
        "section": "section2",
        "priority": 2
      },
      "rd": {
        "label": "CustomDesign_GridGallery_ImageCornerRadius",
        "priority": 1
      },
      "brw": {
        "category": "border",
        "label": "CustomDesign_Borders_Width"
      },
      "bghClr": {
        "state": "hover",
        "label": "CustomDesign_GridGallery_MouseoverOverlay"
      },
      "fntt": {
        "section": "section1",
        "priority": 2
      },
      "ttl2": {
        "label": "CustomDesign_GridGallery_TitleColor",
        "section": "section1",
        "priority": 1
      },
      "txt2": {
        "label": "CustomDesign_GridGallery_DescriptionColor",
        "section": "section2",
        "priority": 1
      },
      "lnkClr": {
        "label": "CustomDesign_GridGallery_LinkColor",
        "section": "section3",
        "priority": 2
      },
      "boxRd": {
        "label": "CustomDesign_GridGallery_TextAreaCornerRadius",
        "priority": 2
      },
      "imgHeightDiff": {
        "label": "CustomDesign_GridGallery_TextBoxHeight",
        "priority": 1,
        "min": 5,
        "max": 200
      },
      "boxbg": {
        "label": "CustomDesign_GridGallery_TextAreaBackground"
      },
      "paddingSize": {
        "label": "CustomDesign_GridGallery_TextBoxPadding",
        "priority": 2,
        "max": 25
      },
      "topPadding": {
        "label": "CustomDesign_GridGallery_ImabeTextBoxSpace",
        "priority": 3,
        "max": 100
      }
    },
    "sections": {
      "section1": {
        "label": "CustomDesign_GridGallery_TitleSection",
        "priority": 1
      },
      "section2": {
        "label": "CustomDesign_GridGallery_DescriptionSection",
        "priority": 2
      },
      "section3": {
        "label": "CustomDesign_GridGallery_OtherTextsSection",
        "priority": 3
      }
    }
  },
  "wysiwyg.common.components.matrixgallery.viewer.skins.MatrixGalleryTextOnCenterSkin": {
    "params": {
      "showMoreColor": {
        "label": "CustomDesign_GridGallery_ShowMoreColor",
        "section": "section3",
        "priority": 1
      },
      "fntds": {
        "section": "section2",
        "priority": 2
      },
      "bgHover": {
        "state": "hover",
        "label": "CustomDesign_GridGallery_MouseoverOverlay"
      },
      "fntt": {
        "section": "section1",
        "priority": 2
      },
      "titleColor": {
        "label": "CustomDesign_GridGallery_TitleColor",
        "section": "section1",
        "priority": 1
      },
      "descColor": {
        "label": "CustomDesign_GridGallery_DescriptionColor",
        "section": "section2",
        "priority": 1
      },
      "linkColor": {
        "category": "text",
        "label": "CustomDesign_GridGallery_LinkColor",
        "section": "section3",
        "priority": 2
      },
      "linkHoverColor": {
        "category": "text",
        "label": "CustomDesign_GridGallery_MouseoverLinkColor"
      }
    },
    "sections": {
      "section1": {
        "label": "CustomDesign_GridGallery_TitleSection",
        "priority": 1
      },
      "section2": {
        "label": "CustomDesign_GridGallery_DescriptionSection",
        "priority": 2
      },
      "section3": {
        "label": "CustomDesign_GridGallery_OtherTextsSection",
        "priority": 3
      }
    }
  },
  "wysiwyg.viewer.skins.gallerymatrix.MatrixGalleryCircleSkin": {
    "params": {
      "txt": {
        "label": "CustomDesign_GridGallery_DescriptionColor",
        "section": "section2",
        "priority": 1
      },
      "fntds": {
        "section": "section2",
        "priority": 2
      },
      "brd": {
        "category": "border",
        "label": "CustomDesign_Borders_Color&Opacity",
        "priority": 1
      },
      "fntt": {
        "section": "section1",
        "priority": 2
      },
      "ttl": {
        "label": "CustomDesign_GridGallery_TitleColor",
        "section": "section1",
        "priority": 1
      },
      "brw": {
        "category": "border",
        "label": "CustomDesign_Borders_Width",
        "priority": 2
      },
      "bgh": {
        "state": "hover",
        "label": "CustomDesign_GridGallery_MouseoverOverlay"
      },
      "rd": {
        "state": "regular",
        "category": "corners",
        "label": "CustomDesign_Corners_CornerRadius",
        "section": "",
        "priority": "",
        "hide": true
      }
    },
    "sections": {
      "section1": {
        "label": "CustomDesign_GridGallery_TitleSection",
        "priority": 1
      },
      "section2": {
        "label": "CustomDesign_GridGallery_DescriptionSection",
        "priority": 2
      }
    }
  },
  "wysiwyg.viewer.skins.gallerymatrix.MatrixGalleryDefaultSkin": {
    "params": {
      "txt": {
        "label": "CustomDesign_GridGallery_DescriptionColor",
        "section": "section2",
        "priority": 1
      },
      "fntds": {
        "section": "section2",
        "priority": 2
      },
      "bgh": {
        "state": "hover",
        "label": "CustomDesign_GridGallery_MouseoverOverlay"
      },
      "fntt": {
        "section": "section1",
        "priority": 2
      },
      "ttl": {
        "label": "CustomDesign_GridGallery_TitleColor",
        "section": "section1",
        "priority": 1
      }
    },
    "sections": {
      "section1": {
        "label": "CustomDesign_GridGallery_TitleSection",
        "priority": 1
      },
      "section2": {
        "label": "CustomDesign_GridGallery_DescriptionSection",
        "priority": 2
      }
    }
  },
  "wysiwyg.viewer.skins.gallerymatrix.MatrixGalleryLiftedShadow": {
    "params": {
      "txt": {
        "label": "CustomDesign_GridGallery_DescriptionColor",
        "section": "section2",
        "priority": 1
      },
      "fntds": {
        "section": "section2",
        "priority": 2
      },
      "bgh": {
        "state": "hover",
        "label": "CustomDesign_GridGallery_MouseoverOverlay"
      },
      "fntt": {
        "section": "section1",
        "priority": 2
      },
      "ttl": {
        "label": "CustomDesign_GridGallery_TitleColor",
        "section": "section1",
        "priority": 1
      }
    },
    "sections": {
      "section1": {
        "label": "CustomDesign_GridGallery_TitleSection",
        "priority": 1
      },
      "section2": {
        "label": "CustomDesign_GridGallery_DescriptionSection",
        "priority": 2
      }
    }
  },
  "wysiwyg.viewer.skins.gallerymatrix.MatrixGallerySloopy": {
    "params": {
      "txt": {
        "label": "CustomDesign_GridGallery_DescriptionColor",
        "section": "section2",
        "priority": 1
      },
      "fntds": {
        "section": "section2",
        "priority": 2
      },
      "bgh": {
        "state": "hover",
        "label": "CustomDesign_GridGallery_MouseoverOverlay"
      },
      "fntt": {
        "section": "section1",
        "priority": 2
      },
      "ttl": {
        "label": "CustomDesign_GridGallery_TitleColor",
        "section": "section1",
        "priority": 1
      },
      "bg": {
        "label": "CustomDesign_GridGallery_BackgroundColor",
        "hide": true
      }
    },
    "sections": {
      "section1": {
        "label": "CustomDesign_GridGallery_TitleSection",
        "priority": 1
      },
      "section2": {
        "label": "CustomDesign_GridGallery_DescriptionSection",
        "priority": 2
      }
    }
  },
  "wysiwyg.viewer.skins.gallerymatrix.MatrixGalleryTextSlideUpSkin": {
    "params": {
      "showMoreColor": {
        "label": "CustomDesign_GridGallery_ShowMoreColor",
        "section": "section3"
      },
      "fntds": {
        "section": "section2",
        "priority": 2
      },
      "bgh": {
        "state": "hover",
        "label": "CustomDesign_GridGallery_MouseoverOverlay"
      },
      "fntt": {
        "section": "section1",
        "priority": 2
      },
      "ttl": {
        "label": "CustomDesign_GridGallery_TitleColor",
        "section": "section1",
        "priority": 1
      },
      "paddingSize": {
        "label": "CustomDesign_GridGallery_TextBoxPadding"
      },
      "txt": {
        "label": "CustomDesign_GridGallery_DescriptionColor",
        "section": "section2",
        "priority": 1
      }
    },
    "sections": {
      "section1": {
        "label": "CustomDesign_GridGallery_TitleSection",
        "priority": 1
      },
      "section2": {
        "label": "CustomDesign_GridGallery_DescriptionSection",
        "priority": 2
      },
      "section3": {
        "label": "CustomDesign_GridGallery_OtherTextsSection",
        "priority": 3
      }
    }
  },
  "wysiwyg.viewer.skins.gallerymatrix.MatrixGalleryTransparentSkin": {
    "params": {
      "showMoreColor": {
        "label": "CustomDesign_GridGallery_ShowMoreColor",
        "section": "section3"
      },
      "fntdsTrans": {
        "section": "section2",
        "priority": 2
      },
      "brw": {
        "priority": 2
      },
      "brdTrans": {
        "state": "regular",
        "priority": 1
      },
      "fntt": {
        "section": "section1",
        "priority": 2
      },
      "ttlTrans": {
        "label": "CustomDesign_GridGallery_TitleColor",
        "section": "section1",
        "priority": 1
      },
      "txtTrans": {
        "label": "CustomDesign_GridGallery_DescriptionColor",
        "section": "section2",
        "priority": 1
      },
      "ttlhTrans": {
        "label": "CustomDesign_GridGallery_TitleColor",
        "priority": 1
      },
      "txthTrans": {
        "label": "CustomDesign_GridGallery_DescriptionColor",
        "priority": 2
      }
    },
    "sections": {
      "section1": {
        "label": "CustomDesign_GridGallery_TitleSection",
        "priority": 1
      },
      "section2": {
        "label": "CustomDesign_GridGallery_DescriptionSection",
        "priority": 2
      },
      "section3": {
        "label": "CustomDesign_GridGallery_OtherTextsSection",
        "priority": 3
      }
    }
  },
  "wysiwyg.viewer.skins.gallerymatrix.PolaroidCustomHeightSkin": {
    "params": {
      "showMoreColor": {
        "section": "section3",
        "priority": 1
      },
      "fntds": {
        "section": "section2",
        "priority": 2
      },
      "imagePadding": {
        "category": "border",
        "label": "CustomDesign_Borders_Width"
      },
      "bgh": {
        "state": "hover",
        "label": "CustomDesign_GridGallery_MouseoverOverlay"
      },
      "imgHeightDiff": {
        "label": "CustomDesign_GridGallery_TextBoxHeight",
        "min": 50,
        "max": 200
      },
      "paddingSize": {
        "label": "CustomDesign_GridGallery_TextBoxPadding"
      },
      "lnk": {
        "section": "section3",
        "priority": 2
      },
      "fntt": {
        "section": "section1",
        "priority": 2
      },
      "ttl": {
        "section": "section1",
        "priority": 1
      },
      "txt": {
        "section": "section2",
        "priority": 1
      }
    },
    "sections": {
      "section1": {
        "label": "CustomDesign_GridGallery_TitleSection",
        "priority": 1
      },
      "section2": {
        "label": "CustomDesign_GridGallery_DescriptionSection",
        "priority": 2
      },
      "section3": {
        "label": "CustomDesign_GridGallery_OtherTextsSection",
        "priority": 3
      }
    }
  },
  "wysiwyg.viewer.skins.gallerymatrix.TextBottomCustomHeightSkin": {
    "params": {
      "showMoreColor": {
        "label": "CustomDesign_GridGallery_ShowMoreColor",
        "section": "section3",
        "priority": 1
      },
      "fntds": {
        "section": "section2",
        "priority": 2
      },
      "brw": {
        "category": "border",
        "label": "CustomDesign_Borders_Width"
      },
      "bgh": {
        "state": "hover",
        "label": "CustomDesign_GridGallery_MouseoverOverlay"
      },
      "fntt": {
        "section": "section1",
        "priority": 2
      },
      "ttl": {
        "label": "CustomDesign_GridGallery_TitleColor",
        "section": "section1",
        "priority": 1
      },
      "txt": {
        "label": "CustomDesign_GridGallery_DescriptionColor",
        "section": "section2",
        "priority": 1
      },
      "lnk": {
        "label": "CustomDesign_GridGallery_LinkColor",
        "section": "section3",
        "priority": 2
      },
      "imgHeightDiff": {
        "label": "CustomDesign_GridGallery_TextBoxHeight",
        "min": 50,
        "max": 200
      },
      "paddingSize": {
        "label": "CustomDesign_GridGallery_TextBoxPadding",
        "max": 25
      }
    },
    "sections": {
      "section1": {
        "label": "CustomDesign_GridGallery_TitleSection",
        "priority": 1
      },
      "section2": {
        "label": "CustomDesign_GridGallery_DescriptionSection",
        "priority": 2
      },
      "section3": {
        "label": "CustomDesign_GridGallery_OtherTextsSection",
        "priority": 3
      }
    }
  },
  "wysiwyg.viewer.skins.dropmenubutton.CirclesMenuButtonNSkin": {
    "params": {
      "nord": {
        "hide": true
      }
    }
  },
  "wysiwyg.viewer.skins.dropmenubutton.SeparatedArrowsMenuButtonNSkin": {},
  "wysiwyg.viewer.skins.MessageViewSkin": {
    "params": {
      "bg": {
        "state": "regular",
        "category": "fill",
        "label": "CustomDesign_FillColor_BGColor",
        "section": "",
        "priority": ""
      }
    }
  },
  "wysiwyg.viewer.skins.MobileMessageViewSkin": {
    "params": {
      "bg": {
        "state": "regular",
        "category": "fill",
        "label": "CustomDesign_FillColor_BGColor",
        "section": "",
        "priority": ""
      }
    }
  },
  "wysiwyg.viewer.skins.paginatedgrid.PaginatedGridArrowsOutside": {
    "params": {
      "fntds": {
        "section": "section3"
      },
      "bg": {
        "state": "hover",
        "label": "CustomDesign_AnimatedGridGallery_MouseoverOverlay"
      },
      "txt": {
        "category": "text",
        "label": "CustomDesign_Text_TextColor",
        "section": "section1",
        "priority": 1
      },
      "fntt": {
        "section": "section2"
      },
      "linkColor": {
        "label": "CustomDesign_AnimatedGridGallery_LinkTextColor",
        "section": "section1",
        "priority": 2
      }
    },
    "sections": {
      "section1": {
        "priority": 1
      },
      "section2": {
        "label": "CustomDesign_AnimatedGridGallery_TitleSection",
        "priority": 2
      },
      "section3": {
        "label": "CustomDesign_AnimatedGridGallery_DescriptionSection",
        "priority": 3
      }
    }
  },
  "wysiwyg.viewer.skins.paginatedgrid.PaginatedGridDefaultSkin": {
    "params": {
      "txt": {
        "category": "text",
        "label": "CustomDesign_Text_TextColor",
        "section": "section1",
        "priority": 1
      },
      "fntds": {
        "section": "section3"
      },
      "bg": {
        "state": "hover",
        "label": "CustomDesign_AnimatedGridGallery_MouseoverOverlay"
      },
      "fntt": {
        "section": "section2"
      },
      "linkColor": {
        "label": "CustomDesign_AnimatedGridGallery_LinkTextColor",
        "section": "section1",
        "priority": 2
      }
    },
    "sections": {
      "section1": {
        "priority": 1
      },
      "section2": {
        "label": "CustomDesign_AnimatedGridGallery_TitleSection",
        "priority": 2
      },
      "section3": {
        "label": "CustomDesign_AnimatedGridGallery_DescriptionSection",
        "priority": 3
      }
    }
  },
  "wysiwyg.viewer.skins.paginatedgrid.PaginatedGridOverlay": {
    "params": {
      "txt": {
        "category": "text",
        "label": "CustomDesign_Text_TextColor",
        "section": "section1",
        "priority": 1
      },
      "fntds": {
        "section": "section3"
      },
      "bg": {
        "state": "hover",
        "label": "CustomDesign_AnimatedGridGallery_MouseoverOverlay"
      },
      "bgh": {
        "label": "CustomDesign_AnimatedGridGallery_TextAreaBackgroundColor"
      },
      "fntt": {
        "section": "section2"
      },
      "linkColor": {
        "label": "CustomDesign_AnimatedGridGallery_LinkTextColor",
        "section": "section1",
        "priority": 2
      }
    },
    "sections": {
      "section1": {
        "priority": 1
      },
      "section2": {
        "label": "CustomDesign_AnimatedGridGallery_TitleSection",
        "priority": 2
      },
      "section3": {
        "label": "CustomDesign_AnimatedGridGallery_DescriptionSection",
        "priority": 3
      }
    }
  },
  "wysiwyg.viewer.skins.paginatedgrid.PaginatedGridRibbonArrows": {
    "params": {
      "fntds": {
        "section": "section3"
      },
      "bg": {
        "state": "hover",
        "label": "CustomDesign_AnimatedGridGallery_MouseoverOverlay"
      },
      "txt": {
        "category": "text",
        "label": "CustomDesign_Text_TextColor",
        "section": "section1",
        "priority": 1
      },
      "fntt": {
        "section": "section2"
      },
      "linkColor": {
        "label": "CustomDesign_AnimatedGridGallery_LinkTextColor",
        "section": "section1",
        "priority": 2
      },
      "bg1": {
        "label": "CustomDesign_AnimatedGridGallery_ArrowsColor"
      }
    },
    "sections": {
      "section1": {
        "priority": 1
      },
      "section2": {
        "label": "CustomDesign_AnimatedGridGallery_TitleSection",
        "priority": 2
      },
      "section3": {
        "label": "CustomDesign_AnimatedGridGallery_DescriptionSection",
        "priority": 3
      }
    }
  },
  "wysiwyg.viewer.skins.paginatedgrid.PaginatedGridTextBottom": {
    "params": {
      "fntds": {
        "section": "section3"
      },
      "bgh": {
        "state": "hover",
        "label": "CustomDesign_AnimatedGridGallery_MouseoverOverlay"
      },
      "txt": {
        "category": "text",
        "label": "CustomDesign_Text_TextColor",
        "section": "section1",
        "priority": 1
      },
      "fntt": {
        "section": "section2"
      },
      "linkColor": {
        "label": "CustomDesign_AnimatedGridGallery_LinkTextColor",
        "section": "section1",
        "priority": 2
      }
    },
    "sections": {
      "section1": {
        "priority": 1
      },
      "section2": {
        "label": "CustomDesign_AnimatedGridGallery_TitleSection",
        "priority": 2
      },
      "section3": {
        "label": "CustomDesign_AnimatedGridGallery_DescriptionSection",
        "priority": 3
      }
    }
  },
  "wysiwyg.common.components.singleaudioplayer.viewer.skins.EPlayerFramedPlay": {
    "params": {
      "brw": {
        "priority": 2
      },
      "brd": {
        "priority": 1
      },
      "bg": {
        "priority": 1
      },
      "audioIcons": {
        "category": "fill",
        "label": "CustomDesign_TrackPlayer_IconColor",
        "priority": 2
      },
      "dividers": {
        "category": "fill",
        "label": "CustomDesign_TrackPlayer_SeparatorsColor",
        "priority": 5
      },
      "fnt": {
        "hide": true
      },
      "fnts": {
        "state": "hover",
        "hide": true
      },
      "iconBG": {
        "state": "regular",
        "category": "border",
        "label": "CustomDesign_TrackPlayer_IconColor",
        "section": "",
        "priority": ""
      },
      "vol": {
        "category": "fill",
        "label": "CustomDesign_TrackPlayer_VolumeColor",
        "priority": 4
      },
      "prog": {
        "label": "CustomDesign_TrackPlayer_ProgressBarColor",
        "priority": 3
      }
    }
  },
  "wysiwyg.common.components.singleaudioplayer.viewer.skins.EPlayerLargePlay": {
    "params": {
      "brd": {
        "priority": 1
      },
      "bg": {
        "priority": 1
      },
      "audioIcons": {
        "category": "fill",
        "label": "CustomDesign_TrackPlayer_IconColor",
        "priority": 2
      },
      "fnt": {
        "hide": true
      },
      "dividers": {
        "label": "CustomDesign_TrackPlayer_SeparatorsColor",
        "priority": 5
      },
      "fnts": {
        "state": "hover",
        "hide": true
      },
      "iconBG": {
        "state": "regular",
        "category": "border",
        "label": "CustomDesign_TrackPlayer_IconColor",
        "section": "",
        "priority": ""
      },
      "vol": {
        "category": "fill",
        "label": "CustomDesign_TrackPlayer_VolumeColor",
        "priority": 4
      },
      "prog": {
        "label": "CustomDesign_TrackPlayer_ProgressBarColor",
        "priority": 3
      }
    }
  },
  "wysiwyg.common.components.singleaudioplayer.viewer.skins.EPlayerRoundPlay": {
    "params": {
      "bg": {
        "priority": 1
      },
      "audioIcons": {
        "category": "fill",
        "label": "CustomDesign_TrackPlayer_IconColor",
        "priority": 2
      },
      "fnt": {
        "hide": true
      },
      "fnts": {
        "state": "hover",
        "hide": true
      },
      "iconBG": {
        "state": "regular",
        "category": "fill",
        "label": "CustomDesign_TrackPlayer_IconColor",
        "section": "",
        "priority": ""
      },
      "vol": {
        "category": "fill",
        "label": "CustomDesign_TrackPlayer_VolumeColor",
        "priority": 4
      },
      "prog": {
        "label": "CustomDesign_TrackPlayer_ProgressBarColor",
        "priority": 3
      }
    }
  },
  "wysiwyg.common.components.singleaudioplayer.viewer.skins.SingleAudioPlayerSkin": {
    "params": {
      "bg": {
        "priority": 1
      },
      "audioIcons": {
        "category": "fill",
        "label": "CustomDesign_TrackPlayer_IconColor",
        "priority": 2
      },
      "fnt": {
        "hide": true
      },
      "fnts": {
        "state": "hover",
        "hide": true
      },
      "iconBG": {
        "state": "regular",
        "category": "fill",
        "label": "CustomDesign_TrackPlayer_IconColor",
        "section": "",
        "priority": ""
      },
      "vol": {
        "category": "fill",
        "label": "CustomDesign_TrackPlayer_VolumeColor",
        "priority": 4
      },
      "prog": {
        "label": "CustomDesign_TrackPlayer_ProgressBarColor",
        "priority": 3
      }
    }
  },
  "wysiwyg.viewer.components.videoBackgroundSkin": {
    "params": {},
    "sections": {}
  },
  "wysiwyg.viewer.skins.button.BasicButton": {
    "params": {
      "fnt": {
        "min": 0,
        "max": 176,
        "stepperMin": 0,
        "stepperMax": 999
      }
    }
  },
  "wysiwyg.viewer.skins.button.BrandButton": {
    "params": {
      "bga": {},
      "brda": {},
      "txta": {}
    }
  },
  "wysiwyg.viewer.skins.button.BorderButton": {
    "params": {
      "brd": {
        "state": "regular",
        "category": "fill",
        "label": "CustomDesign_FillColor_BGColor",
        "section": "",
        "priority": ""
      }
    }
  },
  "wysiwyg.viewer.skins.button.ButtonArrow": {
    "params": {
      "bgh": {
        "category": "fill",
        "label": "CustomDesign_FillColor_BGColor&Opacity"
      },
      "bg": {
        "category": "fill",
        "label": "CustomDesign_FillColor_BGColor&Opacity"
      },
      "fnt": {
        "min": 0,
        "max": 176,
        "stepperMin": 0,
        "stepperMax": 999
      }
    }
  },
  "wysiwyg.viewer.skins.button.ButtonArrowLeft": {
    "params": {
      "bgh": {
        "category": "fill",
        "label": "CustomDesign_FillColor_BGColor&Opacity"
      },
      "bg": {
        "category": "fill",
        "label": "CustomDesign_FillColor_BGColor&Opacity"
      },
      "fnt": {
        "min": 0,
        "max": 176,
        "stepperMin": 0,
        "stepperMax": 999
      }
    }
  },
  "wysiwyg.viewer.skins.button.ButtonInnerShadow": {
    "params": {
      "fnt": {
        "min": 0,
        "max": 176,
        "stepperMin": 0,
        "stepperMax": 999
      }
    }
  },
  "wysiwyg.viewer.skins.button.ButtonLiftedShadow": {
    "params": {
      "fnt": {
        "min": 0,
        "max": 176,
        "stepperMin": 0,
        "stepperMax": 999
      }
    }
  },
  "wysiwyg.viewer.skins.button.ButtonShadowLeft": {
    "params": {
      "fnt": {
        "min": 0,
        "max": 176,
        "stepperMin": 0,
        "stepperMax": 999
      }
    }
  },
  "wysiwyg.viewer.skins.button.ButtonShadowRight": {
    "params": {
      "fnt": {
        "min": 0,
        "max": 176,
        "stepperMin": 0,
        "stepperMax": 999
      }
    }
  },
  "wysiwyg.viewer.skins.button.ButtonThreeD": {
    "params": {
      "fnt": {
        "min": 0,
        "max": 176,
        "stepperMin": 0,
        "stepperMax": 999
      }
    }
  },
  "wysiwyg.viewer.skins.button.CircleButton": {
    "params": {
      "fnt": {
        "min": 0,
        "max": 176,
        "stepperMin": 0,
        "stepperMax": 999
      },
      "rd": {
        "hide": true
      }
    }
  },
  "wysiwyg.viewer.skins.button.GamingButton": {
    "params": {
      "fnt": {
        "min": 0,
        "max": 176,
        "stepperMin": 0,
        "stepperMax": 999
      },
      "rd": {
        "hide": true
      }
    }
  },
  "wysiwyg.viewer.skins.button.IronButton": {
    "params": {
      "fnt": {
        "min": 0,
        "max": 176,
        "stepperMin": 0,
        "stepperMax": 999
      },
      "ishd": {
        "hide": true
      }
    }
  },
  "wysiwyg.viewer.skins.button.RibbonButton": {
    "params": {
      "fnt": {
        "min": 0,
        "max": 176,
        "stepperMin": 0,
        "stepperMax": 999
      }
    }
  },
  "wysiwyg.viewer.skins.button.ScotchTapeButton": {
    "params": {
      "fnt": {
        "min": 0,
        "max": 176,
        "stepperMin": 0,
        "stepperMax": 999
      },
      "brd2": {
        "state": "regular",
        "category": "fill",
        "label": "CustomDesign_FillColor_Color&Opacity",
        "section": "",
        "priority": "",
        "hide": true
      },
      "bg": {
        "state": "regular",
        "category": "fill",
        "label": "CustomDesign_FillColor_Color&Opacity",
        "section": "",
        "priority": "",
        "hide": true
      },
      "brd": {
        "state": "regular",
        "category": "fill",
        "label": "CustomDesign_FillColor_Color&Opacity",
        "section": "",
        "priority": "",
        "hide": true
      }
    }
  },
  "wysiwyg.viewer.skins.button.ShinyButtonIISkin": {
    "params": {
      "fnt": {
        "min": 0,
        "max": 176,
        "stepperMin": 0,
        "stepperMax": 999
      }
    }
  },
  "wysiwyg.viewer.skins.button.ShinyButtonISkin": {
    "params": {
      "fnt": {
        "min": 0,
        "max": 176,
        "stepperMin": 0,
        "stepperMax": 999
      }
    }
  },
  "wysiwyg.viewer.skins.button.ShinyButtonInverted": {
    "params": {
      "fnt": {
        "min": 0,
        "max": 176,
        "stepperMin": 0,
        "stepperMax": 999
      }
    }
  },
  "wysiwyg.viewer.skins.button.SloopyButton": {
    "params": {
      "fnt": {
        "min": 0,
        "max": 176,
        "stepperMin": 0,
        "stepperMax": 999
      }
    }
  },
  "wysiwyg.viewer.skins.button.TextOnlyButtonSkin": {
    "params": {
      "fnt": {
        "min": 0,
        "max": 176,
        "stepperMin": 0,
        "stepperMax": 999
      }
    }
  },
  "skins.viewer.displayers.SlideCleanAndSimple2Displayer": {},
  "skins.viewer.displayers.SlideCleanAndSimpleDisplayer": {},
  "skins.viewer.gallery.SlideShowCleanAndSimple": {
    "params": {
      "brw": {
        "category": "border",
        "label": "CustomDesign_Borders_Width"
      },
      "abg": {
        "label": "CustomDesign_SlideshowGallery_ArrowsColor",
        "priority": 2
      },
      "txt": {
        "section": "section1"
      },
      "abgh": {
        "state": "hover",
        "label": "CustomDesign_SlideshowGallery_MouseoverArrowsColor"
      },
      "bg": {
        "label": "CustomDesign_SlideshowGallery_TextAreaBackgroundColor",
        "priority": 1
      },
      "fntt": {
        "section": "section2"
      },
      "fntds": {
        "section": "section3"
      }
    },
    "sections": {
      "section1": {
        "priority": 1
      },
      "section2": {
        "label": "CustomDesign_SlideshowGallery_TitleSection",
        "priority": 2
      },
      "section3": {
        "label": "CustomDesign_SlideshowGallery_DescriptionSection",
        "priority": 3
      }
    }
  },
  "skins.viewer.gallery.SlideShowCleanAndSimple2": {
    "params": {
      "brw": {
        "category": "border",
        "label": "CustomDesign_Borders_Width"
      },
      "abg": {
        "label": "CustomDesign_SlideshowGallery_ArrowsColor",
        "priority": 2
      },
      "txt": {
        "section": "section1"
      },
      "abgh": {
        "label": "CustomDesign_SlideshowGallery_MouseoverArrowsColor"
      },
      "bg": {
        "label": "CustomDesign_SlideshowGallery_TextAreaBackgroundColor",
        "priority": 1
      },
      "fntt": {
        "section": "section2"
      },
      "fntds": {
        "section": "section3"
      }
    },
    "sections": {
      "section1": {
        "priority": 1
      },
      "section2": {
        "label": "CustomDesign_SlideshowGallery_TitleSection",
        "priority": 2
      },
      "section3": {
        "label": "CustomDesign_SlideshowGallery_DescriptionSection",
        "priority": 3
      }
    }
  },
  "skins.viewer.gallery.SlideShowIron": {
    "params": {
      "brd": {
        "label": "CustomDesign_SlideshowGallery_FrameColor",
        "priority": 2
      },
      "abg": {
        "label": "CustomDesign_SlideshowGallery_ArrowsColor",
        "priority": 3
      },
      "txt": {
        "section": "section1"
      },
      "imageWrapperTop": {
        "hide": true
      },
      "imageWrapperRight": {
        "hide": true
      },
      "imageWrapperBottom": {
        "hide": true
      },
      "imageWrapperLeft": {
        "hide": true
      },
      "bg": {
        "label": "CustomDesign_SlideshowGallery_TextAreaBackgroundColor",
        "priority": 1
      },
      "fntt": {
        "section": "section2"
      },
      "fntds": {
        "section": "section3"
      },
      "ishdd": {
        "hide": true
      },
      "ishd": {
        "hide": true
      }
    },
    "sections": {
      "section1": {
        "priority": 1
      },
      "section2": {
        "label": "CustomDesign_SlideshowGallery_TitleSection",
        "priority": 2
      },
      "section3": {
        "label": "CustomDesign_SlideshowGallery_DescriptionSection",
        "priority": 3
      }
    }
  },
  "skins.viewer.gallery.SlideShowScotchTape": {
    "params": {
      "brw": {
        "category": "border",
        "label": "CustomDesign_Borders_Width"
      },
      "txt": {
        "section": "section1"
      },
      "bg": {
        "label": "CustomDesign_SlideshowGallery_TextAreaBackgroundColor"
      },
      "fntt": {
        "section": "section2"
      },
      "fntds": {
        "section": "section3"
      }
    },
    "sections": {
      "section1": {
        "priority": 1
      },
      "section2": {
        "label": "CustomDesign_SlideshowGallery_TitleSection",
        "priority": 2
      },
      "section3": {
        "label": "CustomDesign_SlideshowGallery_DescriptionSection",
        "priority": 3
      }
    }
  },
  "wysiwyg.viewer.skins.gallery.SlideShowGalleryLiftedShadowSkin": {
    "params": {
      "brw": {
        "category": "border",
        "label": "CustomDesign_Borders_Width"
      },
      "txt": {
        "section": "section1"
      },
      "bg": {
        "label": "CustomDesign_SlideshowGallery_TextAreaBackgroundColor"
      },
      "fntt": {
        "section": "section2"
      },
      "fntds": {
        "section": "section3"
      }
    },
    "sections": {
      "section1": {
        "priority": 1
      },
      "section2": {
        "label": "CustomDesign_SlideshowGallery_TitleSection",
        "priority": 2
      },
      "section3": {
        "label": "CustomDesign_SlideshowGallery_DescriptionSection",
        "priority": 3
      }
    }
  },
  "wysiwyg.viewer.skins.gallery.SlideShowGallerySloopy": {
    "params": {
      "txt": {
        "section": "section1"
      },
      "bg": {
        "label": "CustomDesign_SlideshowGallery_TextAreaBackgroundColor"
      },
      "fntt": {
        "section": "section2"
      },
      "fntds": {
        "section": "section3"
      }
    },
    "sections": {
      "section1": {
        "priority": 1
      },
      "section2": {
        "label": "CustomDesign_SlideshowGallery_TitleSection",
        "priority": 2
      },
      "section3": {
        "label": "CustomDesign_SlideshowGallery_DescriptionSection",
        "priority": 3
      }
    }
  },
  "wysiwyg.viewer.skins.gallery.SlideShowPolaroid": {
    "params": {
      "bg": {
        "label": "CustomDesign_SlideshowGallery_BackgdroundColor"
      },
      "txt": {
        "section": "section1"
      },
      "brw": {
        "category": "border",
        "label": "CustomDesign_Borders_Width"
      },
      "fntds": {
        "section": "section3"
      },
      "fntt": {
        "section": "section2"
      }
    },
    "sections": {
      "section1": {
        "priority": 1
      },
      "section2": {
        "label": "CustomDesign_SlideshowGallery_TitleSection",
        "priority": 2
      },
      "section3": {
        "label": "CustomDesign_SlideshowGallery_DescriptionSection",
        "priority": 3
      }
    }
  },
  "wysiwyg.viewer.skins.gallery.SlideShowTextBottom": {
    "params": {
      "txt": {
        "section": "section1"
      },
      "brw": {
        "category": "border",
        "label": "CustomDesign_Borders_Width"
      },
      "fntt": {
        "section": "section2"
      },
      "fntds": {
        "section": "section3"
      }
    },
    "sections": {
      "section1": {
        "priority": 1
      },
      "section2": {
        "label": "CustomDesign_SlideshowGallery_TitleSection",
        "priority": 2
      },
      "section3": {
        "label": "CustomDesign_SlideshowGallery_DescriptionSection",
        "priority": 3
      }
    }
  },
  "wysiwyg.viewer.skins.gallery.SlideShowTextFloating": {
    "params": {
      "brw": {
        "category": "border",
        "label": "CustomDesign_Borders_Width"
      },
      "txt": {
        "section": "section1"
      },
      "bg": {
        "label": "CustomDesign_SlideshowGallery_TextAreaBackgroundColor"
      },
      "fntt": {
        "section": "section2"
      },
      "fntds": {
        "section": "section3"
      }
    },
    "sections": {
      "section1": {
        "priority": 1
      },
      "section2": {
        "label": "CustomDesign_SlideshowGallery_TitleSection",
        "priority": 2
      },
      "section3": {
        "label": "CustomDesign_SlideshowGallery_DescriptionSection",
        "priority": 3
      }
    }
  },
  "wysiwyg.viewer.skins.gallery.SlideShowTextOverlay": {
    "params": {
      "brw": {
        "category": "border",
        "label": "CustomDesign_Borders_Width"
      },
      "txt": {
        "section": "section1"
      },
      "bg": {
        "label": "CustomDesign_SlideshowGallery_TextAreaBackgroundColor"
      },
      "fntt": {
        "section": "section2"
      },
      "fntds": {
        "section": "section3"
      }
    },
    "sections": {
      "section1": {
        "priority": 1
      },
      "section2": {
        "label": "CustomDesign_SlideshowGallery_TitleSection",
        "priority": 2
      },
      "section3": {
        "label": "CustomDesign_SlideshowGallery_DescriptionSection",
        "priority": 3
      }
    }
  },
  "wysiwyg.viewer.skins.gallery.SlideShowTextRight": {
    "params": {
      "brd": {
        "category": "border",
        "label": "CustomDesign_Borders_Color&Opacity"
      },
      "txt": {
        "section": "section1"
      },
      "brw": {
        "category": "border",
        "label": "CustomDesign_Borders_Width"
      },
      "bgc": {
        "label": "CustomDesign_SlideshowGallery_TextAreaBackgroundColor"
      },
      "fntt": {
        "section": "section2"
      },
      "fntds": {
        "section": "section3"
      }
    },
    "sections": {
      "section1": {
        "priority": 1
      },
      "section2": {
        "label": "CustomDesign_SlideshowGallery_TitleSection",
        "priority": 2
      },
      "section3": {
        "label": "CustomDesign_SlideshowGallery_DescriptionSection",
        "priority": 3
      }
    }
  },
  "skins.viewer.galleryslider.SliderDisplayerIronSkin2": {
    "params": {
      "ishd": {
        "hide": true
      }
    }
  },
  "wysiwyg.viewer.skins.galleryslider.SliderGalleryCircleSkin": {
    "params": {
      "brd": {
        "category": "border"
      },
      "brw": {
        "category": "border",
        "label": "CustomDesign_Borders_Width"
      },
      "rd": {
        "state": "regular",
        "hide": true
      },
      "bg": {
        "label": "CustomDesign_SliderGallery_MouseoverOverlay"
      },
      "fntds": {
        "priority": 2
      },
      "txt": {
        "label": "CustomDesign_SliderGallery_LinkTextColor",
        "priority": 1
      }
    }
  },
  "wysiwyg.viewer.skins.galleryslider.SliderGalleryDefaultSkin": {
    "params": {
      "rd": {
        "state": "regular"
      },
      "bg": {
        "label": "CustomDesign_SliderGallery_MouseoverOverlay"
      },
      "txt": {
        "label": "CustomDesign_SliderGallery_LinkTextColor"
      }
    }
  },
  "wysiwyg.viewer.skins.galleryslider.SliderGalleryIronSkin": {
    "params": {
      "ishd": {
        "hide": true
      },
      "brd": {
        "category": "border"
      },
      "bg": {
        "label": "CustomDesign_SliderGallery_MouseoverOverlay"
      },
      "txt": {
        "label": "CustomDesign_SliderGallery_LinkTextColor"
      }
    }
  },
  "wysiwyg.viewer.skins.galleryslider.SliderGalleryNoArrow": {
    "params": {
      "rd": {
        "state": "regular"
      },
      "bg": {
        "label": "CustomDesign_SliderGallery_MouseoverOverlay"
      },
      "txt": {
        "label": "CustomDesign_SliderGallery_LinkTextColor"
      }
    }
  },
  "wysiwyg.viewer.skins.galleryslider.SliderGalleryScotchTapeSkin": {
    "params": {
      "bg": {
        "label": "CustomDesign_SliderGallery_MouseoverOverlay"
      },
      "txt": {
        "label": "CustomDesign_SliderGallery_LinkTextColor"
      }
    }
  },
  "skins.viewer.svgshape.SvgShapeDefaultSkin": {
    "params": {
      "fillcolor": {
        "label": "CustomDesign_FillColor_BGColor&Opacity"
      }
    }
  },
  "wysiwyg.viewer.skins.input.CheckboxBasicSkin": {
    "params": {
      "bgc": {
        "state": "regular",
        "label": "CustomDesign_InputControls_Checkmark_Color",
        "type": "BG_COLOR"
      },
      "bgch": {
        "label": "CustomDesign_InputControls_Checkmark_Color",
        "type": "BG_COLOR"
      },
      "bgcd": {
        "label": "CustomDesign_InputControls_Checkmark_Color"
      }
    }
  },
  "wysiwyg.viewer.skins.line.VerticalArrowLine": {
    "params": {
      "brd": {
        "label": "CustomDesign_LineGeneric_LineColor&Opacity"
      }
    }
  },
  "wysiwyg.viewer.skins.line.VerticalArrowLineTop": {
    "params": {
      "brd": {
        "label": "CustomDesign_LineGeneric_LineColor&Opacity"
      }
    }
  },
  "wysiwyg.viewer.skins.line.VerticalDashedLine": {
    "params": {
      "lnw": {
        "label": "CustomDesign_LineGeneric_LineWidth",
        "priority": 2
      },
      "brd": {
        "category": "border",
        "label": "CustomDesign_LineGeneric_LineColor&Opacity",
        "priority": 1
      }
    }
  },
  "wysiwyg.viewer.skins.line.VerticalDottedLine": {
    "params": {
      "lnw": {
        "label": "CustomDesign_LineGeneric_LineWidth",
        "priority": 2
      },
      "brd": {
        "category": "border",
        "label": "CustomDesign_LineGeneric_LineColor&Opacity",
        "priority": 1
      }
    }
  },
  "wysiwyg.viewer.skins.line.VerticalDoubleLine": {
    "params": {
      "lnw1": {
        "label": "CustomDesign_VerticalLine_Line1Width"
      },
      "brd": {
        "label": "CustomDesign_LineGeneric_LineColor&Opacity"
      },
      "lnw2": {
        "label": "CustomDesign_VerticalLine_Line2Width"
      },
      "dst": {
        "label": "CustomDesign_VerticalLine_LineSpacing"
      }
    }
  },
  "wysiwyg.viewer.skins.line.VerticalIronLine": {
    "params": {
      "lnw": {
        "category": "border",
        "label": "CustomDesign_LineGeneric_LineWidth",
        "priority": 2
      },
      "clr": {
        "category": "border",
        "label": "CustomDesign_LineGeneric_LineColor&Opacity",
        "priority": 1
      }
    }
  },
  "wysiwyg.viewer.skins.line.VerticalNotchDashedLine": {
    "params": {
      "lnw": {
        "label": "CustomDesign_LineGeneric_LineWidth"
      }
    }
  },
  "wysiwyg.viewer.skins.line.VerticalNotchLine": {
    "params": {
      "lnw": {
        "label": "CustomDesign_LineGeneric_LineWidth",
        "hide": true
      }
    }
  },
  "wysiwyg.viewer.skins.line.VerticalSolidLine": {
    "params": {
      "lnw": {
        "label": "CustomDesign_LineGeneric_LineWidth",
        "priority": 2
      },
      "brd": {
        "category": "border",
        "label": "CustomDesign_LineGeneric_LineColor&Opacity",
        "priority": 1
      }
    }
  },
  "wysiwyg.common.components.verticalmenu.viewer.skins.VerticalMenuArrowSkin": {
    "params": {
      "textSpacing": {
        "min": 0,
        "max": 30
      }
    }
  },
  "wysiwyg.common.components.verticalmenu.viewer.skins.VerticalMenuRibbonsSkin": {
    "params": {
      "textSpacing": {
        "min": 0,
        "max": 30
      }
    }
  },
  "wysiwyg.viewer.skins.ExpandableMenuSkin": {
    "params": {
      "bgexpanded": {
        "category": "fill",
        "label": "CustomDesign_FillColor_BGColor&Opacity",
        "section": "section2",
        "priority": 1
      },
      "menuSpacing": {
        "label": "CustomDesign_VerticalMenu_TextOffset",
        "max": 100,
        "priority": 2
      },
      "subMenuSpacing": {
        "label": "CustomDesign_VerticalMenu_SubmenuOffset",
        "max": 100,
        "priority": 3
      },
      "fnt": {
        "section": "input",
        "min": 6,
        "max": 100
      },
      "fntSubMenu": {
        "section": "section2",
        "priority": 3,
        "min": 6,
        "max": 100
      },
      "txtexpanded": {
        "section": "section2",
        "priority": 2
      },
      "arrowColor": {
        "category": "fill",
        "label": "CustomDesign_FillColor_BGColor&Opacity",
        "section": "section3"
      },
      "bgs": {
        "state": "selected",
        "category": "fill",
        "label": "CustomDesign_FillColor_BGColor&Opacity",
        "section": "section1"
      },
      "txts": {
        "state": "selected",
        "section": "section1"
      },
      "bgsSub": {
        "state": "selected",
        "category": "fill",
        "label": "CustomDesign_VerticalMenu_SubMenuBackground",
        "section": "section2"
      },
      "txtsSub": {
        "state": "selected",
        "section": "section2"
      }
    },
    "sections": {
      "section1": {
        "priority": 1
      },
      "section2": {
        "label": "CustomDesign_Expandable_Menu_Submenu_Divider_Label",
        "priority": 2
      },
      "section3": {
        "label": "CustomDesign_Expandable_Menu_Arrow_Label",
        "priority": 3
      }
    }
  },
  "wysiwyg.viewer.skins.ResponsiveExpandableMenuSkin": {
    "params": {
      "bgexpanded": {
        "category": "fill",
        "label": "CustomDesign_FillColor_BGColor&Opacity",
        "section": "section2",
        "priority": 1
      },
      "menuSpacing": {
        "label": "CustomDesign_VerticalMenu_TextOffset",
        "max": 100,
        "priority": 2
      },
      "subMenuSpacing": {
        "label": "CustomDesign_VerticalMenu_SubmenuOffset",
        "max": 100,
        "priority": 3
      },
      "fnt": {
        "section": "input",
        "min": 6,
        "max": 100
      },
      "fntSubMenu": {
        "section": "section2",
        "priority": 3,
        "min": 6,
        "max": 100
      },
      "txtexpanded": {
        "section": "section2",
        "priority": 2
      },
      "arrowColor": {
        "category": "fill",
        "label": "CustomDesign_FillColor_BGColor&Opacity",
        "section": "section3"
      },
      "bgs": {
        "state": "selected",
        "category": "fill",
        "label": "CustomDesign_FillColor_BGColor&Opacity",
        "section": "section1"
      },
      "txts": {
        "state": "selected",
        "section": "section1"
      },
      "bgsSub": {
        "state": "selected",
        "category": "fill",
        "label": "CustomDesign_VerticalMenu_SubMenuBackground",
        "section": "section2"
      },
      "txtsSub": {
        "state": "selected",
        "section": "section2"
      }
    },
    "sections": {
      "section1": {
        "priority": 1
      },
      "section2": {
        "label": "CustomDesign_Expandable_Menu_Submenu_Divider_Label",
        "priority": 2
      },
      "section3": {
        "label": "CustomDesign_Expandable_Menu_Arrow_Label",
        "priority": 3
      }
    }
  },
  "wysiwyg.common.components.verticalmenu.viewer.skins.VerticalMenuSeparatedButtonFixedWidthSkin": {
    "params": {
      "SKINS_submenuMargin": {
        "label": "CustomDesign_VerticalMenu_SubmenuOffset",
        "priority": 3
      },
      "SKINS_fntSubmenu": {
        "section": "section3"
      },
      "SKINS_bgSubmenu": {
        "label": "CustomDesign_VerticalMenu_SubMenuBackground",
        "priority": 2
      },
      "SKINS_submenuBR": {
        "label": "CustomDesign_VerticalMenu_SubMenuCornerRadius",
        "priority": 2
      },
      "rd": {
        "label": "CustomDesign_VerticalMenu_MenuCornersRadius",
        "priority": 1
      },
      "textSpacing": {
        "label": "CustomDesign_VerticalMenu_TextOffset",
        "priority": 2,
        "min": 0,
        "max": 30
      },
      "bg": {
        "priority": 1
      },
      "bgs": {
        "state": "active"
      },
      "txts": {
        "state": "active"
      },
      "bgh": {
        "state": "hover"
      },
      "txth": {
        "state": "hover"
      },
      "fnt": {
        "section": "section2"
      },
      "txt": {
        "section": "section1"
      },
      "separatorHeight": {
        "label": "CustomDesign_VerticalMenu_ButtonsSpacing",
        "priority": 1
      }
    },
    "sections": {
      "section1": {
        "priority": 1
      },
      "section2": {
        "label": "CustomDesign_VerticalMenu_MenuTextSection",
        "priority": 2
      },
      "section3": {
        "label": "CustomDesign_VerticalMenu_SubMenuTextSection",
        "priority": 3
      }
    }
  },
  "wysiwyg.common.components.verticalmenu.viewer.skins.VerticalMenuSeparatedButtonSkin": {
    "params": {
      "SKINS_fntSubmenu": {
        "section": "section3"
      },
      "SKINS_bgSubmenu": {
        "label": "CustomDesign_VerticalMenu_SubMenuBackground",
        "priority": 2
      },
      "SKINS_submenuBR": {
        "label": "CustomDesign_VerticalMenu_SubMenuCornerRadius",
        "priority": 2
      },
      "SKINS_submenuMargin": {
        "label": "CustomDesign_VerticalMenu_SubmenuOffset",
        "priority": 2
      },
      "rd": {
        "label": "CustomDesign_VerticalMenu_MenuCornersRadius",
        "priority": 1
      },
      "textSpacing": {
        "label": "CustomDesign_VerticalMenu_TextOffset",
        "priority": 3,
        "min": 0,
        "max": 30
      },
      "bg": {
        "priority": 1
      },
      "bgs": {
        "state": "active"
      },
      "txts": {
        "state": "active"
      },
      "bgh": {
        "state": "hover"
      },
      "txth": {
        "state": "hover"
      },
      "fnt": {
        "section": "section2"
      },
      "txt": {
        "section": "section1"
      },
      "separatorHeight": {
        "label": "CustomDesign_VerticalMenu_ButtonsSpacing",
        "priority": 1
      }
    },
    "sections": {
      "section1": {
        "priority": 1
      },
      "section2": {
        "label": "CustomDesign_VerticalMenu_MenuTextSection",
        "priority": 2
      },
      "section3": {
        "label": "CustomDesign_VerticalMenu_SubMenuTextSection",
        "priority": 3
      }
    }
  },
  "wysiwyg.common.components.verticalmenu.viewer.skins.VerticalMenuSolidColorSkin": {
    "params": {
      "brw": {
        "section": "section1",
        "priority": 2
      },
      "brd": {
        "section": "section1",
        "priority": 1
      },
      "rd": {
        "label": "CustomDesign_VerticalMenu_MenuCornersRadius",
        "priority": 1
      },
      "sepw": {
        "label": "CustomDesign_Borders_Width",
        "section": "section2",
        "priority": 2
      },
      "sep": {
        "category": "border",
        "section": "section2",
        "priority": 1
      },
      "bgh": {
        "state": "hover"
      },
      "txth": {
        "state": "hover"
      },
      "bgs": {
        "state": "active"
      },
      "txts": {
        "state": "active"
      },
      "textSpacing": {
        "label": "CustomDesign_VerticalMenu_TextOffset",
        "priority": 1,
        "min": 0,
        "max": 30
      },
      "bg": {
        "priority": 1
      },
      "fnt": {
        "section": "section4",
        "priority": 1
      },
      "txt": {
        "section": "section3",
        "priority": 1
      },
      "SKINS_submenuBR": {
        "label": "CustomDesign_VerticalMenu_SubMenuCornerRadius",
        "priority": 2
      },
      "SKINS_bgSubmenu": {
        "label": "CustomDesign_VerticalMenu_SubMenuBackground",
        "priority": 2
      },
      "SKINS_fntSubmenu": {
        "section": "section5",
        "priority": 1
      },
      "SKINS_submenuMargin": {
        "label": "CustomDesign_VerticalMenu_SubmenuOffset",
        "priority": 2
      }
    },
    "sections": {
      "section1": {
        "label": "CustomDesign_VerticalMenu_BordersSection",
        "priority": 1
      },
      "section2": {
        "label": "CustomDesign_VerticalMenu_SeparatorsSection",
        "priority": 2
      },
      "section3": {
        "priority": 3
      },
      "section4": {
        "label": "CustomDesign_VerticalMenu_MenuTextSection",
        "priority": 4
      },
      "section5": {
        "label": "CustomDesign_VerticalMenu_SubMenuTextSection",
        "priority": 5
      }
    }
  },
  "wysiwyg.common.components.verticalmenu.viewer.skins.VerticalMenuTextSkin": {
    "params": {
      "textSpacing": {
        "label": "CustomDesign_VerticalMenu_TextOffset",
        "priority": 1,
        "min": 0,
        "max": 30
      },
      "txth": {
        "state": "hover"
      },
      "txts": {
        "state": "active"
      },
      "fnt": {
        "section": "section2"
      },
      "txt": {
        "section": "section1"
      },
      "SKINS_bgSubmenu": {
        "label": "CustomDesign_VerticalMenu_SubMenuBackground"
      },
      "SKINS_fntSubmenu": {
        "section": "section3"
      },
      "SKINS_submenuMargin": {
        "label": "CustomDesign_VerticalMenu_SubmenuOffset",
        "priority": 2
      }
    },
    "sections": {
      "section1": {
        "priority": 1
      },
      "section2": {
        "label": "CustomDesign_VerticalMenu_MenuTextSection",
        "priority": 2
      },
      "section3": {
        "label": "CustomDesign_VerticalMenu_SubMenuTextSection",
        "priority": 3
      }
    }
  },
  "wysiwyg.common.components.verticalmenu.viewer.skins.VerticalMenuTextWithSeparatorsSkin": {
    "params": {
      "sepw": {
        "label": "CustomDesign_VerticalMenu_SeparatorsWidth"
      },
      "textSpacing": {
        "label": "CustomDesign_VerticalMenu_TextOffset",
        "priority": 2,
        "min": 0,
        "max": 30
      },
      "bgh": {
        "state": "hover"
      },
      "txth": {
        "state": "hover"
      },
      "bgs": {
        "state": "active"
      },
      "txts": {
        "state": "active"
      },
      "fnt": {
        "section": "section2"
      },
      "txt": {
        "section": "section1"
      },
      "SKINS_bgSubmenu": {
        "label": "CustomDesign_VerticalMenu_SubMenuBackground"
      },
      "SKINS_fntSubmenu": {
        "section": "section3"
      },
      "SKINS_submenuMargin": {
        "label": "CustomDesign_VerticalMenu_SubmenuOffset",
        "priority": 1
      }
    },
    "sections": {
      "section1": {
        "priority": 1
      },
      "section2": {
        "label": "CustomDesign_VerticalMenu_MenuTextSection",
        "priority": 2
      },
      "section3": {
        "label": "CustomDesign_VerticalMenu_SubMenuTextSection",
        "priority": 3
      }
    }
  },
  "wysiwyg.viewer.skins.video.VideoDefault": {
    "params": {
      "brd": {
        "category": "border",
        "label": "CustomDesign_Borders_Color&Opacity",
        "priority": 1
      },
      "brw": {
        "category": "border",
        "label": "CustomDesign_Borders_Width",
        "priority": 2
      }
    }
  },
  "wysiwyg.viewer.skins.video.VideoLiftedShadow": {
    "params": {
      "brd": {
        "category": "border",
        "label": "CustomDesign_Borders_Color&Opacity",
        "priority": 1
      },
      "brw": {
        "category": "border",
        "label": "CustomDesign_Borders_Width",
        "priority": 2
      }
    }
  },
  "wysiwyg.viewer.skins.videoBackgroundSkin": {
    "params": {},
    "sections": {}
  },
  "wysiwyg.viewer.skins.photo.DoubleBorderPhoto": {
    "params": {
      "bg": {
        "label": "CustomDesign_Image_BackgroundColor"
      },
      "mrg1": {
        "category": "border",
        "label": "CustomDesign_Image_ImageSpacing",
        "section": "section3"
      },
      "brd1": {
        "section": "section1"
      },
      "sz1": {
        "section": "section1"
      },
      "brd2": {
        "section": "section2"
      },
      "sz2": {
        "section": "section2"
      },
      "mrg2": {
        "category": "border",
        "label": "CustomDesign_Image_BordersSpacing",
        "section": "section3"
      }
    },
    "sections": {
      "section1": {
        "label": "CustomDesign_Image_OuterBorderSection",
        "priority": 1
      },
      "section2": {
        "label": "CustomDesign_Image_InnerBorderSection",
        "priority": 2
      },
      "section3": {
        "label": "CustomDesign_Image_SpacingSection",
        "priority": 3
      }
    }
  },
  "wysiwyg.viewer.skins.photo.IronPhoto": {
    "params": {
      "bg": {
        "category": "border",
        "label": "CustomDesign_Borders_Color&Opacity"
      }
    }
  },
  "wysiwyg.viewer.skins.photo.MouseOverPhoto": {
    "params": {
      "bgh": {
        "state": "hover",
        "label": "CustomDesign_Image_MouseoverOverlay"
      }
    }
  },
  "wysiwyg.viewer.skins.photo.NewPolaroidPhoto": {
    "params": {
      "bg": {
        "category": "border",
        "label": "CustomDesign_Borders_Color&Opacity"
      }
    }
  },
  "wysiwyg.viewer.skins.wixadsskins.WixAdsBaseSkin": {
    "params": {
      "rd1": {
        "state": "regular",
        "category": "corners",
        "label": "CustomDesign_Corners_CornerRadius",
        "section": "",
        "priority": ""
      },
      "brw": {
        "state": "regular",
        "category": "border",
        "label": "CustomDesign_Borders_Width",
        "section": "",
        "priority": ""
      },
      "brd": {
        "state": "regular",
        "category": "border",
        "label": "CustomDesign_Borders_Color&Opacity",
        "section": "",
        "priority": ""
      },
      "rd2": {
        "state": "regular",
        "category": "corners",
        "label": "CustomDesign_Corners_CornerRadius",
        "section": "",
        "priority": ""
      },
      "nord": {
        "state": "hover",
        "category": "corners",
        "label": "CustomDesign_Corners_CornerRadius",
        "section": "",
        "priority": "",
        "hide": true
      },
      "bg": {
        "state": "regular",
        "category": "fill",
        "label": "CustomDesign_FillColor_BGColor",
        "section": "",
        "priority": ""
      }
    },
    "sections": {}
  },
  "wysiwyg.viewer.skins.wixadsskins.WixAdsFacebookPreviewSkin": {
    "params": {
      "rd1": {
        "state": "regular",
        "category": "corners",
        "label": "CustomDesign_Corners_CornerRadius",
        "section": "",
        "priority": ""
      },
      "brw": {
        "state": "regular",
        "category": "border",
        "label": "CustomDesign_Borders_Width",
        "section": "",
        "priority": ""
      },
      "brd": {
        "state": "regular",
        "category": "border",
        "label": "CustomDesign_Borders_Color&Opacity",
        "section": "",
        "priority": ""
      },
      "rd2": {
        "state": "regular",
        "category": "corners",
        "label": "CustomDesign_Corners_CornerRadius",
        "section": "",
        "priority": ""
      },
      "bg": {
        "state": "regular",
        "category": "fill",
        "label": "CustomDesign_FillColor_BGColor",
        "section": "",
        "priority": ""
      }
    },
    "sections": {}
  },
  "wysiwyg.viewer.skins.wixadsskins.WixAdsRoundSkin": {
    "params": {
      "rd1": {
        "state": "regular",
        "category": "corners",
        "label": "CustomDesign_Corners_CornerRadius",
        "section": "",
        "priority": ""
      }
    },
    "sections": {}
  },
  "wysiwyg.viewer.skins.wixadsskins.WixAdsSitePreviewSkin": {
    "params": {
      "rd1": {
        "state": "regular",
        "category": "corners",
        "label": "CustomDesign_Corners_CornerRadius",
        "section": "",
        "priority": ""
      },
      "brw": {
        "state": "regular",
        "category": "border",
        "label": "CustomDesign_Borders_Width",
        "section": "",
        "priority": ""
      },
      "brd": {
        "state": "regular",
        "category": "border",
        "label": "CustomDesign_Borders_Color&Opacity",
        "section": "",
        "priority": ""
      },
      "rd2": {
        "state": "regular",
        "category": "corners",
        "label": "CustomDesign_Corners_CornerRadius",
        "section": "",
        "priority": ""
      },
      "bg": {
        "state": "regular",
        "category": "fill",
        "label": "CustomDesign_FillColor_BGColor",
        "section": "",
        "priority": ""
      }
    },
    "sections": {}
  },
  "wysiwyg.viewer.skins.wixadsskins.WixAdsWebSkin": {
    "params": {
      "bg": {
        "state": "regular",
        "category": "fill",
        "label": "CustomDesign_FillColor_BGColor&Opacity",
        "section": "",
        "priority": ""
      },
      "rd2": {
        "state": "regular",
        "category": "corners",
        "label": "CustomDesign_Corners_CornerRadius",
        "section": "",
        "priority": ""
      }
    },
    "sections": {}
  },
  "wysiwyg.viewer.skins.wixadsskins.WixAdsWebSkinBU": {
    "params": {
      "bg": {
        "state": "regular",
        "category": "fill",
        "label": "CustomDesign_FillColor_BGColor&Opacity",
        "section": "",
        "priority": ""
      },
      "rd1": {
        "state": "regular",
        "category": "corners",
        "label": "CustomDesign_Corners_CornerRadius",
        "section": "",
        "priority": ""
      },
      "rd2": {
        "state": "regular",
        "category": "corners",
        "label": "CustomDesign_Corners_CornerRadius",
        "section": "",
        "priority": ""
      }
    },
    "sections": {}
  },
  "ecommerce.skins.mcom.MobileAddButton": {
    "params": {
      "shadow": {
        "hide": true
      }
    }
  },
  "ecommerce.skins.mcom.MobileComboBoxSkin": {
    "params": {
      "txt": {
        "label": "CustomDesign_InputControls_Item_Text_Color"
      }
    }
  },
  "ecommerce.skins.mcom.MobileRemoveButton": {
    "params": {
      "shadow": {
        "hide": true
      }
    }
  },
  "ecommerce.skins.mcom.MobileSelectOptionsListSkin": {
    "params": {
      "bg": {
        "state": "regular",
        "category": "fill",
        "label": "CustomDesign_FillColor_BGColor",
        "section": "",
        "priority": ""
      }
    }
  },
  "ecommerce.skins.mcom.MobileTextAreaInputSkin": {
    "params": {
      "brdf": {
        "state": "regular"
      }
    }
  },
  "ecommerce.skins.mcom.MobileZoomPartSkin": {
    "params": {
      "bg": {
        "state": "regular",
        "category": "fill",
        "label": "CustomDesign_FillColor_BGColor",
        "section": "",
        "priority": ""
      }
    }
  },
  "ecommerce.skins.viewer.dialogs.EcomCheckoutMessageDialogSkin": {
    "params": {
      "bg": {
        "state": "regular",
        "category": "fill",
        "label": "CustomDesign_FillColor_BGColor",
        "section": "",
        "priority": ""
      }
    }
  },
  "wysiwyg.viewer.skins.appinputs.EcomErasableTextInputSkin": {
    "params": {
      "txt": {
        "label": "CustomDesign_InputControls_Item_Text_Color"
      }
    }
  },
  "wysiwyg.viewer.skins.appinputs.EcomTextInputSkin": {
    "params": {
      "txt": {
        "label": "CustomDesign_InputControls_Item_Text_Color"
      }
    }
  },
  "wysiwyg.viewer.skins.MobileTryAgainMessageViewSkin": {
    "params": {
      "bg": {
        "state": "regular",
        "category": "fill",
        "label": "CustomDesign_FillColor_BGColor",
        "section": "",
        "priority": ""
      }
    }
  },
  "wysiwyg.viewer.skins.ModalSkin": {
    "params": {
      "bg-block": {
        "state": "regular",
        "category": "fill",
        "label": "CustomDesign_FillColor_BGColor",
        "section": "",
        "priority": ""
      }
    }
  },
  "wysiwyg.viewer.skins.PasswordLoginSkin": {
    "params": {
      "bg-block": {
        "state": "regular",
        "category": "fill",
        "label": "CustomDesign_FillColor_BGColor",
        "section": "",
        "priority": ""
      },
      "bg-border": {
        "state": "regular",
        "category": "fill",
        "label": "CustomDesign_FillColor_BGColor",
        "section": "",
        "priority": ""
      }
    }
  },
  "wysiwyg.viewer.skins.TryAgainMessageViewSkin": {
    "params": {
      "bg": {
        "state": "regular",
        "category": "fill",
        "label": "CustomDesign_FillColor_BGColor",
        "section": "",
        "priority": ""
      }
    }
  },
  "wysiwyg.viewer.skins.appinputs.AppsComboBoxInputSkin": {
    "params": {
      "txt": {
        "label": "CustomDesign_InputControls_Item_Text_Color",
        "priority": "2"
      },
      "txt_placeholder": {
        "state": "regular",
        "category": "text",
        "label": "CustomDesign_InputControls_Placeholder_Text_Color",
        "section": "",
        "priority": "1"
      },
      "txt2": {
        "hide": true
      }
    }
  },
  "wysiwyg.viewer.skins.appinputs.AppsComboBoxInputSkinNoValidation": {
    "params": {
      "txt": {
        "label": "CustomDesign_InputControls_Item_Text_Color"
      },
      "txt2": {
        "hide": true
      }
    }
  },
  "wysiwyg.viewer.skins.appinputs.AppsTextAreaInputSkin": {},
  "wysiwyg.viewer.skins.appinputs.AppsTextAreaInputSkinNoValidation": {},
  "wysiwyg.viewer.skins.input.ResponsiveTextAreaDefaultSkin": {
    "params": {
      "fnt": {
        "section": "input",
        "priority": "6",
        "min": 6,
        "max": 100
      },
      "fntlbl": {
        "state": "regular",
        "category": "text",
        "label": "CustomDesign_Text_Font",
        "section": "label",
        "priority": "3",
        "min": 6,
        "max": 100
      },
      "txt": {
        "label": "CustomDesign_InputControls_Item_Text_Color",
        "section": "input",
        "priority": "5"
      },
      "txt2": {
        "label": "CustomDesign_InputControls_Placeholder_Text_Color",
        "section": "input",
        "priority": "4"
      },
      "txtlbl": {
        "state": "regular",
        "category": "text",
        "label": "CustomDesign_Text_LabelTextColor",
        "section": "label",
        "priority": "1"
      },
      "txtlblrq": {
        "state": "regular",
        "category": "text",
        "label": "CustomDesign_Text_RequiredTextColor&Opacity",
        "section": "label",
        "priority": "2"
      }
    },
    "sections": {
      "input": {
        "state": "active",
        "category": "text",
        "label": "CustomDesign_Input_Section",
        "priority": 2
      },
      "label": {
        "state": "active",
        "category": "text",
        "label": "CustomDesign_Input_Label_Section",
        "priority": 1
      }
    }
  },
  "TextAreaDefaultSkin": {
    "params": {
      "fnt": {
        "section": "input",
        "priority": "6",
        "min": 6,
        "max": 100
      },
      "fntlbl": {
        "section": "label",
        "priority": "3",
        "min": 6,
        "max": 100
      },
      "txt": {
        "label": "CustomDesign_InputControls_Item_Text_Color",
        "section": "input",
        "priority": "5"
      },
      "txt2": {
        "label": "CustomDesign_InputControls_Placeholder_Text_Color",
        "section": "input",
        "priority": "4"
      },
      "txtlbl": {
        "label": "CustomDesign_Text_LabelTextColor",
        "section": "label",
        "priority": "1"
      },
      "txtlblrq": {
        "category": "text",
        "label": "CustomDesign_Text_RequiredTextColor&Opacity",
        "section": "label",
        "priority": "2"
      }
    },
    "sections": {
      "input": {
        "state": "active",
        "category": "text",
        "label": "CustomDesign_Input_Section",
        "priority": 2
      },
      "label": {
        "state": "active",
        "category": "text",
        "label": "CustomDesign_Input_Label_Section",
        "priority": 1
      }
    }
  },
  "wysiwyg.viewer.skins.appinputs.AppsTextInputSkin": {
    "params": {
      "rd": {
        "label": "CustomDesign_Corners_CornersRadius"
      },
      "fnt": {
        "section": "input",
        "priority": "6",
        "min": 6,
        "max": 100
      },
      "fntlbl": {
        "section": "label",
        "priority": "3",
        "min": 6,
        "max": 100
      },
      "txt": {
        "label": "CustomDesign_InputControls_Item_Text_Color",
        "section": "input",
        "priority": "5"
      },
      "txt2": {
        "label": "CustomDesign_InputControls_Placeholder_Text_Color",
        "section": "input",
        "priority": "4"
      },
      "txtlbl": {
        "label": "CustomDesign_Text_LabelTextColor",
        "section": "label",
        "priority": "1"
      },
      "txtlblrq": {
        "category": "text",
        "label": "CustomDesign_Text_RequiredTextColor&Opacity",
        "section": "label",
        "priority": "2"
      },
      "brd": {
        "label": "CustomDesign_Borders_Color&opacity"
      },
      "brdh": {
        "label": "CustomDesign_Borders_Color&opacity"
      },
      "brdf": {
        "label": "CustomDesign_Borders_Color&opacity"
      },
      "brdd": {
        "label": "CustomDesign_Borders_Color&opacity"
      },
      "bgh": {}
    },
    "sections": {
      "input": {
        "state": "active",
        "category": "text",
        "label": "CustomDesign_Input_Section",
        "priority": 2
      },
      "label": {
        "state": "active",
        "category": "text",
        "label": "CustomDesign_Input_Label_Section",
        "priority": 1
      }
    }
  },
  "wysiwyg.viewer.skins.displayers.CircleDetailedDisplayer": {
    "params": {
      "bg1": {
        "state": "regular",
        "category": "fill",
        "label": "CustomDesign_FillColor_BGColor",
        "section": "",
        "priority": ""
      }
    }
  },
  "wysiwyg.viewer.skins.dropmenu.CirclesMenuNSkin": {
    "params": {
      "nord": {
        "hide": true
      }
    }
  },
  "wysiwyg.viewer.skins.dropmenu.SeparatedArrowsMenuNSkin": {},
  "wysiwyg.viewer.skins.gallery.CircleMatrixGallery": {
    "params": {
      "bg1": {
        "state": "regular",
        "category": "fill",
        "label": "CustomDesign_FillColor_BGColor",
        "section": "",
        "priority": ""
      }
    }
  },
  "wysiwyg.viewer.skins.gallery.MatrixGalleryMinimal": {},
  "wysiwyg.viewer.skins.gallery.RibbonsSlideShow": {},
  "wysiwyg.viewer.skins.gallerymatrix.MatrixDisplayerGlowLine": {
    "params": {
      "ishd": {
        "hide": true
      }
    }
  },
  "wysiwyg.viewer.skins.gallerymatrix.GlowLineMatrixGallery": {
    "params": {
      "ishd": {
        "hide": true
      }
    }
  },
  "wysiwyg.viewer.skins.input.ComboBoxInputSkin": {
    "params": {
      "txt": {
        "label": "CustomDesign_InputControls_Item_Text_Color",
        "section": "input"
      },
      "txtlbl": {
        "state": "regular",
        "category": "text",
        "label": "CustomDesign_Text_LabelTextColor",
        "section": "label",
        "priority": ""
      },
      "txtlblrq": {
        "state": "regular",
        "category": "text",
        "label": "CustomDesign_Text_RequiredTextColor&Opacity",
        "section": "label",
        "priority": ""
      },
      "fntlbl": {
        "state": "regular",
        "category": "text",
        "label": "CustomDesign_Text_Font",
        "section": "label",
        "priority": ""
      },
      "txt2": {
        "hide": true
      }
    },
    "sections": {
      "input": {
        "state": "active",
        "category": "text",
        "label": "CustomDesign_Input_Section",
        "priority": 2
      },
      "label": {
        "state": "active",
        "category": "text",
        "label": "CustomDesign_Input_Label_Section",
        "priority": 1
      }
    }
  },
  "ComboBoxInputSkin": {
    "params": {
      "dropdownListBackgroundColor": {
        "compPart": "list",
        "state": "regular",
        "category": "fill",
        "priority": "1",
        "label": "CustomDesign_FillColor_BGColor&Opacity"
      },
      "dropdownListStrokeColor": {
        "compPart": "list",
        "state": "regular",
        "category": "border",
        "priority": "1",
        "label": "CustomDesign_Border_BoxColor"
      },
      "dropdownListStrokeWidth": {
        "compPart": "list",
        "state": "regular",
        "category": "border",
        "priority": "2",
        "label": "CustomDesign_Border_BoxWidth"
      },
      "dropdownListBoxShadow": {
        "compPart": "list",
        "state": "regular",
        "category": "shadow"
      },
      "dropdownListTextColor": {
        "compPart": "list",
        "state": "regular",
        "category": "text",
        "priority": "1",
        "label": "CustomDesign_Text_TextColor"
      },
      "dropdownListFont": {
        "compPart": "list",
        "state": "regular",
        "category": "text",
        "priority": "2",
        "min": 10,
        "max": 24
      },
      "dropdownListBorderRadius": {
        "compPart": "list",
        "state": "regular",
        "category": "corners",
        "min": 0,
        "max": 24
      },
      "dropdownListHoverBackgroundColor": {
        "compPart": "list",
        "state": "hover",
        "category": "fill",
        "priority": "1",
        "label": "CustomDesign_FillColor_BGColor&Opacity"
      },
      "dropdownListHoverTextColor": {
        "compPart": "list",
        "state": "hover",
        "category": "text",
        "priority": "1",
        "label": "CustomDesign_Text_TextColor"
      },
      "shd": {
        "compPart": "input"
      },
      "rd": {
        "compPart": "input",
        "label": "CustomDesign_Corners_CornersRadius"
      },
      "bg": {
        "compPart": "input",
        "priority": "1"
      },
      "bge": {
        "compPart": "input",
        "priority": "1"
      },
      "bgh": {
        "compPart": "input",
        "priority": "1"
      },
      "bgf": {
        "compPart": "input",
        "priority": "1"
      },
      "bgd": {
        "compPart": "input",
        "priority": "1"
      },
      "arrowColor": {
        "compPart": "input",
        "label": "CustomDesign_Dropdown_Icon_Color",
        "priority": "2"
      },
      "txt": {
        "compPart": "input",
        "label": "CustomDesign_InputControls_Item_Text_Color",
        "section": "input",
        "priority": "2"
      },
      "txtd": {
        "compPart": "input",
        "label": "CustomDesign_InputControls_Item_Text_Color",
        "section": "input",
        "priority": "2"
      },
      "txtlbl": {
        "compPart": "input",
        "label": "CustomDesign_Text_LabelTextColor",
        "section": "label"
      },
      "txtlblrq": {
        "compPart": "input",
        "category": "text",
        "label": "CustomDesign_Text_RequiredTextColor&Opacity",
        "section": "label"
      },
      "txt_placeholder": {
        "compPart": "input",
        "label": "CustomDesign_InputControls_Placeholder_Text_Color",
        "section": "input",
        "priority": "1"
      },
      "brw": {
        "compPart": "input",
        "priority": "2"
      },
      "brwe": {
        "compPart": "input",
        "priority": "2"
      },
      "brwf": {
        "compPart": "input",
        "priority": "2"
      },
      "brwh": {
        "compPart": "input",
        "priority": "2"
      },
      "brwd": {
        "compPart": "input",
        "state": "disbaled",
        "category": "border",
        "label": "CustomDesign_Borders_Width",
        "section": "",
        "priority": "2"
      },
      "brd": {
        "compPart": "input",
        "label": "CustomDesign_Borders_Color&opacity",
        "priority": "1"
      },
      "brde": {
        "compPart": "input",
        "label": "CustomDesign_Borders_Color&opacity"
      },
      "brdf": {
        "compPart": "input",
        "label": "CustomDesign_Borders_Color&opacity"
      },
      "brdh": {
        "compPart": "input",
        "label": "CustomDesign_Borders_Color&opacity"
      },
      "brdd": {
        "compPart": "input",
        "label": "CustomDesign_Borders_Color&opacity"
      },
      "fnt": {
        "compPart": "input",
        "section": "input",
        "priority": "3",
        "min": 6,
        "max": 100
      },
      "fntlbl": {
        "compPart": "input",
        "section": "label",
        "priority": "2"
      },
      "bg2": {
        "compPart": "input",
        "state": "regular",
        "category": "fill",
        "label": "CustomDesign_FillColor_BGColor&Opacity",
        "section": "",
        "priority": ""
      },
      "txt2": {
        "hide": true
      }
    },
    "sections": {
      "input": {
        "state": "active",
        "category": "text",
        "label": "CustomDesign_Input_Section",
        "priority": 2
      },
      "label": {
        "state": "active",
        "category": "text",
        "label": "CustomDesign_Input_Label_Section",
        "priority": 1
      }
    },
    "compParts": [
      {
        "label": "CustomDesign_ComponentState_Label_Input_Tab",
        "value": "input",
        "defaultSelected": true
      },
      {
        "label": "CustomDesign_ComponentState_Label_Dropdown_Tab",
        "value": "list",
        "additionalPreviewStates": [
          "list"
        ],
        "experiment": "se_designableDropdown"
      }
    ]
  },
  "ResponsiveComboBoxInputSkin": {
    "params": {
      "dropdownListBackgroundColor": {
        "compPart": "list",
        "state": "regular",
        "category": "fill",
        "priority": "1",
        "label": "CustomDesign_FillColor_BGColor&Opacity"
      },
      "dropdownListStrokeColor": {
        "compPart": "list",
        "state": "regular",
        "category": "border",
        "priority": "1",
        "label": "CustomDesign_Border_BoxColor"
      },
      "dropdownListStrokeWidth": {
        "compPart": "list",
        "state": "regular",
        "category": "border",
        "priority": "2",
        "label": "CustomDesign_Border_BoxWidth"
      },
      "dropdownListBoxShadow": {
        "compPart": "list",
        "state": "regular",
        "category": "shadow"
      },
      "dropdownListTextColor": {
        "compPart": "list",
        "state": "regular",
        "category": "text",
        "priority": "1",
        "label": "CustomDesign_Text_TextColor"
      },
      "dropdownListFont": {
        "compPart": "list",
        "state": "regular",
        "category": "text",
        "priority": "2",
        "min": 10,
        "max": 24
      },
      "dropdownListBorderRadius": {
        "compPart": "list",
        "state": "regular",
        "category": "corners",
        "min": 0,
        "max": 24
      },
      "dropdownListHoverBackgroundColor": {
        "compPart": "list",
        "state": "hover",
        "category": "fill",
        "priority": "1",
        "label": "CustomDesign_FillColor_BGColor&Opacity"
      },
      "dropdownListHoverTextColor": {
        "compPart": "list",
        "state": "hover",
        "category": "text",
        "priority": "1",
        "label": "CustomDesign_Text_TextColor"
      },
      "shd": {
        "compPart": "input"
      },
      "rd": {
        "compPart": "input",
        "label": "CustomDesign_Corners_CornersRadius"
      },
      "bg": {
        "compPart": "input",
        "priority": "1"
      },
      "bge": {
        "compPart": "input",
        "priority": "1"
      },
      "bgh": {
        "compPart": "input",
        "priority": "1"
      },
      "bgf": {
        "compPart": "input",
        "priority": "1"
      },
      "bgd": {
        "compPart": "input",
        "priority": "1"
      },
      "arrowColor": {
        "compPart": "input",
        "label": "CustomDesign_Dropdown_Icon_Color",
        "priority": "2"
      },
      "txt": {
        "compPart": "input",
        "label": "CustomDesign_InputControls_Item_Text_Color",
        "section": "input",
        "priority": "2"
      },
      "txtd": {
        "compPart": "input",
        "label": "CustomDesign_InputControls_Item_Text_Color",
        "section": "input",
        "priority": "2"
      },
      "txtlbl": {
        "compPart": "input",
        "state": "regular",
        "category": "text",
        "label": "CustomDesign_Text_LabelTextColor",
        "section": "label",
        "priority": ""
      },
      "txtlblrq": {
        "compPart": "input",
        "state": "regular",
        "category": "text",
        "label": "CustomDesign_Text_RequiredTextColor&Opacity",
        "section": "label",
        "priority": ""
      },
      "txt_placeholder": {
        "compPart": "input",
        "label": "CustomDesign_InputControls_Placeholder_Text_Color",
        "section": "input",
        "priority": "1"
      },
      "brw": {
        "compPart": "input",
        "priority": "2"
      },
      "brwe": {
        "compPart": "input",
        "priority": "2"
      },
      "brwf": {
        "compPart": "input",
        "priority": "2"
      },
      "brwh": {
        "compPart": "input",
        "priority": "2"
      },
      "brwd": {
        "compPart": "input",
        "state": "disabled",
        "category": "border",
        "label": "CustomDesign_Borders_Width",
        "section": "",
        "priority": "2"
      },
      "brd": {
        "compPart": "input",
        "label": "CustomDesign_Borders_Color&opacity",
        "priority": "1"
      },
      "brde": {
        "compPart": "input",
        "label": "CustomDesign_Borders_Color&opacity"
      },
      "brdf": {
        "compPart": "input",
        "label": "CustomDesign_Borders_Color&opacity"
      },
      "brdh": {
        "compPart": "input",
        "label": "CustomDesign_Borders_Color&opacity"
      },
      "brdd": {
        "compPart": "input",
        "label": "CustomDesign_Borders_Color&opacity"
      },
      "fnt": {
        "compPart": "input",
        "section": "input",
        "priority": "3",
        "min": 6,
        "max": 100
      },
      "fntlbl": {
        "compPart": "input",
        "state": "regular",
        "category": "text",
        "label": "CustomDesign_Text_Font",
        "section": "label",
        "priority": "2"
      },
      "bg2": {
        "compPart": "input",
        "state": "regular",
        "category": "fill",
        "label": "CustomDesign_FillColor_BGColor&Opacity",
        "section": "",
        "priority": ""
      },
      "txt2": {
        "hide": true
      }
    },
    "sections": {
      "input": {
        "state": "active",
        "category": "text",
        "label": "CustomDesign_Input_Section",
        "priority": 2
      },
      "label": {
        "state": "active",
        "category": "text",
        "label": "CustomDesign_Input_Label_Section",
        "priority": 1
      }
    },
    "compParts": [
      {
        "label": "CustomDesign_ComponentState_Label_Input_Tab",
        "value": "input",
        "defaultSelected": true
      },
      {
        "label": "CustomDesign_ComponentState_Label_Dropdown_Tab",
        "value": "list",
        "additionalPreviewStates": [
          "list"
        ],
        "experiment": "se_designableDropdown_editorX"
      }
    ]
  },
  "wysiwyg.viewer.skins.input.ComboBoxInputSkinNoValidation": {
    "params": {
      "rd": {
        "label": "CustomDesign_Corners_CornersRadius"
      },
      "txt": {
        "label": "CustomDesign_InputControls_Item_Text_Color",
        "priority": "2"
      },
      "txt_placeholder": {
        "state": "regular",
        "category": "text",
        "label": "CustomDesign_InputControls_Placeholder_Text_Color",
        "section": "",
        "priority": "1"
      },
      "brw": {
        "priority": "2"
      },
      "brd": {
        "label": "CustomDesign_Borders_Color&opacity",
        "priority": "1"
      },
      "brde": {
        "label": "CustomDesign_Borders_Color&opacity"
      },
      "fnt": {
        "priority": "3"
      },
      "bg2": {
        "state": "regular",
        "category": "fill",
        "label": "CustomDesign_FillColor_BGColor&Opacity",
        "section": "",
        "priority": ""
      },
      "txt2": {
        "hide": true
      }
    }
  },
  "wysiwyg.viewer.skins.input.TextAreaInputSkin": {
    "params": {
      "txt": {
        "label": "CustomDesign_InputControls_Item_Text_Color",
        "section": "input"
      },
      "brdf": {
        "state": "regular"
      },
      "fnt": {
        "state": "regular",
        "category": "text",
        "label": "CustomDesign_Text_Font",
        "section": "input",
        "priority": "",
        "min": 6,
        "max": 100
      },
      "fntlbl": {
        "section": "label",
        "min": 6,
        "max": 100
      },
      "txt2": {
        "state": "regular",
        "category": "text",
        "label": "CustomDesign_InputControls_Placeholder_Text_Color",
        "section": "input"
      },
      "txtlbl": {
        "label": "CustomDesign_Text_LabelTextColor",
        "section": "label"
      },
      "txtlblrq": {
        "state": "regular",
        "category": "text",
        "label": "CustomDesign_Text_RequiredTextColor&Opacity",
        "section": "label",
        "priority": ""
      }
    },
    "sections": {
      "input": {
        "state": "active",
        "category": "text",
        "label": "CustomDesign_Input_Section",
        "priority": 2
      },
      "label": {
        "state": "active",
        "category": "text",
        "label": "CustomDesign_Input_Label_Section",
        "priority": 1
      }
    }
  },
  "wysiwyg.viewer.skins.textinputs.ResponsiveTextInputSkin": {
    "params": {
      "rd": {
        "label": "CustomDesign_Corners_CornersRadius"
      },
      "fnt": {
        "section": "input",
        "priority": "6",
        "min": 6,
        "max": 100
      },
      "fntlbl": {
        "state": "regular",
        "category": "text",
        "label": "CustomDesign_Text_Font",
        "section": "label",
        "priority": "3",
        "min": 6,
        "max": 100
      },
      "txt": {
        "label": "CustomDesign_InputControls_Item_Text_Color",
        "section": "input",
        "priority": "5"
      },
      "txt2": {
        "label": "CustomDesign_InputControls_Placeholder_Text_Color",
        "section": "input",
        "priority": "4"
      },
      "txtlbl": {
        "state": "regular",
        "category": "text",
        "label": "CustomDesign_Text_LabelTextColor",
        "section": "label",
        "priority": "1"
      },
      "txtlblrq": {
        "state": "regular",
        "category": "text",
        "label": "CustomDesign_Text_RequiredTextColor&Opacity",
        "section": "label",
        "priority": "2"
      },
      "brd": {
        "label": "CustomDesign_Borders_Color&opacity"
      },
      "brdh": {
        "label": "CustomDesign_Borders_Color&opacity"
      },
      "brdf": {
        "label": "CustomDesign_Borders_Color&opacity"
      },
      "brdd": {
        "label": "CustomDesign_Borders_Color&opacity"
      },
      "bgh": {}
    },
    "sections": {
      "input": {
        "state": "active",
        "category": "text",
        "label": "CustomDesign_Input_Section",
        "priority": 2
      },
      "label": {
        "state": "active",
        "category": "text",
        "label": "CustomDesign_Input_Label_Section",
        "priority": 1
      }
    }
  },
  "wysiwyg.viewer.skins.menubutton.SeparatedArrowsMenuButtonSkin": {},
  "wysiwyg.viewer.skins.menu.SeparatedArrowsMenuSkin": {},
  "wysiwyg.viewer.skins.page.InnerShadowPageSkin": {
    "params": {
      "shd": {
        "hide": true
      }
    }
  },
  "wysiwyg.viewer.skins.page.ThreeDeePageSkin": {
    "params": {
      "brd": {
        "category": "border",
        "label": "CustomDesign_Borders_Color&Opacity"
      },
      "brw": {
        "category": "border",
        "label": "CustomDesign_Borders_Width"
      }
    }
  },
  "wysiwyg.viewer.skins.paginatedgrid.PaginatedGridPolaroid": {},
  "wysiwyg.viewer.skins.sm.SMContainerSkin": {
    "params": {
      "bg-block": {
        "state": "regular",
        "category": "fill",
        "label": "CustomDesign_FillColor_BGColor",
        "section": "",
        "priority": ""
      },
      "bg-border": {
        "state": "regular",
        "category": "fill",
        "label": "CustomDesign_FillColor_BGColor",
        "section": "",
        "priority": ""
      }
    }
  },
  "wysiwyg.viewer.skins.AdminLoginSkin": {
    "params": {
      "rd": {
        "hide": true
      },
      "bg": {
        "state": "regular",
        "category": "fill",
        "label": "CustomDesign_FillColor_BGColor",
        "section": "",
        "priority": "",
        "hide": true
      }
    }
  },
  "wysiwyg.viewer.skins.wixhomepage.WixHomepageMenuSkin": {
    "params": {
      "pad": {
        "state": "regular",
        "category": "other",
        "label": "CustomDesign_PADDING_SIZE",
        "section": "",
        "priority": ""
      },
      "txt": {
        "state": "regular",
        "category": "text",
        "label": "CustomDesign_Text_TextColor",
        "section": "",
        "priority": ""
      },
      "fnt": {
        "state": "regular",
        "category": "text",
        "label": "CustomDesign_Text_Font",
        "section": "",
        "priority": ""
      },
      "txth": {
        "state": "hover",
        "category": "text",
        "label": "CustomDesign_Text_TextColor",
        "section": "",
        "priority": ""
      }
    },
    "sections": {}
  },
  "wysiwyg.viewer.skins.wixhomepage.WixHomepageMenuSkin2": {
    "params": {
      "pad": {
        "state": "regular",
        "category": "other",
        "label": "CustomDesign_PADDING_SIZE",
        "section": "",
        "priority": ""
      },
      "fnt": {
        "state": "regular",
        "category": "text",
        "label": "CustomDesign_Text_Font",
        "section": "",
        "priority": ""
      },
      "txt": {
        "state": "regular",
        "category": "text",
        "label": "CustomDesign_Text_TextColor",
        "section": "",
        "priority": ""
      },
      "txth": {
        "state": "hover",
        "category": "text",
        "label": "CustomDesign_Text_TextColor",
        "section": "",
        "priority": ""
      }
    },
    "sections": {}
  },
  "wixapps.integration.skins.ecommerce.inputs.TextAreaInputSkin": {
    "params": {
      "txt": {
        "label": "CustomDesign_InputControls_Item_Text_Color"
      },
      "brdf": {
        "state": "regular"
      }
    }
  },
  "wixapps.integration.skins.inputs.CheckBoxInputSkin": {
    "params": {
      "fnt": {
        "state": "regular",
        "category": "text",
        "label": "CustomDesign_Text_Font",
        "section": "",
        "priority": ""
      },
      "txt": {
        "state": "regular",
        "category": "text",
        "label": "CustomDesign_Text_TextColor",
        "section": "",
        "priority": ""
      }
    }
  },
  "wysiwyg.viewer.skins.TPAModalSkin": {
    "params": {
      "bg-block": {
        "state": "regular",
        "category": "fill",
        "label": "CustomDesign_FillColor_BGColor",
        "section": "",
        "priority": ""
      }
    }
  },
  "wysiwyg.common.components.areatooltip.viewer.skins.AreaTooltipSkin": {
    "params": {
      "rd": {
        "state": "regular",
        "category": "corners",
        "label": "CustomDesign_Corners_CornerRadius",
        "section": "",
        "priority": ""
      },
      "shd": {
        "state": "regular",
        "category": "shadow",
        "label": "shadow",
        "section": "",
        "priority": ""
      }
    },
    "sections": {}
  },
  "wysiwyg.common.components.backofficetext.viewer.skins.BackOfficeTextSkin": {
    "params": {
      "fnt": {
        "state": "regular",
        "category": "text",
        "label": "CustomDesign_Text_Font",
        "section": "",
        "priority": ""
      },
      "txt": {
        "state": "regular",
        "category": "text",
        "label": "CustomDesign_Text_TextColor",
        "section": "",
        "priority": ""
      },
      "txth": {
        "state": "hover",
        "category": "text",
        "label": "CustomDesign_Text_TextColor",
        "section": "",
        "priority": ""
      }
    },
    "sections": {}
  },
  "wysiwyg.common.components.domainsearchbar.viewer.skins.DomainSearchBarSkin": {
    "params": {
      "brw": {
        "state": "regular",
        "category": "border",
        "label": "CustomDesign_Borders_Width",
        "section": "",
        "priority": ""
      },
      "BorderRadius": {
        "state": "regular",
        "category": "corners",
        "label": "CustomDesign_Corners_CornerRadius",
        "section": "",
        "priority": ""
      },
      "BorderRadiusLeftOnly": {
        "state": "regular",
        "category": "corners",
        "label": "CustomDesign_Corners_CornerRadius",
        "section": "",
        "priority": ""
      }
    },
    "sections": {}
  },
  "wysiwyg.viewer.skins.wixhomepage.HomePageLoginSkin": {
    "params": {
      "fnt": {
        "state": "regular",
        "category": "text",
        "label": "CustomDesign_Text_Font",
        "section": "",
        "priority": ""
      },
      "txt": {
        "state": "regular",
        "category": "text",
        "label": "CustomDesign_Text_TextColor",
        "section": "",
        "priority": ""
      },
      "txth": {
        "state": "regular",
        "category": "text",
        "label": "CustomDesign_Text_TextColor",
        "section": "",
        "priority": ""
      }
    },
    "sections": {}
  },
  "wysiwyg.viewer.skins.wixhomepage.LanguagesDropDownSkin": {
    "params": {
      "txt": {
        "state": "regular",
        "category": "text",
        "label": "CustomDesign_Text_TextColor",
        "section": "",
        "priority": ""
      },
      "fnt": {
        "state": "regular",
        "category": "text",
        "label": "CustomDesign_Text_Font",
        "section": "",
        "priority": ""
      },
      "rd": {
        "state": "regular",
        "category": "corners",
        "label": "CustomDesign_Corners_CornerRadius",
        "section": "",
        "priority": ""
      },
      "dropShadow": {
        "state": "regular",
        "category": "shadow",
        "label": "shadow",
        "section": "",
        "priority": ""
      }
    },
    "sections": {}
  },
  "wysiwyg.common.components.packagepicker.viewer.skins.PackagePickerSkin": {
    "params": {
      "rd": {
        "state": "regular",
        "category": "corners",
        "label": "CustomDesign_Corners_CornerRadius",
        "section": "",
        "priority": ""
      },
      "shd": {
        "state": "regular",
        "category": "shadow",
        "label": "shadow",
        "section": "",
        "priority": ""
      }
    },
    "sections": {}
  },
  "wysiwyg.viewer.skins.TPAPlaceholderSkin": {
    "params": {},
    "sections": {}
  },
  "wysiwyg.viewer.skins.wixhomepage.WixOfTheDaySkin": {
    "params": {
      "shd": {
        "state": "regular",
        "category": "shadow",
        "label": "shadow",
        "section": "",
        "priority": ""
      }
    },
    "sections": {}
  },
  "wysiwyg.viewer.skins.AppPartSkin": {
    "params": {
      "rd": {
        "state": "regular",
        "category": "corners",
        "label": "CustomDesign_Corners_CornerRadius",
        "section": "",
        "priority": ""
      },
      "videoThumbCorners": {
        "state": "regular",
        "category": "corners",
        "label": "CustomDesign_Corners_CornerRadius",
        "section": "",
        "priority": ""
      },
      "videoThumbBg": {
        "state": "regular",
        "category": "fill",
        "label": "CustomDesign_FillColor_BGColor",
        "section": "",
        "priority": ""
      },
      "videoThumbColor": {
        "state": "regular",
        "category": "fill",
        "label": "CustomDesign_FillColor_Color&Opacity",
        "section": "",
        "priority": ""
      }
    }
  },
  "wysiwyg.skins.AppPartZoomSkin": {
    "params": {
      "rd": {
        "state": "regular",
        "category": "corners",
        "label": "CustomDesign_Corners_CornerRadius",
        "section": "",
        "priority": ""
      },
      "videoThumbCorners": {
        "state": "regular",
        "category": "corners",
        "label": "CustomDesign_Corners_CornerRadius",
        "section": "",
        "priority": ""
      },
      "videoThumbBg": {
        "state": "regular",
        "category": "fill",
        "label": "CustomDesign_FillColor_BGColor",
        "section": "",
        "priority": ""
      },
      "videoThumbColor": {
        "state": "regular",
        "category": "fill",
        "label": "CustomDesign_FillColor_Color&Opacity",
        "section": "",
        "priority": ""
      },
      "bg": {
        "state": "regular",
        "category": "fill",
        "label": "CustomDesign_FillColor_BGColor",
        "section": "",
        "priority": ""
      }
    }
  },
  "wysiwyg.skins.MobileAppPartZoomSkin": {
    "params": {
      "rd": {
        "state": "regular",
        "category": "corners",
        "label": "CustomDesign_Corners_CornerRadius",
        "section": "",
        "priority": ""
      },
      "videoThumbCorners": {
        "state": "regular",
        "category": "corners",
        "label": "CustomDesign_Corners_CornerRadius",
        "section": "",
        "priority": ""
      },
      "videoThumbBg": {
        "state": "regular",
        "category": "fill",
        "label": "CustomDesign_FillColor_BGColor",
        "section": "",
        "priority": ""
      },
      "videoThumbColor": {
        "state": "regular",
        "category": "fill",
        "label": "CustomDesign_FillColor_Color&Opacity",
        "section": "",
        "priority": ""
      },
      "bg": {
        "state": "regular",
        "category": "fill",
        "label": "CustomDesign_FillColor_BGColor",
        "section": "",
        "priority": ""
      }
    }
  },
  "wysiwyg.viewer.skins.PageGroupSkin": {
    "params": {
      "$overlayColor": {
        "state": "regular",
        "category": "fill",
        "label": "CustomDesign_FillColor_BGColor&Opacity",
        "section": "",
        "priority": ""
      }
    }
  },
  "wysiwyg.common.components.verticalanchorsmenu.viewer.skins.VerticalAnchorsMenuSymbolWithTextSkin": {
    "params": {
      "symbolColor": {
        "category": "fill",
        "label": "CustomDesign_AnchorMenu_ButtonFillColor&Opacity_ButtonAndText",
        "helpText": "CustomDesign_AnchorMenu_Automatic_color_correction_label"
      },
      "symbolColorSelected": {
        "state": "active",
        "category": "fill",
        "label": "CustomDesign_AnchorMenu_ButtonFillColor&Opacity_ButtonAndText_Clicked",
        "helpText": "CustomDesign_AnchorMenu_Automatic_color_correction_label"
      },
      "textSpacing": {
        "label": "CustomDesign_AnchorMenu_ButtonsTextOffset"
      }
    }
  },
  "wysiwyg.common.components.verticalanchorsmenu.viewer.skins.VerticalAnchorsMenuSymbolWithHiddenTextSkin": {
    "params": {
      "symbolColor": {
        "label": "CustomDesign_AnchorMenu_ButtonFillColor&Opacity_ButtonAndText",
        "helpText": "CustomDesign_AnchorMenu_Automatic_color_correction_label"
      },
      "symbolColorSelected": {
        "state": "active",
        "category": "fill",
        "label": "CustomDesign_AnchorMenu_ButtonFillColor&Opacity_ButtonAndText",
        "helpText": "CustomDesign_AnchorMenu_Automatic_color_correction_label"
      },
      "textSpacing": {
        "label": "CustomDesign_AnchorMenu_ButtonsTextOffset"
      }
    }
  },
  "wysiwyg.common.components.verticalanchorsmenu.viewer.skins.VerticalAnchorsMenuSymbolSkin": {
    "params": {
      "symbolColor": {
        "label": "CustomDesign_AnchorMenu_ButtonFillColor&Opacity_Button",
        "helpText": "CustomDesign_AnchorMenu_Automatic_color_correction_label"
      },
      "symbolColorSelected": {
        "state": "active",
        "label": "CustomDesign_AnchorMenu_ButtonFillColor&Opacity_Button",
        "helpText": "CustomDesign_AnchorMenu_Automatic_color_correction_label"
      }
    }
  },
  "wysiwyg.common.components.verticalanchorsmenu.viewer.skins.VerticalAnchorsMenuTextSkin": {
    "params": {
      "symbolColor": {
        "category": "fill",
        "label": "CustomDesign_AnchorMenu_ButtonFillColor&Opacity_Text",
        "helpText": "CustomDesign_AnchorMenu_Automatic_color_correction_label"
      },
      "symbolColorSelected": {
        "state": "active",
        "category": "fill",
        "label": "CustomDesign_AnchorMenu_ButtonFillColor&Opacity_Text_Clicked",
        "helpText": "CustomDesign_AnchorMenu_Automatic_color_correction_label"
      }
    }
  },
  "wysiwyg.common.components.verticalanchorsmenu.viewer.skins.VerticalAnchorsMenuLinkedNoTextSkin": {
    "params": {
      "symbolColor": {
        "label": "CustomDesign_AnchorMenu_ButtonFillColor&Opacity_Button",
        "helpText": "CustomDesign_AnchorMenu_Automatic_color_correction_label"
      },
      "symbolColorSelected": {
        "state": "active",
        "label": "CustomDesign_AnchorMenu_ButtonFillColor&Opacity_Button",
        "helpText": "CustomDesign_AnchorMenu_Automatic_color_correction_label"
      }
    }
  },
  "skins.viewer.bgVideo.bgVideoSkin": {
    "params": {},
    "sections": {}
  },
  "contactform.FullWidthButtonSkin": {
    "params": {
      "txt3": {
        "label": "CustomDesign_ContactForm_LabelTextColor",
        "section": "section1",
        "priority": 1
      },
      "fnt": {
        "section": "section1",
        "priority": 3
      },
      "rd": {
        "state": "regular",
        "category": "corners",
        "label": "CustomDesign_Corners_CornerRadius",
        "section": "",
        "priority": ""
      },
      "bg2": {
        "label": "CustomDesign_ContactForm_ButtonBackground",
        "priority": 2
      },
      "fnt2": {
        "section": "section2",
        "priority": 2
      },
      "txt2": {
        "section": "section2",
        "priority": 1
      },
      "txtSuccess": {
        "label": "CustomDesign_ContactForm_SuccessTextColor",
        "section": "section3",
        "priority": 1
      },
      "txtError": {
        "category": "text",
        "label": "CustomDesign_ContactForm_ErrorTextColor",
        "section": "section3",
        "priority": 2
      },
      "txt1": {
        "label": "CustomDesign_ContactForm_InputTextColor",
        "section": "section1",
        "priority": 2
      },
      "bg1": {
        "label": "CustomDesign_ContactForm_FieldBackground",
        "priority": 1
      }
    },
    "sections": {
      "section1": {
        "label": "CustomDesign_ContactForm_FieldTextSection",
        "priority": 1
      },
      "section2": {
        "label": "CustomDesign_ContactForm_ButtonTextSection",
        "priority": 2
      },
      "section3": {
        "label": "CustomDesign_ContactForm_MessageTextSection",
        "priority": 3
      }
    }
  },
  "skins.viewer.displayers.BlogSlideshowDisplayer": {},
  "skins.viewer.gallery.BlogSlideShow": {},
  "wysiwyg.viewer.skins.contactform.HorizontalContactFormSkin": {
    "params": {
      "txt1": {
        "label": "CustomDesign_ContactForm_InputTextColor",
        "section": "section1",
        "priority": 2
      },
      "txtSuccess": {
        "label": "CustomDesign_ContactForm_SuccessTextColor",
        "section": "section3",
        "priority": 1
      },
      "txtError": {
        "category": "text",
        "label": "CustomDesign_ContactForm_ErrorTextColor",
        "section": "section3",
        "priority": 2
      },
      "fnt": {
        "section": "section1",
        "priority": 3
      },
      "bg1": {
        "label": "CustomDesign_ContactForm_FieldBackground"
      },
      "labelTxt": {
        "label": "CustomDesign_ContactForm_LabelTextColor",
        "section": "section1",
        "priority": 1
      },
      "bg2": {
        "label": "CustomDesign_ContactForm_ButtonBackground"
      },
      "fnt2": {
        "section": "section2",
        "priority": 2
      },
      "txt2": {
        "section": "section2",
        "priority": 1
      }
    },
    "sections": {
      "section1": {
        "label": "CustomDesign_ContactForm_FieldTextSection",
        "priority": 1
      },
      "section2": {
        "label": "CustomDesign_ContactForm_ButtonTextSection",
        "priority": 2
      },
      "section3": {
        "label": "CustomDesign_ContactForm_MessageTextSection",
        "priority": 3
      }
    }
  },
  "contactform.FieldAnimationSkin": {
    "params": {
      "rd": {
        "state": "regular",
        "category": "corners",
        "label": "CustomDesign_Corners_CornerRadius",
        "section": "",
        "priority": ""
      },
      "shd": {
        "state": "regular",
        "category": "shadow",
        "label": "shadow",
        "section": "",
        "priority": ""
      },
      "fnt": {
        "section": "section1",
        "priority": "3"
      },
      "bg1": {
        "label": "CustomDesign_ContactForm_InputBackground"
      },
      "brw": {
        "state": "regular",
        "category": "border",
        "label": "CustomDesign_Borders_Width",
        "section": "",
        "priority": ""
      },
      "brd": {
        "state": "regular",
        "category": "border",
        "label": "CustomDesign_Borders_Color&Opacity",
        "section": "",
        "priority": ""
      },
      "txt1": {
        "label": "CustomDesign_ContactForm_InputTextColor",
        "section": "section1",
        "priority": 2
      },
      "txtError": {
        "label": "CustomDesign_ContactForm_ErrorTextColor",
        "section": "section3",
        "priority": 2
      },
      "txt4": {
        "label": "CustomDesign_ContactForm_LabelTextColor",
        "section": "section1",
        "priority": 1
      },
      "bg2": {
        "label": "CustomDesign_ContactForm_ButtonBackground"
      },
      "txt2": {
        "section": "section2",
        "priority": 1
      },
      "fnt2": {
        "section": "section2",
        "priority": "2"
      },
      "txtSuccess": {
        "label": "CustomDesign_ContactForm_SuccessTextColor",
        "section": "section3",
        "priority": "1"
      },
      "bg3": {
        "label": "CustomDesign_ContactForm_FieldBackground"
      }
    },
    "sections": {
      "section1": {
        "label": "CustomDesign_ContactForm_FieldTextSection",
        "priority": 1
      },
      "section2": {
        "label": "CustomDesign_ContactForm_ButtonTextSection",
        "priority": 2
      },
      "section3": {
        "label": "CustomDesign_ContactForm_MessageTextSection",
        "priority": 3
      }
    }
  },
  "contactform.LineOnlySkin": {
    "params": {
      "brd2": {
        "state": "regular",
        "label": "CustomDesign_ContactForm_Input_Border_Opacity&Color"
      },
      "bg1": {
        "state": "regular",
        "category": "border",
        "label": "CustomDesign_ContactForm_Border_Color&Opacity",
        "section": "",
        "priority": ""
      },
      "txt1": {
        "label": "CustomDesign_ContactForm_InputTextColor",
        "section": "section1",
        "priority": 2
      },
      "shd": {
        "state": "regular",
        "category": "shadow",
        "label": "shadow",
        "section": "",
        "priority": ""
      },
      "fnt": {
        "section": "section1",
        "priority": 3
      },
      "txt3": {
        "label": "CustomDesign_ContactForm_LabelTextColor",
        "section": "section1",
        "priority": 1
      },
      "fnt2": {
        "section": "section2",
        "priority": 2
      },
      "txt4": {
        "section": "section2",
        "priority": 1
      },
      "txtSuccess": {
        "label": "CustomDesign_ContactForm_SuccessTextColor",
        "section": "section3",
        "priority": 1
      },
      "txtError": {
        "category": "text",
        "label": "CustomDesign_ContactForm_ErrorTextColor",
        "section": "section3",
        "priority": 2
      }
    },
    "sections": {
      "section1": {
        "label": "CustomDesign_ContactForm_FieldTextSection",
        "priority": 1
      },
      "section2": {
        "label": "CustomDesign_ContactForm_ButtonTextSection",
        "priority": 2
      },
      "section3": {
        "label": "CustomDesign_ContactForm_MessageTextSection",
        "priority": 3
      }
    }
  },
  "wysiwyg.viewer.skins.MobileAppBannerBasicSkin": {
    "params": {},
    "sections": {}
  },
  "wysiwyg.common.components.radio.viewer.skins.RadioButtonDefaultSkin": {
    "params": {
      "bg": {
        "priority": "1"
      },
      "bgh": {
        "priority": "1"
      },
      "bg2": {
        "label": "CustomDesign_InputControls_Selected_Color",
        "priority": "2"
      },
      "bg2h": {
        "label": "CustomDesign_InputControls_Selected_Color",
        "priority": "2"
      },
      "bg3": {
        "label": "CustomDesign_Borders_Color&opacity"
      },
      "bg3h": {
        "label": "CustomDesign_Borders_Color&opacity"
      },
      "bg3f": {
        "category": "border",
        "label": "CustomDesign_Borders_Color&opacity",
        "section": "",
        "priority": ""
      },
      "bg3e": {
        "label": "CustomDesign_Borders_Color&opacity"
      },
      "brw": {
        "state": "regular"
      }
    }
  },
  "wysiwyg.viewer.skins.TouchMediaZoomSlideshow": {
    "params": {
      "zoom": {
        "state": "regular",
        "category": "unknownType",
        "label": "unknownType",
        "section": "",
        "priority": ""
      }
    },
    "sections": {}
  },
  "wysiwyg.viewer.skins.input.DatePickerDefaultSkin": {
    "params": {
      "bg": {
        "compPart": "input",
        "priority": "1"
      },
      "rd": {
        "compPart": "input"
      },
      "shd": {
        "compPart": "input"
      },
      "brw": {
        "compPart": "input",
        "priority": "2"
      },
      "brwf": {
        "compPart": "input",
        "priority": "2"
      },
      "brwh": {
        "compPart": "input",
        "priority": "2"
      },
      "brwd": {
        "compPart": "input",
        "priority": "2"
      },
      "brwe": {
        "compPart": "input",
        "priority": "2"
      },
      "brd": {
        "compPart": "input",
        "priority": "1"
      },
      "bge": {
        "compPart": "input"
      },
      "brde": {
        "compPart": "input",
        "priority": "1"
      },
      "bgd": {
        "compPart": "input"
      },
      "brdd": {
        "compPart": "input",
        "priority": "1"
      },
      "bgf": {
        "compPart": "input"
      },
      "brdf": {
        "compPart": "input",
        "priority": "1"
      },
      "bgh": {
        "compPart": "input"
      },
      "brdh": {
        "compPart": "input",
        "priority": "1"
      },
      "icn-color": {
        "compPart": "input",
        "label": "CustomDesign_FillColor_DatePicker_Icon",
        "priority": "2"
      },
      "fnt": {
        "compPart": "input",
        "section": "input",
        "priority": "3"
      },
      "txt": {
        "compPart": "input",
        "label": "CustomDesign_Text_Color_DatePicker_DateInput",
        "section": "input",
        "priority": "1"
      },
      "fntlbl": {
        "compPart": "input",
        "section": "label"
      },
      "txtlbl": {
        "compPart": "input",
        "label": "CustomDesign_Text_LabelTextColor",
        "section": "label"
      },
      "txtlblrq": {
        "compPart": "input",
        "category": "text",
        "label": "CustomDesign_Text_RequiredTextColor&Opacity",
        "section": "label"
      },
      "txtd": {
        "compPart": "input"
      },
      "txt-placeholder": {
        "compPart": "input",
        "label": "CustomDesign_Text_Color_DatePicker_Placeholder",
        "section": "input",
        "priority": "2"
      },
      "shd-calendar": {
        "compPart": "calendar"
      },
      "rd-calendar": {
        "compPart": "calendar"
      },
      "bg-calendar": {
        "compPart": "calendar"
      },
      "brw-calendar": {
        "compPart": "calendar",
        "label": "CustomDesign_Borders_Width",
        "priority": "2"
      },
      "brd-calendar": {
        "compPart": "calendar",
        "priority": "1"
      },
      "fnt-family-header": {
        "compPart": "calendar",
        "label": "CustomDesign_DatePicker_HeaderText_Font",
        "section": "headerTexts",
        "priority": "2"
      },
      "txt-header": {
        "compPart": "calendar",
        "label": "CustomDesign_DatePicker_HeaderText_Color",
        "section": "headerTexts",
        "priority": "1"
      },
      "txt-size-header": {
        "compPart": "calendar",
        "label": "CustomDesign_DatePicker_HeaderText_FontSize",
        "min": 10,
        "max": 18,
        "section": "headerTexts",
        "priority": "3"
      },
      "bg-header": {
        "compPart": "calendar",
        "label": "CustomDesign_FillColor_DatePicker_Header",
        "priority": "2"
      },
      "fnt-family-day": {
        "compPart": "calendar",
        "label": "CustomDesign_DatePicker_CalendarText_Font",
        "section": "calendarTexts",
        "priority": "2"
      },
      "txt-size-day": {
        "compPart": "calendar",
        "label": "CustomDesign_DatePicker_CalendarText_FontSize",
        "min": 10,
        "max": 18,
        "section": "calendarTexts",
        "priority": "3"
      },
      "txt-day": {
        "compPart": "calendar",
        "state": "regular",
        "label": "CustomDesign_DatePicker_CalendarText_Color",
        "section": "calendarTexts",
        "priority": "1"
      },
      "txt-day-selected": {
        "compPart": "calendar",
        "state": "selected",
        "label": "CustomDesign_DatePicker_SelectedText_Color"
      },
      "bg-day-selected": {
        "compPart": "calendar",
        "state": "selected",
        "label": "CustomDesign_FillColor_DatePicker_SelectedDate"
      },
      "rd-day-selected": {
        "compPart": "calendar",
        "state": "selected"
      }
    },
    "sections": {
      "calendarTexts": {
        "label": "CustomDesign_DatePicker_CalendarText_Header",
        "priority": 1
      },
      "headerTexts": {
        "label": "CustomDesign_DatePicker_HeaderText_Header",
        "priority": 2
      },
      "label": {
        "state": "active",
        "category": "text",
        "label": "CustomDesign_Input_Label_Section",
        "priority": 1
      },
      "input": {
        "state": "active",
        "category": "text",
        "label": "CustomDesign_Input_Section",
        "priority": 2
      }
    },
    "compParts": [
      {
        "label": "CustomDesign_ComponentState_Label_Input_Tab",
        "value": "input",
        "defaultSelected": true
      },
      {
        "label": "CustomDesign_ComponentState_Label_Calendar_Tab",
        "value": "calendar",
        "additionalPreviewStates": [
          "focus",
          "open",
          "fakeData"
        ]
      }
    ]
  },
  "wysiwyg.viewer.skins.input.DatePickerTextBetweenNavSkin": {
    "params": {
      "bg": {
        "compPart": "input",
        "priority": "1"
      },
      "rd": {
        "compPart": "input"
      },
      "shd": {
        "compPart": "input"
      },
      "brw": {
        "compPart": "input",
        "priority": "2"
      },
      "brwf": {
        "compPart": "input",
        "priority": "2"
      },
      "brwh": {
        "compPart": "input",
        "priority": "2"
      },
      "brwd": {
        "compPart": "input",
        "priority": "2"
      },
      "brwe": {
        "compPart": "input",
        "priority": "2"
      },
      "brd": {
        "compPart": "input",
        "priority": "1"
      },
      "bge": {
        "compPart": "input"
      },
      "brde": {
        "compPart": "input",
        "priority": "1"
      },
      "bgd": {
        "compPart": "input"
      },
      "brdd": {
        "compPart": "input",
        "priority": "1"
      },
      "bgf": {
        "compPart": "input"
      },
      "brdf": {
        "compPart": "input",
        "priority": "1"
      },
      "bgh": {
        "compPart": "input"
      },
      "brdh": {
        "compPart": "input",
        "priority": "1"
      },
      "icn-color": {
        "compPart": "input",
        "label": "CustomDesign_FillColor_DatePicker_Icon",
        "priority": "2"
      },
      "fnt": {
        "compPart": "input",
        "section": "input",
        "priority": "3"
      },
      "fntlbl": {
        "compPart": "input",
        "section": "label"
      },
      "txt": {
        "compPart": "input",
        "label": "CustomDesign_Text_Color_DatePicker_DateInput",
        "section": "input",
        "priority": "1"
      },
      "txtlbl": {
        "compPart": "input",
        "label": "CustomDesign_Text_LabelTextColor",
        "section": "label"
      },
      "txtlblrq": {
        "compPart": "input",
        "category": "text",
        "label": "CustomDesign_Text_RequiredTextColor&Opacity",
        "section": "label"
      },
      "txtd": {
        "compPart": "input"
      },
      "txt-placeholder": {
        "compPart": "input",
        "label": "CustomDesign_Text_Color_DatePicker_Placeholder",
        "section": "input",
        "priority": "2"
      },
      "shd-calendar": {
        "compPart": "calendar"
      },
      "rd-calendar": {
        "compPart": "calendar"
      },
      "bg-calendar": {
        "compPart": "calendar"
      },
      "brw-calendar": {
        "compPart": "calendar",
        "label": "CustomDesign_Borders_Width",
        "priority": "2"
      },
      "brd-calendar": {
        "compPart": "calendar",
        "priority": "1"
      },
      "fnt-family-header": {
        "compPart": "calendar",
        "label": "CustomDesign_DatePicker_HeaderText_Font",
        "section": "headerTexts",
        "priority": "2"
      },
      "txt-header": {
        "compPart": "calendar",
        "label": "CustomDesign_DatePicker_HeaderText_Color",
        "section": "headerTexts",
        "priority": "1"
      },
      "txt-size-header": {
        "compPart": "calendar",
        "label": "CustomDesign_DatePicker_HeaderText_FontSize",
        "min": 10,
        "max": 18,
        "section": "headerTexts",
        "priority": "3"
      },
      "bg-header": {
        "compPart": "calendar",
        "label": "CustomDesign_FillColor_DatePicker_Header",
        "priority": "2"
      },
      "fnt-family-day": {
        "compPart": "calendar",
        "label": "CustomDesign_DatePicker_CalendarText_Font",
        "section": "calendarTexts",
        "priority": "2"
      },
      "txt-size-day": {
        "compPart": "calendar",
        "label": "CustomDesign_DatePicker_CalendarText_FontSize",
        "min": 10,
        "max": 18,
        "section": "calendarTexts",
        "priority": "3"
      },
      "txt-day": {
        "compPart": "calendar",
        "state": "regular",
        "label": "CustomDesign_DatePicker_CalendarText_Color",
        "section": "calendarTexts",
        "priority": "1"
      },
      "txt-day-selected": {
        "compPart": "calendar",
        "state": "selected",
        "label": "CustomDesign_DatePicker_SelectedText_Color"
      },
      "bg-day-selected": {
        "compPart": "calendar",
        "state": "selected",
        "label": "CustomDesign_FillColor_DatePicker_SelectedDate"
      },
      "rd-day-selected": {
        "compPart": "calendar",
        "state": "selected"
      }
    },
    "sections": {
      "calendarTexts": {
        "label": "CustomDesign_DatePicker_CalendarText_Header",
        "priority": 1
      },
      "headerTexts": {
        "label": "CustomDesign_DatePicker_HeaderText_Header",
        "priority": 2
      },
      "label": {
        "state": "active",
        "category": "text",
        "label": "CustomDesign_Input_Label_Section",
        "priority": 1
      },
      "input": {
        "state": "active",
        "category": "text",
        "label": "CustomDesign_Input_Section",
        "priority": 2
      }
    },
    "compParts": [
      {
        "label": "CustomDesign_ComponentState_Label_Input_Tab",
        "value": "input",
        "defaultSelected": true
      },
      {
        "label": "CustomDesign_ComponentState_Label_Calendar_Tab",
        "value": "calendar",
        "additionalPreviewStates": [
          "focus",
          "open",
          "fakeData"
        ]
      }
    ]
  },
  "wysiwyg.viewer.skins.input.DatePickerTextYearNavSkin": {
    "params": {
      "bg": {
        "compPart": "input",
        "priority": "1"
      },
      "rd": {
        "compPart": "input"
      },
      "shd": {
        "compPart": "input"
      },
      "brw": {
        "compPart": "input",
        "priority": "2"
      },
      "brwf": {
        "compPart": "input",
        "priority": "2"
      },
      "brwh": {
        "compPart": "input",
        "priority": "2"
      },
      "brwd": {
        "compPart": "input",
        "priority": "2"
      },
      "brwe": {
        "compPart": "input",
        "priority": "2"
      },
      "brd": {
        "compPart": "input",
        "priority": "1"
      },
      "bge": {
        "compPart": "input"
      },
      "brde": {
        "compPart": "input",
        "priority": "1"
      },
      "bgd": {
        "compPart": "input"
      },
      "brdd": {
        "compPart": "input",
        "priority": "1"
      },
      "bgf": {
        "compPart": "input"
      },
      "brdf": {
        "compPart": "input",
        "priority": "1"
      },
      "bgh": {
        "compPart": "input"
      },
      "brdh": {
        "compPart": "input",
        "priority": "1"
      },
      "icn-color": {
        "compPart": "input",
        "label": "CustomDesign_FillColor_DatePicker_Icon",
        "priority": "2"
      },
      "fnt": {
        "compPart": "input",
        "section": "input",
        "priority": "3"
      },
      "txt": {
        "compPart": "input",
        "label": "CustomDesign_Text_Color_DatePicker_DateInput",
        "section": "input",
        "priority": "1"
      },
      "fntlbl": {
        "compPart": "input",
        "section": "label"
      },
      "txtlbl": {
        "compPart": "input",
        "label": "CustomDesign_Text_LabelTextColor",
        "section": "label"
      },
      "txtlblrq": {
        "compPart": "input",
        "category": "text",
        "label": "CustomDesign_Text_RequiredTextColor&Opacity",
        "section": "label"
      },
      "txtd": {
        "compPart": "input"
      },
      "txt-placeholder": {
        "compPart": "input",
        "label": "CustomDesign_Text_Color_DatePicker_Placeholder",
        "section": "input",
        "priority": "2"
      },
      "shd-calendar": {
        "compPart": "calendar"
      },
      "rd-calendar": {
        "compPart": "calendar"
      },
      "bg-calendar": {
        "compPart": "calendar"
      },
      "brw-calendar": {
        "compPart": "calendar",
        "label": "CustomDesign_Borders_Width",
        "priority": "2"
      },
      "brd-calendar": {
        "compPart": "calendar",
        "priority": "1"
      },
      "fnt-family-header": {
        "compPart": "calendar",
        "label": "CustomDesign_DatePicker_HeaderText_Font",
        "section": "headerTexts",
        "priority": "2"
      },
      "txt-header": {
        "compPart": "calendar",
        "label": "CustomDesign_DatePicker_HeaderText_Color",
        "section": "headerTexts",
        "priority": "1"
      },
      "txt-size-header": {
        "compPart": "calendar",
        "label": "CustomDesign_DatePicker_HeaderText_FontSize",
        "min": 10,
        "max": 18,
        "section": "headerTexts",
        "priority": "3"
      },
      "bg-header": {
        "compPart": "calendar",
        "label": "CustomDesign_FillColor_DatePicker_Header",
        "priority": "2"
      },
      "fnt-family-day": {
        "compPart": "calendar",
        "label": "CustomDesign_DatePicker_CalendarText_Font",
        "section": "calendarTexts",
        "priority": "2"
      },
      "txt-size-day": {
        "compPart": "calendar",
        "label": "CustomDesign_DatePicker_CalendarText_FontSize",
        "min": 10,
        "max": 18,
        "section": "calendarTexts",
        "priority": "3"
      },
      "txt-day": {
        "compPart": "calendar",
        "state": "regular",
        "label": "CustomDesign_DatePicker_CalendarText_Color",
        "section": "calendarTexts",
        "priority": "1"
      },
      "txt-day-selected": {
        "compPart": "calendar",
        "state": "selected",
        "label": "CustomDesign_DatePicker_SelectedText_Color"
      },
      "bg-day-selected": {
        "compPart": "calendar",
        "state": "selected",
        "label": "CustomDesign_FillColor_DatePicker_SelectedDate"
      },
      "rd-day-selected": {
        "compPart": "calendar",
        "state": "selected"
      }
    },
    "sections": {
      "calendarTexts": {
        "label": "CustomDesign_DatePicker_CalendarText_Header",
        "priority": 1
      },
      "headerTexts": {
        "label": "CustomDesign_DatePicker_HeaderText_Header",
        "priority": 2
      },
      "label": {
        "state": "active",
        "category": "text",
        "label": "CustomDesign_Input_Label_Section",
        "priority": 1
      },
      "input": {
        "state": "active",
        "category": "text",
        "label": "CustomDesign_Input_Section",
        "priority": 2
      }
    },
    "compParts": [
      {
        "label": "CustomDesign_ComponentState_Label_Input_Tab",
        "value": "input",
        "defaultSelected": true
      },
      {
        "label": "CustomDesign_ComponentState_Label_Calendar_Tab",
        "value": "calendar",
        "additionalPreviewStates": [
          "focus",
          "open",
          "fakeData"
        ]
      }
    ]
  },
  "wysiwyg.viewer.skins.GridSkin": {
    "params": {
      "headerBg": {
        "label": "CustomDesign_Grid_Header_BGColor&Opacity",
        "priority": "1"
      },
      "rowBg": {
        "label": "CustomDesign_Grid_Row_BGColor&Opacity",
        "priority": "2"
      },
      "row2Bg": {
        "label": "CustomDesign_Grid_Row_Secondary_BGColor&Opacity",
        "priority": "3"
      },
      "rowOrCellHoverBg": {
        "state": "regular",
        "label": "CustomDesign_Grid_Row_Cell_Hover_BGColor&Opacity",
        "priority": "4"
      },
      "selection": {
        "label": "CustomDesign_Grid_Selection_BGColor&Opacity",
        "priority": "5"
      },
      "containerBg": {
        "label": "CustomDesign_Grid_Container_BGColor&Opacity",
        "priority": "6"
      },
      "paginationColor": {
        "label": "CustomDesign_Grid_Pagination_BGColor&Opacity",
        "priority": "7"
      },
      "containerBrd": {
        "label": "CustomDesign_Grid_Container_Border_Color&Opacity",
        "priority": "1"
      },
      "containerBrw": {
        "label": "CustomDesign_Grid_Container_Border_Width",
        "priority": "1",
        "min": 0,
        "max": 15
      },
      "headerDivider": {
        "label": "CustomDesign_Grid_Header_Divider_Color&Opacity",
        "priority": "2",
        "section": "dividers"
      },
      "headerDivider_w": {
        "label": "CustomDesign_Grid_Header_Divider_Width",
        "priority": "2",
        "section": "dividers",
        "min": 0,
        "max": 15
      },
      "columnDivider": {
        "label": "CustomDesign_Grid_Column_Divider_Color&Opacity",
        "priority": "3",
        "section": "dividers"
      },
      "columnDivider_w": {
        "label": "CustomDesign_Grid_Column_Divider_Width",
        "priority": "3",
        "section": "dividers",
        "min": 0,
        "max": 15
      },
      "rowDivider": {
        "label": "CustomDesign_Grid_Row_Divider_Color&Opacity",
        "priority": "4",
        "section": "dividers"
      },
      "rowDivider_w": {
        "label": "CustomDesign_Grid_Row_Divider_Width",
        "priority": "4",
        "section": "dividers",
        "min": 0,
        "max": 15
      },
      "headerFont": {
        "priority": "2",
        "section": "headerText"
      },
      "headerText": {
        "label": "CustomDesign_Grid_Header_Text_Color&Opacity",
        "priority": "1",
        "section": "headerText"
      },
      "rowFont": {
        "priority": "4",
        "section": "rowText"
      },
      "rowText": {
        "label": "CustomDesign_Grid_Row_Text_Color&Opacity",
        "priority": "1",
        "section": "rowText"
      },
      "linkColor": {
        "label": "CustomDesign_Grid_Row_Text_Link_Color&Opacity",
        "priority": "2",
        "section": "rowText"
      },
      "linkHoverColor": {
        "label": "CustomDesign_Grid_Row_Text_LinkHover_Color&Opacity",
        "priority": "3",
        "section": "rowText",
        "state": "regular"
      },
      "cellPadding": {
        "hide": true
      }
    },
    "sections": {
      "headerText": {
        "label": "CustomDesign_Grid_HeaderTextSection",
        "priority": 1
      },
      "rowText": {
        "label": "CustomDesign_Grid_RowTextSection",
        "priority": 2
      },
      "dividers": {
        "label": "CustomDesign_Grid_Border_Dividers_Divider",
        "priority": 2
      }
    }
  },
  "wysiwyg.viewer.skins.GridLeftHeaderSkin": {
    "params": {
      "headerBg": {
        "label": "CustomDesign_Grid_Header_BGColor&Opacity",
        "priority": "1"
      },
      "headerColumnBg": {
        "label": "CustomDesign_Grid_Column_Header_BGColor&Opacity",
        "priority": "2"
      },
      "rowBg": {
        "label": "CustomDesign_Grid_Row_BGColor&Opacity",
        "priority": "3"
      },
      "row2Bg": {
        "label": "CustomDesign_Grid_Row_Secondary_BGColor&Opacity",
        "priority": "4"
      },
      "rowOrCellHoverBg": {
        "state": "regular",
        "label": "CustomDesign_Grid_Row_Cell_Hover_BGColor&Opacity",
        "priority": "5"
      },
      "selection": {
        "label": "CustomDesign_Grid_Selection_BGColor&Opacity",
        "priority": "6"
      },
      "containerBg": {
        "label": "CustomDesign_Grid_Container_BGColor&Opacity",
        "priority": "7"
      },
      "paginationColor": {
        "label": "CustomDesign_Grid_Pagination_BGColor&Opacity",
        "priority": "7"
      },
      "containerBrd": {
        "label": "CustomDesign_Grid_Container_Border_Color&Opacity",
        "priority": "1"
      },
      "containerBrw": {
        "label": "CustomDesign_Grid_Container_Border_Width",
        "priority": "1"
      },
      "headerDivider": {
        "label": "CustomDesign_Grid_Header_Divider_Color&Opacity",
        "priority": "2",
        "section": "dividers"
      },
      "headerDivider_w": {
        "label": "CustomDesign_Grid_Header_Divider_Width",
        "priority": "2",
        "section": "dividers"
      },
      "columnDivider": {
        "label": "CustomDesign_Grid_Column_Divider_Color&Opacity",
        "priority": "3",
        "section": "dividers"
      },
      "columnDivider_w": {
        "label": "CustomDesign_Grid_Column_Divider_Width",
        "priority": "3",
        "section": "dividers"
      },
      "rowDivider": {
        "label": "CustomDesign_Grid_Row_Divider_Color&Opacity",
        "priority": "4",
        "section": "dividers"
      },
      "rowDivider_w": {
        "label": "CustomDesign_Grid_Row_Divider_Width",
        "priority": "4",
        "section": "dividers"
      },
      "headerFont": {
        "priority": "2",
        "section": "headerText"
      },
      "headerText": {
        "label": "CustomDesign_Grid_Header_Text_Color&Opacity",
        "priority": "1",
        "section": "headerText"
      },
      "headerColumnFont": {
        "priority": "2",
        "section": "headerColumnText"
      },
      "headerColumnText": {
        "label": "CustomDesign_Grid_Header_Text_Color&Opacity",
        "priority": "1",
        "section": "headerColumnText"
      },
      "rowFont": {
        "priority": "4",
        "section": "rowText"
      },
      "rowText": {
        "label": "CustomDesign_Grid_Row_Text_Color&Opacity",
        "priority": "1",
        "section": "rowText"
      },
      "linkColor": {
        "label": "CustomDesign_Grid_Row_Text_Link_Color&Opacity",
        "priority": "2",
        "section": "rowText"
      },
      "linkHoverColor": {
        "label": "CustomDesign_Grid_Row_Text_LinkHover_Color&Opacity",
        "priority": "3",
        "section": "rowText"
      },
      "cellPadding": {
        "hide": true
      }
    },
    "sections": {
      "headerText": {
        "label": "CustomDesign_Grid_HeaderTextSection",
        "priority": 1
      },
      "headerColumnText": {
        "label": "CustomDesign_Grid_HeaderColumnTextSection",
        "priority": 2
      },
      "rowText": {
        "label": "CustomDesign_Grid_RowTextSection",
        "priority": 3
      },
      "dividers": {
        "label": "CustomDesign_Grid_Border_Dividers_Divider",
        "priority": 4
      }
    }
  },
  "wysiwyg.viewer.skins.GridRightHeaderSkin": {
    "params": {
      "headerBg": {
        "label": "CustomDesign_Grid_Header_BGColor&Opacity",
        "priority": "1"
      },
      "headerColumnBg": {
        "label": "CustomDesign_Grid_Column_Header_BGColor&Opacity",
        "priority": "2"
      },
      "rowBg": {
        "label": "CustomDesign_Grid_Row_BGColor&Opacity",
        "priority": "3"
      },
      "row2Bg": {
        "label": "CustomDesign_Grid_Row_Secondary_BGColor&Opacity",
        "priority": "4"
      },
      "rowOrCellHoverBg": {
        "state": "regular",
        "label": "CustomDesign_Grid_Row_Cell_Hover_BGColor&Opacity",
        "priority": "5"
      },
      "selection": {
        "label": "CustomDesign_Grid_Selection_BGColor&Opacity",
        "priority": "6"
      },
      "containerBg": {
        "label": "CustomDesign_Grid_Container_BGColor&Opacity",
        "priority": "7"
      },
      "paginationColor": {
        "label": "CustomDesign_Grid_Pagination_BGColor&Opacity",
        "priority": "7"
      },
      "containerBrd": {
        "label": "CustomDesign_Grid_Container_Border_Color&Opacity",
        "priority": "1"
      },
      "containerBrw": {
        "label": "CustomDesign_Grid_Container_Border_Width",
        "priority": "1"
      },
      "headerDivider": {
        "label": "CustomDesign_Grid_Header_Divider_Color&Opacity",
        "priority": "2",
        "section": "dividers"
      },
      "headerDivider_w": {
        "label": "CustomDesign_Grid_Header_Divider_Width",
        "priority": "2",
        "section": "dividers"
      },
      "columnDivider": {
        "label": "CustomDesign_Grid_Column_Divider_Color&Opacity",
        "priority": "3",
        "section": "dividers"
      },
      "columnDivider_w": {
        "label": "CustomDesign_Grid_Column_Divider_Width",
        "priority": "3",
        "section": "dividers"
      },
      "rowDivider": {
        "label": "CustomDesign_Grid_Row_Divider_Color&Opacity",
        "priority": "4",
        "section": "dividers"
      },
      "rowDivider_w": {
        "label": "CustomDesign_Grid_Row_Divider_Width",
        "priority": "4",
        "section": "dividers"
      },
      "headerFont": {
        "priority": "2",
        "section": "headerText"
      },
      "headerText": {
        "label": "CustomDesign_Grid_Header_Text_Color&Opacity",
        "priority": "1",
        "section": "headerText"
      },
      "headerColumnFont": {
        "priority": "2",
        "section": "headerColumnText"
      },
      "headerColumnText": {
        "label": "CustomDesign_Grid_Header_Text_Color&Opacity",
        "priority": "1",
        "section": "headerColumnText"
      },
      "rowFont": {
        "priority": "2",
        "section": "rowText"
      },
      "rowText": {
        "label": "CustomDesign_Grid_Row_Text_Color&Opacity",
        "priority": "4",
        "section": "rowText"
      },
      "linkColor": {
        "label": "CustomDesign_Grid_Row_Text_Link_Color&Opacity",
        "priority": "2",
        "section": "rowText"
      },
      "linkHoverColor": {
        "label": "CustomDesign_Grid_Row_Text_LinkHover_Color&Opacity",
        "priority": "3",
        "section": "rowText"
      },
      "cellPadding": {
        "hide": true
      }
    },
    "sections": {
      "headerText": {
        "label": "CustomDesign_Grid_HeaderTextSection",
        "priority": 1
      },
      "headerColumnText": {
        "label": "CustomDesign_Grid_HeaderColumnTextSection",
        "priority": 2
      },
      "rowText": {
        "label": "CustomDesign_Grid_RowTextSection",
        "priority": 3
      },
      "dividers": {
        "label": "CustomDesign_Grid_Border_Dividers_Divider",
        "priority": 4
      }
    }
  },
  "wysiwyg.viewer.skins.LanguageSelectorSkin": {
    "params": {
      "itemTextColor": {
        "priority": 1
      },
      "itemFont": {
        "priority": 3
      },
      "borderRadius": {
        "priority": 1,
        "max": 30
      },
      "backgroundColor": {
        "priority": 1
      },
      "separatorColor": {
        "label": "CustomDesign_LangMenu_SeparatorsColor",
        "priority": 2
      },
      "shadowColor": {
        "state": "regular",
        "category": "shadow",
        "label": "CustomDesign_Shadow_Color&Opacity",
        "section": "",
        "priority": ""
      },
      "borderColor": {
        "priority": 2
      },
      "borderWidth": {
        "priority": 3
      },
      "itemSpacing": {
        "state": "regular",
        "category": "border",
        "label": "CustomDesign_Item_Spacing",
        "section": "",
        "priority": 4
      },
      "iconType": {
        "state": "regular",
        "category": "icon",
        "label": "CustomDesign_Flag_Form",
        "section": "",
        "priority": 4
      },
      "iconSize": {
        "state": "regular",
        "category": "icon",
        "label": "CustomDesign_LangMenu_Icons_Size",
        "section": "",
        "priority": 5,
        "min": 12,
        "max": 50
      },
      "itemTextColorHover": {
        "priority": 2
      },
      "backgroundColorHover": {
        "priority": 1
      },
      "itemTextColorActive": {
        "priority": 2
      },
      "backgroundColorActive": {
        "priority": 1
      }
    }
  },
  "skins.input.RadioGroupDefaultSkin": {
    "params": {
      "border_width_focus": {
        "state": "focus"
      },
      "border_color_focus": {
        "state": "focus"
      },
      "brw": {
        "state": "regular"
      },
      "bg2": {
        "label": "CustomDesign_InputControls_Selected_Color"
      },
      "bg2h": {
        "label": "CustomDesign_InputControls_Selected_Color"
      },
      "bg2d": {
        "state": "disabled",
        "category": "fill",
        "label": "CustomDesign_InputControls_Selected_Color",
        "section": "",
        "priority": ""
      },
      "bg3d": {
        "label": "CustomDesign_InputControls_Selected_Color",
        "priority": "2"
      },
      "bgf": {
        "state": "focus"
      },
      "brwf": {
        "state": "focus",
        "category": "border",
        "label": "CustomDesign_Borders_Width",
        "section": "",
        "priority": ""
      },
      "bg3f": {
        "state": "focus",
        "category": "border",
        "label": "CustomDesign_Borders_Color&Opacity",
        "section": "",
        "priority": ""
      },
      "bgd": {
        "priority": "1"
      },
      "brwd": {
        "state": "disabled",
        "category": "border",
        "label": "CustomDesign_Borders_Width",
        "section": "",
        "priority": ""
      },
      "brwe": {
        "state": "error",
        "category": "border",
        "label": "CustomDesign_Borders_Width",
        "section": "",
        "priority": ""
      },
      "fnt": {
        "label": "CustomDesign_Text_TextColor",
        "section": "radioButtons",
        "min": 6,
        "max": 100
      },
      "txt": {
        "section": "radioButtons"
      },
      "txt2": {
        "label": "CustomDesign_Text_LabelTextColor",
        "section": "label"
      },
      "txtlblrq": {
        "category": "text",
        "label": "CustomDesign_Text_RequiredTextColor&Opacity",
        "section": "label"
      },
      "fnt2": {
        "section": "label",
        "min": 6,
        "max": 100
      }
    },
    "sections": {
      "radioButtons": {
        "state": "active",
        "category": "text",
        "label": "CustomDesign_Radio_Buttons_Section",
        "priority": 2
      },
      "label": {
        "state": "active",
        "category": "text",
        "label": "CustomDesign_Input_Label_Section",
        "section": "",
        "priority": 1
      }
    }
  },
  "skins.input.CheckboxGroupDefaultSkin": {
    "params": {
      "border_width_focus": {
        "state": "focus",
        "category": "border",
        "label": "CustomDesign_Borders_Width",
        "section": "",
        "priority": ""
      },
      "border_color_focus": {
        "state": "focus",
        "category": "border",
        "label": "CustomDesign_Borders_Color&Opacity",
        "section": "",
        "priority": ""
      },
      "bg3": {
        "state": "regular",
        "category": "border",
        "label": "CustomDesign_Borders_Color&Opacity",
        "section": "",
        "priority": ""
      },
      "bg3h": {
        "state": "hover",
        "category": "border",
        "label": "CustomDesign_Borders_Color&Opacity",
        "section": "",
        "priority": ""
      },
      "bg2": {
        "state": "regular",
        "category": "fill",
        "label": "CustomDesign_InputControls_Selected_Color",
        "section": "",
        "priority": ""
      },
      "bg2h": {
        "state": "hover",
        "category": "fill",
        "label": "CustomDesign_InputControls_Selected_Color",
        "section": "",
        "priority": ""
      },
      "bg2d": {
        "state": "disabled",
        "category": "fill",
        "label": "CustomDesign_InputControls_Selected_Color",
        "section": "",
        "priority": ""
      },
      "bg3d": {
        "state": "disabled",
        "category": "fill",
        "label": "CustomDesign_InputControls_Selected_Color",
        "section": "",
        "priority": "2"
      },
      "bg3f": {
        "state": "focus",
        "category": "border",
        "label": "CustomDesign_Borders_Color&Opacity",
        "section": "",
        "priority": ""
      },
      "bgd": {
        "priority": "1"
      },
      "bg3e": {
        "state": "error",
        "category": "border",
        "label": "CustomDesign_Borders_Color&Opacity",
        "section": "",
        "priority": ""
      },
      "bgc": {
        "state": "regular",
        "label": "CustomDesign_InputControls_Checkmark_Color",
        "type": "BG_COLOR"
      },
      "bgch": {
        "label": "CustomDesign_InputControls_Checkmark_Color",
        "type": "BG_COLOR"
      },
      "bgcd": {
        "label": "CustomDesign_InputControls_Checkmark_Color"
      },
      "fnt": {
        "label": "CustomDesign_Text_TextColor",
        "section": "checkboxes",
        "min": 6,
        "max": 100
      },
      "txt": {
        "section": "checkboxes"
      },
      "txt2": {
        "label": "CustomDesign_Text_LabelTextColor",
        "section": "label"
      },
      "txtlblrq": {
        "category": "text",
        "label": "CustomDesign_Text_RequiredTextColor&Opacity",
        "section": "label"
      },
      "fnt2": {
        "section": "label",
        "min": 6,
        "max": 100
      },
      "rd": {
        "priority": "1"
      }
    },
    "sections": {
      "checkboxes": {
        "state": "active",
        "category": "text",
        "label": "CustomDesign_Checkboxes_Group_Section",
        "priority": 2
      },
      "label": {
        "state": "active",
        "category": "text",
        "label": "CustomDesign_Input_Label_Section",
        "section": "",
        "priority": 1
      }
    }
  },
  "wysiwyg.viewer.skins.quickActionBar.anchoredSkin": {
    "params": {
      "actionsBorderSize": {
        "min": 0,
        "max": 7
      }
    }
  },
  "wysiwyg.viewer.skins.quickActionBar.floatingSkin": {
    "params": {
      "controlButtonBG": {
        "state": "closed"
      },
      "controlButtonFG": {
        "state": "closed",
        "category": "fill"
      },
      "controlButtonBorderSize": {
        "state": "closed",
        "min": 0,
        "max": 7
      },
      "controlButtonBorderColor": {
        "state": "closed"
      },
      "controlButtonSHD": {
        "state": "closed"
      },
      "actionsSHD": {
        "state": "open"
      },
      "actionsBorderSize": {
        "state": "open",
        "min": 0,
        "max": 7
      },
      "actionsBorderColor": {
        "state": "open"
      },
      "colorScheme": {
        "state": "open",
        "label": "CustomDesign_Fill_ColorScheme"
      },
      "actionButtonColor0": {
        "state": "open",
        "category": "fill",
        "label": "CustomDesign_Fill_Action_Button_Color_0",
        "section": "",
        "priority": ""
      },
      "actionButtonColor1": {
        "state": "open",
        "category": "fill",
        "label": "CustomDesign_Fill_Action_Button_Color_1",
        "section": "",
        "priority": ""
      },
      "actionButtonColor2": {
        "state": "open",
        "category": "fill",
        "label": "CustomDesign_Fill_Action_Button_Color_2",
        "section": "",
        "priority": ""
      },
      "actionButtonColor3": {
        "state": "open",
        "category": "fill",
        "label": "CustomDesign_Fill_Action_Button_Color_3",
        "section": "",
        "priority": ""
      },
      "actionButtonColor4": {
        "state": "open",
        "category": "fill",
        "label": "CustomDesign_Fill_Action_Button_Color_4",
        "section": "",
        "priority": ""
      }
    },
    "defaultState": "open"
  },
  "wysiwyg.viewer.skins.quickActionBar.ovalSkin": {
    "params": {
      "actionsBorderSize": {
        "min": 0,
        "max": 7
      }
    }
  },
  "wysiwyg.viewer.skins.quickActionBar.rectSkin": {
    "params": {
      "actionsBorderSize": {
        "min": 0,
        "max": 7
      }
    }
  },
  "wysiwyg.viewer.skins.LoginSocialBarSkin": {
    "params": {
      "bg": {
        "compPart": "loggedIn",
        "label": "CustomDesign_Fill_Color_LoginSocialBar_Regular",
        "section": "background",
        "priority": "1"
      },
      "bg-dd": {
        "compPart": "loggedIn",
        "label": "CustomDesign_Fill_Color_LoginSocialBar_DropDown",
        "section": "background",
        "priority": "2"
      },
      "fillcolor": {
        "compPart": "loggedIn",
        "label": "CustomDesign_Fill_Color_LoginSocialBar_Svg",
        "priority": "1"
      },
      "avatarAndArrowStrokeWidth": {
        "compPart": "loggedIn",
        "state": "regular",
        "category": "border",
        "label": "CustomDesign_Fill_Color_LoginSocialBar_Avatar_Stroke_Width",
        "section": "avatar-border",
        "priority": "1",
        "min": 0,
        "max": 5
      },
      "avatarCornerRadius": {
        "compPart": "loggedIn",
        "state": "regular",
        "category": "corners",
        "label": "CustomDesign_Fill_Color_LoginSocialBar_Avatar_Corner_Radius",
        "section": "avatar-corners",
        "priority": "1",
        "min": 0,
        "max": 100
      },
      "badge-bg": {
        "compPart": "loggedIn",
        "label": "CustomDesign_Fill_Color_LoginSocialBar_Badge",
        "section": "badge",
        "priority": "1"
      },
      "badge-txt": {
        "compPart": "loggedIn",
        "category": "fill",
        "label": "CustomDesign_Text_Color_LoginSocialBar_Badge",
        "section": "badge",
        "priority": "2"
      },
      "brd": {
        "compPart": "loggedIn",
        "priority": "1"
      },
      "brw": {
        "compPart": "loggedIn",
        "priority": "2"
      },
      "brd-dd": {
        "compPart": "loggedIn",
        "section": "dropdown-border",
        "priority": "1"
      },
      "brw-dd": {
        "compPart": "loggedIn",
        "section": "dropdown-border",
        "priority": "2"
      },
      "rd": {
        "compPart": "loggedIn",
        "priority": "1"
      },
      "rd-dd": {
        "compPart": "loggedIn",
        "section": "dropdown-corners",
        "priority": "1"
      },
      "shd": {
        "compPart": "loggedIn",
        "priority": "1"
      },
      "shd-dd": {
        "compPart": "loggedIn",
        "section": "dropdown-shadow",
        "priority": "1"
      },
      "txt": {
        "compPart": "loggedIn",
        "label": "CustomDesign_Text_Color_LoginSocialBar_MemberName",
        "priority": "1"
      },
      "fnt": {
        "compPart": "loggedIn",
        "min": 12,
        "max": 28,
        "priority": "2"
      },
      "txt-dd": {
        "compPart": "loggedIn",
        "label": "CustomDesign_Text_Color_LoginSocialBar_DropDown",
        "section": "dropdown-text",
        "priority": "1"
      },
      "fnt-size-dd": {
        "compPart": "loggedIn",
        "min": 12,
        "max": 28,
        "section": "dropdown-text",
        "priority": "2"
      },
      "txth": {
        "compPart": "loggedIn",
        "label": "CustomDesign_Text_Color_LoginSocialBar_MemberName",
        "priority": "1"
      },
      "txth-dd": {
        "compPart": "loggedIn",
        "label": "CustomDesign_Fill_Color_LoginSocialBar_DropDown_Hover",
        "priority": "2"
      },
      "txt-slct-dd": {
        "compPart": "loggedIn",
        "state": "selected",
        "label": "CustomDesign_Fill_Color_LoginSocialBar_DropDown_Hover",
        "priority": "1"
      }
    },
    "sections": {
      "background": {
        "label": "CustomDesign_LoginSocialBar_Background_Header",
        "priority": 2
      },
      "badge": {
        "label": "CustomDesign_LoginSocialBar_Badge_Header",
        "priority": 3
      },
      "dropdown-border": {
        "label": "CustomDesign_LoginSocialBar_DropDownText_Header",
        "priority": 3
      },
      "dropdown-corners": {
        "label": "CustomDesign_LoginSocialBar_DropDownText_Header",
        "priority": 3
      },
      "dropdown-shadow": {
        "label": "CustomDesign_LoginSocialBar_DropDownText_Header",
        "priority": 1
      },
      "dropdown-text": {
        "label": "CustomDesign_LoginSocialBar_DropDownText_Header",
        "priority": 2
      },
      "avatar-border": {
        "label": "CustomDesign_LoginSocialBar_AvatarBorder_Header",
        "priority": 2
      },
      "avatar-corners": {
        "label": "CustomDesign_LoginSocialBar_AvatarCorners_Header",
        "priority": 2
      }
    },
    "compParts": [
      {
        "label": "CustomDesign_ComponentState_Label_Logged_In_Tab",
        "value": "loggedIn",
        "additionalPreviewStates": [
          "loggedInMenuOpened"
        ],
        "defaultSelected": true
      }
    ]
  },
  "wixui.skins.RichTextBox": {
    "params": {
      "txt": {
        "category": "text",
        "label": "CustomDesign_Text_TextColor",
        "priority": "1"
      },
      "txtd": {
        "state": "disabled",
        "priority": "1"
      },
      "placeHolderTextColor": {
        "label": "CustomDesign_InputControls_Placeholder_Text_Color",
        "priority": "2"
      },
      "linkColor": {
        "label": "CustomDesign_Text_LinkColor",
        "priority": "3"
      },
      "fnt": {
        "priority": "4"
      },
      "rd": {},
      "shd": {},
      "bg": {
        "label": "CustomDesign_FillColor&Opacity_TextBox",
        "priority": "1"
      },
      "bgh": {
        "priority": "1"
      },
      "bgf": {
        "priority": "1"
      },
      "bge": {
        "state": "error",
        "priority": "1"
      },
      "bgd": {
        "state": "disabled",
        "priority": "1"
      },
      "toolbarBg": {
        "label": "CustomDesign_FillColor&Opacity_Toolbar",
        "priority": "2"
      },
      "toolbarBgh": {
        "label": "CustomDesign_FillColor&Opacity_Toolbar",
        "priority": "2"
      },
      "toolbarBgf": {
        "label": "CustomDesign_FillColor&Opacity_Toolbar",
        "priority": "2"
      },
      "toolbarBge": {
        "state": "error",
        "label": "CustomDesign_FillColor&Opacity_Toolbar",
        "priority": "2"
      },
      "toolbarBgd": {
        "state": "disabled",
        "label": "CustomDesign_FillColor&Opacity_Toolbar",
        "priority": "2"
      },
      "iconsColor": {
        "label": "CustomDesign_FillColor&Opacity_Icons",
        "priority": "3"
      },
      "iconsActiveColor": {
        "category": "fill",
        "label": "CustomDesign_FillColor&Opacity_IconsActive",
        "priority": "4"
      },
      "brd": {
        "priority": "1"
      },
      "brdh": {
        "priority": "1"
      },
      "brdf": {
        "priority": "1"
      },
      "brde": {
        "state": "error",
        "priority": "1"
      },
      "brdd": {
        "state": "disabled",
        "priority": "1"
      },
      "brw": {
        "priority": "2"
      },
      "brwh": {
        "priority": "2"
      },
      "brwf": {
        "priority": "2"
      },
      "brwe": {
        "state": "error",
        "priority": "2"
      },
      "brwd": {
        "state": "disabled",
        "priority": "2"
      }
    }
  },
  "wixui.skins.SignatureInput": {
    "params": {
      "padBackground": {
        "priority": "1",
        "section": "background",
        "label": "CustomDesign_FillColor_BGColor&Opacity"
      },
      "padBackgroundHover": {
        "priority": "1",
        "section": "background",
        "label": "CustomDesign_FillColor_BGColor&Opacity"
      },
      "padBackgroundFocus": {
        "state": "focus",
        "priority": "1",
        "section": "background",
        "label": "CustomDesign_FillColor_BGColor&Opacity"
      },
      "padBackgroundError": {
        "priority": "1",
        "section": "background",
        "label": "CustomDesign_FillColor_BGColor&Opacity"
      },
      "padBackgroundDisabled": {
        "priority": "1",
        "section": "background",
        "label": "CustomDesign_SignatureInput_Background_BGColor"
      },
      "padLineColor": {
        "priority": "1",
        "label": "CustomDesign_Borders_Color&opacity",
        "section": "signatureLine"
      },
      "padLineColorHover": {
        "priority": "1",
        "label": "CustomDesign_Borders_Color&opacity",
        "section": "signatureLine"
      },
      "padLineColorFocus": {
        "state": "focus",
        "priority": "1",
        "label": "CustomDesign_Borders_Color&opacity",
        "section": "signatureLine"
      },
      "padLineColorError": {
        "priority": "1",
        "label": "CustomDesign_Borders_Color&opacity",
        "section": "signatureLine"
      },
      "padLineColorDisabled": {
        "priority": "1",
        "label": "CustomDesign_Borders_Color&opacity",
        "section": "signatureLine"
      },
      "padLineWidth": {
        "category": "fill",
        "priority": "2",
        "section": "signatureLine"
      },
      "padLineWidthHover": {
        "category": "fill",
        "priority": "2",
        "section": "signatureLine"
      },
      "padLineWidthFocus": {
        "state": "focus",
        "category": "fill",
        "priority": "2",
        "section": "signatureLine"
      },
      "padLineWidthError": {
        "category": "fill",
        "priority": "2",
        "section": "signatureLine"
      },
      "padLineWidthDisabled": {
        "category": "fill",
        "priority": "2",
        "section": "signatureLine"
      },
      "signatureColor": {
        "priority": "1",
        "label": "CustomDesign_SignatureInput_Signature_Color",
        "section": "signatureInput"
      },
      "signatureSize": {
        "category": "fill",
        "priority": "2",
        "label": "CustomDesign_Borders_Width",
        "section": "signatureInput",
        "min": 1,
        "max": 4
      },
      "padBorderColor": {
        "category": "border",
        "priority": "1",
        "label": "CustomDesign_Border_Opacity&Color"
      },
      "padBorderColorHover": {
        "category": "border",
        "priority": "1",
        "label": "CustomDesign_Border_Opacity&Color"
      },
      "padBorderColorFocus": {
        "state": "focus",
        "category": "border",
        "priority": "1",
        "label": "CustomDesign_Border_Opacity&Color"
      },
      "padBorderColorError": {
        "category": "border",
        "priority": "1",
        "label": "CustomDesign_Border_Opacity&Color"
      },
      "padBorderColorDisabled": {
        "category": "border",
        "priority": "1",
        "label": "CustomDesign_Border_Opacity&Color"
      },
      "padBorderWidth": {
        "priority": "2"
      },
      "padBorderWidthHover": {
        "priority": "2"
      },
      "padBorderWidthFocus": {
        "state": "focus",
        "priority": "2"
      },
      "padBorderWidthError": {
        "priority": "2"
      },
      "padBorderWidthDisabled": {
        "priority": "2"
      },
      "padBorderRadius": {
        "priority": "1"
      },
      "padBoxShadow": {
        "priority": "1"
      },
      "titleColor": {
        "priority": "1",
        "label": "CustomDesign_SignatureInput_Title_Color",
        "section": "fieldTitle"
      },
      "titleColorDisabled": {
        "priority": "1",
        "label": "CustomDesign_SignatureInput_Title_Color",
        "section": "fieldTitle"
      },
      "titleAsteriskColor": {
        "priority": "2",
        "label": "CustomDesign_SignatureInput_Required_Asterisk_Color",
        "section": "fieldTitle"
      },
      "titleAsteriskColorDisabled": {
        "priority": "2",
        "label": "CustomDesign_SignatureInput_Required_Asterisk_Color",
        "section": "fieldTitle"
      },
      "titleFont": {
        "priority": "3",
        "section": "fieldTitle"
      },
      "titleFontDisabled": {
        "priority": "3",
        "section": "fieldTitle"
      },
      "clearButtonColor": {
        "priority": "1",
        "label": "CustomDesign_SignatureInput_Clear_Button_Color",
        "section": "clearButton"
      },
      "clearButtonColorDisabled": {
        "priority": "1",
        "label": "CustomDesign_SignatureInput_Clear_Button_Color",
        "section": "clearButton"
      },
      "clearButtonFont": {
        "priority": "2",
        "section": "clearButton"
      },
      "clearButtonFontDisabled": {
        "priority": "2",
        "section": "clearButton"
      }
    },
    "sections": {
      "background": {
        "label": "CustomDesign_Fill_SignatureInput_Background_Section_Title",
        "priority": 1
      },
      "signatureLine": {
        "label": "CustomDesign_Fill_SignatureInput_Signature_Line_Section_Title",
        "priority": 2
      },
      "signatureInput": {
        "label": "CustomDesign_Fill_SignatureInput_Signature_Input_Section_Title",
        "priority": 3
      },
      "fieldTitle": {
        "label": "CustomDesign_Text_SignatureInput_Field_Title_Section_Title",
        "priority": 1
      },
      "clearButton": {
        "label": "CustomDesign_Text_SignatureInput_Clear_Button_Section_Title",
        "priority": 2
      }
    }
  },
  "wixui.skins.Slider": {
    "params": {
      "thumbBackground": {
        "label": "CustomDesign_Slider_Handle_FillColor&Opacity",
        "priority": "1"
      },
      "thumbBackgroundHover": {
        "label": "CustomDesign_Slider_Handle_FillColor&Opacity",
        "priority": "1"
      },
      "thumbBackgroundFocus": {
        "label": "CustomDesign_Slider_Handle_FillColor&Opacity",
        "priority": "1"
      },
      "thumbBackgroundDisabled": {
        "label": "CustomDesign_Slider_Handle_FillColor&Opacity",
        "priority": "1"
      },
      "thumbBoxShadow": {
        "section": "thumb",
        "priority": "1",
        "label": "CustomDesign_Slider_thumbBoxShadow"
      },
      "trackFill": {
        "priority": "2",
        "label": "CustomDesign_InputControls_Selected_Color"
      },
      "trackFillHover": {
        "priority": "2",
        "label": "CustomDesign_InputControls_Selected_Color"
      },
      "trackFillFocus": {
        "priority": "2",
        "label": "CustomDesign_InputControls_Selected_Color"
      },
      "trackFillDisabled": {
        "priority": "2",
        "label": "CustomDesign_InputControls_Selected_Color"
      },
      "trackBoxShadow": {
        "section": "track",
        "priority": "2",
        "label": "CustomDesign_Slider_trackBoxShadow"
      },
      "trackBackground": {
        "priority": "2",
        "label": "CustomDesign_FillColor_BGColor&Opacity"
      },
      "trackBackgroundHover": {
        "priority": "2",
        "label": "CustomDesign_FillColor_BGColor&Opacity"
      },
      "trackBackgroundFocus": {
        "priority": "2",
        "label": "CustomDesign_FillColor_BGColor&Opacity"
      },
      "trackBackgroundDisabled": {
        "priority": "2",
        "label": "CustomDesign_FillColor_BGColor&Opacity"
      },
      "thumbBorderColor": {
        "category": "border",
        "section": "thumb",
        "priority": "1",
        "label": "CustomDesign_Borders_Color&opacity"
      },
      "thumbBorderColorHover": {
        "category": "border",
        "section": "thumb",
        "priority": "1",
        "label": "CustomDesign_Borders_Color&opacity"
      },
      "thumbBorderColorFocus": {
        "category": "border",
        "section": "thumb",
        "priority": "1",
        "label": "CustomDesign_Borders_Color&opacity"
      },
      "thumbBorderColorDisabled": {
        "category": "border",
        "section": "thumb",
        "priority": "1",
        "label": "CustomDesign_Borders_Color&opacity"
      },
      "thumbBorderWidth": {
        "section": "thumb",
        "priority": "1"
      },
      "thumbBorderWidthHover": {
        "section": "thumb",
        "priority": "1"
      },
      "thumbBorderWidthFocus": {
        "section": "thumb",
        "priority": "1"
      },
      "thumbBorderWidthDisabled": {
        "section": "thumb",
        "priority": "1"
      },
      "thumbBorderRadius": {
        "section": "thumb"
      },
      "trackBorderColor": {
        "category": "border",
        "section": "track",
        "priority": "2",
        "label": "CustomDesign_Borders_Color&opacity"
      },
      "trackBorderColorHover": {
        "category": "border",
        "section": "track",
        "priority": "2",
        "label": "CustomDesign_Borders_Color&opacity"
      },
      "trackBorderColorFocus": {
        "category": "border",
        "section": "track",
        "priority": "2",
        "label": "CustomDesign_Borders_Color&opacity"
      },
      "trackBorderColorDisabled": {
        "category": "border",
        "section": "track",
        "priority": "2",
        "label": "CustomDesign_Borders_Color&opacity"
      },
      "trackBorderWidth": {
        "section": "track",
        "priority": "2"
      },
      "trackBorderWidthHover": {
        "section": "track",
        "priority": "2"
      },
      "trackBorderWidthFocus": {
        "section": "track",
        "priority": "2"
      },
      "trackBorderWidthDisabled": {
        "section": "track",
        "priority": "2"
      },
      "trackBorderRadius": {
        "section": "track"
      },
      "tooltipBackground": {
        "priority": "3",
        "label": "CustomDesign_Slider_Value_Label_FillColor&Opacity"
      },
      "tooltipBorderColor": {
        "state": "regular",
        "category": "border",
        "section": "tooltip",
        "priority": "3",
        "label": "CustomDesign_Borders_Color&opacity"
      },
      "tooltipBorderWidth": {
        "state": "regular",
        "category": "border",
        "section": "tooltip",
        "priority": "3",
        "label": "CustomDesign_Borders_Width"
      },
      "tooltipBorderRadius": {
        "section": "tooltip",
        "priority": "3",
        "max": 10
      },
      "tooltipFontColor": {
        "priority": "3"
      },
      "tooltipFontFamily": {
        "priority": "3"
      },
      "tooltipFontSize": {
        "priority": "3",
        "min": 10,
        "max": 24
      },
      "tooltipBoxShadow": {
        "section": "tooltip",
        "priority": "3",
        "label": "CustomDesign_Slider_tooltipBoxShadow"
      },
      "tickMarkColor": {
        "label": "CustomDesign_Slider_Steps_FillColor&Opacity",
        "priority": "4"
      }
    },
    "sections": {
      "thumb": {
        "label": "CustomDesign_Borders_Slider_Handle_Section_Title",
        "priority": 1
      },
      "track": {
        "label": "CustomDesign_Borders_Slider_Track_Section_Title",
        "priority": 2
      },
      "tooltip": {
        "label": "CustomDesign_Borders_Slider_Value_Label_Section_Title",
        "priority": 3
      }
    }
  },
  "wixui.skins.MusicPlayer": {
    "params": {
      "font": {
        "min": 12,
        "max": 18
      },
      "borderSize": {
        "min": 0,
        "max": 4
      },
      "boxShadow": {
        "hide": true
      }
    }
  },
  "wixui.skins.VideoPlayer": {
    "params": {
      "backgroundColor": {
        "label": "CustomDesign_VideoPlayer_OverlayColor"
      },
      "fontColor": {
        "label": "CustomDesign_VideoPlayer_TitleColor",
        "category": "text",
        "priority": 1
      },
      "font": {
        "priority": 2,
        "min": 12,
        "max": 70
      },
      "textSizeScale": {
        "hide": true
      },
      "playButtonSize": {
        "hide": true
      },
      "playButtonMobileSize": {
        "hide": true
      }
    }
  },
  "wixui.skins.ProgressBar": {
    "params": {
      "barBackgroundColor": {
        "label": "progressBar_Design_Fill_Background_Label",
        "priority": 2
      },
      "barForegroundColor": {
        "label": "progressBar_Design_Fill_Bar_Label",
        "priority": 1
      },
      "borderColor": {
        "category": "border"
      },
      "borderRadius": {
        "label": "CustomDesign_Corners_CornerRadius_noPX"
      },
      "boxShadow": {},
      "borderSize": {}
    }
  },
  "wixui.skins.TimePicker": {
    "params": {
      "backgroundColor": {
        "priority": "1"
      },
      "iconColor": {
        "priority": "1",
        "label": "CustomDesign_ToggleSwitch_IconColor"
      },
      "borderColor": {
        "category": "border",
        "priority": "1"
      },
      "borderWidth": {
        "priority": "1"
      },
      "borderRadius": {
        "priority": "1"
      },
      "boxShadow": {
        "priority": "1",
        "label": "CustomDesign_Slider_tooltipBoxShadow"
      },
      "txtlbl": {
        "label": "CustomDesign_Text_LabelTextColor",
        "section": "label",
        "priority": "1"
      },
      "txtlblrq": {
        "category": "text",
        "label": "CustomDesign_Text_RequiredTextColor&Opacity",
        "section": "label",
        "priority": "2"
      },
      "fntlbl": {
        "section": "label",
        "priority": "2"
      },
      "textFont": {
        "section": "input",
        "priority": "1"
      },
      "textColor": {
        "section": "input",
        "priority": "1"
      },
      "placeholderTextColor": {
        "section": "input",
        "priority": "1",
        "label": "CustomDesign_InputControls_Placeholder_Text_Color"
      },
      "hoverBackgroundColor": {
        "priority": "1"
      },
      "hoverBorderColor": {
        "category": "border",
        "priority": "1"
      },
      "hoverBorderWidth": {
        "priority": "1"
      },
      "focusBackgroundColor": {
        "priority": "1"
      },
      "focusBorderColor": {
        "category": "border",
        "priority": "1"
      },
      "focusBorderWidth": {
        "priority": "1"
      },
      "errorBackgroundColor": {
        "priority": "1"
      },
      "errorBorderColor": {
        "category": "border",
        "priority": "1"
      },
      "errorBorderWidth": {
        "priority": "1"
      },
      "disabledBackgroundColor": {
        "priority": "1"
      },
      "disabledBorderColor": {
        "category": "border",
        "priority": "1"
      },
      "disabledBorderWidth": {
        "priority": "1"
      },
      "disabledTextColor": {
        "priority": "1"
      }
    },
    "sections": {
      "input": {
        "state": "active",
        "category": "text",
        "label": "CustomDesign_Input_Section",
        "priority": 2
      },
      "label": {
        "state": "active",
        "category": "text",
        "label": "CustomDesign_Input_Label_Section",
        "priority": 1
      }
    }
  },
  "wixui.skins.ToggleSwitch": {
    "params": {
      "outerLabelBorderSizeDisabled": {
        "compPart": "on",
        "state": "disabled",
        "section": "track",
        "priority": "1"
      },
      "outerLabelBorderColorDisabled": {
        "compPart": "on",
        "state": "disabled",
        "category": "border",
        "section": "track",
        "priority": "1"
      },
      "backgroundColorDisabled": {
        "compPart": "on",
        "state": "disabled",
        "section": "track",
        "priority": "1"
      },
      "innerLabelBorderSizeDisabled": {
        "compPart": "on",
        "state": "disabled",
        "section": "knob",
        "priority": "1"
      },
      "innerLabelBorderColorDisabled": {
        "compPart": "on",
        "state": "disabled",
        "category": "border",
        "section": "knob",
        "priority": "1"
      },
      "innerLabelBackgroundColorDisabled": {
        "compPart": "on",
        "state": "disabled",
        "section": "knob",
        "priority": "1"
      },
      "colorCheckedDisabled": {
        "compPart": "on",
        "state": "disabled",
        "section": "knob",
        "priority": "1",
        "label": "CustomDesign_ToggleSwitch_IconColor"
      },
      "colorDisabled": {
        "compPart": "on",
        "state": "disabled",
        "section": "knob",
        "priority": "1",
        "label": "CustomDesign_ToggleSwitch_IconColor"
      },
      "outerLabelBorderSizeHoverChecked": {
        "compPart": "on",
        "section": "track",
        "priority": "1"
      },
      "outerLabelBorderColorHoverChecked": {
        "compPart": "on",
        "category": "border",
        "section": "track",
        "priority": "1"
      },
      "outerLabelBorderSizeFocus": {
        "compPart": "on",
        "section": "track",
        "priority": "1"
      },
      "outerLabelBorderColorFocus": {
        "compPart": "on",
        "category": "border",
        "section": "track",
        "priority": "1"
      },
      "backgroundColorHoverChecked": {
        "compPart": "on",
        "section": "track",
        "priority": "1"
      },
      "backgroundColorFocus": {
        "compPart": "on",
        "section": "track",
        "priority": "1"
      },
      "innerLabelBorderSizeHoverChecked": {
        "compPart": "on",
        "section": "knob",
        "priority": "1"
      },
      "innerLabelBorderColorHoverChecked": {
        "compPart": "on",
        "category": "border",
        "section": "knob",
        "priority": "1"
      },
      "innerLabelBackgroundColorHoverChecked": {
        "compPart": "on",
        "section": "knob",
        "priority": "1"
      },
      "colorHoverChecked": {
        "compPart": "on",
        "section": "knob",
        "priority": "1",
        "label": "CustomDesign_ToggleSwitch_IconColor"
      },
      "outerLabelBorderSizeHover": {
        "compPart": "off",
        "section": "track",
        "priority": "1"
      },
      "outerLabelBorderColorHover": {
        "compPart": "off",
        "category": "border",
        "section": "track",
        "priority": "1"
      },
      "backgroundColorHover": {
        "compPart": "off",
        "section": "track",
        "priority": "1"
      },
      "innerLabelBorderSizeHover": {
        "compPart": "off",
        "section": "knob",
        "priority": "1"
      },
      "innerLabelBorderColorHover": {
        "compPart": "off",
        "category": "border",
        "section": "knob",
        "priority": "1"
      },
      "innerLabelBackgroundColorHover": {
        "compPart": "off",
        "section": "knob",
        "priority": "1"
      },
      "colorHover": {
        "compPart": "off",
        "section": "knob",
        "priority": "1",
        "label": "CustomDesign_ToggleSwitch_IconColor"
      },
      "outerLabelBorderSizeChecked": {
        "compPart": "on",
        "section": "track",
        "priority": "1"
      },
      "outerLabelBorderColorChecked": {
        "compPart": "on",
        "category": "border",
        "section": "track",
        "priority": "1"
      },
      "backgroundColorChecked": {
        "compPart": "on",
        "section": "track",
        "priority": "1"
      },
      "innerLabelBorderSizeChecked": {
        "compPart": "on",
        "section": "knob",
        "priority": "1"
      },
      "innerLabelBorderColorChecked": {
        "compPart": "on",
        "category": "border",
        "section": "knob",
        "priority": "1"
      },
      "innerLabelBackgroundColorChecked": {
        "compPart": "on",
        "section": "knob",
        "priority": "1"
      },
      "colorChecked": {
        "compPart": "on",
        "section": "knob",
        "priority": "1",
        "label": "CustomDesign_ToggleSwitch_IconColor"
      },
      "outerLabelBorderSize": {
        "compPart": "off",
        "section": "track",
        "priority": "1"
      },
      "outerLabelBorderColor": {
        "compPart": "off",
        "category": "border",
        "section": "track",
        "priority": "1"
      },
      "borderRadius": {
        "compPart": "on",
        "section": "track",
        "priority": "1"
      },
      "backgroundColor": {
        "compPart": "off",
        "section": "track",
        "priority": "1"
      },
      "innerLabelBorderSize": {
        "compPart": "off",
        "section": "knob",
        "priority": "1"
      },
      "innerLabelBorderColor": {
        "compPart": "off",
        "category": "border",
        "section": "knob",
        "priority": "1"
      },
      "innerLabelBackgroundColor": {
        "compPart": "off",
        "section": "knob",
        "priority": "1"
      },
      "color": {
        "compPart": "off",
        "section": "knob",
        "priority": "1",
        "label": "CustomDesign_ToggleSwitch_IconColor"
      },
      "innerLabelBoxShadow": {
        "compPart": "on",
        "section": "knob",
        "priority": "1"
      },
      "outerLabelBoxShadow": {
        "compPart": "on",
        "section": "track",
        "priority": "1"
      }
    },
    "sections": {
      "track": {
        "label": "CustomDesign_ToggleSwitch_Track",
        "priority": 1
      },
      "knob": {
        "label": "CustomDesign_ToggleSwitch_Knob",
        "priority": 2
      }
    },
    "compParts": [
      {
        "label": "CustomDesign_ToggleSwitch_ComponentState_Label_On_Tab",
        "value": "on",
        "defaultSelected": true,
        "additionalPreviewStates": [
          "checked"
        ]
      },
      {
        "label": "CustomDesign_ToggleSwitch_ComponentState_Label_Off_Tab",
        "value": "off"
      }
    ]
  },
  "wixui.skins.Pagination": {
    "params": {
      "selectedPageBG": {
        "section": "selectedPage",
        "priority": "1",
        "label": "CustomDesign_Pagination_Bar_BGFillColor_SelectedPageNumber_Color&Opacity"
      },
      "navButtonBG": {
        "section": "navigationButtons",
        "priority": "2",
        "label": "CustomDesign_Pagination_Bar_BGFillColor_NavigationButtons_Color&Opacity"
      },
      "arrowsColor": {
        "section": "navigationButtons",
        "priority": "3",
        "label": "CustomDesign_Pagination_Bar_Buttons_FillColor_NavigationButtons_Color&Opacity"
      },
      "selectedPageBorderColor": {
        "section": "selectedPage",
        "priority": "1",
        "label": "CustomDesign_Borders_Color&opacity"
      },
      "selectedPageBorderWidth": {
        "section": "selectedPage",
        "priority": "2",
        "min": 0,
        "max": 3
      },
      "navButtonBorderColor": {
        "section": "navigationButtons",
        "priority": "3",
        "label": "CustomDesign_Borders_Color&opacity"
      },
      "navButtonBorderWidth": {
        "section": "navigationButtons",
        "priority": "4",
        "min": 0,
        "max": 3
      },
      "pageNumbersTextColor": {
        "priority": "1",
        "label": "CustomDesign_Pagination_Bar_PageNumberTextColor"
      },
      "selectedPageTextColor": {
        "priority": "2",
        "label": "CustomDesign_Pagination_Bar_Selected_PageNumberTextColor"
      },
      "fnt": {
        "priority": "3",
        "min": 6
      },
      "arrowsDisabledColor": {
        "priority": "2",
        "label": "CustomDesign_Pagination_Bar_FillColor_Navigation_Buttons_Arrows",
        "section": "navigationButtons"
      },
      "navButtonDisabledBG": {
        "priority": "1",
        "label": "CustomDesign_Pagination_Bar_BGFillColor_NavigationButtons_Color&Opacity",
        "section": "navigationButtons"
      },
      "navButtonDisabledBorderColor": {
        "priority": "1",
        "section": "navigationButtons"
      },
      "navButtonDisabledBorderWidth": {
        "priority": "2",
        "section": "navigationButtons",
        "min": 0,
        "max": 3
      },
      "pageInputBG": {
        "priority": "1",
        "label": "CustomDesign_Pagination_Bar_BGFillColor_Input_Field_Color&Opacity",
        "section": "inputField"
      },
      "pageInputBorderColor": {
        "section": "inputField",
        "priority": "1"
      },
      "pageInputBorderWidth": {
        "section": "inputField",
        "priority": "2",
        "min": 0,
        "max": 3
      },
      "totalPagesTextColor": {
        "priority": "1",
        "label": "CustomDesign_Pagination_Bar_TotalPages_TextColor"
      },
      "pageInputTextColor": {
        "priority": "2",
        "label": "CustomDesign_Pagination_Bar_InputNumber_TextColor"
      },
      "navButtonTextColor": {
        "priority": "4",
        "section": "navigationButtons"
      },
      "navButtonTextSize": {
        "priority": "5",
        "section": "navigationButtons",
        "min": 6,
        "max": 100
      },
      "navButtonDisabledTextColor": {
        "priority": "1"
      },
      "pageInputFocusBG": {
        "label": "CustomDesign_Pagination_Bar_BGFillColor_Input_Field_Color&Opacity"
      },
      "pageInputFocusBorderColor": {
        "section": "inputField",
        "priority": "1"
      },
      "pageInputFocusBorderWidth": {
        "section": "inputField",
        "priority": "2",
        "min": 0,
        "max": 3
      },
      "pageInputErrorBG": {
        "label": "CustomDesign_Pagination_Bar_BGFillColor_Input_Field_Color&Opacity"
      },
      "pageInputErrorBorderColor": {
        "section": "inputField",
        "priority": "1"
      },
      "pageInputErrorBorderWidth": {
        "section": "inputField",
        "priority": "2",
        "min": 0,
        "max": 3
      }
    },
    "sections": {
      "selectedPage": {
        "label": "CustomDesign_Pagination_Bar_FieldText_Section",
        "priority": 1
      },
      "navigationButtons": {
        "label": "CustomDesign_Pagination_Bar_NavButtons_Section",
        "priority": 2
      },
      "inputField": {
        "label": "CustomDesign_Pagination_Bar_Input_Field_Section",
        "priority": 1
      }
    }
  },
  "wixui.skins.Rating": {
    "params": {
      "filledShapeColor": {
        "priority": "1",
        "label": "CustomDesign_Ratings_Read_Icons_WithValue_Color&Opacity"
      },
      "errorFilledShapeColor": {
        "priority": "1",
        "label": "CustomDesign_Ratings_Write_Icons_Error_Color&Opacity"
      },
      "emptyShapeColor": {
        "priority": "2",
        "label": "CustomDesign_Ratings_Read_Icons_WithNoValue_Color&Opacity"
      },
      "shapeBorderColor": {
        "priority": "1",
        "label": "CustomDesign_Borders_Color&opacity"
      },
      "errorShapeBorderColor": {
        "priority": "1",
        "label": "CustomDesign_Borders_Color&opacity"
      },
      "shapeBorderWidth": {
        "priority": "2",
        "min": 0,
        "max": 15
      },
      "errorShapeBorderWidth": {
        "priority": "2",
        "min": 0,
        "max": 15
      },
      "labelFontColor": {
        "priority": "1"
      },
      "errorLabelFontColor": {
        "priority": "1"
      },
      "labelFont": {
        "priority": "2",
        "min": 6,
        "max": 100
      },
      "focusEmptyShapeColor": {
        "priority": "1",
        "state": "focus",
        "label": "CustomDesign_Ratings_Read_Icons_WithNoValue_Color&Opacity"
      },
      "focusShapeBorderColor": {
        "priority": "1",
        "state": "focus",
        "label": "CustomDesign_Borders_Color&opacity"
      },
      "focusShapeBorderWidth": {
        "priority": "2",
        "state": "focus",
        "min": 0,
        "max": 15
      }
    }
  },
  "wixui.skins.Tags": {
    "params": {
      "tagBackgroundColor": {
        "label": "CustomDesign_Tags_TagBackground"
      },
      "tagBackgroundColorHover": {
        "label": "CustomDesign_Tags_TagBackgroundColorHover"
      },
      "tagBorderColor": {
        "label": "CustomDesign_Tags_TagBorderColor"
      },
      "tagBorderSize": {
        "label": "CustomDesign_Tags_TagBorderSize"
      },
      "tagBoxShadow": {
        "label": "CustomDesign_Tags_TagBoxShadow"
      },
      "tagFont": {
        "label": "CustomDesign_Tags_TagFont",
        "section": "tags"
      },
      "tagFontColor": {
        "label": "CustomDesign_Tags_TagFontColor",
        "category": "text",
        "section": "tags"
      },
      "moreLinkFont": {
        "label": "CustomDesign_Tags_MoreLinkFont",
        "category": "text",
        "section": "moreLink"
      },
      "moreLinkColor": {
        "label": "CustomDesign_Tags_MoreLinkFontColor",
        "category": "text",
        "section": "moreLink"
      },
      "moreLinkColorHover": {
        "label": "CustomDesign_Tags_MoreLinkColorHover",
        "category": "text",
        "section": "moreLink"
      },
      "tagFontColorHover": {
        "label": "CustomDesign_Tags_TagFontColorHover",
        "category": "text",
        "section": "tags"
      },
      "tagBorderRadius": {
        "label": "CustomDesign_Tags_TagBorderRadius"
      }
    },
    "sections": {
      "tags": {
        "label": "CustomDesign_Tags_Tags_Section",
        "priority": 1
      }
    }
  },
  "wixui.skins.AddressInput": {
    "params": {
      "dropdownBackgroundColor": {
        "compPart": "list",
        "priority": "1"
      },
      "dropdownLocationIconColor": {
        "compPart": "list",
        "priority": "2",
        "label": "CustomDesign_FillColor_LocationIcon"
      },
      "dropdownStrokeColor": {
        "compPart": "list",
        "priority": "1",
        "label": "CustomDesign_Border_BoxColor"
      },
      "dropdownStrokeWidth": {
        "compPart": "list",
        "priority": "2",
        "label": "CustomDesign_Border_BoxWidth"
      },
      "dropdownDividerStrokeColor": {
        "compPart": "list",
        "priority": "3",
        "label": "CustomDesign_Border_DividerColor"
      },
      "dropdownDividerStrokeWidth": {
        "compPart": "list",
        "priority": "4",
        "label": "CustomDesign_Border_DividerWidth"
      },
      "dropdownBoxShadow": {
        "compPart": "list"
      },
      "dropdownTextColor": {
        "compPart": "list",
        "category": "text",
        "priority": "1",
        "label": "CustomDesign_Text_TextColor"
      },
      "dropdownFont": {
        "compPart": "list",
        "priority": "2",
        "min": 10,
        "max": 24
      },
      "dropdownHoverTextColor": {
        "compPart": "list",
        "category": "text",
        "priority": "1",
        "label": "CustomDesign_Text_TextColor"
      },
      "dropdownHoverBackgroundColor": {
        "compPart": "list",
        "state": "hover",
        "priority": "1"
      },
      "inputBackgroundColor": {
        "compPart": "input",
        "priority": "1"
      },
      "inputStrokeColor": {
        "compPart": "input",
        "priority": "1",
        "label": "CustomDesign_Grid_Container_Border_Color&Opacity"
      },
      "inputStrokeWidth": {
        "compPart": "input",
        "priority": "2",
        "label": "CustomDesign_InputControls_BorderWidth"
      },
      "inputBorderRadius": {
        "compPart": "input",
        "min": 0,
        "max": 24
      },
      "inputBoxShadow": {
        "compPart": "input"
      },
      "inputTextColor": {
        "compPart": "input",
        "category": "text",
        "section": "input",
        "priority": "1",
        "label": "CustomDesign_InputControls_Item_Text_Color"
      },
      "inputPlaceholderTextColor": {
        "compPart": "input",
        "category": "text",
        "section": "input",
        "priority": "2",
        "label": "CustomDesign_InputControls_Placeholder_Text_Color"
      },
      "inputFont": {
        "compPart": "input",
        "section": "input",
        "priority": "3",
        "min": 10,
        "max": 24
      },
      "inputHoverBackgroundColor": {
        "compPart": "input"
      },
      "inputHoverStrokeColor": {
        "compPart": "input",
        "priority": "1",
        "label": "CustomDesign_Grid_Container_Border_Color&Opacity"
      },
      "inputHoverStrokeWidth": {
        "compPart": "input",
        "priority": "2",
        "label": "CustomDesign_InputControls_BorderWidth"
      },
      "inputFocusBackgroundColor": {
        "compPart": "input",
        "priority": "1"
      },
      "inputFocusStrokeColor": {
        "compPart": "input",
        "priority": "1",
        "label": "CustomDesign_Grid_Container_Border_Color&Opacity"
      },
      "inputFocusStrokeWidth": {
        "compPart": "input",
        "priority": "2",
        "label": "CustomDesign_InputControls_BorderWidth"
      },
      "inputErrorBackgroundColor": {
        "compPart": "input",
        "priority": "1"
      },
      "inputErrorStrokeColor": {
        "compPart": "input",
        "priority": "1",
        "label": "CustomDesign_Grid_Container_Border_Color&Opacity"
      },
      "inputErrorStrokeWidth": {
        "compPart": "input",
        "priority": "2",
        "label": "CustomDesign_InputControls_BorderWidth"
      },
      "inputDisabledBackgroundColor": {
        "compPart": "input",
        "priority": "1"
      },
      "inputDisabledStrokeColor": {
        "compPart": "input",
        "priority": "1",
        "label": "CustomDesign_Grid_Container_Border_Color&Opacity"
      },
      "inputDisabledStrokeWidth": {
        "compPart": "input",
        "priority": "2",
        "label": "CustomDesign_InputControls_BorderWidth"
      },
      "inputDisabledTextColor": {
        "compPart": "input",
        "category": "text",
        "priority": "1",
        "label": "CustomDesign_Grid_Row_Text_Color&Opacity"
      },
      "txtlbl": {
        "compPart": "input",
        "label": "CustomDesign_Text_LabelTextColor",
        "section": "label",
        "priority": "1"
      },
      "txtlblrq": {
        "compPart": "input",
        "category": "text",
        "label": "CustomDesign_Text_RequiredTextColor&Opacity",
        "section": "label",
        "priority": "2"
      },
      "fntlbl": {
        "compPart": "input",
        "section": "label",
        "priority": "2"
      }
    },
    "sections": {
      "input": {
        "state": "active",
        "category": "text",
        "label": "CustomDesign_Input_Section",
        "priority": 2
      },
      "label": {
        "state": "active",
        "category": "text",
        "label": "CustomDesign_Input_Label_Section",
        "priority": 1
      }
    },
    "compParts": [
      {
        "label": "CustomDesign_ComponentState_Label_Input_Tab",
        "value": "input",
        "defaultSelected": true
      },
      {
        "label": "CustomDesign_ComponentState_Label_Dropdown_Tab",
        "value": "list",
        "additionalPreviewStates": [
          "list"
        ]
      }
    ]
  },
  "wixui.skins.SearchBox": {
    "params": {
      "inputBackgroundColor": {
        "label": "CustomDesign_FillColor_BGColor&Opacity",
        "priority": 1,
        "section": "input"
      },
      "inputIconColor": {
        "label": "CustomDesign_FillColor_Icon",
        "priority": 2,
        "section": "input"
      },
      "buttonBackgroundColor": {
        "label": "CustomDesign_FillColor_BGColor&Opacity",
        "priority": 3,
        "section": "button"
      },
      "buttonIconColor": {
        "label": "CustomDesign_FillColor_Icon",
        "priority": 4,
        "section": "button"
      },
      "inputTextColor": {
        "category": "text",
        "priority": 2,
        "label": "CustomDesign_SiteSearch_Text_TextColor"
      },
      "inputPlaceholderColor": {
        "category": "text",
        "priority": 1,
        "label": "CustomDesign_InputControls_Placeholder_Text_Color"
      },
      "inputFont": {
        "label": "CustomDesign_TabLabel_Text",
        "priority": 3,
        "min": 6,
        "max": 100
      },
      "inputBorderColor": {
        "label": "CustomDesign_Border_Opacity&Color"
      },
      "inputBorderWidth": {
        "label": "CustomDesign_Border_Width"
      },
      "inputBorderRadius": {
        "label": "CustomDesign_Corners_CornersRadius"
      },
      "inputShadow": {
        "label": "CustomDesign_TabLabel_Shadow"
      },
      "inputHoverBackgroundColor": {
        "label": "CustomDesign_FillColor_BGColor&Opacity",
        "section": "input"
      },
      "inputHoverIconColor": {
        "label": "CustomDesign_FillColor_Icon",
        "section": "input"
      },
      "buttonHoverBackgroundColor": {
        "label": "CustomDesign_FillColor_BGColor&Opacity",
        "section": "button"
      },
      "buttonHoverIconColor": {
        "label": "CustomDesign_FillColor_Icon",
        "section": "button"
      },
      "inputHoverBorderColor": {},
      "inputHoverBorderWidth": {
        "label": "CustomDesign_Border_Width"
      },
      "inputFocusBackgroundColor": {
        "label": "CustomDesign_FillColor_BGColor&Opacity",
        "section": "input"
      },
      "inputFocusIconColor": {
        "label": "CustomDesign_FillColor_Icon",
        "section": "input"
      },
      "buttonFocusBackgroundColor": {
        "state": "focus",
        "label": "CustomDesign_FillColor_BGColor&Opacity",
        "section": "button"
      },
      "buttonFocusIconColor": {
        "state": "focus",
        "label": "CustomDesign_FillColor_Icon",
        "section": "button"
      },
      "inputFocusBorderColor": {},
      "inputFocusBorderWidth": {
        "state": "focus",
        "label": "CustomDesign_Border_Width"
      },
      "suggestionSectionDividerColorNew": {
        "hide": true
      }
    },
    "sections": {
      "input": {
        "label": "CustomDesign_FillColor_Input_Field_Section_Title",
        "priority": 1
      },
      "button": {
        "label": "CustomDesign_FillColor_Button_Section_Title",
        "priority": 2
      }
    }
  },
  "wixui.skins.ImageX": {
    "params": {
      "backgroundColor": {
        "label": "editor-components-imageX.Design.ImageBackgroundColor.Label"
      },
      "mediaOpacity": {
        "label": "editor-components-imageX.Design.ImageOpacity.Label",
        "stepperMax": 100,
        "stepperStep": 1,
        "stepperFactor": 100,
        "units": "percent"
      }
    }
  }
}