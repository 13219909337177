// @ts-nocheck
import constants from '@/constants';
import * as helpIds from '@/helpIds';
import gfppDataUtils from '../../utils/gfppDataUtils';

const { ACTIONS } = constants.ROOT_COMPS.GFPP;

export default {
  untranslatable: true,
  mainActions: [
    {
      label: 'gfpp_mainaction_rich_text_box',
      isApplied: true,
      isSelected: gfppDataUtils.getPanelStateFn(ACTIONS.SETTINGS),
      onClick: gfppDataUtils.getTogglePanelFn(ACTIONS.SETTINGS),
    },
  ],
  enabledActions: [
    ACTIONS.LAYOUT,
    ACTIONS.DESIGN,
    ACTIONS.ANIMATION,
    ACTIONS.HELP,
  ],
  mobileMainActions: [ACTIONS.SCALE_UP, ACTIONS.SCALE_DOWN],
  mobileEnabledActions: [ACTIONS.LAYOUT, ACTIONS.HIDE, ACTIONS.HELP],
  presetActions: {
    help: {
      helpId: helpIds.GFPP.RICH_TEXT_BOX_HELP,
      mobileHelpId: helpIds.GFPP.RICH_TEXT_BOX_MOBILE_HELP,
    },
  },
};
