:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._loader_1l5sd_7 {
  background: #fff;
  z-index: 1999; }

._panel_1l5sd_11 .control-base-modal {
  max-height: 96vh; }

._panel_1l5sd_11 .control-base-modal .control-base-modal-body {
  padding-bottom: 0; }

._panel_1l5sd_11 .control-base-modal .control-base-modal-header {
  border-top: 0; }

._panel_1l5sd_11 .control-custom-modal.control-custom-modal
.control-custom-modal-content-wrapper {
  overflow: visible; }

._panel_opt_1l5sd_24 .control-base-modal {
  margin: 30px; }

._panel_opt_1l5sd_24 .control-base-modal .control-base-modal-header {
  padding-top: 0; }

._panel_opt_1l5sd_24 .control-base-modal-header-btn-bar > button {
  background: transparent !important; }

._panel_opt_1l5sd_24 .control-custom-modal.control-custom-modal
.control-custom-modal-content-wrapper {
  display: flex;
  height: 100%;
  padding: 0; }

._panel_opt_1l5sd_24 ._form_1l5sd_39 {
  padding-top: 30px;
  width: 50vw;
  height: 64vh;
  min-width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between; }
  @media (max-height: 965px), (max-width: 1288px), (min-height: 965px) {
    ._panel_opt_1l5sd_24 ._form_1l5sd_39 {
      height: 100%; } }

._panel_opt_1l5sd_24 ._title_1l5sd_51 {
  padding: 18px 84px 18px 54px; }

._panel_opt_1l5sd_24 ._row_1l5sd_54 > .composite-text-input-multiline-labeled {
  padding-top: 6px; }

._panel_opt_1l5sd_24 ._row_1l5sd_54 > .composite-text-input-labeled {
  padding-top: 0; }

@media (max-width: 800px) {
  ._panel_opt_1l5sd_24 ._row_1l5sd_54 > .composite-text-input-multiline-labeled .input-container,
  ._panel_opt_1l5sd_24 ._row_1l5sd_54 > .composite-text-input-multiline-labeled .input {
    height: 60px !important; } }

._form_1l5sd_39 {
  width: 600px; }

._title_1l5sd_51 {
  position: relative;
  padding: 30px 84px 18px 54px; }
  ._title_1l5sd_51 .control-badge {
    display: inline-block !important;
    margin-left: 10px;
    margin-bottom: -1px; }
  ._title_1l5sd_51 .control-label-base {
    line-height: 1.3 !important; }

._icon_1l5sd_78 {
  position: absolute;
  top: 28px;
  right: 47px;
  width: 34px;
  height: 26px; }

._subtitle_1l5sd_85 {
  padding: 0 84px 18px 54px; }

._row_1l5sd_54 {
  display: block;
  padding: 0 30px; }

._submit_1l5sd_92 {
  padding-top: 30px;
  padding-bottom: 30px; }
  ._submit_opt_1l5sd_95 {
    padding-top: 12px;
    padding-bottom: 30px;
    z-index: 2; }

._terms_1l5sd_100 {
  padding: 12px 0;
  border-radius: 4px; }

._footer_1l5sd_104 {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.8) 0%, #fff 88.54%);
  border-radius: 4px;
  flex-grow: 2;
  z-index: 2; }
  ._footer_1l5sd_104 ._terms_1l5sd_100 {
    background-color: #fff; }
