import React from 'react';
import LayoutPresets from './LayoutPresets';

import type { CompRef } from 'types/documentServices';
import type { Preset } from './LayoutPresets';

const siteSegmentLayoutPresets: Preset[] = [
  {
    id: 'layout1',
    icon: '50_50',
    proportion: [[50, 50]],
    tooltip: 'section_header_footer_blank_layout_two_columns_tooltip',
  },
  {
    id: 'layout2',
    icon: '25_75',
    proportion: [[25, 75]],
    tooltip: 'section_header_footer_blank_layout_two_asym_columns_tooltip',
  },
  {
    id: 'layout3',
    icon: '33_33_33',
    tooltip: 'section_header_footer_blank_layout_three_columns_tooltip',
    proportion: [[34, 33, 33]],
  },
  {
    id: 'layout4',
    icon: '25_50_25',
    tooltip: 'section_header_footer_blank_layout_three_asym_columns_tooltip',
    proportion: [[25, 50, 25]],
  },
];

interface SiteSegmentLayoutPresetsProps {
  siteSegment: CompRef;
}

export const SiteSegmentLayoutPresets: React.FC<SiteSegmentLayoutPresetsProps> =
  React.memo(({ siteSegment }) => (
    <LayoutPresets
      presets={siteSegmentLayoutPresets}
      sectionLike={siteSegment}
    />
  ));

SiteSegmentLayoutPresets.displayName = 'SiteSegmentLayoutPresets';
