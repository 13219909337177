import * as util from '@/util';
import constants from '@/constants';
import React, { useEffect, useRef, useState } from 'react';
import styles from './siteCreationFPD.scss';
import SiteCreationBottomSection from './siteCreationBottomSection';
import SiteCreationFPDThumbnail from './siteCreationFPDThumbnail';
import HorizontalLoader from './horizontalLoader';
import {
  disablePageScroll,
  enablePageScroll,
  getHeaderFooterContentItems,
  getScale,
  getThumbnailWidth,
  removeUnsupportedSections,
  getCountOrCuratedTemplateIds,
} from './siteCreationUtils';
import {
  COLUMN_MARGINS,
  FPD_COUNT,
  MIN_FOOTER_HEIGHT,
  MIN_HEADER_HEIGHT,
  DEFAULT_INDUSTRY_ID,
  DEFAULT_STRUCTURE_ID,
  PLACEHOLDER_DIMENSIONS_RATIO,
} from './siteCreationConstants';
import { translate as t } from '@/i18n';
import { adapter } from '@/presetApi';

import type { BiEventFields } from 'types/bi';
import type { SiteCreationProps } from './siteCreationPagesView';
import type { UserContactInfo } from '@/editorPaas';
import type { PageAlternative, CeType } from '@/presetApi';

export interface SiteCreationFPDProps extends SiteCreationProps {
  editorPaasInitFinished: boolean;
  userContactInfo: UserContactInfo;
  setHeaderPresetNode: (headerPresetNode: any) => void;
  setFooterPresetNode: (footerPresetNode: any) => void;
  setCuratedTemplateId: (curatedTemplateId: number) => void;
  setAddHomePagePromise: (addPagePromise: Promise<void>) => void;
  setChosenHomePagePreset: (page: PageAlternative) => void;
}

const { SITE_CREATION: SITE_CREATION_CONSTS } = constants;
const HOME_NAME = t('site_creation_site_homepage_default');
const HOMEPAGE_ADD_DELAY = 100;
const FPD_SCREEN_HIDDEN_TIMEOUT = 3500;

function getThumbnailsHeight(
  windowHeight: number,
  headingRef: React.RefObject<HTMLDivElement>,
  footerRef: React.RefObject<HTMLDivElement>,
): number {
  return (
    windowHeight -
    (headingRef?.current?.clientHeight || MIN_HEADER_HEIGHT) -
    (footerRef?.current?.clientHeight || MIN_FOOTER_HEIGHT)
  );
}

const SiteCreationFPD: React.FC<SiteCreationFPDProps> = ({
  editorPaasInitFinished,
  windowWidth,
  windowHeight,
  addPagePreset,
  getPagePresets,
  userContactInfo,
  installChosenApps,
  fireAddingHomepage,
  setHeaderPresetNode,
  fireNextButtonClick,
  setFooterPresetNode,
  fireViewStartLoading,
  fireContentScrolled,
  fireViewContentLoaded,
  setCuratedTemplateId,
  setAddHomePagePromise,
  setChosenHomePagePreset,
  setReplacementsBusinessData,
}) => {
  const [isPlaceholderVisible, setIsPlaceholderVisible] =
    useState<boolean>(false);
  const [scale, setScale] = useState<number>(0.25);
  const [isAdding, setIsAdding] = useState<boolean>(false);
  const [isScreenVisible, setScreenVisible] = useState<boolean>(true);
  const [presets, setPresets] = useState<PageAlternative[]>([]);
  const [sectionsCeTypes, setSectionsCeTypes] = useState<CeType[]>([]);
  const [selectedHomepage, setSelectedHomepage] =
    useState<PageAlternative>(null);
  const [selectedBi, setSelectedBi] = useState<BiEventFields>({});
  const { siteCreationController } = window;
  const headingRef = useRef<HTMLDivElement>(null);
  const footerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setScale(getScale(windowWidth));
  }, [windowWidth]);

  useEffect(() => {
    Promise.all([
      siteCreationController.getChooseKitScreenPromise(),
      siteCreationController.initEditorPaasPromise,
    ]).then(() => {
      fireViewStartLoading(SITE_CREATION_CONSTS.SCREENS.HOMEPAGE);
      setIsPlaceholderVisible(true);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (editorPaasInitFinished) {
      setReplacementsBusinessData().then(async () => {
        const [homePage] = siteCreationController.caas.getPages();
        const ceTypes = homePage.sections.map(
          (section) => section.ceType,
        ) as CeType[];
        const countOrCuratedTemplateIds = getCountOrCuratedTemplateIds();
        window.siteCreationController.hooks.siteCreationCreatePresets.fire({
          isCreatePresetsInProgress: true,
        });
        let presets = await getPagePresets(
          siteCreationController.caas,
          homePage,
          userContactInfo,
          countOrCuratedTemplateIds,
          getHeaderFooterContentItems(),
          true,
        );
        if (presets.length === 0) {
          const { ContentManager, SUPPORTED_CE_TYPES_IN_PAAS } =
            await adapter();
          window.siteCreationController.setCaas(
            ContentManager,
            SUPPORTED_CE_TYPES_IN_PAAS,
            DEFAULT_INDUSTRY_ID,
            DEFAULT_STRUCTURE_ID,
          );
          await window.siteCreationController.caasContentPromise;
          const [defaultHomePage] = siteCreationController.caas.getPages();
          presets = await getPagePresets(
            siteCreationController.caas,
            defaultHomePage,
            userContactInfo,
            countOrCuratedTemplateIds,
            getHeaderFooterContentItems(),
            true,
          );
        }
        presets.forEach((page) => removeUnsupportedSections(page));
        disablePageScroll();
        fireViewContentLoaded(SITE_CREATION_CONSTS.SCREENS.HOMEPAGE);
        setPresets(presets);
        setSectionsCeTypes(ceTypes);
        if (!siteCreationController.autoSkip) {
          setIsPlaceholderVisible(false);
        }
        siteCreationController.setSiteCreationInstallAppsPromise(
          installChosenApps(),
        );
        if (siteCreationController.autoSkip) {
          addChosenFPD(presets[0], NaN, null);
        }
        window.siteCreationController.hooks.siteCreationCreatePresets.fire({
          isCreatePresetsInProgress: false,
        });
      });
    }
    return enablePageScroll;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editorPaasInitFinished]);

  const onClickThumbnail = (
    page: PageAlternative,
    index: number,
    actionSource: string | null,
  ) => {
    if (isAdding) return;
    setSelectedBi({ index, actionSource });
    setSelectedHomepage(page);
  };

  const addChosenFPD = async (
    page: PageAlternative,
    index: number,
    actionSource: string,
  ) => {
    if (isAdding) return;
    setIsAdding(true);
    siteCreationController.screensLoadingTimes.homepage.setFinished();
    page = page || presets[0];
    fireAddingHomepage(index, actionSource, page.pageConfig.curatedTemplateId);
    await new Promise(requestAnimationFrame);
    await new Promise((resolve) => setTimeout(resolve, HOMEPAGE_ADD_DELAY));
    setAddHomePagePromise(
      addPagePreset(
        page,
        HOME_NAME,
        true,
        true,
        page.pageConfig.curatedTemplateId,
        sectionsCeTypes,
        page.pageConfig.colorationMethod,
      ),
    );
    setHeaderPresetNode(page.presetPreviews[0]);
    setFooterPresetNode(page.presetPreviews[page.presetPreviews.length - 1]);
    setCuratedTemplateId(page.pageConfig.curatedTemplateId);
    setChosenHomePagePreset(page);
    window.setTimeout(() => {
      setScreenVisible(false);
    }, FPD_SCREEN_HIDDEN_TIMEOUT);
  };

  const thumbnailsHeight = getThumbnailsHeight(
    windowHeight,
    headingRef,
    footerRef,
  );

  const getPresetsPlaceholders = () => {
    const width = getThumbnailWidth(scale) * FPD_COUNT + COLUMN_MARGINS;
    const height = width / PLACEHOLDER_DIMENSIONS_RATIO;
    return (
      <div
        className={styles.siteCreationFPDPlaceholder}
        style={{
          width,
          height,
          maxHeight: thumbnailsHeight,
          backgroundImage: `url(${util.media.getMediaUrl(
            `siteCreation/fpdsSkelaton.gif`,
          )})`,
        }}
      />
    );
  };

  return (
    <div
      className={util.cx(styles.siteCreationFPD, { hidden: !isScreenVisible })}
      data-hook="fpd-screen"
    >
      <div className={styles.siteCreationFPDContentWrapper}>
        <div className={styles.siteCreationFPDTitlesWrapper} ref={headingRef}>
          <span className="main-fpd-title">
            {t(
              presets.length
                ? 'site_creation_homepage_design_title'
                : 'site_creation_loading_homepage_design_title_',
            )}
          </span>
          <span className="secondary-fpd-title">
            {t(
              presets.length
                ? 'site_creation_homepage_design_subtitle'
                : 'Site_creation_loading_homepage_design_subtitle_',
            )}
          </span>
        </div>
        <div className={styles.siteCreationFPDColumnsWrapper}>
          {presets.length && !siteCreationController.autoSkip
            ? presets.map((pagePreset, index) => (
                <SiteCreationFPDThumbnail
                  scale={scale}
                  presetIndex={index}
                  pagePreset={pagePreset}
                  thumbnailHeight={thumbnailsHeight}
                  fireContentScrolled={fireContentScrolled}
                  onClick={(actionSource: string) => {
                    onClickThumbnail(pagePreset, index, actionSource);
                  }}
                  key={pagePreset.pageConfig.curatedTemplateId}
                  isSelected={index === selectedBi.index}
                />
              ))
            : getPresetsPlaceholders()}
        </div>
      </div>
      <HorizontalLoader shouldShow={isPlaceholderVisible} />
      <SiteCreationBottomSection
        isLoaderVisible={isAdding}
        hideButtons={!presets.length}
        wrapperRef={footerRef}
        actionsDisabled={!presets.length}
        mainActionDisabled={!selectedHomepage}
        mainActionLabel={t('site_creation_next_button')}
        symbol={'arrowRightWhite'}
        onMainActionClick={() => {
          fireNextButtonClick(
            SITE_CREATION_CONSTS.SCREENS.HOMEPAGE,
            SITE_CREATION_CONSTS.NAVIGATIONS.NEXT,
          );
          addChosenFPD(
            selectedHomepage,
            selectedBi.index,
            selectedBi.actionSource,
          );
        }}
        secondaryActionLabel={t('site_creation_homepage_design_skip_button')}
        onSecondaryActionClick={() => {
          fireNextButtonClick(
            SITE_CREATION_CONSTS.SCREENS.HOMEPAGE,
            SITE_CREATION_CONSTS.NAVIGATIONS.SKIP,
          );
          addChosenFPD(presets[0], NaN, 'skip');
        }}
      />
    </div>
  );
};

export default SiteCreationFPD;
