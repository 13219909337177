// @ts-nocheck
import _ from 'lodash';
import experiment from 'experiment';
import * as helpIds from '@/helpIds';
import addPanelDataConsts from '@/addPanelDataConsts';
import * as blogAPI from '@/blog';
import * as wixStore from '@/wixStore';
import { isDataModeOn } from '@/wixData';
import * as stateManagement from '@/stateManagement';
import { tpaUtils } from '@/util';
import { DATA_BINDING, MEMBERS_AREA } from '@wix/app-definition-ids';

/*
 * How to add a category (with or without experiment)
 * ==================================================
 *
 * Add to the "categories" array bellow an object
 * in the desired position with the following structure:
 *
 * {
 *   id: string, unique category id from addPanelDataConsts.CATEGORIES_ID,
 *   label: string, language key to be displayed in the add panel list of categories,
 *   title: string, language key to be displayed in the add panel title,
 *   help: {
 *       hide: boolean, use "false"; will be used for A/B testing in the future,
 *       helpId: string, GUID for the help content in the Help Center
 *   },
 *   [optional] experiment: string, name of the experiment that enables this category,
 *   [optional] condition: function, will be called with editorApi and should return true to enable this category,
 *   [optional] shouldTranslate: boolean, if set to false keys will not be translated. Defaults to true.
 *   hasSubCategory: boolean, is the category uses subCategoryNavigaion. If you don't know, use false
 * }
 *
 * @ShaiLa, 22-DEC-2015
 * */

const getCategories = function (editorAPI) {
  const installedAppsCondition = () =>
    !_.isEmpty(
      stateManagement.platform.selectors.getInstalledPackages(editorAPI.dsRead),
    );

  const hasUserSavedComponents = () => {
    const state = editorAPI.store.getState();
    const { getIsEmpty } = stateManagement.savedComponents.selectors;
    return !getIsEmpty(state);
  };
  return [
    {
      id: addPanelDataConsts.CATEGORIES_ID.STRIP,
      label: 'add_category_label_strip',
      title: 'add_category_title_strip',
      help: {
        hide: false,
        helpId: helpIds.ADD_PANEL.STRIP,
      },
      condition() {
        return (
          experiment.isOpen('se_AddPanelStripSectionAsTopSection') &&
          !experiment.isOpen('se_addPanelStripSectionAfterPage') &&
          (!editorAPI.dsRead.pages.popupPages.isPopupOpened() ||
            editorAPI.pages.popupPages.isFullWidthPopupOpened())
        );
      },
      hasSubCategory: true,
    },
    {
      id: addPanelDataConsts.CATEGORIES_ID.PAGE,
      label: 'add_section_label_page',
      title: 'add_section_label_add_page',
      help: {
        hide: false,
        helpId: 'a33d53d4-9458-4526-93ea-67c1e6acc9b4',
      },
      props: {
        class: 'no-space-before-section invisible-header',
      },
      condition() {
        return editorAPI.developerMode.isEnabled();
      },
      hasSubCategory: false,
    },
    {
      id: addPanelDataConsts.CATEGORIES_ID.STRIP,
      label: 'add_category_label_strip',
      title: 'add_category_title_strip',
      help: {
        hide: false,
        helpId: helpIds.ADD_PANEL.STRIP,
      },
      condition() {
        return (
          experiment.isOpen('se_AddPanelStripSectionAsTopSection') &&
          experiment.isOpen('se_addPanelStripSectionAfterPage') &&
          (!editorAPI.dsRead.pages.popupPages.isPopupOpened() ||
            editorAPI.pages.popupPages.isFullWidthPopupOpened())
        );
      },
      hasSubCategory: true,
    },
    {
      id: addPanelDataConsts.CATEGORIES_ID.TEXT,
      label: 'add_category_label_text',
      title: 'add_category_title_text',
      help: {
        hide: false,
        helpId: '792327a0-6933-43fa-8816-8fa4ccf97c45',
      },
      hintProps: {
        id: 'AddText',
      },
      hasSubCategory: true,
    },
    {
      id: addPanelDataConsts.CATEGORIES_ID.IMAGE,
      label: 'add_category_label_image',
      title: 'add_category_title_image',
      help: {
        hide: false,
        helpId: helpIds.ADD_PANEL.IMAGE,
      },
      hintProps: {
        id: 'AddImage',
      },
      hasSubCategory: true,
      condition: () =>
        !experiment.isOpen('se_addPanel_image_replaceWithDynamicCategory'),
    },
    {
      id: addPanelDataConsts.CATEGORIES_ID.IMAGE_DYNAMIC,
      label: 'add_category_label_image',
      title: 'add_category_title_image',
      help: {
        hide: false,
        helpId: helpIds.ADD_PANEL.IMAGE,
      },
      hintProps: {
        id: 'AddImage',
      },
      hasSubCategory: true,
      condition: () =>
        experiment.isOpen('se_addPanel_image_replaceWithDynamicCategory'),
    },
    {
      id: addPanelDataConsts.CATEGORIES_ID.BUTTON,
      label: 'add_category_label_button',
      title: 'add_category_title_button',
      help: {
        hide: false,
        helpId: 'f196b636-d7c6-459c-8c7e-7aeb254f5fb5',
      },
      hintProps: {
        id: 'AddButton',
      },
      hasSubCategory: true,
    },
    {
      // mock category not really in use
      id: 'newCategoryId',
      hasSubCategory: false,
    },
    {
      id: addPanelDataConsts.CATEGORIES_ID.GALLERY,
      label: 'add_category_label_gallery',
      title: 'add_category_title_gallery',
      help: {
        hide: false,
        helpId: 'de578d88-d1fe-4a98-b671-a355ee8d7113',
      },
      hintProps: {
        id: 'AddGallery',
      },
      hasSubCategory: true,
    },
    {
      id: addPanelDataConsts.CATEGORIES_ID.DECORATIVE,
      label: 'add_category_label_decorative',
      title: 'add_category_title_decorative',
      help: {
        hide: false,
        helpId: '7828997e-dc5e-4ca1-812a-6451211232a4',
      },
      hasSubCategory: true,
    },
    {
      id: addPanelDataConsts.CATEGORIES_ID.BOX_SLIDE_SHOW,
      label: 'add_section_label_interactive',
      title: 'add_category_title_interactive',
      help: {
        hide: false,
        helpId: 'f2154651-abc6-4218-9a52-eeadf96c6e73',
      },
      hasSubCategory: true,
    },
    {
      id: addPanelDataConsts.CATEGORIES_ID.BOX,
      label: 'add_category_label_box',
      title: 'add_category_title_box_only',
      help: {
        hide: false,
        helpId: helpIds.ADD_PANEL.BOX,
      },
      hasSubCategory: true,
    },
    {
      id: addPanelDataConsts.CATEGORIES_ID.STRIP,
      label: 'add_category_label_strip',
      title: 'add_category_title_strip',
      help: {
        hide: false,
        helpId: helpIds.ADD_PANEL.STRIP,
      },
      condition() {
        return (
          !experiment.isOpen('se_AddPanelStripSectionAsTopSection') &&
          (!editorAPI.dsRead.pages.popupPages.isPopupOpened() ||
            editorAPI.pages.popupPages.isFullWidthPopupOpened())
        );
      },
      hasSubCategory: true,
    },
    {
      id: addPanelDataConsts.CATEGORIES_ID.REPEATER,
      label: 'add_category_label_listsandgrids',
      title: 'add_category_title_repeating_layout',
      help: {
        hide: false,
        helpId: '57ef0099-438e-4355-8bb5-d1a80a191776',
      },
      hasSubCategory:
        editorAPI.developerMode.isEnabled() || isDataModeOn(editorAPI),
    },
    {
      id: addPanelDataConsts.CATEGORIES_ID.MEDIA,
      label: 'add_category_label_media',
      title: 'add_category_title_media',
      help: {
        hide: false,
        helpId: '5811afb1-fb95-4460-8e7c-70b92ab539a4',
      },
      hasSubCategory: true,
    },
    {
      id: addPanelDataConsts.CATEGORIES_ID.NAVIGATION,
      label: 'add_category_label_menu',
      title: 'add_category_title_menu',
      help: {
        hide: false,
        helpId: '2ad19566-8644-4e07-82c7-972539aa0c29',
      },
      hasSubCategory: true,
    },
    {
      id: addPanelDataConsts.CATEGORIES_ID.SOCIAL,
      label: 'add_category_label_social',
      title: 'add_category_title_social',
      help: {
        hide: false,
        helpId: '27cf0316-3c15-4dfd-b43f-6f10ab44c24c',
      },
      hasSubCategory: true,
    },
    {
      id: addPanelDataConsts.CATEGORIES_ID.CONTACT,
      label: 'add_category_label_contact_tools',
      title: 'add_category_title_contact_tool',
      help: {
        hide: false,
        helpId: '4aec8834-e911-4bf1-9b88-1ce05e03eb7f',
      },
      hasSubCategory: true,
    },
    {
      id: addPanelDataConsts.CATEGORIES_ID.EMBEDS,
      label: 'add_category_label_embed',
      title: 'add_category_title_embed',
      help: {
        hide: false,
        helpId: 'b3f6305d-8d7b-4d2c-8ab5-1c87b086cf12',
      },
      hasSubCategory: true,
    },
    {
      id: addPanelDataConsts.CATEGORIES_ID.DEVELOPER,
      label: 'add_category_label_developer',
      title: 'add_category_title_developer',
      help: {
        hide: false,
        helpId: '45287fdd-b807-46e5-a8ce-60988afb2e14',
      },
      condition: () =>
        editorAPI.developerMode.isEnabled() || isDataModeOn(editorAPI),
      hasSubCategory: true,
    },
    {
      id: addPanelDataConsts.CATEGORIES_ID.DATABASE,
      label: 'add_category_label_CMS',
      title: editorAPI.documentServices.wixCode.isProvisioned()
        ? 'add_category_title_CMS_elements'
        : 'add_category_title_CMS',
      appDefinitionId: DATA_BINDING,
      help: {
        hide: false,
        helpId:
          editorAPI.developerMode.isEnabled() || isDataModeOn(editorAPI)
            ? '76c4f8d2-2572-4ce7-8314-fcf5d743c8fe'
            : '3085ff6a-ccc5-4f84-8051-e473a8a4282f',
      },
      hasSubCategory: false,
    },
    {
      id: addPanelDataConsts.CATEGORIES_ID.NEW_BLOG,
      label: 'add_category_label_newblog',
      title: 'add_section_label_newblog_elements',
      appDefinitionId: '14bcded7-0066-7c35-14d7-466cb3f09103',
      help: {
        hide: false,
        helpId: helpIds.ADD_PANEL.NEW_BLOG,
      },
      condition() {
        return blogAPI.siteHasNewBlog(editorAPI);
      },
      hasSubCategory: true,
    },
    {
      id: addPanelDataConsts.CATEGORIES_ID.NEW_BLOG_WELCOME,
      label: 'add_category_label_newblog',
      title: 'add_section_label_newblog',
      appDefinitionId: '14bcded7-0066-7c35-14d7-466cb3f09103',
      help: {
        hide: false,
        helpId: helpIds.ADD_PANEL.NEW_BLOG_WELCOME,
      },
      condition() {
        return !blogAPI.siteHasNewBlog(editorAPI);
      },
      hasSubCategory: false,
    },
    {
      id: addPanelDataConsts.CATEGORIES_ID.STORE,
      label: 'add_category_label_store',
      title: wixStore.wixStoreDataProvider.isAppInstalled(editorAPI)
        ? 'SiteApp_WixStores_AddTab_Header'
        : 'add_category_title_store',
      appDefinitionId: tpaUtils.getEcomAppDefId(),
      help: {
        hide: false,
        helpId: '23862857-e4f2-4205-87da-592e3cad64de',
      },
      hasSubCategory: wixStore.wixStoreDataProvider.isAppInstalled(editorAPI),
    },
    {
      id: addPanelDataConsts.CATEGORIES_ID.BOOKINGS,
      label: 'add_category_label_bookings',
      title: 'add_category_title_bookings',
      appDefinitionId: '13d21c63-b5ec-5912-8397-c3a5ddb27a97',
      help: {
        hide: false,
        helpId: helpIds.ADD_PANEL.WIX_BOOKINGS_ADD_ELEMENT,
      },
      condition() {
        // return wixBookings.isWixBookingsInstalled(editorAPI);
        return editorAPI.tpa.app.isInstalled(
          '13d21c63-b5ec-5912-8397-c3a5ddb27a97',
        );
      },
      hasSubCategory: true,
    },
    {
      id: addPanelDataConsts.CATEGORIES_ID.BOOKINGS_WELCOME,
      label: 'add_category_label_bookings',
      title: 'add_category_title_bookings_welcome',
      appDefinitionId: '13d21c63-b5ec-5912-8397-c3a5ddb27a97',
      help: {
        hide: false,
        helpId: helpIds.ADD_PANEL.WIX_BOOKINGS_WELCOME,
      },
      condition() {
        // return !wixBookings.isWixBookingsInstalled(editorAPI);
        return !editorAPI.tpa.app.isInstalled(
          '13d21c63-b5ec-5912-8397-c3a5ddb27a97',
        );
      },
      hasSubCategory: false,
    },
    {
      id: addPanelDataConsts.CATEGORIES_ID.EVENTS,
      label: 'add_category_label_events',
      title: 'add_category_title_events',
      appDefinitionId: '140603ad-af8d-84a5-2c80-a0f60cb47351',
      help: {
        hide: false,
        helpId: helpIds.ADD_PANEL.EVENTS,
      },
      condition() {
        return editorAPI.tpa.app.isInstalled(
          '140603ad-af8d-84a5-2c80-a0f60cb47351',
        );
      },
      hasSubCategory: false,
    },
    {
      id: addPanelDataConsts.CATEGORIES_ID.EVENTS_WELCOME,
      label: 'add_category_label_events',
      title: 'add_category_title_events',
      help: {
        hide: false,
        helpId: helpIds.ADD_PANEL.EVENTS,
      },
      condition() {
        return !editorAPI.tpa.app.isInstalled(
          '140603ad-af8d-84a5-2c80-a0f60cb47351',
        );
      },
      hasSubCategory: false,
    },
    {
      id: addPanelDataConsts.CATEGORIES_ID.MEMBERS,
      label: 'add_category_label_members',
      title: 'add_category_title_members2',
      appDefinitionId:
        editorAPI.dsRead.platform.editorApps.SANTA_MEMBERS.appDefId,
      help: {
        hide: false,
        helpId: helpIds.ADD_PANEL.MEMBERS,
      },
      condition() {
        return editorAPI.tpa.app.isInstalled(MEMBERS_AREA);
      },
      hasSubCategory: true,
    },
    {
      id: addPanelDataConsts.CATEGORIES_ID.MEMBERS_WELCOME,
      label: 'add_category_label_members',
      title: 'add_category_title_members',
      appDefinitionId:
        editorAPI.dsRead.platform.editorApps.SANTA_MEMBERS.appDefId,
      help: {
        hide: false,
        helpId: helpIds.ADD_PANEL.MEMBERS_WELCOME,
      },
      condition() {
        return !editorAPI.tpa.app.isInstalled(MEMBERS_AREA);
      },
      hasSubCategory: false,
    },
    {
      id: 'categoryWithWelcome',
      hasSubCategory: false,
    },
    {
      id: addPanelDataConsts.CATEGORIES_ID.INSTALLED_APPS,
      label: 'add_category_label_installed_apps',
      title: 'add_category_label_installed_apps',
      help: {
        hide: false,
        helpId: 'eabe1ada-d279-47a5-abf0-9437367e17ab',
      },
      hasSubCategory: true,
      condition: installedAppsCondition,
    },
    {
      id: addPanelDataConsts.CATEGORIES_ID.SAVED_COMPONENTS,
      label: 'add_category_label_MyDesigns',
      title: 'add_category_title_MyDesigns',
      help: {
        hide: false,
        helpId: 'a7fcc82b-b766-4a66-ac2d-b1648bbbdb46',
      },
      hasSubCategory: false,
      condition: () => hasUserSavedComponents(),
    },
    {
      id: addPanelDataConsts.CATEGORIES_ID.SAVED_COMPONENTS_WELCOME,
      label: 'add_category_label_MyDesigns',
      title: 'add_category_title_MyDesigns_empty_state',
      help: {
        hide: false,
        helpId: 'a7fcc82b-b766-4a66-ac2d-b1648bbbdb46',
      },
      hasSubCategory: false,
      condition: () => !hasUserSavedComponents(),
    },
  ];
};

function getCategoryDefinitions(editorAPI) {
  const categories = getCategories(editorAPI);

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/filter
  return _.filter(categories, function (category) {
    return (
      (!category.experiment || experiment.isOpen(category.experiment)) &&
      (!category.condition || category.condition())
    );
  });
}

export { getCategoryDefinitions as get };
