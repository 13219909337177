/*eslint max-lines: [2, { "max": 1725, "skipComments": true, "skipBlankLines": true}]*/
import _ from 'lodash';
import { translate as t } from '@/i18n';
import constants from '@/constants';
import { AddPresetApiKey } from '@/apis';
import { domainSuggestions } from '@/stateManagement';
import {
  fedopsLogger,
  isDebugMode,
  link,
  fakeBrowserUtils,
  object as objectUtils,
  sections,
  url,
  http,
} from '@/util';
import { layoutUtils } from '@/layoutUtils';
import {
  constants as tpaConstants,
  bi as tpaBi,
  tpaUtils,
  superApps,
} from '@/tpa';
import {
  WIX_BOOKINGS,
  WIX_NEW_STORES,
  WIX_PRO_GALLERY,
  WIX_STORES,
} from '@wix/app-definition-ids';
import {
  ALLOWED_SILENT_INSTALLATION_APPS,
  ALLOWED_HEADLESS_INSTALL_APP_DEF_IDS,
} from '@wix/editor-site-generator';
import { events } from '@/coreBi';
import * as wixStore from '@/wixStore';
import {
  ADDITIONAL_PAGES_COLUMN_NUM,
  EDITOR_CONTAINERS_BACKGROUND_THEME_COLOR,
  MAX_SITE_NAME_LENGTH,
  MIN_FOOTER_HEIGHT,
  MIN_HEADER_HEIGHT,
  PAAS_PREVIEW_BASE_FRAME_WIDTH,
  PAAS_PREVIEW_BASE_SCALE,
  POSITION,
  REGEX_ENDS_HYPHEN,
  SITE_CREATION_PROVISION_ORIGIN,
  SITE_EXTRA_MARGIN,
  SITE_NAME_INVALID_CHARS_REGEX,
  ALLOWED_CURATED_TEMPLATED_IDS,
  APPS_PRIORITY_MAP,
} from './siteCreationConstants';
import type {
  PaaSRenderedModelNode,
  CeType,
  PaaSPageRenderedModel,
  PageAlternative,
  PreviewFlag,
} from '@/presetApi';
import { appToAppDefId, adapter, AppType } from '@/presetApi';
import {
  EditorPlatformHostIntegrationAPI,
  type AppInstallOrigin,
} from '@wix/editor-platform-host-integration-apis';
import {
  InstallationOriginType,
  InstallInitiator,
  EditorType,
} from '@wix/platform-editor-sdk';
import { paasColorsToInverseEntries } from '@/editorPaas';
import {
  appDefIdToSectionStructureMap,
  appendProGalleryIfNeeded,
  getWidgetPositionCfg,
  type PositionCfg,
  sortWidgetsByPriority,
} from './siteCreationWidgetSections';
import { getComponentsBelowPosition, shiftComponents } from '@/sectionsOnStage';
import { ErrorReporter } from '@wix/editor-error-reporter';
import { serviceGenerator } from '@/wixBookings';
import { isMeshLayoutEnabled } from '@/layout';

import type {
  EditorPaasApi,
  HeaderFooterContentItemData,
  PaasSocialLinkData,
  PageMobileAdjustmentsConfig,
  SocialLink,
} from '@/editorPaas';
import type { RefObject } from 'react';
import type { EditorAPI } from '@/editorAPI';
import type {
  CompLayout,
  CompRef,
  CompStructure,
  MenuData,
  MenuItem,
  PageLink,
  Pointer,
} from 'types/documentServices';
import type { SiteProperties } from 'types/siteProperties';
import type { SilentInstallAppsOptions } from '@/platform';

const { fetchDomainSuggestions, getSiteBusinessNameForDomainSuggestion } =
  domainSuggestions.actions;

const isDev = isDebugMode();

export interface AdditionalPage {
  id: string;
  name: string;
  isSelected: boolean;
  page: PageAlternative;
  ceTypes: CeType[];
}

export interface Address {
  street?: string;
  city?: string;
  country?: string;
  state?: string;
  zip?: string;
  isPhysical?: boolean;
  googleFormattedAddress: string;
  streetNumber?: string;
  coordinates?: {
    latitude: number;
    longitude: number;
  };
}

export interface CeTypeToSection {
  [ceType: string]: number;
}

interface AppDefIdToNameMap {
  [appDefId: string]: string;
}

interface WidgetOptions {
  sourceTemplateId?: string;
}

const { interactionStarted, interactionEnded, INTERACTIONS } = fedopsLogger;

const SEARCH_BAR_WIDTH = 170;

const TEMP_APPS_TO_ADD_SEPARATELY_FROM_SECTION = new Set([WIX_PRO_GALLERY]);

export function getSocialLinksData(
  socialLinks: SocialLink[],
): PaasSocialLinkData[] {
  return socialLinks.map((socialLink) => ({
    fileName: socialLink.platform,
    relativeUri: socialLink.relativeUri,
    width: 200,
    height: 200,
    Link: {
      targetUrl: socialLink.url,
    },
    mediaType: 'icon',
    platform: socialLink.platform,
    Title: socialLink.Title,
  }));
}

export function getScale(windowWidth: number, baseScale?: number): number {
  return (
    (windowWidth / PAAS_PREVIEW_BASE_FRAME_WIDTH) *
    (baseScale ?? PAAS_PREVIEW_BASE_SCALE)
  );
}

export function getThumbnailWidth(scale: number): number {
  return PAAS_PREVIEW_BASE_FRAME_WIDTH * scale;
}

export async function setFpdAsHomePage(editorAPI: EditorAPI, pageId: string) {
  if (!pageId) {
    ErrorReporter.captureException(
      new Error('no pageId given to setFpdAsHomePage'),
      {
        tags: {
          siteCreationFlow: true,
          siteCreationSetFpdAsHomepage: true,
        },
        extra: {
          curatedTemplateId: window.siteCreationController.curatedTemplateId,
        },
      },
    );
    return;
  }
  try {
    fedopsLogger.interactionStarted(
      fedopsLogger.INTERACTIONS.SITE_CREATION.SET_FPD_AS_HOMEPAGE,
    );
    const currentHomePageId = editorAPI.homePage.get();
    editorAPI.homePage.set(pageId);
    const focusedPageId = editorAPI.pages.getFocusedPageId();
    if (focusedPageId !== pageId) {
      await editorAPI.pages.navigateToAsync(pageId);
    }
    await editorAPI.waitForChangesAppliedAsync();
    editorAPI.documentServices.pages.remove(currentHomePageId);
    fedopsLogger.interactionEnded(
      fedopsLogger.INTERACTIONS.SITE_CREATION.SET_FPD_AS_HOMEPAGE,
    );
  } catch (e: MaybeError) {
    console.log('setFpdAsHomePage failed', e.message);
    ErrorReporter.captureException(e, {
      tags: {
        siteCreationFlow: true,
        siteCreationSetFpdAsHomepage: true,
      },
      extra: {
        curatedTemplateId: window.siteCreationController.curatedTemplateId,
      },
    });
  }
}

export async function verifyChosenHomepage(
  editorAPI: EditorAPI,
  originalTemplateHomepageId: string,
  chosenHomepageId: string,
): Promise<void> {
  const pageIdList = editorAPI.pages.getPageIdList();
  if (pageIdList.includes(originalTemplateHomepageId)) {
    try {
      if (editorAPI.pages.getFocusedPageId() !== chosenHomepageId) {
        await editorAPI.pages.navigateToAsync(chosenHomepageId);
      }
      if (editorAPI.homePage.get() !== chosenHomepageId) {
        editorAPI.homePage.set(chosenHomepageId);
      }
      await editorAPI.waitForChangesAppliedAsync();
      if (editorAPI.pages.doesPageExist(originalTemplateHomepageId)) {
        editorAPI.documentServices.pages.remove(originalTemplateHomepageId);
      }
    } catch (e: MaybeError) {
      ErrorReporter.captureException(e, {
        tags: {
          siteCreationFlow: true,
        },
        extra: {
          originalTemplateHomepageId,
          chosenHomepageId,
          curatedTemplateId: window.siteCreationController.curatedTemplateId,
          flow: 'verifyChosenHomepage',
        },
      });
      console.log('verifyChosenHomepage failed', e.message);
    }
  }
}

export function getHeaderFooterContentItems(): HeaderFooterContentItemData {
  const {
    businessCardData: { businessName, email, socialLinks },
    businessCardDataDefaults: { DEFAULT_EMAIL, DEFAULT_BUSINESS_NAME },
    logo,
  } = window.siteCreationController;
  const businessNameOrDefault = businessName || DEFAULT_BUSINESS_NAME;
  return {
    title: logo ? undefined : businessNameOrDefault,
    subTitle: '',
    email: email || DEFAULT_EMAIL,
    media: logo ? [logo] : [],
    menu: true,
    login: false,
    copyrightMSG: t('site_creation_site_copyright_message', {
      YEAR: new Date().getFullYear(),
      BUSINESS_NAME: businessNameOrDefault,
    }),
    'social.media': getSocialLinksData(socialLinks),
  };
}

const getSitePropertiesHeaders = (editorAPI: EditorAPI): Headers =>
  new Headers({
    Authorization: editorAPI.dsRead.platform.getAppDataByApplicationId(
      constants.APPLICATIONS.META_SITE_APPLICATION_ID,
    ).instance,
  });

const getSitePropertiesUrl = (params: string[] = []): string => {
  const query = params.map((fieldKey) => `fields.paths=${fieldKey}`).join('&');
  return `${constants.MULTILINGUAL.sitePropertyServiceUrl}${
    query ? `?${query}` : ''
  }`;
};

export const getSiteProperties = async (
  editorAPI: EditorAPI,
  properties: string[] = [],
): Promise<Partial<SiteProperties>> => {
  const url = getSitePropertiesUrl(properties);
  return http.fetchJson(url, {
    headers: getSitePropertiesHeaders(editorAPI),
    method: 'GET',
  });
};

const updateSiteProperties = async (
  editorAPI: EditorAPI,
  properties: Partial<SiteProperties['properties']>,
): Promise<Response> => {
  const url = getSitePropertiesUrl(Object.keys(properties));
  return http.fetch(url, {
    headers: getSitePropertiesHeaders(editorAPI),
    method: 'PATCH',
    body: JSON.stringify({ properties }),
  });
};

const buildSiteAddress = async (
  editorAPI: EditorAPI,
  address: Address,
): Promise<Address> => {
  const emptyAddress = {
    street: '',
    city: '',
    country: '',
    state: '',
    zip: '',
    isPhysical: true,
    googleFormattedAddress: '',
    streetNumber: '',
    apartmentNumber: '',
    coordinates: {
      latitude: 0,
      longitude: 0,
    },
  };
  const siteProperties = await getSiteProperties(editorAPI);
  return { ...emptyAddress, ...siteProperties.properties.address, ...address };
};

export const setSiteProperties = async (editorAPI: EditorAPI) => {
  fedopsLogger.interactionStarted(
    fedopsLogger.INTERACTIONS.SITE_CREATION.SET_SITE_PROPERTIES,
  );
  const { businessCardData, logo } = window.siteCreationController;
  const newProperties = {
    businessName: businessCardData.businessName,
    email: businessCardData.email,
    phone: businessCardData.phoneNumber,
    logo: logo?.uri,
    address: await buildSiteAddress(editorAPI, businessCardData.address),
  };
  await updateSiteProperties(editorAPI, filterEmptyValues(newProperties));
  fedopsLogger.interactionEnded(
    fedopsLogger.INTERACTIONS.SITE_CREATION.SET_SITE_PROPERTIES,
  );
};

// modern Chrome requires { passive: false } when adding event
let supportsPassive = false;
try {
  window.addEventListener(
    'test',
    null,
    Object.defineProperty({}, 'passive', {
      get() {
        supportsPassive = true;
      },
    }),
  );
} catch (e: MaybeError) {
  // eslint-disable-next-line no-console
  console.error('error', e.message);
}

const wheelOpt = supportsPassive
  ? ({ passive: false } as AddEventListenerOptions)
  : false;
const wheelEvent =
  'onwheel' in window.document.createElement('div') ? 'wheel' : 'mousewheel';

const preventDefault = (e: Event): void => e.preventDefault();

export const disablePageScroll = () => {
  window.addEventListener('DOMMouseScroll', preventDefault, false); // older FF
  window.addEventListener(wheelEvent, preventDefault, wheelOpt); // modern desktop
};

export const enablePageScroll = () => {
  window.removeEventListener('DOMMouseScroll', preventDefault, false);
  window.removeEventListener(wheelEvent, preventDefault, wheelOpt);
};

export const disableScrollTransition = (
  elementRef: RefObject<HTMLDivElement>,
  scrollBarRef: RefObject<HTMLDivElement>,
) => {
  const thumbFrame = elementRef.current.firstElementChild as HTMLDivElement;
  if (thumbFrame.style.transition !== 'none') {
    thumbFrame.style.transition = 'none';
    scrollBarRef.current.style.transition = 'none';
  }
};

export const resetScrollTransition = (
  elementRef: RefObject<HTMLDivElement>,
  scrollBarRef: RefObject<HTMLDivElement>,
) => {
  const thumbFrame = elementRef.current.firstElementChild as HTMLDivElement;
  thumbFrame.style.transition = '';
  scrollBarRef.current.style.transition = '';
};

export const setScrollTransitionDuration = (
  elementRef: RefObject<HTMLDivElement>,
  scrollBarRef: RefObject<HTMLDivElement>,
  duration: number,
) => {
  const thumbFrame = elementRef.current.firstElementChild as HTMLDivElement;
  thumbFrame.style.transitionDuration = `${duration}s, ${duration}s`;
  scrollBarRef.current.style.transitionDuration = `${duration}s, ${duration}s`;
};

const isAppOrTpaInstalled = (
  editorAPI: EditorAPI,
  appDefId: string,
): boolean => {
  return (
    editorAPI.platform.isAppActive(appDefId) ||
    editorAPI.tpa.app.isInstalled(appDefId)
  );
};

export const getCurrentTranslatePosition = (element: HTMLDivElement) => {
  const yPositionFromMatrix = window
    .getComputedStyle(element)
    .transform.split(',')
    .pop();

  return Math.abs(window.parseFloat(yPositionFromMatrix));
};

export const getBiScreenResolution = () => {
  return `${window.innerWidth}x${window.innerHeight}`;
};

export const getCountOrCuratedTemplateIds = () => {
  if (window.siteCreationController.curatedTemplateId) {
    return [window.siteCreationController.curatedTemplateId];
  }
  return getAllowedCuratedTemplateIds();
};

export function sanitizeSiteNameFromBusinessName(businessName: string): string {
  return businessName
    .replace(SITE_NAME_INVALID_CHARS_REGEX, '-')
    .slice(0, MAX_SITE_NAME_LENGTH)
    .replace(REGEX_ENDS_HYPHEN, '');
}

export const filterEmptyValues = (obj: any) =>
  Object.fromEntries(Object.entries(obj).filter(([_, value]) => !!value));

/**
 * When header gets its layout from site creation flow, members/stores components can be overlapped with other PaaS components.
 * This function checks if any header components are overlapped with PaaS components, and moves them side by side (in a row) with the dropDownMenu and the social bar.
 * @param editorAPI
 * @param siteNameComp - site name compRef
 */
export async function moveOverlappingHeaderComponentsIfNeeded(
  editorAPI: EditorAPI,
  siteNameComp: CompRef,
): Promise<void> {
  try {
    const headerRef = editorAPI.siteSegments.getHeader();
    let [loginBarRef] =
      editorAPI.components.get.byType_DEPRECATED_BAD_PERFORMANCE(
        constants.COMP_TYPES.LOGIN_SOCIAL_BAR,
        headerRef,
      );
    const [cartIconRef] =
      editorAPI.components.get.byType_DEPRECATED_BAD_PERFORMANCE(
        constants.COMP_TYPES.TPA_WIDGET,
        headerRef,
      );
    if (loginBarRef) {
      const loginBarWidgetRef =
        editorAPI.components.getContainer_DEPRECATED_BAD_PERFORMANCE(
          loginBarRef,
        );
      if (
        editorAPI.components.getType(loginBarWidgetRef) ===
        constants.COMP_TYPES.APP_WIDGET
      ) {
        loginBarRef = loginBarWidgetRef;
      }
    }
    const [searchBarRef] =
      editorAPI.components.get.byType_DEPRECATED_BAD_PERFORMANCE(
        constants.COMP_TYPES.APP_WIDGET,
        headerRef,
      );
    if (searchBarRef && siteNameComp) {
      const { x, width, height } =
        editorAPI.components.layout.get_rect(searchBarRef);
      const searchBarX = x + (width - SEARCH_BAR_WIDTH);
      const searchBarY =
        editorAPI.components.layout.getRelativeToScreen(siteNameComp).y;
      editorAPI.dsActions.components.layout.update(searchBarRef, {
        y: searchBarY,
        x: searchBarX,
        width: SEARCH_BAR_WIDTH,
      });
      if (cartIconRef) {
        const cartWidth =
          editorAPI.components.layout.get_size(cartIconRef).width;
        if (loginBarRef) {
          const searchBottom = searchBarY + height;
          editorAPI.dsActions.components.layout.update(loginBarRef, {
            y: searchBottom,
            x: x + cartWidth,
          });
          editorAPI.dsActions.components.layout.update(cartIconRef, {
            y: searchBottom,
            x,
          });
        } else {
          editorAPI.dsActions.components.layout.update(cartIconRef, {
            y: searchBarY,
            x: searchBarX - cartWidth,
          });
        }
        await editorAPI.waitForChangesAppliedAsync();
      }
    }
    const headerCompsToMove: CompRef[] = [
      loginBarRef,
      cartIconRef,
      searchBarRef,
    ].filter((ref) => !!ref);
    if (!headerCompsToMove.length) return;

    for (const compRef of headerCompsToMove) {
      await editorAPI.waitForChangesAppliedAsync();
      if (editorAPI.dsRead.components.arrangement.canMoveForward(compRef)) {
        editorAPI.components.arrangement.moveToFront(compRef);
        await editorAPI.waitForChangesAppliedAsync();
      }
    }

    const dropDownMenuRef =
      editorAPI.components.get.byType_DEPRECATED_BAD_PERFORMANCE(
        constants.COMP_TYPES.DROPDOWN_MENU,
        headerRef,
      )[0];
    const socialBarRef =
      editorAPI.components.get.byType_DEPRECATED_BAD_PERFORMANCE(
        constants.COMP_TYPES.SOCIAL_LINK_BAR,
        headerRef,
      )[0];

    const otherPossibleOverlappingComponentsLayoutRelative = [
      dropDownMenuRef,
      socialBarRef,
    ]
      .filter((ref) => !!ref)
      .filter(editorAPI.components.is.exist)
      .map(editorAPI.components.layout.getRelativeToScreen);

    if (
      headerCompsToMove.some((compRef) =>
        otherPossibleOverlappingComponentsLayoutRelative.some(
          (otherCompLayoutRelativeToScreen) =>
            layoutUtils.doBoxesOverlap(
              editorAPI.components.layout.getRelativeToScreen(compRef),
              otherCompLayoutRelativeToScreen,
            ),
        ),
      )
    ) {
      const columnRef =
        editorAPI.components.getContainer_DEPRECATED_BAD_PERFORMANCE(
          dropDownMenuRef,
        );
      const columnLayout = editorAPI.components.layout.get_size(columnRef);

      headerCompsToMove.forEach((compRef) => {
        editorAPI.dsActions.components.setContainer(compRef, columnRef);
      });

      const restColumnCompsWidth = [...headerCompsToMove, socialBarRef].reduce(
        (sum, ref) =>
          sum + (ref ? editorAPI.components.layout.get_size(ref).width : 0),
        0,
      );

      const newDropDownMenuWidth =
        columnLayout.width - restColumnCompsWidth - SITE_EXTRA_MARGIN;
      editorAPI.components.layout.update(
        dropDownMenuRef,
        { width: newDropDownMenuWidth },
        true,
      );

      await editorAPI.waitForChangesAppliedAsync();

      [dropDownMenuRef, socialBarRef, ...headerCompsToMove].reduce(
        (xPosition, compRef) => {
          if (!editorAPI.components.is.exist(compRef)) return xPosition;
          const compLayout = editorAPI.components.layout.get_size(compRef);
          editorAPI.components.layout.update(
            compRef,
            {
              x: xPosition,
              y: columnLayout.height / 2 - compLayout.height / 2,
            },
            true,
          );
          return xPosition + compLayout.width;
        },
        0,
      );
    } else if (
      editorAPI.components.is.exist(cartIconRef) &&
      editorAPI.components.is.exist(loginBarRef)
    ) {
      const cartIconLayout = editorAPI.components.layout.get_rect(cartIconRef);
      const loginBarLayout = editorAPI.components.layout.get_rect(loginBarRef);
      if (layoutUtils.doBoxesOverlap(cartIconLayout, loginBarLayout)) {
        editorAPI.components.layout.update(
          loginBarRef,
          { x: cartIconLayout.x - loginBarLayout.width },
          true,
        );
      }
    }
  } catch (e) {
    console.log('moveHeaderComps failed', e);
    ErrorReporter.captureException(e, {
      tags: {
        siteCreationFlow: true,
        siteCreationMoveHeaderComps: true,
      },
      extra: {
        curatedTemplateId: window.siteCreationController.curatedTemplateId,
      },
    });
  }
}

const adjustColumnContainer = async (
  editorAPI: EditorAPI,
  siteNameComp: CompRef,
  columnComp: CompRef,
  extraScaledWidth: number,
): Promise<number> => {
  if (
    !editorAPI.columns.isColumn(columnComp) ||
    !editorAPI.components.is.exist(columnComp)
  ) {
    return 0;
  }

  const columnLayout = editorAPI.components.layout.get_size(columnComp);
  const siteNameLayout = editorAPI.components.layout.get_position(siteNameComp);
  const needToWidenColumn =
    siteNameLayout.x + extraScaledWidth > columnLayout?.width;
  if (!columnLayout || !siteNameLayout || !needToWidenColumn) return 0;

  const width = siteNameLayout.x + extraScaledWidth;
  editorAPI.dsActions.components.layout.update(columnComp, { width });
  await editorAPI.waitForChangesAppliedAsync();
  return editorAPI.components.layout.get_size(columnComp).width; // may be different from width due to constraints
};

const adjustSiteName = async (
  editorAPI: EditorAPI,
  columnComp: CompRef,
  siteNameNewWidth: number,
  siteNameComp: CompRef,
) => {
  const siteNameLayoutToUpdate: Partial<CompLayout> = {
    width: siteNameNewWidth,
  };
  const isTextCentered = getIsTextCentered(editorAPI, siteNameComp);
  const columnLayout = editorAPI.components.layout.get_size(columnComp);
  if (!columnLayout) return;
  if (isTextCentered) {
    siteNameLayoutToUpdate.x = 0;
    siteNameLayoutToUpdate.width = siteNameNewWidth || columnLayout.width;
  }
  editorAPI.dsActions.components.layout.update(
    siteNameComp,
    siteNameLayoutToUpdate,
  );
  await editorAPI.waitForChangesAppliedAsync();
};

export const adjustHeaderBusinessName = async (
  editorAPI: EditorAPI,
  siteNameComp: CompRef,
): Promise<void> => {
  const SCALE_FACTOR = 1.1;
  if (!siteNameComp || !editorAPI.components.is.exist(siteNameComp)) return;
  const headerRef = editorAPI.siteSegments.getHeader();

  const siteNameLayout = editorAPI.components.layout.get_size(siteNameComp);
  if (!siteNameLayout) return;
  const columnComp =
    editorAPI.components.getContainer_DEPRECATED_BAD_PERFORMANCE(siteNameComp);
  const scaledWidth = siteNameLayout.width * SCALE_FACTOR;
  const adjustedWidth = await adjustColumnContainer(
    editorAPI,
    siteNameComp,
    columnComp,
    siteNameLayout.width * SCALE_FACTOR,
  );
  await centerLogoIfNeeded(editorAPI, siteNameComp, headerRef, columnComp);
  await adjustSiteName(
    editorAPI,
    columnComp,
    adjustedWidth || scaledWidth,
    siteNameComp,
  );
};

export const adjustHeaderHeight = async (
  editorAPI: EditorAPI,
): Promise<void> => {
  try {
    const headerRef = editorAPI.siteSegments.getHeader();
    const descendantsBottoms = editorAPI.components
      .getChildren_DEPRECATED_BAD_PERFORMANCE(headerRef, true)
      .map((ref: CompRef) => {
        const { y, height } =
          editorAPI.components.layout.getRelativeToScreen(ref);
        return y + height;
      });
    const lowestDecedentBottom = Math.max(...descendantsBottoms);
    const { height: headerHeight } =
      editorAPI.components.layout.get_size(headerRef);
    if (lowestDecedentBottom > headerHeight) {
      editorAPI.components.layout.update(
        headerRef,
        { height: lowestDecedentBottom },
        true,
      );
    }
  } catch (e) {
    console.error(e);
    ErrorReporter.captureException(e, {
      tags: {
        siteCreationFlow: true,
        siteCreationAdjustHeaderHeight: true,
      },
    });
  }
  await editorAPI.waitForChangesAppliedAsync();
};

export const removeHeaderFreeze = (editorAPI: EditorAPI) => {
  const headerRef = editorAPI.siteSegments.getHeader();
  const fixedPosition =
    editorAPI.components.layout.get_fixedPosition(headerRef);
  if (fixedPosition) {
    editorAPI.dsActions.components.layout.update(headerRef, {
      fixedPosition: false,
    });
  }
};

const getIsTextCentered = (
  editorAPI: EditorAPI,
  siteNameComponent: CompRef,
) => {
  const text = editorAPI.components.data.get(siteNameComponent).text;
  const alignCenterRegex = /text-align:\s?center/;
  return alignCenterRegex.test(text);
};

const centerLogoIfNeeded = async (
  editorAPI: EditorAPI,
  siteNameComp: CompRef,
  headerRef: CompRef,
  columnComponent: CompRef,
) => {
  const isTextCentered = getIsTextCentered(editorAPI, siteNameComp);
  const columnWidth =
    editorAPI.components.layout.get_size(columnComponent)?.width;
  if (!isTextCentered || !columnWidth) return;

  const logoComp = editorAPI.components
    .getChildren_DEPRECATED_BAD_PERFORMANCE(headerRef, true)
    .find(
      (childRef) =>
        editorAPI.components.getType(childRef) === constants.COMP_TYPES.PHOTO,
    );
  if (!editorAPI.components.is.exist(logoComp)) return;

  const logoLayout = editorAPI.components.layout.get_size(logoComp);
  if (!logoLayout) return;
  const newLogoPosition = (columnWidth - logoLayout.width) / 2;
  editorAPI.components.layout.update(logoComp, { x: newLogoPosition }, true);
};

export const splitToColumns = (pages: AdditionalPage[]) => {
  const emptyColumns = Array(ADDITIONAL_PAGES_COLUMN_NUM)
    .fill(null)
    .map(() => []);

  return pages
    .filter((page) => !!page)
    .reduce((columns, page, i) => {
      columns[i % ADDITIONAL_PAGES_COLUMN_NUM].push(page);
      return columns;
    }, emptyColumns);
};

export const getThumbnailsHeight = (
  windowHeight: number,
  headingRef: RefObject<HTMLDivElement>,
  footerRef: RefObject<HTMLDivElement>,
): number =>
  windowHeight -
  (headingRef?.current?.clientHeight || MIN_HEADER_HEIGHT) -
  (footerRef?.current?.clientHeight || MIN_FOOTER_HEIGHT);

export const adjustMobileBusinessNameIfNeeded = async (
  editorAPI: EditorAPI,
  editorPaasApi: EditorPaasApi,
): Promise<void> => {
  if (wixStore.wixStoreDataProvider.isAppInstalled(editorAPI)) {
    editorPaasApi.adjustMobileHeaderBusinessName();
  }
};

export async function fixSospPositionIfNeeded(
  editorAPI: EditorAPI,
): Promise<void> {
  const pageId = editorAPI.pages.getFocusedPageId();
  const sospRef = editorAPI.components.get.byId('SOSP_CONTAINER_CUSTOM_ID');
  if (!editorAPI.components.is.exist(sospRef)) return;
  try {
    const group = editorAPI.pagesGroup.getByGroupName(
      'members_pages_group',
    ) as Pointer;
    editorAPI.pagesGroup.addPageToPagesGroup(group, pageId);
    const { height } = editorAPI.components.layout.get_size(
      editorAPI.siteSegments.getHeader(),
    );
    await editorAPI.waitForChangesAppliedAsync();
    editorAPI.components.layout.update(sospRef, { y: height });
    editorAPI.pagesGroup.removePageFromPagesGroup(group, pageId);
  } catch (e) {
    console.warn('ERROR cannot update sosp layout');
    console.error(e);
  }
  await editorAPI.waitForChangesAppliedAsync();
}

export const removeUnsupportedSections = (page: PageAlternative) => {
  const UNSUPPORTED_COMPONENTS: PreviewFlag[] = ['slideShowPreset'];
  UNSUPPORTED_COMPONENTS.forEach((compFlag) => {
    page.presetPreviews = page.presetPreviews.filter(
      (presetPreview) => !presetPreview.flags?.includes(compFlag),
    );
  });
};

const DEFAULT_SITE_SEGMENTS_STYLE = {
  'alpha-bg': '1',
  'alpha-bgctr': '1',
  'alpha-brd': '0',
  bg: EDITOR_CONTAINERS_BACKGROUND_THEME_COLOR,
  bgctr: EDITOR_CONTAINERS_BACKGROUND_THEME_COLOR,
  'boxShadowToggleOn-shd': 'false',
  brd: EDITOR_CONTAINERS_BACKGROUND_THEME_COLOR,
  brwb: '0',
  brwt: '0',
  rd: '0',
  shd: '0 1 4 0 rgba(0,0,0,0.6)',
};

const getAllowedCuratedTemplateIds = () => {
  const firstCuratedTemplateIdIndex = Math.floor(
    Math.random() * ALLOWED_CURATED_TEMPLATED_IDS.length,
  );
  return [
    ALLOWED_CURATED_TEMPLATED_IDS[firstCuratedTemplateIdIndex],
    ALLOWED_CURATED_TEMPLATED_IDS[
      (firstCuratedTemplateIdIndex + 1) % ALLOWED_CURATED_TEMPLATED_IDS.length
    ],
    ALLOWED_CURATED_TEMPLATED_IDS[
      (firstCuratedTemplateIdIndex + 2) % ALLOWED_CURATED_TEMPLATED_IDS.length
    ],
  ];
};

export function checkAsyncInstallAppsPromiseStatus(
  editorAPI: EditorAPI,
  appsNamesToInstall: string,
) {
  const INSTALL_APPS_DELAY = 500;
  setTimeout(() => {
    if (!window.siteCreationController.installAppsPromiseFulfilled) {
      editorAPI.bi.event(events.siteCreation.ERROR_LOADING_CONTENT, {
        flow: 'installAppsCheck',

        appsToInstall: appsNamesToInstall,
      });
    }
  }, INSTALL_APPS_DELAY);
}

export const verifyFooterPosition = (
  editorAPI: EditorAPI,
  pagesMobileAdjustmentsConfig: PageMobileAdjustmentsConfig[],
) => {
  try {
    const footerRef = editorAPI.siteSegments.getFooter();
    if (!pagesMobileAdjustmentsConfig[0]?.pageModel) return;
    const pageRef = pagesMobileAdjustmentsConfig[0].pageModel
      .pageRef as CompRef;
    const footerY =
      editorAPI.components.layout.getRelativeToScreen(footerRef).y;
    const { height: pageHeight, y: pageY } =
      editorAPI.components.layout.getRelativeToScreen(pageRef);
    const footerDistanceFromBottomPage = Math.floor(
      pageHeight + pageY - footerY,
    );
    if (footerDistanceFromBottomPage > 30) {
      const { industryId, structureId, curatedTemplateId } =
        window.siteCreationController;
      editorAPI.bi.event(events.siteCreation.ERROR_LOADING_CONTENT, {
        flow: 'verifyFooterPosition',
        industryId,
        structureId,
        curatedTemplateId,
      });
      ErrorReporter.captureException(new Error('Wrong footer position'), {
        tags: {
          siteCreationFlow: true,
          siteCreationWrongFooterPosition: true,
        },
        extra: {
          curatedTemplateId,
          footerDistanceFromBottomPage,
        },
      });
    }
  } catch (e) {
    console.error('verifyFooterPosition failed', e);
    ErrorReporter.captureException(e, {
      tags: {
        siteCreationFlow: true,
        siteCreationVerifyFooterPosition: true,
      },
      extra: {
        curatedTemplateId: window.siteCreationController.curatedTemplateId,
      },
    });
  }
};

export async function wirePagesBackgrounds(
  editorAPI: EditorAPI,
  pagesMobileAdjustmentsConfig: PageMobileAdjustmentsConfig[],
): Promise<void> {
  for (const pageConfig of pagesMobileAdjustmentsConfig) {
    const pageName = pageConfig.pageName;
    const pageId = pageConfig.pageModel.pageRef.id;
    try {
      ['desktop', 'mobile'].forEach((deviceType) => {
        const pageBackground = editorAPI.pages.background.get(
          pageId,
          deviceType,
        );
        if (
          pageBackground?.ref?.color &&
          !pageBackground.ref.color.includes('color_')
        ) {
          pageBackground.ref.color = `{${EDITOR_CONTAINERS_BACKGROUND_THEME_COLOR}}`;
          editorAPI.dsActions.pages.background.update(
            pageId,
            pageBackground,
            deviceType,
          );
        }
      });
      await editorAPI.waitForChangesAppliedAsync();
    } catch (e) {
      console.error('wirePageBackgrounds failed , pageId:', pageId);
      ErrorReporter.captureException(e, {
        tags: {
          siteCreationFlow: true,
          siteCreationWirePageBackground: true,
        },
        extra: {
          pageId,
          pageName,
          curatedTemplateId: window.siteCreationController.curatedTemplateId,
        },
      });
    }
  }
}

export async function fixEmptyHeaderFooter(
  editorAPI: EditorAPI,
): Promise<void> {
  [
    editorAPI.siteSegments.getHeader(),
    editorAPI.siteSegments.getFooter(),
  ].forEach((siteSegmentRef) => {
    const style = editorAPI.components.style.get(siteSegmentRef);
    if (
      style?.style?.properties?.bg !==
        EDITOR_CONTAINERS_BACKGROUND_THEME_COLOR ||
      style?.skin ===
        'wysiwyg.viewer.skins.screenwidthcontainer.TransparentScreen'
    ) {
      style.style.properties = DEFAULT_SITE_SEGMENTS_STYLE;
      style.skin = 'wysiwyg.viewer.skins.screenwidthcontainer.DefaultScreen';
      editorAPI.dsActions.components.style.update(siteSegmentRef, style);
    }
  });
  await editorAPI.waitForChangesAppliedAsync();
}

export function inverseFormsComponentsPalette(
  editorAPI: EditorAPI,
  pageRef: CompRef,
): void {
  editorAPI.components.get
    .byType_DEPRECATED_BAD_PERFORMANCE(
      constants.COMP_TYPES.FORM_CONTAINER,
      pageRef,
    )
    .forEach((formContainer) => {
      editorAPI.components
        .getChildren_DEPRECATED_BAD_PERFORMANCE(formContainer)
        .forEach((formChild) => {
          switch (editorAPI.components.getType(formChild)) {
            case constants.COMP_TYPES.TEXT:
              const textData = editorAPI.components.data.get(formChild);
              const inversedColorsTextData = objectUtils.invertObjectByEntries(
                textData,
                paasColorsToInverseEntries,
              );
              editorAPI.dsActions.components.data.update(
                formChild,
                inversedColorsTextData,
              );
              return;
            default:
              const compStyle = editorAPI.components.style.get(formChild);
              if (compStyle && typeof compStyle === 'object') {
                const inversedColorsCompStyle =
                  objectUtils.invertObjectByEntries(
                    compStyle,
                    paasColorsToInverseEntries,
                  );
                editorAPI.dsActions.components.style.update(
                  formChild,
                  inversedColorsCompStyle,
                );
              }
              return;
          }
        });
    });
}

export const resetColorationCache = async () => {
  // Hacky solution for resetting coloration cache after site creation
  const { themeChooser } = await adapter();
  themeChooser.getActiveTheme('color').kitFonts.confPattern = [
    { display: 'L1', id: 0 },
  ];
};

export const syncAndResetMobileLayout = async (editorAPI: EditorAPI) => {
  if (isDev) console.log('syncMobileSite');
  await editorAPI.waitForChangesAppliedAsync();
  editorAPI.mobileConversion.syncMobileSite();
  await editorAPI.waitForChangesAppliedAsync();
  if (isDev) console.log('resetMobileLayoutOnAllPages');
  editorAPI.mobileConversion.resetMobileLayoutOnAllPages({
    heuristicStrategy: 'default',
  });
  await editorAPI.waitForChangesAppliedAsync();
};

export const adjustMobileOnAllPages = async (
  editorPaasApi: EditorPaasApi,
  pagesMobileAdjustmentsConfig: PageMobileAdjustmentsConfig[],
) => {
  interactionStarted(INTERACTIONS.EDITOR_PAAS_API.PAGE_MOBILE_ADJUSTMENTS);
  for (const adjustmentConfig of pagesMobileAdjustmentsConfig) {
    await editorPaasApi.runPaasPageMobileAdjustments(adjustmentConfig);
  }
  interactionEnded(INTERACTIONS.EDITOR_PAAS_API.PAGE_MOBILE_ADJUSTMENTS);
};

const installAppWidget = async (
  editorAPI: EditorAPI,
  appDefId: string,
  options: WidgetOptions | undefined = {},
  widgetName: string,
): Promise<boolean> => {
  let success = true;
  try {
    const widgetsInstallStart = performance.now();
    editorAPI.bi.event(events.siteCreation.SITE_CREATION_WIDGET_INSTALL_START, {
      widgetName,
    });
    const platformOrigin = {
      type: EditorType.Classic,
      initiator: InstallInitiator.Editor,
      info: {
        type: InstallationOriginType.SITE_CREATION,
      },
    };
    tpaUtils.bi.reportBIOnAppIntent(
      editorAPI,
      tpaBi.events.APP_INTENT,
      appDefId,
      tpaConstants.BI.initiator.EDITOR,
      SITE_CREATION_PROVISION_ORIGIN,
    );
    const platformAPI = editorAPI.host.getAPI(EditorPlatformHostIntegrationAPI);
    await new Promise((resolve, reject) => {
      platformAPI.applications.install(
        [appDefId],
        {
          appsOptions: {
            [appDefId]: {
              ...options,
              isSilent: true,
              origin: platformOrigin,
              headlessInstallation: true,
              biData: {
                origin: SITE_CREATION_PROVISION_ORIGIN,
              },
            },
          },
        },
        {
          singleAppCallback: resolve,
          finishAllCallback: resolve,
          onError: reject,
        },
      );
    });
    const widgetsInstallEnd = performance.now();
    const duration = Math.round(widgetsInstallEnd - widgetsInstallStart);
    editorAPI.bi.event(events.siteCreation.SITE_CREATION_WIDGET_INSTALL_END, {
      widgetName,
      duration,
    });
    if (isDev) console.log(`provisioning ${appDefId} took: `, duration);
  } catch (e) {
    success = false;
    if (isDev) console.log('failed to provision app: ', appDefId);
    ErrorReporter.captureException(e, {
      tags: {
        siteCreationFlow: true,
        siteCreationInstallWidgetApp: true,
      },
      extra: {
        appDefId,
        curatedTemplateId: window.siteCreationController.curatedTemplateId,
        flow: 'provisionWidgetApp',
      },
    });
  }
  return success;
};

function getMainMenu(editorAPI: EditorAPI): MenuData {
  return (
    editorAPI.menu.getByType('MAIN_MENU')[0] ||
    editorAPI.menu.getById('CUSTOM_MAIN_MENU')
  );
}

export const arrangeMenu = (
  editorAPI: EditorAPI,
  pagesMobileAdjConfig: PageMobileAdjustmentsConfig[],
) => {
  try {
    const pagesIds: Set<string> = new Set<string>(
      pagesMobileAdjConfig.map((item) => item.pageModel.pageRef.id),
    );
    const mainPageId = pagesMobileAdjConfig[0]
      ? pagesMobileAdjConfig[0].pageModel.pageRef.id
      : editorAPI.pages.getFocusedPageId();
    const [dropDownMenuRef] =
      editorAPI.components.get.byType_DEPRECATED_BAD_PERFORMANCE(
        constants.COMP_TYPES.DROPDOWN_MENU,
        editorAPI.siteSegments.getHeader(),
      );
    const mainMenu = getMainMenu(editorAPI);
    const homeIndex = mainMenu.items.findIndex((item: MenuItem) =>
      (item.link as PageLink)?.pageId?.includes(mainPageId),
    );
    if (homeIndex > 0) {
      const menuHomeItem = mainMenu.items[homeIndex];
      mainMenu.items.splice(homeIndex, 1);
      mainMenu.items.unshift(menuHomeItem);
    }
    mainMenu.items = mainMenu.items.filter((menuItem) => {
      // @ts-expect-error
      const linkPageId = menuItem?.link?.pageId;
      if (linkPageId) {
        return !linkPageId.includes(
          window.siteCreationController.originalTemplateHomepageId,
        );
      }
      return true;
    });
    const isPage = (linkId: string) => pagesIds.has(cleanId(linkId));
    mainMenu.items.sort(
      (item1, item2) =>
        +isPage((item2?.link as PageLink)?.pageId) -
        +isPage((item1?.link as PageLink)?.pageId),
    );
    editorAPI.dsActions.menu.update(mainMenu.id, mainMenu);
    editorAPI.dsActions.menu.connect(dropDownMenuRef, mainMenu.id);
  } catch (e) {
    ErrorReporter.captureException(e, {
      tags: {
        siteCreationFlow: true,
        siteCreationMoveMenuToHead: true,
      },
      extra: {
        curatedTemplateId: window.siteCreationController.curatedTemplateId,
      },
    });
  }
};

export const addSectionsToMenuIfNeeded = (editorAPI: EditorAPI) => {
  try {
    if (!sections.isSectionsEnabled()) return;
    const pagesList = editorAPI.pages.getPageIdList();
    if (pagesList.length !== 1) return;
    const mainMenu = getMainMenu(editorAPI);
    const menuId = cleanId(mainMenu.id);
    const sectionAnchorData = link
      .getSectionAnchors(editorAPI)
      .map((sectionAnchor) => ({
        ...sectionAnchor,
        type: 'AnchorLink',
      }))
      .sort((a, b) => a.y - b.y);
    sectionAnchorData.forEach((sectionLink) => {
      editorAPI.menu.addItem(menuId, {
        link: sectionLink,
        label: sectionLink.anchorName,
        isVisible: true,
        isVisibleMobile: true,
      });
    });
  } catch (e) {
    ErrorReporter.captureException(e, {
      tags: {
        siteCreationFlow: true,
        siteCreationAddSectionsToMenu: true,
      },
      extra: {
        curatedTemplateId: window.siteCreationController.curatedTemplateId,
      },
    });
  }
};

const cleanId = (id: string) => id.replace('#', '');

const getCeTypeToSectionIndex = (
  editorAPI: EditorAPI,
  pageRef: CompRef,
): CeTypeToSection => {
  const allSections =
    editorAPI.sections.getPageSectionsSortedByStageOrder(pageRef);
  return allSections.reduce((acc, section, index) => {
    const ceType = editorAPI.components.features.get(
      section,
      'contentRole',
    )?.contentRole;
    if (!ceType) return acc;
    acc[ceType] = index;
    return acc;
  }, {} as any);
};

const getSectionIndex = (
  editorAPI: EditorAPI,
  positionCfg: PositionCfg,
  pageRef: CompRef,
  ceTypeToSectionIndex: CeTypeToSection,
) => {
  const sectionIndex = ceTypeToSectionIndex[positionCfg?.positionCeType];
  if (sectionIndex && _.isNumber(sectionIndex)) {
    if (positionCfg.position === POSITION.LAST) {
      return editorAPI.sections.getPageSectionsSortedByStageOrder(pageRef)
        .length;
    }
    if (positionCfg.position === POSITION.AFTER) {
      return sectionIndex + 1;
    }
    return sectionIndex;
  }
  return 1;
};

const getCompModel = (
  editorAPI: EditorAPI,
  mobileLayout: CompLayout,
  compDesktopRef: CompRef,
): PaaSRenderedModelNode => {
  return {
    compRef: {
      id: compDesktopRef.id,
      type: 'MOBILE',
    },
    compDef: {
      componentType: editorAPI.components.getType(compDesktopRef),
      layout: {
        x: mobileLayout.x,
        y: mobileLayout.y,
        height: mobileLayout.height,
        width: mobileLayout.width,
        scale: mobileLayout.scale,
      },
    },
    children: [],
  };
};

const appendWidgetChildrenModel = (
  editorAPI: EditorAPI,
  compDesktopRef: CompRef,
  compStructure: CompStructure,
  compModel: PaaSRenderedModelNode,
): void => {
  if (!compStructure.components?.length) return;
  const compDesktopChildren =
    editorAPI.components.getChildren_DEPRECATED_BAD_PERFORMANCE(compDesktopRef);
  (compStructure.components as CompStructure[]).forEach(
    (childStructure, index) => {
      const childDesktopRef = compDesktopChildren[index];
      const childModel = getCompModel(
        editorAPI,
        childStructure.mobileStructure.layout,
        childDesktopRef,
      );
      compModel.children.push(childModel);
      appendWidgetChildrenModel(
        editorAPI,
        childDesktopRef,
        childStructure,
        childModel,
      );
    },
  );
};

const addWidgetToMobileModel = (
  editorAPI: EditorAPI,
  pageModel: PaaSPageRenderedModel,
  presetRef: CompRef,
  presetStructure: CompStructure,
  sectionIndex: number,
) => {
  const stripsMobileModels = pageModel.body.mobile.children;
  let widgetRef: CompRef;
  if (sections.isSectionsEnabled()) {
    widgetRef =
      editorAPI.components.getChildren_DEPRECATED_BAD_PERFORMANCE(presetRef)[0];
  } else {
    widgetRef = presetRef;
  }
  if (!widgetRef) return;
  const widgetMobileModel = getCompModel(
    editorAPI,
    presetStructure.mobileStructure.layout,
    widgetRef,
  );
  if (presetStructure.components?.length) {
    appendWidgetChildrenModel(
      editorAPI,
      widgetRef,
      presetStructure.components[0] as CompStructure,
      widgetMobileModel,
    );
  }
  stripsMobileModels.splice(sectionIndex, 0, widgetMobileModel);
};

const getIsWidgetBlocked = (appDefId: string, installedAppDefIds: string[]) => {
  const { structureId } = window.siteCreationController;
  const blockingApps = getWidgetPositionCfg(appDefId)?.blockingApps;
  const blockingStructureIds =
    getWidgetPositionCfg(appDefId)?.blockingStructureIds;
  const blockedByApp = blockingApps?.some((appId: string) =>
    installedAppDefIds.includes(appId),
  );
  const blockedByStructureId = blockingStructureIds?.includes(structureId);
  return blockedByApp || blockedByStructureId;
};

const addWidgetToPage = async (
  editorAPI: EditorAPI,
  pageRef: CompRef,
  appDefId: string,
  presetSectionStructure: CompStructure,
  sectionIndex: number,
) => {
  const pageSections =
    editorAPI.sections.getPageSectionsSortedByStageOrder(pageRef);
  const addPresetAPI = editorAPI.host.getAPI(AddPresetApiKey);
  if (!editorAPI.dsRead.platform.getAppDataByAppDefId(appDefId)) return;
  addPresetAPI.fixPresetStructureApplicationIds(presetSectionStructure, [
    appDefId,
  ]);
  const stageEntryIndex = Math.min(pageSections.length, sectionIndex);
  presetSectionStructure.layout.y = sections.getTopPositionByStageEntryIndex(
    editorAPI,
    stageEntryIndex,
    pageRef,
  );
  let yPositionToAddWidgetSection;
  if (sectionIndex === pageSections.length) {
    const nextSectionLayout = editorAPI.components.layout.get_rect(
      pageSections[pageSections.length - 1],
    );
    yPositionToAddWidgetSection =
      nextSectionLayout?.y + nextSectionLayout?.height;
  } else {
    yPositionToAddWidgetSection = editorAPI.components.layout.get_position(
      pageSections[sectionIndex],
    )?.y;
  }
  if (!yPositionToAddWidgetSection) return;
  const componentsToShift: CompRef[] = getComponentsBelowPosition(
    editorAPI,
    yPositionToAddWidgetSection,
  );
  if (isDev) console.log(`widget ${appDefId} in index ${sectionIndex}`);
  let widgetStructureToAdd: CompStructure;
  if (TEMP_APPS_TO_ADD_SEPARATELY_FROM_SECTION.has(appDefId)) {
    widgetStructureToAdd = (
      presetSectionStructure.components as CompStructure[]
    )[0];
    presetSectionStructure.components = [];
  }
  const sectionRef = (await new Promise((resolve) => {
    editorAPI.components.add(
      pageRef,
      presetSectionStructure,
      null,
      resolve,
      null,
      {
        optionalIndex: sectionIndex,
      },
    );
  })) as CompRef;
  if (!sectionRef) return null;
  await editorAPI.waitForChangesAppliedAsync();
  if (widgetStructureToAdd) {
    await superApps.addWidget(
      appDefId,
      widgetStructureToAdd.data.widgetId,
      widgetStructureToAdd.layout,
      {
        containerRef: sectionRef,
        styleId: widgetStructureToAdd.style,
        biOrigin: SITE_CREATION_PROVISION_ORIGIN,
        platformOrigin: {
          type: EditorType.Classic,
          initiator: InstallInitiator.Editor,
          info: {
            type: InstallationOriginType.SITE_CREATION,
          },
        },
      },
    );
  }
  await editorAPI.waitForChangesAppliedAsync();

  // NOTE: mesh layout anchors will shift next sections automatically
  if (!isMeshLayoutEnabled()) {
    const sectionHeight = presetSectionStructure.layout.height;
    shiftComponents(editorAPI, componentsToShift, sectionHeight);
  }
  return sectionRef;
};

const removeSectionIfNeeded = async (
  editorAPI: EditorAPI,
  positionCfg: PositionCfg,
  pageRef: CompRef,
) => {
  const ceTypeToSectionIndex = getCeTypeToSectionIndex(editorAPI, pageRef);
  const removeSectionCeType = positionCfg?.removeSection;
  const sectionIndex = ceTypeToSectionIndex[removeSectionCeType];
  if (!removeSectionCeType || !sectionIndex) return;
  const allSections =
    editorAPI.sections.getPageSectionsSortedByStageOrder(pageRef);
  await editorAPI.components.remove(allSections[sectionIndex]);
  await editorAPI.waitForChangesAppliedAsync();
};

export const attemptSave = async (editorAPI: EditorAPI): Promise<void> => {
  try {
    if (url.isQA()) return;
    await new Promise(editorAPI.saveManager.saveInBackground);
  } catch (e) {
    ErrorReporter.captureException(e, {
      tags: {
        siteCreationAttemptSave: true,
      },
      extra: {
        curatedTemplateId: window.siteCreationController.curatedTemplateId,
      },
    });
  }
};

export const addInstalledAppsWidgets = async (
  editorAPI: EditorAPI,
  pageMobileAdjustmentsConfigs: PageMobileAdjustmentsConfig[],
) => {
  const appsToInstall = window.siteCreationController.silentInstallAppDefIds;
  const widgetAppsToInstall = getWidgetAppDefIds(editorAPI);
  const appDefIdToNameMap = getAppDefIdToNameMap();
  const installedAppDefIds = [...appsToInstall, ...widgetAppsToInstall].map(
    replaceStoresIfNeeded,
  );
  const nonInstalledAppDefIds = new Set([
    ...widgetAppsToInstall,
    WIX_PRO_GALLERY,
  ]);
  try {
    const pageRef = pageMobileAdjustmentsConfigs[0]
      ? (pageMobileAdjustmentsConfigs[0].pageModel.pageRef as CompRef)
      : editorAPI.pages.getFocusedPage();
    if (editorAPI.dsRead.generalInfo.isDraft()) {
      await attemptSave(editorAPI);
    }

    let shouldSaveAfterAddingWidgets = false;
    const sortedAppDefIds = sortWidgetsByPriority(installedAppDefIds);
    const appsOptions = getAppsToInstallOptions();
    for (const appDefId of sortedAppDefIds) {
      const presetSectionStructure = appDefIdToSectionStructureMap[appDefId];
      const isBlocked = getIsWidgetBlocked(appDefId, installedAppDefIds);
      if (!presetSectionStructure || isBlocked) continue;

      const isInstalled = isAppOrTpaInstalled(editorAPI, appDefId);
      if (nonInstalledAppDefIds.has(appDefId) && !isInstalled) {
        shouldSaveAfterAddingWidgets = true;
        const installSuccess = await installAppWidget(
          editorAPI,
          appDefId,
          appsOptions[appDefId],
          appDefIdToNameMap[appDefId],
        );
        if (!installSuccess) continue;
      }

      if (!isAppOrTpaInstalled(editorAPI, appDefId)) continue;

      fedopsLogger.interactionStarted(
        fedopsLogger.INTERACTIONS.SITE_CREATION.ADD_WIDGET_PRESET,
        {
          customParams: {
            appDefId,
          },
        },
      );
      const ceTypeToSectionIndex = getCeTypeToSectionIndex(editorAPI, pageRef);
      const positionCfg = getWidgetPositionCfg(appDefId, ceTypeToSectionIndex);
      const presetIndex = getSectionIndex(
        editorAPI,
        positionCfg,
        pageRef,
        ceTypeToSectionIndex,
      );
      const addedPresetRef = await addWidgetToPage(
        editorAPI,
        pageRef,
        appDefId,
        presetSectionStructure,
        presetIndex,
      );
      if (!addedPresetRef) continue;
      if (pageMobileAdjustmentsConfigs.length > 0) {
        addWidgetToMobileModel(
          editorAPI,
          pageMobileAdjustmentsConfigs[0].pageModel,
          addedPresetRef,
          presetSectionStructure,
          presetIndex,
        );
      }
      await removeSectionIfNeeded(
        editorAPI,
        positionCfg as PositionCfg,
        pageRef,
      );
      fedopsLogger.interactionEnded(
        fedopsLogger.INTERACTIONS.SITE_CREATION.ADD_WIDGET_PRESET,
        {
          paramsOverrides: {
            appDefId,
          },
        },
      );
    }
    await editorAPI.waitForChangesAppliedAsync();
    if (shouldSaveAfterAddingWidgets) {
      await attemptSave(editorAPI);
    }
  } catch (e) {
    if (isDev) console.error('siteCreation add widgets err', e);
    ErrorReporter.captureException(e, {
      tags: {
        siteCreationFlow: true,
        siteCreationAddWidgets: true,
      },
      extra: {
        installedAppDefIds,
        curatedTemplateId: window.siteCreationController.curatedTemplateId,
        flow: 'addInstalledAppsWidgets',
      },
    });
  }
};

const getAppDefId = (appName: AppType): string => {
  return appToAppDefId(appName);
};

const getAppCeType = (appDefId: string): string | undefined =>
  appDefIdToSectionStructureMap[appDefId]?.contentRole?.contentRole;

export const getAppsToInstallOptions =
  (): SilentInstallAppsOptions['appsOptions'] => {
    const { caas, appsNamesToInstall } = window.siteCreationController;
    const appsOptions = {} as SilentInstallAppsOptions['appsOptions'];
    const appNames = [
      ...(appsNamesToInstall.split(',') as AppType[]),
      AppType.ProGallery,
    ];
    const templates = caas.getTpaMetasitesIDs();
    appNames.forEach((appName) => {
      const appDefId = getAppDefId(appName);
      const ceType = getAppCeType(appDefId);
      const sourceTemplateId = templates[ceType] || templates[appName];
      if (sourceTemplateId) {
        appsOptions[appDefId] = { sourceTemplateId };
      }
    });
    return appsOptions;
  };

const getAppsDefIds = (editorAPI: EditorAPI): string[] => {
  if (window.siteCreationController.businessFirstFlow) {
    const allSupportedApps = new Set([
      ...ALLOWED_HEADLESS_INSTALL_APP_DEF_IDS,
      ...ALLOWED_SILENT_INSTALLATION_APPS,
    ]);
    const provisionedApps = editorAPI.dsRead.platform
      .getInstalledApps()
      .concat(editorAPI.dsRead.tpa.getPendingApps())
      .map(({ appDefinitionId }) => appDefinitionId);
    return [...new Set(provisionedApps)].filter((appDefId) =>
      allSupportedApps.has(appDefId),
    );
  }
  return (
    window.siteCreationController.appsNamesToInstall.split(',') as AppType[]
  ).map(getAppDefId);
};

const replaceStoresIfNeeded = (appDefId: string): string => {
  if (appDefId === WIX_NEW_STORES) {
    return WIX_STORES;
  }
  return appDefId;
};

export const getAppsToInstallDefIds = (editorAPI: EditorAPI): string[] => {
  const appDefIds = getAppsDefIds(editorAPI).filter((x) =>
    ALLOWED_SILENT_INSTALLATION_APPS.has(x),
  );
  return orderAppsToInstall(appDefIds);
};

const getWidgetAppDefIds = (editorAPI: EditorAPI): string[] => {
  const widgetsDefIds = getAppsDefIds(editorAPI).filter(
    (appDefId) => !ALLOWED_SILENT_INSTALLATION_APPS.has(appDefId),
  );
  appendProGalleryIfNeeded(widgetsDefIds);
  return widgetsDefIds;
};

export const getAppDefIdToNameMap = (): AppDefIdToNameMap => {
  const { appsNamesToInstall } = window.siteCreationController;
  const appNames = appsNamesToInstall.split(',') as AppType[];
  return appNames.reduce(
    (acc, appName) => {
      const appDefId = getAppDefId(appName);
      acc[appDefId] = appName;
      return acc;
    },
    {
      [WIX_PRO_GALLERY]: 'ProGallery',
    } as AppDefIdToNameMap,
  );
};

export const fetchBusinessNameDomainSuggestion = (
  editorAPI: EditorAPI,
  businessName: string,
): void => {
  if (fakeBrowserUtils.isFakeBrowserEnabled()) {
    editorAPI.store.dispatch(
      fetchDomainSuggestions(
        getSiteBusinessNameForDomainSuggestion(editorAPI, businessName),
      ),
    );
  }
};

const personalizeBookings = async (editorAPI: EditorAPI) => {
  if (
    !isAppOrTpaInstalled(editorAPI, WIX_BOOKINGS) ||
    window.siteCreationController.businessFirstFlow
  ) {
    return;
  }
  const { caas } = window.siteCreationController;
  fedopsLogger.interactionStarted(
    fedopsLogger.INTERACTIONS.SITE_CREATION.PERSONALIZE_BOOKINGS,
  );
  await serviceGenerator.updateContentServices(editorAPI, caas);
  fedopsLogger.interactionEnded(
    fedopsLogger.INTERACTIONS.SITE_CREATION.PERSONALIZE_BOOKINGS,
  );
};

export const personalizeApps = async (editorAPI: EditorAPI) => {
  await personalizeBookings(editorAPI);
};

export const orderAppsToInstall = (appDefIds: string[]): string[] => {
  return [...appDefIds].sort((a, b) => {
    if (!APPS_PRIORITY_MAP[a]) return 1;
    if (!APPS_PRIORITY_MAP[b]) return -1;
    return APPS_PRIORITY_MAP[a] - APPS_PRIORITY_MAP[b];
  });
};

export function getSiteCreationSilentInstallOrigin(): AppInstallOrigin {
  return {
    type: EditorType.Classic,
    initiator: InstallInitiator.Editor,
    info: {
      type: InstallationOriginType.SILENT_INSTALL_SITE_CREATION,
    },
  };
}
