import constants from '@/constants';
import * as coreBi from '@/coreBi';
import {
  manageMenuGfppAutomationId,
  openManageMenu,
} from '../../utils/menuUtils';
import type { GFPPActionOnClick } from '@wix/editor-component-types';
import { isCustomMenusEnabled } from '@/util';
import gfppDataUtils from '../../utils/gfppDataUtils';
import type { CompRef } from 'types/documentServices';
import type { EditorAPI } from '@/editorAPI';

const { ACTIONS } = constants.ROOT_COMPS.GFPP;

function openMenuPanel(editorAPI: AnyFixMe, compRef: AnyFixMe) {
  const menuData = editorAPI.components.data.get(compRef) || {
    menuRef: 'CUSTOM_MAIN_MENU',
  };
  const menuId = menuData.menuRef.id ? menuData.menuRef.id : menuData.menuRef;
  editorAPI.openPagesPanel({
    menuId: menuId.replace('#', ''),
    origin: 'gfpp_vmenu',
  });
  editorAPI.bi.event(coreBi.events.pages.click_on_pages_panel_open, {
    origin: 'gfpp_vmenu',
  });
}

function openQuickNavigation(editorAPI: AnyFixMe) {
  editorAPI.setForceOpenPagesQuickNavigationEventCounter();
  editorAPI.bi.event(coreBi.events.pages.quick_navigation_clicked, {
    origin: 'gfpp_vmenu',
  });
}

const manageMenuOnClick = (
  editorAPI: EditorAPI,
  compRef: CompRef[],
): GFPPActionOnClick => {
  if (
    isCustomMenusEnabled() ||
    editorAPI?.temporaryAdvancedMenuAPI?.isAdvanced()
  ) {
    openManageMenu(editorAPI, { selectedComponents: compRef });
    return;
  }
  openMenuPanel(editorAPI, compRef);
};

const manageMenu = {
  label: 'gfpp_mainaction_Vmenu',
  isApplied: true,
  isSelected: gfppDataUtils.getPanelStateFn('menu.panels.MenuManagePanel'),
  onClick: manageMenuOnClick,
  automationId: manageMenuGfppAutomationId,
};

const manageMenuMobile = {
  label: 'gfpp_mainaction_Vmenu_mobile',
  isApplied: true,
  isSelected: false,
  onClick: manageMenuOnClick,
  automationId: manageMenuGfppAutomationId,
};

export default {
  mainActions: [
    manageMenu,
    {
      label: 'gfpp_secondaryaction_Vmenu',
      isApplied: true,
      isSelected: false, //TODO - check if pages dialog is open
      onClick: openQuickNavigation,
    },
  ],

  mobileMainActions: [
    manageMenuMobile,
    {
      label: 'gfpp_mainaction_Vmenu_mobile2',
      isApplied: true,
      isSelected: false, //TODO - check if pages dialog is open
      onClick: openQuickNavigation,
    },
  ],
  enabledActions: [
    ACTIONS.DESIGN,
    ACTIONS.LAYOUT,
    ACTIONS.ANIMATION,
    ACTIONS.HELP,
  ],
  mobileEnabledActions: [
    ACTIONS.SCALE_UP,
    ACTIONS.SCALE_DOWN,
    ACTIONS.HIDE,
    ACTIONS.HELP,
    ACTIONS.ANIMATION,
  ],
  presetActions: {
    help: {
      helpId: isCustomMenusEnabled()
        ? '4136a3fa-caa3-4f0f-9ace-8d08c4ecd893'
        : '9b4be2e9-6ec4-4e8c-96b9-31ccad6f5960',
    },
  },
};
