import type { CompRef } from 'types/documentServices';

import { BasePublicApi } from '@/apilib';
import constants from '@/constants';
import { array } from '@/util';

import { getComponentAnimations } from './animationPanel/utils';
import type { AnimationPanelOwnProps } from './animationPanel/types';
import { createAnimationsApi } from './api/v1';
import { AnimationsScope } from './scope';
import { createGlobalAnimationsApi } from './api/v3/globalAnimationsApi';

const toggleAnimationPanel = (
  { panelManager }: AnimationsScope,
  props: AnimationPanelOwnProps,
) => {
  panelManager.toggleComponentPanel(
    constants.componentPanels.animation,
    props,
    {
      panelLoader: () =>
        import('./animationPanel').then((m) => m.AnimationPanel),
    },
  );
};

const isGfppSelected = (
  { animationsApiV3 }: AnimationsScope,
  compRefs: CompRef[],
) => {
  // @TODO move utils that use api somewhere in the api
  const allEffects = getComponentAnimations(animationsApiV3, compRefs);
  return allEffects.every(({ entrance }) => entrance.name !== 'None');
};

const hasAnimations = (
  { animationsApiV2 }: AnimationsScope,
  compRefs: CompRef[] | CompRef,
) => {
  return array.asArray(compRefs).some((compRef) =>
    // update to check all types in api v3
    animationsApiV2.findExistingAnimation(compRef, 'entrance'),
  );
};

export class AnimationsApi extends BasePublicApi<AnimationsScope> {
  private animationsApiV1 = createAnimationsApi(this.scope);
  private globalAnimationsApi = createGlobalAnimationsApi(this.scope);

  toggleAnimationPanel = this.bindScope(toggleAnimationPanel);
  isGfppSelected = this.bindScope(isGfppSelected);
  hasAnimations = this.bindScope(hasAnimations);

  cleanAllAnimations = this.animationsApiV1.cleanAllAnimations;
  findExistingAnimation = this.animationsApiV1.findExistingAnimation;
  getEffectData = this.animationsApiV1.getEffectData;
  getMobileOverrideEffectData =
    this.animationsApiV1.getMobileOverrideEffectData;
  setAnimation = this.animationsApiV1.setAnimation;
  removeAnimation = this.animationsApiV1.removeAnimation;

  applyGlobalAnimations = this.globalAnimationsApi.applyGlobalAnimations;
}
