import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Text, TextButton } from '@wix/wix-base-ui';
import { cx, hoc } from '@/util';
import { AUTOMATION_IDS } from './constants';

import { mapStateToProps } from './SiteSegmentEmptyStateMapper';
import { SiteSegmentLayoutPresets } from '../LayoutPresets';
import styles from './SiteSegmentBox.scss';

import type { SiteSegmentEmptyStateStateProps } from './SiteSegmentEmptyStateMapper';
import type { CompRef } from 'types/documentServices';

export interface SiteSegmentEmptyStateOwnProps {
  type: 'header' | 'footer';
  onAddElementsClick: (event: React.MouseEvent) => void;
  siteSegment: CompRef;
}

interface SiteSegmentEmptyStateProps
  extends SiteSegmentEmptyStateOwnProps,
    SiteSegmentEmptyStateStateProps {}

const SiteSegmentEmptyStateComponent: React.FC<SiteSegmentEmptyStateProps> = ({
  onAddElementsClick,
  isLightTextColor,
  isLayoutPresetsVisible,
  isTitleVisible,
  siteSegment,
  style,
  type,
}) => {
  const { t } = useTranslation();

  const renderAddLinks = () => {
    if (isLayoutPresetsVisible) {
      return (
        <Trans i18nKey="section_header_footer_blank_empty_state_text">
          <TextButton
            size="small"
            skin="standard"
            weight="normal"
            shouldTranslate={false}
            dataHook={AUTOMATION_IDS.EMPTY_STATE.ADD_ELEMENT}
            onClick={onAddElementsClick}
          />
        </Trans>
      );
    }

    return (
      <TextButton
        size="small"
        skin="standard"
        weight="normal"
        shouldTranslate={false}
        dataHook={AUTOMATION_IDS.EMPTY_STATE.ADD_ELEMENT}
        onClick={onAddElementsClick}
      >
        {t('section_header_footer_blank_empty_state_text_2')}
      </TextButton>
    );
  };

  return (
    <div
      data-hook={AUTOMATION_IDS.EMPTY_STATE.CONTAINER}
      className={cx(styles.emptyStateContainer, {
        [styles.light]: isLightTextColor,
      })}
      style={style}
    >
      {isTitleVisible && (
        <Text
          size="small"
          skin="secondary"
          weight="normal"
          shouldTranslate={false}
        >
          {t(
            type === 'header'
              ? 'section_header_blank_empty_state_text_1'
              : 'section_footer_blank_empty_state_text_1',
          )}
        </Text>
      )}
      <div
        className={styles.linksContainer}
        onMouseDown={(event) => event.stopPropagation()}
      >
        {renderAddLinks()}
      </div>
      {isLayoutPresetsVisible && (
        <div className={styles.presetsContainer}>
          <SiteSegmentLayoutPresets siteSegment={siteSegment} />
        </div>
      )}
    </div>
  );
};

export const SiteSegmentEmptyState = hoc.connect(
  hoc.STORES.EDITOR_API,
  mapStateToProps,
  null,
)(SiteSegmentEmptyStateComponent);
