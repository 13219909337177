import * as coreBi from '@/coreBi';
import type { EditorAPI } from '@/editorAPI';
import * as stateManagement from '@/stateManagement';
import { array, media } from '@/util';
import _ from 'lodash';
import * as categoryDefinitionValidator from './categoryDefinitionValidator';
import imagesMap from './imagesMap';
import * as sectionDefinitionValidator from './sectionDefinitionValidator';
import sectionTypesMap from './sectionTypesMap';
import {
  ImageSources,
  type PlatformAppCategoryDefinition,
  type PlatformAppItemDefinition,
  type PlatformAppWithCategoryDefinition,
} from './types';
import { shouldShowInAddPanel } from './appFilters';

import type {
  DSRead,
  CompRef,
  PlatformAppDescription,
  AppData,
} from 'types/documentServices';
import type { Point } from '@/rEditor';
import type { DraggableItem } from '@/addPanelInfra';

const platformSelectors = stateManagement.platform.selectors;
const platformActions = stateManagement.platform.actions;
const SYMBOL = 'plusBig';
const TOOLTIP = 'add_section_add_page_blankpage_tooltip';
const CATEGORY_TYPE = 'category';
const SECTION_TYPE = 'section';
const SUB_NAV_MIN_LENGTH = 5;
const DEFAULT_IMAGE_COUNT = 5;

const createImageUrlFromMap = (imageName: string) => {
  return media.getMediaUrl(imagesMap[imageName]);
};

const getDefaultImage = (categoryIndex: number): string =>
  createImageUrlFromMap(`LOGO${categoryIndex % DEFAULT_IMAGE_COUNT}`);

const setDefaultImageForItem = (subCategory: AnyFixMe, index: number) =>
  _.defaultsDeep(
    {
      props: {
        items: subCategory.props.items.map((item: AnyFixMe) =>
          _.defaults({}, item, {
            image: getDefaultImage(index),
          }),
        ),
      },
    },
    subCategory,
  );

const mapDefinitionToSection = (
  editorAPI: EditorAPI,
  app: PlatformAppDescription,
  {
    title,
    desc,
    image,
    imageSource,
    id,
    presetId,
    appExtraData,
    size,
    widgetId,
  }: PlatformAppItemDefinition,
) => ({
  id,
  presetId,
  title,
  desc,
  shouldTranslate: false,
  showSectionHeader: true,
  symbol: SYMBOL,
  tooltip: TOOLTIP,
  image:
    imageSource === ImageSources.IMAGE_LIST
      ? createImageUrlFromMap(image)
      : image,
  size,
  onItemDrop: (position: Point, item: DraggableItem, containerRef: CompRef) => {
    editorAPI.panelManager.closeAllPanels();
    editorAPI.store.dispatch(
      platformActions.notifyAddWidget(app.applicationId, id, {
        ...appExtraData,
        containerRef,
        position,
        size,
      }),
    );
    editorAPI.bi.event(coreBi.events.platform.add_widget_to_page, {
      source_name: 'add_panel',
      widget_id: widgetId || id,
      app_id: app.appDefinitionId,
    });
  },
  onItemClick: () => {
    editorAPI.panelManager.closeAllPanels();
    editorAPI.store.dispatch(
      platformActions.notifyAddWidget(app.applicationId, id, {
        ...appExtraData,
        size,
      }),
    );
    editorAPI.bi.event(coreBi.events.platform.add_widget_to_page, {
      source_name: 'add_panel',
      widget_id: widgetId || id,
      app_id: app.appDefinitionId,
    });
  },
});

const mapDefinitionToItems = (
  editorAPI: EditorAPI,
  app: PlatformAppDescription,
  { items }: PlatformAppCategoryDefinition,
) => {
  return _(items)
    .partition(sectionDefinitionValidator.validate)
    .thru(([validDefinitions, invalidDefinitions]) => {
      reportInvalidDefinitions(invalidDefinitions, SECTION_TYPE);
      return validDefinitions;
    })
    .map(_.partial(mapDefinitionToSection, editorAPI, app))
    .value();
};

const mapDefinitionToSubCategory = (
  editorAPI: EditorAPI,
  subNavigationHide: boolean,
  { app, categoryDefinition }: PlatformAppWithCategoryDefinition,
) => ({
  hide: false,
  id: app.appDefinitionId,
  type: sectionTypesMap[categoryDefinition.type],
  title: categoryDefinition.title,
  subNavigationTitle: categoryDefinition.title,
  presetTitle: categoryDefinition.header,
  subNavigationHide,
  showSectionHeader: true,
  shouldTranslate: false,
  disableInfoIcon: true,
  props: {
    items: mapDefinitionToItems(editorAPI, app, categoryDefinition),
  },
});

const showSubNavigation = (sections: AnyFixMe) =>
  sections.length >= SUB_NAV_MIN_LENGTH;

const addCategoryDefinition = (
  app: PlatformAppDescription,
  dsRead: DSRead,
): PlatformAppWithCategoryDefinition => ({
  app,
  categoryDefinition:
    platformSelectors.getAddPanelCategoryDefinitionFromManifest(
      dsRead,
      app.appDefinitionId,
    ),
});

const addCategoryDefinitionsToApps = (
  apps: PlatformAppDescription[],
  dsRead: DSRead,
): PlatformAppWithCategoryDefinition[] =>
  apps.map((app) => addCategoryDefinition(app, dsRead));

function partitionAppsByValidity(apps: PlatformAppWithCategoryDefinition[]) {
  return array.partition<PlatformAppWithCategoryDefinition>(apps, (app) =>
    categoryDefinitionValidator.validate(app.categoryDefinition),
  );
}

const createSectionsFromValidApps = (
  validDefinitions: PlatformAppWithCategoryDefinition[],
  editorAPI: EditorAPI,
) => {
  const subNavigationHide = !showSubNavigation(validDefinitions);

  const subCategories = validDefinitions.map((item) =>
    mapDefinitionToSubCategory(editorAPI, subNavigationHide, item),
  );

  return array.sortByProp(subCategories, 'title').map(setDefaultImageForItem);
};

function filterPlugins(
  apps: PlatformAppWithCategoryDefinition[],
): PlatformAppWithCategoryDefinition[] {
  const pluginReferencedCompIds = apps
    .flatMap(({ app }) => (app as AppData).components)
    .filter((comp) => comp.type === 'WIDGET_PLUGIN')
    .map((comp) => (comp as AnyFixMe).data.referenceComponentId);

  const isPluginReferencedDefinition = (
    item: PlatformAppItemDefinition,
  ): boolean => pluginReferencedCompIds.includes(item.widgetId);

  return apps.map((app) => ({
    ...app,
    categoryDefinition: {
      ...app.categoryDefinition,
      items: app.categoryDefinition?.items.filter(
        (item) => !isPluginReferencedDefinition(item),
      ),
    },
  }));
}

// "Add" panel V2 currently used in Classic Editor
function createMyWidgetsSectionsAddPanelV2(editorAPI: EditorAPI) {
  const apps = stateManagement.platform.selectors
    .getInstalledPackages(editorAPI.dsRead)
    .filter((app) => shouldShowInAddPanel(app as AppData));

  const appsWithCategoryDefinition = addCategoryDefinitionsToApps(
    apps,
    editorAPI.dsRead,
  );

  const [validApps] = partitionAppsByValidity(
    filterPlugins(appsWithCategoryDefinition),
  );

  return createSectionsFromValidApps(validApps, editorAPI);
}

// "Add" panel V1 is deprecated and isn't used in Classic Editor anymore
function createMyWidgetsSectionsAddPanelV1(editorAPI: EditorAPI) {
  const apps = editorAPI.dsRead.platform.getInstalledEditorApps();

  const appsWithCategoryDefinition = addCategoryDefinitionsToApps(
    apps,
    editorAPI.dsRead,
  );

  const [validDefinitions, invalidDefinitions] = partitionAppsByValidity(
    appsWithCategoryDefinition,
  );

  reportInvalidDefinitions(invalidDefinitions, CATEGORY_TYPE);

  const sections = createSectionsFromValidApps(validDefinitions, editorAPI);

  return [
    {
      sections,
    },
  ];
}

const reportInvalidDefinitions = (
  definitions: AnyFixMe,
  definitionType: AnyFixMe,
) =>
  definitions.forEach((definition: AnyFixMe) =>
    console.error(
      `Invalid ${definitionType} definition: ${JSON.stringify(definition)}`,
    ),
  );

export { createMyWidgetsSectionsAddPanelV1, createMyWidgetsSectionsAddPanelV2 };
