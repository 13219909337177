import React from 'react';
import { Text, TextButton, Divider, Tooltip } from '@wix/wix-base-ui';
import { translate } from '@/i18n';
import { isResponsiveEditor } from '@wix/santa-editor-utils';
import experiment from 'experiment';
import styles from './collectionsListHeader.scss';
import VideoTooltip from '../videoTooltip/videoTooltip';
import { getYoutubeUrl } from '../utils/youtubeVideoUrl';
import type { BiEventDefinition, BiEventFields } from 'types/bi';

interface CollectionsListHeaderProps {
  title: string;
  subtitle: string;
  collectionCount?: number;
  showVideoTooltip: boolean;
  onManage: () => void;
  openVideoPanel: (videoId: string, isEmptyState: boolean) => void;
  sendBi: (event: BiEventDefinition, params: BiEventFields) => void;
}

export const CollectionsListHeader: React.FC<CollectionsListHeaderProps> = ({
  title,
  subtitle,
  collectionCount,
  showVideoTooltip,
  openVideoPanel,
  onManage,
  sendBi,
}) => {
  const titlesInSubTitles =
    isResponsiveEditor() &&
    experiment.isOpen('specs.wixDataClient.6thButtonPanelTitleInSubtitle');
  const subtitleSize = titlesInSubTitles ? 'medium' : 'small';
  const linkSize = titlesInSubTitles ? 'small' : 'tiny';
  return (
    <div className={styles.header} data-hook="collections-list-header">
      <div className={styles.headerText}>
        {title && (
          <div className={styles.title}>
            <Text
              size="small"
              dataHook="collections-list-heading"
              weight="bold"
            >
              {title}
            </Text>
            <Text
              size="small"
              dataHook="collections-list-count"
              weight="bold"
              skin="placeholder"
              shouldTranslate={false}
            >
              {collectionCount}
            </Text>
          </div>
        )}
        <Text
          size={subtitleSize}
          weight="thin"
          dataHook="collections-list-subtitle"
          enableEllipsis={false}
          skin="secondary"
          shouldTranslate={false}
        >
          {translate(subtitle, {
            collectionCount: translate(
              'SiteApp_WixData_DataManager_Tab_Collection_Count',
              {
                count: collectionCount,
              },
            ),
          })}
        </Text>
      </div>
      <div className={styles.headerActions}>
        <Tooltip
          content="CONTENT_MANAGER_6_BUTTON_COLLECTIONS_LIST_HEADER_CTA_TOOLTIP"
          dataHook="collections-list-header-action-tooltip"
        >
          <TextButton
            size={linkSize}
            onClick={onManage}
            dataHook="collections-list-header-action"
          >
            CONTENT_MANAGER_6_BUTTON_COLLECTIONS_LIST_HEADER_CTA
          </TextButton>
        </Tooltip>
        {!isResponsiveEditor() && showVideoTooltip && (
          <>
            <Divider direction="vertical" long />
            <VideoTooltip
              className={styles.videoTooltip}
              data-hook="video-tooltip"
              openVideoPanel={() => {
                openVideoPanel(
                  translate(
                    'CONTENT_MANAGER_6_BUTTON_MANAGE_CONTENT_COLLECTIONS_TOOLTIP_VIDEO_ID',
                  ),
                  false,
                );
              }}
              title="CONTENT_MANAGER_6_BUTTON_MANAGE_CONTENT_COLLECTIONS_TOOLTIP_VIDEO_TITLE"
              subtitle="CONTENT_MANAGER_6_BUTTON_MANAGE_CONTENT_COLLECTIONS_TOOLTIP_VIDEO_SUBTITLE"
              imageUrl={
                'addPanelData/sections/dataBinding/contentManagerVideoThumbnail.png'
              }
              sendBi={sendBi}
              videoUrl={getYoutubeUrl(
                translate(
                  'CONTENT_MANAGER_6_BUTTON_MANAGE_CONTENT_COLLECTIONS_TOOLTIP_VIDEO_ID',
                ),
              )}
              isEmptyState={false}
              tabName="content_manager"
            />
          </>
        )}
      </div>
    </div>
  );
};
