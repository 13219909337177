import _ from 'lodash';
import * as platformEvents from 'platformEvents';
import { arrayUtils } from '@/util';
import {
  interactionsActions,
  interactionsSelectors,
  selectionSelectors,
} from '@/stateManagement';
import * as componentsUtils from './componentsUtils';

import type {
  DSRead,
  CompRef,
  CompStructure,
  CompVariantPointer,
  StyleRefOrStyleRefs,
} from 'types/documentServices';
import experiment from 'experiment';
import type { EditorAPI } from '@/editorAPI';
import { hasResponsiveLayout } from './componentsStylableApi/componentsStylableApi';

export function createComponentsStyleApi({
  editorAPI,
}: {
  editorAPI: EditorAPI;
}) {
  const {
    isInInteractionMode,
    isShownOnlyOnVariant,
    getCompVariantPointer,
    isInteractionModeAvailable,
    getComponentCompVariantPointersIfExists,
  } = interactionsSelectors;

  function shouldCascadeStylesToRef(compRef: CompRef) {
    const isCompHasStyleInMobile = () => {
      const pageMobileVariant = editorAPI.mobile.getMobileVariant();
      if (pageMobileVariant) {
        const compMobileVariantPointer =
          editorAPI.components.variants.getPointer(compRef, [
            pageMobileVariant,
          ]);
        return !!editorAPI.components.style.get(compMobileVariantPointer);
      }
    };
    return (
      editorAPI.components.refComponents.isReferredComponent(compRef) &&
      !editorAPI.isMobileEditor() &&
      isCompHasStyleInMobile()
    );
  }

  function cascadeStyleToMobileIfNeeded(
    compRef: CompRef,
    styleValue: CompStructure['style'],
    updateFn: (compPointer: CompRef, styleVal: CompStructure['style']) => void,
  ) {
    if (shouldCascadeStylesToRef(compRef)) {
      updateStyleInMobile(compRef, styleValue, updateFn);
    }
  }

  const clearStCss = (styleValue: CompStructure['style']) => {
    const VALID_EMPTY_ST_CSS = '.root {}';
    const styleValueCopy: any = _.cloneDeep(styleValue);
    const styleProperties = _.get(styleValue, 'style.properties');
    if (styleProperties && styleProperties['$st-css']) {
      styleValueCopy.style.properties['$st-css'] = VALID_EMPTY_ST_CSS;
    }
    return styleValueCopy;
  };

  const getMobileStyleValue = (
    compRef: CompRef,
    styleValue: CompStructure['style'],
    compMobileVariantPointer: any,
  ) => {
    const STYLESHEET_KEY_PATH = ['style', 'properties', '$st-css'];
    const isStylable = !!_.get(styleValue, STYLESHEET_KEY_PATH);
    const variantStyleItem = editorAPI.components.style.get(
      compMobileVariantPointer,
    );
    const isNewVariant = !variantStyleItem;
    const shouldClearStCss =
      isStylable &&
      experiment.isOpen('se_fixStylableInMobileEditor') &&
      isNewVariant &&
      !hasResponsiveLayout(editorAPI, compRef);

    return shouldClearStCss ? clearStCss(styleValue) : styleValue;
  };

  function updateStyleInMobile(
    compRef: CompRef,
    styleValue: CompStructure['style'],
    updateFn: (compPointer: CompRef, styleVal: CompStructure['style']) => void,
  ) {
    const mobileVariant = editorAPI.mobile.getMobileVariant();
    const compMobileVariantPointer = editorAPI.components.variants.getPointer(
      compRef,
      [mobileVariant],
    );

    const mobileStyleValue = getMobileStyleValue(
      compRef,
      styleValue,
      compMobileVariantPointer,
    );
    updateFn(compMobileVariantPointer, mobileStyleValue);
  }

  function getStyle(compRefs: CompRef[]) {
    const dsRead: DSRead = editorAPI.dsRead;
    const components = arrayUtils.asArray(compRefs);
    if (arrayUtils.isMultiselect(components)) {
      throw new Error("components.style.get isn't supported for multi select");
    }
    const [comp] = components;
    if (
      editorAPI.isMobileEditor() &&
      !editorAPI.mobile.mobileOnlyComponents.isMobileOnlyNonNativeComponent(
        comp,
      )
    ) {
      const pageMobileVariant = editorAPI.mobile.getMobileVariant();
      const compMobileVariantPointer = editorAPI.components.variants.getPointer(
        comp,
        [pageMobileVariant],
      );
      return (
        dsRead.components.style.get(compMobileVariantPointer) ||
        dsRead.components.style.get(comp)
      );
    }
    const currentEditorState = editorAPI.store.getState();
    if (
      isInInteractionMode(currentEditorState) &&
      !isShownOnlyOnVariant(editorAPI, components[0])
    ) {
      const pointerWithVariant = getCompVariantPointer(currentEditorState);
      const compRef =
        selectionSelectors.getSelectedCompsRefs(currentEditorState)[0]; // components[0] can be compVariantRef
      return (
        dsRead.components.style.get(pointerWithVariant) ||
        dsRead.components.style.get(compRef)
      );
    }

    return dsRead.components.style.get(components[0]);
  }

  function isSkinChange(compRef: CompRef, styleValue: CompStructure['style']) {
    const { skin: oldSkin } = editorAPI.components.style.get(compRef);
    const { skin: newSkin } = styleValue as StyleRefOrStyleRefs;

    return oldSkin && newSkin && oldSkin !== newSkin;
  }

  function updateStyle(
    compRefs: CompRef[],
    styleValue: CompStructure['style'],
    dontAddToUndoRedoStack?: boolean,
    dontRemoveScopedStyle?: boolean,
    removeScopedStyle?: boolean,
  ) {
    const components = arrayUtils.asArray(compRefs);
    if (arrayUtils.isMultiselect(components)) {
      throw new Error(
        "components.style.update isn't supported for multi select",
      );
    }

    let res;
    const currentEditorState = editorAPI.store.getState();
    const isInteractionMode = isInInteractionMode(currentEditorState);

    const shouldRemoveScopedStyle =
      removeScopedStyle || isSkinChange(components[0], styleValue);

    if (
      // @ts-expect-error
      isInteractionModeAvailable(currentEditorState) &&
      !dontRemoveScopedStyle &&
      !isInteractionMode &&
      shouldRemoveScopedStyle
    ) {
      const compVariantPointers: CompVariantPointer[] =
        getComponentCompVariantPointersIfExists(editorAPI, components[0]);

      const pageMobileVariant = editorAPI.mobile.getMobileVariant();

      if (!_.isEmpty(pageMobileVariant)) {
        const compMobileVariantPointer =
          editorAPI.components.variants.getPointer(components[0], [
            pageMobileVariant,
          ]);
        compVariantPointers.push(compMobileVariantPointer);
      }

      compVariantPointers.forEach((compWithVariant) => {
        if (editorAPI.components.style.get(compWithVariant)) {
          editorAPI.components.style.scoped.remove(compWithVariant);
        }
      });
    }

    if (isInteractionMode && !isShownOnlyOnVariant(editorAPI, components[0])) {
      const pointerWithVariant = getCompVariantPointer(currentEditorState);
      const { id, ...styleValueWithoutId } = styleValue as StyleRefOrStyleRefs;
      res = editorAPI.dsActions.components.style.update(
        pointerWithVariant,
        // @ts-expect-error
        styleValueWithoutId,
      );
      interactionsActions.setDefaultTransition(editorAPI, components[0]);
    } else if (
      !shouldRemoveScopedStyle &&
      editorAPI.isMobileEditor() &&
      !editorAPI.mobile.mobileOnlyComponents.isMobileOnlyComponent(
        components[0]?.id,
      )
    ) {
      updateStyleInMobile(
        components[0],
        styleValue,
        // @ts-expect-error
        editorAPI.dsActions.components.style.update,
      );
    } else {
      const compRef = components[0];
      // @ts-expect-error
      res = editorAPI.dsActions.components.style.update(compRef, styleValue);
      cascadeStyleToMobileIfNeeded(
        compRef,
        styleValue,
        // @ts-expect-error
        editorAPI.dsActions.components.style.update,
      );
    }

    editorAPI.dsActions.platform.notifyAppsOnCustomEvent(
      platformEvents.factory.componentStyleChanged({ compRef: components[0] }),
    );

    if (!dontAddToUndoRedoStack) {
      editorAPI.history.debouncedAdd('Update component style');
    }
    componentsUtils.sendBiIfRefComponent(
      editorAPI,
      components[0],
      componentsUtils.REF_COMPONENT_CHANGE_TYPE.STYLE,
    );
    return res;
  }

  function forkStyle(compRefs: CompRef[], styleValue: AnyFixMe) {
    const components = arrayUtils.asArray(compRefs);
    if (arrayUtils.isMultiselect(components)) {
      throw new Error(
        "components.style.forkStyle isn't supported for multi select",
      );
    }

    // @ts-expect-error
    return editorAPI.dsActions.components.style.fork(components[0], styleValue);
  }

  return {
    get: getStyle,
    update: updateStyle,
    fork: forkStyle,
    updateStyleInMobile,
    cascadeStyleToMobileIfNeeded,
  };
}

export type ComponentsStyleApi = ReturnType<typeof createComponentsStyleApi>;
