import type { MapStateToProps } from 'types/redux';
import type { SiteSegmentEmptyStateOwnProps } from './SiteSegmentEmptyState';
import { backgroundUtils } from '@/util';
import { utils as themeUtils } from '@/theme';
import { FixedStageApiKey, EditorRestrictionsApiKey } from '@/apis';
import type { CSSProperties } from 'react';

const PERCEIVED_LIGHTNESS_THRESHOLD = 0.5;
const isLightColor = (color: string) =>
  themeUtils.getLumaPerceivedLightness(color) > PERCEIVED_LIGHTNESS_THRESHOLD;

export interface SiteSegmentEmptyStateStateProps {
  isLayoutPresetsVisible: boolean;
  isTitleVisible: boolean;
  isLightTextColor: boolean;
  style: CSSProperties;
}

export const mapStateToProps: MapStateToProps<
  SiteSegmentEmptyStateStateProps,
  SiteSegmentEmptyStateOwnProps
> = ({ editorAPI, dsRead }) => {
  const fixedStage = editorAPI.host.getAPI(FixedStageApiKey);
  const editorRestrictionsApi = editorAPI.host.getAPI(EditorRestrictionsApiKey);
  const backgroundData = dsRead.pages.background.get(
    dsRead.pages.getCurrentPageId(),
    'desktop',
  );

  const pageBackgroundColor = backgroundUtils.getColorValue(
    editorAPI,
    backgroundData.ref.color,
  );

  // TODO: probably add check for header/footer background instead
  const isLightTextColor = !isLightColor(pageBackgroundColor);

  const centeredContentLeftOffset = fixedStage.getCenteredComponentsLeft(
    0.5,
    'none',
  );

  const styleForHorizontallyScrollableStage: CSSProperties = {
    margin: 0,
    position: 'absolute',
  };

  const isLayoutPresetsVisible = editorRestrictionsApi.allowed(
    'sections_site-segment-empty-state-layout-presets.visible',
  );

  const isTitleVisible = !isLayoutPresetsVisible;

  return {
    isLightTextColor,
    isLayoutPresetsVisible,
    isTitleVisible,
    style: {
      ...centeredContentLeftOffset,
      ...(fixedStage.isStageHorizontallyScrollable()
        ? styleForHorizontallyScrollableStage
        : {}),
    },
  };
};
