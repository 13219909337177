import type {
  CompRef,
  CompVariantPointer,
  ComponentEffect,
  Pointer,
} from 'types/documentServices';
import type { EditorAPI } from '@/editorAPI';

import type { EffectData, Reaction } from '../types';

export interface AnimationAPI {
  findExistingAnimation(compRef: CompRef): Pointer;
  getEffectData(compRef: CompRef): EffectData;
  setAnimation(compRef: CompRef, effectObj: EffectData): void;
  removeAnimation(compRef: CompRef): Promise<void>;
  removeMobileOverrides(compRef: CompRef): Promise<void>;
  previewAnimation(compRef: CompRef, effectObj: EffectData): void;
  stopPreviewAnimation(compRef?: CompRef): void;
}

export abstract class BaseAnimationAPI implements AnimationAPI {
  private previewIds = new Map<string, number | null>();

  constructor(protected editorAPI: EditorAPI) {}

  protected abstract getTriggerParams(compRef?: CompRef): {
    trigger: string;
    params?: any;
  };

  protected abstract getTriggerType(compRef?: CompRef): string | string[];

  abstract get reactionParams(): {
    type: string;
    [key: string]: any;
  };

  protected get emptyEffectObj() {
    return {
      type: 'TimeAnimation',
      name: '',
      value: {
        type: 'TimeAnimationOptions',
        namedEffect: null as null,
      },
    };
  }

  /* UTILITIES */

  getCompRefs(compRef: CompRef) {
    const isMobileOnlyComp =
      this.editorAPI.mobile.mobileOnlyComponents.isMobileOnlyComponent(
        compRef.id,
      );
    // For mobile only components desktopCompRef has type: MOBILE
    // and mobileCompPointer is type: MOBILE, variant: MOBILE
    // this makes all other code work without any extra ifs
    const desktopCompRef = {
      id: compRef.id,
      type: isMobileOnlyComp ? 'MOBILE' : 'DESKTOP',
    } as CompVariantPointer;
    const mobileCompPointer = this.editorAPI.components.variants.getPointer(
      desktopCompRef,
      [this.editorAPI.mobile.getMobileVariant()],
    );

    return { desktopCompRef, mobileCompPointer };
  }

  private setEmptyEffect(compRef: CompVariantPointer, effectRef?: Pointer) {
    if (effectRef) {
      this.editorAPI.components.effects.update(
        compRef,
        effectRef,
        this.emptyEffectObj,
      );
      return effectRef;
    }

    return this.editorAPI.components.effects.add(compRef, this.emptyEffectObj);
  }

  protected async createTriggerAndReaction(
    compRef: CompRef,
    effectRef: Pointer,
    triggerParams = this.getTriggerParams(compRef),
  ) {
    // Find trigger with the same type on a component
    // If not found - create a new one
    const triggerRef =
      this.editorAPI.components.triggers
        .getAll(compRef)
        .find(
          (ref) =>
            this.editorAPI.components.triggers.get(compRef, ref).trigger ===
            triggerParams.trigger,
        ) ?? this.editorAPI.components.triggers.add(compRef, triggerParams);

    this.editorAPI.components.reactions.add(compRef, triggerRef, {
      effect: effectRef,
      ...this.reactionParams,
    });

    await this.editorAPI.waitForChangesAppliedAsync();
  }

  findReaction(compRef: CompRef, triggerType = this.getTriggerType(compRef)) {
    const reactions = this.editorAPI.components.reactions.get(compRef);

    return reactions?.find(
      (reaction: Reaction) => reaction.triggerType === triggerType,
    );
  }

  // Is trigger used by a reaction on Mobile/Desktop
  protected isTriggerInUseInAnotherBp(compRef: CompRef, trigger: string) {
    const { mobileCompPointer, desktopCompRef } = this.getCompRefs(compRef);

    const compRefInAnotherBp = this.editorAPI.isMobileEditor()
      ? desktopCompRef
      : mobileCompPointer;
    const anotherBpReactions =
      this.editorAPI.components.reactions.get(compRefInAnotherBp);

    return anotherBpReactions?.some(
      ({ triggerType }: { triggerType: string }) => trigger === triggerType,
    );
  }

  protected findTrigger(
    compRef: CompRef,
    triggerType = this.getTriggerType(compRef),
  ) {
    const allTriggers = this.editorAPI.components.triggers.getAll(compRef);

    return allTriggers.find(
      (ref) =>
        triggerType ===
        this.editorAPI.components.triggers.get(compRef, ref).trigger,
    );
  }

  protected async removeReactionAndTrigger(
    compRef: CompRef,
    triggerType = this.getTriggerType(compRef),
  ) {
    const relevantReaction = this.findReaction(compRef, triggerType);
    const relevantTrigger = this.findTrigger(compRef, triggerType);

    if (this.editorAPI.isMobileEditor()) {
      // Disable for mobile to preserve override
      this.editorAPI.components.reactions.disable(compRef, relevantTrigger);
    } else {
      this.editorAPI.components.reactions.remove(
        compRef,
        relevantTrigger,
        relevantReaction.pointer,
      );

      // Remove trigger only if it's not used by a reaction in another BP
      if (
        relevantTrigger &&
        !this.isTriggerInUseInAnotherBp(compRef, relevantReaction.triggerType)
      ) {
        this.editorAPI.components.triggers.remove(compRef, relevantTrigger);
      }
    }

    await this.editorAPI.waitForChangesAppliedAsync();
  }

  /* DESKTOP */

  private async createDesktopAnimation(
    desktopCompRef: CompVariantPointer,
    mobileCompPointer: CompVariantPointer,
    effectObj: ComponentEffect,
  ) {
    let effectRef: Pointer;

    const mobileReaction = this.getMobileOverride(mobileCompPointer);

    if (mobileReaction) {
      effectRef = mobileReaction.effect;

      this.editorAPI.components.effects.update(
        desktopCompRef,
        mobileReaction.effect,
        effectObj,
      );
    } else {
      effectRef = this.editorAPI.components.effects.add(
        desktopCompRef,
        effectObj,
      );
    }

    await this.createTriggerAndReaction(desktopCompRef, effectRef);
  }

  private removeDesktopAnimation(
    desktopCompRef: CompVariantPointer,
    mobileCompPointer: CompVariantPointer,
    reaction: Reaction,
  ) {
    this.removeReactionAndTrigger(desktopCompRef);

    if (this.getMobileOverride(mobileCompPointer)) {
      this.setEmptyEffect(desktopCompRef, reaction.effect);
    } else {
      this.editorAPI.components.effects.remove(desktopCompRef, reaction.effect);
    }
  }

  /* MOBILE */

  private getMobileOverride(mobileCompPointer: CompVariantPointer) {
    return (
      this.isSplit(mobileCompPointer) && this.findReaction(mobileCompPointer)
    );
  }

  isSplit(mobileCompPointer: CompVariantPointer) {
    return !!this.editorAPI.components.reactions.get(mobileCompPointer);
  }

  private async createMobileAnimation(
    mobileCompPointer: CompVariantPointer,
    desktopCompRef: CompVariantPointer,
    effectObj: EffectData,
  ) {
    const desktopEffect =
      this.findReaction(desktopCompRef)?.effect ??
      this.setEmptyEffect(desktopCompRef);

    this.editorAPI.components.effects.update(
      mobileCompPointer,
      desktopEffect,
      effectObj,
    );

    await this.createTriggerAndReaction(mobileCompPointer, desktopEffect);
  }

  private removeMobileAnimation(
    mobileCompPointer: CompVariantPointer,
    desktopCompRef: CompVariantPointer,
  ) {
    const mobileReaction = this.findReaction(mobileCompPointer);
    const desktopReaction = this.findReaction(desktopCompRef);

    const effectRef = mobileReaction?.effect ?? desktopReaction?.effect;

    this.removeReactionAndTrigger(mobileCompPointer);

    this.setEmptyEffect(mobileCompPointer, effectRef);

    // Complete cleanup if there are no more animations left
    if (!desktopReaction) {
      const relevantTrigger = this.findTrigger(
        desktopCompRef,
        this.getTriggerType(),
      );
      this.editorAPI.components.triggers.remove(
        desktopCompRef,
        relevantTrigger,
      );
      this.editorAPI.components.effects.remove(desktopCompRef, effectRef);
    }
  }

  /* EXTERNAL API */

  findExistingAnimation(compRef: CompRef) {
    const { mobileCompPointer, desktopCompRef } = this.getCompRefs(compRef);

    const foundMobileReaction = this.findReaction(mobileCompPointer);
    const foundDesktopReaction = this.findReaction(desktopCompRef);

    if (!this.editorAPI.isMobileEditor()) {
      return foundDesktopReaction;
    }

    // Return Desktop reaction if we are on mobile and cascading
    if (!this.isSplit(mobileCompPointer)) {
      return foundDesktopReaction;
    }

    if (!foundMobileReaction && foundDesktopReaction) {
      return null;
    }

    return foundMobileReaction;
  }

  getEffectData(compRef: CompRef): EffectData {
    const { mobileCompPointer, desktopCompRef } = this.getCompRefs(compRef);
    const animation = this.findExistingAnimation(compRef);

    if (!animation) {
      return null;
    }

    // If we are on Desktop OR on Mobile and cascading - use desktopCompRef to get effect data
    const compRefWithData =
      this.editorAPI.isMobileEditor() && this.isSplit(mobileCompPointer)
        ? mobileCompPointer
        : desktopCompRef;

    return this.editorAPI.components.effects.get(
      compRefWithData,
      animation.effect,
    );
  }

  async setAnimation(compRef: CompRef, effectObj: EffectData) {
    const { mobileCompPointer, desktopCompRef } = this.getCompRefs(compRef);

    if (this.editorAPI.isMobileEditor()) {
      const existingReaction = this.findReaction(mobileCompPointer);

      if (existingReaction) {
        this.editorAPI.components.effects.update(
          mobileCompPointer,
          existingReaction.effect,
          effectObj,
        );
      } else {
        await this.createMobileAnimation(
          mobileCompPointer,
          desktopCompRef,
          effectObj,
        );
      }
    } else {
      const existingReaction = this.findReaction(desktopCompRef);
      if (existingReaction) {
        this.editorAPI.components.effects.update(
          desktopCompRef,
          existingReaction.effect,
          effectObj,
        );
      } else {
        await this.createDesktopAnimation(
          desktopCompRef,
          mobileCompPointer,
          effectObj,
        );
      }
    }

    await this.editorAPI.waitForChangesAppliedAsync();
  }

  async removeAnimation(compRef: CompRef) {
    const { mobileCompPointer, desktopCompRef } = this.getCompRefs(compRef);

    if (this.editorAPI.isMobileEditor()) {
      this.removeMobileAnimation(mobileCompPointer, desktopCompRef);
    } else {
      const existingAnimation = this.findReaction(desktopCompRef);

      if (!existingAnimation) return;

      this.removeDesktopAnimation(
        desktopCompRef,
        mobileCompPointer,
        existingAnimation,
      );
    }

    await this.editorAPI.waitForChangesAppliedAsync();
  }

  async removeMobileOverrides(compRef: CompRef) {
    const { mobileCompPointer, desktopCompRef } = this.getCompRefs(compRef);

    if (
      this.editorAPI.mobile.mobileOnlyComponents.isMobileOnlyComponent(
        compRef.id,
      )
    ) {
      return;
    }
    const triggerRef = this.findTrigger(desktopCompRef);
    const reaction = this.findReaction(mobileCompPointer);
    const effectRef =
      reaction?.effect ?? this.findReaction(desktopCompRef)?.effect;

    this.editorAPI.components.reactions.removeAll(
      mobileCompPointer,
      triggerRef,
    );

    await this.editorAPI.waitForChangesAppliedAsync();

    if (effectRef) {
      this.editorAPI.components.effects.remove(mobileCompPointer, effectRef);
    }

    await this.editorAPI.waitForChangesAppliedAsync();
  }

  previewAnimation(compRef: CompRef, effectObj: EffectData) {
    const previewId = this.editorAPI.components.behaviors.previewAnimation(
      compRef,
      {
        ...effectObj.value,
        params: effectObj.value.namedEffect,
      },
      () => {
        this.previewIds.delete(compRef.id);
      },
    );

    this.previewIds.set(compRef.id, previewId);
  }

  stopPreviewAnimation(compRef: CompRef) {
    this.editorAPI.components.behaviors.stopPreviewAnimation(
      this.previewIds.get(compRef.id),
      1,
    );
  }
}
