// @ts-nocheck
import _ from 'lodash';
import experiment from 'experiment';

/**
 * Collect theme data from serialized components or pages which is needed to be able to paste with original styles.
 * @param editorAPI
 * @param {Object|Object[]} structure - object or array of components or pages
 *
 * @returns {Object} themeData - serialized theme data
 * @returns {Object} themeData.colors
 * @returns {Object} themeData.fonts
 * @returns {Object} themeData.styles - only styles used in copied components
 */
function collectThemeFromSerializedStructure(editorAPI, structure) {
  // Collection of style ids used in copied components and extract corresponding styles
  const themeStyleIds = [];

  function collectStyleIds(component) {
    if (_.isString(component.style)) {
      themeStyleIds.push(component.style);
    }

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/is-array
    if (_.isArray(component.components)) {
      component.components.forEach(collectStyleIds);
    }
  }

  [].concat(structure).forEach((component) => {
    collectStyleIds(component);
  });

  const themeStyles = themeStyleIds.reduce(
    (styles, styleId) =>
      _.merge(styles, {
        [styleId]: editorAPI.dsRead.theme.styles.get(styleId),
      }),
    {},
  );

  const theme = {
    colors: editorAPI.dsRead.theme.colors.getAll(),
    // eslint-disable-next-line @wix/santa-editor/deprecatedFontsApi
    fonts: editorAPI.dsRead.theme.fonts.getAll(),
    styles: themeStyles,
  };

  if (experiment.isOpen('specs.thunderbolt.saveLetterSpacingToTextTheme')) {
    theme.textThemes = editorAPI.dsRead.theme.textThemes.getAll();
  }

  return theme;
}

export { collectThemeFromSerializedStructure };
