// @ts-nocheck
import {
  environment,
  editorDataFunction,
} from '@wix/editor-component-data-resolver';
import constants from '@/constants';

export default {
  allowConnectToDB: true,
  allowedMobileOnly: true,
  overrideProportionalResize: true,
  overridesWhenReferred: editorDataFunction(
    environment('isMobileEditor'),
    (isMobileEditor) => ({
      gfpp: {
        disableActions: isMobileEditor
          ? [
              constants.ROOT_COMPS.GFPP.ACTIONS.SCALE_UP,
              constants.ROOT_COMPS.GFPP.ACTIONS.SCALE_DOWN,
            ]
          : [],
      },
    }),
  ),
};
