import * as coreBi from '@/coreBi';
import * as util from '@/util';
import constants from '@/constants';
import {
  EditorParamsApiKey,
  SectionsMigrationApiKey,
  EditorPermissionsApiKey,
} from '@/apis';
import $ from 'zepto';
import experiment from 'experiment';
import type { EditorAPI } from '@/editorAPI';
import type { EditorParams } from '@/editorParams';

const editorReadyBIPermissions = [
  'html-editor.publish',
  'my-account.duplicate-site',
  'my-account.transfer-site',
  'my-account.delete-site',
] as const;

function getPermissionsToSendOnEditorReady(userPermissions: string[]) {
  return editorReadyBIPermissions.filter((permission) =>
    userPermissions.includes(permission),
  );
}

function getOpenExperiments() {
  return util.array
    .sort(
      Object.keys(
        util.object.pickBy(util.editorModel.runningExperiments, (value, key) =>
          experiment.isOpen(key),
        ),
      ),
    )
    .join(',');
}

function getUserBrowserTheme() {
  if (window.matchMedia) {
    if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
      return 'dark';
    }
    return 'light';
  }
  return '';
}

const getOpenMethod = ({
  siteCreationWizard,
  siteGenerationWizard,
}: EditorParams): string => {
  if (siteCreationWizard) {
    return constants.SITE_CREATION.OPEN_METHOD_NAME;
  } else if (siteGenerationWizard) {
    return constants.SITE_GENERATION.OPEN_METHOD_NAME;
  }
  return null;
};

export const sendEditorStageReadyBI = ({
  editorAPI,
}: {
  editorAPI: EditorAPI;
}) => {
  const { host, dsRead } = editorAPI;

  // TODO: investigate zepto usage
  const $window = $(window);

  const userPermissions = dsRead?.generalInfo.getUserPermissions();

  const permissions = getPermissionsToSendOnEditorReady(userPermissions);

  const isSiteFromOnBoarding = dsRead?.generalInfo.isSiteFromOnBoarding();
  const isFirstSave = dsRead?.generalInfo.isFirstSave();
  const isPremium = editorAPI.site.isPremium();
  const language = util.languages.getLanguageCode();
  const sectionsMigrationApi = host.getAPI(SectionsMigrationApiKey);
  const editorParams = host.getAPI(EditorParamsApiKey);
  const editorPermissionsApi = host.getAPI(EditorPermissionsApiKey);
  const deviceType = util.browserUtil.getDeviceType();
  const screenOrientation = util.browserUtil.getScreenOrientation();
  const touchSupported = util.browserUtil.isTouchSupported();

  editorAPI.bi.event(coreBi.events.editor.EDITOR_STAGE_READY, {
    isWelcomeScreen: false, // user preferences cannot be defined on this step TODO: move flag to another BI event
    is_premium: isPremium,
    is_template: isFirstSave,
    window_width: $window.width(),
    window_height: $window.height(),
    screen_width: window.screen.width,
    screen_height: window.screen.height,
    avail_width: window.screen.availWidth,
    avail_height: window.screen.availHeight,
    prefetch: window.afterEditorPrefetch,
    request_from_adi: isSiteFromOnBoarding,
    experiments: getOpenExperiments(),
    ref: window.document.referrer,
    permission_status: permissions.join(','),
    open_method: getOpenMethod(editorParams),
    is_section_migration: sectionsMigrationApi.hasMigrationProcessed(),
    sectionsEnabled: util.sections.isSectionsEnabled(),
    is_section_component:
      util.sections.checkSiteStructureForSections(editorAPI),
    theme: getUserBrowserTheme(),
    contentEditingOnly:
      editorPermissionsApi.has('CLASSIC-EDITOR.EDIT-CONTENT') &&
      !editorPermissionsApi.has('CLASSIC-EDITOR.EDIT-DESIGN'),
    editorLanguage: language,
    screen_orientation: screenOrientation,
    device: deviceType,
    touch_supported: touchSupported,
  });
};
