import _ from 'lodash';
import type {
  CompRef,
  Pointer,
  CompVariantPointer,
} from 'types/documentServices';

import { array } from '@/util';

import type { AnimationsScope } from '../../scope';
import type { EffectData } from '../types';
import { triggerTypes, type AnimationType, type Reaction } from './types';
import { createAnimationsHelpers } from './helpers';
import {
  triggerParams,
  reactionParams,
  triggerToAnimationTypeMap,
  animationTypeToTriggerMap,
} from './configs';

export const createAnimationsApi = ({ editorAPI }: AnimationsScope) => {
  const helpers = createAnimationsHelpers(editorAPI);

  function updateLoopTrigger(
    compRef: CompRef,
    triggerTypeToChangeTo: 'page-visible' | 'animation-end',
  ) {
    const currentTriggerType = helpers.loop.getAnotherTriggerType(
      triggerTypeToChangeTo,
    );

    const entranceEffect =
      triggerTypeToChangeTo === 'animation-end' &&
      helpers.loop.getDependentEffect(compRef);

    const loopReaction = helpers.findReactionByTriggerType(
      compRef,
      currentTriggerType,
    );

    if (!loopReaction) return;

    const newTriggerParams = triggerParams.loop(entranceEffect);

    helpers.removeReaction(compRef, loopReaction);
    helpers.removeTriggerIfNeeded(compRef, loopReaction.triggerType);

    const triggerRef = helpers.getTriggerRef(
      compRef,
      newTriggerParams.trigger,
      newTriggerParams.params,
    );

    helpers.createReaction(
      compRef,
      triggerRef,
      loopReaction.effect,
      reactionParams.loop,
    );
  }

  function createDesktopAnimation(
    defaultCompRef: CompRef,
    compRefWithMobileVariant: CompVariantPointer,
    {
      animationType,
      effectData,
    }: { animationType: AnimationType; effectData: EffectData },
  ) {
    const { trigger: triggerType, params } = helpers.getTriggerParams(
      defaultCompRef,
      animationType,
    );

    const mobileReaction = helpers.findReaction(
      compRefWithMobileVariant,
      animationType,
    );

    const effectRef =
      mobileReaction?.effect ??
      helpers.setEmptyEffect(defaultCompRef, animationType);

    editorAPI.components.effects.update(
      defaultCompRef as CompVariantPointer,
      effectRef,
      effectData,
    );

    const triggerRef = helpers.getTriggerRef(
      defaultCompRef,
      triggerType,
      params,
    );

    helpers.createReaction(
      defaultCompRef,
      triggerRef,
      effectRef,
      reactionParams[animationType],
    );

    if (helpers.hasMobileOverrides(compRefWithMobileVariant)) {
      disableMobileAnimation(
        compRefWithMobileVariant,
        animationType,
        effectRef,
      );
    }
  }

  function disableDesktopAnimation(
    defaultCompRef: CompRef,
    reaction: Reaction,
  ) {
    helpers.removeReaction(defaultCompRef, reaction);
    helpers.removeTriggerIfNeeded(defaultCompRef, reaction.triggerType);

    helpers.setEmptyEffect(
      defaultCompRef,
      triggerToAnimationTypeMap[reaction.triggerType],
      reaction.effect,
    );
  }

  function createMobileAnimation(
    compRefWithMobileVariant: CompVariantPointer,
    defaultCompRef: CompRef,
    {
      animationType,
      effectData,
    }: { animationType: AnimationType; effectData: EffectData },
  ) {
    const { trigger: triggerType, params } = helpers.getTriggerParams(
      compRefWithMobileVariant,
      animationType,
    );

    const effectRef =
      helpers.findReaction(defaultCompRef, animationType)?.effect ??
      helpers.setEmptyEffect(defaultCompRef, animationType);

    editorAPI.components.effects.update(
      compRefWithMobileVariant,
      effectRef,
      effectData,
    );

    const triggerRef = helpers.getTriggerRef(
      compRefWithMobileVariant,
      triggerType,
      params,
    );

    helpers.createReaction(
      compRefWithMobileVariant,
      triggerRef,
      effectRef,
      reactionParams[animationType],
    );
  }

  function disableMobileAnimation(
    compRefWithMobileVariant: CompVariantPointer,
    animationType: AnimationType,
    effectRef: Pointer,
  ) {
    helpers.disableReaction(compRefWithMobileVariant, animationType);

    helpers.setEmptyEffect(compRefWithMobileVariant, animationType, effectRef);
  }

  function removeMobileAndDesktopAnimation(
    compRefWithMobileVariant: CompVariantPointer,
    defaultCompRef: CompVariantPointer,
    reaction: Reaction,
  ) {
    const triggerRef = helpers.findTriggerRef(
      defaultCompRef,
      reaction.triggerType,
    );

    editorAPI.components.reactions.removeAll(defaultCompRef, triggerRef);
    editorAPI.components.reactions.removeAll(
      compRefWithMobileVariant,
      triggerRef,
    );

    helpers.removeTriggerIfNeeded(defaultCompRef, reaction.triggerType);

    if (animationTypeToTriggerMap.loop.includes(reaction.triggerType)) {
      const anotherTriggerType = helpers.loop.getAnotherTriggerType(
        reaction.triggerType,
      );

      const triggerRef = helpers.findTriggerRef(
        defaultCompRef,
        anotherTriggerType,
      );

      editorAPI.components.reactions.removeAll(defaultCompRef, triggerRef);
      editorAPI.components.reactions.removeAll(
        compRefWithMobileVariant,
        triggerRef,
      );

      helpers.removeTriggerIfNeeded(defaultCompRef, anotherTriggerType);
    }

    editorAPI.components.effects.remove(defaultCompRef, reaction.effect);
  }

  function cloneAnimationsToMobile(
    defaultCompRef: CompRef,
    compRefWithMobileVariant: CompVariantPointer,
  ) {
    const desktopReactions = editorAPI.components.reactions.get(
      defaultCompRef,
    ) as Reaction[];

    if (!desktopReactions) return;

    for (const reaction of desktopReactions) {
      const triggerRef = helpers.findTriggerRef(
        defaultCompRef,
        reaction.triggerType,
      );

      const reactionParams = _.omit(reaction, [
        'component',
        'pointer',
        'triggerType',
      ]);

      editorAPI.components.reactions.add(
        compRefWithMobileVariant,
        triggerRef,
        reactionParams,
      );

      const effectData = _.omit(
        helpers.getEffectData(defaultCompRef, reactionParams.effect),
        ['id', 'metaData', 'namedEffect.id', 'namedEffect.metaData'],
      ) as EffectData;

      editorAPI.components.effects.update(
        compRefWithMobileVariant,
        reactionParams.effect,
        effectData,
      );
    }
  }

  /* Public API functions */

  function get(compRef: CompRef, animationType: AnimationType) {
    const { compRefWithMobileVariant, defaultCompRef } =
      helpers.getCompRefs(compRef);

    const mobileReaction = helpers.findReaction(
      compRefWithMobileVariant,
      animationType,
    );
    const desktopReaction = helpers.findReaction(defaultCompRef, animationType);

    if (
      compRef.type === 'MOBILE' &&
      helpers.hasMobileOverrides(compRefWithMobileVariant)
    ) {
      return helpers.getEffectData(
        compRefWithMobileVariant,
        mobileReaction?.effect,
      );
    }

    return helpers.getEffectData(defaultCompRef, desktopReaction?.effect);
  }

  async function set(
    compRef: CompRef,
    {
      animationType,
      effectData,
    }: { animationType: AnimationType; effectData: EffectData },
  ) {
    const { compRefWithMobileVariant, defaultCompRef } =
      helpers.getCompRefs(compRef);

    const isMobile = compRef.type === editorAPI.viewMode.VIEW_MODES.MOBILE;
    const compToUpdate = isMobile ? compRefWithMobileVariant : defaultCompRef;

    if (isMobile && !helpers.hasMobileOverrides(compRefWithMobileVariant)) {
      cloneAnimationsToMobile(defaultCompRef, compRefWithMobileVariant);
    }

    const existingReaction = helpers.findReaction(compToUpdate, animationType);

    if (existingReaction) {
      editorAPI.components.effects.update(
        compToUpdate,
        existingReaction.effect,
        effectData,
      );
      return;
    }

    if (isMobile) {
      createMobileAnimation(compRefWithMobileVariant, defaultCompRef, {
        animationType,
        effectData,
      });
    } else {
      createDesktopAnimation(defaultCompRef, compRefWithMobileVariant, {
        animationType,
        effectData,
      });
    }

    if (animationType === 'entrance') {
      await editorAPI.waitForChangesAppliedAsync();
      updateLoopTrigger(compToUpdate, 'animation-end');
    }

    await editorAPI.waitForChangesAppliedAsync();
  }

  async function remove(compRef: CompRef, animationType: AnimationType) {
    const { compRefWithMobileVariant, defaultCompRef } =
      helpers.getCompRefs(compRef);

    const isMobile = compRef.type === 'MOBILE';
    const compToUpdate = isMobile ? compRefWithMobileVariant : defaultCompRef;

    const desktopReaction = helpers.findReaction(defaultCompRef, animationType);
    const mobileReaction = helpers.findReaction(
      compRefWithMobileVariant,
      animationType,
    );

    if (!mobileReaction && !desktopReaction) return;

    if (animationType === 'entrance') {
      updateLoopTrigger(compToUpdate, 'page-visible');
      await editorAPI.waitForChangesAppliedAsync();
    }

    if ((isMobile && !desktopReaction) || (!isMobile && !mobileReaction)) {
      removeMobileAndDesktopAnimation(
        compRefWithMobileVariant,
        defaultCompRef,
        desktopReaction ?? mobileReaction,
      );

      return;
    }

    if (isMobile) {
      disableMobileAnimation(
        compRefWithMobileVariant,
        animationType,
        mobileReaction?.effect ?? desktopReaction?.effect,
      );
    } else {
      if (!desktopReaction || !mobileReaction) return;

      disableDesktopAnimation(defaultCompRef, desktopReaction);
    }

    await editorAPI.waitForChangesAppliedAsync();
  }

  async function removeMobileOverrides(compRef: CompRef) {
    const { compRefWithMobileVariant, defaultCompRef } =
      helpers.getCompRefs(compRef);

    if (
      editorAPI.mobile.mobileOnlyComponents.isMobileOnlyComponent(compRef.id)
    ) {
      return;
    }

    for (const triggerType of triggerTypes) {
      const triggerRef = helpers.findTriggerRef(defaultCompRef, triggerType);

      if (!triggerRef) continue;

      const mobileReaction = helpers.findReactionByTriggerType(
        compRefWithMobileVariant,
        triggerType,
      );

      const effectRef =
        mobileReaction?.effect ??
        helpers.findReactionByTriggerType(defaultCompRef, triggerType)?.effect;

      editorAPI.components.reactions.removeAll(
        compRefWithMobileVariant,
        triggerRef,
      );

      if (effectRef) {
        editorAPI.components.effects.remove(
          compRefWithMobileVariant,
          effectRef,
        );
      }

      await editorAPI.waitForChangesAppliedAsync();
    }
  }

  return {
    get,
    set: (
      compRefs: CompRef | CompRef[],
      params: { animationType: AnimationType; effectData: EffectData },
    ) => array.applyForAllAsync((comp) => set(comp, params), compRefs),

    remove: (compRefs: CompRef | CompRef[], animationType: AnimationType) =>
      array.applyForAllAsync((comp) => remove(comp, animationType), compRefs),

    removeMobileOverrides: (compRefs: CompRef | CompRef[]) =>
      array.applyForAllAsync((comp) => removeMobileOverrides(comp), compRefs),
  };
};
