import _ from 'lodash';
import {
  editorDataFunction,
  componentInstanceData,
} from '@wix/editor-component-data-resolver';

import type { ComponentEditorMetaDataDefinition } from '../types';

const LABEL_SKIN_PARAMS = ['txtlbl', 'fntlbl', 'txtlblrq'];
const DROPDOWN_LIST_SKIN_PARAMS = [
  'dropdownListBackgroundColor',
  'dropdownListBoxShadow',
  'dropdownListStrokeColor',
  'dropdownListStrokeWidth',
  'dropdownListFont',
  'dropdownListTextColor',
  'dropdownListHoverBackgroundColor',
  'dropdownListHoverTextColor',
  'dropdownListBorderRadius',
];

const metaData: ComponentEditorMetaDataDefinition = {
  skipInQuickEditPanel: true,
  skinParamsToIgnore: editorDataFunction(
    componentInstanceData('componentData'),
    componentInstanceData('componentProperties'),
    (componentData: AnyFixMe, componentProperties: AnyFixMe) => {
      const hasLabel = !_.isEmpty(componentData?.label);
      const isDesignableList = componentProperties?.designableList;
      const labelParams = hasLabel ? [] : LABEL_SKIN_PARAMS;
      const dropdownListParamsToIgnore = isDesignableList
        ? []
        : DROPDOWN_LIST_SKIN_PARAMS;

      return [...labelParams, ...dropdownListParamsToIgnore];
    },
  ),
  allowConnectToDB: true,
};

export default metaData;
