import React from 'react';
import * as util from '@/util';
import experiment from 'experiment';
import {
  type ExternalCollectionsListStateProps,
  mapStateToProps,
} from './externalCollectionsList.mapper';
import CollectionsList from '../collectionsList/collectionsList';
import { useRedirectToUserCollectionsTab } from '../../hooks/useRedirectToUserCollectionsTab';
import { useAllConnectors } from '../../hooks/useAllConnectors';
import { CollectionsGroup } from '../utils/collections-group';
import { isResponsiveEditor } from '@wix/santa-editor-utils';

import type { CollectionsListOwnProps } from '../collectionsList/collectionsList.mapper';

interface ExternalCollectionsListProps
  extends CollectionsListOwnProps,
    ExternalCollectionsListStateProps {}

const ExternalCollectionsList: React.FC<ExternalCollectionsListProps> = ({
  collections,
  selectTabByName,
  instance,
}) => {
  useRedirectToUserCollectionsTab({ collections, selectTabByName });
  const externalConnectors = useAllConnectors(instance, collections);

  const titlesInSubTitles =
    isResponsiveEditor() &&
    experiment.isOpen('specs.wixDataClient.6thButtonPanelTitleInSubtitle');

  const title = titlesInSubTitles
    ? undefined
    : 'CONTENT_MANAGER_6_BUTTON_EXTERNAL_COLLECTIONS_TITLE';
  const subtitle = titlesInSubTitles
    ? 'EDITORX_CONTENT_MANAGER_6_BUTTON_EXTERNAL_COLLECTIONS_SUBTITLE_WITH_TITLE'
    : 'CONTENT_MANAGER_6_BUTTON_EXTERNAL_COLLECTIONS_SUBTITLE';

  return (
    <CollectionsList
      collections={collections}
      externalConnectors={externalConnectors}
      collectionsGroup={CollectionsGroup.EXTERNAL}
      title={title}
      subtitle={subtitle}
      showActionButtons={false}
      showVideoTooltip={false}
      selectTabByName={selectTabByName}
    />
  );
};

export default util.hoc.connect(
  util.hoc.STORES.EDITOR_API,
  mapStateToProps,
)(ExternalCollectionsList);
