import { biLogger, fedopsLogger } from '@/util';
import { ErrorReporter } from '@wix/editor-error-reporter';
import { componentAddedToStage } from '@wix/bi-logger-editor/v2';

import type {
  AddPanelComponentAddedToStageByDrag,
  AddPanelComponentAddedToStageByClick,
} from '@/components';
import type { EditorAPI } from '@/editorAPI';
import type { DraggableItem, OnDropCallback } from '@/addPanelInfra';
import type { Point, CompRef, CompStructure } from 'types/documentServices';

const installAndAddBlocksWidget = async (
  editorAPI: EditorAPI,
  appDefinitionId: string,
  compStructure: CompStructure,
  containerRef: CompRef,
  type: (
    | AddPanelComponentAddedToStageByDrag
    | AddPanelComponentAddedToStageByClick
  )['type'],
  coords: Point,
  biOrigin: string,
  presetId: string,
  categoryId: string,
  sectionTitle: string,
): Promise<CompRef> => {
  fedopsLogger.interactionStarted(
    fedopsLogger.INTERACTIONS.ADD_PANEL.ADD_BLOCKS_WIDGET,
  );
  await editorAPI.tpa.installAppIfNeeded(appDefinitionId, {
    resolveBeforeSave: true,
    platformProvisionOnly: true,
  });
  const { data, layout, presets } = compStructure;
  const { widgetId } = data;
  const mobileVariant = editorAPI.mobile.getMobileVariant();
  const scopedPresets = {
    // @ts-expect-error
    [mobileVariant.id]: compStructure.scopedPresets.mobile,
  };
  return new Promise((resolve, reject) => {
    editorAPI.dsActions.appStudioWidgets.addWidget(appDefinitionId, widgetId, {
      presets,
      containerRef,
      scopedPresets,
      installationType: 'closed',
      layout: { ...layout, ...coords },
      onSuccess: async (newWidgetRef: CompRef) => {
        await editorAPI.components.hooks.componentAddedToStage.fire({
          type,
          origin: 'addPanel',
          compRef: newWidgetRef,
          mousePosition: coords,
        });
        editorAPI.selection.selectComponentByCompRef(newWidgetRef);
        resolve(newWidgetRef);
        fedopsLogger.interactionEnded(
          fedopsLogger.INTERACTIONS.ADD_PANEL.ADD_BLOCKS_WIDGET,
        );
        biLogger.report(
          componentAddedToStage({
            ...editorAPI.bi.getComponentsBIParams([newWidgetRef])[0],
            origin: biOrigin,
            category: categoryId,
            section: sectionTitle,
            preset_id: presetId,
            adding_method: type,
            page_id: editorAPI.pages.getFocusedPageId(),
            target_component: editorAPI.components.getType(containerRef),
            target_component_id: containerRef.id,
          }),
        );
      },
      onError: (e) => {
        ErrorReporter.captureException(e, {
          tags: { failedToAddAppStudioWidget: true },
        });
        reject(e);
      },
    });
  });
};

interface AddBlocksWidgetParams {
  appDefinitionId: string;
  compStructure: CompStructure;
  biOrigin: string;
  presetId: string;
  categoryId: string;
  sectionTitle: string;
}

export const addBlocksWidgetOnClick = async (
  editorAPI: EditorAPI,
  {
    biOrigin,
    presetId,
    categoryId,
    sectionTitle,
    compStructure,
    appDefinitionId,
  }: AddBlocksWidgetParams,
) => {
  const containerToAddTo =
    editorAPI.addPanelInfra.addPanelUtils.getContainerToAddTo(
      editorAPI,
      compStructure,
      null,
    );
  const { width: parentWidth } =
    editorAPI.components.layout.get_size(containerToAddTo);
  const coords = {
    y: 0,
    x: (parentWidth - compStructure.layout.width) / 2,
  };
  await installAndAddBlocksWidget(
    editorAPI,
    appDefinitionId,
    compStructure,
    containerToAddTo,
    'click',
    coords,
    biOrigin,
    presetId,
    categoryId,
    sectionTitle,
  );
};

export const addBlocksWidgetOnDrop = (
  editorAPI: EditorAPI,
  {
    biOrigin,
    presetId,
    categoryId,
    sectionTitle,
    compStructure,
    appDefinitionId,
  }: AddBlocksWidgetParams,
): OnDropCallback => {
  return async (
    coords: Point,
    itemData: DraggableItem,
    containerRef: CompRef,
  ) => {
    await installAndAddBlocksWidget(
      editorAPI,
      appDefinitionId,
      compStructure,
      containerRef,
      'drag',
      coords,
      biOrigin,
      presetId,
      categoryId,
      sectionTitle,
    );
  };
};
