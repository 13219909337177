// @ts-nocheck
import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import * as coreBi from '@/coreBi';
import * as util from '@/util';
import { translate } from '@/i18n';

import * as symbols from '@wix/santa-editor-symbols';
import * as baseUI from '@/baseUI';
import {
  Button,
  ContextMenu,
  ContextMenuAction,
  ContextMenuContent,
  ContextMenuFolder,
  Divider,
  TextInput,
  Tooltip,
  TooltipOnEllipsis,
} from '@wix/wix-base-ui';
import { cx, isCustomMenusEnabled } from '@/util';
import constants from '@/constants';
import { getPagesMenuItemSymbol, getMenuItemBiType } from '../../../utils';

const itemTypeSymbolSize = 20;

function renderFolder(action, { handleClickAction }) {
  const icon = <symbols.symbol name={action.symbolName} />;
  return (
    <ContextMenuFolder icon={icon} label={action.title} shouldTranslate={false}>
      {action.actions.map((folderAction) =>
        renderAction(folderAction, { handleClickAction }),
      )}
    </ContextMenuFolder>
  );
}

function getActionSymbol(action) {
  if (action?.symbol) {
    return <action.symbol className="symbol" />;
  }
  if (action?.symbolName) {
    return <symbols.symbol name={action.symbolName} />;
  }
  return null;
}

function renderAction(action, { handleClickAction }) {
  if (action.isDivider) {
    return null; //<baseUILib.Divider key={action.key} />;
  }

  if (action.isFolder) {
    return renderFolder(action, { handleClickAction });
  }

  const isSetting = action.isSettings;
  const className = isSetting
    ? 'menu-item-settings-action-pp'
    : `action-${action.title}`;
  const handleClick = () => handleClickAction(action, { isSetting });
  const key = `action-${action.title}-${action.folderId || ''}`;

  return (
    <ContextMenuAction
      onClick={handleClick}
      key={key}
      disabled={action.disabled}
      automationId={`action-${action.title}`}
      className={className}
    >
      {getActionSymbol(action)}
      {action.tooltipText ? (
        <Tooltip
          alignment={constants.UI.TOOLTIP.ALIGNMENT.RIGHT}
          shouldTranslate={false}
          key="tooltip"
          content={action.tooltipText}
        >
          <span>{action.title}</span>
        </Tooltip>
      ) : (
        <span>{action.title}</span>
      )}
    </ContextMenuAction>
  );
}

function template() {
  return (
    <div
      onClick={this.handleClickItem}
      data-aitemtype={this.getAutomationItemType()}
      className={cx('menu-item-pp', this.getTopLevelClasses())}
    >
      {this.state.inputValue === null
        ? (() => {
            const pagePermissionSymbol = this.getPagePermissionSymbol();

            return (
              <div
                key="pageInfo"
                onDoubleClick={this.onDoubleClick}
                className="title-action-container"
              >
                <div className="left-container">
                  {this.props.isDragAvailable ? (
                    <symbols.symbol
                      name="dragPage"
                      key="drag-icon"
                      dataHintMyData="hint_my_data"
                      className="page-item-drag"
                    />
                  ) : null}
                  <Tooltip
                    disabled={!this.props.info}
                    content={this.props.info}
                    shouldTranslate={false}
                    arrowAlignment="top"
                    key="tooltip-type-symbol-pp"
                  >
                    <baseUI.SymbolWrapper
                      name={this.getTypeSymbolName()}
                      size={itemTypeSymbolSize}
                      className="symbol"
                    />
                  </Tooltip>
                  <span className={cx(this.getTitleClass())}>
                    <TooltipOnEllipsis
                      marginTop={5}
                      contentClassName="menu-item-title-name"
                    >
                      {this.props.dataSource.label}
                    </TooltipOnEllipsis>
                  </span>
                </div>
                <div className="right-container">
                  {pagePermissionSymbol ? (
                    <symbols.symbol
                      name={pagePermissionSymbol}
                      key="pagePermissionSymbol"
                    />
                  ) : null}
                  {this.isHidden() ? (
                    <symbols.symbol
                      name="hiddenPageStatus"
                      key="hiddenPageStatus-symbol"
                    />
                  ) : null}
                  {this.state.actions
                    ? (() => {
                        const actions = this.filterRegularActions();
                        const dataBindingActions =
                          this.filterDataBindingActions();
                        const tools = this.filterToolsActions();
                        const settings = this.filterSettingsActions();

                        return (
                          <div onContextMenu={this.handleRightClickOnMenu}>
                            <ContextMenu
                              isOpen={this.state.contextMenuOpened}
                              onToggle={this.handleContextMenuToggle}
                              automationId="menu-item-context-menu"
                              className="flat menu-item-context-menu-button"
                              maxWidth={'400px'}
                            >
                              <ContextMenuContent
                                key={`actions-${this.state.actions.length}`}
                              >
                                {settings.map((action) =>
                                  renderAction(action, {
                                    handleClickAction: this.handleClickAction,
                                  }),
                                )}

                                {!_.isEmpty(tools) ? (
                                  <Divider key="actions-divider-1" />
                                ) : null}

                                {tools.map((action) =>
                                  renderAction(action, {
                                    handleClickAction: this.handleClickAction,
                                  }),
                                )}

                                {!_.isEmpty(dataBindingActions) ? (
                                  <Divider key="actions-divider-2" />
                                ) : null}

                                {dataBindingActions.map((action) =>
                                  renderAction(action, {
                                    handleClickAction: this.handleClickAction,
                                  }),
                                )}

                                {!_.isEmpty(actions) && !_.isEmpty(settings) ? (
                                  <Divider key="actions-divider-3" />
                                ) : null}

                                {actions.map((action) =>
                                  renderAction(action, {
                                    handleClickAction: this.handleClickAction,
                                  }),
                                )}
                              </ContextMenuContent>
                            </ContextMenu>
                          </div>
                        );
                      })()
                    : null}
                </div>
              </div>
            );
          })()
        : null}
      {this.state.inputValue !== null
        ? (() => {
            const validationInvalidMsg = this.getValidationInvalidMsg();

            return (
              <div
                onMouseDown={(e) => {
                  e.stopPropagation();
                }}
                key="input-container-page-pp"
                className="input-button-container"
              >
                <TextInput
                  value={this.state.inputValue}
                  onChange={this.handleChangeInput}
                  isValid={!validationInvalidMsg}
                  invalidMessage={validationInvalidMsg}
                  shouldTranslate={false}
                  selectOnFocus={true}
                  maxLength={40}
                  onBlur={this.handleRenameDone}
                  autoSelect={true}
                  placeholder={this.getPlaceholder()}
                />
                <Button disabled={validationInvalidMsg} className="done-button">
                  {this.getRenameButtonLabel()}
                </Button>
              </div>
            );
          })()
        : null}
    </div>
  );
}

class MenuItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      actions: this.props.initialActions,
      contextMenuOpened: false,
      inputValue: (() => {
        const renamedMenuItem =
          this.props.dataSource.id === this.props.renamedMenuItemId;
        if (!renamedMenuItem || !this.props.renameEnabled) {
          return null;
        }

        const actions = this.props.getMenuItemActions(this.props.dataSource);
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/filter
        const renameAction = _.head(_.filter(actions, 'isRename'));
        return renameAction ? this.props.dataSource.label : null;
      })(),
    };

    this.handleClickAction = (action, { isSetting } = {}) => {
      if (isSetting) {
        this.props.setSelected();
      }
      if (action.isRename) {
        this.openRenameMode('quick_actions_menu');
      } else {
        action.onClick(this.props.dataSource.id, this.props.dataSource.label);
      }
    };
    this.handleClickItem = (event) => {
      // Check whether it was click on context menu or item iteself
      const CONTEXT_MENU_SELECTOR = '.menu-item-context-menu-button';
      if (event.target.closest(CONTEXT_MENU_SELECTOR)) {
        return;
      }

      this.props.setSelected();
    };
    this.openRenameMode = (actionSourceType) => {
      const renameAction = this.getRenameAction();
      if (typeof this.props.setRenamingState === 'function') {
        this.props.setRenamingState(true);
      }
      if (renameAction && this.props.renameEnabled) {
        this.actionSourceType = actionSourceType;
        this.setState({ inputValue: this.props.dataSource.label });
      }
    };
    this.handleChangeInput = (inputValue) => this.setState({ inputValue });
    this.handleRenameDone = () => {
      const isValid = !util.validate.legacyPageNameValidator(
        this.state.inputValue,
      );
      const renamedValue = isValid
        ? this.state.inputValue
        : this.props.dataSource.label;

      const renameAction = this.getRenameAction();
      renameAction.onClick(
        this.props.dataSource.id,
        renamedValue,
        'rename',
        this.actionSourceType || 'right-click-context-menu',
      );

      if (typeof this.props.setRenamingState === 'function') {
        this.props.setRenamingState(false);
      }
      this.props.setSelected();
      this.setState({ inputValue: null, contextMenuOpened: false });
      delete this.actionSourceType;
    };
    this.getRenameAction = () => {
      let { actions } = this.state;
      if (_.isEmpty(actions)) {
        actions = this.props.getMenuItemActions(this.props.dataSource);
      }

      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/filter
      return _.head(_.filter(actions, 'isRename'));
    };

    this.onDoubleClick = () => this.openRenameMode('double_click');

    this.handleRightClickOnMenu = (e) => {
      e.preventDefault();
      e.stopPropagation();
    };

    this.handleContextMenuToggle = (actionsOpened) => {
      if (actionsOpened) {
        this.setState({
          contextMenuOpened: true,
          actions: this.getActionsForItem(this.props.dataSource),
        });

        this.props.biEvent(coreBi.events.pages.pagesPanel.page_actions_click, {
          page_id:
            this.props.dataSource?.pageData?.id ||
            this.props.dataSource?.link?.pageId,
          source: this.props.biCategory,
          type: getMenuItemBiType(this.props.dataSource),
        });
      } else {
        this.setState({
          contextMenuOpened: false,
          actions: [],
        });
      }
    };

    this.getActionsForItem = (menuItem) =>
      this.props.getMenuItemActions(menuItem);

    this.isHidden = () => {
      if (isCustomMenusEnabled()) {
        return false;
      }

      if (this.props.isMobile) {
        return !this.props.dataSource.isVisibleMobile;
      }
      return !this.props.dataSource.isVisible;
    };

    this.getAutomationItemType = () => {
      const menuItem = this.props.dataSource;
      const prefix = menuItem.parent ? 'subpage-' : '';

      if (menuItem.isHomePage) {
        return `${prefix}home`;
      }
      if (menuItem.type.isPage) {
        return `${prefix}page`;
      }
      if (menuItem.type.isLink) {
        return `${prefix}link`;
      }
      if (menuItem.type.isDropdown) {
        return `${prefix}folder`;
      }
    };

    this.getTopLevelClasses = () => ({
      'hidden-page': this.isHidden(),
      selected: this.props.isSelected,
      'show-context-menu-button': this.state.contextMenuOpened,
    });

    this.getPagePermissionSymbol = () => {
      const isMembersPermission =
        this.props.dataSource?.pageData?.pageSecurity?.requireLogin;
      if (isMembersPermission) {
        return 'membersPagePermission';
      }
      const isPasswordPermission =
        this.props.dataSource?.pageData?.pageSecurity?.dialogLanguage;
      if (isPasswordPermission) {
        return 'passwordPagePermission';
      }
      return null;
    };

    this.getTitleClass = () => {
      const hasAttribute = !!this.getPagePermissionSymbol();
      const isHidden = !!this.isHidden();
      const isSubpage = !!this.props.dataSource.parent;

      return {
        'menu-item-title': true,
        'page-without-attributes': !isSubpage && !hasAttribute && !isHidden,
        'subpage-without-attributes': isSubpage && !hasAttribute && !isHidden,
        'page-with-attribute':
          (!isSubpage && hasAttribute && !isHidden) ||
          (!isSubpage && !hasAttribute && isHidden),
        'subpage-with-attribute':
          (isSubpage && hasAttribute && !isHidden) ||
          (isSubpage && !hasAttribute && isHidden),
        'page-with-attributes': !isSubpage && hasAttribute && isHidden,
        'subpage-with-attributes': isSubpage && hasAttribute && isHidden,
      };
    };

    this.filterSettingsActions = () =>
      (this.state.actions || []).filter((action) => action.isSettings);
    this.filterRegularActions = () =>
      (this.state.actions || []).filter(
        (action) =>
          !action.isSettings && !action.isTools && !action.isCmsAction,
      );
    this.filterToolsActions = () =>
      (this.state.actions || []).filter((action) => action.isTools);
    this.filterDataBindingActions = () =>
      (this.state.actions || []).filter((action) => action.isCmsAction);
    this.getTypeSymbolName = () => {
      const data = this.props.dataSource;
      const pageAppDefId = data.pageData?.managingAppDefId;
      const pageId = data.pageData?.id;

      const pageAppData = this.props.appPages?.find(
        (element) => element.id === pageAppDefId,
      );
      const page = pageAppData?.pagesData.find(
        (page: any) => page.id === pageId,
      );
      const pageIcon = page?.pageDescriptor?.icon;
      return pageIcon ?? getPagesMenuItemSymbol(data);
    };

    this.getRenameButtonLabel = () => {
      const menuItem = this.props.dataSource;

      if (isCustomMenusEnabled() && menuItem.type.isDropdown) {
        return translate('PagesPanel_Actions_Folder_Rename_Done');
      }

      return translate('Pages_Actions_Rename_Button');
    };
    this.getPlaceholder = () => {
      const menuItem = this.props.dataSource;

      if (isCustomMenusEnabled()) {
        if (menuItem.type.isDropdown) {
          return translate('PagesPanel_Actions_Folder_Placeholder_Text');
        }

        return translate('Pages_Actions_Rename_Placeholder');
      }

      return undefined;
    };

    this.getValidationInvalidMsg = () => {
      const menuItem = this.props.dataSource;
      let validator = util.validate.legacyPageNameValidator;

      if (isCustomMenusEnabled() && menuItem.type.isDropdown) {
        validator = util.validate.pagesFolderNameValidator;
      }

      return translate(validator(this.state.inputValue));
    };
  }

  UNSAFE_componentWillReceiveProps({ dataSource, renamedMenuItemId }) {
    const alreadyInTheRenameMode = this.state.inputValue !== null;
    if (dataSource.id === renamedMenuItemId && !alreadyInTheRenameMode) {
      this.openRenameMode();
    }
  }

  render() {
    return template.call(this);
  }
}

MenuItem.displayName = 'MenuItem';
MenuItem.propTypes = {
  renamedMenuItemId: PropTypes.string,
  dataSource: PropTypes.object.isRequired,
  isSelected: PropTypes.bool,
  setSelected: PropTypes.func.isRequired,
  isDragAvailable: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired,
  info: PropTypes.string,
  getMenuItemActions: PropTypes.func.isRequired,
  renameEnabled: PropTypes.bool,
  biEvent: PropTypes.func.isRequired,
  biCategory: PropTypes.string.isRequired,
  initialActions: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

export default MenuItem;
