// @ts-nocheck
import _ from 'lodash';
import { translate } from '@/i18n';
import constants from '@/constants';

const { ACTIONS } = constants.ROOT_COMPS.GFPP;
const openSource = 'change_document';

export default {
  mainActions: [
    {
      label: 'gfpp_mainaction_document',
      isApplied: true,
      onClick(editorAPI, compRef, origin: string) {
        const { mediaServices } = editorAPI;
        mediaServices.mediaManager.open(
          mediaServices.mediaManager.categories.DOCUMENT,
          openSource,
          {
            translation: {
              submitButton: translate(
                'MMGR_submitbutton_onstage_doc_mydocs_choose',
              ),
            },
            multiSelect: false,
            origin,
            callback(items) {
              if (!_.isEmpty(items)) {
                const data = editorAPI.components.data.get(compRef);

                const link = _.cloneDeep(data.link);
                const file = items[0];
                link.name = file.title;
                link.docId = file.fileUrl;

                data.link = link;
                editorAPI.components.data.update(compRef, data);
              }
              editorAPI.mediaServices.setFocusToEditor();
            },
          },
        );
      },
    },
  ],
  enabledActions: [
    ACTIONS.SETTINGS,
    ACTIONS.DESIGN,
    ACTIONS.ANIMATION,
    ACTIONS.HELP,
  ],
  mobileEnabledActions: [
    ACTIONS.SETTINGS,
    ACTIONS.HIDE,
    ACTIONS.HELP,
    ACTIONS.ANIMATION,
  ],
  presetActions: {
    help: {
      helpId: '01e5fb5e-fdc7-442b-aca5-322b2338e032',
    },
  },
};
