import * as stateManagement from '@/stateManagement';
import type { EditorAPI } from '@/editorAPI';
import type { CompRef } from 'types/documentServices';

export function createSelectionFocusStore(store: EditorAPI['store']) {
  const selectionActions = stateManagement.selection.actions;
  const selectionSelectors = stateManagement.selection.selectors;

  function setFocusedContainer(
    nextFocusedContainer: CompRef,
    currentFocusedContainer?: CompRef,
    callback?: (
      dsRead: EditorAPI['dsRead'],
      nextFocusedContainer: CompRef,
      currentFocusedContainer?: CompRef,
    ) => void,
    biParams?: { origin: string },
  ) {
    store.dispatch(
      selectionActions.setFocusedContainer(
        nextFocusedContainer,
        currentFocusedContainer,
        callback,
        biParams,
      ),
    );
  }

  function getFocusedContainer(): CompRef | null {
    const editorState = store.getState();
    return selectionSelectors.getFocusedContainer(editorState);
  }

  return {
    setFocusedContainer,
    getFocusedContainer,
  };
}
