import type { CompRef } from 'types/documentServices';

import { array } from '@/util';

import type { AnimationsScope } from '../../scope';
import type { EffectData } from '../types';

export const createPreviewApi = ({ editorAPI }: AnimationsScope) => {
  const previewIds = new Map<string, number | null>();

  function start(compRef: CompRef, effectObj: EffectData) {
    const previewId = editorAPI.components.behaviors.previewAnimation(
      compRef,
      {
        ...effectObj.value,
        params: effectObj.value.namedEffect,
      },
      () => {
        previewIds.delete(compRef.id);
      },
    );

    previewIds.set(compRef.id, previewId);
  }

  function stop(compRef: CompRef) {
    editorAPI.components.behaviors.stopPreviewAnimation(
      previewIds.get(compRef.id),
      1,
    );
  }

  return {
    start: (compRefs: CompRef | CompRef[], effectData: EffectData) =>
      array.applyForAll((compRef) => start(compRef, effectData), compRefs),
    stop: (compRefs: CompRef | CompRef[]) =>
      array.applyForAll((compRef) => stop(compRef), compRefs),
  };
};
