import React from 'react';
import experiment from 'experiment';
import {
  Button,
  Composites,
  Illustration,
  PanelHeader,
  RichText,
  TextLabel,
  MessageModal,
} from '@wix/wix-base-ui';
import * as baseUI from '@/baseUI';
import { translate } from '@/i18n';

interface ErrorSectionProps {
  closePanel: () => void;
  content: {
    header: string;
    message: string;
  };
  onHelp?: () => void;
  primaryButton: {
    label: string;
    onClick: () => void;
  };
}

function oldTemplate(props: ErrorSectionProps) {
  return (
    <div className="error-section">
      <Composites.PopupSmallSize className="composite-full-height">
        <PanelHeader
          // @ts-expect-error
          titleType="T16"
          noHelpBtn={true}
          onClose={props.closePanel}
          className="dark"
        >
          <span data-aid="header-title">{props.content.header}</span>
        </PanelHeader>
        <Composites.PanelContent className="package-panel-content">
          <Composites.RichTextWithIllustration>
            <Illustration>
              <baseUI.symbol name="plaster" />
            </Illustration>
            <Composites.RichText>
              <RichText>
                <span
                  data-aid="error-message"
                  // @ts-expect-error
                  type="T03"
                >
                  {props.content.message}
                </span>
              </RichText>
            </Composites.RichText>
          </Composites.RichTextWithIllustration>
        </Composites.PanelContent>
        <Composites.ActionSetHorizontal>
          <Button
            automationId="primary-button-package-and-update-panel"
            onClick={() => {
              props.primaryButton.onClick();
            }}
            className="primary"
          >
            <TextLabel
              value={props.primaryButton.label}
              automationId="primary-button-label"
            />
          </Button>
        </Composites.ActionSetHorizontal>
      </Composites.PopupSmallSize>
    </div>
  );
}

function template(props: ErrorSectionProps) {
  return (
    <MessageModal
      theme="destructive"
      onCloseButtonClick={props.closePanel}
      title={props.content.header}
      content={props.content.message}
      primaryButtonText={translate(props.primaryButton.label)}
      primaryButtonOnClick={props.primaryButton.onClick}
    />
  );
}

const ErrorSection = (props: ErrorSectionProps) =>
  experiment.isOpen('se_newBlocksPublishErrorModal')
    ? template(props)
    : oldTemplate(props);

export default ErrorSection;
