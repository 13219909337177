import _ from 'lodash';
import * as util from '@/util';
import type { EditorAPI } from '@/editorAPI';
import type { CompRef } from '@wix/document-services-types';
import { newAddPanelAPI, castDesignCompType } from '@/newAddPanelAPI';
import isLocaleOverrideExists from '@wix/editor-add-panel/dist/isLocaleOverrideExists';
import * as addPanelData from '@/addPanelData';
import addPanel from '@/oldAddPanel';

const applyExperiments = (sections: any): any => {
  return _.omit(sections, ['popup', 'boxSlideShow']);
};

type LanguageCode = keyof typeof Mapping;

function getLanguageCode(): LanguageCode | null {
  const languageCode = util.languages.getLanguageCode();
  if (
    languageCode &&
    languageCode.toLowerCase() !== 'en' &&
    isLocaleOverrideExists(languageCode)
  ) {
    return languageCode.toUpperCase() as LanguageCode;
  }
  return null;
}

const Mapping = {
  CS: () => import('@wix/editor-add-panel/dist/addPanelSectionsCS.js'),
  DA: () => import('@wix/editor-add-panel/dist/addPanelSectionsDA.js'),
  DE: () => import('@wix/editor-add-panel/dist/addPanelSectionsDE.js'),
  ES: () => import('@wix/editor-add-panel/dist/addPanelSectionsES.js'),
  FR: () => import('@wix/editor-add-panel/dist/addPanelSectionsFR.js'),
  HI: () => import('@wix/editor-add-panel/dist/addPanelSectionsHI.js'),
  IT: () => import('@wix/editor-add-panel/dist/addPanelSectionsIT.js'),
  JA: () => import('@wix/editor-add-panel/dist/addPanelSectionsJA.js'),
  KO: () => import('@wix/editor-add-panel/dist/addPanelSectionsKO.js'),
  NL: () => import('@wix/editor-add-panel/dist/addPanelSectionsNL.js'),
  NO: () => import('@wix/editor-add-panel/dist/addPanelSectionsNO.js'),
  PL: () => import('@wix/editor-add-panel/dist/addPanelSectionsPL.js'),
  PT: () => import('@wix/editor-add-panel/dist/addPanelSectionsPT.js'),
  RU: () => import('@wix/editor-add-panel/dist/addPanelSectionsRU.js'),
  SV: () => import('@wix/editor-add-panel/dist/addPanelSectionsSV.js'),
  TR: () => import('@wix/editor-add-panel/dist/addPanelSectionsTR.js'),
};

function getAddPanelDependencies() {
  const languageCode = getLanguageCode();
  return [
    languageCode
      ? Mapping[languageCode]().then((mod) => mod.default)
      : undefined,
  ] as const;
}

function loadAndCreate(editorAPI: EditorAPI) {
  return Promise.all(getAddPanelDependencies()).then(
    async ([optionalLanguage]) => {
      if (util.addPanel.isNewPanelEnabled()) {
        await newAddPanelAPI.initUniversalAddPanelData();
      }
      const disableOldAddPanelRegistry =
        util.addPanel.isOldAddPanelRegistryDisabled();

      if (optionalLanguage) {
        //hide
        optionalLanguage = applyExperiments(optionalLanguage);
        addPanel.sections.applyTranslatedSections(optionalLanguage);
      }

      function getCategory(categoryId: string) {
        return addPanelData.getCategory(editorAPI, categoryId);
      }

      function getSectionsByComponentType(
        compType: string,
        compRef: CompRef,
        withAdditionalBehaviors = false,
      ) {
        const componentType = castDesignCompType(compType, compRef, editorAPI);

        return (
          newAddPanelAPI.getSectionsByComponentType(componentType) ??
          addPanelData.getSectionsByComponentType(
            componentType,
            withAdditionalBehaviors,
          )
        );
      }

      function getDesignPanelTitleByComp(compType: string, compRef: CompRef) {
        const componentType = castDesignCompType(compType, compRef, editorAPI);

        return (
          newAddPanelAPI.getDesignPanelTitleByComp(componentType) ??
          addPanelData.getDesignPanelTitleByComp(componentType)
        );
      }

      function hasAddPanelDesign(compType: string, compRef: CompRef) {
        const componentType = castDesignCompType(compType, compRef, editorAPI);

        return newAddPanelAPI.hasAddPanelDesign(componentType);
      }

      function registerOverrideGroupsForCategory(
        categoryId: string,
        overrideFunction: AnyFixMe,
      ) {
        if (disableOldAddPanelRegistry) {
          return;
        }
        addPanelData.manager.override.registerOverrideGroupsForCategory(
          categoryId,
          overrideFunction,
        );
      }

      function unRegisterOverrideGroupsForCategory(categoryId: string) {
        if (disableOldAddPanelRegistry) {
          return;
        }
        addPanelData.manager.override.unRegisterOverrideGroupsForCategory(
          categoryId,
        );
      }

      function registerSectionToCategoryByGroup(
        categoryId: string,
        groupIndex: number,
        sectionIndex: number,
        sectionFunction: AnyFixMe,
      ) {
        if (disableOldAddPanelRegistry) {
          return;
        }
        addPanelData.manager.registration.registerSectionToCategoryByGroup(
          categoryId,
          groupIndex,
          sectionIndex,
          sectionFunction,
        );
      }

      function registerSectionAtStart(
        categoryId: string,
        sectionFunction: AnyFixMe,
      ) {
        if (disableOldAddPanelRegistry) {
          return;
        }
        addPanelData.manager.registration.registerSectionAtStart(
          categoryId,
          sectionFunction,
        );
      }

      function registerSectionAtEnd(
        categoryId: number,
        sectionFunction: AnyFixMe,
      ) {
        if (disableOldAddPanelRegistry) {
          return;
        }
        addPanelData.manager.registration.registerSectionAtEnd(
          categoryId,
          sectionFunction,
        );
      }

      return {
        registerOverrideGroupsForCategory,
        unRegisterOverrideGroupsForCategory,
        registerSectionToCategoryByGroup,
        registerSectionAtStart,
        registerSectionAtEnd,
        getCategory,
        hasAddPanelDesign,
        getSectionsByComponentType,
        getDesignPanelTitleByComp,
      };
    },
  );
}

export { loadAndCreate };
