import { browserUtil, media } from '@/util';

interface MediaSrcByScreenType {
  regular: string;
  retina: string;
}

export const getMediaSrc = <T extends string>(
  rawMediaConfig: Record<T, MediaSrcByScreenType>,
) => {
  const isRetina = browserUtil.getDevicePixelRatio() > 1; // Retina is 2 but it depends on page's zoom

  return Object.keys(rawMediaConfig).reduce((acc, tourStep) => {
    const { regular, retina } =
      rawMediaConfig[tourStep as keyof typeof rawMediaConfig];
    const mediaURL = isRetina ? retina : regular;
    return { ...acc, [tourStep]: media.getMediaUrl(mediaURL) };
  }, {} as Record<T, string>);
};
