import { CategoryId, SectionType } from '@wix/add-panel-common';

import type {
  Group,
  Category,
  Condition,
  AnySection,
} from '@wix/add-panel-common';
import type { AddPanelEnhanceDataOptions } from './index';

export interface MyWidgetItem {
  id: string;
  title: string;
}

export interface MyWidgetApp {
  id: string;
  title: string;
  props: {
    items: MyWidgetItem[];
  };
}

const getGroupedSections = (
  placeholderGroup: Group,
  app: MyWidgetApp,
): AnySection[] => {
  const uniqueSectionIds = [
    ...new Set<string>(app.props.items.map(({ id }: MyWidgetItem) => id)),
  ];
  const groupedSectionsById = uniqueSectionIds.reduce((sections, id, index) => {
    const items = app.props.items.filter(
      (item: MyWidgetItem) => item.id === id,
    );
    const title = items[0].title;
    sections[id] = {
      ...placeholderGroup.sections[0],
      props: {
        items: app.props.items.filter((item: MyWidgetItem) => item.id === id),
      },
      showSectionHeader: false,
      hide: false,
      systemId: `${id}_${app.id}`,
      groupIds: [app.id],
      conditions: [] as Condition[],
      appId: app.id,
      sectionName: app.id,
      title,
      automationId: 'add-panel-section-myWidgetsSection',
      translations: {
        shouldTranslate: false,
        subNavigationTitle: title,
        title: '',
        presetTitle: title,
      },
      type: SectionType.MyWidgets,
      index,
      noSpaceBeforeSection: false,
    };
    return sections;
  }, {} as { [key: string]: AnySection });
  return Object.entries(groupedSectionsById).map(([, section]) => section);
};

export const myWidgetsAddPanelEnhancer = (
  categories: Category[],
  addPanelEnhanceDataOptions: AddPanelEnhanceDataOptions,
): void => {
  if (addPanelEnhanceDataOptions.myWidgets) {
    const myWidgetsCategory = categories?.find(
      ({ id }) => id === CategoryId.InstalledApps,
    );
    if (myWidgetsCategory) {
      const [placeholderGroup] = myWidgetsCategory.groups;
      myWidgetsCategory.groups = addPanelEnhanceDataOptions.myWidgets.map(
        (app: MyWidgetApp, index: number) => ({
          ...placeholderGroup,
          systemId: app.id,
          id: app.id,
          title: app.title,
          translations: {
            title: app.title,
            shouldTranslate: false,
          },
          index,
          noDivider: false,
          sections: getGroupedSections(placeholderGroup, app),
        }),
      );
    }
  }
};
