import addPanelDataConsts from '@/addPanelDataConsts';
import type { OnDropCallback } from '@/addPanelInfra';
import { events } from '@/coreBi';
import type { EditorAPI } from '@/editorAPI';
import { constants as tpaConstants } from '@/tpa';
import {
  CustomClickAndDropHandler,
  type PresetSectionItem,
  type StyleParam,
} from '@wix/add-panel-component';
import type { CompRef, CompStructure } from 'types/documentServices';
import type { MapDispatchToProps, ThunkAction } from 'types/redux';
import { notifyApplication } from '../../../sections/listSection/dataSectionUtils';
import { BI_ORIGIN } from '../../constants';
import { attemptToAddComponent } from './addComponent';
import {
  addCompOnClick,
  type AddCompOnClickParams,
  addCompOnDrop,
  type AddCompOnDropParams,
} from './installHandlers/addComp';
import {
  addAppointmentFieldOnClick,
  addAppointmentFieldOnDrop,
} from './installHandlers/appointmentField';
import {
  addBlocksWidgetOnClick,
  addBlocksWidgetOnDrop,
} from './installHandlers/blocks';
import { addBlogOnClick, addBlogOnDrop } from './installHandlers/blog';
import {
  addPricingPlansOnDrop,
  addPricingPlansOnClick,
} from './installHandlers/pricingPlans';
import { addHotelsOnDrop, addHotelsOnClick } from './installHandlers/hotels';
import {
  addBookingButtonOnClick,
  addBookingButtonOnDrop,
  addBookingOnClick,
  addBookingOnDrop,
} from './installHandlers/bookings';
import {
  addChatButtonOnClick,
  addChatButtonOnDrop,
  addLiveChatOnClick,
  addLiveChatOnDrop,
} from './installHandlers/chat';
import {
  addMembersSuperAppOnClick,
  addMembersSuperAppOnDrop,
} from './installHandlers/membersSuperApp';
import { addSiteSearch } from './installHandlers/siteSearch';
import {
  addWixFormOnClick,
  addWixFormOnDrop,
  addWixFormToContainerOnClick,
  addWixFormToContainerOnDrop,
} from './installHandlers/wixForm';
import {
  addWixStoreWidgetOnClick,
  addWixStoreWidgetOnDrop,
} from './installHandlers/wixStore';

export interface InstallMapperProps {
  addCompOnClick: (params: {
    compDef: CompStructure;
    sectionTitle: string;
    tags: string;
    itemId: string;
    appDefinitionId: string;
    categoryId: string;
    position: { x: number; y: number } | void;
  }) => Promise<CompRef>;
  installAppAndNotify: (appDefinitionId: string) => void;
  installAppWithCustomHandlerOnClick: (params: {
    customClickAndDropHandler: CustomClickAndDropHandler;
    sectionTitle: string;
    categoryId: string;
    itemId?: string;
    presetId?: string;
    compDef?: CompStructure;
    appDefinitionId?: string;
    widgetId?: string;
    tags?: string;
    styleParams?: StyleParam[];
    item?: PresetSectionItem;
    position: { x: number; y: number } | void;
  }) => Promise<CompRef>;
  installAppWithCustomHandlerOnDrop: (params: {
    customClickAndDropHandler: CustomClickAndDropHandler;
    sectionTitle: string;
    categoryId: string;
    presetId?: string;
    compStructure: CompStructure;
    appDefinitionId?: string;
    widgetId?: string;
    styleParams?: StyleParam[];
    item?: PresetSectionItem;
  }) => OnDropCallback | undefined;
  installSuperAppOnClick: (params: AddCompOnClickParams) => Promise<CompRef>;
  installSuperAppOnDrop: (params: AddCompOnDropParams) => OnDropCallback;
}

const getEditorAPI: ThunkAction<EditorAPI> = (
  dispatch,
  getState,
  { editorAPI },
) => editorAPI;

export const installMapper: MapDispatchToProps<InstallMapperProps, any> = (
  dispatch,
) => {
  const editorAPI: EditorAPI = dispatch(getEditorAPI);

  return {
    addCompOnClick: ({
      compDef,
      sectionTitle,
      tags,
      itemId,
      appDefinitionId,
      categoryId,
      position,
    }) => {
      return attemptToAddComponent(editorAPI, {
        compDef,
        sectionTitle,
        tags,
        itemId,
        appDefinitionId,
        categoryId,
        position,
      });
    },
    installAppAndNotify: (appDefinitionId) => {
      const event = events.pages.add_dashboard_page_from_add_panel;
      const isMultilingualEnabled = editorAPI.language.multilingual.isEnabled();

      editorAPI.bi.event(event, { multilingual: isMultilingualEnabled });

      return editorAPI.tpa.installAppIfNeeded(appDefinitionId).then(() =>
        notifyApplication(editorAPI, appDefinitionId, {
          eventType: addPanelDataConsts.CLICK_ACTION.EVENT.APP_CLICKED,
          eventPayload: {},
        }),
      );
    },
    installAppWithCustomHandlerOnClick: async ({
      customClickAndDropHandler,
      sectionTitle,
      categoryId,
      itemId,
      presetId,
      compDef,
      appDefinitionId,
      widgetId,
      tags,
      styleParams,
      item,
      position,
    }) => {
      switch (customClickAndDropHandler) {
        case CustomClickAndDropHandler.Blog: {
          return addBlogOnClick(editorAPI, {
            item,
            appDefinitionId,
            widgetId,
            compStructure: compDef,
            tags,
            itemId,
            sectionTitle,
            categoryId,
            biOrigin: BI_ORIGIN,
            position,
          });
        }
        case CustomClickAndDropHandler.Events: {
          return addCompOnClick(editorAPI, {
            appDefinitionId,
            widgetId,
            compStructure: compDef,
            tags,
            itemId,
            sectionTitle,
            categoryId,
            biOrigin: tpaConstants.BI.type.ADD_WIDGET,
            position,
          });
        }
        case CustomClickAndDropHandler.Members: {
          return addMembersSuperAppOnClick(editorAPI, {
            appDefinitionId,
            widgetId,
            compStructure: compDef,
            tags,
            itemId,
            sectionTitle,
            categoryId,
            position,
          });
        }
        case CustomClickAndDropHandler.ChatButton: {
          addChatButtonOnClick(editorAPI, { structure: compDef });
          return;
        }
        case CustomClickAndDropHandler.LiveChat: {
          return addLiveChatOnClick(editorAPI, {
            itemId,
            sectionTitle,
            structure: compDef,
            tags,
            position,
          });
        }
        case CustomClickAndDropHandler.Blocks: {
          return addBlocksWidgetOnClick(editorAPI, {
            categoryId,
            sectionTitle,
            appDefinitionId,
            presetId: item.id,
            biOrigin: BI_ORIGIN,
            compStructure: compDef,
          });
        }
        case CustomClickAndDropHandler.PricingPlans: {
          return addPricingPlansOnClick(editorAPI, {
            appDefinitionId,
            widgetId,
            compStructure: compDef,
            tags,
            itemId,
            sectionTitle,
            categoryId,
            biOrigin: BI_ORIGIN,
            position,
          });
        }
        case CustomClickAndDropHandler.Hotels: {
          return addHotelsOnClick(editorAPI, {
            appDefinitionId,
            widgetId,
            compStructure: compDef,
            tags,
            itemId,
            sectionTitle,
            categoryId,
            biOrigin: BI_ORIGIN,
            position,
          });
        }
        case CustomClickAndDropHandler.WixFormToContainer: {
          return addWixFormToContainerOnClick(editorAPI, {
            sectionTitle,
            categoryId,
            presetId,
            structure: compDef,
            position,
          });
        }
        case CustomClickAndDropHandler.WixForm: {
          return addWixFormOnClick(editorAPI, {
            presetId,
            categoryId,
            structure: compDef,
          });
        }
        case CustomClickAndDropHandler.AppointmentField: {
          return addAppointmentFieldOnClick(editorAPI, {
            presetId,
            structure: compDef,
          });
        }
        case CustomClickAndDropHandler.Store: {
          return addWixStoreWidgetOnClick({
            compStructure: compDef,
            itemId,
            styleParams,
            biParams: {
              addingMethod: 'click',
              presetId: itemId,
              category: categoryId,
              section: sectionTitle,
            },
          });
        }
        case CustomClickAndDropHandler.Bookings: {
          return addBookingOnClick(editorAPI, {
            widgetId,
            sectionTitle,
            tags,
            itemId,
          });
        }
        case CustomClickAndDropHandler.BookingButton: {
          return addBookingButtonOnClick(editorAPI, {
            compStructure: compDef,
            itemId,
            tags,
            categoryId,
            sectionTitle,
          });
        }
        case CustomClickAndDropHandler.SiteSearch: {
          addSiteSearch({ appDefinitionId });
          return;
        }
        default: {
          return;
        }
      }
    },
    installAppWithCustomHandlerOnDrop: ({
      customClickAndDropHandler,
      sectionTitle,
      categoryId,
      presetId,
      appDefinitionId,
      widgetId,
      compStructure,
      styleParams,
      item,
    }) => {
      switch (customClickAndDropHandler) {
        case CustomClickAndDropHandler.Events: {
          return addCompOnDrop(editorAPI, {
            appDefinitionId,
            widgetId,
            biOrigin: tpaConstants.BI.type.ADD_WIDGET,
          });
        }
        case CustomClickAndDropHandler.Blog: {
          return addBlogOnDrop(editorAPI, {
            item,
            appDefinitionId,
            widgetId,
            biOrigin: BI_ORIGIN,
          });
        }
        case CustomClickAndDropHandler.Members: {
          return addMembersSuperAppOnDrop(editorAPI, {
            appDefinitionId,
            widgetId,
          });
        }
        case CustomClickAndDropHandler.PricingPlans: {
          return addPricingPlansOnDrop(editorAPI, {
            item,
            appDefinitionId,
            widgetId,
            biOrigin: BI_ORIGIN,
          });
        }
        case CustomClickAndDropHandler.Hotels: {
          return addHotelsOnDrop(editorAPI, {
            item,
            compStructure,
            appDefinitionId,
            widgetId,
            biOrigin: BI_ORIGIN,
          });
        }
        case CustomClickAndDropHandler.ChatButton: {
          return addChatButtonOnDrop(editorAPI);
        }
        case CustomClickAndDropHandler.LiveChat: {
          return addLiveChatOnDrop(editorAPI);
        }
        case CustomClickAndDropHandler.WixFormToContainer: {
          return addWixFormToContainerOnDrop(editorAPI, {
            presetId,
            categoryId,
            sectionTitle,
          });
        }
        case CustomClickAndDropHandler.Blocks: {
          return addBlocksWidgetOnDrop(editorAPI, {
            categoryId,
            sectionTitle,
            appDefinitionId,
            presetId: item.id,
            biOrigin: BI_ORIGIN,
            compStructure: item.structure as CompStructure,
          });
        }
        case CustomClickAndDropHandler.WixForm: {
          return addWixFormOnDrop(editorAPI, { presetId, categoryId });
        }
        case CustomClickAndDropHandler.AppointmentField: {
          return addAppointmentFieldOnDrop(editorAPI, { presetId });
        }
        case CustomClickAndDropHandler.Store: {
          return addWixStoreWidgetOnDrop({
            styleParams,
            biParams: {
              addingMethod: 'drag',
              category: categoryId,
              section: sectionTitle,
              presetId,
            },
          });
        }
        case CustomClickAndDropHandler.Bookings: {
          return addBookingOnDrop(editorAPI);
        }
        case CustomClickAndDropHandler.BookingButton: {
          return addBookingButtonOnDrop(editorAPI);
        }
        default: {
          return;
        }
      }
    },
    installSuperAppOnClick: ({
      appDefinitionId,
      widgetId,
      compStructure,
      tags,
      itemId,
      sectionTitle,
      categoryId,
      biOrigin,
      position,
    }) => {
      return addCompOnClick(editorAPI, {
        appDefinitionId,
        widgetId,
        compStructure,
        tags,
        itemId,
        sectionTitle,
        categoryId,
        biOrigin,
        position,
      });
    },
    installSuperAppOnDrop: ({ appDefinitionId, widgetId, biOrigin }) =>
      addCompOnDrop(editorAPI, {
        appDefinitionId,
        widgetId,
        biOrigin,
      }),
  };
};
