import React from 'react';
import createReactClass from 'create-react-class';
import _ from 'lodash';
import * as core from '@/core';
import { hoc } from '@/util';
import * as platformEvents from 'platformEvents';
import * as stateManagement from '@/stateManagement';
import * as textControls from '@/textControls';
import experiment from 'experiment';
import constants from '@/constants';
// TODO: renamed temporary until se_useWixRichtextWebpackBased is merged
// eslint-disable-next-line @typescript-eslint/prefer-ts-expect-error
// @ts-ignore
import WixRichText from '@wix/wix-richtextCurrent';
import { mapStateToProps, type TextEditorStateProps } from './textEditorMapper';
import { openTextLinkPanel } from './openTextLinkPanel';
import { TextEditorLocation } from '@/constants';
import styles from './textEditor.scss';

import type { TextManager } from 'types/data';
import type { CompRef } from '@wix/document-services-types';
import getStyleManager from './stylesManager';

let isRemovingTextComp = false;

const textActions = stateManagement.text.actions;
const textSelectors = stateManagement.text.selectors;
const isLetterSpacingExperimentOpen = experiment.isOpen(
  'specs.thunderbolt.saveLetterSpacingToTextTheme',
);

export interface TextEditorOwnProps {
  isResizing: boolean;
  textCompId?: string;
  customLayout?: object;
  onFocus?: (comp: CompRef) => void;
  onBlur?: (comp: CompRef) => void;
  onTextEditorReady?: () => void;
  isNotBaseTextEditor?: boolean;
  setLinksHelper?: (linksHelper: typeof textControls.LinksHelper) => void;
  pimple?: JSX.Element;
}

interface TextEditorProps extends TextEditorOwnProps, TextEditorStateProps {}

// eslint-disable-next-line react/prefer-es6-class
const TextEditor = createReactClass<TextEditorProps>({
  displayName: 'textEditor',
  mixins: [core.mixins.editorAPIMixin],
  UNSAFE_componentWillMount() {
    const editorAPI = this.getEditorAPI();
    this.documentServicesApi = {};
    this.linksHelper = new (textControls.LinksHelper as AnyFixMe)([]);
    this.linksManager = new (textControls.TextLinksManager as AnyFixMe)(
      this.linksHelper,
    );
    if (this.props.setLinksHelper) {
      this.props.setLinksHelper(this.linksHelper);
    }
    this.stylesManager = getStyleManager(editorAPI);
  },
  UNSAFE_componentWillReceiveProps(nextProps) {
    // if we initialize rich-text with documentServicesApi at first render - we will make 1k-2k fonts requests
    // so we set ds only if texteditor was opened
    // reproduced on HMR and if viewerReady was fired before first editor render.
    if (nextProps.isOpenTextEditor) {
      // it's required to mutate because documentServicesApi was captured by rich text
      Object.assign(this.documentServicesApi, this.getEditorAPI());
    }
    const textLimitPanelWasClosed =
      this.props.isTextLimitExceededPanelOpen &&
      !nextProps.isTextLimitExceededPanelOpen;

    if (textLimitPanelWasClosed) {
      textControls.ckUtils.setCkReadOnly(this.manager, false);
      this.manager.focus();
    }
  },
  UNSAFE_componentWillUpdate(nextProps) {
    if (
      (this.props.isOpenTextEditor &&
        this.props.selectedTextComp &&
        !_.isEqual(this.props.selectedTextComp, nextProps.selectedTextComp)) ||
      (this.props.isOpenTextEditor && !nextProps.isOpenTextEditor)
    ) {
      this.stopTextEditing();
    }
  },
  componentDidUpdate(prevProps) {
    const textValue = this.getValue().text;
    this._internalTextValue = textValue;
    if (
      this.manager &&
      this.props.selectedTextComp &&
      !prevProps.isOpenTextEditor &&
      this.props.isOpenTextEditor
    ) {
      this.updateTextEditor(textValue);
    }

    if (this.manager && !_.isEqual(this.props.layout, prevProps.layout)) {
      this.manager.resize(this.props.layout.width, this.props.layout.height);
    }
  },
  componentWillUnmount() {
    const editorAPI = this.getEditorAPI();
    this.stopTextEditing();
    editorAPI.text.setCurrentTextEditor(undefined, TextEditorLocation.STAGE);
    editorAPI.store.dispatch(textActions.textEditor.unload());
  },
  updateTextEditor(textValue: string) {
    const editorAPI = this.getEditorAPI();
    this.editedTextComponent = this.props.selectedTextComp;
    editorAPI.store.dispatch(
      textActions.multiselect.setSelectedComponent(this.props.selectedTextComp),
    );
    const value = this.getValue();
    const setDataCallback = () =>
      this.verticalTextTransformIfNeeded(this.editedTextComponent);
    this.manager.refreshPropsCollector();
    this.manager.setData(textValue, {
      validateHtml: false,
      cursorLocation: 'all',
      removeNestedStyles: true,
      callback: setDataCallback,
    });
    this.manager.focus();
    this.manager.resetUndo();
    this.linksHelper.setCurrentLinks(value.linkList);

    if (!this.props.styleOnly) {
      editorAPI.renderPlugins.setHideTextComponent(
        this.props.selectedTextComp.id,
      );
    }

    const uploadedUsedFonts = textControls.fontUtils.getUploadedUsedFonts(
      value.text,
      // eslint-disable-next-line @wix/santa-editor/deprecatedFontsApi
      editorAPI.dsRead.theme.fonts.getAll(),
    );
    textControls.ckUtils.insertUploadedFontsToCk(
      uploadedUsedFonts,
      this.manager,
    );

    if (!this.isPanelOpened(constants.componentPanels.settings)) {
      this.migrateToTextTheme();

      if (this.props.isOpenLinkPanel) {
        openTextLinkPanel(editorAPI, {
          removeOverlay: true,
          shouldCloseParentPanels: true,
          linkUpdate: true,
        });
      } else {
        editorAPI.openComponentPanel(constants.componentPanels.settings, {
          getLinksHelper: this.getLinksHelper,
          selectedComponentType: constants.COMP_TYPES.TEXT,
        });
      }
    }
  },
  getSelectedComponents() {
    const editorAPI = this.getEditorAPI();
    const widgetDesignSelectedComponents =
      textSelectors.getWidgetDesignSelectedComponents(
        editorAPI.store.getState(),
      );
    return (
      widgetDesignSelectedComponents ||
      editorAPI.selection.getSelectedComponents()
    );
  },
  getValue() {
    return this.getEditorAPI().text.getValue(
      this.props.selectedTextComp,
      this.props.layout.scale,
    );
  },
  getInitialValue() {
    return this.getValue().text || '<p></p>';
  },
  setReady(manager: TextManager) {
    const editorAPI = this.getEditorAPI();
    this.manager = manager;
    editorAPI.text.setCurrentTextEditor(manager, TextEditorLocation.STAGE);
    editorAPI.text.setCurrentLinksHelper(this.linksHelper);
    manager.execCommand('wixCss', {
      id: 'overFlowCss',
      value: 'body {overflow: hidden !important;}',
      isLink: false,
      force: false,
    });
    editorAPI.store.dispatch(textActions.textEditor.load(manager));
    this.manager.onAfterUndo(() => {
      const shouldApplyChangesToRepeatedText =
        this.manager.isAllSelected() && this.props.isRepeatedTextComp;
      if (shouldApplyChangesToRepeatedText) {
        editorAPI.history.undo();
      }
      if (this.getSelectedComponents().length > 1) {
        editorAPI.store.dispatch(textActions.multiselect.undo());
      }
    });
    this.manager.onAfterRedo(() => {
      const shouldApplyChangesToRepeatedText =
        this.manager.isAllSelected() && this.props.isRepeatedTextComp;
      if (shouldApplyChangesToRepeatedText) {
        editorAPI.history.redo();
      }
      if (this.getSelectedComponents().length > 1) {
        editorAPI.store.dispatch(textActions.multiselect.redo());
      }
    });
  },
  isPanelOpened(panelName: string): boolean {
    const openedPanels = this.getEditorAPI().panelManager.getOpenPanels();

    return openedPanels.some(
      (panel: AnyFixMe) =>
        _.isEqual(panel.componentRef, this.props.selectedTextComp) &&
        panel.name.endsWith(panelName),
    );
  },
  isExperimentOpen(spec: string) {
    return experiment.isOpen(spec);
  },
  stopTextEditing() {
    /**
     * Fixed for WEED-2074, issue is with the flow of closing the text component, stopTextEditing is being called twice
     * once with the new selected text component, and another time with isOpenTextEditor false - causing it to run stopTextEditing logic
     * on the new text component with links data from the old component.
     * TODO: fix the flow
     */
    if (
      isRemovingTextComp ||
      !_.isEqual(this.editedTextComponent, this.props.selectedTextComp)
    ) {
      return;
    }
    if (this.props.isOpenTextEditor && this.props.selectedTextComp) {
      isRemovingTextComp = true;
      const editorAPI = this.getEditorAPI();
      const self = this;
      this.clearUnusedLinks();
      this.getEditorAPI().renderPlugins.setHideTextComponent(null);
      const textComp = this.props.selectedTextComp;

      editorAPI.dsActions.platform.notifyAppsOnCustomEvent(
        platformEvents.factory.textEditBoxClosed({ compRef: textComp }),
      );

      editorAPI.dsActions.waitForChangesApplied(
        function deleteTextCompIfNeeded() {
          self.deleteTextEditorComponentIfEmpty(textComp);
          isRemovingTextComp = false;
        },
      );
    }
    this.getEditorAPI().text.stopEditing();
  },
  clearUnusedLinks() {
    if (this.manager) {
      const componentLinks = this.linksHelper.getAllLinks();
      const linksInText = this.manager.getLinkRefsList();

      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/filter
      const linkList = _.filter(componentLinks, function (link) {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/includes
        return _.includes(linksInText, link.id);
      });

      const data = {
        text: this._internalTextValue,
        linkList,
      };

      this.getEditorAPI().text.setComponentData(
        this.props.selectedTextComp,
        this.props.layout.scale,
        data,
      );
    }
  },
  verticalTextTransformIfNeeded(selectedCompRef: AnyFixMe) {
    const compProps =
      this.getEditorAPI().components.properties.get(selectedCompRef);
    const verticalText = compProps?.verticalText ?? false;

    this.manager.execCommand('verticalText', verticalText, {
      execWithoutFocus: true,
      execWithoutHistory: true,
    });
  },
  handleDataChange(textHtml: string) {
    const isChanged = !_.isEqual(textHtml, this._internalTextValue);
    if (this.props.selectedTextComp && isChanged) {
      this._internalTextValue = textHtml;
      this.getEditorAPI().text.handleDataChange(
        this.manager,
        this.props.selectedTextComp,
        textHtml,
        this.props.layout.scale,
        this.linksHelper.getAllLinks(),
      );
    }
  },
  deleteTextEditorComponentIfEmpty(textComp: AnyFixMe) {
    const textCompData = this.getEditorAPI().components.data.get(textComp);
    if (textCompData) {
      const html = textCompData.text;
      if (textControls.dataUtils.isEmptyHtmlText(html)) {
        this.getEditorAPI().components.remove(textComp);
      }
    }
  },
  getStyleManager() {
    return this.stylesManager;
  },
  getLinksManager() {
    return this.linksManager;
  },
  getLinksHelper(): typeof textControls.LinksHelper {
    return this.linksHelper;
  },
  onCutOrCopy() {
    this.linksHelper.cacheCopiedLinks();
  },
  getTextEditorStyles() {
    if (!this.props.layout.visibility && !this.manager) {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/assign
      return _.assign(this.props.layout, { visibility: 'hidden' });
    }

    if (this.props.isResizing) {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/assign
      return _.assign(this.props.layout, { pointerEvents: 'none' });
    }

    return this.props.layout;
  },
  migrateToTextTheme() {
    const compRef = this.props.selectedTextComp;
    if (!compRef || !isLetterSpacingExperimentOpen) return;

    const editorAPI = this.getEditorAPI();
    const shouldMigrateToTextTheme =
      textControls.migrationUtils.shouldMigrateToTextTheme(editorAPI, compRef);
    if (shouldMigrateToTextTheme) {
      textControls.migrationUtils.migrateToTextTheme(
        editorAPI,
        compRef,
        editorAPI._stageTextManager,
      );
    }
  },
  render() {
    const textEditorStyles = this.getTextEditorStyles();
    const pimplePosition = {
      top: textEditorStyles.top + textEditorStyles.height,
      left: textEditorStyles.left + textEditorStyles.width,
    };

    return (
      <>
        <div
          style={textEditorStyles}
          className={this.props.isResizing ? 'resizing' : ''}
        >
          <WixRichText.TextEditor
            initialData={this.getInitialValue()}
            onReady={this.setReady}
            validateHtml={false}
            onFocus={this.props.onFocus}
            onBlur={this.props.onBlur}
            showToolbar={false}
            documentServicesApi={this.documentServicesApi}
            isExperimentOpen={this.isExperimentOpen}
            onCut={this.onCutOrCopy}
            onCopy={this.onCutOrCopy}
            stylesManager={this.getStyleManager()}
            onDataChange={this.handleDataChange}
            linksManager={this.getLinksManager()}
            shouldInjectFontsToDocument={false}
          />
        </div>

        {this.props.selectedTextComp && this.props.pimple && (
          <div className={styles.textEditor__pimple} style={pimplePosition}>
            {this.props.pimple}
          </div>
        )}
      </>
    );
  },
});

export default hoc.connect(hoc.STORES.EDITOR_API, mapStateToProps)(TextEditor);
