// @ts-nocheck
import React from 'react';
import PropTypes from 'prop-types';
import * as util from '@/util';
import constants from '@/constants';

import { FocusPanelFrame } from '../../frames';
import TasksProgressSection from './sections/tasksProgressSection';
import FirstSuccessSection from './sections/firstSuccessSection';
import ErrorSection from './sections/errorSection';

function template() {
  return (
    <FocusPanelFrame
      automationId="app-studio-package-and-update-panel"
      panelName={this.props.panelName}
      shouldHideHeader={true}
      shouldNotCloseOnBlur={this.isProgressStep()}
      keyboardShortcuts={this.getKeyboardShortcuts()}
      className={this.props.className}
    >
      {this.isProgressStep() ? (
        <TasksProgressSection
          key="tasksSection"
          tasksDone={this.props.tasksDone}
          taskDisplayName={this.props.taskDisplayName}
          totalNumOfTasks={this.props.totalNumOfTasks}
          title={this.props.tasksTitle}
        />
      ) : null}
      {this.isFirstResultStep() ? (
        <FirstSuccessSection
          key="secondSuccessStep"
          content={this.props.firstResultSectionContent}
          closePanel={this.props.closePanel}
          onHelp={this.props.firstSuccessOnHelp}
          primaryButton={this.props.firstResultPrimaryButton}
          textButton={this.props.firstResultTextButton}
        />
      ) : null}
      {this.isError() ? (
        <ErrorSection
          key="errorSection"
          content={this.props.errorSectionContent}
          primaryButton={this.props.errorSectionPrimaryButton}
          closePanel={this.props.closePanel}
        />
      ) : null}
    </FocusPanelFrame>
  );
}

const { TASKS, FIRST_SUCCESS, ERROR } = constants.PACKAGE_UPDATE_PANEL_STEPS;

class PackageAndUpdatePanel extends React.Component {
  constructor(props) {
    super(props);
    this.isProgressStep = () => this.props.currStep === TASKS;
    this.isFirstResultStep = () => this.props.currStep === FIRST_SUCCESS;
    this.isError = () => this.props.currStep === ERROR;
    this.getKeyboardShortcuts = () => ({
      esc: () => {
        if (!this.isProgressStep()) {
          this.props.closePanel();
        }
      },
    });
  }

  componentDidUpdate() {
    if (this.props.isDone && !this.isFirstResultStep() && !this.isError()) {
      this.props.closePanel();
    }
  }

  render() {
    return template.call(this);
  }
}

PackageAndUpdatePanel.propTypes = {
  className: PropTypes.string,
  closePanel: PropTypes.func.isRequired,
  currStep: PropTypes.string.isRequired,
  errorSectionContent: PropTypes.shape({
    header: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    fixSteps: PropTypes.arrayOf(PropTypes.string),
  }),
  errorSectionPrimaryButton: PropTypes.shape({
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
  }),
  firstResultContent: PropTypes.shape({
    header: PropTypes.string.isRequired,
    headerSubtitle: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(PropTypes.string).isRequired,
  }),
  firstResultPrimaryButton: PropTypes.shape({
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
  }),
  firstResultTextButton: PropTypes.shape({
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
  }),
  firstSuccessOnHelp: PropTypes.func,
  isDone: PropTypes.bool,
  tasksDone: PropTypes.number,
  taskDisplayName: PropTypes.string,
  tasksTitle: PropTypes.string,
  totalNumOfTasks: PropTypes.number,
};

export default util.hoc.renderWhenMutated(PackageAndUpdatePanel);
