import _ from 'lodash';
import {
  browserUtil,
  fedopsLogger,
  fixedStage,
  keyboardShortcuts,
} from '@/util';
import { events } from '@/coreBi';
import { utils as coreUtils } from '@/core';
import constants from '@/constants';
import { silentInstallApps } from '@/platform';
import * as stateManagement from '@/stateManagement';
import { ErrorReporter } from '@wix/editor-error-reporter';
import {
  verifyChosenHomepage,
  adjustHeaderBusinessName,
  adjustHeaderHeight,
  adjustMobileBusinessNameIfNeeded,
  adjustMobileOnAllPages,
  fixEmptyHeaderFooter,
  fixSospPositionIfNeeded,
  addInstalledAppsWidgets,
  getBiScreenResolution,
  inverseFormsComponentsPalette,
  arrangeMenu,
  moveOverlappingHeaderComponentsIfNeeded,
  resetColorationCache,
  sanitizeSiteNameFromBusinessName,
  checkAsyncInstallAppsPromiseStatus,
  verifyFooterPosition,
  attemptSave,
  setFpdAsHomePage,
  setSiteProperties,
  syncAndResetMobileLayout,
  wirePagesBackgrounds,
  addSectionsToMenuIfNeeded,
  getAppsToInstallOptions,
  getAppsToInstallDefIds,
  removeHeaderFreeze,
  fetchBusinessNameDomainSuggestion,
  personalizeApps,
  getSiteCreationSilentInstallOrigin,
} from './siteCreationUtils';
import { MIN_SITE_NAME_LENGTH } from './siteCreationConstants';
import { COLORATION_METHOD_TYPE } from '@/presetApi';
import { SiteGlobalDataApiKey } from '@/apis';
import { migrateColorsAfterSc } from '@/theme';
import experiment from 'experiment';

import type {
  CeType,
  PageAlternative,
  ContentManager,
  IPageDescription,
} from '@/presetApi';
import type { Scope } from '../mainEditorScope';
import type { CompRef } from 'types/documentServices';
import type {
  HeaderFooterContentItemData,
  PageMobileAdjustmentsConfig,
  UserContactInfo,
} from '@/editorPaas';

export interface SiteCreationStateProps {
  windowWidth: number;
  windowHeight: number;
  editorIsInit: boolean;
  businessFirstFlow: boolean;
}

export interface SiteCreationDispatchProps {
  addPagePreset: (
    pageAlternative: PageAlternative,
    pageName: string,
    shouldNavigateToPage: boolean,
    isHomePage: boolean,
    curatedTemplateId: number,
    sectionsCeTypes: CeType[],
    colorationMethod?: COLORATION_METHOD_TYPE,
  ) => Promise<void>;
  setKit: () => Promise<void>;
  getPagePresets: (
    caas: ContentManager,
    page: IPageDescription,
    userContactInfo: UserContactInfo,
    countOrCuratedTemplateIds: number | number[],
    headerFooterContentItemData: HeaderFooterContentItemData,
    isHomepage: boolean,
  ) => Promise<PageAlternative[]>;
  publishSite: () => Promise<void>;
  handleBackToBo: () => void;
  openLiveSite: () => void;
  sendWizardFinishedBi: () => void;
  setLastStepSiteCreationFinished: () => void;
  installChosenApps: () => Promise<void>;
  initEditorPaas: () => Promise<void>;
  disableKeyboardShortcuts: () => void;
  setSiteNameAsBusinessName: () => void;
  fireContentScrolled: (view: string, index: number, mode: string) => void;
  fireViewStartLoading: (view: string) => void;
  fireViewContentLoaded: (view: string, pageCount?: number) => void;
  fireNextButtonClick: (view: string, navButton: string) => void;
  fireAddingHomepage: (
    index: number,
    actionSource: string,
    curatedTemplateId: number,
  ) => void;
  fireAdditionalPageClick: (toggleValue: boolean, pageName: string) => void;
  fireBusinessFirstAction: (action: string) => void;
  getUserContactInfo: () => { [key: string]: string };
  onSiteCreationAllPagesAdded: () => Promise<void>;
  setReplacementsBusinessData: () => Promise<void>;
  setIsSiteCreationUiVisible: () => void;
}

const { hideWizard, setSiteCreationUiFinished, setSiteCreationFullyDone } =
  stateManagement.siteCreation.actions;
const { PAGES, HOMEPAGE } = constants.SITE_CREATION.SCREENS;
const pagesMobileAdjustmentsConfig: PageMobileAdjustmentsConfig[] = [];
let shouldInversePalette = false;

export const mapStateToProps = (scope: Scope): SiteCreationStateProps => {
  const { editorAPI } = scope;
  const editorState = editorAPI.store.getState();
  return {
    windowWidth: window.innerWidth,
    windowHeight: window.innerHeight,
    editorIsInit: editorState.editorIsInit,
    businessFirstFlow: window.siteCreationController.businessFirstFlow,
  };
};

export const mapDispatchToProps = (scope: Scope): SiteCreationDispatchProps => {
  const { editorPaasApi, editorAPI } = scope;
  const {
    industryId,
    structureId,
    businessCardData,
    screensLoadingTimes,
    appsNamesToInstall,
  } = window.siteCreationController;

  return {
    addPagePreset: async (
      pageAlternative: PageAlternative,
      pageName: string,
      shouldNavigateToPage: boolean,
      isHomePage: boolean,
      curatedTemplateId: number,
      sectionsCeTypes: CeType[],
      colorationMethod: COLORATION_METHOD_TYPE,
    ) => {
      try {
        if (isHomePage) {
          window.siteCreationController.curatedTemplateId = curatedTemplateId;
          window.siteCreationController.setOriginalTemplateHomepageId(
            editorAPI.pages.getFocusedPageId(),
          );
          if (colorationMethod === COLORATION_METHOD_TYPE.dark) {
            shouldInversePalette = true;
          }
        }
        const interactionName = isHomePage
          ? fedopsLogger.INTERACTIONS.SITE_CREATION.ADD_HOMEPAGE
          : fedopsLogger.INTERACTIONS.SITE_CREATION.ADD_ADDITIONAL_PAGE;
        fedopsLogger.interactionStarted(interactionName);
        const pageModel = await editorPaasApi.addPagePreset(
          pageAlternative,
          isHomePage,
          pageName,
          shouldNavigateToPage,
          sectionsCeTypes,
          shouldInversePalette,
        );
        const pageRef = pageModel.pageRef as CompRef;
        pagesMobileAdjustmentsConfig.push({
          pageName,
          pageModel,
          shouldRunOnHeaderFooter: isHomePage,
          shouldInversePalette,
          curatedTemplateId,
        });
        fedopsLogger.interactionEnded(interactionName);
        if (isHomePage) {
          window.siteCreationController.setChosenHomepageId(pageRef.id);
          await setFpdAsHomePage(editorAPI, pageRef.id);
          if (shouldInversePalette) {
            editorPaasApi.inverseSitePaletteByPaasEntries();
          }
          await adjustHeaderHeight(editorAPI);
          fixEmptyHeaderFooter(editorAPI); // no need to wait for it here
        }
        if (shouldInversePalette) {
          inverseFormsComponentsPalette(editorAPI, pageRef);
        }
      } catch (e) {
        console.error('addPagePreset failed', e);
        console.log('failure in curatedTemplateId', curatedTemplateId);
        const flow = isHomePage ? 'addHomepagePreset' : 'addPreset';
        editorAPI.bi.event(events.siteCreation.ERROR_LOADING_CONTENT, {
          flow,
          industryId,
          structureId,
          curatedTemplateId,
          appsToInstall: appsNamesToInstall,
        });
        ErrorReporter.captureException(e, {
          tags: { siteCreationAddPagePreset: true, siteCreationFlow: true },
          extra: {
            flow,
            industryId,
            structureId,
            curatedTemplateId,
            appsToInstall: appsNamesToInstall,
          },
        });
      }
    },
    getPagePresets: async (
      caas: ContentManager,
      page: IPageDescription,
      userContactInfo: UserContactInfo,
      countOrCuratedTemplateIds: number | number[],
      headerFooterContentItemData: HeaderFooterContentItemData,
      isHomepage: boolean,
    ): Promise<PageAlternative[]> => {
      let pagePresets: PageAlternative[] = [];
      try {
        fedopsLogger.interactionStarted(
          fedopsLogger.INTERACTIONS.SITE_CREATION.GET_PAGE_PRESETS,
        );
        pagePresets = await editorPaasApi.getPagePresets(
          caas,
          page,
          userContactInfo,
          countOrCuratedTemplateIds,
          headerFooterContentItemData,
        );
        fedopsLogger.interactionEnded(
          fedopsLogger.INTERACTIONS.SITE_CREATION.GET_PAGE_PRESETS,
        );
      } catch (e) {
        if (isHomepage) {
          console.error('Site creation get homepage presets failed');
        }
        console.error('Error getPagePresets: ', e);
        const curatedTemplateId = isHomepage ? null : countOrCuratedTemplateIds;
        const flow = isHomepage
          ? 'fetchHomepagePresets'
          : 'fetchAdditionalPagesPresets';
        editorAPI.bi.event(events.siteCreation.ERROR_LOADING_CONTENT, {
          flow,
          industryId,
          structureId,
          curatedTemplateId,
          appsToInstall: appsNamesToInstall,
        });
        ErrorReporter.captureException(e, {
          tags: { siteCreationGetPagePresets: true, siteCreationFlow: true },
          extra: {
            flow,
            industryId,
            structureId,
            curatedTemplateId,
            appsToInstall: appsNamesToInstall,
          },
        });
      }
      return pagePresets;
    },
    sendWizardFinishedBi: () => {
      editorAPI.bi.event(events.siteCreation.WIZARD_FINISHED, {
        view: PAGES,
        duration: Math.round(
          performance.now() - screensLoadingTimes.businessCard.loadTime,
        ),
        screenResolution: getBiScreenResolution(),
      });
    },
    setLastStepSiteCreationFinished: async () => {
      if (
        !editorAPI.zoomMode.isInZoomMode() &&
        !fixedStage.isFixedStageEnabled()
      ) {
        editorAPI.zoomMode.enterZoomMode({
          biParams: {
            origin: 'openEditor',
            is_section: true,
            is_site_creation: true,
          },
          animationDuration: 0,
        });
      }
      editorAPI.store.dispatch(hideWizard());
      const installTimeout = await window.siteCreationController
        .installAppsPromiseOrTimeout;
      editorAPI.bi.event(
        events.siteCreation.EDITOR_FULLY_INTERACTIVE_AFTER_WIZARD,
        {
          appsToInstall: appsNamesToInstall,
          timeout: _.isNumber(installTimeout) ? installTimeout : null,
          pageId: editorAPI.pages.getFocusedPageId(),
          screenResolution: getBiScreenResolution(),
          device: browserUtil.getDeviceType(),
          screen_orientation: browserUtil.getScreenOrientation(),
          touch_supported: browserUtil.isTouchSupported(),
        },
      );
      editorAPI.store.dispatch(setSiteCreationUiFinished());
      editorAPI.panelManager.closeAllPanels();
      editorAPI.history.clear();
    },
    initEditorPaas: async () => {
      try {
        fedopsLogger.interactionStarted(
          fedopsLogger.INTERACTIONS.SITE_CREATION.INIT_PRESET_API,
        );
        await editorPaasApi.init();
        fedopsLogger.interactionEnded(
          fedopsLogger.INTERACTIONS.SITE_CREATION.INIT_PRESET_API,
        );
      } catch (e) {
        editorAPI.bi.event(events.siteCreation.ERROR_LOADING_CONTENT, {
          flow: 'initPresets',
          industryId,
          structureId,
          appsToInstall: appsNamesToInstall,
        });
        ErrorReporter.captureException(e, {
          tags: { siteCreationInitPresetApi: true, siteCreationFlow: true },
          extra: {
            flow: 'initPresets',
            industryId,
            structureId,
            appsToInstall: appsNamesToInstall,
          },
        });
      }
      window.siteCreationController.resolveInitEditorPaasPromise();
    },
    installChosenApps: async () => {
      const appsToInstall = getAppsToInstallDefIds(editorAPI);
      if (appsToInstall.length === 0) {
        return;
      }
      window.siteCreationController.silentInstallAppDefIds = appsToInstall;
      try {
        fedopsLogger.interactionStarted(
          fedopsLogger.INTERACTIONS.SITE_CREATION.INSTALL_CHOSEN_APPS,
          {
            customParams: {
              appsToInstall,
            },
          },
        );
        await silentInstallApps(editorAPI, appsToInstall, {
          disableHistoryRevert: true,
          appsOptions: getAppsToInstallOptions(),
          installOrigin: getSiteCreationSilentInstallOrigin(),
        });
        fedopsLogger.interactionEnded(
          fedopsLogger.INTERACTIONS.SITE_CREATION.INSTALL_CHOSEN_APPS,
          {
            customParams: {
              appsToInstall,
            },
          },
        );
      } catch (e) {
        const err = e || new Error('Apps installation flow error.');
        ErrorReporter.captureException(err, {
          tags: { siteCreationFailToInstallApps: true, siteCreationFlow: true },
          extra: {
            industryId,
            structureId,
            flow: 'appsInstallation',
            appsToInstall: appsNamesToInstall,
          },
        });
        console.error(e);
      }
    },
    setSiteNameAsBusinessName: () => {
      const { businessName } = businessCardData;
      if (!businessName) {
        return;
      }
      const sanitizedBusinessName =
        sanitizeSiteNameFromBusinessName(businessName);
      if (sanitizedBusinessName.length < MIN_SITE_NAME_LENGTH) {
        return;
      }
      fedopsLogger.interactionStarted(
        fedopsLogger.INTERACTIONS.SITE_CREATION.SET_SITE_NAME,
      );
      editorAPI.siteName.setAsync(
        sanitizedBusinessName,
        () => {
          fedopsLogger.interactionEnded(
            fedopsLogger.INTERACTIONS.SITE_CREATION.SET_SITE_NAME,
          );
        },
        (result: any) => {
          console.error('Error setting siteName as businessName: ', result);
        },
      );
    },
    fireViewStartLoading: (view: string) => {
      if (view === HOMEPAGE) {
        screensLoadingTimes.homepage.setLoaded();
      } else if (view === PAGES) {
        screensLoadingTimes.pages.setLoaded();
      }
      editorAPI.bi.event(events.siteCreation.SCREEN_LOADING, {
        view,
        screenResolution: getBiScreenResolution(),
      });
    },
    fireViewContentLoaded: (view: string, pageCount?: number) => {
      let loadTime: number = null;
      let durationToAction: number = null;
      if (view === HOMEPAGE) {
        screensLoadingTimes.homepage.setContentLoaded();
        loadTime =
          screensLoadingTimes.homepage.contentLoadedTime -
          screensLoadingTimes.homepage.loadTime;
        durationToAction =
          screensLoadingTimes.homepage.contentLoadedTime -
          screensLoadingTimes.kits.contentLoadedTime;
      } else if (view === PAGES) {
        screensLoadingTimes.pages.setContentLoaded();
        loadTime =
          screensLoadingTimes.pages.contentLoadedTime -
          screensLoadingTimes.pages.loadTime;
        durationToAction =
          screensLoadingTimes.pages.contentLoadedTime -
          screensLoadingTimes.homepage.contentLoadedTime;
      }
      editorAPI.bi.event(events.siteCreation.PAGE_LOADING_SUCCESS, {
        view,
        loadTime,
        screenResolution: getBiScreenResolution(),
        durationToAction,
        pageCount,
      });
    },
    fireNextButtonClick: (view: string, navButton: string) => {
      editorAPI.bi.event(events.siteCreation.NAVIGATION_CLICK, {
        view,
        navButton,
        screenResolution: getBiScreenResolution(),
      });
    },
    fireContentScrolled: (view: string, index: number, mode: string) => {
      editorAPI.bi.event(events.siteCreation.THUMBNAIL_SCROLL, {
        view,
        mode,
        position: index + 1,
        screenResolution: getBiScreenResolution(),
      });
    },
    fireAddingHomepage: (
      index: number,
      actionSource: string,
      curatedTemplateId: number,
    ) => {
      editorAPI.bi.event(events.siteCreation.ADDING_HOMEPAGE, {
        actionSource,
        curatedTemplateId,
        position: index + 1,
        screenResolution: getBiScreenResolution(),
      });
    },
    fireAdditionalPageClick: (toggleValue: boolean, pageName: string) => {
      editorAPI.bi.event(events.siteCreation.ADDITIONAL_PAGE_CLICK, {
        action: toggleValue ? 'add' : 'remove',
        pageName,
        screenResolution: getBiScreenResolution(),
      });
    },
    fireBusinessFirstAction: (action: string) => {
      editorAPI.bi.event(
        events.siteCreation.SITE_CREATION_BUSINESS_FIRST_ACTION,
        { action },
      );
    },
    disableKeyboardShortcuts: () => {
      keyboardShortcuts.disable();
    },
    setKit: async () => {
      await window.siteCreationController.getChooseKitScreenPromise();
      try {
        fedopsLogger.interactionStarted(
          fedopsLogger.INTERACTIONS.SITE_CREATION.SET_KIT,
        );
        await window.siteCreationController.setKit();
        fedopsLogger.interactionEnded(
          fedopsLogger.INTERACTIONS.SITE_CREATION.SET_KIT,
        );
        await editorAPI.waitForChangesAppliedAsync();
      } catch (e) {
        console.error('setKit error', e);
        const flow = 'setKit';
        editorAPI.bi.event(events.siteCreation.ERROR_LOADING_CONTENT, {
          flow,
          industryId,
          structureId,
          appsToInstall: appsNamesToInstall,
        });
        ErrorReporter.captureException(e, {
          tags: { siteCreationSetKit: true, siteCreationFlow: true },
          extra: {
            flow,
            industryId,
            structureId,
            appsToInstall: appsNamesToInstall,
          },
        });
      }
      try {
        await editorPaasApi.inverseSiteFontThemeByPaasEntries();
      } catch (e) {
        console.error('inverseSiteFontThemeByPaasEntries error:', e);
        ErrorReporter.captureException(e, {
          tags: {
            siteCreationFlow: true,
            siteCreationInverseSiteFontTheme: true,
          },
          extra: {
            industryId,
            structureId,
            flow: 'inverseSiteFontTheme',
          },
        });
      }
    },
    getUserContactInfo: () =>
      window.siteCreationController.getUserContactInfo(),
    onSiteCreationAllPagesAdded: async () => {
      await window.siteCreationController.installAppsPromiseOrTimeout;
      await verifyChosenHomepage(
        editorAPI,
        window.siteCreationController.originalTemplateHomepageId,
        window.siteCreationController.chosenHomepageId,
      );
      arrangeMenu(editorAPI, pagesMobileAdjustmentsConfig);
      fetchBusinessNameDomainSuggestion(
        editorAPI,
        businessCardData.businessName,
      );
      setSiteProperties(editorAPI);
      checkAsyncInstallAppsPromiseStatus(editorAPI, appsNamesToInstall);
      verifyFooterPosition(editorAPI, pagesMobileAdjustmentsConfig);
      await wirePagesBackgrounds(editorAPI, pagesMobileAdjustmentsConfig);
      await migrateColorsAfterSc(editorAPI);
      const siteNameComp = editorPaasApi.getPaasSiteNameComp();
      moveOverlappingHeaderComponentsIfNeeded(editorAPI, siteNameComp);
      removeHeaderFreeze(editorAPI);
      await personalizeApps(editorAPI);
      await addInstalledAppsWidgets(editorAPI, pagesMobileAdjustmentsConfig);
      await adjustHeaderBusinessName(editorAPI, siteNameComp);
      await fixSospPositionIfNeeded(editorAPI);
      await resetColorationCache();
      if (experiment.isOpen('se_siteCompletionSourceData')) {
        coreUtils.componentSourceFeatureUtils.updateAllChildrenSourceData(
          editorAPI,
          {
            source: 'caas',
            changedOverride: false,
          },
        );
      }
      addSectionsToMenuIfNeeded(editorAPI);
      keyboardShortcuts.enable();
      await syncAndResetMobileLayout(editorAPI);
      await attemptSave(editorAPI);
      await adjustMobileOnAllPages(editorPaasApi, pagesMobileAdjustmentsConfig);
      await adjustMobileBusinessNameIfNeeded(editorAPI, editorPaasApi);
      editorAPI.history.clear();
      await attemptSave(editorAPI);
      editorAPI.store.dispatch(setSiteCreationFullyDone());
      window.siteCreationController.resolveIsEditorFullyLoaded();
      editorAPI.bi.event(events.siteCreation.SITE_CREATION_PROCESS_FINISHED, {
        revisionId: editorAPI.dsRead.generalInfo.getRevision(),
      });
    },
    publishSite: async () => {
      await editorAPI.publishManager.publish({
        origin: 'siteCreation',
        publish_type: 'auto_publish',
        isAutoPublish: true,
      });
      editorAPI.panelManager.closeAllPanels();
    },
    openLiveSite: () => {
      const sitePublicUrl = editorAPI.dsRead.generalInfo.getPublicUrl();
      window.open(sitePublicUrl, '_blank');
    },
    handleBackToBo: async () => {
      if (!fixedStage.isFixedStageEnabled()) {
        editorAPI.zoomMode.setShouldOpenInZoomFromLocalStorage(true);
      }
      editorAPI.account.manage(true);
    },
    setReplacementsBusinessData: async () => {
      const siteGlobalDataAPI = editorAPI.host.getAPI(SiteGlobalDataApiKey);
      await siteGlobalDataAPI.getIsSiteGlobalDataReadyPromise();
      const { displayName } = siteGlobalDataAPI.getBusinessType() || {};
      window.siteCreationController.setReplacementsBusinessData(displayName);
    },
    setIsSiteCreationUiVisible: () => {
      window.siteCreationController?.hooks.isSiteCreationUiVisible.resolve();
    },
  };
};
