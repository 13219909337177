import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import _ from 'lodash';
import { Preloader, Composites } from '@wix/wix-base-ui';
import { useTranslation } from 'react-i18next';
import { cx, hoc, hooks, sections as sectionsUtils } from '@/util';
import { CloudBoldSmall } from '@wix/wix-ui-icons-common/classic-editor';
import { SectionName } from '../SectionName/SectionName';
import { AUTOMATION_IDS, ButtonAlignment } from './constants';
import { SectionEmptyState } from './EmptyState/SectionEmptyState';
import { ResizeHandle } from '../ResizeHandle/ResizeHandle';
import { StageActions } from '../StageActions/StageActions';
import { useUXResourceRestrictions } from '@/editorRestrictions';
import { AddSectionButton } from '../AddSectionButton/AddSectionButton';
import experiment from 'experiment';

import type {
  SectionBoxDispatchProps,
  SectionBoxStateProps,
} from './SectionBox.mapper';
import { mapDispatchToProps, mapStateToProps } from './SectionBox.mapper';
import {
  shouldShowHoveredSectionBoxSel,
  shouldShowSelectedSectionBoxSel,
} from './selectors';
import styles from './SectionBox.scss';

export enum SectionBoxType {
  Hovered = 'Hovered',
  Selected = 'Selected',
}

const {
  connect,
  STORES: { MOUSE_OPS },
  withConditionalRender,
} = hoc;

const { useClickPreventionOnDoubleClick } = hooks;

const SECTION_PADDING_FOR_MOUSE = 60;
const UPDATE_MOUSE_POSITION_DELAY = 100;

export interface SectionBoxOwnProps {
  type: SectionBoxType;
}

interface SectionBoxProps
  extends SectionBoxOwnProps,
    SectionBoxStateProps,
    SectionBoxDispatchProps {}

const useIsMountedCheck = () => {
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  return useCallback(() => isMounted.current, []);
};

const stopEvent = (event: React.MouseEvent) => {
  event.preventDefault();
  event.stopPropagation();
};

const SectionBoxComponent: React.FC<SectionBoxProps> = ({
  canSelectSection,
  isSectionBothSelectedAndHovered,
  isMobileEditor,
  corvidName,
  isConnectedToDataBinding,
  type,
  sectionRef,
  sectionRect,
  sectionType,
  hasSectionAbove,
  isResizing,
  isDragging,
  isFocused,
  isMultilingual,
  isStageZoomOut,
  isLeftShrinkedStageZoomOut,
  shouldShowEmptyState,
  siteScale,
  stageXOffset,
  shouldDisplayResizeHandle,
  shouldHideLabels,
  openAddPanel,
  isLandingPage,
  openAddSectionPanel,
  logButtonBi,
  logEmptyStateShownBi,
  logEmptyStateButtonClick,
  selectComponent,
  shouldDisplayPreLoader,
  isInSwitchLayoutMode,
  getMouseY,
  isOverlayVisible,
  areContainerPointerEventsEnabled,
  stageActionsSkin,
  resizeHandleSkin,
  addSectionButtonSkin,
  clearHoveredSection,
  openQuickEditIfNeeded,
  getCenteredContainerStyle,
  labelOffsetRight,
  isAddSectionButtonOverlapsComponent,
}) => {
  const [isAddSectionButtonVisible] = useUXResourceRestrictions([
    'sections_add-section-button.visible',
  ]);
  const checkIsMounted = useIsMountedCheck();
  const [translate] = useTranslation();

  const [mouseY, setMouseY] = useState(0);

  // memo not callback because of lint
  const updateMousePosition = useMemo(() => {
    return _.throttle(() => {
      if (!checkIsMounted()) return;
      const y = getMouseY() / siteScale;
      setMouseY(y);
    }, UPDATE_MOUSE_POSITION_DELAY);
  }, [getMouseY, siteScale, checkIsMounted]);

  useEffect(() => {
    if (type === SectionBoxType.Selected) return;
    window.addEventListener('mousemove', updateMousePosition);

    return () => {
      window.removeEventListener('mousemove', updateMousePosition);
    };
  }, [updateMousePosition, type]);

  const isSectionSelected = type === SectionBoxType.Selected;
  const isSectionHovered = type === SectionBoxType.Hovered;

  const buttonAlignment: ButtonAlignment = useMemo(() => {
    if (sectionType === sectionsUtils.constants.SectionTypes.Footer) {
      return ButtonAlignment.TOP;
    }
    if (sectionType === sectionsUtils.constants.SectionTypes.Header) {
      return ButtonAlignment.BOTTOM;
    }

    return !isResizing && mouseY - sectionRect.top < sectionRect.bottom - mouseY
      ? ButtonAlignment.TOP
      : ButtonAlignment.BOTTOM;
  }, [mouseY, sectionRect, sectionType, isResizing]);

  const handleAddSectionClick = useCallback(
    (event: React.MouseEvent) => {
      stopEvent(event);
      openAddSectionPanel(sectionRef, buttonAlignment, 'add-section-button');
      logButtonBi('click');
    },
    [sectionRef, buttonAlignment, logButtonBi, openAddSectionPanel],
  );

  const handleAddSectionEmptyStateClick = useCallback(
    (event: React.MouseEvent) => {
      stopEvent(event);
      clearHoveredSection();
      openAddSectionPanel(sectionRef, buttonAlignment, 'empty-state-link');
      logEmptyStateButtonClick('add-section');
    },
    [
      sectionRef,
      buttonAlignment,
      logEmptyStateButtonClick,
      openAddSectionPanel,
      clearHoveredSection,
    ],
  );

  const handleAddElementsClick = useCallback(
    (event: React.MouseEvent) => {
      stopEvent(event);
      selectComponent(sectionRef);
      openAddPanel();
      logButtonBi('click');
      logEmptyStateButtonClick('add-element');
    },
    [
      openAddPanel,
      logButtonBi,
      logEmptyStateButtonClick,
      selectComponent,
      sectionRef,
    ],
  );

  const shouldHideButtonsWhenOldDesign =
    isMobileEditor || isLeftShrinkedStageZoomOut || isInSwitchLayoutMode;

  const shouldHideButtons =
    !addSectionButtonSkin ||
    !isAddSectionButtonVisible ||
    (sectionsUtils.isSectionsControlsRedesignEnabled()
      ? false
      : shouldHideButtonsWhenOldDesign) ||
    isResizing ||
    isDragging ||
    isMultilingual ||
    isSectionSelected ||
    (sectionRect.top + SECTION_PADDING_FOR_MOUSE < mouseY &&
      sectionRect.bottom - SECTION_PADDING_FOR_MOUSE > mouseY) ||
    isAddSectionButtonOverlapsComponent(buttonAlignment, sectionRef);

  useEffect(() => {
    if (!shouldShowEmptyState || isSectionSelected) return;
    logEmptyStateShownBi();
  }, [shouldShowEmptyState, isSectionSelected, logEmptyStateShownBi]);

  const handleMouseDown = (event: React.MouseEvent) => {
    if (canSelectSection && !isSectionSelected) {
      stopEvent(event);
      selectComponent(sectionRef);
    }
  };

  const handleOnClick = () => {
    if (
      isStageZoomOut &&
      experiment.isOpen('se_openQuickEditOnZoomStageClick')
    ) {
      openQuickEditIfNeeded('zoomStageClick');
    }
  };

  const [handleClick, handleDoubleClick] = useClickPreventionOnDoubleClick(
    handleOnClick,
    () => {},
  );

  const isTopMostSection = isLandingPage && !hasSectionAbove;

  return (
    <div
      data-hook={`section-box-${type}`}
      data-overlay={isOverlayVisible}
      data-comp-id={sectionRef.id}
      data-focused={isFocused}
      className={cx(styles.container, {
        [styles.selected]:
          (isSectionSelected && !isFocused) || !canSelectSection,
        [styles.hovered]: isSectionHovered,
        [styles.mouseOpsInProgress]: isDragging || isResizing,
        [styles.zoomMode]: isStageZoomOut || areContainerPointerEventsEnabled,
        [styles.bothSelectedAndHovered]: isSectionBothSelectedAndHovered,
        [styles.topMostSection]: isTopMostSection,
        [styles.overlay]: isOverlayVisible,
      })}
      style={{
        left: stageXOffset,
        width: sectionRect.width * siteScale,
        top: sectionRect.top * siteScale,
        height: sectionRect.height * siteScale,
      }}
      onMouseDown={handleMouseDown}
      onClick={handleClick}
      onDoubleClick={handleDoubleClick}
    >
      {shouldHideLabels ? null : (
        <div
          className={styles.labels}
          onMouseUp={stopEvent}
          onMouseDown={stopEvent}
          onMouseMove={stopEvent}
          style={{ right: labelOffsetRight }}
        >
          {corvidName && (
            <div className={styles.corvidName}>
              <span className={styles.corvidNameLabel}>{`#${corvidName}`}</span>
              {isConnectedToDataBinding && (
                <span className={styles.cloud}>
                  <CloudBoldSmall />
                </span>
              )}
            </div>
          )}
          <SectionName className={styles.name} compRef={sectionRef} />
        </div>
      )}
      {shouldShowEmptyState && (
        <SectionEmptyState
          section={sectionRef}
          onAddElementsClick={handleAddElementsClick}
          onAddSectionClick={handleAddSectionEmptyStateClick}
        />
      )}
      {!shouldHideButtons && (
        <div
          className={cx(styles.addSectionButtonContainer, {
            [styles.positionTop]: buttonAlignment === ButtonAlignment.TOP,
            [styles.positionBottom]: buttonAlignment === ButtonAlignment.BOTTOM,
          })}
          data-hook={AUTOMATION_IDS.BUTTON_CONTAINER}
          data-skin={addSectionButtonSkin}
          onMouseUp={stopEvent}
          onMouseDown={stopEvent}
          onMouseMove={stopEvent}
          onMouseEnter={() => {
            logButtonBi('hover');
          }}
          style={getCenteredContainerStyle(0.5, 'auto')}
        >
          <AddSectionButton
            skin={addSectionButtonSkin}
            sharpBorderTop={
              isTopMostSection && buttonAlignment === ButtonAlignment.TOP
            }
            onClick={handleAddSectionClick}
          />
        </div>
      )}
      {shouldDisplayResizeHandle && (
        <div
          data-hook={AUTOMATION_IDS.RESIZE_HANDLE_CONTAINER}
          className={cx(styles.resizeHandleContainer, {
            [styles.mobileEditor]: isMobileEditor,
          })}
          style={getCenteredContainerStyle(0.7, '70%')}
        >
          <ResizeHandle
            skin={resizeHandleSkin}
            compRef={sectionRef}
            tooltipText={translate(
              'section_onstage_adjust_section_height_tooltip',
            )}
          />
        </div>
      )}
      {stageActionsSkin && (
        <StageActions compRef={sectionRef} skin={stageActionsSkin} />
      )}

      {shouldDisplayPreLoader && (
        <Composites.Preloader className={styles.preloader}>
          <Preloader />
        </Composites.Preloader>
      )}
      <div className={styles.frame} />
    </div>
  );
};

const ConnectedSectionBox = connect(
  MOUSE_OPS,
  mapStateToProps,
  mapDispatchToProps,
)(SectionBoxComponent);

const ConnectedSelectedSectionBox = withConditionalRender(
  MOUSE_OPS,
  shouldShowSelectedSectionBoxSel,
)(ConnectedSectionBox);

const ConnectedHoveredSectionBox = withConditionalRender(
  MOUSE_OPS,
  shouldShowHoveredSectionBoxSel,
)(ConnectedSectionBox);

export const HoveredSectionBox = () => (
  <ConnectedHoveredSectionBox type={SectionBoxType.Hovered} />
);

export const SelectedSectionBox = () => (
  <ConnectedSelectedSectionBox type={SectionBoxType.Selected} />
);

HoveredSectionBox.displayName = 'HoveredSectionBox';
SelectedSectionBox.displayName = 'SelectedSectionBox';
