// @ts-nocheck
import experiment from 'experiment';
import offeringListWidget from './offeringListWidget/offeringListWidget';
import timetableWidget from './timetableWidget/timetableWidget';
import bookButtonWidget from './bookButtonWidget/bookButtonWidget';
import singleServiceWidget from './singleServiceWidget/singleServiceWidget';
import staffListWidget from './staffListWidget/staffListWidget';
import weeklyTimetableWidget from './weeklyTimetableWidget/weeklyTimetableWidget';
import dailyAgendaWidget from './dailyAgendaWidget/dailyAgendaWidget';
import calendarWidget from './calendarWidget/calendarWidget';
import { shoppingBagWidget } from '@/wixStore';
import * as stateManagement from '@/stateManagement';

export default ({ editorAPI }) => {
  return function () {
    const sections = [
      shoppingBagWidget,
      singleServiceWidget({ editorAPI }),
      offeringListWidget({ editorAPI }),
      bookButtonWidget({ editorAPI }),
      weeklyTimetableWidget({ editorAPI }),
      ...(experiment.isOpen('se_bookingsAddDailyAgendaWidget')
        ? [dailyAgendaWidget({ editorAPI })]
        : []),
      ...(experiment.isOpen('se_bookingsAddCalendarWidget')
        ? [calendarWidget({ editorAPI })]
        : []),
    ];
    const isRefComponentsSupported =
      stateManagement.platform.selectors.isRefComponentSupported(editorAPI);
    if (isRefComponentsSupported) {
      sections.push(timetableWidget({ editorAPI }));
      sections.push(staffListWidget({ editorAPI }));
    }
    return sections;
  };
};
