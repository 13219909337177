import _ from 'lodash';
import { EditorAPIKey, ComponentsApiKey } from '@/apis';
import * as util from '@/util';
import * as stateManagement from '@/stateManagement';

import type { Shell } from '@/apilib';
import type { CompRef } from 'types/documentServices';
import type { BiEventDefinition, BiEventFields } from 'types/bi';
import experiment from 'experiment';

export interface CompCommonBiParams {
  component_id: string;
  component_type: string;
  parent_component_id: string;
  parent_component_type: string;
  non_page_top_parent_component_id: string;
  non_page_top_parent_component_type: string;
  is_last: boolean;
}

const componentsSelectors = stateManagement.components.selectors;

export const createBiApi = (shell: Shell) => {
  const editorAPI = shell.getAPI(EditorAPIKey);

  const componentsApi = shell.getAPI(ComponentsApiKey);

  function initBI() {
    util.biLoggerUpdateDefaults?.(util.bi.getBILoggerDSParams(editorAPI));
  }

  function getRefComponentBIParams(componentRef: CompRef) {
    const compDataItem = componentsApi.data.get(componentRef);
    if (compDataItem) {
      return {
        app_id: `${compDataItem.appDefinitionId}`,
        widget_id: `${compDataItem.widgetId}`,
      };
    }
    return {};
  }

  function getReferredComponentBIParams(componentRef: CompRef) {
    const rootRefHostCompPointer =
      editorAPI.components.refComponents.getRootRefHostCompPointer(
        componentRef,
      );
    const compDataItem = componentsApi.data.get(rootRefHostCompPointer);
    if (compDataItem) {
      return {
        app_id: `${compDataItem.appDefinitionId}`,
        widget_id: `${compDataItem.widgetId}`,
      };
    }
    return {};
  }

  function getAppWidgetComponentBIParams(componentRef: CompRef) {
    if (componentRef) {
      const applicationId = componentsApi.data.get(componentRef)?.applicationId;
      const devCenterWidgetId =
        editorAPI.dsRead.platform.controllers.settings.getIn(
          componentRef,
          'devCenterWidgetId',
        );
      if (applicationId) {
        return {
          app_id: `${applicationId}`,
          widget_id: `${devCenterWidgetId}`,
        };
      }
    }
    return {};
  }

  function reportBI(
    event: AnyFixMe,
    biParamArray: AnyFixMe,
    fireCondition?: AnyFixMe,
  ) {
    if (!fireCondition || (_.isFunction(fireCondition) && fireCondition())) {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
      _.forEach(
        biParamArray,
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/bind
        _.bind(function (biParam) {
          const biParams = _.isFunction(biParam) ? biParam() : biParam;
          biParams.is_last = biParams.is_last || biParams === editorAPI;
          editorAPI.bi.event(event, biParams);
        }, biParamArray[biParamArray.length - 1]),
      );
    } else if (_.isFunction(fireCondition)) {
      editorAPI.biConditionedEventsQueue.push({
        event,
        biParamArray,
        condition: fireCondition,
      });
    }
  }

  function tryFlushingConditionedEvents() {
    if (_.isEmpty(editorAPI.biConditionedEventsQueue)) {
      return;
    }

    const eventsToFire = _.remove(
      editorAPI.biConditionedEventsQueue,
      function (conditionedBIEvent) {
        return (
          _.isFunction(conditionedBIEvent.condition) &&
          conditionedBIEvent.condition()
        );
      },
    );

    eventsToFire.forEach(function (conditionedEvent) {
      reportBI(conditionedEvent.event, conditionedEvent.biParamArray);
    });
  }

  function logBiEvent(
    biEvent: BiEventDefinition,
    biParamsParams?: BiEventFields,
  ) {
    util.bi.biLoggerLegacy.event(biEvent, biParamsParams);
  }

  function getComponentsBIParams<T extends CompCommonBiParams>(
    componentsRefs: CompRef[],
  ): T[] {
    const getTopLevelNoNPageAncestorId = (
      compRef: CompRef,
    ): CompRef | undefined => {
      const nonPageAncestors = editorAPI.components
        .getAncestors_DEPRECATED_BAD_PERFORMANCE(compRef)
        .filter((comp) => !editorAPI.components.is.page(comp));

      return nonPageAncestors.slice(-1)[0];
    };

    return util.array.asArray(componentsRefs).map(function (componentRef) {
      const biParams: any = {}; //TODO
      if (componentRef) {
        const compType = componentsApi.getType(componentRef);
        const currentPage = editorAPI.pages.getCurrentPage();
        const componentsOfCompType = componentsApi.get.byType(
          compType,
          currentPage,
        );

        const parent =
          editorAPI.components.getContainerOrScopeOwner(componentRef);
        const parentType = editorAPI.components.getType(parent);
        const topLevelAncestor = getTopLevelNoNPageAncestorId(componentRef);
        const topLeventAncestorType =
          editorAPI.components.getType(topLevelAncestor);

        biParams.component_id = componentRef.id;
        biParams.component_type = compType;
        biParams.parent_component_id = parent?.id;
        biParams.parent_component_type = parentType;
        biParams.non_page_top_parent_component_id = topLevelAncestor?.id;
        biParams.non_page_top_parent_component_type = topLeventAncestorType;
        biParams.is_last = componentsOfCompType.length < 2;

        if (compType && editorAPI.isTpa(compType)) {
          Object.assign(biParams, getTpaComponentBIParams(componentRef));
        }
        if (
          componentsSelectors.isRefComponent(componentRef, editorAPI.dsRead)
        ) {
          Object.assign(biParams, getRefComponentBIParams(componentRef));
        }
        if (
          experiment.isOpen('se_sendReferredComponentBI') &&
          componentsSelectors.isReferredComponent(componentRef)
        ) {
          Object.assign(biParams, getReferredComponentBIParams(componentRef));
        }
        if (componentsSelectors.isAppWidget(componentRef, editorAPI.dsRead)) {
          Object.assign(biParams, getAppWidgetComponentBIParams(componentRef));
        }
        const containingAppWidget =
          componentsSelectors.getPrimaryConnectionToAppWidget(
            componentRef,
            editorAPI.dsRead,
          );
        if (containingAppWidget) {
          Object.assign(
            biParams,
            getAppWidgetComponentBIParams(containingAppWidget),
          );
        }
      }
      return biParams;
    });
  }

  function getTpaComponentBIParams(componentRef: CompRef) {
    const isTpaComponent =
      !_.isEmpty(componentRef) &&
      editorAPI.isTpa(componentsApi.getType(componentRef));
    if (isTpaComponent) {
      const compDataItem = componentsApi.data.get(componentRef) || {};
      const appData = editorAPI.dsRead.tpa.app.getData(
        compDataItem.applicationId,
      );

      const isWidget = compDataItem.type === 'TPAWidget';
      const isOneComp = editorAPI.dsRead.tpa.app.isLastAppComp(
        compDataItem.applicationId,
      );
      const isLastTpa =
        isOneComp ||
        !(
          isWidget ||
          editorAPI.dsRead.tpa.app.isMultiSectionInstalled(
            compDataItem.applicationId,
          )
        );

      return {
        app_id: `${appData.appDefinitionId}`,
        app_site_id: `${appData.instanceId}`,
        instance_id: `${componentRef.id}`,
        widget_id: `${compDataItem.widgetId}`,
        is_last_tpa: isLastTpa ? 1 : 0,
      };
    }
    return {};
  }

  return {
    initBI,
    getComponentsBIParams,
    getTpaComponentBIParams,
    getRefComponentBIParams,
    getAppWidgetComponentBIParams,
    reportBI,
    tryFlushingConditionedEvents,
    event: logBiEvent,
  };
};
