import { Hooks } from '@/apilib';

import type { EditorBaseHooks } from './types';
import type { DSRead, CompRef } from 'types/documentServices';

export const createEditorCoreApi = ({
  editorBaseHooks,
}: {
  editorBaseHooks: EditorBaseHooks;
}) => {
  const dsIsReadyHook = Hooks.createPromiseHook<{
    dsRead: DSRead;
  }>();

  const blockingInitPromises: Promise<any>[] = [
    dsIsReadyHook.promise,
    editorBaseHooks.sectionsMigrationReady.promise,
  ];
  let initReadyCalled = false;
  const initReadyHook = Hooks.createPromiseHook();

  return {
    addToEditorInit: (promise: Promise<any>) => {
      if (initReadyCalled) {
        throw new Error(
          'cannot register blocking promises after init ready promise was created',
        );
      }

      blockingInitPromises.push(promise);
    },
    createInitReadyPromise: () => {
      initReadyCalled = true;
      return Promise.all(blockingInitPromises).then(() => {
        initReadyHook.resolve();
      });
    },
    hooks: {
      ...editorBaseHooks,
      //TODO this is temporary hook that is resolved in the very end of the onPreviewReady callback
      // it is added to relieve refactoring of that function and moving current logic to hooks
      // please, do not use it in the new business logic
      __previewIsReady_TEMPORARY: Hooks.createPromiseHook(),
      _dsIsReady: dsIsReadyHook,
      initReady: initReadyHook,
      stageIsReady: Hooks.createPromiseHook(),
      workspaceModesInit: Hooks.createPromiseHook(),
      stageIsReadyAndVisible: Hooks.createPromiseHook(),
      sectionsBootstrapFinished: Hooks.createPromiseHook(),
      zoomModeInit: Hooks.createPromiseHook(),
      focusedPageChanged: Hooks.createHook<{ pageRef: CompRef }>(),
      editorNewUsersTourInitializationFinished: Hooks.createPromiseHook<{
        hasTourStarted: boolean;
      }>(),
      appsSilentInstallationReady: Hooks.createPromiseHook(),
      enterZoomModeAtStartup: Hooks.createPromiseHook(),
      wixCodeLiveEditorReady: Hooks.createPromiseHook(),
      userPreferencesLoaded: Hooks.createPromiseHook(),
    },
  };
};
