import constants from '@/constants';
import {
  getIsPermittedToAddCollection,
  selectCollections,
  selectCollectionsLoaded,
} from '../../collections';
import type { EditorAPI } from '@/editorAPI';
import type { EditorState } from '@/stateManagement';
import type {
  Dispatch,
  MapStateToProps,
  MapDispatchToProps,
  ThunkAction,
} from 'types/redux';
import type { BiEventDefinition, BiEventFields } from 'types/bi';
import { contentManagerButtonOrigin, EVENTS } from '../../consts';
import { notifyDataBindingApplication } from '../../notifyApplication';
import { getHasDynamicPages } from '../../dynamicPages';
import { bi } from '@/stateManagement';
import { createCollectionInNotEmptyStateClicked, videoClicked } from '../../bi';
import { getYoutubeUrl } from '../utils/youtubeVideoUrl';
import { isDataModeOn } from '../../dataMode';
import type { Schema } from '@wix/wix-data-schema-types';

export interface ManageContentOwnProps {
  selectTabByName: (tabName: string) => void;
}

export interface ManageContentStateProps {
  collections: Schema[];
  isLoading: boolean;
  hasDynamicPages: boolean;
  isPermittedToAddCollection: boolean;
}

export interface ManageContentDispatchProps {
  openCreateCollectionPanel: () => void;
  openVideoPanel: (videoId: string, isEmptyState: boolean) => void;
  openContentManagerAdvancedSettings: () => void;
  openContentManagerCollectionsList: () => void;
  sendBi: (event: BiEventDefinition, params: BiEventFields) => void;
}

const getEditorAPI: ThunkAction<EditorAPI> = (
  dispatch: Dispatch,
  getState: () => EditorState,
  { editorAPI }: { editorAPI: EditorAPI },
) => editorAPI;

export const mapStateToProps: MapStateToProps<
  ManageContentStateProps,
  ManageContentOwnProps
> = ({ state, editorAPI }) => ({
  collections: selectCollections(state),
  isLoading: !selectCollectionsLoaded(state) || !isDataModeOn(editorAPI),
  hasDynamicPages: getHasDynamicPages(editorAPI),
  isPermittedToAddCollection: getIsPermittedToAddCollection(editorAPI),
});

export const mapDispatchToProps: MapDispatchToProps<
  ManageContentDispatchProps,
  ManageContentOwnProps
> = (dispatch) => {
  const editorAPI = dispatch(getEditorAPI);

  const sendBi = (event: BiEventDefinition, params: BiEventFields) =>
    dispatch(bi.actions.event(event, params));

  return {
    openCreateCollectionPanel: () => {
      sendBi(createCollectionInNotEmptyStateClicked, null);
      notifyDataBindingApplication(editorAPI, {
        eventType: EVENTS.createCollectionButtonClicked,
        eventPayload: { origin: contentManagerButtonOrigin },
      });
    },
    openVideoPanel: (videoId, isEmptyState) => {
      editorAPI.panelManager.openPanel(
        constants.PANELS.PLAYER_PANEL,
        {
          videoId,
          origin: contentManagerButtonOrigin,
          videoName: 'manage_collections',
        },
        true,
      );

      sendBi(videoClicked, {
        tabName: 'content_manager',
        videoUrl: getYoutubeUrl(videoId),
        isEmptyState,
      });
    },
    openContentManagerAdvancedSettings: () =>
      notifyDataBindingApplication(editorAPI, {
        eventType: EVENTS.openContentManagerAdvancedSettings,
        eventPayload: { origin: contentManagerButtonOrigin },
      }),
    openContentManagerCollectionsList: () =>
      notifyDataBindingApplication(editorAPI, {
        eventType: EVENTS.openContentManagerCollectionsList,
        eventPayload: { origin: contentManagerButtonOrigin },
      }),
    sendBi,
  };
};
