// @ts-nocheck
import constants from '@/constants';
import * as helpIds from '@/helpIds';
import { advancedStyle } from '@/styles';
import gfppDataUtils from '../../utils/gfppDataUtils';

const { ACTIONS } = constants.ROOT_COMPS.GFPP;
const PREVIEW_STATES = advancedStyle.advancedStyleDataUtil.STATES;

export default {
  untranslatable: true,
  mainActions: [
    {
      label: 'gfpp_mainaction_manage_uploaded_files',
      isSelected: gfppDataUtils.getPanelStateFn(ACTIONS.SETTINGS),
      onClick: gfppDataUtils.getTogglePanelFn(ACTIONS.SETTINGS),
    },
  ],
  enabledActions: [
    ACTIONS.SETTINGS,
    ACTIONS.LAYOUT,
    ACTIONS.DESIGN,
    ACTIONS.ANIMATION,
    ACTIONS.HELP,
  ],
  mobileMainActions: [],
  mobileEnabledActions: [
    ACTIONS.SCALE_UP,
    ACTIONS.SCALE_DOWN,
    ACTIONS.LAYOUT,
    ACTIONS.HIDE,
    ACTIONS.HELP,
    ACTIONS.ANIMATION,
  ],
  presetActions: {
    help: {
      helpId: helpIds.GFPP.FILE_UPLOADER,
      mobileHelpId: helpIds.GFPP_MOBILE.FILE_UPLOADER,
    },
  },
  previewState: PREVIEW_STATES.REGULAR,
};
