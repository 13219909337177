:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._container_wmq5k_7 {
  display: flex;
  justify-content: center;
  gap: 0 24px; }
  ._container_wmq5k_7 ._icon_wmq5k_11:hover {
    cursor: pointer;
    stroke: #116dff; }
