import ReactDOM from 'react-dom';
import React, { type CSSProperties, type MouseEvent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import * as core from '@/core';
import type { KnobMargins } from '@/core';
import constants from '@/constants';
import * as stateManagement from '@/stateManagement';
import * as util from '@/util';
import LazyComponent from '@/lazyComponent';

import GFPP from '../gfpp/gfpp';
import FocusBox from '../selectionBox/focusBox/focusBox';
import { ParentContainerBox } from '../selectionBox/parentContainerBox/parentContainerBox';
import EditBox from '../selectionBox/editBox/editBox';
import InteractionsEditBox from '../interactionsEditBox/interactionsEditBox';
import InteractionContainerOutline from '../interactionContainerOutline/interactionContainerOutline';
import {
  mapStateToProps,
  mapDispatchToProps,
  type CompControlOwnProps,
} from './compControlsMapper';
import {
  getBoundingLayoutRelativeToScreen,
  getNavControlsMeasurements,
  getPageXBoundary,
  isCompPreventFixedControls,
} from './compControls.utils';

import type { EditorAPI } from '@/editorAPI';
import type { CompRef, CompLayout } from 'types/documentServices';
import type { InteractionDef } from '@/stateManagement';
import type {
  ControlsPositionsParams,
  SelectedCompParams,
} from './compControls.types';
import { FixedStageApiKey } from '@/apis';

const { getAllDraggableSlotsPositions } =
  stateManagement.draggableSlots.selectors;

const { CUSTOM_GFPP_POS } = constants.UI;

const { getViewPort } = stateManagement.domMeasurements.selectors;
const { compControlsMeasurer } = core.utils;
const { getFocusedContainer, getAppContainer, getLastSelectionClickPos } =
  stateManagement.selection.selectors;
const FORBIDDEN_EDIT_BOX_COMP_TYPES = [
  'wysiwyg.common.components.anchor.viewer.Anchor',
];

const noCustomGfppPosTypes = [
  'wysiwyg.viewer.components.WRichText',
  'wysiwyg.viewer.components.HoverBox',
  'wysiwyg.viewer.components.BoxSlideShow',
  'wysiwyg.viewer.components.StripContainerSlideShow',
  'wysiwyg.viewer.components.StateBox',
  'wysiwyg.viewer.components.StateStrip',
  constants.COMP_TYPES.POPUP_CONTAINER,
];

const SHOULD_SHOW_PARENT_NAV_COMP_TYPES = ['wysiwyg.viewer.components.Column'];

type NavControlsSize = { height: number; width: number } | null;

const MARGIN_FROM_ROTATE_KNOB = 3;

const DEFAULT_ROTATE_KNOB_MARGINS = {
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
};

function isEqual(size1: AnyFixMe, size2: AnyFixMe, tolerance: AnyFixMe) {
  tolerance = tolerance || 0;
  return Math.abs(size1 - size2) <= tolerance;
}

function getMargin(a: AnyFixMe, b: AnyFixMe) {
  return Math.ceil(a - b + MARGIN_FROM_ROTATE_KNOB);
}

function calculateRotateKnobMargins(
  compLayout: CompLayout,
  rotateKnobRect: AnyFixMe,
): KnobMargins {
  if (!rotateKnobRect) {
    return DEFAULT_ROTATE_KNOB_MARGINS;
  }

  const rotateKnobMargins = {
    top: getMargin(compLayout.y, rotateKnobRect.top),
    bottom: getMargin(rotateKnobRect.bottom, compLayout.y + compLayout.height),
    left: getMargin(compLayout.x, rotateKnobRect.left),
    right: getMargin(rotateKnobRect.right, compLayout.x + compLayout.width),
  };

  return rotateKnobMargins;
}

function shouldCalcNewStyle(
  prevProps: CompControlsProps,
  nextProps: CompControlsProps,
) {
  const hoveredCompChange = !_.isEqual(
    prevProps.hoveredComp,
    nextProps.hoveredComp,
  );

  const selectedCompChanged = !_.isEqual(
    prevProps.selectedComponents,
    nextProps.selectedComponents,
  );

  const notMouseActionOrEndMouseAction =
    !nextProps.performingMouseMoveAction ||
    prevProps.performingMouseMoveAction !== nextProps.performingMouseMoveAction;

  return (
    notMouseActionOrEndMouseAction &&
    (!hoveredCompChange || selectedCompChanged)
  );
}

const isCompRequiresCustomGfppPos = (
  editorAPI: EditorAPI,
  selectedComponents: CompRef[],
  compLayout: CompLayout,
) => {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/includes
  const isSelectedCompNotAllowedCustomPos = _.includes(
    noCustomGfppPosTypes,
    editorAPI.components.getType(selectedComponents),
  );
  if (isSelectedCompNotAllowedCustomPos) {
    return false;
  }
  const heightLimit = CUSTOM_GFPP_POS.HEIGHT_TO_APPLY;

  return compLayout.height >= heightLimit;
};

function getMeasurerStyles(
  editorAPI: EditorAPI,
  compControlProps: CompControlsProps,
  selectedComponents: CompRef[],
  compLayout: CompLayout,
  compPanel: AnyFixMe,
  gfppSize: AnyFixMe,
  rotateKnobMargins: AnyFixMe,
  navControlsSize: NavControlsSize,
) {
  const { getState } = editorAPI.store;
  const fixedStageApi = editorAPI.host.getAPI(FixedStageApiKey);
  let customGfppPosition;
  const selectedComp = _.head(selectedComponents);

  //todo: remove once 'se_newComponentLabel' is merged

  let selectedCompParams: SelectedCompParams = {
    id: selectedComp.id,
    layout: compLayout,
  };
  // remove until here

  const selectedCompConnections =
    selectedComp &&
    editorAPI.dsRead.platform.controllers.connections.get(selectedComp);
  const shouldShowEditBoxLabels = selectedComponents.length === 1;

  selectedCompParams = !selectedComp
    ? {}
    : {
        id: selectedComp.id,
        layout: compLayout,
        hasLabels:
          shouldShowEditBoxLabels || !_.isEmpty(selectedCompConnections),
      };

  const editorState = getState();
  if (isCompRequiresCustomGfppPos(editorAPI, selectedComponents, compLayout)) {
    const lastSelectionClickPos = getLastSelectionClickPos(editorState);

    if (
      lastSelectionClickPos &&
      lastSelectionClickPos.y > compLayout.bounding.y &&
      lastSelectionClickPos.y <=
        compLayout.bounding.y + compLayout.bounding.height &&
      lastSelectionClickPos.x > compLayout.bounding.x &&
      lastSelectionClickPos.x <=
        compLayout.bounding.x + compLayout.bounding.width
    ) {
      // Use default gfpp position resolution if last click position is irrelevant
      // for a selected component (adding from add panel sends it below lastSelectionClickPos)
      customGfppPosition = lastSelectionClickPos;
    }
  }

  const leftBarBoundingRect = editorAPI.getLeftBarButtonsBoundingRect();
  const focusedContainer = getFocusedContainer(editorState);
  const appContainer = getAppContainer(editorState);
  const focusedLayout =
    focusedContainer &&
    getBoundingLayoutRelativeToScreen(editorAPI, focusedContainer);

  const focusedCompParams = focusedContainer
    ? {
        id: focusedContainer.id,
        layout: focusedLayout,
      }
    : null;

  const relativeToScroll =
    !compLayout.fixedPosition ||
    isCompPreventFixedControls(
      editorAPI,
      _.head(editorAPI.selection.getSelectedComponents()),
    );
  const viewPort = getViewPort(
    editorState,
    relativeToScroll,
    leftBarBoundingRect,
  );

  if (util.sections.isSectionsEnabled() && editorAPI.isMobileEditor()) {
    viewPort.rightX =
      editorAPI.site.getSiteX() +
      editorAPI.siteSegments.getPagesContainerAbsLayout().width;
  }

  const sizesAndConstraints = {
    gfppSize,
    viewPort,
    navControlsSize,
    pageXBoundary: getPageXBoundary(editorAPI),
    rotateKnobMargins,
    alignToPageBoundaries:
      editorAPI.dsRead.viewMode.get() ===
      editorAPI.dsRead.viewMode.VIEW_MODES.MOBILE,
    draggableSlots: getAllDraggableSlotsPositions(getState()),
  };
  const isInteractionsCompControls = !!compControlProps.compInteraction;
  const customGfppMargins =
    editorAPI.components.is.gfppMargins(selectedComponents);

  const controlsPositionsParams: ControlsPositionsParams = [
    compPanel,
    selectedCompParams,
    sizesAndConstraints,
    customGfppPosition,
    customGfppMargins,
    appContainer,
    isInteractionsCompControls,
  ];

  const shouldSubtractStageLeftMargin =
    fixedStageApi.isStageFixedInDesktop() &&
    !fixedStageApi.isStageHorizontallyScrollable();

  const getParentNavControlsStyle = () => {
    const focusedParent: CompRef =
      editorAPI.components.getContainerOrScopeOwner(focusedContainer);

    const parentFocusedLayout =
      focusedParent &&
      getBoundingLayoutRelativeToScreen(editorAPI, focusedParent);

    const focusedParentParams = focusedParent
      ? {
          id: focusedParent.id,
          layout: parentFocusedLayout,
        }
      : null;

    return compControlsMeasurer.getCompControlsPositions(
      focusedParentParams,
      ...controlsPositionsParams,
      shouldSubtractStageLeftMargin,
    ).navControlsStyle;
  };

  return {
    ...compControlsMeasurer.getCompControlsPositions(
      focusedCompParams,
      ...controlsPositionsParams,
      shouldSubtractStageLeftMargin,
    ),
    parentNavControlsStyle: SHOULD_SHOW_PARENT_NAV_COMP_TYPES.includes(
      compControlProps.compType,
    )
      ? getParentNavControlsStyle()
      : null,
  };
}

//TYPE WAS GENERATED, remove this line when reviewed
export interface CompControlsStateProps {
  editorAPI: EditorAPI;
  getEditorAPI: () => EditorAPI; //TODO remove
  containerInteractionOutlineRef: CompRef | null;
  shouldShowContainerInteractionOutlineRef: boolean;
  isMobile: boolean;
  compInteraction: InteractionDef | null;
  isInteractionMode: boolean;
  shouldShowInteractionModeControls: boolean;
  compType: string;
  columnsContainer: AnyFixMe;
  isFocusBoxOverSelected: boolean;
  attachCandidateFocusableParent: AnyFixMe;
  isDesignPanelOpen: boolean;
  siteScale: number;
  shouldShowCompPanels: boolean;
  zoomModeEnabledCompControls: boolean;
  tabIndicationState: AnyFixMe;
  focusedContainerTabsDef: AnyFixMe;
  panel?: {
    name?: string;
    loader?: () => Promise<React.ComponentType>;
    props?: AnyFixMe;
    token?: string;
  };
  isSectionDragging?: boolean;
  isInZoomMode: boolean;
  selectedComponents: CompRef[];
  hoveredComp?: boolean | AnyFixMe;
  clearHoverBox?: (ev: MouseEvent) => void;
  onContextMenu?: (ev: MouseEvent) => void;
  previewPosition: AnyFixMe;
  attachCandidate?: CompRef;
  appContainer?: CompRef;
  applyModeFromClipboardSuggestion?: AnyFixMe;
  isSameRef: EditorAPI['utils']['isSameRef'];
  secondaryActionInProgress: boolean;
  shouldShowFocusBoxWithNavControls: boolean;
  shouldShowFocusBox: boolean;
  containerAncestor?: CompRef;
  isGFPPVisible: boolean;
  forceCompStyleMeasurements: boolean;
  typeOfFocusedContainer: string;
  hasViewStates: boolean;
  focusedContainer: CompRef;
}

export interface CompControlsState {
  gfppIsCollapsed: boolean;
  gfppStyle: CSSProperties;
  compPanelStyle: CSSProperties;
  navControlsStyle: CSSProperties;
  parentNavControlsStyle?: CSSProperties;
  appNavControlsStyle: CSSProperties;
  attachCandidateNavControlsStyle?: CSSProperties;
  editBoxLabelsOffset: number;
  isSelectedComponentConnected: boolean;
}

const startNavPosition = {
  top: 0,
  left: 0,
};
const initialState: CompControlsState = {
  gfppIsCollapsed: true,
  gfppStyle: {
    ...startNavPosition,

    position: 'absolute',
  },
  compPanelStyle: {
    ...startNavPosition,
    opacity: 0, //todo remove once animation is fixed
    visibility: 'hidden',
  },
  navControlsStyle: {
    ...startNavPosition,
    visibility: 'hidden',
  },
  parentNavControlsStyle: {
    ...startNavPosition,
    visibility: 'hidden',
  },
  appNavControlsStyle: {
    ...startNavPosition,
  },
  editBoxLabelsOffset: 0,
  isSelectedComponentConnected: false,
};

type CompControlsProps = CompControlOwnProps & CompControlsStateProps;

function ensureIsNumber(value: unknown): asserts value is number {
  if (typeof value !== 'number') {
    throw new Error('value should be a number');
  }
}

class ControlsClass extends React.Component<
  CompControlsProps,
  CompControlsState
> {
  static displayName = 'compControls';

  static propTypes = {
    panel: PropTypes.shape({
      name: PropTypes.string,
      props: PropTypes.object,
    }),
    isDragging: PropTypes.bool,
    isResizing: PropTypes.bool,
    isRotating: PropTypes.bool,
    isSectionDragging: PropTypes.bool,
    selectedComponents: PropTypes.arrayOf(PropTypes.object).isRequired,
    hoveredComp: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
    clearHoverBox: PropTypes.func,
    performingMouseMoveAction: PropTypes.bool,
    onContextMenu: PropTypes.func,
    previewPosition: PropTypes.object.isRequired,
    attachCandidate: PropTypes.object,
    applyModeFromClipboardSuggestion: PropTypes.object,
    isIndicatorBottom: PropTypes.bool,
  };

  panelReady: boolean;
  lastMeasuredPanelHeight: number;
  gfppMeasureFunc?: () => { width: number; height: number } | null;
  getEditorAPI = () => this.props.getEditorAPI(); //TODO remove

  constructor(props: AnyFixMe) {
    super(props);
    this.panelReady = false;
    this.lastMeasuredPanelHeight = 0;

    this.state = initialState;
  }

  setGFPPMeasureFunc = (measureFunc: AnyFixMe) => {
    this.gfppMeasureFunc = measureFunc;
  };

  isCompsStyleSet = () => {
    return (
      !this.props.panel ||
      (this.state.compPanelStyle.visibility !== 'hidden' &&
        !!this.lastMeasuredPanelHeight &&
        this.state.compPanelStyle.left !== 0 &&
        this.state.compPanelStyle.top !== 0)
    );
  };

  getCompControlsStyles = (
    forcePanelRepositioning: boolean,
    gfppSize: { width: number; height: number },
    compPanel: Element,
    compLayout: CompLayout,
    rotateKnobMargins: KnobMargins,
  ) => {
    const editorAPI = this.getEditorAPI();
    const { isInZoomMode } = this.props;
    const shouldPanelUpdate =
      this.props.panel && (forcePanelRepositioning || !this.isCompsStyleSet());

    const compRef = _.head(this.props.selectedComponents);
    const shouldPreventFixedControls = isCompPreventFixedControls(
      editorAPI,
      compRef,
    );

    let styles = getMeasurerStyles(
      editorAPI,
      this.props,
      this.props.selectedComponents,
      compLayout,
      compPanel,
      gfppSize,
      rotateKnobMargins,
      (this.refs.focusBox as any)?.getNavControlsSize(),
    );

    styles.gfppStyle.visibility = 'inherit';

    if (shouldPanelUpdate && isInZoomMode) {
      const pageClientRect =
        editorAPI.components.layout.measure.getBoundingClientRect(
          editorAPI.pages.getFocusedPage(),
        );
      const editorScroll = editorAPI.ui.scroll.getScroll();
      Object.assign(styles.compPanelStyle, {
        position: 'fixed',
        top: window.innerHeight / 4 + editorScroll.scrollTop, // Still didn't get any other UX def for compPanel in zoom mode.
        left: pageClientRect.absoluteLeft,
      });
    } else if (shouldPanelUpdate) {
      styles.compPanelStyle.top += this.props.previewPosition.top;
      if (_.has(styles.compPanelStyle, 'left')) {
        styles.compPanelStyle.left += this.props.previewPosition.left;
      }
      if (!compLayout.fixedPosition) {
        const editorScroll = editorAPI.ui.scroll.getScroll();

        ensureIsNumber(styles.compPanelStyle.top);
        styles.compPanelStyle.top -= editorScroll.scrollTop;
      }
      Object.assign(styles.compPanelStyle, this.getPanelStyle());
    } else {
      styles = _.pick(styles, [
        'gfppStyle',
        'navControlsStyle',
        'parentNavControlsStyle',
        'editBoxLabelsOffset',
      ]) as AnyFixMe;
    }

    if (compLayout.fixedPosition && !shouldPreventFixedControls) {
      styles.gfppStyle.top += this.props.previewPosition.top;
      if (_.has(styles.gfppStyle, 'left')) {
        styles.gfppStyle.left += this.props.previewPosition.left;
      }
      styles.gfppStyle.position = 'fixed';
    } else {
      styles.gfppStyle.position = 'absolute';
    }
    if (this.props.isPinMode) {
      styles.gfppStyle.visibility = 'hidden';
    }

    if (
      compLayout.fixedPosition &&
      shouldPreventFixedControls &&
      shouldPanelUpdate
    ) {
      ensureIsNumber(styles.compPanelStyle.top);
      ensureIsNumber(styles.compPanelStyle.left);
      styles.compPanelStyle = {
        ...styles.compPanelStyle,
        position: 'absolute',
        top: styles.compPanelStyle.top - this.props.previewPosition.top,
        left: styles.compPanelStyle.left - this.props.previewPosition.left,
      } as AnyFixMe;
    }

    return styles;
  };

  getPanelStyle = () => {
    const style: CSSProperties = {};
    if (this.panelReady && !this.props.performingMouseMoveAction) {
      style.visibility = 'inherit';
      style.opacity = 1;
    } else {
      style.visibility = 'hidden';
      style.opacity = 0;
    }
    return style;
  };

  getRotateKnobRect = () => {
    return this.getEditorAPI().getEditBoxRotateKnobRect();
  };

  getRotateKnobMargins = (compLayout: CompLayout) => {
    if (!compLayout.rotationInDegrees) {
      return DEFAULT_ROTATE_KNOB_MARGINS;
    }

    let rotateKnobRect = this.getRotateKnobRect();

    if (!rotateKnobRect) {
      return DEFAULT_ROTATE_KNOB_MARGINS;
    }

    const editorScroll = this.getEditorAPI().ui.scroll.getScroll();
    const knobVerticalOffset =
      editorScroll.scrollTop - this.props.previewPosition.top;
    const knobHorizontalOffset =
      editorScroll.scrollLeft - this.props.previewPosition.left;

    rotateKnobRect = {
      top: rotateKnobRect.top + knobVerticalOffset,
      bottom: rotateKnobRect.bottom + knobVerticalOffset,
      left: rotateKnobRect.left + knobHorizontalOffset,
      right: rotateKnobRect.right + knobHorizontalOffset,
    };

    return calculateRotateKnobMargins(compLayout, rotateKnobRect);
  };

  getCompPanelRef() {
    return this.refs.compPanel
      ? (ReactDOM.findDOMNode(this.refs.compPanel) as Element)
      : null;
  }

  setCompsStyle = (forcePanelRepositioning?: boolean) => {
    const editorAPI = this.getEditorAPI();

    let gfppSize = this.gfppMeasureFunc ? this.gfppMeasureFunc() : null;

    if (!gfppSize && this.props.forceCompStyleMeasurements) {
      gfppSize = { width: 0, height: 0 };
    }

    if (!gfppSize) {
      return;
    }

    if (
      this.props.selectedComponents.length === 0 ||
      (this.props.selectedComponents.length &&
        !editorAPI.components.is.exist(this.props.selectedComponents[0]))
    ) {
      // because it's debounced/throttled
      return;
    }

    const compPanel = this.getCompPanelRef();

    this.lastMeasuredPanelHeight = compPanel
      ? compPanel.getBoundingClientRect().height
      : 0;

    const boundingLayoutRelativeToScreen = getBoundingLayoutRelativeToScreen(
      editorAPI,
      this.props.selectedComponents,
    );
    const rotateKnobMargins = this.getRotateKnobMargins(
      boundingLayoutRelativeToScreen,
    );

    const styles = this.getCompControlsStyles(
      forcePanelRepositioning,
      gfppSize,
      compPanel,
      boundingLayoutRelativeToScreen,
      rotateKnobMargins,
    );
    if (!this.lastMeasuredPanelHeight) {
      styles.compPanelStyle = {
        top: 0,
        left: 0,
        opacity: 0, //todo remove once animation is fixed
        visibility: 'hidden',
      };
    }

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/keys
    const currStyles = _.pick(this.state, _.keys(styles));

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/every
    const isEqualStyles = _.every(styles, function (newStyle, key) {
      const currentStyle = currStyles[key as keyof typeof currStyles];

      if (typeof newStyle === 'string' || typeof newStyle === 'number') {
        return newStyle === currentStyle;
      }

      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/every
      return _.every(newStyle, function (newValue, propKey) {
        const currValue = currentStyle[propKey as keyof typeof currentStyle];
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/is-finite
        if (_.isFinite(currValue) && _.isFinite(newValue)) {
          return isEqual(currValue, newValue, 2);
        }
        return _.isEqual(currValue, newValue);
      });
    });

    if (!isEqualStyles) {
      this.setState(styles);
    }
  };

  throttledSetCompsStyle = _.throttle(this.setCompsStyle, 50, {
    leading: false,
    trailing: true,
  });

  onWindowScroll = () => {
    this.setCompsStyle();
    this.setAppNavControlsStyle();
  };

  debouncedSetCompsStyle = _.debounce(() => {
    this.setCompsStyle();
    this.setAppNavControlsStyle();
  }, 200);

  onWindowResize = () => {
    this.debouncedSetCompsStyle();
  };

  shouldRenderEditBox = () => {
    const {
      selectedComponents,
      isSectionDragging,
      compType,
      isInZoomMode,
      secondaryActionInProgress,
    } = this.props;

    if (
      isInZoomMode ||
      secondaryActionInProgress ||
      !selectedComponents.length ||
      isSectionDragging ||
      this.shouldRenderInteractionEditBox()
    ) {
      return false;
    }

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/includes
    return !_.includes(FORBIDDEN_EDIT_BOX_COMP_TYPES, compType);
  };

  shouldRenderInteractionEditBox = () => {
    const {
      isInteractionMode,
      secondaryActionInProgress,
      shouldShowInteractionModeControls,
    } = this.props;
    return (
      isInteractionMode &&
      !secondaryActionInProgress &&
      shouldShowInteractionModeControls
    );
  };

  componentDidMount() {
    this.throttledSetCompsStyle();

    window.addEventListener('resize', this.onWindowResize); // TODO: do not access window
  }

  componentWillUnmount() {
    this.throttledSetCompsStyle.cancel();

    window.removeEventListener('resize', this.onWindowResize); // TODO: do not access window
  }

  UNSAFE_componentWillReceiveProps(nextProps: CompControlsProps) {
    const shouldIgnoreLastPanelPosition =
      !nextProps.panel?.props?.useLastPanelPosition;

    const isSamePanelId =
      this.props?.panel &&
      this.props?.panel?.name === nextProps?.panel?.name &&
      this.props?.panel?.token === nextProps?.panel?.token;

    const isPanelChanged =
      !_.isEqual(this.props.panel, nextProps.panel) &&
      shouldIgnoreLastPanelPosition &&
      !isSamePanelId;

    const selectedCompChanged = !this.props.isSameRef(
      this.props.selectedComponents,
      nextProps.selectedComponents,
    );
    const startedMouseMoveAction =
      !this.props.performingMouseMoveAction &&
      nextProps.performingMouseMoveAction;

    if (isPanelChanged || selectedCompChanged || startedMouseMoveAction) {
      this.setState({
        compPanelStyle: {
          top: 0,
          left: 0,
          opacity: 0, //todo remove once animation is fixed
          visibility: 'hidden',
        },
      });
      if (!nextProps.panel) {
        this.panelReady = false;
      }
    }

    if (selectedCompChanged || startedMouseMoveAction) {
      this.setState({
        gfppStyle: {
          top: 0,
          left: 0,
          visibility: 'hidden',
          position: 'absolute',
        },
      });
    }
  }

  getNavControlSizeToContainer = (compRef: CompRef) => {
    if (compRef) {
      return getNavControlsMeasurements(
        this.getEditorAPI(),
        compRef,
        this.getCompPanelRef(),
      );
    }
  };

  setAttachCandidateNavControlsStyle = () => {
    const measurerStyles = this.getNavControlSizeToContainer(
      this.props.attachCandidate,
    );
    this.setState({
      attachCandidateNavControlsStyle: measurerStyles.navControlsStyle,
    });
  };

  setAppNavControlsStyle = () => {
    if (this.props.appContainer) {
      const measurerStyles = this.getNavControlSizeToContainer(
        this.props.appContainer,
      );
      this.setState({
        appNavControlsStyle: measurerStyles.navControlsStyle,
      });
    }
  };

  componentDidUpdate(prevProps: CompControlsProps) {
    if (shouldCalcNewStyle(prevProps, this.props)) {
      this.throttledSetCompsStyle();
    }

    if (
      !_.isEqual(prevProps.attachCandidate, this.props.attachCandidate) &&
      this.props.attachCandidateFocusableParent
    ) {
      this.setAttachCandidateNavControlsStyle();
    }
    if (
      this.props.appContainer &&
      !_.isEqual(prevProps.appContainer, this.props.appContainer)
    ) {
      this.setAppNavControlsStyle();
    }
  }

  onPanelDragEnd = (newPosition: AnyFixMe) => {
    this.setState({
      compPanelStyle: newPosition,
    });
  };

  onCompPanelLoaded = () => {
    this.panelReady = true;
    this.throttledSetCompsStyle();
  };

  getPanelProps = () => {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/assign
    return _.assign(
      {
        onDragEnd: this.onPanelDragEnd,
        onCompPanelLoaded: this.onCompPanelLoaded,
        style: this.state.compPanelStyle,
        isHidden: this.state.compPanelStyle?.visibility === 'hidden',
        selectedComponent: this.props.selectedComponents,
        compType: this.props.compType,
      },
      this.props.panel.props,
    );
  };

  setGfppIsCollapsed = (isCollapsed: boolean) => {
    this.setState({ gfppIsCollapsed: isCollapsed });
  };

  toggleGfpp = () => {
    this.setState({ gfppIsCollapsed: !this.state.gfppIsCollapsed });
  };

  getAppContainerFocusBoxKey = () => {
    if (this.props.appContainer) {
      return `appContainerFocusBox-${this.props.appContainer.id}`;
    }
    return 'appContainerFocusBox';
  };

  renderEditBox = () => {
    const {
      isPinMode,
      isDesignPanelOpen,
      onContextMenu,
      isFocusBoxOverSelected,
      applyModeFromClipboardSuggestion,
    } = this.props;
    const { editBoxLabelsOffset } = this.state;

    return (
      <EditBox
        isFocusBoxOverSelected={isFocusBoxOverSelected}
        isPinMode={isPinMode}
        isDim={isDesignPanelOpen}
        onContextMenu={onContextMenu}
        applyModeFromClipboardSuggestion={applyModeFromClipboardSuggestion}
        labelsOffset={editBoxLabelsOffset}
      />
    );
  };

  getCompControlsWrapperStyles = (): React.CSSProperties => {
    const { isInZoomMode, zoomModeEnabledCompControls } = this.props;
    if (isInZoomMode && zoomModeEnabledCompControls) return null;
    return {
      visibility: this.props.siteScale === 1 ? 'visible' : 'hidden',
    };
  };

  shouldShowParentPav = (): boolean => {
    return SHOULD_SHOW_PARENT_NAV_COMP_TYPES.includes(this.props.compType);
  };

  shouldShowShowSmallTabs = () => {
    return (
      this.shouldShowParentPav() ||
      SHOULD_SHOW_PARENT_NAV_COMP_TYPES.includes(
        this.props.typeOfFocusedContainer,
      )
    );
  };

  render() {
    const { attachCandidateFocusableParent } = this.props;

    return (
      <div
        style={this.getCompControlsWrapperStyles()}
        onMouseEnter={this.props.clearHoverBox}
      >
        {this.props.shouldShowCompPanels && this.props.panel && (
          <LazyComponent
            ref="compPanel"
            moduleName={this.props.panel.name}
            componentLoader={this.props.panel.loader}
            {...this.getPanelProps()}
            onModuleLoaded={() => this.forceUpdate()}
          />
        )}

        {!this.shouldRenderInteractionEditBox() &&
          this.props.columnsContainer && (
            <LazyComponent
              moduleName="columnsControls.default"
              selectedComponents={this.props.selectedComponents}
              columnsContainer={this.props.columnsContainer}
            />
          )}

        {this.props.shouldShowContainerInteractionOutlineRef &&
          this.props.containerInteractionOutlineRef &&
          !this.props.isMobile && (
            <InteractionContainerOutline
              containerWithInteraction={
                this.props.containerInteractionOutlineRef
              }
            />
          )}

        <ParentContainerBox />

        {this.props.appContainer && this.props.shouldShowFocusBox && (
          <FocusBox
            navControlsStyle={this.state.appNavControlsStyle}
            focusedContainer={this.props.appContainer}
            selectedComponents={this.props.selectedComponents}
          />
        )}

        {this.props.appContainer &&
          this.props.shouldShowFocusBox &&
          this.props.hasViewStates && (
            <FocusBox
              navControlsStyle={this.state.appNavControlsStyle}
              selectedComponents={this.props.selectedComponents}
            />
          )}

        {this.props.shouldShowFocusBoxWithNavControls &&
          this.props.shouldShowFocusBox && (
            <FocusBox
              ref="focusBox"
              compInteraction={this.props.compInteraction}
              navControlsStyle={this.state.navControlsStyle}
              parentNavControlsStyle={
                this.shouldShowParentPav()
                  ? this.state.parentNavControlsStyle
                  : null
              }
              shouldShowShowSmallTabs={this.shouldShowShowSmallTabs()}
              tabsDef={this.props.focusedContainerTabsDef}
              selectedComponents={this.props.selectedComponents}
              tabIndicationState={this.props.tabIndicationState}
            />
          )}

        {attachCandidateFocusableParent && this.props.shouldShowFocusBox && (
          <FocusBox
            navControlsStyle={this.state.attachCandidateNavControlsStyle}
            focusedContainer={
              !this.props.hasViewStates
                ? attachCandidateFocusableParent
                : undefined
            }
            selectedComponents={this.props.selectedComponents}
          />
        )}

        {this.shouldRenderEditBox() && this.renderEditBox()}

        {this.shouldRenderInteractionEditBox() && (
          <InteractionsEditBox
            navOffsetLeft={this.state.navControlsStyle.left}
            renderRegularEditBox={this.renderEditBox}
          />
        )}

        {this.props.isGFPPVisible && (
          <GFPP
            data-hook="gfpp"
            setGFPPMeasureFunc={this.setGFPPMeasureFunc}
            toggleGfpp={this.toggleGfpp}
            setGfppIsCollapsed={this.setGfppIsCollapsed}
            gfppIsCollapsed={this.state.gfppIsCollapsed}
            style={this.state.gfppStyle}
            isGfppCollapseEnabled={true}
            selectedComponents={this.props.selectedComponents}
            previewState={null}
          />
        )}
      </div>
    );
  }
}

const ConnectedComponent = util.hoc.connect(
  util.hoc.STORES.EDITOR_API,
  mapStateToProps,
  mapDispatchToProps,
  undefined,
  false,
)(ControlsClass as React.ComponentType<CompControlsProps>);
(ConnectedComponent as AnyFixMe).pure = ControlsClass;

export default ConnectedComponent;
