import _ from 'lodash';

import { sections, arrayUtils } from '@/util';
import * as stateManagement from '@/stateManagement';
import constants from '@/constants';
import type { CompRef } from 'types/documentServices';
import type { EditorAPI } from '@/editorAPI';
import type { Dispatch, StateMapperArgs, ThunkAction } from 'types/redux';
import { isContainerComp } from './compControls.utils';
import { EditorRestrictionsApiKey } from '@/apis';
import { getViewStates } from '../selectionBox/focusBox/widgetViewState';

const { components } = stateManagement;
const {
  getFocusedContainer,
  getFocusedContainerTabDefinition,
  getAncestorOfSelectedWithInteraction,
} = stateManagement.selection.selectors;
const {
  isInInteractionMode,
  showInteractionModeControls,
  getComponentInteractionIfExists,
  isInteractionPlaying,
  componentHasInteraction,
} = stateManagement.interactions.selectors;

const { selectAttachCandidateComponent } =
  stateManagement.attachCandidate.selectors;

export interface CompControlOwnProps {
  performingMouseMoveAction: boolean;
  isDragging: boolean;
  isResizing: boolean;
  isRotating: boolean;
  isPinMode: boolean;
}

const getColumnsContainer = (editorAPI: EditorAPI, compRef: CompRef[]) => {
  const isColumnsContainer = (compRef: CompRef | CompRef[]) => {
    const COLUMNS_CONTAINER = 'wysiwyg.viewer.components.StripColumnsContainer';
    return editorAPI.components.getType(compRef) === COLUMNS_CONTAINER;
  };

  if (isColumnsContainer(compRef)) {
    return compRef;
  }

  const ancestorColumnsContainer = editorAPI.components.findAncestor(
    compRef,
    isColumnsContainer,
    { includeScopeOwner: true },
  );
  if (ancestorColumnsContainer) {
    return arrayUtils.asArray(ancestorColumnsContainer);
  }

  return null;
};

function isFocusBoxShownOverSelected(
  shouldShowFocusBox: boolean,
  selectedComponents: CompRef[],
  focusedContainer: CompRef,
): boolean {
  return (
    shouldShowFocusBox &&
    selectedComponents.length === 1 &&
    focusedContainer?.id === selectedComponents[0].id
  );
}

export const mapStateToProps = (
  { state: editorState, editorAPI }: StateMapperArgs,
  ownProps: CompControlOwnProps,
) => {
  const selectedComponents: CompRef[] =
    stateManagement.selection.selectors.getSelectedCompsRefs(editorState);
  const isInteractionMode = isInInteractionMode(editorState);
  const shouldShowInteractionModeControls =
    showInteractionModeControls(editorState);
  const focusedContainer = getFocusedContainer(editorState);

  const isInZoomMode = editorAPI.zoomMode.isInZoomMode();
  const zoomModeEnabledCompControls = editorAPI.zoomMode.enabledCompControls();

  const selectedComp = _.head(selectedComponents);

  const focusedContainerTabsDef = getFocusedContainerTabDefinition(editorState);

  const columnsContainer = getColumnsContainer(editorAPI, selectedComponents);

  const compType = editorAPI.components.getType(selectedComponents);

  const attachCandidate = selectAttachCandidateComponent(editorState);

  const attachCandidateFocusableParent = sections.isSectionsEnabled()
    ? null
    : editorAPI.selection.getParentToFocus(attachCandidate);
  const ancestorOfSelectedWithInteraction =
    getAncestorOfSelectedWithInteraction(editorState);
  const containerInteractionOutlineRef =
    editorAPI.components.getType(ancestorOfSelectedWithInteraction) ===
    constants.COMP_TYPES.CONTAINER
      ? ancestorOfSelectedWithInteraction
      : null;

  const compInteraction = getComponentInteractionIfExists(
    editorAPI,
    selectedComponents,
  );
  const isMobile = editorAPI.isMobileEditor();
  const openedPanels = editorAPI.panelManager.getOpenPanels();
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/find
  const isDesignPanelOpen = !!_.find(openedPanels, {
    name: constants.componentPanels.design,
  });

  const secondaryActionInProgress = isInteractionPlaying(editorState);

  const containerAncestor = editorAPI.components.findAncestor(
    selectedComp,
    (ancestor) =>
      !componentHasInteraction(editorAPI, ancestor) &&
      isContainerComp(editorAPI, ancestor),
    { includeScopeOwner: true },
  );

  const focusedCompIsSelected =
    selectedComp && focusedContainer?.id === selectedComp.id;

  const viewStates = getViewStates(editorAPI, focusedContainer);
  const hasViewStates = !!viewStates.length;

  const shouldShowFocusBoxWithNavControls = (() => {
    const isLightBoxSelected = _.isEqual(
      editorAPI.pages.popupPages.getPopupContainer(),
      selectedComp,
    );

    const hideLightBoxLabel =
      ownProps.performingMouseMoveAction &&
      focusedCompIsSelected &&
      !isLightBoxSelected;

    const isSectionFn = (ref: CompRef) => {
      if (!sections.isSectionsEnabled()) {
        return false;
      }

      return editorAPI.sections.isSectionLike(ref);
    };

    // For ref components inside ref components we don't want to show a FocusBox
    // unless the child ref component has view states
    const isReferredComponent = components.selectors.isWidgetInWidget(
      focusedContainer,
      editorAPI.dsRead,
    );

    return (
      !isInteractionMode &&
      focusedContainer &&
      !componentHasInteraction(editorAPI, focusedContainer) &&
      !isSectionFn(focusedContainer) &&
      !hideLightBoxLabel &&
      !isReferredComponent
    );
  })();

  const isFocusBoxOverSelected = isFocusBoxShownOverSelected(
    shouldShowFocusBoxWithNavControls,
    selectedComponents,
    focusedContainer,
  );

  const shouldShowGfppWhenSectionActionsAreHovered = (editorAPI: EditorAPI) => {
    const selectedSection = editorAPI.sections.getSelectedSectionLike();
    const hoveredSection = editorAPI.sections.getHoveredSectionLike();
    if (selectedSection?.id === hoveredSection?.id) return true;
    return !stateManagement.selection.selectors.shouldHideSelectionWhenStageActionsHovered(
      editorAPI,
    );
  };

  const isGFPPVisible = (isInZoomMode: boolean) => {
    const { isDragging, isResizing, isRotating } = ownProps;
    const basicCondition =
      selectedComponents.length &&
      !isInZoomMode &&
      !isDragging &&
      !isResizing &&
      !isRotating &&
      !secondaryActionInProgress;

    return (
      basicCondition &&
      editorAPI.components.is.gfppVisible(selectedComponents) &&
      shouldShowGfppWhenSectionActionsAreHovered(editorAPI)
    );
  };

  const forceCompStyleMeasurements =
    ownProps.isPinMode ||
    (isInZoomMode && zoomModeEnabledCompControls) ||
    (sections.isSectionsEnabled() &&
      editorAPI.components.is.siteSegment(selectedComp));

  const shouldShowContainerInteractionOutlineRef = !isInZoomMode;

  const shouldShowCompPanels =
    !isInZoomMode || editorAPI.zoomMode.enabledCompPanels();

  // TODO: use withRestrictions on FocusBox after debugging failing tests due to wrappers
  const editorRestrictionsApi = editorAPI.host.getAPI(EditorRestrictionsApiKey);
  const isFocusBoxVisible = editorRestrictionsApi.allowed(
    'rEditor_focus-box.visible',
  );
  const shouldShowFocusBox =
    !isInZoomMode &&
    isFocusBoxVisible &&
    !stateManagement.selection.selectors.shouldHideSelectionWhenStageActionsHovered(
      editorAPI,
    );

  return {
    editorAPI,
    isMobile,
    shouldShowFocusBox,
    shouldShowInteractionModeControls,
    compInteraction,
    selectedComponents,
    isFocusBoxOverSelected,
    focusedContainerTabsDef,
    columnsContainer,
    compType,
    isInZoomMode,
    shouldShowCompPanels,
    zoomModeEnabledCompControls,
    shouldShowContainerInteractionOutlineRef,
    attachCandidateFocusableParent,
    isDesignPanelOpen,
    attachCandidate,
    containerInteractionOutlineRef,
    secondaryActionInProgress,
    containerAncestor,
    shouldShowFocusBoxWithNavControls,
    isGFPPVisible: isGFPPVisible(isInZoomMode),
    forceCompStyleMeasurements,
    typeOfFocusedContainer: editorAPI.components.getType(focusedContainer),
    hasViewStates,
    focusedContainer,
  };
};

const getEditorAPI: ThunkAction<EditorAPI> = (
  dispatch,
  getState,
  { editorAPI },
) => editorAPI;

export const mapDispatchToProps = (dispatch: Dispatch) => {
  const editorAPI: EditorAPI = dispatch(getEditorAPI);

  return {
    isSameRef: editorAPI.utils.isSameRef,
    getEditorAPI: () => editorAPI,
  };
};
