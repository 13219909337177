import type { EditorAPI } from '@/editorAPI';
import type { CompRef } from 'types/documentServices';
import _ from 'lodash';

export default {
  overrideProportionalResize: true,
  skinParamsToIgnore(editorAPI: EditorAPI, compRef: CompRef) {
    const canChangeVideoCover = _.chain(compRef)
      .thru(editorAPI.components.data.get)
      .get('videoType')
      .isEqual('file')
      .value();

    return canChangeVideoCover ? [] : ['coverImage'];
  },
};
