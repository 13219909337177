import { url as urlUtils, fedopsLogger } from '@/util';
import { SaveError } from '../utils';

import type { SectionsMigrationScope as Scope } from '../scope';

export async function saveBeforeMigration(scope: Scope): Promise<void> {
  if (urlUtils.isQA()) {
    return;
  }

  fedopsLogger.interactionStarted(fedopsLogger.INTERACTIONS.SAVE_SITE);

  await new Promise<void>((resolve, reject) => {
    // (onSuccess, onError, isFullSave, options)
    scope.editorAPI.documentServices.save(resolve, reject, false, {
      origin: 'sectionsMigration',
      sourceOfStart: 'sectionsMigration_preSave',
    });
  });

  fedopsLogger.interactionEnded(fedopsLogger.INTERACTIONS.SAVE_SITE);
}

export async function saveAfterMigration(scope: Scope) {
  if (urlUtils.isQA()) {
    return;
  }

  try {
    fedopsLogger.interactionStarted(fedopsLogger.INTERACTIONS.SAVE_SITE);

    await new Promise<void>((resolve, reject) => {
      // (onSuccess, onError, isFullSave, options)
      scope.editorAPI.documentServices.save(resolve, reject, false, {
        origin: 'sectionsMigration',
        sourceOfStart: 'sectionsMigration_postSave',
      });
    });

    fedopsLogger.interactionEnded(fedopsLogger.INTERACTIONS.SAVE_SITE);
  } catch (error: Error | any) {
    throw new SaveError(`save after migration error: ${error?.message}`);
  }
}

export async function saveInBackground(scope: Scope, callback?: () => void) {
  if (urlUtils.isQA()) {
    return;
  }

  await new Promise((resolve, reject) =>
    scope.editorAPI.saveManager.saveInBackground(
      resolve,
      reject,
      'sectionsMigration',
      {
        forceOBMigration: true,
        sourceOfStart: 'sectionsMigration_bgSave',
      },
    ),
  );

  callback?.();
}
