import React from 'react';
import _ from 'lodash';
import * as util from '@/util';

import { derivePublishPanelDataFromProps } from '../../topBarComponent/mapTopBarPropsToButtons';
import {
  mapStateToProps,
  mapDispatchToProps,
  type PublishPanelDispatchProps,
} from './publishPanelMappers';

import TextAndLinkPanel from '../textAndLinkPanel';

const {
  connect,
  STORES: { EDITOR_API },
} = util.hoc;

interface PublishPanelProps extends PublishPanelDispatchProps {
  canUserPublish: boolean;
  isSitePublished: boolean;
  sitePublicUrl: string;
}

class PublishPanel extends React.Component<PublishPanelProps> {
  state = {
    panelData: null as AnyFixMe,
  };

  static getDerivedStateFromProps(props: AnyFixMe) {
    const panelData = derivePublishPanelDataFromProps(props);

    return { panelData };
  }

  render() {
    const { panelData } = this.state;

    return <TextAndLinkPanel panelData={panelData} />;
  }
}

const ConnectedPublishPanel = _.flow(
  connect(EDITOR_API, mapStateToProps, mapDispatchToProps),
)(PublishPanel);

export default ConnectedPublishPanel;
