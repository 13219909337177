import _ from 'lodash';
import * as util from '@/util';
import * as coreBi from '@/coreBi';
import * as platformEvents from 'platformEvents';
import constants from '@/constants';
import * as stateManagement from '@/stateManagement';
import welcomeScreenMobileTooltip from './mobileElementSettings/welcomeScreenMobileTooltip';
import mobileBrowserThemeColorTooltip from './mobileElementSettings/mobileBrowserThemeColorTooltip';
import { editorWixRecorder } from '@/util';

const {
  componentPanels,
  ROOT_COMPS: {
    MOBILE_EDITOR: { MOBILE_TOOLS_ITEMS },
  },
} = constants;

function quickActionBarIsFullyFunctional(editorAPI: AnyFixMe) {
  return _.reject(editorAPI.mobile.qab.getActions(), 'isDynamic').length > 0;
}

function quickActionBarAttachedToMasterPage(editorAPI: AnyFixMe) {
  const qabRef = editorAPI.mobile.qab.get();
  const containerRef = editorAPI.components.getContainer(qabRef);

  return containerRef?.id === editorAPI.pages.getMasterPageId();
}

function selectQuickActionBar(editorAPI: AnyFixMe) {
  const quickActionBarRef = editorAPI.mobile.qab.get();
  editorAPI.selection.selectComponentByCompRef(quickActionBarRef);
}

function selectAndOpenManagementPanel(editorAPI: AnyFixMe) {
  selectQuickActionBar(editorAPI);
  editorAPI.panelManager.closeAllPanels();
  editorAPI.openComponentPanel(componentPanels.manage, {
    firstTimeOpen: true,
  });
}

function sendQABAddedBI(editorAPI: AnyFixMe) {
  const quickActionBarRef = editorAPI.mobile.qab.get();
  const componentType = editorAPI.components.getType(quickActionBarRef);
  editorWixRecorder.addLabel(`${componentType} added to stage`);
  editorAPI.bi.event(coreBi.events.addPanel.COMPONENT_ADDED_TO_STAGE, {
    origin: 'mobile_tools',
    category: null,
    section: null,
    component_type: componentType,
    component_id: quickActionBarRef.id,
    preset_id: null,
    adding_method: null,
    preset_data_skin:
      quickActionBarRef.skin || quickActionBarRef?.style?.skin || '',
    preset_data_tags: null,
    editor_working_mode: 'mobile',
  });
}

function quickActionBarCallback(editorAPI: AnyFixMe) {
  if (quickActionBarIsFullyFunctional(editorAPI)) {
    if (!quickActionBarAttachedToMasterPage(editorAPI)) {
      const interaction =
        util.fedopsLogger.INTERACTIONS.ATTACH_QAB_TO_MASTER_PAGE;
      util.fedopsLogger.interactionStarted(interaction);
      editorAPI.components.setContainer(
        editorAPI.mobile.qab.get(),
        editorAPI.components.get.byId(editorAPI.pages.getMasterPageId()),
      );
      editorAPI.dsActions.waitForChangesApplied(() => {
        util.fedopsLogger.interactionEnded(interaction);
        selectAndOpenManagementPanel(editorAPI);
      });
      return;
    }

    selectQuickActionBar(editorAPI);
    return;
  }
  util.fedopsLogger.interactionStarted(
    util.fedopsLogger.INTERACTIONS.ADD_QUICK_ACTION_BAR,
  );
  editorAPI.mobile.actionBar.version.upgrade();
  editorAPI.dsActions.waitForChangesApplied(function () {
    sendQABAddedBI(editorAPI);
    selectAndOpenManagementPanel(editorAPI);
    util.fedopsLogger.interactionEnded(
      util.fedopsLogger.INTERACTIONS.ADD_QUICK_ACTION_BAR,
    );
    const backToTopButton = getBackToTopButton(editorAPI);
    if (backToTopButton) {
      // eslint-disable-next-line @wix/santa-editor/deprecatedDSApi
      editorAPI.components.layout.updateDock(backToTopButton, {
        bottom: { px: 87 },
      });
    }
  });
}

function welcomeScreenCallback(editorAPI: AnyFixMe) {
  const welcomeScreenConfigError =
    editorAPI.store.getState().welcomeScreen?.error ?? false;
  if (welcomeScreenConfigError) {
    return;
  }
  const isDraftMode = editorAPI.dsRead.generalInfo.isDraft();
  if (editorAPI.dsRead.generalInfo.isFirstSave() || isDraftMode) {
    editorAPI.saveManager.openSaveReminderPanel(
      'Notification_Box_Welcome_Screen_Save_Site_Text',
      'Notification_Box_Welcome_Screen_Save_Site_Header',
      'Notification_Box_Welcome_Screen_Save_Site_Save_Button',
      false,
    );
  } else {
    editorAPI.panelManager.openPanel('welcomeScreen.welcomeScreenPanel');
  }
}

function browserThemeColorCallback(editorAPI: AnyFixMe) {
  editorAPI.panelManager.openPanel('panels.focusPanels.browserThemeColorPanel');
}

function backToTopCallback(editorAPI: AnyFixMe) {
  editorAPI.panelManager.closeAllPanels();
  const dockedOverrides = isQABExist(editorAPI) ? { bottom: { px: 87 } } : {};
  editorAPI.dsActions.mobile.mobileOnlyComponents.toggleBackToTopButton(
    true,
    dockedOverrides,
  );
  editorAPI.dsActions.waitForChangesApplied(() => {
    const backToTopButton = getBackToTopButton(editorAPI);
    editorAPI.selection.selectComponentByCompRef(backToTopButton);
    editorAPI.panelManager.openComponentPanel(
      'compPanels.panels.BackToTopButton.changePanel',
    );
    editorAPI.dsActions.platform.notifyAppsOnCustomEvent(
      platformEvents.factory.toggleBackToTopButtonOn({}),
    );
  });
  editorAPI.bi.event(coreBi.events.addPanel.COMPONENT_ADDED_TO_STAGE, {
    origin: 'mobile_tools',
    category: null,
    section: null,
    preset_id: null,
    adding_method: null,
    preset_data_tags: null,
    editor_working_mode: 'mobile',
  });
}

const getMobileElementsAPI = (editorAPI: AnyFixMe) => ({
  [MOBILE_TOOLS_ITEMS.QUICK_ACTION_BAR]: () =>
    quickActionBarCallback(editorAPI),

  [MOBILE_TOOLS_ITEMS.BACK_TO_TOP]: () => backToTopCallback(editorAPI),

  [MOBILE_TOOLS_ITEMS.BROWSE_THEME_COLOR]: () =>
    browserThemeColorCallback(editorAPI),

  [MOBILE_TOOLS_ITEMS.WELCOME_SCREEN]: () => welcomeScreenCallback(editorAPI),
});

function getBackToTopButton(editorAPI: AnyFixMe) {
  return editorAPI.components.get.byType(
    'wysiwyg.viewer.components.BackToTopButton',
  )[0];
}

function isQABExist(editorAPI: AnyFixMe) {
  return !_.isEmpty(editorAPI.mobile.qab.getActions());
}

const shouldHaveNewQuickActionBar = _.once(function shouldHaveNewQuickActionBar(
  editorAPI,
) {
  const isEnabled = stateManagement.mobile.selectors.qab.isEnabled(
    editorAPI.store.getState(),
  );
  const hasLegacyActionBar = editorAPI.mobile.actionBar.isEnabled();
  return (
    isEnabled &&
    (quickActionBarIsFullyFunctional(editorAPI) || !hasLegacyActionBar)
  );
});

const shouldHaveLegacyActionBar = _.once(function shouldHaveLegacyActionBar(
  editorAPI,
) {
  const isEnabled = stateManagement.mobile.selectors.qab.isEnabled(
    editorAPI.store.getState(),
  );
  const hasLegacyActionBar = editorAPI.mobile.actionBar.isEnabled();
  return isEnabled && hasLegacyActionBar;
});

function getQuickActionBarData(editorAPI: AnyFixMe) {
  const mobileElementsAPI = getMobileElementsAPI(editorAPI);

  if (shouldHaveNewQuickActionBar(editorAPI)) {
    return [
      {
        id: MOBILE_TOOLS_ITEMS.QUICK_ACTION_BAR,
        title: 'mobile_features_feature_title_quick_actions',
        desc: 'mobile_features_feature_text_quick_actions',
        symbol: 'mobile_left_menu_action_bar_v2',
        expandSymbol: 'plusBig',
        expandSymbolDisabled: 'successIcon',
        onClick: mobileElementsAPI[MOBILE_TOOLS_ITEMS.QUICK_ACTION_BAR],
        tooltip: {
          key: 'qabActivatedTooltip',
          id: 'qabActivatedTooltip',
          content: 'mobile_features_feature_text_add_tosite',
        },
        disabled:
          quickActionBarIsFullyFunctional(editorAPI) &&
          quickActionBarAttachedToMasterPage(editorAPI),
        disabledTooltip: {
          key: 'qabDisabledTooltip',
          id: 'qabDisabledTooltip',
          content: 'mobile_quick_actions_preinstalled_tooltip',
        },
      },
    ];
  }

  if (shouldHaveLegacyActionBar(editorAPI)) {
    return [
      {
        id: MOBILE_TOOLS_ITEMS.ACTION_BAR,
        title: 'mobile_action_bar_title',
        desc: 'mobile_features_feature_text_quick_actions',
        symbol: 'mobile_left_menu_action_bar_v2',
        helpId: 'ec11d690-b5f0-4c35-b95f-2e928067049d',
        toggle: {
          action: editorAPI.mobile.actionBar.enable,
          state: editorAPI.mobile.actionBar.isEnabled,
        },
      },
    ];
  }

  return [];
}

function getMobileElementsData(editorAPI: AnyFixMe) {
  const mobileElementsAPI = getMobileElementsAPI(editorAPI);

  const mobileElementDataItems = [
    ...getQuickActionBarData(editorAPI),
    {
      id: MOBILE_TOOLS_ITEMS.BACK_TO_TOP,
      title: 'mobile_features_feature_title_backto_top',
      desc: 'mobile_features_feature_text_backto_top',
      symbol: 'mobile_left_menu_back_to_top_v2',
      expandSymbol: 'plusBig',
      expandSymbolDisabled: 'successIcon',
      helpId: 'ca3e54a0-3d76-4ede-af8c-f9ccbaeeba5d',
      disabled: Boolean(getBackToTopButton(editorAPI)),
      onClick: mobileElementsAPI[MOBILE_TOOLS_ITEMS.BACK_TO_TOP],
      tooltip: {
        key: 'addBackToTopToSiteTooltip',
        id: 'addBackToTopToSiteTooltip',
        content: 'Mobile_features_feature_text_add_tosite',
      },
      disabledTooltip: {
        key: 'backToTopInstalledOnSiteTooltip',
        id: 'backToTopInstalledOnSiteTooltip',
        content: 'Mobile_back_to_top_preinstalled_tooltip',
      },
    },
    {
      id: MOBILE_TOOLS_ITEMS.WELCOME_SCREEN,
      title: 'mobile_features_feature_title_welcome_screen',
      desc: 'mobile_features_feature_text_welcome_screen',
      onClick: mobileElementsAPI[MOBILE_TOOLS_ITEMS.WELCOME_SCREEN],
      customTooltipContent: welcomeScreenMobileTooltip,
      symbol: 'welcome_mobile_tools',
    },
    {
      id: MOBILE_TOOLS_ITEMS.BROWSE_THEME_COLOR,
      title: 'mobile_features_feature_title_browser_theme',
      desc: 'mobile_features_feature_text_browser_theme',
      onClick: mobileElementsAPI[MOBILE_TOOLS_ITEMS.BROWSE_THEME_COLOR],
      customTooltipContent: mobileBrowserThemeColorTooltip,
      symbol: 'browser_color_mobile_tools',
    },
  ];

  return mobileElementDataItems;
}

export { getMobileElementsData, getMobileElementsAPI };
