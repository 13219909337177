// @ts-nocheck
import ReactDOM from 'reactDOM';
import createReactClass from 'create-react-class';
import _ from 'lodash';
import $ from 'zepto';
import * as core from '@/core';
import * as stateManagement from '@/stateManagement';
import * as designPanelUtils from '@/designPanelUtils';
import * as advancedStylePanel from '@/advancedStylePanel';
import designPanelMixin from '../core/designPanelMixin';
import experiment from 'experiment';
import * as util from '@/util';

import React from 'react';
import DesignPanel from '../core/designPanel';
import * as baseUI from '@/baseUI';

const { panelPosition } = stateManagement.domMeasurements.hoc;
const { getStyleId } = stateManagement.components.selectors;

const CONNECTABLE_GALLERY_TYPES = [
  'wysiwyg.viewer.components.SlideShowGallery',
  'wysiwyg.viewer.components.SliderGallery',
  'wysiwyg.viewer.components.MatrixGallery',
  'wysiwyg.viewer.components.PaginatedGridGallery',
];

function buildNewGalleryCompStructure(
  editorAPI,
  compPointer,
  galleryStructure,
  data,
  properties,
  style,
  currentLayout,
) {
  const newGalleryCompStructure = {
    componentType: galleryStructure.componentType,
    type: 'Component',
    style,
    layout: currentLayout,
    data,
    props: properties,
    connections: editorAPI.platform.controllers.connections.get(compPointer),
  };

  if (
    CONNECTABLE_GALLERY_TYPES.includes(newGalleryCompStructure.componentType) &&
    newGalleryCompStructure.connections?.items
  ) {
    newGalleryCompStructure.connections.items =
      newGalleryCompStructure.connections.items.filter({
        type: 'WixCodeConnectionItem',
      });
  }

  return Object.assign(newGalleryCompStructure, {
    layout: designPanelUtils.changeGalleryHelper.convertGalleryLayouts(
      editorAPI,
      compPointer,
      newGalleryCompStructure,
    ),
  });
}

function changeGalleryType(
  compPointer,
  currentGalleryStructure,
  origProperties,
  origDataItem,
  style,
  layout,
  editorAPI,
) {
  const myPointer = compPointer[0]; //compPointer is an array of selected components
  const newProperties =
    designPanelUtils.changeGalleryHelper.convertGalleryProperties(
      origProperties,
      currentGalleryStructure.props,
      editorAPI,
    );
  const newGalleryStructure = buildNewGalleryCompStructure(
    editorAPI,
    myPointer,
    currentGalleryStructure,
    origDataItem,
    newProperties,
    style,
    layout,
  );
  const galleryContainer =
    editorAPI.components.getContainer_DEPRECATED_BAD_PERFORMANCE(myPointer);
  return editorAPI.components
    .remove(myPointer, { isReplacingComp: true })
    .then(() => {
      editorAPI.components.add(
        galleryContainer,
        newGalleryStructure,
        myPointer.id,
      );
    });
}

// eslint-disable-next-line react/prefer-es6-class
const ChangeGalleryDesignPanel = createReactClass({
  displayName: 'changeGalleryDesignPanel',
  mixins: [designPanelMixin, core.mixins.editorAPIMixin],
  render() {
    const AdvanceStyleClass = this.getAdvancedStyleClass();

    return (
      <DesignPanel ref="designPanel" {...this.getPropsForDesignPanel()}>
        {!this.state.advancedStyling && !!AdvanceStyleClass ? (
          <div key="customizeDesignBtn" className="button-wrapper">
            <baseUI.button
              ref="customizeDesign"
              label="design_customize_button"
              onClick={this.openCustomizeDesign}
              icon="customize_design_drop_light"
              className="button"
            />
          </div>
        ) : null}
        {this.state.advancedStyling && !!AdvanceStyleClass ? (
          <div key="advancedStylePanel">
            <AdvanceStyleClass {...this.getAdvancedStylingProps()} />
          </div>
        ) : null}
      </DesignPanel>
    );
  },
  getDefaultProps() {
    return {
      backButtonLabel: 'design_panel_back_button_label',
    };
  },
  getStateFromProps(props) {
    const editorAPI = this.getEditorAPI();
    const layoutToMaintain = _.omit(
      editorAPI.components.layout.get(props.selectedComponent),
      'anchors',
    );

    return {
      propertyItemToMaintain: editorAPI.components.properties.get(
        props.selectedComponent,
      ),
      dataItemToMaintain: editorAPI.components.data.get(
        props.selectedComponent,
      ),
      layoutToMaintain,
      currentStyleId: getStyleId(props.selectedComponent, editorAPI.dsRead),
      currentStyleDef: editorAPI.components.style.get(
        this.props.selectedComponent,
      ),
    };
  },
  UNSAFE_componentWillReceiveProps(nextProps) {
    const editorAPI = this.getEditorAPI();
    const currentStyleId = getStyleId(
      nextProps.selectedComponent,
      editorAPI.dsRead,
    );
    const prevStyleId = this.state.currentStyleId;
    const isStyleChanged = currentStyleId !== prevStyleId;
    this.setState({
      isStyleChanged,
    });
  },

  changePermanentState(compDef) {
    if (this.pendingGalleryChange) {
      return;
    }
    this.pendingGalleryChange = true;

    const editorAPI = this.getEditorAPI();
    return changeGalleryType(
      this.props.selectedComponent,
      compDef,
      this.state.propertyItemToMaintain,
      this.state.dataItemToMaintain,
      compDef.style,
      this.state.layoutToMaintain,
      editorAPI,
    ).then(() => {
      editorAPI.dsActions.waitForChangesApplied(
        function () {
          this.pendingGalleryChange = false;

          if (experiment.isOpen('animateChangeStyleButton')) {
            const customizeDesign = $(
              ReactDOM.findDOMNode(this.refs.customizeDesign),
            );
            customizeDesign.removeClass('animate-button');
            util.animationFrameUtils.request(
              customizeDesign.addClass.bind(customizeDesign, 'animate-button'),
            );
          }
        }.bind(this),
      );
    });
  },
  changeTempState() {},
  maintainOriginalState() {},
  isSelectedItem(compDef) {
    const compType = compDef.compType || compDef.componentType;
    if (compType === this.props.compType) {
      return core.styleManager.isEqual(
        compDef.style,
        this.state.currentStyleDef,
      );
    }
    return false;
  },
  getAdvancedStyleClass() {
    if (
      !designPanelUtils.customizeDesignComponents.isGalleryCustomizable(
        this.props.compType,
      )
    ) {
      return null;
    }
    if (
      designPanelUtils.customizeDesignComponents.isTPAGalleryAdvancedStyle(
        this.props.compType,
      )
    ) {
      return advancedStylePanel.tpaGallery;
    }
    return advancedStylePanel.panel;
  },
});

const wrappedPanel = panelPosition()(ChangeGalleryDesignPanel);
wrappedPanel.pure = ChangeGalleryDesignPanel;

export default wrappedPanel;
