import * as util from '@/util';
import createReactClass from 'create-react-class';
import * as core from '@/core';
import addPanelDataConsts from '@/addPanelDataConsts';
import constants from '@/constants';
import React from 'react';
import ListSection from './listSection';
import { getCollections, EVENTS } from '@/wixData';
import {
  notifyApplication,
  notifyDataBindingApplication,
} from './dataSectionUtils';
import type { EditorAPI } from '@/editorAPI';
import { events } from '@/coreBi';

// eslint-disable-next-line react/prefer-es6-class
const DataSection = createReactClass({
  displayName: 'dataSection',
  mixins: [core.mixins.editorAPIMixin],
  onItemClick(item: AnyFixMe) {
    const editorAPI = this.getEditorAPI() as EditorAPI;

    switch (item.action) {
      case addPanelDataConsts.CLICK_ACTION.DATA.ADD_COLLECTION:
        notifyDataBindingApplication(editorAPI, {
          eventType: EVENTS.createCollectionButtonClicked,
          eventPayload: {
            origin: 'add_panel',
          },
        });
        break;

      case addPanelDataConsts.CLICK_ACTION.DATA.ADD_DATASET:
        this.props.onClick(
          item.structure,
          /*sectionTitle*/ undefined,
          /*sectionTags*/ undefined,
          'dataset',
        );
        break;

      case addPanelDataConsts.CLICK_ACTION.DATA.ADD_RICHCONTENT:
        this.props.onClick(
          item.structure,
          /*sectionTitle*/ undefined,
          /*sectionTags*/ undefined,
          'dataset',
          editorAPI,
          undefined,
        );
        break;

      case addPanelDataConsts.CLICK_ACTION.DATA.ADD_FORM_DATASET:
        this.props.onClick(
          item.structure,
          /*sectionTitle*/ undefined,
          /*sectionTags*/ undefined,
          'form_dataset',
        );
        break;

      case addPanelDataConsts.CLICK_ACTION.DATA.ADD_PRESET:
        notifyDataBindingApplication(editorAPI, {
          eventType: EVENTS.openAddPreset,
          eventPayload: {
            origin: item.origin || 'add_panel',
          },
        });
        break;

      case addPanelDataConsts.CLICK_ACTION.DATA.INSTALL_APP_AND_NOTIFY: {
        const event = events.pages.add_dashboard_page_from_add_panel;
        const isMultilingualEnabled =
          editorAPI.language.multilingual.isEnabled();

        editorAPI.bi.event(event, { multilingual: isMultilingualEnabled });

        return editorAPI.tpa.installAppIfNeeded(item.appDefinitionId).then(() =>
          notifyApplication(editorAPI, item.appDefinitionId, {
            eventType: addPanelDataConsts.CLICK_ACTION.EVENT.APP_CLICKED,
            eventPayload: item.params,
          }),
        );
      }

      case addPanelDataConsts.CLICK_ACTION.DATA.ADD_DP_DATASET:
        const pageId = editorAPI.dsRead.pages.getPrimaryPageId();
        notifyDataBindingApplication(editorAPI, {
          eventType: EVENTS.addDynamicPageClicked,
          eventPayload: {
            pageRef: editorAPI.dsRead.pages.getReference(pageId),
            origin: 'add_panel',
          },
        });
        break;
    }

    editorAPI.panelManager.closePanelByName(
      constants.SUPER_APPS.WIX_DATA_PANEL_NAME,
    );
  },
  getDisabledToolTip(item: AnyFixMe) {
    if (this.isDisabled(item)) {
      if (!this.props.hasCollections) {
        return item.disabledTooltipNoCollection;
      }
      return item.disabledTooltipNonConnectablePage;
    }
    return item.tooltip;
  },
  isDisabled(item: AnyFixMe) {
    const editorAPI = this.getEditorAPI();

    if (!this.props.hasCollections) {
      //no collection in the site, should be disabled
      return true;
    }

    const pageId = editorAPI.dsRead.pages.getFocusedPageId();
    if (
      item.action === addPanelDataConsts.CLICK_ACTION.DATA.ADD_DP_DATASET &&
      !editorAPI.dsRead.routers.pages.isConnectablePage(pageId)
    ) {
      //add dynamic dataset for non connectable page, should be disabled
      return true;
    }

    return false;
  },
  render() {
    return (
      <ListSection
        shouldBeDisabled={this.isDisabled}
        getDisabledToolTip={this.getDisabledToolTip}
        callback={this.onItemClick}
        {...this.props}
      />
    );
  },
});

const mapStateToProps = ({ editorAPI }: AnyFixMe) => ({
  hasCollections: getCollections(editorAPI).length > 0,
});

export default util.hoc.connect(
  util.hoc.STORES.EDITOR_API,
  mapStateToProps,
)(DataSection);
