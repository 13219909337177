// @ts-nocheck
import _ from 'lodash';
import experiment from 'experiment';
import { translate } from '@/i18n';
import constants from '@/constants';
import * as stateManagement from '@/stateManagement';
import { isResponsiveEditor } from '@wix/santa-editor-utils';
import * as platformEvents from 'platformEvents';
import * as textControls from '@/textControls';
import * as coreBi from '@/coreBi';
import * as util from '@/util';
import { triggerRefreshBindingIfNeededMobile } from '@/wixData';

import gfppDataUtils from '../../utils/gfppDataUtils';
import multiselectGfppUtils from '../../utils/multiselectGfppUtils';

import type { CompRef } from 'types/documentServices';
import type { EditorAPI } from '@/editorAPI';

const { multipleRichTextCompsAreSelected } = multiselectGfppUtils;
const { isInInteractionMode, isShownOnlyOnVariant } =
  stateManagement.interactions.selectors;
const { isTranslating } = stateManagement.multilingual.selectors;
const { ACTIONS } = constants.ROOT_COMPS.GFPP;
const { isConnectedToDataBindingController } =
  stateManagement.platform.selectors;

function getThemeFonts(editorAPI) {
  // eslint-disable-next-line @wix/santa-editor/deprecatedFontsApi
  return editorAPI.dsRead.theme.fonts.getAll();
}

function getSelectedComponentText(editorAPI, compRef) {
  return editorAPI.components.data.get(compRef).text;
}

function getSelectedComponentScale(editorAPI: EditorAPI, compRef: CompRef) {
  return editorAPI.components.layout.get_scale(compRef);
}

function calculateTextSizeForMobile(editorAPI, compRef, scale) {
  const commonTextSize = textControls.mobileUtils.getMostCommonTextSize(
    getSelectedComponentText(editorAPI, compRef),
    getThemeFonts(editorAPI),
  );
  return util.mobileUtils.convertFontSizeToMobile(commonTextSize, scale);
}

function changeScale(
  isScaleUp: boolean,
  editorAPI: EditorAPI,
  compRefs: CompRef | CompRef[],
) {
  if (isScalingDisabled(isScaleUp, editorAPI, compRefs)) {
    return;
  }
  if (multipleRichTextCompsAreSelected(editorAPI, compRefs)) {
    return compRefs.forEach((compRef) =>
      changeCompScale(isScaleUp, editorAPI, compRef),
    );
  }
  const selectedComp = Array.isArray(compRefs) ? compRefs[0] : compRefs;
  return changeCompScale(isScaleUp, editorAPI, selectedComp);
}

function changeCompScale(
  isScaleUp: boolean,
  editorAPI: EditorAPI,
  compRef: CompRef,
) {
  const currentScale = getSelectedComponentScale(editorAPI, compRef);
  const unscaledTextSize = calculateTextSizeForMobile(editorAPI, compRef, 1);
  const scaledTextSize = _.round(unscaledTextSize * currentScale);
  const step = isScaleUp ? 1 : -1;
  const newScale = (scaledTextSize + step) / Math.max(unscaledTextSize, 1); // no division by zero in this house!

  editorAPI.components.layout.updateAndAdjustLayout(compRef, {
    scale: newScale,
  });

  triggerRefreshBindingIfNeededMobile(editorAPI, compRef);

  editorAPI.dsActions.platform.notifyAppsOnCustomEvent(
    platformEvents.factory.mobileTextScaleChanged({ compRef }),
  );
}

function isScalingDisabled(
  isScaleUp: boolean,
  editorAPI: EditorAPI,
  compRefs: CompRef[] | CompRef,
) {
  if (Array.isArray(compRefs)) {
    return compRefs.some((compRef) =>
      isScalingDisabledForComp(isScaleUp, editorAPI, compRef),
    );
  }
  return isScalingDisabledForComp(isScaleUp, editorAPI, compRefs);
}

function isScalingDisabledForComp(
  isScaleUp: boolean,
  editorAPI: EditorAPI,
  compRef: CompRef,
) {
  const currentScale = getSelectedComponentScale(editorAPI, compRef);
  const currentTextSize = calculateTextSizeForMobile(
    editorAPI,
    compRef,
    currentScale,
  );
  return (
    (isScaleUp && currentTextSize >= 150) ||
    (!isScaleUp && currentTextSize <= 1)
  );
}

function getScalingTooltip(
  isScaleUp: boolean,
  editorAPI: EditorAPI,
  compRef: CompRef[] | CompRef,
) {
  if (multipleRichTextCompsAreSelected(editorAPI, compRef)) {
    return isScaleUp
      ? translate('PLATFORM_Widget_Design_Tooltip_Increase_Font')
      : translate('PLATFORM_Widget_Design_Tooltip_Decrease_Font');
  }
  const selectedComp = Array.isArray(compRef) ? compRef[0] : compRef;
  const scale = getSelectedComponentScale(editorAPI, selectedComp);
  const textSize = calculateTextSizeForMobile(editorAPI, selectedComp, scale);
  return `${_.round(textSize)}px`;
}

function getDataEditButtonLabel(editorAPI, componentRefs) {
  return util.modes.isStyleOnlyModeEnabled(editorAPI, componentRefs)
    ? 'gfpp_mainaction_style_vs_content_text'
    : 'gfpp_mainaction_text';
}

function isAITextCreatorPanelOpen(editorAPI: EditorAPI) {
  return (
    editorAPI.panelManager.isPanelOpened(
      `compPanels.dynamicPanels.${constants.componentPanels.aiTextGenerator}`,
    ) ||
    editorAPI.panelManager.isPanelOpened(
      `compPanels.dynamicPanels.${constants.componentPanels.aiTextCreatorV2}`,
    )
  );
}

function isAIToolsPanelOpen(editorAPI: EditorAPI) {
  return editorAPI.panelManager.isPanelOpened(
    `compPanels.dynamicPanels.aiToolsPanel`,
  );
}

function getMainActions(editorAPI, compRefs: CompRef[]) {
  const isGfppAiButtonUiEnabled = experiment.isOpen('se_gfppAiButtonUI');
  const compRef = Array.isArray(compRefs) ? compRefs[0] : compRefs;
  const state = editorAPI.store.getState();

  if (isInInteractionMode(state) && !isShownOnlyOnVariant(editorAPI, compRef)) {
    return [];
  }

  // Temp solution until generic aiTools gfpp icon is implemented
  const aiTextToolsIcon = {
    label: undefined,
    icon: !isAIToolsPanelOpen(editorAPI)
      ? 'sparklesBoldAnimation'
      : 'sparklesBold',
    tooltip: 'ai_text_creator_gfpp_main_action',
    className: 'gfpp-btn_ai-icon_animation',
    automationId: 'gfpp-ai-tools-icon-aid',
    isSelected: gfppDataUtils.getPanelStateFn('aiTools'),
    onClick: gfppDataUtils.getTogglePanelFn('aiTools'),
  };

  const mainInteractions = [
    {
      automationId: 'gfpp_text-edit-aid',
      label: getDataEditButtonLabel,
      isApplied: gfppDataUtils.getDataDefinedFn('text'),
      isSelected: gfppDataUtils.getPanelStateFn(ACTIONS.SETTINGS),
      onClick(editorAPI, compRef) {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/is-array
        if (_.isArray(compRef) && compRef.length > 1) {
          if (editorAPI.text.isEditingText()) {
            editorAPI.text.stopEditing();
          }
        }
        if (!editorAPI.text.isEditingText()) {
          editorAPI.bi.event(coreBi.events.textControls.Text_Panel_opened, {
            component_id: compRef?.id,
            origin: 'gfpp',
          });
          editorAPI.text.startEditing();
        }
      },
    },
  ];

  const smartWriter = {
    label: 'gfpp_SmartWriter_Action_GetText',
    className: 'gfpp-btn_smart-writer',
    automationId: 'gfpp-smart-writer-aid',
    isSelected: gfppDataUtils.getPanelStateFn('smartWriter'),
    onClick: () => {
      const openPanelInteraction = util.fedopsLogger.mapInteraction(
        util.fedopsLogger.INTERACTIONS.SMART_WRITER_OPEN,
      );
      openPanelInteraction.start();
      editorAPI.openComponentPanel(constants.componentPanels.smartWriter, {
        openPanelInteraction,
      });
    },
  };

  const aiTextGeneratorIcon = {
    label: undefined,
    icon:
      isGfppAiButtonUiEnabled && !isAITextCreatorPanelOpen(editorAPI)
        ? 'sparklesBoldAnimation'
        : 'sparklesBold',
    tooltip: 'ai_text_creator_gfpp_main_action',
    className: `${
      isGfppAiButtonUiEnabled
        ? 'gfpp-btn_ai-icon_animation'
        : 'gfpp-btn_ai-icon'
    }`,
    automationId: 'gfpp-ai-text-creator-icon-aid',
    isSelected: isAITextCreatorPanelOpen(editorAPI),
    onClick: (..._arguments) => {
      if (isAITextCreatorPanelOpen(editorAPI)) {
        return editorAPI.closeCompPanelIfExists();
      }

      const openPanelInteraction = util.fedopsLogger.mapInteraction(
        util.fedopsLogger.INTERACTIONS.AI_TEXTGENERATOR_OPEN,
      );
      openPanelInteraction.start();
      editorAPI.openComponentPanel(constants.componentPanels.aiTextGenerator, {
        openPanelInteraction,
        origin: _arguments[2],
      });
    },
  };

  const aiTextGenerator = {
    label: 'ai_text_creator_gfpp_main_action',
    className: 'gfpp-btn_ai-text-generator',
    automationId: 'gfpp-ai-text-creator-aid',
    isSelected: gfppDataUtils.getPanelStateFn('aiTextGenerator'),
    onClick: (..._arguments) => {
      if (isAITextCreatorPanelOpen(editorAPI)) return;

      const openPanelInteraction = util.fedopsLogger.mapInteraction(
        util.fedopsLogger.INTERACTIONS.AI_TEXTGENERATOR_OPEN,
      );
      openPanelInteraction.start();
      editorAPI.openComponentPanel(constants.componentPanels.aiTextGenerator, {
        openPanelInteraction,
        origin: _arguments[2],
      });
    },
  };

  if (
    !isTranslating(state) &&
    !util.modes.isStyleOnlyModeEnabled(editorAPI, compRefs)
  ) {
    const userLanguage = editorAPI.generalInfo.getLanguage();
    const ADISupportedLanguages = [
      'pt',
      'es',
      'fr',
      'de',
      'ru',
      'ja',
      'it',
      'nl',
      'ko',
      'sv',
      'pl',
      'tr',
      'en',
    ];
    const isSchoolMode = stateManagement.schoolMode.selectors.isEnabled(
      editorAPI.store.getState(),
    );
    const isAITextGeneratorEnabledInClassicEditor =
      experiment.isOpen('se_aiTextGenerator') && !isSchoolMode;

    const isAITextGeneratorEnabled =
      !util.appStudioUtils.isAppStudio() &&
      (isResponsiveEditor()
        ? experiment.isOpen('se_aiTextGeneratorForEditorX')
        : isAITextGeneratorEnabledInClassicEditor);

    if (isAITextGeneratorEnabled) {
      if (
        !isResponsiveEditor() &&
        (experiment.getValue('se_aiTextGeneratorGfppIcon') === 'B' ||
          experiment.isOpen('se_aiTextTools'))
      ) {
        if (experiment.isOpen('se_aiTextTools')) {
          mainInteractions.unshift(aiTextToolsIcon);
        } else {
          mainInteractions.unshift(aiTextGeneratorIcon);
        }
      }
      mainInteractions.push(aiTextGenerator);
    } else if (ADISupportedLanguages.includes(userLanguage) && !isSchoolMode) {
      mainInteractions.push(smartWriter);
    }
  }

  return mainInteractions;
}

function getEnabledActions(editorAPI) {
  const actions = [
    ACTIONS.TEXT_LINK,
    ACTIONS.DESIGN,
    ACTIONS.ANIMATION,
    ACTIONS.HELP,
  ];

  const isSchoolMode = stateManagement.schoolMode.selectors.isEnabled(
    editorAPI.store.getState(),
  );

  if (
    experiment.isOpen('se_aiGfpp') &&
    !isSchoolMode &&
    !isResponsiveEditor() &&
    !util.appStudioUtils.isAppStudio()
  ) {
    return [ACTIONS.AI_TOOLS, ...actions];
  }

  return actions;
}

const wRichTextGFPPData = {
  mainActions: getMainActions,
  enabledActions: getEnabledActions,
  mobileMainActions: [],
  mobileEnabledActions: [
    ACTIONS.SCALE_UP,
    ACTIONS.SCALE_DOWN,
    ACTIONS.SETTINGS,
    ACTIONS.HIDE,
    ACTIONS.HELP,
    ACTIONS.ANIMATION,
  ],
  presetActions: {
    help: {
      helpId: 'a6046c00-b434-4068-97c0-d5bc472c691d',
      mobileHelpId: '79267696-2d13-44bd-bea0-d2ed6cef5228',
    },
    scale_up: {
      icon: 'scale_up',
      tooltip: (editorAPI, compRef) =>
        getScalingTooltip(true, editorAPI, compRef),
      shouldTranslate: false,
      isDisabled: isScalingDisabled.bind(null, true),
      onClick: changeScale.bind(null, true),
    },
    scale_down: {
      icon: 'scale_down',
      tooltip: (editorAPI, compRef) =>
        getScalingTooltip(false, editorAPI, compRef),
      shouldTranslate: false,
      isDisabled: isScalingDisabled.bind(null, false),
      onClick: changeScale.bind(null, false),
    },
    [ACTIONS.DESIGN]: {
      isSupported: (editorAPI, compRefs) => {
        const state = editorAPI.store.getState();
        return (
          isInInteractionMode(state) &&
          !isShownOnlyOnVariant(editorAPI, compRefs[0])
        );
      },
      onClick: (editorAPI, compRef) => {
        // TODO: review BI event
        editorAPI.bi.event(coreBi.events.textControls.Text_Panel_opened, {
          component_id: compRef?.id,
          origin: 'gfpp',
        });
        util.fedopsLogger.interactionStarted(
          util.fedopsLogger.INTERACTIONS.INTERACTIONS_FEATURE
            .OPEN_EDIT_TEXT_PANEL,
        );
        editorAPI.panelManager.openComponentPanel(
          'interactions.panels.textSettingsPanel',
          {},
        );
      },
    },
    [ACTIONS.TEXT_LINK]: {
      isSupported: (editorAPI, compRefs) => {
        const isConnectedToData = isConnectedToDataBindingController(
          _.head(compRefs),
          editorAPI.dsRead,
        );
        const isDesignOnly =
          editorAPI.components.is.getDataEditOptions(_.head(compRefs)) ===
          constants.META_DATA.DATA_EDIT_OPTIONS.TEXT.STYLE_ONLY;

        return !isConnectedToData && !isDesignOnly;
      },
    },
  },
};

export default wRichTextGFPPData;
