import { BaseEntryScope, BasePublicApi } from '@/apilib';
import { ComponentsApiKey, DocumentServicesApiKey, EditorAPIKey } from '@/apis';
import type * as DS from '@wix/document-services-types';
import type { DocumentServicesObject } from 'types/documentServices';
import type { ComponentsApi } from '@/components';

export class DataBindingScope extends BaseEntryScope {
  editorApi = this.useDependency(EditorAPIKey);
  documentServices: DocumentServicesObject = this.useDependency(
    DocumentServicesApiKey,
  );
  componentsApi: ComponentsApi = this.useDependency(ComponentsApiKey);
}

function enabled(scope: DataBindingScope, compRef: DS.CompRef) {
  return !scope.documentServices.components.refComponents.getRefHostCompPointer(
    compRef,
  );
}

function getDataBindingConnection(
  scope: DataBindingScope,
  compRef: DS.CompRef,
): DS.Connection | undefined {
  // @ts-expect-error TS is failing to resolve the correct type
  const connections: DS.Connection[] =
    scope.editorApi.platform.controllers.connections.get(compRef);

  return connections.find((connection: DS.Connection) => {
    const appData = scope.componentsApi.data.get(connection.controllerRef);

    return appData?.applicationId === 'dataBinding';
  });
}

export class DataBindingApi extends BasePublicApi<DataBindingScope> {
  enabled = this.bindScope(enabled);
  getDataBindingConnection = this.bindScope(getDataBindingConnection);
}
