// @ts-nocheck
import React from 'react';
import * as baseUI from '@/baseUI';
import type { EditorAPI } from '@/editorAPI';

const translations = {
  UNIQUE_TEXT_FOR_MOBILE: {
    TEXT: 'Create unique text for mobile. To optimize your site for mobile viewing, go to Text Settings and add mobile-only text. ',
    LINK: 'Learn more',
  },
};

function close(editorAPI: EditorAPI) {
  editorAPI.floatingBubble.hide();
}

function open(editorAPI: EditorAPI) {
  const [selectedCompRef] = editorAPI.selection.getSelectedComponents();

  const { absoluteTop, absoluteLeft } =
    editorAPI.components.layout.measure.getRelativeToViewportBoundingBox(
      selectedCompRef,
    );

  const targetLayout = {
    height: 0,
    width: 0,
    top: absoluteTop + 50 || 0,
    left: absoluteLeft + 500 || 0,
  };

  const propsForBubble = {
    noArrow: true,
    shake: false,
    behindPopUps: true,
    hideMethod: editorAPI.toolsControl.toggleHalfOpacityTools.bind(this, false),
    alignment: 'bottom',
  };

  const innerTemplate = React.createElement(baseUI.popoverTemplates.quickTip, {
    symbol: 'idea-bulb',
    text: translations.UNIQUE_TEXT_FOR_MOBILE.TEXT,
    closeAction: editorAPI.floatingBubble.hide,
    linkActionText: translations.UNIQUE_TEXT_FOR_MOBILE.LINK,
    linkAction: () => {}, // link to the article (that isn't exist yet)
  });

  editorAPI.floatingBubble.show(innerTemplate, targetLayout, propsForBubble, {
    shouldNotHideOnMouseLeaveTarget: true,
    countDisplayTimesOptions: {
      countBy: 'site',
      maxNum: 311,
      uniqueBubbleId: 'quickTipForUniqueMobileText',
    },
    delay: 300,
    closeDelay: 15000,
  });
}

export { open, close };
