// @ts-nocheck
import _ from 'lodash';
import addPanelDataConsts from '@/addPanelDataConsts';
import * as addPanelAddCompService from '../addPanelAddCompService';
import triggerBiEvent from './triggerElementAddedBiEvent';
import * as superAppsCustomData from '../../superApps/superAppsCustomData';
import constants from '../../constants/constants';
import superAppsConstants from '../../superApps/superAppsConstants';

const APP_DEF_ID = superAppsConstants.NEW_BLOG_APP_DEF_ID;
const WIDGET_ID = '14e5b39b-6d47-99c3-3ee5-cee1c2574c89';
const itemIds = {
  SIDE_BY_SIDE: 'customFeedSideBySide',
  MAGAZINE: 'customFeedMagazine',
  POSTCARD: 'customFeedPostcard',
  ONE_COLUMN: 'customFeedOneColumn',
  EDITORIAL: 'customFeedEditorial',
};
const layoutNames = {
  [itemIds.SIDE_BY_SIDE]: 'side_by_side',
  [itemIds.MAGAZINE]: 'magazine',
  [itemIds.POSTCARD]: 'postcard',
  [itemIds.ONE_COLUMN]: 'one_column',
  [itemIds.EDITORIAL]: 'editorial',
};

const getBiParams = function (itemId) {
  return {
    element_type: 'custom_feed',
    layout: layoutNames[itemId],
  };
};

const addApp = function (
  compStructure,
  sectionTitle,
  tags,
  itemId,
  editorAPI,
  addOptions,
) {
  triggerBiEvent(editorAPI, getBiParams(itemId));
  return addPanelAddCompService.addCompOnClick(
    editorAPI,
    APP_DEF_ID,
    WIDGET_ID,
    compStructure,
    tags,
    itemId,
    { origin: constants.BI.type.ADD_WIDGET },
    addOptions,
  );
};

const onDrop = function (layoutParams, compPreset, parentComponent, editorAPI) {
  triggerBiEvent(editorAPI, getBiParams(compPreset.itemId));
  return addPanelAddCompService.addCompOnDrop(
    editorAPI,
    APP_DEF_ID,
    WIDGET_ID,
    layoutParams,
    compPreset,
    parentComponent,
    _.noop,
    { origin: constants.BI.type.ADD_WIDGET },
  );
};

function getSection() {
  return {
    type: addPanelDataConsts.SECTIONS_TYPES.PRESET,
    hide: false,
    title: 'add_section_label_newblog_customfeed',
    subNavigationTitle: 'add_section_label_newblog_customfeed',
    presetTitle: 'add_section_label_newblog_customfeed',
    tooltipTitle: 'add_section_label_newblog_customfeed',
    sectionName: 'customFeedSection',
    subNavigationHide: false,
    showSectionHeader: true,
    additionalBehaviours: {
      labelMode: addPanelDataConsts.LABEL_BEHAVIOUR.HOVER,
      infoIcon: addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
      hoverImageAction: addPanelDataConsts.HOVER_IMAGE_ACTIONS.NONE,
      iconEnabledForComps: {},
    },
    props: {
      onClick: addApp,
      onDrop,
      image:
        'addPanelData/sections/blogCustomFeedSection_en/blogCustomFeedSection_en.png',
      imageHover: null,
      items: [
        {
          id: itemIds.SIDE_BY_SIDE,
          structure: {
            type: 'Component',
            skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
            layout: {
              width: 980,
              height: 800,
              x: 0,
              y: 0,
              scale: 1,
              rotationInDegrees: 0,
              fixedPosition: false,
            },
            componentType: 'wysiwyg.viewer.components.tpapps.TPAWidget',
            data: {
              type: 'TPAWidget',
              metaData: {
                isPreset: false,
                schemaVersion: '1.0',
                isHidden: false,
              },
              applicationId: '1977',
              widgetId: '14e5b39b-6d47-99c3-3ee5-cee1c2574c89',
            },
            style: {
              type: 'TopLevelStyle',
              metaData: {
                isPreset: false,
                schemaVersion: '1.0',
                isHidden: false,
              },
              style: superAppsCustomData.getSuperAppsAddPanelStyles(
                APP_DEF_ID,
                itemIds.SIDE_BY_SIDE,
              ),
              componentClassName: 'wysiwyg.viewer.components.tpapps.TPAWidget',
              pageId: '',
              compId: '',
              styleType: 'custom',
              skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
            },
            mobileStructure: {
              componentType: 'wysiwyg.viewer.components.tpapps.TPAWidget',
              type: 'Component',
              conversionData: {
                isCustomPreset: true,
              },
              layout: {
                width: 280,
                height: 229,
                x: 20,
                y: 10,
                scale: 1,
                rotationInDegrees: 0,
                fixedPosition: false,
              },
            },
            activeModes: {},
          },
          preset: {
            rect: {
              width: 162,
              height: 154,
              x: 0,
              y: 0,
            },
            label: 'add_preset_newblog_sidebyside',
            tags: null,
          },
        },
        {
          id: itemIds.MAGAZINE,
          structure: {
            type: 'Component',
            skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
            layout: {
              width: 980,
              height: 980,
              x: 0,
              y: 0,
              scale: 1,
              rotationInDegrees: 0,
              fixedPosition: false,
            },
            componentType: 'wysiwyg.viewer.components.tpapps.TPAWidget',
            data: {
              type: 'TPAWidget',
              metaData: {
                isPreset: false,
                schemaVersion: '1.0',
                isHidden: false,
              },
              applicationId: '1977',
              widgetId: '14e5b39b-6d47-99c3-3ee5-cee1c2574c89',
            },
            style: {
              type: 'TopLevelStyle',
              metaData: {
                isPreset: false,
                schemaVersion: '1.0',
                isHidden: false,
              },
              style: superAppsCustomData.getSuperAppsAddPanelStyles(
                APP_DEF_ID,
                itemIds.MAGAZINE,
              ),
              componentClassName: 'wysiwyg.viewer.components.tpapps.TPAWidget',
              pageId: '',
              compId: '',
              styleType: 'custom',
              skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
            },
            mobileStructure: {
              componentType: 'wysiwyg.viewer.components.tpapps.TPAWidget',
              type: 'Component',
              conversionData: {
                isCustomPreset: true,
              },
              layout: {
                width: 280,
                height: 280,
                x: 20,
                y: 10,
                scale: 1,
                rotationInDegrees: 0,
                fixedPosition: false,
              },
            },
            activeModes: {},
          },
          preset: {
            rect: {
              width: 162,
              height: 182,
              x: 162,
              y: 0,
            },
            label: 'add_preset_newblog_magazine',
            tags: null,
          },
        },
        {
          id: itemIds.POSTCARD,
          structure: {
            type: 'Component',
            skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
            layout: {
              width: 980,
              height: 656,
              x: 0,
              y: 0,
              scale: 1,
              rotationInDegrees: 0,
              fixedPosition: false,
            },
            componentType: 'wysiwyg.viewer.components.tpapps.TPAWidget',
            data: {
              type: 'TPAWidget',
              metaData: {
                isPreset: false,
                schemaVersion: '1.0',
                isHidden: false,
              },
              applicationId: '1977',
              widgetId: '14e5b39b-6d47-99c3-3ee5-cee1c2574c89',
            },
            style: {
              type: 'TopLevelStyle',
              metaData: {
                isPreset: false,
                schemaVersion: '1.0',
                isHidden: false,
              },
              style: superAppsCustomData.getSuperAppsAddPanelStyles(
                APP_DEF_ID,
                itemIds.POSTCARD,
              ),
              componentClassName: 'wysiwyg.viewer.components.tpapps.TPAWidget',
              pageId: '',
              compId: '',
              styleType: 'custom',
              skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
            },
            mobileStructure: {
              componentType: 'wysiwyg.viewer.components.tpapps.TPAWidget',
              type: 'Component',
              conversionData: {
                isCustomPreset: true,
              },
              layout: {
                width: 280,
                height: 187,
                x: 20,
                y: 10,
                scale: 1,
                rotationInDegrees: 0,
                fixedPosition: false,
              },
            },
            activeModes: {},
          },
          preset: {
            rect: {
              width: 162,
              height: 136,
              x: 0,
              y: 154,
            },
            label: 'add_preset_newblog_postcard',
            tags: null,
          },
        },
        {
          id: itemIds.ONE_COLUMN,
          structure: {
            type: 'Component',
            skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
            layout: {
              width: 980,
              height: 1410,
              x: 0,
              y: 0,
              scale: 1,
              rotationInDegrees: 0,
              fixedPosition: false,
            },
            componentType: 'wysiwyg.viewer.components.tpapps.TPAWidget',
            data: {
              type: 'TPAWidget',
              metaData: {
                isPreset: false,
                schemaVersion: '1.0',
                isHidden: false,
              },
              applicationId: '1977',
              widgetId: '14e5b39b-6d47-99c3-3ee5-cee1c2574c89',
            },
            style: {
              type: 'TopLevelStyle',
              metaData: {
                isPreset: false,
                schemaVersion: '1.0',
                isHidden: false,
              },
              style: superAppsCustomData.getSuperAppsAddPanelStyles(
                APP_DEF_ID,
                itemIds.ONE_COLUMN,
              ),
              componentClassName: 'wysiwyg.viewer.components.tpapps.TPAWidget',
              pageId: '',
              compId: '',
              styleType: 'custom',
              skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
            },
            mobileStructure: {
              componentType: 'wysiwyg.viewer.components.tpapps.TPAWidget',
              type: 'Component',
              conversionData: {
                isCustomPreset: true,
              },
              layout: {
                width: 280,
                height: 403,
                x: 20,
                y: 10,
                scale: 1,
                rotationInDegrees: 0,
                fixedPosition: false,
              },
            },
            activeModes: {},
          },
          preset: {
            rect: {
              width: 162,
              height: 239,
              x: 162,
              y: 182,
            },
            label: 'add_preset_newblog_onecolumn',
            tags: null,
          },
        },
        {
          id: itemIds.EDITORIAL,
          structure: {
            type: 'Component',
            skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
            layout: {
              width: 980,
              height: 698,
              x: 0,
              y: 0,
              scale: 1,
              rotationInDegrees: 0,
              fixedPosition: false,
            },
            componentType: 'wysiwyg.viewer.components.tpapps.TPAWidget',
            data: {
              type: 'TPAWidget',
              metaData: {
                isPreset: false,
                schemaVersion: '1.0',
                isHidden: false,
              },
              applicationId: '1977',
              widgetId: '14e5b39b-6d47-99c3-3ee5-cee1c2574c89',
            },
            style: {
              type: 'TopLevelStyle',
              metaData: {
                isPreset: false,
                schemaVersion: '1.0',
                isHidden: false,
              },
              style: superAppsCustomData.getSuperAppsAddPanelStyles(
                APP_DEF_ID,
                itemIds.EDITORIAL,
              ),
              componentClassName: 'wysiwyg.viewer.components.tpapps.TPAWidget',
              pageId: '',
              compId: '',
              styleType: 'custom',
              skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
            },
            mobileStructure: {
              componentType: 'wysiwyg.viewer.components.tpapps.TPAWidget',
              type: 'Component',
              conversionData: {
                isCustomPreset: true,
              },
              layout: {
                width: 280,
                height: 199,
                x: 20,
                y: 10,
                scale: 1,
                rotationInDegrees: 0,
                fixedPosition: false,
              },
            },
            activeModes: {},
          },
          preset: {
            rect: {
              width: 162,
              height: 162,
              x: 0,
              y: 290,
            },
            label: 'add_preset_newblog_editorial',
            tags: null,
          },
        },
      ],
      compTypes: ['wysiwyg.viewer.components.tpapps.TPAWidget'],
    },
    help: { hide: false, text: 'add_section_info_text_customfeed' },
  };
}

export default {
  registerSection(editorAPI) {
    editorAPI.addPanel.registerSectionAtStart(
      addPanelDataConsts.CATEGORIES_ID.NEW_BLOG,
      _.partial(getSection, editorAPI),
    );
  },
};
