import React from 'react';
import ReactLinkedStateMixin from 'react-addons-linked-state-mixin';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Checkbox, Heading } from '@wix/wix-base-ui';
import * as symbols from '@wix/santa-editor-symbols';
import experiment from 'experiment';
import * as util from '@/util';
import {
  cx,
  editorWixRecorder,
  fakeBrowserUtils,
  fedopsLogger,
  publishUtil,
  workspace,
} from '@/util';

import * as core from '@/core';
import * as coreBi from '@/coreBi';
import { translate } from '@/i18n';
import constants from '@/constants';
import * as panels from '@/panels';
import * as baseUI from '@/baseUI';
import type { SitePremiumState } from '@/stateManagement';
import * as stateManagement from '@/stateManagement';

import savePublishConstants from '../../constants/constants';
import savePublishErrorPanelData from '../../constants/savePublishErrorPanelData';
import connectDomain from '../content/connectDomain';
import publishContent from '../content/publishContent';
import seoDealerFallback from '../content/seoDealerFallback/seoDealerFallback';
import type { InnerPanelComponent, InnerPanelProps } from '../content/types';
import type { EditorAPI } from '@/editorAPI';
import type { PublishInteractionStartedSource } from 'types/fedops/publishInteraction';
import type { DealerViewerProps } from '@/topBar';
import type { SaveInteractionStartedSource } from 'types/fedops/saveInteraction';

const { fetchDomainSuggestions } = stateManagement.domainSuggestions.actions;
const { isDomainConnected } = stateManagement.domain.selectors;
const { getSiteUserPreferences } = stateManagement.userPreferences.selectors;
const isOpenDealerForPremiumDomainConnected = experiment.isOpen(
  'se_dealerForPremiumDomainConnected',
);

export const enum SiteWasSavedPanelCloseSource {
  OuterOverlay = 'OuterOverlay',
  InnerPanelPublishPublishLink = 'InnerPanelPublishPublishLink',
  InnerPanelConnectDomainActionLink = 'InnerPanelConnectDomainActionLink',
  HeaderCloseButton = 'HeaderCloseButton',
  FooterPublishButton = 'FooterPublishButton',
  FooterContinueButton = 'FooterContinueButton',
  KeyboardEscapeKey = 'KeyboardEscapeKey',
}

export interface SiteWasSavedPanelResult {
  closeSource: SiteWasSavedPanelCloseSource;
}

export type SiteWasSavedPanelProps = SitePremiumState & {
  overrideTitle?: string;
  overrideSubtitle?: string;
  callback?: (panelResult: SiteWasSavedPanelResult) => void;
  dealerViewer?: React.ComponentType<DealerViewerProps>;
  shouldDisplayInnerPanel: boolean;
  sourceOfStart?: SaveInteractionStartedSource;
};

interface FooterProps {
  buttonClassName?: string;
}

interface DealerOnClickParams {
  additionalLogParams?: {
    clickType?: string;
  };
}

const InnerPanels = {
  PUBLISH: 'PUBLISH',
  CONNECT_DOMAIN: 'CONNECT_DOMAIN',
  SEO_DEALER_FALLBACK: 'SEO_DEALER_FALLBACK',
} as const;

function parseFreeDomain(domain: AnyFixMe) {
  const splitIndex = domain.lastIndexOf('/');
  return {
    prefix: domain.slice(0, splitIndex + 1),
    siteName: domain.slice(splitIndex + 1),
  };
}

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'siteWasSavedPanel',
  mixins: [ReactLinkedStateMixin, core.mixins.editorAPIMixin], //eslint-disable-line react/no-deprecated
  propTypes: {
    domain: PropTypes.string.isRequired,
    isSitePremium: PropTypes.bool,
    isFirstSave: PropTypes.bool,
    isDomainConnected: PropTypes.bool,
    isSitePublished: PropTypes.bool,
    isMockSave: PropTypes.bool,
    overrideTitle: PropTypes.string,
    overrideSubtitle: PropTypes.string,
    panelName: PropTypes.string,
    callback: PropTypes.func,
    shouldDisplayInnerPanel: PropTypes.bool,
    dealerViewer: PropTypes.func,
  },
  getInitialState() {
    const state = {
      isContinueDisabled: false,
      doNotShowAgain: null as AnyFixMe,
      domain: this.props.domain,
      hasDealerViewerError: false,
    };

    if (!this.props.isFirstSave) {
      state.doNotShowAgain =
        !!stateManagement.userPreferences.selectors.getSessionUserPreferences(
          constants.USER_PREFS.SAVE.SITE_SAVED_DONT_SHOW_AGAIN,
        )(this.getEditorAPI().store.getState());
    }
    return state;
  },
  getPanelTitle() {
    return translate(this.props.overrideTitle || 'SAVE_SITE_SAVED_TITLE_TITLE');
  },
  getPanelSubtitle() {
    return translate(
      this.props.overrideSubtitle || 'SAVE_SITE_SAVED_TITLE_SUBTITLE',
    );
  },

  sendDealerBI(description: string, success: boolean, offeringName: string) {
    this.getEditorAPI().bi.event(
      coreBi.events.dealer.content_presented_by_dealer,
      {
        description,
        content_presented_by_dealer: success,
        stage: 'display',
        category: 'postSave',
        panel_name: this.props.panelName,
        panelProps: JSON.stringify(this.props),
        ...(offeringName ? { offeringName } : {}),
      },
    );
  },

  onDealerDisplay() {
    this.sendDealerBI('Dealer onSave offer displayed', true);
  },

  onDealerError(error?: AnyFixMe) {
    this.setState((prevState: any) => {
      return { ...prevState, hasDealerViewerError: true };
    });

    this.sendDealerBI(
      `Error displaying onSave offer: ${JSON.stringify(error)}`,
      false,
      this.getInnerPanelType(),
    );
  },

  isDealerAvailable() {
    return this.props.dealerViewer && !this.state.hasDealerViewerError;
  },

  shouldPresentDealer() {
    return (
      experiment.isOpen('se_dealerPlacementOnSave') && this.isDealerAvailable()
    );
  },

  getDealerViewerProps() {
    const editorAPI = this.getEditorAPI();

    return {
      ...util.dealerUtils.getDealerViewerBaseProps(),
      metaSiteId: editorAPI.dsRead.generalInfo.getMetaSiteId(),
      bannerPosition: util.dealerUtils.bannerPositions.POST_SAVE,
      signedInstance: editorAPI.dsRead.platform.getAppDataByApplicationId(
        constants.APPLICATIONS.META_SITE_APPLICATION_ID,
      )?.instance,
      key: 'dealerViewerSave',
      className: 'publish-inner-panel',
      onDisplay: this.onDealerDisplay,
      onError: this.onDealerError,
      onWarning: this.onDealerError, // any dealer issue is critical for this panel
      onClick: this.onDealerBannerClick,
    };
  },

  getInnerPanelType(): ValueOf<typeof InnerPanels> {
    if (!this.props.isSitePublished) {
      return InnerPanels.PUBLISH;
    } else if (!this.props.isDomainConnected) {
      return InnerPanels.CONNECT_DOMAIN;
    } else if (
      isOpenDealerForPremiumDomainConnected &&
      this.props.isSitePremium
    ) {
      return InnerPanels.SEO_DEALER_FALLBACK;
    }
  },

  getInnerPanel() {
    let panelComponent: InnerPanelComponent;
    let props;
    let dataHook;

    if (this.shouldPresentDealer()) {
      panelComponent = this.props.dealerViewer;
      props = this.getDealerViewerProps();
      dataHook = 'dealer-postsave-banner';
    } else {
      const panelType = this.getInnerPanelType();

      props = {
        ...this.getInnerPanelProps(),
        key: 'innerPanel',
        className: cx(
          `inner-panel ${this.getInnerPanelClass()}`,
          this.getInnerPanelProps()?.className,
        ),
      };

      dataHook = 'fallback-postsave-banner';

      if (panelType === InnerPanels.PUBLISH) {
        panelComponent = publishContent;
      }

      if (panelType === InnerPanels.CONNECT_DOMAIN) {
        panelComponent = connectDomain;
      }

      if (panelType === InnerPanels.SEO_DEALER_FALLBACK) {
        panelComponent = seoDealerFallback;
      }
    }
    if (panelComponent && this.props.shouldDisplayInnerPanel) {
      return (
        <div data-hook={dataHook} className={dataHook}>
          {React.createElement(panelComponent, props)}
        </div>
      );
    }

    return null;
  },

  getPanelClass() {
    let panelClass = 'site-was-saved-panel';
    const innerPanelClass = this.getInnerPanelClass();
    panelClass += innerPanelClass ? ` contains-${innerPanelClass}` : '';

    if (
      this.props.isSitePremium &&
      this.props.isSitePublished &&
      this.props.isDomainConnected
    ) {
      panelClass += ' narrow-domain-input';
    }

    return `${panelClass} save-publish-panel`;
  },
  getInnerPanelClass() {
    const panelType = this.getInnerPanelType();
    switch (panelType) {
      case InnerPanels.PUBLISH:
        return 'publish-inner-panel';
      case InnerPanels.CONNECT_DOMAIN:
        return 'connect-domain-inner-panel';
    }
  },
  getInnerPanelProps(): InnerPanelProps {
    const props: InnerPanelProps = {
      sitePublishedDomain: this.props.domain,
      isDomainConnected: this.props.isDomainConnected,
      isFirstPublish: !this.props.isSitePublished,
      isSitePremium: this.props.isSitePremium,
      isFirstSave: this.props.isFirstSave,
      flowId: constants.CONNECT_DOMAIN.FLOWS.SAVE,
      closePanel: this.close,
      publishMethod: undefined,
      actionCallback: undefined,
    };

    const panelType = this.getInnerPanelType();
    const editorAPI: EditorAPI = this.getEditorAPI();
    switch (panelType) {
      case InnerPanels.PUBLISH:
        props.publishMethod = this.props.isMockSave
          ? editorAPI.publishManager.configureMockPostPublish
          : editorAPI.publishManager.publish;

        if (!this.props.isMockSave) {
          props.actionCallback = function (this: AnyFixMe) {
            this.reportPublishClickedBI();
            editorWixRecorder.addLabel('publish click');
            const currentViewMode = publishUtil.getPublishViewMode(
              editorAPI.isMobileEditor(),
              editorAPI.preview.isInPreviewMode(),
            );

            const numberOfManualPublish = getSiteUserPreferences(
              'numberOfManualPublish',
            )(editorAPI.store.getState()) as number;
            this.getEditorAPI().bi.event(coreBi.events.publish.PUBLISH_CLICK, {
              origin: 'saveSuccessPanel',
              viewModeSource: currentViewMode,
              numberOfManualPublish: numberOfManualPublish
                ? numberOfManualPublish + 1
                : 1,
            });
          }.bind(this);
        }
        break;
      case InnerPanels.CONNECT_DOMAIN:
        if (!this.props.isMockSave) {
          props.actionCallback = this.reportConnectDomainClickedBI;
        }
        break;
    }

    return props;
  },
  shouldDisplayInnerPanel() {
    return (
      !this.props.isSitePublished ||
      !this.props.isDomainConnected ||
      (isOpenDealerForPremiumDomainConnected && this.props.isSitePremium)
    );
  },
  shouldShowEditAndPublishButton() {
    return !(
      isOpenDealerForPremiumDomainConnected &&
      this.props.isSitePremium &&
      this.props.isDomainConnected &&
      this.props.isSitePublished
    );
  },

  doPublish(
    sourceOfStart: PublishInteractionStartedSource,
    closeSource: SiteWasSavedPanelCloseSource.FooterPublishButton,
  ) {
    // NOTE: https://github.com/wix-private/santa-editor/issues/37029
    fedopsLogger.interactionStarted(fedopsLogger.INTERACTIONS.PUBLISH, {
      paramsOverrides: { sourceOfStart },
    });
    fedopsLogger.interactionStarted('user_publish_site', {
      paramsOverrides: { sourceOfStart },
    });

    const editorAPI: EditorAPI = this.getEditorAPI();

    if (!this.props.isMockSave) {
      editorWixRecorder.addLabel('publish click');
      const currentViewMode = publishUtil.getPublishViewMode(
        editorAPI.isMobileEditor(),
        editorAPI.preview.isInPreviewMode(),
      );
      const isSitePublished = editorAPI.dsRead?.generalInfo.isSitePublished();
      const siteWasOnlyAutoPublished = getSiteUserPreferences(
        constants.USER_PREFS.PUBLISH.SITE_WAS_ONLY_AUTO_PUBLISHED,
      )(editorAPI.store.getState());
      const isFirstManualPublish = siteWasOnlyAutoPublished || !isSitePublished;
      const isPremium = editorAPI.site.isPremium();

      const numberOfManualPublish = getSiteUserPreferences(
        'numberOfManualPublish',
      )(editorAPI.store.getState()) as number;
      editorAPI.bi.event(coreBi.events.publish.PUBLISH_CLICK, {
        origin: 'saveSuccessPanel',
        viewModeSource: currentViewMode,
        is_first_manual_publish: isFirstManualPublish,
        isPremium,
        numberOfManualPublish: numberOfManualPublish
          ? numberOfManualPublish + 1
          : 1,
      });
      this.reportBIWithUserStatus(
        coreBi.events.save.siteWasSavedPanel
          .SECOND_SAVE_PUBLISH_NOW_BUTTON_CLICKED,
      );
    }

    this.close({ closeSource });
    void editorAPI.publishManager.publish({
      sourceOfStart,
      origin: 'saveSuccessPanel',
    });
  },
  onDealerBannerClick(params: DealerOnClickParams) {
    if (params.additionalLogParams.clickType === 'publishSite') {
      this.doPublish(
        'saveSuccessPanel_innerPanel_publish_publishLink',
        SiteWasSavedPanelCloseSource.InnerPanelPublishPublishLink,
      );
    } else if (
      typeof params?.additionalLogParams?.clickType === 'string' &&
      params.additionalLogParams.clickType.includes('Editor.openPanel')
    ) {
      const panelToOpen = params.additionalLogParams.clickType.split(':')[1];
      this.getEditorAPI().panelManager.openPanel(panelToOpen);
    }
  },
  onPublishButtonClick() {
    this.doPublish(
      'saveSuccessPanel_footer_publishButton',
      SiteWasSavedPanelCloseSource.FooterPublishButton,
    );
  },
  onContinueEditingButtonClick() {
    if (!this.props.isMockSave) {
      if (this.props.isFirstSave) {
        this.getEditorAPI().bi.event(
          coreBi.events.save.siteWasSavedPanel.FIRST_SAVE_ACTION_BUTTON_CLICKED,
          {},
        );
      } else {
        this.reportBIWithUserStatus(
          coreBi.events.save.siteWasSavedPanel
            .SECOND_SAVE_ACTION_BUTTON_CLICKED,
        );
      }
    }
    if (this.state.isContinueDisabled) {
      throw new Error(
        'Should not get to continueEditing if continue button is disabled',
      );
    }

    if (this.state.doNotShowAgain) {
      this.getEditorAPI().store.dispatch(
        stateManagement.userPreferences.actions.setSiteUserPreferences(
          constants.USER_PREFS.SAVE.SITE_SAVED_DONT_SHOW_AGAIN,
          Date.now(),
        ),
      );
    }

    this.close({
      closeSource: SiteWasSavedPanelCloseSource.FooterContinueButton,
    });
  },
  reportBIWithUserStatus(biEvent: AnyFixMe) {
    const params = { user_status: this.getUserStatus() };
    this.getEditorAPI().bi.event(biEvent, params);
  },
  getUserStatus() {
    let status = '';
    if (!this.props.isSitePublished) {
      status += 'not_';
    }
    status += 'published_';
    if (!this.props.isDomainConnected) {
      status += 'not_';
    }
    status += 'connected';
    return status;
  },
  renameSiteAndProcessResult(
    siteName: AnyFixMe,
    onSuccess: AnyFixMe,
    onError: AnyFixMe,
  ) {
    const editorAPI = this.getEditorAPI();
    if (
      fakeBrowserUtils.isFakeBrowserEnabled() &&
      !isDomainConnected(editorAPI.dsRead)
    ) {
      editorAPI.store.dispatch(fetchDomainSuggestions(siteName));
    }
    editorAPI.siteName.rename(
      siteName,
      function onSuccessWrapper() {
        onSuccess();
      },
      function onErrorWrapper(error: AnyFixMe) {
        const errorCode = error?.errorCode;
        if (errorCode === savePublishConstants.RENAME.ERRORS.SITE_NAME_TAKEN) {
          onError('SAVE_SITE_NAME_ALREADY_EXISTS');
        } else {
          onError();

          const finalPanelData = _.chain(
            savePublishErrorPanelData.NAME_SITE_AGAIN_PANEL_DATA,
          )
            .clone()
            .defaults(savePublishErrorPanelData.SAVE_ERROR_DEFAULT_PANEL_DATA)
            .assign({ errorCode })
            .value();
          editorAPI.panelManager.openPanel(
            'savePublish.panels.common.failPanel',
            finalPanelData,
            true,
          );
        }
      },
    );
  },
  setDomain(siteName: string) {
    if (!siteName) {
      return;
    }

    const { prefix } = parseFreeDomain(this.props.domain);

    this.setState({
      domain: prefix + siteName,
    });
  },
  handleDomainEditingStatusChanged(newName: string) {
    this.setDomain(newName);
    this.setState({
      isContinueDisabled: !newName,
    });
  },
  handleNeedHelpConnectingDomainClicked() {
    this.getEditorAPI().bi.event(
      coreBi.events.save.siteWasSavedPanel.HELP_CONNECTING_DOMAIN_CLICKED,
      {},
    );
  },
  handleDontShowAgainClicked(isChecked: AnyFixMe) {
    this.setState({ doNotShowAgain: isChecked });
    this.getEditorAPI().bi.event(
      coreBi.events.save.siteWasSavedPanel.SECOND_SAVE_DONT_SHOW_AGAIN_CLICKED,
      {
        toggle: isChecked,
        user_status: this.getUserStatus(),
      },
    );
  },
  reportPublishClickedBI() {
    this.getEditorAPI().bi.event(
      coreBi.events.save.siteWasSavedPanel.PUBLISH_NOW_CLICKED,
      {
        user_status: this.getUserStatus(),
        is_first_save: this.props.isFirstSave ? 1 : 0,
      },
    );
  },
  reportConnectDomainClickedBI() {
    this.getEditorAPI().bi.event(
      coreBi.events.save.siteWasSavedPanel.SECOND_SAVE_CONNECT_DOMAIN_CLICKED,
      {
        user_status: this.getUserStatus(),
      },
    );
  },
  domainInputBiReporter(
    biEventName: keyof typeof coreBi.events.domainInput,
    additionalProps?: object,
  ) {
    if (!this.props.isMockSave) {
      const biProps = {
        user_status: this.getUserStatus(),
        panel_name: this.props.panelName,
        save_or_publish: 'save',
        is_first_save_or_publish: this.props.isFirstSave ? 1 : 0,
      };
      this.getEditorAPI().bi.event(coreBi.events.domainInput[biEventName], {
        ...biProps,
        ...additionalProps,
      });
    }
  },
  componentDidMount() {
    util.fedopsLogger.interactionEnded('ui_save', {
      paramsOverrides: {
        sourceOfStart: this.props.sourceOfStart,
        sourceOfEnd: 'siteWasSavedPanel',
      },
    });

    if (
      this.shouldDisplayInnerPanel() &&
      !this.isDealerAvailable() &&
      experiment.isOpen('se_dealerPlacementOnSave')
    ) {
      this.sendDealerBI(
        `Error displaying onSave offer`,
        false,
        this.getInnerPanelType(),
      );
    }
  },
  componentWillUnmount() {
    if (this.props.callback) {
      this.props.callback(this.callbackResult);
      this.callbackResult = undefined;
    }
  },
  close(result: SiteWasSavedPanelResult) {
    this.callbackResult = result;
    this.getEditorAPI().panelManager.closePanelByName(this.props.panelName);
  },
  getKeyboardShortcuts() {
    const self = this;
    return {
      enter() {
        if (!self.state.isContinueDisabled) {
          self.onContinueEditingButtonClick();
        }
      },
      esc() {
        self.close({
          closeSource: SiteWasSavedPanelCloseSource.KeyboardEscapeKey,
        });
      },
    };
  },
  getDomainInputProps() {
    let props: AnyFixMe;
    const { domain } = this.state;
    const isFreeDomain = !this.props.isDomainConnected;

    if (isFreeDomain) {
      const parsedDomain = parseFreeDomain(domain);
      props = {
        prefix: parsedDomain.prefix,
        value: parsedDomain.siteName,
        isEditable: true,
        domainValidator: this.getEditorAPI().siteName.validate,
        domainHandler: this.props.isMockSave
          ? null
          : this.renameSiteAndProcessResult,
        onChange: this.handleDomainEditingStatusChanged,
      };
    } else {
      props = {
        value: domain,
        isEditable: false,
      };
    }

    props.biHandler = this.domainInputBiReporter;

    return props;
  },
  handleValidityChange(isValid: boolean) {
    if (!isValid) {
      this.setState({
        isContinueDisabled: true,
      });
    }
  },
  async handleChange(value: string) {
    const domainInputProps = this.getDomainInputProps();

    if (!domainInputProps.isEditable) {
      return;
    }

    let isError: boolean = false;

    if (value !== domainInputProps.value) {
      try {
        await new Promise((resolve, reject) => {
          domainInputProps.domainHandler(value, resolve, reject);
        });
      } catch (e) {
        isError = true;
      }
    }

    domainInputProps.onChange(value);

    this.setState({
      isContinueDisabled: isError,
    });
  },
  logValidationResponseReceived(params: object) {
    this.getEditorAPI().bi.event(
      coreBi.events.domainInput.SITE_DOMAIN_VALIDATION_SUCCESS,
      {
        ...params,
        origin: this.props.isFirstSave ? 'first_save' : 'second_save',
      },
    );
  },
  handleDomainClick() {
    this.domainInputBiReporter('DISABLED_DOMAIN_PART_CLICKED');
  },
  handleInputClick() {
    const { isEditable, biHandler } = this.getDomainInputProps();

    if (!isEditable) {
      biHandler('DISABLED_DOMAIN_PART_CLICKED');
    }
  },
  handleEditClick() {
    this.domainInputBiReporter('DOMAIN_EDIT_CLICKED');
  },
  handleCancelClick() {
    this.domainInputBiReporter('DOMAIN_EDIT_CANCEL_CLICKED');
  },
  handleConfirmClick(siteDisplayName: string) {
    this.domainInputBiReporter('DOMAIN_EDIT_SAVE_CLICKED', { siteDisplayName });
  },
  handleCloseButtonClick() {
    this.close({ closeSource: SiteWasSavedPanelCloseSource.HeaderCloseButton });
  },
  handleOuterClick() {
    this.close({
      closeSource: SiteWasSavedPanelCloseSource.OuterOverlay,
    });
  },
  getDomainInput() {
    const { value, prefix, isEditable } = this.getDomainInputProps();

    return (
      <baseUI.DomainInput
        value={value}
        prefix={prefix}
        isEditable={isEditable}
        onValidityChange={this.handleValidityChange}
        onChange={this.handleChange}
        withEditButtons={this.shouldShowEditAndPublishButton()}
        onDomainClick={this.handleDomainClick}
        onInputClick={this.handleInputClick}
        onEditClick={this.handleEditClick}
        onCancelClick={this.handleCancelClick}
        onConfirmClick={this.handleConfirmClick}
        onValidationResponseReceived={this.logValidationResponseReceived}
      />
    );
  },

  renderHeader() {
    return (
      <header className="save-publish-panel-header">
        <button
          onClick={this.handleCloseButtonClick}
          className="close"
          data-hook="BaseModal-closeButton"
        >
          <symbols.symbol name="headerCloseButton" />
        </button>
        <span className="title">{this.getPanelTitle()}</span>
        <span className="subtitle">{this.getPanelSubtitle()}</span>
      </header>
    );
  },

  renderBody() {
    const innerPanel = this.shouldDisplayInnerPanel() && this.getInnerPanel();

    return (
      <>
        <div className="domain-input">{this.getDomainInput()}</div>
        {innerPanel}
      </>
    );
  },

  renderFooter(footerProps: FooterProps = {}) {
    const { buttonClassName } = footerProps;

    return (
      <div className="site-saved-footer">
        {!this.props.isFirstSave ? (
          <Checkbox
            key="dontShowAgainCheckbox"
            label="SAVE_PUBLISH_DO_NOT_SHOW_AGAIN_LABEL"
            // @ts-expect-error
            valueLink={{
              value: this.state.doNotShowAgain,
              requestChange: this.handleDontShowAgainClicked,
            }}
            labelAfterSymbol={true}
            className="dont-show-again"
          />
        ) : null}
        {this.props.isSitePremium &&
        !this.props.isDomainConnected &&
        this.props.isFirstSave ? (
          <span
            key="needHelpConnectingDomain"
            className="help-connecting-domain"
          >
            {`${translate('SAVE_SITE_SAVED_NEED_HELP_LABEL')} `}
            <a
              target="_blank"
              href={translate('SAVE_SITE_SAVED_NEED_HELP_URL')}
              onClick={this.handleNeedHelpConnectingDomainClicked}
            >
              {translate('SAVE_SITE_SAVED_NEED_HELP_LINK')}
            </a>
          </span>
        ) : null}
        <div className="footer-buttons">
          {this.props.isSitePublished &&
          this.shouldShowEditAndPublishButton() ? (
            <baseUI.button
              onClick={this.onPublishButtonClick}
              key="publishButton"
              label="SAVE_SITE_SAVED_WHATS_NEXT_PUBLISH_LINK"
              disabled={this.state.isContinueDisabled}
              className={cx(
                'publish-button',
                'btn-confirm-secondary',
                buttonClassName,
              )}
            />
          ) : null}
          <baseUI.button
            label="SAVE_SITE_SAVED_BUTTON_OK"
            onClick={this.onContinueEditingButtonClick}
            disabled={this.state.isContinueDisabled}
            className={cx(
              'continue-button',
              'btn-confirm-primary',
              buttonClassName,
            )}
          />
        </div>
      </div>
    );
  },

  renderNewWorkspacePanelFrame() {
    const { isSitePublished } = this.props;
    const titleKey = isSitePublished
      ? 'SAVE_SITE_SAVED_SMALL_TITLE_TITLE'
      : 'SAVE_SITE_SAVED_TITLE_TITLE';
    const subtitleKey = isSitePublished
      ? 'SAVE_SITE_SAVED_SMALL_TITLE_SUBTITLE'
      : 'SAVE_SITE_SAVED_TITLE_SUBTITLE';

    return (
      <panels.frames.CustomPanelFrame
        panelName={this.props.panelName}
        className="new-workspace-save-publish-panel-frame"
        dataHook="site-was-saved-panel"
        footerContent={this.renderFooter({ buttonClassName: 'btn-md' })}
        onCloseButtonClick={this.handleCloseButtonClick}
        onOuterClick={this.handleOuterClick}
        keyboardShortcuts={this.getKeyboardShortcuts()}
      >
        <>
          <div className="site-saved-header">
            <Heading shouldTranslate={false} appearance="h1" multiline>
              {translate(titleKey)}
            </Heading>
            <Heading shouldTranslate={false} appearance="h4" multiline>
              {translate(subtitleKey)}
            </Heading>
          </div>
          <div>{this.renderBody()}</div>
        </>
      </panels.frames.CustomPanelFrame>
    );
  },

  render() {
    if (workspace.isNewWorkspaceEnabled()) {
      return this.renderNewWorkspacePanelFrame();
    }

    return (
      <panels.frames.FocusPanelFrame
        panelName={this.props.panelName}
        shouldHideHeader={true}
        frameClassName="save-publish-panel-frame"
        dataHook="site-was-saved-panel"
        keyboardShortcuts={this.getKeyboardShortcuts()}
        className={this.getPanelClass()}
      >
        {this.renderHeader()}
        <div className="wrapper">
          {this.renderBody()}
          {this.renderFooter()}
        </div>
      </panels.frames.FocusPanelFrame>
    );
  },
});
