import React, { useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import * as util from '@/util';
import * as stateManagement from '@/stateManagement';
import type { MapDispatchToProps, MapStateToProps } from 'types/redux';
import _ from 'lodash';
import type DS from '@wix/document-services-types';
import { parseAppVersion, resetTestVersion } from '../../utils/utils';
import { isEligible } from '@wix/ambassador-premium-features-manager-v1-feature/http';
import { HttpClient } from '@wix/http-client';
import constants from '@/constants';

export interface AppData extends DS.AppData {
  latestVersion: string;
  displayVersion: string;
  installStatus: InstallStatus;
  blocksVersion?: string;
  canEdit?: boolean;
  isPublished?: boolean;
}

interface StateProps {
  availableApps: AppData[];
  installedApps: AppData[];
  isLoadingAvailableApps: boolean;
  appToken: string;
}

interface DispatchProps {
  fetchAvailableApps: () => void;
  fetchFreshAppData: () => Promise<void>;
}

type Props = StateProps & DispatchProps;

export enum InstallStatus {
  Installed,
  Available,
  Versioned,
}

export enum ListView {
  Apps,
  AppVersions,
}

const FETCH_ACCOUNT_INFO_KEY = 'FETCH_ACCOUNT_INFO_KEY';

const {
  getIsLoading,
  getAvailableApps,
  getInstalledPackages,
  getLatestVersions,
  getFreshAppsData,
} = stateManagement.platform.selectors;

const { fetchAvailableApps } = stateManagement.platform.actions;

interface PrivateAppsContextValue {
  installedApps: AppData[];
  availableApps: AppData[];
  isLoading: boolean;
  isStudioAccount: boolean;
  selectedListView: ListView;
  setSelectedListView: (selectedListView: ListView) => void;
}

const PrivateAppsContext = React.createContext<PrivateAppsContextValue>({
  installedApps: [],
  availableApps: [],
  isLoading: false,
  isStudioAccount: false,
  selectedListView: ListView.Apps,
  setSelectedListView: () => ({}),
});

const PrivateAppsProviderComponent: React.FC<Props> = ({
  children,
  fetchAvailableApps,
  installedApps,
  availableApps,
  isLoadingAvailableApps,
  fetchFreshAppData,
  appToken,
}) => {
  const [selectedListView, setSelectedListView] = useState(ListView.Apps);
  const [isLoadingFreshAppData, setIsLoadingFreshAppData] = useState(true);
  const httpClient = new HttpClient({
    getAppToken: () => appToken,
  });

  const { isLoading: isLoadingAccountInfo, data: isStudioAccount } = useQuery(
    FETCH_ACCOUNT_INFO_KEY,
    () => {
      return httpClient
        .request(
          isEligible({
            uniqueName: 'wix_studio',
            includeParentFeature: false,
          }),
        )
        .then((res) => res.data.isEligible);
    },
    {
      staleTime: Infinity,
    },
  );

  useEffect(() => {
    fetchAvailableApps();
  }, [fetchAvailableApps]);

  useEffect(() => {
    fetchFreshAppData().then(() => {
      setIsLoadingFreshAppData(false);
    });
  }, [fetchFreshAppData]);

  return (
    <PrivateAppsContext.Provider
      value={{
        isStudioAccount,
        installedApps,
        availableApps,
        isLoading:
          isLoadingAvailableApps ||
          isLoadingFreshAppData ||
          isLoadingAccountInfo,
        selectedListView,
        setSelectedListView,
      }}
    >
      {children}
    </PrivateAppsContext.Provider>
  );
};

export const usePrivateApps = () => useContext(PrivateAppsContext);

const mapStateToProps: MapStateToProps<StateProps, {}> = ({
  state,
  dsRead,
}): StateProps => {
  const appToken =
    dsRead.platform.getAppDataByApplicationId(
      constants.APPLICATIONS.META_SITE_APPLICATION_ID,
    )?.instance || '';
  const isLoadingAvailableApps = getIsLoading(state);
  const userApps = getAvailableApps(state) as AppData[];
  const latestVersions = getLatestVersions(state);
  const freshAppsData = getFreshAppsData(state);
  const installedApps = getInstalledPackages(dsRead) as AppData[];
  const builtApps = userApps
    ? userApps.filter(
        (app) =>
          util.appStudioUtils.isBlocksApp(app) &&
          util.appStudioUtils.isAppNotEmpty(app),
      )
    : [];
  const appsForInstallation = _.differenceBy(
    builtApps,
    installedApps,
    (app) => app.appDefinitionId,
  );

  const availableApps = _.sortBy(
    appsForInstallation.map((app) => ({
      ...app,
      latestVersion: resetTestVersion(app.latestVersion),
      installStatus: InstallStatus.Available,
    })),
    'appDefinitionName',
  );
  return {
    appToken,
    isLoadingAvailableApps,
    availableApps: availableApps.map((app) => ({
      ...app,
      canEdit: true,
      components: app.components?.map(
        ({ compData, compType, ...component }: any) => ({
          ...component,
          data: Object.values(compData || {}).shift(),
          displayVersion: parseAppVersion(app.latestVersion || app.version),
          type: compType,
        }),
      ),
    })),
    installedApps: _(installedApps)
      .sortBy(installedApps, 'appDefinitionName')
      .map((app) => {
        const latestVersion = resetTestVersion(
          latestVersions[app.appDefinitionId],
        );
        return {
          ...app,
          latestVersion,
          displayVersion: parseAppVersion(app.latestVersion),
          blocksVersion: util.appStudioUtils.getBlocksVersion(app),
          installStatus: InstallStatus.Installed,
          canEdit: !!builtApps.find(
            ({ appDefinitionId }) => appDefinitionId === app.appDefinitionId,
          ),
          isPublished: !!freshAppsData.find(
            ({ appId }: { appId: string }) => appId === app.appDefinitionId,
          )?.isPublished,
        };
      })
      .filter(
        (app) =>
          builtApps.some(
            ({ appDefinitionId }) => appDefinitionId === app.appDefinitionId,
          ) || !app.isPublished,
      )
      .value(),
  };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, {}> = (
  dispatch,
): DispatchProps => ({
  fetchAvailableApps: () => dispatch(fetchAvailableApps()),
  fetchFreshAppData: () =>
    dispatch(stateManagement.platform.actions.fetchFreshAppsData()),
});

export const PrivateAppsProvider = util.hoc.connect(
  util.hoc.STORES.EDITOR_API,
  mapStateToProps,
  mapDispatchToProps,
)(PrivateAppsProviderComponent);
