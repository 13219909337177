import experiment from 'experiment';
import {
  leftBarAiToolsClickOnToolsEntryPoint,
  componentAddedToStage,
  componentRemoved,
} from '@wix/bi-logger-editor/v2';
import { AiWriterApiKey } from '@/apis';
import * as stateManagement from '@/stateManagement';
import constants from '@/constants';
import { waitForAddedCompRef } from '@/componentsAddUtils';
import { biLogger } from '@/util';
import { layoutUtils } from '@/layoutUtils';

import getTextCompSerialized from './textCompSerialized';

import type { EditorAPI } from '@/editorAPI';

export interface AiPageToolsItem {
  automationId: string;
  title: string;
  description: string;
  symbolName: string;
  condition(): boolean;
  onClick(): void;
}

export interface AiPageToolsCategory {
  title: string;
  items: AiPageToolsItem[];
}

const ORIGIN = 'ai_tools_left_bar';

export const getAiPageToolsList = (
  editorAPI: EditorAPI,
): AiPageToolsCategory[] => {
  return [
    {
      title: 'assistant',
      items: [
        {
          automationId: 'ai-tools-assistant',
          title: 'All new AI Assistant',
          description: 'ai_tools_assistant_body',
          symbolName: 'sparklesBold',
          condition: () => experiment.isOpen('se_editorCopilotPoc'),
          onClick() {
            editorAPI.store.dispatch(
              stateManagement.panels.actions.updateOrOpenPanel(
                'panels.focusPanels.AIAssistantPanel',
                {},
              ),
            );
          },
        },
      ],
    },
    {
      title: 'ai_tools_improve_page_section_header',
      items: [
        {
          automationId: 'ai-tools-page-writer',
          title: 'ai_tools_improve_page_section_create_text_title',
          description: 'ai_tools_improve_page_section_create_text_body',
          symbolName: 'editSparkles',
          condition() {
            return experiment.getValue('se_aiToolsLeftBar') === 'C';
          },
          onClick() {
            biLogger.report(
              leftBarAiToolsClickOnToolsEntryPoint({
                targetName: 'Ai Page Writer',
              }),
            );

            editorAPI.host.getAPI(AiWriterApiKey).openAiWriterPanel({
              origin: ORIGIN,
            });
          },
        },
      ],
    },
    {
      title: 'ai_tools_more_tools_section_header',
      items: [
        {
          automationId: 'ai-tools-text-generator',
          title: 'ai_tools_more_tools_section_text_generator_title',
          description: 'ai_tools_more_tools_section_text_generator_body',
          symbolName: 'aiToolsTextCreator',
          condition() {
            return experiment.isOpen('se_aiTextGenerator');
          },
          async onClick() {
            biLogger.report(
              leftBarAiToolsClickOnToolsEntryPoint({
                targetName: 'Ai Text Generator',
              }),
            );

            const text = 'I&rsquo;m a paragraph';
            const containerToAddTo = editorAPI.pages.getFocusedPage();
            const textCompDef = getTextCompSerialized({
              text,
            });

            const position =
              layoutUtils.getPositionInsideParentCloseToViewportCenter(
                editorAPI,
                stateManagement.domMeasurements.selectors.getViewPort(
                  editorAPI.store.getState(),
                  true,
                ),
                containerToAddTo,
                textCompDef,
              );

            const compDefWithPosition = getTextCompSerialized({
              text,
              position,
            });

            const textCompRef = await waitForAddedCompRef(
              editorAPI.components.add(containerToAddTo, compDefWithPosition),
            );

            biLogger.report(
              componentAddedToStage({
                origin: ORIGIN,
                adding_method: 'ai_tools_flow',
                page_id: containerToAddTo.id,
                component_id: textCompRef.id,
                component_type: compDefWithPosition.componentType,
              }),
            );

            await editorAPI.waitForChangesAppliedAsync();

            editorAPI.selection.selectComponentByCompRef(textCompRef);

            editorAPI.openComponentPanel(
              constants.componentPanels.aiTextGenerator,
              {
                origin: ORIGIN,
                onUnmount: () => {
                  const htmlContent =
                    editorAPI.components.data.get(textCompRef).text ?? '';
                  const updatedText = htmlContent.replace(/(<([^>]+)>)/gi, '');

                  if (updatedText === text) {
                    editorAPI.components.remove(textCompRef);

                    biLogger.report(
                      componentRemoved({
                        removal_method: 'auto_deletion_ai_tools_flow',
                        page_id: containerToAddTo.id,
                        component_id: textCompRef.id,
                        component_type: compDefWithPosition.componentType,
                      }),
                    );
                  }
                },
              },
            );
          },
        },
        {
          automationId: 'ai-tools-section-generator',
          title: 'ai_tools_more_tools_section_generator_title',
          description: 'ai_tools_more_tools_section_generator_body',
          symbolName: 'aiToolsSectionCreator',
          condition() {
            return experiment.isOpen('se_createSectionWithAI');
          },
          onClick() {
            biLogger.report(
              leftBarAiToolsClickOnToolsEntryPoint({
                targetName: 'Ai Section Generator',
              }),
            );

            editorAPI.store.dispatch(
              stateManagement.panels.actions.openLeftPanel(
                constants.ROOT_COMPS.LEFTBAR.ADD_SECTION_PANEL_NAME,
                {
                  origin: ORIGIN,
                  emptyStateSectionReplacement: true,
                  emptyStateBlankSectionIndex: 1,
                  category: 'ai_creator',
                },
              ),
            );
          },
        },
      ],
    },
  ];
};
