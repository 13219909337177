// @ts-nocheck
import createReactClass from 'create-react-class';
import _ from 'lodash';
import * as util from '@/util';
import { translate } from '@/i18n';
import CONSTANTS from '../../utils/constants';
import * as coreBi from '@/coreBi';
import pageSettingsTabMixin from './pageSettingsTabMixin';

import React from 'react';
import * as baseUI from '@/baseUI';
import {
  Composites,
  CustomScroll,
  TextInput,
  TextLabel,
  DropDown,
  DropDownOption,
} from '@wix/wix-base-ui';

const sendPrivacyChangeBI = function (editorAPI, pageId, privacy) {
  editorAPI.bi.event(
    coreBi.events.topbar.pages.top_bar_PAGES_settings_privacy_changed,
    {
      privacy,
      page_id: pageId,
    },
  );
};

const updatePageSecurityData = function (
  editorAPI,
  pageId,
  data,
  privacyTypeBI,
) {
  editorAPI.pages.data.update(pageId, { pageSecurity: data });
  sendPrivacyChangeBI(editorAPI, pageId, privacyTypeBI);
};

const doesPageHavePasswordOnServer = function (editorAPI, pageId) {
  return editorAPI.pages.permissions.hasPassword(pageId);
};

const updatePagePassword = function (editorAPI, pageId, plainPassword) {
  editorAPI.pages.permissions.updatePassword(pageId, plainPassword);
};

const removePagePassword = function (editorAPI, pageId) {
  editorAPI.pages.permissions.removePassword(pageId);
};

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'tabPermissions',
  mixins: [pageSettingsTabMixin],
  getInitialState() {
    return this.getInitialPageState();
  },

  componentWillReceiveProps(nextProps) {
    if (nextProps.pageData.id !== this.state.pageId) {
      this.setState(this.getInitialPageState());
    }
  },

  getInitialPageState() {
    return {
      focusedProtectionType: this.getInitialProtectionType(),
      passwordDialogLanguage: this.getInitialLanguage(),
      membersDialogLanguage: this.getInitialLanguage(),
      pageId: this.props.pageData.id,
      isNewValidPasswordInserted: false,
      passwordInputType: 'password',
      plainPassword: '',
    };
  },

  getInitialProtectionType() {
    const pageSecurityData = this.props?.pageData?.pageSecurity;
    if (pageSecurityData.requireLogin) {
      return CONSTANTS.PERMISSION_STATES.MEMBERS;
    }

    if (this.isPageProtectedWithPassword()) {
      return CONSTANTS.PERMISSION_STATES.PASSWORD;
    }

    return CONSTANTS.PERMISSION_STATES.NONE;
  },

  isPageProtectedWithPassword() {
    return (
      !_.isEmpty(this.props?.pageData?.pageSecurity?.passwordDigest) ||
      doesPageHavePasswordOnServer(this.getEditorAPI(), this.props.pageData.id)
    );
  },

  savePageSecurityData() {
    const pageId = this.props.pageData.id;
    const pageSecurityUpdater = _.partialRight(
      updatePageSecurityData,
      this.state.focusedProtectionType,
    );
    switch (this.state.focusedProtectionType) {
      case CONSTANTS.PERMISSION_STATES.NONE:
        pageSecurityUpdater(this.getEditorAPI(), pageId, {
          requireLogin: false,
        });
        removePagePassword(this.getEditorAPI(), pageId);
        break;
      case CONSTANTS.PERMISSION_STATES.PASSWORD:
        if (this.state.isNewValidPasswordInserted) {
          updatePagePassword(
            this.getEditorAPI(),
            pageId,
            this.state.plainPassword,
          );
        }
        if (
          this.state.isNewValidPasswordInserted ||
          this.isPageProtectedWithPassword()
        ) {
          pageSecurityUpdater(this.getEditorAPI(), pageId, {
            requireLogin: false,
            dialogLanguage: this.state.passwordDialogLanguage,
          });
        }
        break;
      case CONSTANTS.PERMISSION_STATES.MEMBERS:
        pageSecurityUpdater(this.getEditorAPI(), pageId, {
          requireLogin: true,
          dialogLanguage: this.state.membersDialogLanguage,
        });
        removePagePassword(this.getEditorAPI(), pageId);
        break;
    }
  },

  setFocusedProtectionType(type) {
    this.setState(
      {
        focusedProtectionType: type,
      },
      this.savePageSecurityData,
    );
  },

  /**********************************************/
  /****************** members login *************/
  /**********************************************/
  isSiteSaved() {
    return (
      !this.getEditorAPI().dsRead.generalInfo.isFirstSave() &&
      !this.getEditorAPI().dsRead.generalInfo.isDraft()
    );
  },

  getSiteMembersUrl() {
    const MY_ACCOUNT_BASE_URL = '//www.wix.com/my-account/sites/';
    const SITE_MEMBERS_PATH = '/contacts/?tag=contacts-site_members_approved';
    const metaSiteId = this.getEditorAPI().dsRead.generalInfo.getMetaSiteId();
    return MY_ACCOUNT_BASE_URL + metaSiteId + SITE_MEMBERS_PATH;
  },

  getInitialLanguage() {
    const defaultLanguage =
      this.getEditorAPI().dsRead.generalInfo.getLanguage();
    const savedLanguage = this.props?.pageData?.pageSecurity?.dialogLanguage;
    return _.isEmpty(savedLanguage) ? defaultLanguage : savedLanguage;
  },

  /**********************************************/
  /****************** password ******************/
  /**********************************************/
  isValidPassword(plainPassword) {
    const failIfInvalidCharachters = util.validate.byInvalidCharacters(
      CONSTANTS.INVALID_CHARACTERS,
    );
    return (
      util.validate.notEmpty(plainPassword) &&
      failIfInvalidCharachters(plainPassword)
    );
  },

  isPasswordHidden() {
    return this.state.passwordInputType === 'password';
  },

  shouldShowPasswordMockElement() {
    return (
      this.isPasswordHidden() &&
      _.isEmpty(this.state.plainPassword) &&
      this.isPageProtectedWithPassword()
    );
  },

  onPasswordBlur(event) {
    const inputActualValue = event.target.value;
    this.setState(
      {
        plainPassword: inputActualValue,
        isNewValidPasswordInserted: this.isValidPassword(inputActualValue),
        passwordInputType: 'password',
      },
      this.savePageSecurityData,
    );
  },

  onPasswordFocus() {
    if (this.state.passwordInputType !== 'text') {
      this.setState({
        passwordInputType: 'text',
      });
    }
  },

  /*****************************************************/
  /****************** dialog language ******************/
  /*****************************************************/
  setDialogLanguage(dialogLanguageType, language) {
    const stateToSet = {};
    stateToSet[dialogLanguageType] = language;
    this.setState(stateToSet, this.savePageSecurityData);
    this.getEditorAPI().bi.event(
      coreBi.events.topbar.pages
        .top_bar_PAGES_settings_privacy_language_selected_click,
      {
        language_selected: language,
        category: this.state.focusedProtectionType,
      },
    );
  },
  getDialogLanguageLink(dialogLanguageType) {
    return {
      value: this.state[dialogLanguageType],
      requestChange: function (newValue) {
        if (this.state[dialogLanguageType] !== newValue) {
          this.setDialogLanguage(dialogLanguageType, newValue);
        }
      }.bind(this),
    };
  },
  getTabFocusLink() {
    return {
      value: this.state.focusedProtectionType,
      requestChange: function (newProtectionType) {
        if (this.state.focusedProtectionType !== newProtectionType) {
          this.setFocusedProtectionType(newProtectionType);
        }
      }.bind(this),
    };
  },

  openSiteMembersSettingsPanel() {
    this.getEditorAPI().panelManager.openPanel(
      'panels.focusPanels.siteMembersSettingsPanel',
      { panelName: 'panels.focusPanels.siteMembersSettingsPanel' },
      true,
    );
  },

  render() {
    return (
      <CustomScroll>
        <div className="privacy tab-inner page-settings-permissions-tab">
          <baseUI.tabs.holder
            valueLink={this.getTabFocusLink()}
            className="left radio"
          >
            <baseUI.tabs.header>
              <baseUI.tabs.label htmlFor={CONSTANTS.PERMISSION_STATES.NONE}>
                <h4>{translate('Pages_Permissions_NoRestrict_Title')}</h4>
                <span>{translate('Pages_Permissions_NoRestrict_Text')}</span>
              </baseUI.tabs.label>

              <baseUI.tabs.label htmlFor="password">
                <h4>{translate('Pages_Permissions_Password_Title')}</h4>
                <span>{translate('Pages_Permissions_Password_Text')}</span>

                {this.state.focusedProtectionType ===
                CONSTANTS.PERMISSION_STATES.PASSWORD ? (
                  <div
                    key="page-settings-tab-privacy-password"
                    className="password-settings-content"
                  >
                    <hr className="separator-permissions-password" />
                    <Composites.TextInputLabeled>
                      <TextLabel value="Pages_Permissions_Password_Choose" />
                      {this.shouldShowPasswordMockElement() ? (
                        <TextInput
                          key="passwordMockElement"
                          onClick={this.onPasswordFocus}
                          value={CONSTANTS.PASSWORD_TEMPLATE}
                          type="password"
                        />
                      ) : null}

                      {!this.shouldShowPasswordMockElement() ? (
                        <TextInput
                          key="realPasswordElement"
                          focus={!this.isPasswordHidden()}
                          onFocus={this.onPasswordFocus}
                          onBlur={this.onPasswordBlur}
                          value={this.state.plainPassword}
                          type={this.state.passwordInputType}
                          placeholder="Pages_Permissions_Password_Placeholder"
                          maxLength={CONSTANTS.PASSWORD_MAX_LENGTH}
                          validator={this.isValidPassword}
                          invalidMessage="Pages_Permissions_Password_Error_Tooltip"
                        />
                      ) : null}
                    </Composites.TextInputLabeled>

                    <hr className="separator-permissions-password-language" />
                    <Composites.DropDownLabeled>
                      <TextLabel value="Pages_Permissions_Password_Language" />
                      <DropDown
                        value={
                          this.getDialogLanguageLink('passwordDialogLanguage')
                            .value
                        }
                        onChange={
                          this.getDialogLanguageLink('passwordDialogLanguage')
                            .requestChange
                        }
                        className="control-permissions-password-language"
                        shouldTranslate={false}
                      >
                        {util.languages.getLanguagesList().map((language) => (
                          <DropDownOption
                            key={language.value}
                            value={language.value}
                            label={language.label}
                            shouldTranslate={false}
                          />
                        ))}
                      </DropDown>
                    </Composites.DropDownLabeled>
                  </div>
                ) : null}
              </baseUI.tabs.label>

              <baseUI.tabs.label htmlFor={CONSTANTS.PERMISSION_STATES.MEMBERS}>
                <h4>{translate('Pages_Permissions_Members_Title')}</h4>
                <span>{translate('Pages_Permissions_Members_Text')}</span>

                {this.state.focusedProtectionType ===
                CONSTANTS.PERMISSION_STATES.MEMBERS ? (
                  <div key="page-settings-tab-privacy-member">
                    <p className="site-members-info">
                      {translate('Pages_Permissions_Members_Link')}
                      {this.isSiteSaved() ? (
                        <a
                          key="page-settings-tab-privacy-member-url"
                          href={this.getSiteMembersUrl()}
                          target="_blank"
                        >
                          <baseUI.button
                            label="Pages_Permissions_Members_Link_Clickhere"
                            className="btn-text"
                          />
                        </a>
                      ) : null}
                      {!this.isSiteSaved() ? (
                        <span key="page-settings-tab-privacy-member-non-url">
                          {translate(
                            'Pages_Permissions_Members_Link_Clickhere',
                          )}
                        </span>
                      ) : null}
                    </p>
                    <hr className="separator-site-members-info" />

                    <Composites.DropDownLabeled>
                      <TextLabel value="Pages_Permissions_Members_Language" />
                      <DropDown
                        value={
                          this.getDialogLanguageLink('membersDialogLanguage')
                            .value
                        }
                        onChange={
                          this.getDialogLanguageLink('membersDialogLanguage')
                            .requestChange
                        }
                        className="control-permissions-member-language"
                        shouldTranslate={false}
                      >
                        {util.languages.getLanguagesList().map((language) => (
                          <DropDownOption
                            key={language.value}
                            value={language.value}
                            label={language.label}
                            shouldTranslate={false}
                          />
                        ))}
                      </DropDown>
                    </Composites.DropDownLabeled>

                    <hr className="separator-permissions-member-language" />

                    <div
                      key="smSettingsPanelBtn"
                      className="site-members-settings"
                    >
                      <baseUI.symbol name="site-members" />
                      <a
                        onClick={this.openSiteMembersSettingsPanel}
                        className="link"
                      >
                        {translate(
                          'Pages_Permissions_Member_Signup_Settings_Label',
                        )}
                      </a>
                      <baseUI.infoIcon text="Pages_Permissions_Member_Signup_Settings_Tooltip" />
                    </div>
                  </div>
                ) : null}
              </baseUI.tabs.label>
            </baseUI.tabs.header>
          </baseUI.tabs.holder>
        </div>
      </CustomScroll>
    );
  },
});
