// @ts-nocheck
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Bubble } from '@wix/wix-base-ui';

import PanelContent from './textAndLinkPanel';

function template() {
  return (
    <Bubble
      onMouseEnter={this.props.onMouseEnter}
      onMouseLeave={this.props.onMouseLeave}
      arrowAlignment="top"
      contentStyle={this.bubbleContentStyle}
      arrowStyle={this.bubbleArrowStyle}
      maxWidth={750}
      className="drop-panel-top-bar"
    >
      <PanelContent panelData={this.props.dropPanelData} />
    </Bubble>
  );
}

const BUBBLE_ARROW_STYLE = {
  position: 'absolute',
  right: 'calc(50% - 8px)',
  top: '53px',
};

const BUBBLE_CONTENT_STYLE = {
  position: 'absolute',
  display: 'block',
  right: 'calc(50% - 120px)',
  top: '60px',
};

class TextAndLinkDropPanel extends React.Component {
  constructor(props) {
    super(props);

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/assign
    this.bubbleContentStyle = _.assign(
      {},
      BUBBLE_CONTENT_STYLE,
      props?.bubbleStyleOverrides?.content ?? {},
    );
    this.bubbleArrowStyle = BUBBLE_ARROW_STYLE;
  }
  componentWillUnmount() {
    if (this.props.dropPanelData.onUnmounted) {
      this.props.dropPanelData.onUnmounted();
    }
  }
  render() {
    return template.call(this);
  }
}

TextAndLinkDropPanel.displayName = 'TextAndLinkDropPanel';

TextAndLinkDropPanel.propTypes = {
  dropPanelData: PropTypes.shape({
    body: PropTypes.string.isRequired,
    title: PropTypes.string,
    keyText: PropTypes.string,
    linkText: PropTypes.string,
    linkAction: PropTypes.func,
  }).isRequired,
  bubbleStyleOverrides: PropTypes.shape({
    content: PropTypes.object,
    arrow: PropTypes.object,
  }),
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
};

export default TextAndLinkDropPanel;
