// @ts-nocheck
import _ from 'lodash';
import * as constants from '@/constants';
import * as gfppData from '@/gfppData';
import * as stateManagement from '@/stateManagement';

const { isReferredComponent } = stateManagement.components.selectors;

export default function (editorAPI, compRefs, compGfppData) {
  function isSelectableContainer(comRef) {
    return (
      editorAPI.components.getType(comRef) === constants.SELECTABLE_CONTAINER
    );
  }

  if (
    editorAPI.components.is.repeaterItem(compRefs) &&
    !isSelectableContainer(Array.isArray(compRefs) ? compRefs[0] : compRefs)
  ) {
    if (compGfppData.mainActions && !isReferredComponent(_.head(compRefs))) {
      compGfppData.mainActions.push(
        gfppData.repeaterGfppUtils.getManageItemAction(),
      );
    }
  }
}
