import _ from 'lodash';
import constants from '@/constants';
import { cx } from '@/util';
import React from 'react';
import { DropDown, DropDownOption } from '@wix/wix-base-ui';

export interface DropDownControllerOption {
  id: number;
  label: string;
  active: boolean;
  key: string;
}

export interface DropDownControllerProps {
  options: DropDownControllerOption[];
  label: string;
  selectFocusedContainer: () => void;
  onSelect: (value: AnyFixMe) => void;
  componentUIColor: ValueOf<typeof constants.COMPONENT_UI_COLORS>;
  componentUITheme: ValueOf<typeof constants.COMPONENT_UI_THEMES>;

  className?: string;
}

class DropDownController extends React.Component<DropDownControllerProps> {
  getDropDownControllerClasses() {
    return {
      'dropdown-controller': true,
      'components-ui-color-orange':
        this.props.componentUIColor === constants.COMPONENT_UI_COLORS.ORANGE,
      'components-ui-theme-light':
        this.props.componentUITheme === constants.COMPONENT_UI_THEMES.LIGHT,
    };
  }

  getValue() {
    return (
      this.props.options.find((option) => option.active) ||
      _.head(this.props.options)
    ).id;
  }

  render() {
    return (
      <div
        className={cx(
          this.props.className,
          this.getDropDownControllerClasses(),
        )}
      >
        <span
          onClick={this.props.selectFocusedContainer}
          className="controller-label"
        >
          {this.props.label}
        </span>
        <DropDown
          skin="dark"
          componentUIColor={this.props.componentUIColor}
          componentUITheme={this.props.componentUITheme}
          value={this.getValue()}
          onChange={this.props.onSelect}
          shouldTranslate={false}
          openOnSelected={false}
          automationId="dropdown-controller"
          optionsContainerClassName="controller-options"
        >
          {this.props.options.map((option) => (
            <DropDownOption
              automationId="dropdown-controller-option"
              value={option.id}
              label={option.label}
              key={option.id}
              shouldTranslate={false}
            />
          ))}
        </DropDown>
      </div>
    );
  }
}

export default DropDownController;
