import type { CompRef } from 'types/documentServices';
import type { MapStateToProps } from 'types/redux';
import _ from 'lodash';
import {
  interactions,
  text,
  domMeasurements,
  selection,
} from '@/stateManagement';
import type { TextEditorOwnProps } from './textEditor';
const { isInInteractionMode } = interactions.selectors;
const { isTextEditorOpen, isStyleOnly, isLinkPanelOpen } = text.selectors;
const { getPreviewPosition } = domMeasurements.selectors;
const { getSelectedCompsRefs } = selection.selectors;

interface TextEditorPosition {
  top: number;
  left: number;
  scale: number;
  width: number;
  height: number;
  position: 'fixed' | 'absolute';
  zIndex?: number;
  visibility?: string;
  overflow?: string;
}

interface HiddenTextEditorPosition {
  visibility: string;
}

export interface TextEditorStateProps {
  isOpenTextEditor: boolean;
  isRepeatedTextComp: boolean;
  styleOnly: boolean;
  isTextLimitExceededPanelOpen: boolean;
  selectedTextComp: CompRef;
  layout: TextEditorPosition | HiddenTextEditorPosition | {};
  isOpenLinkPanel: boolean;
}

export const mapStateToProps: MapStateToProps<
  TextEditorStateProps,
  TextEditorOwnProps
> = ({ editorAPI, state }, ownProps) => {
  const isOpenTextEditor = isTextEditorOpen(state);
  const isOpenLinkPanel = isLinkPanelOpen(state);
  const isRepeatedTextComp = editorAPI.text.isEditingRepeatedTextComp();
  const styleOnly = isStyleOnly(state);
  const selectedComponents = getSelectedCompsRefs(state);

  const getComponentData = (compRef: CompRef) =>
    editorAPI.components.data.get(compRef);
  const getRelativeToScreen = (compRef: CompRef) =>
    editorAPI.components.layout.getRelativeToScreen(compRef);
  const getIsShowOnFixedPosition = (compRef: CompRef) =>
    editorAPI.components.layout.isShowOnFixedPosition(compRef);
  const previewPosition = getPreviewPosition(state);

  const TEXT_LIMIT_EXCEEDED_PANEL_NAME =
    'panels.focusPanels.createNewTextBoxPanel';
  const isTextLimitExceededPanelOpen = Boolean(
    editorAPI
      .getOpenPanels()
      .find((panel) => panel.name === TEXT_LIMIT_EXCEEDED_PANEL_NAME),
  );

  const getWidgetDesignSelectedComponents = () =>
    text.selectors.getWidgetDesignSelectedComponents(
      editorAPI.store.getState(),
    );

  const getIsVerticalTextComp = (compRef: CompRef) =>
    editorAPI.components.properties.get(compRef)?.verticalText;

  const getTopPositionForComp = (compRef: CompRef) => {
    const compPositionRelativeToScreen = getRelativeToScreen(compRef);

    let top = compPositionRelativeToScreen.y;
    const isShowOnFixedPosition = getIsShowOnFixedPosition(compRef);
    if (isShowOnFixedPosition) {
      top += previewPosition.top;
    }
    return top;
  };

  const getTopSelectedComponent = () => {
    const selectedComps =
      getWidgetDesignSelectedComponents() || selectedComponents;
    const topPositions = selectedComps.map((compRef) => ({
      top: getTopPositionForComp(compRef),
      compRef,
    }));
    const isVerticalText = selectedComps.some((compRef) =>
      getIsVerticalTextComp(compRef),
    );
    const getSelectedPosition = isVerticalText ? _.last : _.head;
    const selectedPosition = getSelectedPosition(_.sortBy(topPositions, 'top'));
    return selectedPosition.compRef;
  };

  const getSelectedTextComp = () => {
    if (ownProps.textCompId) {
      return editorAPI.components.get.byId(ownProps.textCompId);
    }
    const selectedComps =
      getWidgetDesignSelectedComponents() || selectedComponents;
    if (selectedComps && selectedComps.length > 0) {
      const compRef =
        selectedComps.length > 1 ? getTopSelectedComponent() : selectedComps[0];
      const compData = getComponentData(compRef);
      const type = compData?.type;
      const isTextType = type === 'StyledText';

      return isTextType ? compRef : null;
    }

    return null;
  };

  const getTextEditorPosition = (selectedTextComp: CompRef) => {
    let result: TextEditorPosition | HiddenTextEditorPosition;
    const isTextEditorInStyleOnlyMode = isStyleOnly(state);
    if (selectedTextComp) {
      const compPositionRelativeToScreen =
        getRelativeToScreen(selectedTextComp);
      const isShowOnFixedPosition = getIsShowOnFixedPosition(selectedTextComp);
      const isInteractionMode = isInInteractionMode(state);

      result = {
        top: compPositionRelativeToScreen.y,
        left: compPositionRelativeToScreen.x,
        scale: compPositionRelativeToScreen.scale,
        width: compPositionRelativeToScreen.width,
        height: compPositionRelativeToScreen.height,
        position: isShowOnFixedPosition ? 'fixed' : 'absolute',
      };

      if (compPositionRelativeToScreen.rotationInDegrees) {
        const transformValue = `rotate(${compPositionRelativeToScreen.rotationInDegrees}deg)`;
        Object.assign(result, {
          transform: transformValue,
          WebkitTransform: transformValue,
        });
      }

      if (isShowOnFixedPosition) {
        result.top += previewPosition.top;
        result.left += previewPosition.left;
      }

      if (isInteractionMode) {
        result.zIndex = 1;
      }
    }

    if (!isOpenTextEditor || isTextEditorInStyleOnlyMode) {
      result = Object.assign(
        result || {},
        { visibility: 'hidden' },
        ownProps.customLayout,
      );
    }

    return result || {};
  };

  const selectedTextComp = getSelectedTextComp();
  const layout = getTextEditorPosition(selectedTextComp);

  return {
    isOpenTextEditor,
    isRepeatedTextComp,
    styleOnly,
    isTextLimitExceededPanelOpen,
    layout,
    selectedTextComp,
    isOpenLinkPanel,
  };
};
