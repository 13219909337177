/*eslint max-lines: [2, { "max": 1117, "skipComments": true, "skipBlankLines": true}]*/
// @ts-nocheck
import constants from '@/constants';
import type { EditorAPI } from '@/editorAPI';
import * as stateManagement from '@/stateManagement';
import { EditorPlatformHostIntegrationAPI } from '@wix/editor-platform-host-integration-apis';
import type {
  AddComponentHandler,
  AppData as PlatformAppData,
  ComponentRef,
  RemoveComponentHandler,
  EditorNamespace,
  NativePanelType,
  ComponentPanelsConfigurations,
} from '@wix/platform-editor-sdk';
import experiment from 'experiment';
import _ from 'lodash';
import type { AppData } from 'types/documentServices';
import platformConsts from '../../common/constants';
import { getPanelProps } from '../../common/utils';
import * as editorUtils from './utils/utils';
import { fedopsLogger } from '@/util';
import {
  navigateAndOpenPanel,
  createOpenTpaComponentPanelHandler,
} from './panelHelpers/panelHelpers';
import type {
  OpenConfirmationPanelOptions,
  OpenPromotionalPanelOptions,
  OpenErrorPanelOptions,
} from '../../panels/platformPanelsUtils';
import {
  AddElementsOptions,
  OpenNativeComponentPanelOptions,
  ShowUserActionNotificationOptions,
} from './editorInterfaces';
import * as sectionsService from '../../services/sectionsService';

const { openPlatformPanel, updateOrOpenPanel } = stateManagement.panels.actions;
const mediaPayloadConverterByType = {
  IMAGE(payload) {
    return {
      uri: payload.uri,
      title: payload.title,
      width: payload.width,
      height: payload.height,
    };
  },
  DOCUMENT(payload) {
    return {
      uri: payload.uri,
      title: payload.title,
      fileUrl: payload.fileUrl,
    };
  },
  DEFAULT(payload) {
    return payload;
  },
};

function openNativeComponentPanel<
  ComponentType extends keyof ComponentPanelsConfigurations,
  NativePanel extends NativePanelType<ComponentType> = NativePanelType<ComponentType>,
>(
  editorAPI: EditorAPI,
  appData: AppData,
  nativePanelType: NativePanel | string,
  {
    componentRef,
    panelSectionsDefinition,
    helpId,
    configuration,
  }: OpenNativeComponentPanelOptions<ComponentType, NativePanel>,
) {
  return new Promise((resolve, reject) => {
    const nativePanelName = platformConsts.NATIVE_PANELS[nativePanelType];

    if (!nativePanelName) {
      return reject(new Error(`panelType ${nativePanelType} unknown`));
    }
    if (!componentRef) {
      return reject(new Error('No componentRef provided'));
    }
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/map
    const allConnections = _.map(
      editorAPI.dsRead.platform.controllers.connections.get(componentRef),
      'controllerRef',
    );

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/filter
    const applicationConnections = _.filter(
      allConnections,
      (controllerRef) =>
        editorAPI.dsRead.components.data.get(controllerRef).applicationId ===
        appData.appDefinitionId,
    );
    if (_.isEmpty(applicationConnections)) {
      return reject(
        new Error(
          `Component ${componentRef.id} is not connected to app ${appData.appDefinitionId}`,
        ),
      );
    }

    const panelSectionsOverride =
      panelSectionsDefinition &&
      (nativePanelType === 'settings' || nativePanelType === 'manage')
        ? { panelSectionsDefinition }
        : {};

    const panelProps = {
      experimentIsOpen: experiment.isOpen,
      helpId,
      ...panelSectionsOverride,
      configuration,
    };

    editorAPI.selection.selectComponentByCompRef(componentRef);
    editorAPI.openComponentPanel(nativePanelName, panelProps, {
      panelMetaData: {
        resolve,
      },
    });
  });
}

type ComponentPanelOptions = Parameters<
  EditorNamespace['openComponentPanel']
>[1];

function openComponentPanel(
  editorAPI: EditorAPI,
  appData: AppData,
  token: string,
  options: ComponentPanelOptions,
  onOpen: (panelToken: string) => void,
) {
  const tpaModalPanelUrl = getTPAModalPanelUrl(
    editorAPI,
    options.url,
    appData.applicationId,
  );
  const props = getPanelProps(
    editorAPI,
    appData,
    { scrolling: 'auto', ...options },
    tpaModalPanelUrl,
  );

  if (onOpen) {
    onOpen(props.token);
  }

  editorAPI.selection.selectComponentByCompRef(options.componentRef);
  return new Promise(function (resolve) {
    editorAPI.store.dispatch(
      openPlatformPanel({
        panelName: platformConsts.panelTypes.COMPONENT_PANEL,
        panelProps: props,
        panelMetaData: {
          resolve,
          frameType: constants.PANEL_TYPES.COMP,
          closeAllOtherPanels: true,
        },
      }),
    );
  });
}

function openComponentPanelWithSiteContext(
  editorAPI: EditorAPI,
  appData: AppData,
  token: string,
  options: ComponentPanelOptions,
  onOpen: (panelToken: string) => void,
) {
  openComponentPanel(
    editorAPI,
    appData,
    token,
    { ...options, useEditorContext: true },
    onOpen,
  );
}

function showPanelPreloader(
  editorAPI: EditorAPI,
  appData: AppData,
  panelToken: string,
) {
  if (
    experiment.isOpen('specs.responsive-editor.disablePlatformPanelPreloader')
  ) {
    return;
  }
  return editorAPI.componentPanelPreloader.showPreloader(panelToken);
}

function hidePanelPreloader(
  editorAPI: EditorAPI,
  appData: AppData,
  panelToken: string,
) {
  if (
    experiment.isOpen('specs.responsive-editor.disablePlatformPanelPreloader')
  ) {
    return;
  }
  return editorAPI.componentPanelPreloader.hidePreloader(panelToken);
}

type OpenChangeVariationsPanelOptions = Parameters<
  EditorNamespace['openChangeVariationsPanel']
>[1];

function openChangeVariationsPanel(
  editorAPI: EditorAPI,
  appData: AppData,
  token: string,
  options: OpenChangeVariationsPanelOptions,
) {
  return new Promise(function (resolve, reject) {
    if (!options.presets) {
      reject(new Error('options must contain presets'));
      return;
    }
    const invalidPresets = options.presets.some(
      (preset) =>
        !preset.variationId || !(preset.mainImageUrl || preset.displayName),
    );
    if (invalidPresets) {
      reject(
        new Error(
          'Each preset must contain variationId and mainImageUrl or displayName properties',
        ),
      );
      return;
    }
    const props = getPanelProps(editorAPI, appData, options);
    props.applicationId = appData.applicationId;
    editorAPI.store.dispatch(
      openPlatformPanel({
        panelName: platformConsts.panelTypes.CHANGE_VARIATIONS,
        panelProps: props,
        panelMetaData: {
          resolve,
          frameType: constants.PANEL_TYPES.COMP,
          closeAllOtherPanels: true,
        },
      }),
    );
  });
}

function openModalPanel(
  editorAPI: EditorAPI,
  appData: AppData,
  token: string,
  options,
) {
  const tpaModalPanelUrl = getTPAModalPanelUrl(
    editorAPI,
    options.url,
    appData.applicationId,
  );
  const props = getPanelProps(editorAPI, appData, options, tpaModalPanelUrl);
  return new Promise(function (resolve) {
    editorAPI.store.dispatch(
      openPlatformPanel({
        panelName: platformConsts.panelTypes.MODAL,
        panelProps: props,
        panelMetaData: {
          resolve,
        },
      }),
    );
  });
}

function openPagePanel(editorAPI, appData, token, options) {
  const pageId = options.pageRef.id;
  const renameEnabled = options?.renameEnabled || false;
  const dynamicPageSettings =
    editorAPI.pages.dynamicPages.getDynamicPageActions(pageId, 'pageSettings');
  const panelProps = _.isEmpty(dynamicPageSettings)
    ? {
        initialSettingsTabType: constants.PAGE_SETTINGS_TABS.PAGE_INFO,
        renameEnabled,
        origin: options?.origin,
      }
    : {
        initialSettingsTabType: _.head(dynamicPageSettings).type,
        renameEnabled,
        origin: options?.origin,
      };
  editorAPI.navigateAndOpenPagesPanel(pageId, panelProps);
}

function openPagesPanel(editorAPI, appData, token, options) {
  const openPanelInteraction = fedopsLogger.mapInteraction(
    fedopsLogger.INTERACTIONS.PAGES_PANEL.OPEN_PANEL,
  );
  const closePanelInteraction = fedopsLogger.mapInteraction(
    fedopsLogger.INTERACTIONS.PAGES_PANEL.CLOSE_PANEL,
  );

  return new Promise((resolve, reject) => {
    const pageId = options.pageRef.id;
    if (!editorAPI.pages.doesPageExist(pageId)) {
      reject('PageRef does not exist'); //eslint-disable-line prefer-promise-reject-errors
      return;
    }
    const panelProps = {
      renameEnabled: options?.renameEnabled,
      initialSettingsTabType: options?.initialSettingsTabType,
      origin: options?.origin,
      openPanelInteraction,
      closePanelInteraction,
      frameType: constants.PANEL_TYPES.LEFT,
    };
    openPanelInteraction.start();
    editorAPI.navigateAndOpenPagesPanel(pageId, panelProps, resolve);
  });
}

function closePagesPanel(editorAPI) {
  editorAPI.panelHelpers.closePagesPanel();
}

function canAddStaticPage(editorAPI) {
  return editorAPI.pages.canAddStaticPage();
}

const getOffset = (node: Element) => {
  // https://github.com/madrobby/zepto/blob/master/src/zepto.js#L716
  const { left, top, width, height } = node.getBoundingClientRect();

  return {
    left: left + window.pageXOffset,
    top: top + window.pageYOffset,
    width: Math.round(width),
    height: Math.round(height),
  };
};

function calcPanelPosition(editorAPI, options = {}) {
  const openPanels = editorAPI.panelManager.getOpenPanels();
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/find
  const platformPanel = _.find(openPanels, { type: 'platform' });
  if (platformPanel) {
    const iframe = window.document.querySelector(
      `[name="${platformPanel.token}"]`,
    );

    if (iframe) {
      const iframePos = getOffset(iframe);
      if (
        iframePos &&
        _.isNumber(iframePos.top) &&
        _.isNumber(iframePos.left)
      ) {
        if (_.isNumber(options.x) && _.isNumber(options.y)) {
          return {
            top: iframePos.top + options.y,
            left: iframePos.left + options.x,
          };
        }
        if (
          _.isNumber(options.height) &&
          platformPanel?.props?.height &&
          platformPanel?.props?.width
        ) {
          return {
            top:
              iframePos.top +
              platformPanel.props.height / 2 -
              options.height / 2,
            left: iframePos.left + platformPanel.props.width / 2,
          };
        }
      }
    }
  }
  return {
    top: '25%',
    left: '45%',
  };
}

function openToolPanel(editorAPI, appData, token, options) {
  const props = getPanelProps(editorAPI, appData, options);
  const path = platformConsts.panelTypes.TOOL;
  const position = calcPanelPosition(editorAPI, options);
  _.merge(props, position);

  return new Promise(function (resolve) {
    editorAPI.store.dispatch(
      openPlatformPanel({
        panelName: path,
        panelProps: props,
        panelMetaData: {
          resolve,
        },
      }),
    );
  });
}

function openSidePanel(editorAPI, appData, token, options) {
  const defaultOptions = { displayAboveModals: true };
  const props = getPanelProps(editorAPI, appData, {
    ...defaultOptions,
    ...options,
  });

  return new Promise(function (resolve) {
    editorAPI.store.dispatch(
      openPlatformPanel({
        panelName: platformConsts.panelTypes.DOCKED,
        panelProps: props,
        panelMetaData: {
          resolve,
          closeWithUserIntent: true,
          frameType: constants.PANEL_TYPES.DOCKED_PANEL,
        },
      }),
    );
  });
}

function openLinkPanel(editorAPI, appData, token, options) {
  return new Promise(function (resolve) {
    const linkPanelProps = {
      link: options?.value ?? null,
      visibleSections: options?.visibleSections ?? null,
      callback: resolve,
      hideLinkTarget: !options?.showLinkTargetSection,
      onCancelCallback: resolve,
    };
    editorAPI.openLinkPanel(linkPanelProps);
  });
}

function openColorPicker(editorAPI, _appData, _token, options, onColorChanged) {
  const defaultOptions = {
    position: {
      x: -12,
      y: 72,
    },
    color: '#FFFFFF',
  };

  const opts = Object.assign({}, defaultOptions, options);

  let promiseResolve;
  const panelClosedPromise = new Promise((resolve) => {
    promiseResolve = resolve;
  });

  editorAPI.openColorPicker(
    opts.color,
    calcPanelPosition(editorAPI, opts.position),
    {
      onChange: (color) => {
        let hexColor = null;
        let themeColor = null;

        const colors = editorAPI.theme.colors.getAll();

        if (color.includes('color_')) {
          hexColor = colors[color];
          themeColor = color;
        } else {
          hexColor = color;
          themeColor = Object.keys(colors).find(
            (colorName) => colors[colorName] === hexColor,
          );
        }

        onColorChanged({
          color: hexColor,
          theme: themeColor,
          isHover: false,
        });
      },
      onClose: promiseResolve,
      allowPaletteEditing: true,
      enableHistory: false,
      previewOnHover: false,
      overridePosition: true,
      panelMode: 'solid',
    },
  );

  return panelClosedPromise;
}

function openFontPicker(
  editorAPI: EditorAPI,
  appData,
  token: string,
  options: {
    position?: {
      x: number;
      y: number;
    };
    title?: string;
    panelSectionsDefinition?: Partial<{
      theme: 'hidden' | 'enabled';
      font: 'hidden' | 'enabled';
      size: 'hidden' | 'enabled';
      style: 'hidden' | 'enabled';
      htmlTag: 'hidden' | 'enabled';
    }>;
    fontMaxSize?: number;
    fontMinSize?: number;
    componentStyle?: {
      preset: string;
      editorKey: string;
      family: string;
      size: number;
      style: {
        bold: boolean;
        italic: boolean;
        underline: boolean;
      };
      htmlTag?: string;
    };
  },
  onStyleChanged: (
    styleChange: [string, string | boolean | number, string] | [string, string],
  ) => void,
): Promise<void> {
  const defaultOptions: Readonly<{
    position: {
      x: number;
      y: number;
    };
    panelSectionsDefinition: {
      theme: 'hidden' | 'enabled';
      font: 'hidden' | 'enabled';
      size: 'hidden' | 'enabled';
      style: 'hidden' | 'enabled';
      htmlTag: 'hidden' | 'enabled';
    };
  }> = {
    position: {
      x: -12,
      y: 72,
    },
    panelSectionsDefinition: {
      theme: 'enabled',
      font: 'enabled',
      size: 'enabled',
      style: 'enabled',
      htmlTag: 'hidden',
    },
  };

  const opts = {
    ...defaultOptions,
    ...options,
    panelSectionsDefinition: {
      ...defaultOptions.panelSectionsDefinition,
      ...options.panelSectionsDefinition,
    },
  };

  return new Promise((resolve) => {
    editorAPI.panelManager.openPanel(
      'tpaPanels.uiLib.uiLibTextParamDesignPanel',
      Object.assign(
        {
          title: opts.title,
          hideTheme: opts.panelSectionsDefinition.theme === 'hidden',
          hideFont: opts.panelSectionsDefinition.font === 'hidden',
          hideSize: opts.panelSectionsDefinition.size === 'hidden',
          hideStyle: opts.panelSectionsDefinition.style === 'hidden',
          showHTMLTag: opts.panelSectionsDefinition.htmlTag === 'enabled',
          fontMaxSize: opts.fontMaxSize,
          fontMinSize: opts.fontMinSize,
          compStyle: opts.componentStyle,
          onStyleChange: onStyleChanged,
          onClose: resolve,
        },
        calcPanelPosition(editorAPI, opts.position),
      ),
      true,
    );
  });
}

function openMediaPanel(editorAPI, appData, token, options) {
  options = _.defaults(options || {}, {
    callOnCancel: true,
    isMultiSelect: true,
    mediaType: 'IMAGE',
  });

  return new Promise(function (resolve) {
    const handleMediaDialogResult = function (mediaDialogPayloads) {
      let payload;
      if (!_.isEmpty(mediaDialogPayloads)) {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/is-array
        if (!_.isArray(mediaDialogPayloads)) {
          mediaDialogPayloads = [mediaDialogPayloads];
        }
        const mediaPayloadConverter = _.get(
          mediaPayloadConverterByType,
          options.mediaType,
          mediaPayloadConverterByType.DEFAULT,
        );
        payload = mediaDialogPayloads.map(mediaPayloadConverter);
      } else if (options.callOnCancel) {
        payload = null;
      }

      resolve(payload);
      editorAPI.mediaServices.setFocusToEditor();
    };
    editorAPI.mediaServices.mediaManager.open(options.mediaType, {
      multiSelect: options.isMultiSelect,
      callback: handleMediaDialogResult,
    });
  });
}

function openHelpPanel(editorAPI, appData, token, options) {
  return new Promise((resolve) => {
    const helpPanelProps = {
      origin: 'platform',
      onClose: resolve,
    };
    const { helpId, anchorId } = options;
    if (anchorId) {
      helpPanelProps.anchorId = anchorId;
    }
    const biHelpParams = {
      component: editorAPI.selection.getSelectedComponentType(),
      learn_more: false,
      panel_name: 'platform',
    };

    editorAPI.panelManager.openHelpCenter(helpId, helpPanelProps, biHelpParams);
  });
}

function getTPAModalPanelUrl(
  editorAPI: EditorAPI,
  url: string | undefined,
  applicationId: number | undefined,
) {
  if (url && applicationId) {
    const selectedCompRef = editorAPI.selection.getSelectedComponents()[0];
    const isTpaByCompType = editorAPI.dsRead.tpa.isTpaByCompType(
      editorAPI.components.getType(selectedCompRef),
    );
    if (!isTpaByCompType) {
      return undefined;
    }

    const allAppComponents =
      editorAPI.dsRead.tpa.app.getAllCompsByApplicationId(applicationId);
    const isSelectedComponentOwnedByApp = allAppComponents?.some(
      ({ id }) => id === selectedCompRef.id,
    );
    if (!isSelectedComponentOwnedByApp) {
      return undefined;
    }

    return editorAPI.dsRead.tpa.app.url.getSettingsModalUrl(
      {
        url,
        deviceType: 'desktop',
        applicationId,
        origCompId: selectedCompRef.id,
      },
      selectedCompRef.id,
    );
  }
}

function openFullStagePanel(editorAPI, appData, token, options) {
  const fullStagePanelProps = getPanelProps(editorAPI, appData, options);
  editorAPI.store.dispatch(
    openPlatformPanel({
      panelName: platformConsts.panelTypes.FULL_STAGE,
      panelProps: fullStagePanelProps,
      panelMetaData: {
        frameType: constants.PANEL_TYPES.FULL_STAGE,
        closeAllOtherPanels: true,
      },
    }),
  );
}

function getPanelFromToken(editorAPI, token) {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/find
  const panelDescriptor = _.find(editorAPI.panelManager.getOpenPanels(), {
    token,
  });

  return panelDescriptor;
}

function updatePanelOptions(editorAPI, appData, token, options) {
  const panelDescriptor = getPanelFromToken(editorAPI, token);
  if (!panelDescriptor) {
    return;
  }

  const props = getPanelProps(editorAPI, appData, options);
  props.token = token;

  editorAPI.panelManager.updatePanelProps(panelDescriptor.name, props);
}

function closePanel(editorAPI, appData, token, value) {
  editorAPI.store.dispatch(
    stateManagement.panels.actions.closePlatformPanel(
      token,
      value,
      'apiMethodCall',
    ),
  );
}

function setAppAPI(
  editorAPI: EditorApi,
  appData: AppData,
  token: string,
  options: any,
) {
  const platformAPI = editorAPI.host.getAPI(EditorPlatformHostIntegrationAPI);
  if (options?.apiName) {
    platformAPI.applications.setAppPublicAPI(options.apiName);
  }
  // migrating from using applicationId as apiName to more specific name
  // needed for compatibility with older sdk versions
  // TODO: remove once verified no apps are effected
  return platformAPI.applications.setAppPublicAPI(appData.applicationId);
}

function openPanel(editorAPI, appData, panelName, panelProps, leavePanelsOpen) {
  // TODO: replace with public openExtensionsPrimaryPanel method
  if (panelName === platformConsts.panelTypes.EXTENSIONS_PRIMARY) {
    openExtensionsPrimaryPanel(editorAPI, panelProps);
  } else {
    editorAPI.panelManager.openPanel(panelName, panelProps, leavePanelsOpen);
  }
}

function openExtensionsPrimaryPanel(editorAPI, panelProps) {
  editorAPI.store.dispatch(
    openPlatformPanel({
      panelName: platformConsts.panelTypes.EXTENSIONS_PRIMARY,
      panelProps,
    }),
  );
}

function getCompRefByWidgetId(
  editorAPI: EditorApi,
  widgetId: string,
  appDefinitionId: string,
) {
  const applicationId =
    editorAPI.dsRead.tpa.app.getDataByAppDefId(appDefinitionId)?.applicationId;
  if (!applicationId) {
    throw new Error(
      `Could not find application data for appDefinitionId: ${appDefinitionId}`,
    );
  }
  const components =
    editorAPI.dsRead.tpa.app.getAllCompsByApplicationId(applicationId);
  const widgetCompId = components?.find((c) => c.widgetId === widgetId)?.id;
  if (!widgetCompId) {
    throw new Error(
      `Could not find component with widgetId: ${widgetId} for applicationId: ${applicationId}`,
    );
  }
  return editorAPI.components.get.byId(widgetCompId);
}

function getCompRefFromOptions(
  editorAPI: EditorApi,
  appData: AppData,
  options:
    | { widgetId: string; appDefinitionId?: string }
    | { compRef: ComponentRef },
): ComponentRef {
  const compRef: ComponentRef =
    options.compRef ??
    getCompRefByWidgetId(
      editorAPI,
      options.widgetId,
      options.appDefinitionId ?? appData.appDefinitionId, // options.appDefinitionId may be used for opening widget of external apps
    );
  return compRef;
}

function openSettingsPanel(
  editorAPI: EditorApi,
  appData: AppData,
  options:
    | { widgetId: string; appDefinitionId?: string }
    | { compRef: ComponentRef },
) {
  const compRef = getCompRefFromOptions(editorAPI, appData, options);
  navigateAndOpenPanel(editorAPI, compRef, 'settings');
}

function closeTpaSettingsPanel(editorAPI: EditorApi, compRef: ComponentRef) {
  const openedPanel = editorAPI.panelManager
    .getOpenPanels()
    .find(
      (panel: AnyFixMe) =>
        panel.props?.origCompId === compRef.id &&
        panel.name === 'tpa.compPanels.tpaSettings',
    );
  if (!openedPanel) {
    return;
  }
  editorAPI.panelManager.closePanelByName(openedPanel.name);
}

function closeNativeSettingsPanel(editorAPI: EditorApi, compRef: ComponentRef) {
  const openedPanel = editorAPI.panelManager
    .getOpenPanels()
    .find(
      (panel: AnyFixMe) =>
        panel.componentRef?.id === compRef.id &&
        panel.name.endsWith('settingsPanel'),
    );
  if (!openedPanel) {
    return;
  }
  editorAPI.panelManager.closePanelByName(openedPanel.name);
}

function closeSettingsPanel(
  editorAPI: EditorApi,
  appData: AppData,
  options:
    | { widgetId: string; appDefinitionId?: string }
    | { compRef: ComponentRef },
) {
  const compRef = getCompRefFromOptions(editorAPI, appData, options);
  if (editorAPI.isTpa(editorAPI.components.getType(compRef))) {
    closeTpaSettingsPanel(editorAPI, compRef);
  } else {
    closeNativeSettingsPanel(editorAPI, compRef);
  }
}

function openComponentPanelFromType(
  editorAPI: EditorApi,
  componentType: string,
  panelName: FeatureWithNamed<'component'>['named']['panelName'],
) {
  editorAPI.store.dispatch(
    stateManagement.panels.actions.openComponentPanelFromType(
      componentType,
      constants.componentPanels[panelName],
    ),
  );
}

function _OpenSiteMembersSettingsPanel(editorAPI, appData, panelProps) {
  editorAPI.store.dispatch(
    updateOrOpenPanel(
      'panels.focusPanels.siteMembersSettingsPanel',
      panelProps,
      true,
    ),
  );
}

function showNotification(
  editorAPI,
  appData,
  token,
  { title, message, type, link },
) {
  const notificationInstancesOnScreen = _.findKey(
    editorAPI.store.getState().notifications,
    ['message', message],
  );
  if (notificationInstancesOnScreen) {
    return;
  }
  return new Promise((resolve) => {
    const notification = {
      name: 'notifications.editorNotification',
      onClose: () => resolve(),
      title,
      message,
      type,
      id: _.uniqueId('notification-'),
      shouldTranslate: false,
    };
    if (link?.caption) {
      notification.linkAction = {
        caption: link.caption,
        onClick: () => resolve(true),
      };
    }

    editorAPI.store.dispatch(
      stateManagement.notifications.actions.showTPAUserActionNotification(
        notification,
      ),
    );
  });
}

function showUserActionNotification(
  editorAPI: EditorAPI,
  appData: PlatformAppData,
  token: string,
  {
    title,
    message,
    type,
    link,
    shouldTranslate,
  }: ShowUserActionNotificationOptions,
  linkOnClickHandler?: () => void,
): Promise<boolean> | undefined {
  const notificationInstancesOnScreen: string = _.findKey(
    editorAPI.store.getState().notifications,
    ['message', message],
  );
  if (notificationInstancesOnScreen) {
    return;
  }
  return new Promise((resolve) => {
    const notification = {
      name: 'notifications.editorNotification',
      onClose: () => resolve(false),
      title,
      message,
      type,
      id: _.uniqueId('notification-'),
      shouldTranslate: shouldTranslate || false,
    };
    if (link?.caption) {
      linkOnClickHandler = linkOnClickHandler || link.onClick;
      notification.linkAction = {
        caption: link.caption,
        onClick: () => {
          if (_.isFunction(linkOnClickHandler)) {
            linkOnClickHandler();
          }
          resolve(true);
        },
      };
    }

    editorAPI.store.dispatch(
      stateManagement.notifications.actions.showTPAUserActionNotification(
        notification,
      ),
    );
  });
}

function showPresetUserActionNotification(
  editorAPI,
  appData,
  token,
  { preset },
) {
  switch (preset) {
    case constants.NOTIFICATIONS.PRESETS.REPEATER_MAX_ITEMS_EDITOR_LIMITATION:
      const link = {
        caption: 'PLATFORM_Live_Preview_Notification_Limit_To_50_cta',
        onClick: () => editorAPI.preview.togglePreview(),
      };
      return showUserActionNotification(editorAPI, appData, token, {
        title: '',
        message: 'PLATFORM_Live_Preview_Notification_Limit_To_50_Text',
        type: constants.NOTIFICATIONS.TYPES.INFO,
        link,
        shouldTranslate: true,
      });
    default:
      break;
  }
}

function openBillingPage(
  editorAPI,
  appData,
  token,
  { premiumIntent, referrer } = {},
) {
  const isHandlerFlow = true;
  return import('@/tpa').then((tpa) => {
    tpa.packagePicker.startUpgradeFlow(
      editorAPI,
      appData.appDefinitionId,
      isHandlerFlow,
      {
        premiumIntent,
        origin: referrer,
        shouldNavigate: false,
      },
    );
  });
}

const updateAppState = (editorAPI, appData, partialState) => {
  editorAPI.store.dispatch(
    stateManagement.appsStore.actions.updateAppsStore(
      appData.appDefinitionId,
      partialState,
    ),
  );
};

function openDashboardPanel(editorAPI, appData, _token, options) {
  return editorAPI.platform.panels.openDashboardPanel(appData, options);
}

function openProgressBar(editorAPI, appData, _token, options) {
  return new Promise((resolve, reject) => {
    try {
      editorAPI.panelHelpers.openProgressBar(
        {
          title: options.title,
          totalSteps: options.totalSteps,
          currentStep: options.currentStep,
          illustration: options.image,
          taskDisplayName: options.stepTitle,
        },
        false,
        appData?.appDefinitionId,
      );
    } catch (e) {
      reject(e);
    }
    resolve();
  });
}

function updateProgressBar(editorAPI, appData, _token, options) {
  return new Promise((resolve, reject) => {
    try {
      editorAPI.panelHelpers.updateProgressBar(
        options.currentStep,
        options.stepTitle,
        undefined,
        appData?.appDefinitionId,
      );
    } catch (e) {
      reject(e);
    }
    resolve();
  });
}

function closeProgressBar(editorAPI: EditorAPI, appData, _token, options = {}) {
  return editorAPI.panelHelpers.closeProgressBar(
    options.isError,
    appData?.appDefinitionId,
  );
}

function openErrorPanel(
  editorAPI: EditorAPI,
  appData: AppData,
  _token: string,
  options: OpenErrorPanelOptions,
) {
  return editorAPI.platform.panels.openErrorPanel(options);
}

function openConfirmationPanel(
  editorAPI: EditorAPI,
  appData: AppData,
  _token: string,
  options: OpenConfirmationPanelOptions,
) {
  return editorAPI.platform.panels.openConfirmationPanel(options);
}

function openPromotionalPanel(
  editorAPI: EditorAPI,
  appData: AppData,
  _token: string,
  options: OpenPromotionalPanelOptions,
) {
  return editorAPI.platform.panels.openPromotionalPanel(options);
}

function openManageContentPanel(editorAPI, appData, token, options) {
  return new Promise(function (resolve) {
    const panelProps = {
      title: options.title,
      descriptionText: options.descriptionText,
      mainActionText: options.mainActionText,
      secondaryActionText: options.secondaryActionText,
      helpId: options.helpId,
      onPanelClose: resolve,
      origin: options.origin,
    };

    editorAPI.store.dispatch(
      openPlatformPanel({
        panelName: 'platformPanels.platformManageContentPanel',
        panelProps,
        panelMetaData: {
          frameType: constants.PANEL_TYPES.COMP,
          closeAllOtherPanels: true,
        },
      }),
    );
  });
}

function openAddElementsPanel(editorAPI, appData, token, options) {
  const panelProps = {
    widgetRef: options.widgetRef,
    components: options.components,
    shouldHaveReset: options.shouldHaveReset,
    applicationId: appData.applicationId,
    type: constants.ROOT_COMPS.GFPP.ACTIONS.ADD,
  };

  editorAPI.selection.selectComponentByCompRef(
    options.compToSelect || options.widgetRef,
  );
  editorAPI.panelManager.openComponentPanel(
    'compPanels.panels.Widget.platformAddElementsPanel',
    panelProps,
  );
}

function openElementsPanel(
  editorAPI: EditorAPI,
  appData: PlatformAppData,
  token: string,
  options: AddElementsOptions,
  addComponentHandler: AddComponentHandler,
  removeComponentHandler: RemoveComponentHandler,
) {
  const panelProps = {
    helpId: options.helpId,
    widgetRef: options.widgetRef,
    elementsData: options.elementsData,
    categoriesData: options.categoriesData,
    sectionsData: options.sectionsData,
    applicationId: appData.applicationId,
    addComponentHandler,
    removeComponentHandler,
    withDivider: options.withDivider,
    subtitle: options.subtitle,
  };
  editorAPI.selection.selectComponentByCompRef(options.widgetRef);
  editorAPI.panelManager.openComponentPanel(
    'compPanels.panels.Widget.elementsPanel',
    panelProps,
  );
}

const sections = {
  addSectionToPage: sectionsService.addSectionForWidgetToEndOfPage,
};

export {
  openPanel,
  _OpenSiteMembersSettingsPanel,
  openChangeVariationsPanel,
  openComponentPanel,
  openComponentPanelWithSiteContext,
  openSettingsPanel,
  closeSettingsPanel,
  createOpenTpaComponentPanelHandler,
  openComponentPanelFromType,
  getCompRefByWidgetId,
  hidePanelPreloader,
  showPanelPreloader,
  openNativeComponentPanel,
  openModalPanel,
  openToolPanel,
  openPagePanel,
  openPagesPanel,
  closePagesPanel,
  canAddStaticPage,
  openFullStagePanel,
  openManageContentPanel,
  openLinkPanel,
  openSidePanel,
  openColorPicker,
  openFontPicker,
  openMediaPanel,
  openHelpPanel,
  updatePanelOptions,
  /**
   * @param {object} editorAPI
   * @param {string} token
   * @param {object} panel
   * @param {string} panel.name
   */
  closePanel,
  setAppAPI,
  editorUtils as utils,
  showNotification,
  showUserActionNotification,
  showPresetUserActionNotification,
  openBillingPage,
  // eslint-disable-next-line max-lines
  updateAppState,
  openDashboardPanel,
  openProgressBar,
  updateProgressBar,
  closeProgressBar,
  openErrorPanel,
  openConfirmationPanel,
  openPromotionalPanel,
  openAddElementsPanel,
  openElementsPanel,
  sections,
};
