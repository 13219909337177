import { DATA_BINDING } from '@wix/app-definition-ids';
import addPanelDataConsts from '@/addPanelDataConsts';
import constants from '@/constants';
import type { EditorAPI } from '@/editorAPI';
import type { EditorState } from '@/stateManagement';
import type {
  Dispatch,
  MapStateToProps,
  MapDispatchToProps,
  ThunkAction,
} from 'types/redux';
import type { BiEventDefinition, BiEventFields } from 'types/bi';
import { selectCollections, selectUserCollections } from '../../collections';
import { getHasDynamicPages } from '../../dynamicPages';
import { notifyDataBindingApplication } from '../../notifyApplication';
import {
  EVENTS,
  contentManagerButtonOrigin,
  contentManagerMainTab,
} from '../../consts';
import { bi } from '@/stateManagement';
import { videoClicked, settingsClicked } from '../../bi';
import { getYoutubeUrl } from '../utils/youtubeVideoUrl';
import experiment from 'experiment';

const { ADD_PANEL_NAME } = constants.ROOT_COMPS.LEFTBAR;
const { DATABASE } = addPanelDataConsts.CATEGORIES_ID;

const getEditorAPI: ThunkAction<EditorAPI> = (
  dispatch: Dispatch,
  getState: () => EditorState,
  { editorAPI }: { editorAPI: EditorAPI },
) => editorAPI;

export interface WelcomePageOwnProps {
  selectTabByName: (tabName: string) => void;
  isLoading: boolean;
}

export interface WelcomePageStateProps {
  hasDynamicPages: boolean;
  hasCollections: boolean;
  hasUserCollections: boolean;
  canEditSiteStructure: boolean;
  canSaveSchemas: boolean;
  canQueryData: boolean;
  canAddComponents: boolean;
  canOpenConnectionsList: boolean;
}

export interface WelcomePageDispatchProps {
  navigateToDynamicPagesPanel: () => void;
  navigateToManageContent: () => void;
  navigateToDataPanel: () => void;
  navigateToLearnMore: () => void;
  openAddPresetPanel: () => void;
  openCreateCollectionPanel: () => void;
  sendBi: (event: BiEventDefinition, params: BiEventFields) => void;
  openVideoPanel: (videoId: string, isEmptyState: boolean) => void;
  openHelpCenter: (artivleId: string) => void;
  openContentManagerAdvancedSettings: (
    actionName: string,
    isEmptyState: boolean,
  ) => void;
  openPageConnections: () => void;
}

export const mapDispatchToProps: MapDispatchToProps<
  WelcomePageDispatchProps,
  WelcomePageOwnProps
> = (dispatch, ownProps) => {
  const editorAPI = dispatch(getEditorAPI) as EditorAPI;

  const sendBi = (event: BiEventDefinition, params: BiEventFields) =>
    dispatch(bi.actions.event(event, params));

  const closeDataPanel = () => {
    return editorAPI.panelManager.closePanelByName(
      constants.SUPER_APPS.WIX_DATA_PANEL_NAME,
    );
  };

  return {
    navigateToDynamicPagesPanel: () => {
      const hasDynamicPagesWithManagingAppDefId = getHasDynamicPages(
        editorAPI,
        true,
      );

      editorAPI.panelHelpers.openPagesPanel({
        origin: contentManagerButtonOrigin,
        selectedTabId: hasDynamicPagesWithManagingAppDefId
          ? DATA_BINDING
          : 'DYNAMIC_PAGES_INTRO',
      });
    },
    navigateToManageContent: () => ownProps.selectTabByName('ManageContent'),
    navigateToDataPanel: () =>
      editorAPI.panelManager.openPanel(ADD_PANEL_NAME, {
        category: DATABASE,
        origin: contentManagerButtonOrigin,
      }),
    openAddPresetPanel: () =>
      notifyDataBindingApplication(editorAPI, {
        eventType: EVENTS.openAddPreset,
        eventPayload: { origin: contentManagerButtonOrigin },
      }),
    openCreateCollectionPanel: () =>
      notifyDataBindingApplication(editorAPI, {
        eventType: EVENTS.createCollectionButtonClicked,
        eventPayload: { origin: contentManagerMainTab },
      }),
    navigateToLearnMore: () => ownProps.selectTabByName('LearnMore'),
    openVideoPanel: (videoId, isEmptyState) => {
      editorAPI.panelManager.openPanel(
        constants.PANELS.PLAYER_PANEL,
        {
          videoId,
          origin: contentManagerButtonOrigin,
          videoName: 'manage_collections',
        },
        true,
      );

      sendBi(videoClicked, {
        tabName: 'main',
        videoUrl: getYoutubeUrl(videoId),
        isEmptyState,
      });
    },
    openContentManagerAdvancedSettings: (
      actionName: string,
      isEmptyState: boolean,
    ) => {
      sendBi(settingsClicked, {
        actionName,
        isEmptyState,
      });
      notifyDataBindingApplication(editorAPI, {
        eventType: EVENTS.openContentManagerAdvancedSettings,
        eventPayload: { origin: contentManagerButtonOrigin },
      });
    },
    openHelpCenter: (articleId) => {
      editorAPI.panelManager.openHelpCenter(articleId);
    },
    openPageConnections: () => {
      const pageRef = editorAPI.pages.getCurrentPage();
      editorAPI.selection.selectComponentByCompRef(pageRef);
      closeDataPanel();
      notifyDataBindingApplication(editorAPI, {
        eventType: EVENTS.openConnectionsListClicked,
        eventPayload: {
          origin: '6th_button',
          componentRef: pageRef,
        },
      });
    },
    sendBi,
  };
};

export const mapStateToProps: MapStateToProps<
  WelcomePageStateProps,
  WelcomePageOwnProps
> = ({ editorAPI, state }) => ({
  hasDynamicPages: getHasDynamicPages(editorAPI),
  hasCollections: selectCollections(state).length > 0,
  hasUserCollections: selectUserCollections(state).length > 0,
  canAddComponents:
    editorAPI.platform.permissions.isCustomPermissionsGranted([
      'DOCUMENT.ADD_COMPONENTS',
    ]) ||
    editorAPI.platform.permissions.isCustomPermissionsGranted([
      'DOCUMENT.EDIT_STRUCTURE',
    ]),
  canSaveSchemas: editorAPI.platform.permissions.isCustomPermissionsGranted([
    'WIX_DATA.SAVE_SCHEMA',
  ]),
  canEditSiteStructure:
    editorAPI.platform.permissions.isCustomPermissionsGranted([
      'DOCUMENT.EDIT_STRUCTURE',
    ]),
  canQueryData: editorAPI.platform.permissions.isCustomPermissionsGranted([
    'WIX_DATA.FIND',
  ]),
  canOpenConnectionsList: experiment.isOpen(
    'specs.wixDataClient.ClassicInspectorPanel',
  ),
});
