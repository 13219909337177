// @ts-nocheck
import _ from 'lodash';
import constants from '../constants/constants';
import * as mediaDialogHandlerUtils from '../utils/mediaDialogHandlerUtils';
import * as mediaDialogService from '../services/mediaDialogService';
import * as superApps from '../superApps/superApps';

const getAppDefIdOfSelectedComponent = function (editorAPI) {
  const origCompData = editorAPI.selection.getSelectedComponentData();
  if (!origCompData) {
    return null;
  }
  const appData =
    editorAPI.dsRead.tpa.app.getData(origCompData.applicationId) || {};
  return appData.appDefinitionId || null;
};

const superAppsOpenMediaDialog = function (editorAPI, msg, callback) {
  const parseMediaDialogPayload = function (mediaDialogPayload) {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/is-array
    if (!_.isArray(mediaDialogPayload)) {
      mediaDialogPayload = [mediaDialogPayload];
    }

    let mediaItems = mediaDialogPayload.map(
      mediaDialogHandlerUtils.convertMediaManagerResultForSuperApps,
    );
    mediaItems = msg.data.multiSelection ? mediaItems : mediaItems[0];
    return mediaItems;
  };
  mediaDialogService.openMediaDialogByPayloadParsingMethod(
    editorAPI,
    msg,
    parseMediaDialogPayload,
    callback,
  );
};

const setHelpArticle = function (editorAPI, msg) {
  const articleId = msg?.data?.articleId;
  const type = msg?.data?.type;
  if (type === 'MODAL') {
    editorAPI.panelManager.updatePanelProps('tpa.compModals.tpaSettingsModal', {
      articleId,
    });
  } else {
    editorAPI.panelManager.updatePanelProps('tpa.compPanels.tpaSettings', {
      articleId,
    });
  }
};

const openDashboard = function (editorAPI, msg, callback) {
  const msgData = msg?.data ?? {};
  const hashToSet = msgData.state || '';
  const origin = msgData.origin || '';
  const currentAppDefId = getAppDefIdOfSelectedComponent(editorAPI);
  const selectedCompData = editorAPI.selection.getSelectedComponentData();
  const { widgetId } = selectedCompData;

  superApps.openDashboardUrl(
    currentAppDefId,
    {
      state: hashToSet,
      origin,
      widgetId,
      onClose: callback,
      shouldNotCloseOnBlur: true,
      leavePanelsOpen: true,
    },
    { type: constants.BI.type.TPA_DASHBOARD },
  );
};

const getSelectedComponentApplicationId = function (editorAPI, msg, callback) {
  const origCompData = editorAPI.selection.getSelectedComponentData();
  callback(origCompData?.applicationId ?? null);
};

export {
  superAppsOpenMediaDialog,
  setHelpArticle,
  openDashboard,
  getSelectedComponentApplicationId,
};
