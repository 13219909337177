import { BaseEntryScope, createEntryPoint } from '@/apilib';
import { EditorAPIKey, EditorCoreApiKey } from '@/apis';
import { handleEditorDataFixing } from './editorDataFixersUtil';

export class EditorDataFixersScope extends BaseEntryScope {
  editorAPI = this.useDependency(EditorAPIKey);
  editorCoreApi = this.useDependency(EditorCoreApiKey);
}

export const EditorDataFixersEntrypoint = createEntryPoint({
  name: 'EditorDataFixers',
  Scope: EditorDataFixersScope,
  publicApi() {},
  async initialize({ editorAPI, editorCoreApi }: EditorDataFixersScope) {
    await editorCoreApi.hooks.initReady.promise;
    handleEditorDataFixing(editorAPI);
  },
});
