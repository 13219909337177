import experiment from 'experiment';
import * as stateManagement from '@/stateManagement';
import constants from '@/constants';
import { isMeshLayoutEnabled } from '@/layout';
import { layoutTransitionsUtil } from '@/layoutUtils';
import * as pushingComponentSnapper from './pushingComponentSnapper';
import * as util from '@/util';
import { EditorAPIKey } from '@/apis';
import { Hooks } from '@/apilib';
import {
  shouldShowFreezeWarning,
  showFreezeWarning,
} from './baseResizeAndPush/showFreezeWarning';
import type { CompRef, Point, CompLayout } from 'types/documentServices';
import type { EditorAPI } from '@/editorAPI';
import type { Shell } from '@/apilib';
import type React from 'react';

const { translateToViewerCoordinates } =
  stateManagement.domMeasurements.selectors;

export const createBaseResizeAndPushApi = (shell: Shell) => {
  let selectedComponent: CompRef[];
  const editorAPI: EditorAPI = shell.getAPI(EditorAPIKey);
  let resizeTransition: InstanceType<typeof layoutTransitionsUtil.Resize>;
  let meshResizeToAndPushTransition: ReturnType<
    typeof editorAPI.components.layout.__mesh.resizeToAndPushTransition
  >;

  const hooks = {
    resizeAndPushStart: Hooks.createHook<{ compRef: CompRef }>(),
    resizeAndPushOn:
      Hooks.createHook<{ compRef: CompRef; event: React.MouseEvent }>(),
    resizeAndPushEnd:
      Hooks.createHook<{ compRef: CompRef; event: React.MouseEvent }>(),
  };

  function getMousePosition(event: React.MouseEvent) {
    const mouseCoordinates = translateToViewerCoordinates(editorAPI, event);
    return { x: mouseCoordinates.pageX, y: mouseCoordinates.pageY };
  }

  function startResize(
    _editorAPI: EditorAPI,
    params: {
      evt: React.MouseEvent;
      comps: CompRef[];
      initalMouseOverride?: Point;
    },
  ) {
    editorAPI.components.layout.setLayoutReadFromDOM(true);
    if (experiment.isOpen('se_disableMeasureMapsInZoom')) {
      editorAPI.documentMode.enableShouldUpdateJsonFromMeasureMap(true);
    }
    selectedComponent = params.comps;
    pushingComponentSnapper.init(editorAPI, selectedComponent, {
      bottom: true,
    });
    editorAPI.selection.setIsMouseUpSelectionEnabled(false);

    const isCompFocusMode = editorAPI.componentFocusMode.isEnabled();
    if (!isCompFocusMode) {
      const selectedCompIds = selectedComponent.map((comp) => comp.id);
      editorAPI.dsActions.renderPlugins.setCompsToShowOnTop(selectedCompIds);
    }

    const mouse = getMousePosition(params.evt);

    hooks.resizeAndPushStart.fire({
      compRef: selectedComponent[0],
    });

    const initAbsLayout =
      editorAPI.components.layout.getRelativeToScreen(selectedComponent);
    const siteScale = stateManagement.domMeasurements.selectors.getSiteScale(
      editorAPI.store.getState(),
    );
    const resizeRatio = util.sections.isSectionsEnabled()
      ? siteScale
      : undefined;

    resizeTransition = new layoutTransitionsUtil.Resize(
      initAbsLayout,
      params?.initalMouseOverride || mouse,
      { x: 0, y: 1 },
      false,
      resizeRatio,
    );

    if (isMeshLayoutEnabled()) {
      meshResizeToAndPushTransition =
        editorAPI.components.layout.__mesh.resizeToAndPushTransition(
          selectedComponent[0],
        );
      return;
    }

    editorAPI.dsActions.components.layout.updateAndPushStart(
      selectedComponent[0],
    );
  }

  function onResize(
    event: React.MouseEvent,
    layoutOverride?: Pick<CompLayout, 'width' | 'height'>,
  ) {
    const compRef = selectedComponent[0];
    const mouse = getMousePosition(event);
    const layout =
      (layoutOverride as CompLayout) || resizeTransition.getLayout(mouse);
    layout.bounding = editorAPI.utils.getBoundingLayout(layout);

    const alterWidth =
      editorAPI.components.layout.getRelativeToScreen(selectedComponent).width;

    pushingComponentSnapper.snapIfPossible(editorAPI, layout, alterWidth);

    (function resizeToAndPush() {
      const yOrHeight = {
        height: layout.height,
      };

      if (isMeshLayoutEnabled()) {
        meshResizeToAndPushTransition.update(yOrHeight);
        return;
      }

      editorAPI.components.layout.resizeAndPush(compRef, yOrHeight);
      editorAPI.notifyViewerUpdate(['components.layout.runtime.updateAndPush']);
    })();

    const minimalEditorAPI = {
      waitForChangesApplied: editorAPI.dsActions.waitForChangesApplied,
      waitForChangesAppliedAsync:
        editorAPI.dsActions.waitForChangesAppliedAsync,
      getRelativeToScreen: editorAPI.components.layout.getRelativeToScreen,
      snapToEnabled: editorAPI.getViewTools().snapToEnabled,
      snapDataSetter: editorAPI.snapData.set,
    };

    hooks.resizeAndPushOn.fire({ compRef, event });

    pushingComponentSnapper.waitForChangesAppliedAndDrawSnapLines(
      minimalEditorAPI,
      layout,
      selectedComponent,
    );
  }

  async function endResize(
    event: React.MouseEvent,
    params?: {
      dontAddToUndoRedoStack?: boolean;
    },
  ) {
    const compRef = selectedComponent[0];

    event?.persist?.();

    await (async () => {
      if (isMeshLayoutEnabled()) {
        meshResizeToAndPushTransition.end({
          dontAddToUndoRedoStack: true,
        });
        return;
      }

      editorAPI.dsActions.components.layout.updateAndPushEnd(compRef);
      editorAPI.components.layout.setLayoutReadFromDOM(false);
    })();

    await editorAPI.dsActions.waitForChangesAppliedAsync();

    if (shouldShowFreezeWarning({ editorAPI }, compRef)) {
      showFreezeWarning({ editorAPI }, compRef);
    }

    hooks.resizeAndPushEnd.fire({
      compRef: selectedComponent[0],
      event,
    });

    if (experiment.isOpen('se_disableMeasureMapsInZoom')) {
      editorAPI.documentMode.enableShouldUpdateJsonFromMeasureMap(
        !editorAPI.zoomMode.isStageZoomMode(),
      );
    }

    pushingComponentSnapper.clear(editorAPI);
    if (!editorAPI.componentFocusMode.isEnabled()) {
      editorAPI.dsActions.renderPlugins.setCompsToShowOnTop(null);
    }
    if (!params?.dontAddToUndoRedoStack)
      editorAPI.history.add('Resize component');
  }

  return {
    hooks,
    start: startResize,
    on: onResize,
    end: endResize,
    type: constants.MOUSE_ACTION_TYPES.RESIZE_PUSH,
  };
};
