import React from 'react';
import { WithRestrictionsRender } from '@/editorRestrictions';
import {
  ContextMenu,
  ContextMenuAction,
  ContextMenuContent,
  SectionDivider,
} from '@wix/wix-base-ui';
import { SymbolWrapper } from '@/baseUI';
import { translate } from '@/i18n';
import styles from './footerContextMenu.scss';

interface MenuAddItemAction {
  type?: string;
  title: React.ReactNode;
  symbolName: string;
  disabled?: boolean;
  onClick: () => void;
  automationId?: string;
}

interface FooterContextMenuProps {
  addActions: MenuAddItemAction[];
  customButton: React.ReactNode;
  onContextMenuItemsHover: (category: string, target: string) => void;
  category: string;
}

const FooterContextMenu: React.FC<FooterContextMenuProps> = ({
  addActions,
  customButton,
  onContextMenuItemsHover,
  category,
}) => {
  return (
    <div>
      <WithRestrictionsRender name={'menu-panel_add-item'}>
        {({ disabled }) => (
          <ContextMenu
            disabled={disabled}
            alignment="RIGHT"
            dataHook={'footer-context-menu'}
            customButton={customButton}
          >
            <ContextMenuContent>
              {addActions.map((action) => {
                if (action.type === 'divider') {
                  return (
                    <div className={styles.dividerWrapper}>
                      <SectionDivider>
                        <div className={styles.dividerContent}>
                          <span>
                            {translate(
                              'custom_menu_manage_menu_items_section_title',
                            )}
                          </span>
                        </div>
                      </SectionDivider>
                    </div>
                  );
                }

                return (
                  <ContextMenuAction
                    key={action.symbolName}
                    onClick={action.onClick}
                    disabled={action.disabled}
                    dataHook={action.automationId}
                    automationId={action.automationId}
                    className="sortable-list-item__context-menu-action"
                  >
                    <div
                      className={styles.contextMenuActionWrapper}
                      onMouseEnter={() =>
                        onContextMenuItemsHover(category, action.automationId)
                      }
                    >
                      <SymbolWrapper
                        className="symbol sortable-list-item__context-menu-action-icon"
                        name={action.symbolName}
                      />
                      <span className="sortable-list-item__context-menu-action-text">
                        {action.title}
                      </span>
                    </div>
                  </ContextMenuAction>
                );
              })}
            </ContextMenuContent>
          </ContextMenu>
        )}
      </WithRestrictionsRender>
    </div>
  );
};

export default FooterContextMenu;
