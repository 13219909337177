import type {
  ThemedPreviewProps,
  AddPanelProps as AddPanelComponentProps,
} from '@wix/add-panel-component';
import * as uiAnimations from '@/uiAnimations';
import { media, serviceTopology } from '@/util';
import { MobileElementsPanelContent } from '@/mobileEditor';
import {
  ThemeSection as ThemedPreview,
  PersonalizedSection as PersonalizedPreview,
} from '@/addPanelInfra';
import { getConditionHelpers, getRegistrySections } from './newAddPanelAPI';

export type NewAddPanelUtils = AddPanelComponentProps['utils'];

interface CreateUtilsArgs {
  theme: NewAddPanelUtils['theme'];
  translate: NewAddPanelUtils['translate'];
  getSuperAppData: NewAddPanelUtils['getSuperAppData'];
  hasSectionStyle: NewAddPanelUtils['hasSectionStyle'];
  shouldUseTextThemes: NewAddPanelUtils['shouldUseTextThemes'];
  getLiveChatSectionColors: NewAddPanelUtils['getLiveChatSectionColors'];
  getBlankSectionStructure: NewAddPanelUtils['getBlankSectionStructure'];
  shouldShowMyDesignsFooter: NewAddPanelUtils['shouldShowMyDesignsFooter'];
}

export const createUtils = ({
  theme,
  translate,
  getSuperAppData,
  hasSectionStyle,
  getLiveChatSectionColors,
  getBlankSectionStructure,
  shouldUseTextThemes,
  shouldShowMyDesignsFooter,
}: CreateUtilsArgs): NewAddPanelUtils => ({
  media,
  translate,
  serviceTopology: {
    editorAssetsBaseUrl: serviceTopology.editorAssetsBaseUrl,
    itemsDataBaseUrl:
      serviceTopology.scriptsLocationMap['add-panel-data-classic-editor'],
  },
  conditionHelpers: getConditionHelpers(),
  theme: theme as unknown as AddPanelComponentProps['utils']['theme'],
  uiAnimations,
  getSuperAppData,
  hasSectionStyle,
  ThemedPreview: ThemedPreview as React.ComponentType<ThemedPreviewProps>,
  PersonalizedPreview:
    PersonalizedPreview as React.ComponentType<ThemedPreviewProps>,
  getLiveChatSectionColors,
  MobileToolsPanel: MobileElementsPanelContent,
  getBlankSectionStructure,
  getRegistrySections,
  shouldUseTextThemes,
  shouldShowMyDesignsFooter,
});
