// @ts-nocheck
import * as util from '@/util';

function getLinkAsString(editorAPI, appData, token, options) {
  return util.link.getLinkValueAsString(editorAPI, options.link);
}

function getCsrfToken(): string {
  return util.cookie.getCookie('XSRF-TOKEN');
}

export { getLinkAsString, getCsrfToken };
