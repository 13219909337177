import _ from 'lodash';
import * as stateManagement from '@/stateManagement';
import * as coreBi from '@/coreBi';
import type { EditorAPI } from '@/editorAPI';
import type { CompRef } from 'types/documentServices';

const REF_COMPONENT_CHANGE_TYPE = {
  PROPS: 'props',
  DATA: 'data',
  STYLE: 'style',
  DESIGN: 'design',
  BEHAVIORS: 'behaviors',
};
const debouncedSendBi = _.debounce((editorAPI, compRef, changeType) => {
  editorAPI.store.dispatch(
    stateManagement.bi.actions.event(coreBi.events.refComponent.changes, {
      changeType,
      component_id: compRef.id,
      component_type: editorAPI.components.getType(compRef),
    }),
  );
}, 1000);

function sendBiIfRefComponent(
  editorAPI: EditorAPI,
  compRef: CompRef,
  changeType: string,
) {
  if (stateManagement.components.selectors.isReferredComponent(compRef)) {
    debouncedSendBi(editorAPI, compRef, changeType);
  }
}

function isContainedObject<TSource extends Record<string, any>>(
  sourceObj: TSource,
  partialObj: Partial<TSource>,
) {
  if (!partialObj) {
    return true;
  }
  return Object.keys(partialObj).every((key) =>
    _.isEqual(sourceObj[key], partialObj[key]),
  );
}

export { REF_COMPONENT_CHANGE_TYPE, sendBiIfRefComponent, isContainedObject };
