import _ from 'lodash';
import * as util from '@/util';
import { translate } from '@/i18n';
import constants from '@/constants';
import * as core from '@/core';
import * as stateManagement from '@/stateManagement';
import {
  type ColorName,
  type ColorPalette,
  utils as themeUtils,
} from '@/theme';
import { WIX_ECOM, WIX_EVENTS, WIX_NEW_STORES } from '@wix/app-definition-ids';
import {
  ADD_SECTION_PANEL_CONTENT_WIDTH,
  AI_SECTION_FEEDBACK_ALREADY_SHOWN,
  ALLOWED_SILENT_INSTALLATION_APPS,
  BLACK_LISTED_MOBILE_HINTS_PROPS,
  COLORS_NOT_TO_APPLY_ON_INJECT_THEME,
  MAX_SCALE_RATIO,
  MODAL_SIZE_RATIO,
  PresetConditions,
  PREVIEW_BASE_WIDTH,
  SCANNING_WIDTH,
  TRANSPARENT_BOX,
} from './addPresetConsts';
import { isAddPageProductionMode } from './addPage/addPagePanelUtil';
import { isAddSectionProductionMode } from './addSection/addSectionPanelUtil';
import { WorkspaceModesApiKey } from '@/apis';
import { WorkspaceModes } from '@/workspaceModes';

import type {
  PagePresetDefinition,
  PresetCategory,
  PreviewComponentsEntry,
  SectionAdditionalCategoryInfo,
  SectionCategoryDefinition,
  SectionPresetDefinition,
  ThemeMap,
} from './types';
import type { EditorAPI } from '@/editorAPI';
import type { EditorInteractionName } from 'types/fedops';
import type {
  CompRef,
  CompStructure,
  FontId,
  FontThemeObject,
} from 'types/documentServices';
import type { AppInstallOrigin } from '@wix/editor-platform-host-integration-apis';
import type { SavedComponentPreset } from '@/savedComponents';
import experiment from 'experiment';
import type { AddPresetScope } from './addPresetApiEntry';
import { silentInstallApps } from '@/platform';
import { SavedSectionCategory } from './addSection/consts';
import type { ProcessSavedSections } from './addSection/savedSectionUtils';
import { getAiSectionCreatorFeedbackModel } from './addSection/userFeedbackModels';

const { showUserActionNotification } = stateManagement.notifications.actions;

function isWidgetInstallationAllowed(appId: string) {
  return !ALLOWED_SILENT_INSTALLATION_APPS.has(appId);
}

function isSilentInstallationAllowed(appId: string) {
  return ALLOWED_SILENT_INSTALLATION_APPS.has(appId);
}

export interface StyleByIdMap {
  [styleId: string]: any;
}

export interface AppDefinitionToApplicationIdMap {
  [appDefinitionId: string]: number;
}

/**
 * returns numeric value for url dimension param
 * @param urlDimensionStr - either "w_123" or "h_123"
 * @returns numeric dimension value
 */
export function getDimensionFromUrlDimension(urlDimensionStr: string): number {
  return Math.floor(Number(urlDimensionStr.split('_')[1]) * MAX_SCALE_RATIO);
}

export function dimensionsReplacer(match: string): string {
  const [widthStr, heightStr] = match.split(',');
  const width = getDimensionFromUrlDimension(widthStr);
  const height = getDimensionFromUrlDimension(heightStr);
  return `fill/w_${width},h_${height}`;
}

function goOverChildrenAndFixSerializedApplicationIds(
  compStructure: any,
  appDefinitionToApplicationIdMap: AppDefinitionToApplicationIdMap,
): void {
  if (
    compStructure.data?.appDefinitionId &&
    compStructure.data?.applicationId
  ) {
    compStructure.data.applicationId = `${
      appDefinitionToApplicationIdMap[compStructure.data.appDefinitionId]
    }`;
    if (!compStructure.originCompId && compStructure.id) {
      compStructure.originCompId = compStructure.id;
    }
  }
  ['components', 'mobileComponents'].forEach((structureProperty) => {
    compStructure[structureProperty]?.forEach(
      (compStructure: CompStructure) => {
        goOverChildrenAndFixSerializedApplicationIds(
          compStructure,
          appDefinitionToApplicationIdMap,
        );
      },
    );
  });
}

export function fixSerializedApplicationIds(
  rootSerialize: CompStructure,
  appDefinitionToApplicationIdMap: AppDefinitionToApplicationIdMap,
): void {
  goOverChildrenAndFixSerializedApplicationIds(
    rootSerialize,
    appDefinitionToApplicationIdMap,
  );
}

export function getAppDefinitionToApplicationIdMap(
  editorAPI: EditorAPI,
  appDefIds: string[],
): AppDefinitionToApplicationIdMap {
  return appDefIds.reduce((acc, appDefId) => {
    const appData = editorAPI.dsRead.platform.getAppDataByAppDefId(appDefId);
    acc[appDefId] = appData.applicationId;
    return acc;
  }, {} as AppDefinitionToApplicationIdMap);
}

function goOverChildrenAndFixStyles(
  compStructure: CompStructure,
  styleByIdMap: StyleByIdMap,
): void {
  if (_.isString(compStructure.style) && styleByIdMap[compStructure.style]) {
    compStructure.style = styleByIdMap[compStructure.style];
  }
  ['components', 'mobileComponents'].forEach((structureProperty) => {
    compStructure[structureProperty as keyof CompStructure]?.forEach(
      (compStructure: CompStructure) => {
        goOverChildrenAndFixStyles(compStructure, styleByIdMap);
      },
    );
  });
}

export function fixRecursiveTopLevelStyles(
  editorAPI: EditorAPI,
  rootSerialize: CompStructure,
): void {
  const styleByIdMap = editorAPI.theme.styles.getAll();
  goOverChildrenAndFixStyles(rootSerialize, styleByIdMap);
}

export function applyTranslationsRecursively(
  compStructure: CompStructure,
  language: string,
): void {
  if (
    compStructure.translations &&
    compStructure.translations[language] &&
    compStructure.data
  ) {
    Object.assign(compStructure.data, compStructure.translations[language]);
  }
  delete compStructure.translations;
  ['components', 'mobileComponents'].forEach((structureProperty) => {
    compStructure[structureProperty as keyof CompStructure]?.forEach(
      (compStructure: CompStructure) => {
        applyTranslationsRecursively(compStructure, language);
      },
    );
  });
}

export function extendJsonWithOriginInstanceId(
  compStructure: CompStructure,
  appDefId: string,
  instanceId: string,
): void {
  if (
    compStructure.data?.appDefinitionId === appDefId ||
    compStructure.data?.applicationId === appDefId
  ) {
    // @ts-expect-error
    compStructure.originInstanceId = instanceId;
  }
  ['components', 'mobileComponents'].forEach((structureProperty) => {
    compStructure[structureProperty as keyof CompStructure]?.forEach(
      (childStructure: CompStructure) => {
        extendJsonWithOriginInstanceId(childStructure, appDefId, instanceId);
      },
    );
  });
}

function getHashUniqueStr(colorName: string): string {
  return `#hash${colorName}hash#`;
}

function getRgbUniqueStr(colorName: string): string {
  return `#rgb${colorName}rgb#`;
}

export function applyColorThemeInHtml(
  html: string,
  originColorThemeMap: ColorPalette,
  targetColorThemeMap: ColorPalette,
): string {
  Object.entries(originColorThemeMap).forEach(([colorName, colorValue]) => {
    if (COLORS_NOT_TO_APPLY_ON_INJECT_THEME.has(colorName)) return;
    const hashValueToApply = getHashUniqueStr(colorName);
    const colorRgb = themeUtils.hexToRgb(colorValue);
    const rgbValueToReplace = `${colorRgb.red}, ?${colorRgb.green}, ?${colorRgb.blue}`;
    const rgbValueToApply = getRgbUniqueStr(colorName);
    const rgbRegex = new RegExp(rgbValueToReplace, 'g');
    html = html.replace(rgbRegex, rgbValueToApply);

    const hashRegex = new RegExp(colorValue, 'g');
    html = html.replace(hashRegex, hashValueToApply);
  });

  Object.entries(originColorThemeMap).forEach(([colorName]) => {
    if (COLORS_NOT_TO_APPLY_ON_INJECT_THEME.has(colorName)) return;
    const currentSiteColorValue = targetColorThemeMap[colorName as ColorName];
    const currentSiteColorRGB = themeUtils.hexToRgb(currentSiteColorValue);
    const rgbValueToApply = `${currentSiteColorRGB.red}, ${currentSiteColorRGB.green}, ${currentSiteColorRGB.blue}`;
    const rgbRegex = new RegExp(getRgbUniqueStr(colorName), 'g');
    html = html.replace(rgbRegex, rgbValueToApply);

    const hashRegex = new RegExp(getHashUniqueStr(colorName), 'g');
    html = html.replace(hashRegex, currentSiteColorValue);
  });
  return html;
}

export function applyFontThemeInHtml(
  html: string,
  originFontsThemeMap: FontThemeObject,
  targetFontsThemeMap: FontThemeObject,
): string {
  Object.entries(originFontsThemeMap).forEach(([fontName]) => {
    const fontToReplace = targetFontsThemeMap[fontName as FontId].replace(
      / {color_\d+}/,
      '',
    );

    const boltFontRegex = new RegExp(`.${fontName} \{.* ;`);
    const boltFontValueToReplace = `.${fontName} {font: ${fontToReplace} ;`;
    html = html.replace(boltFontRegex, boltFontValueToReplace);

    const tbFontRegex = new RegExp(`--${fontName}:(.*?);`);
    const tbFontValueToReplace = `--${fontName}:${fontToReplace};`;
    html = html.replace(tbFontRegex, tbFontValueToReplace);
  });
  return html;
}

export const getPreviewComponentsEntryFromStructure = (
  compDefsAndStructures: any,
  previewerReactComponents: React.ReactElement[],
): PreviewComponentsEntry =>
  previewerReactComponents.reduce(
    (
      acc: PreviewComponentsEntry,
      previewComponent: React.ReactElement,
      index: number,
    ) => ({
      ...acc,
      [compDefsAndStructures[index]._id]: {
        reactElement: previewComponent,
        compStructure: compDefsAndStructures[index].injectedStructure,
      },
    }),
    {},
  );

export const calcMinHeight = (
  previewHeight: number,
  containerWidth: number,
): number => {
  const heightFromWindow = window.innerHeight * MODAL_SIZE_RATIO;
  const heightFromFrame = calcRelativeHeight(previewHeight, containerWidth);
  return Math.round(Math.min(heightFromWindow, heightFromFrame));
};

export const calcPagePreviewMinHeight = (
  previewHeight: number,
  containerWidth: number,
): number => {
  const heightFromWindow = window.innerHeight * MODAL_SIZE_RATIO;
  const heightFromFrame = calcRelativeHeight(previewHeight, containerWidth);
  return Math.round(Math.min(heightFromWindow, heightFromFrame));
};

export const calcRelativeHeight = (
  previewHeight: number,
  displayWidth: number,
  previewWidth = PREVIEW_BASE_WIDTH,
): number => {
  return (previewHeight * displayWidth) / previewWidth;
};

const getPreviewWidthRatio = (): number => {
  return SCANNING_WIDTH / document.body.getBoundingClientRect().width;
};

const getThumbnailHeight = (
  previewHeight: number,
  originalPresetWidth: number,
) => {
  return calcRelativeHeight(
    previewHeight,
    ADD_SECTION_PANEL_CONTENT_WIDTH,
    originalPresetWidth,
  );
};

export const getPreviewerPreviewMaxHeight = (
  sectionDefinition: SectionPresetDefinition,
) => {
  return (
    getThumbnailHeight(
      sectionDefinition.previewHeight,
      sectionDefinition.originalPresetWidth,
    ) * getPreviewWidthRatio()
  );
};

export const removeScriptsFromHtml = (previewHtml: string): string => {
  return previewHtml
    .replace(/<link.*as="script".*>/g, '')
    .replace(
      /(<!--\[if lt(e)? IE 9\]>)?<script[\s\S]*?>[\s\S]*?<\/script>(?:<!\[endif\]-->)?/g,
      () => '',
    );
};

export function getSiteThemeMap(editorAPI: EditorAPI): ThemeMap {
  return {
    colors: editorAPI.theme.colors.getAll(),
    // eslint-disable-next-line @wix/santa-editor/deprecatedFontsApi
    fonts: editorAPI.theme.fonts.getAll(),
  };
}

export function getGroupedCategories<T extends PresetCategory>(
  categories: T[],
): T[][] {
  return categories.reduce(
    (acc, category) => {
      if (category.isDivider) {
        acc.push([category], []);
      } else {
        acc[acc.length - 1].push(category);
      }
      return acc;
    },
    [[]],
  );
}

export function enrichHtmlWithThemeFontUrls(
  html: string,
  fontsUrls: string[],
): string {
  let fontLinkStrings = '';
  fontsUrls.forEach((fontUrl: string) => {
    fontLinkStrings += `<link type="text/css" href="${fontUrl}" rel="stylesheet" />`;
  });
  return html.replace(/<\/head>/, `${fontLinkStrings}</head>`);
}

export function getFontsUrls(editorAPI: EditorAPI): string[] {
  return Object.entries(editorAPI.fonts.css.getUrls()).map(
    ([, fontUrl]) => fontUrl,
  );
}

export function isAddPresetProductionMode() {
  return isAddPageProductionMode || isAddSectionProductionMode;
}

export async function extractStructurePromise(
  structureFetchPromise: Promise<Response>,
): Promise<CompStructure> {
  return new Promise((resolve) => {
    structureFetchPromise
      .then((res) => res.blob())
      .then((blob) => {
        const fr = new FileReader();
        fr.onload = (event) => {
          if (
            event.target.readyState === 2 &&
            typeof event.target.result === 'string'
          ) {
            resolve(JSON.parse(event.target.result));
          }
        };
        fr.readAsText(blob);
      });
  });
}

export async function installAppsByOrder(
  editorAPI: EditorAPI,
  appIds: string[],
  platformOrigin: AppInstallOrigin,
  mapApplicationToFedopsEvent: { [appDefId: string]: EditorInteractionName },
): Promise<void> {
  await appIds
    .filter(isWidgetInstallationAllowed)
    .filter((appId: string) => !isAppOrTpaInstalled(editorAPI, appId))
    .reduce(async (prevPromise: Promise<unknown>, appDefId: string) => {
      await prevPromise;
      const applicationFedopsEvent =
        mapApplicationToFedopsEvent[
          appDefId as keyof typeof mapApplicationToFedopsEvent
        ];
      if (isAddPageProductionMode) {
        util.fedopsLogger.interactionStarted(applicationFedopsEvent);
      }
      await editorAPI.tpa.installAppIfNeeded(appDefId, {
        platformOrigin,
        resolveBeforeSave: true,
      });
      if (isAddPageProductionMode) {
        util.fedopsLogger.interactionEnded(applicationFedopsEvent);
      }
      return Promise.resolve();
    }, Promise.resolve());
}

function getPresetsConditionsMap(
  editorAPI: EditorAPI,
  presetCondition: PresetConditions,
) {
  const isSectionsEnabled = util.sections.isSectionsEnabled();
  const workspaceModesApi = editorAPI.host.getAPI(WorkspaceModesApiKey);
  switch (presetCondition) {
    case PresetConditions.SECTIONS_DISABLED:
      return !isSectionsEnabled;
    case PresetConditions.SECTIONS_ENABLED:
      return isSectionsEnabled;
    case PresetConditions.WORKSPACE_MODE_FULL:
      return workspaceModesApi.isMode(WorkspaceModes.FULL);
    default:
      return true;
  }
}

export function filterCategory(editorAPI: EditorAPI) {
  return (category: PresetCategory) => {
    let filterValue = true;
    const { experiments, experimentsOff, condition } = category;
    if (condition) {
      filterValue =
        filterValue && getPresetsConditionsMap(editorAPI, condition);
    }
    if (experiments || experimentsOff) {
      filterValue =
        filterValue &&
        (experiments || []).every(experiment.isOpen) &&
        (experimentsOff || []).every((exp) => !experiment.isOpen(exp));
    }

    return filterValue;
  };
}

export function getFilteredResults(
  editorAPI: EditorAPI,
  categories: PresetCategory[],
): PresetCategory[] {
  return categories.filter(filterCategory(editorAPI));
}

export function getFilteredPages(
  pages: PagePresetDefinition[],
): PagePresetDefinition[] {
  return pages.filter((page: PagePresetDefinition) => {
    const { experiments } = page;
    if (experiments) {
      return experiments.every(experiment.isOpen);
    }
    return true;
  });
}

export function translatePageSectionsNamesIfNeeded(pageStructure: any): void {
  ['components', 'mobileComponents'].forEach((structureProperty) => {
    pageStructure[structureProperty]?.forEach(
      (compStructure: CompStructure) => {
        if (compStructure.componentType === constants.COMP_TYPES.SECTION) {
          translateSectionNameIfNeeded(compStructure);
        }
      },
    );
  });
}

export function translateSectionNameIfNeeded(
  sectionStructure: CompStructure,
): void {
  if (sectionStructure.anchor?.name) {
    const translatedName = translate(sectionStructure.anchor.name);
    if (translatedName && !translatedName.startsWith('!')) {
      sectionStructure.anchor.name = translatedName;
    }
  }
}
export function fixStructureMobileHints(compStructure: any): void {
  if (compStructure.mobileHints) {
    /**
     * We are setting author studio to mobile structure directly to be consistent with mobile hints
     * */
    if (compStructure.mobileStructure?.metaData) {
      compStructure.mobileStructure.metaData.author = 'studio';
    }

    BLACK_LISTED_MOBILE_HINTS_PROPS.forEach((prop) => {
      delete compStructure.mobileHints[prop];
    });
    compStructure.mobileHints.author = 'studio';
  }
  compStructure.components?.forEach((childStructure: CompStructure) =>
    fixStructureMobileHints(childStructure),
  );
}

export const getCategoryIndex = (
  categories: PresetCategory[],
  categoryId: string,
): number => {
  return categories.findIndex(({ id }) => categoryId === id);
};

function reportAppIdFedopsEvent(
  appIds: string[],
  mapApplicationToFedopsEvent: { [appId: string]: EditorInteractionName },
  isStarted: boolean,
) {
  if (isStarted) {
    appIds.map((appId: string) =>
      util.fedopsLogger.interactionStarted(
        mapApplicationToFedopsEvent[
          appId as keyof typeof mapApplicationToFedopsEvent
        ],
      ),
    );
  } else {
    appIds.map((appId: string) =>
      util.fedopsLogger.interactionEnded(
        mapApplicationToFedopsEvent[
          appId as keyof typeof mapApplicationToFedopsEvent
        ],
      ),
    );
  }
}

export async function preInstallAddedSectionAppsIfNeeded(
  scope: AddPresetScope,
  appIds: string[],
  platformOrigin: AppInstallOrigin,
  mapApplicationToFedopsEvent: { [appDefId: string]: EditorInteractionName },
) {
  const { editorAPI } = scope;
  const appsNotInstalled = appIds
    .map(modifyStoresAppDefIdIfNeeded)
    .filter(isSilentInstallationAllowed)
    .filter((appId: string) => {
      return !isAppOrTpaInstalled(editorAPI, appId);
    });

  if (appsNotInstalled.length) {
    if (isAddPageProductionMode) {
      reportAppIdFedopsEvent(
        appsNotInstalled,
        mapApplicationToFedopsEvent,
        true,
      );
    }
    await silentInstallApps(editorAPI, appsNotInstalled, {
      installOrigin: platformOrigin,
    });
    if (isAddPageProductionMode) {
      reportAppIdFedopsEvent(
        appsNotInstalled,
        mapApplicationToFedopsEvent,
        false,
      );
    }
  }
}

export const shouldShowVerticalAppInstallNotification = (appIds: string[]) => {
  return appIds.some((appId) => {
    return isSilentInstallationAllowed(appId) || appId === WIX_EVENTS;
  });
};

export const showVerticalSectionInstallNotification = (
  editorAPI: EditorAPI,
  notificationText: string,
) => {
  editorAPI.store.dispatch(
    showUserActionNotification({
      message: notificationText,
      type: 'info',
    }),
  );
};

export const isAppOrTpaInstalled = (
  editorAPI: EditorAPI,
  appDefId: string,
): boolean => {
  return (
    editorAPI.platform.isAppActive(appDefId) ||
    editorAPI.tpa.app.isInstalled(appDefId)
  );
};

const modifyStoresAppDefIdIfNeeded = (appDefId: string): string => {
  if (appDefId === WIX_ECOM) {
    return WIX_NEW_STORES;
  }
  return appDefId;
};

export const getSectionOrWidgetRef = (
  sectionRef: CompRef,
  editorAPI: EditorAPI,
) => {
  const widgetInSection = editorAPI.components.getTpaChildren(sectionRef);
  if (widgetInSection.length) {
    return widgetInSection[0];
  }
  return sectionRef;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const extractAppIdsFromStructure = (structure: CompStructure): string[] => {
  const appId = structure?.data?.appDefinitionId;

  if (!structure?.components?.length) return appId ? [appId] : [];

  return [
    appId,
    ...(structure.components as CompStructure[]).flatMap(
      extractAppIdsFromStructure,
    ),
  ].filter(Boolean);
};

export const extractAppIds = (
  structure: CompStructure,
  sectionDefinition: SectionPresetDefinition,
  categoryDefinition: SectionCategoryDefinition,
) => {
  if (categoryDefinition.id === SavedSectionCategory.id) {
    return [];
    // TODO: fix saved saved sections apps installation
    // return [...new Set(extractAppIdsFromStructure(structure))];
  }

  return sectionDefinition.appIds;
};

export const extractStructure = (
  structure: Promise<Response>,
  categoryDefinition: SectionCategoryDefinition,
  processSavedSections: ProcessSavedSections,
) => {
  if (categoryDefinition.id === SavedSectionCategory.id) {
    return structure
      .then((res: Response) => res.json())
      .then((preset: SavedComponentPreset) => {
        return processSavedSections.beforeAdd(preset);
      });
  }

  return extractStructurePromise(structure);
};

function getAppMarketData(editorAPI: EditorAPI, appId: string) {
  return editorAPI.dsRead.tpa.appMarket.getData(appId);
}

function getCategoryWithAppInfo(
  editorAPI: EditorAPI,
  category: SectionCategoryDefinition,
): SectionCategoryDefinition {
  const appData = getAppMarketData(
    editorAPI,
    category.additionalCategoryInfo.appId,
  );

  const isAppInstallRequired = !isAppOrTpaInstalled(
    editorAPI,
    category.additionalCategoryInfo.appId,
  );

  const additionalCategoryInfo: SectionAdditionalCategoryInfo = {
    ...category.additionalCategoryInfo,
    // TODO: fix after updating AppData type
    // @ts-expect-error
    appIcon: appData?.appIcon,
    appName: appData?.name,
  };

  return {
    ...category,
    additionalCategoryInfo,
    isAppInstallRequired,
  };
}

export function getCategoryWithAddedProps<T extends PresetCategory>(
  category: T,
  i: number,
): T {
  return {
    ...category,
    label: category.name,
    value: String(i),
  };
}

export function getEnrichedCategory(
  editorAPI: EditorAPI,
  category: SectionCategoryDefinition,
  i: number,
): SectionCategoryDefinition {
  const categoryWithAddedProps = getCategoryWithAddedProps(category, i);

  if (categoryWithAddedProps.additionalCategoryInfo?.appId) {
    return getCategoryWithAppInfo(editorAPI, categoryWithAddedProps);
  }
  return categoryWithAddedProps;
}

export function removeTransparentBoxesIfNeeded(
  editorAPI: EditorAPI,
  compRef: CompRef,
) {
  const sectionChildren =
    editorAPI.components.getChildren_DEPRECATED_BAD_PERFORMANCE(compRef, true);
  const transparentStrip = sectionChildren.filter((childCompRef) =>
    filterTransparentBox(editorAPI, childCompRef),
  );
  if (transparentStrip.length) {
    editorAPI.components.remove(transparentStrip);
  }
}

const filterTransparentBox = (editorAPI: EditorAPI, compRef: CompRef) =>
  editorAPI.components?.code?.getNickname(compRef).includes(TRANSPARENT_BOX);

export function convertBusinessTypeToBiString(
  industry_id: string,
  structure_id: string,
): string {
  return JSON.stringify({ industry_id, structure_id });
}

export const overridePageCompsStructureIfApplicable = (
  pageStructure: CompStructure,
) => {
  if (experiment.isOpen('se_defaultImageAutoFill')) {
    core.utils.addUtil.overrideChildrenComponentsDataRecursively(
      pageStructure.components,
    );
    core.utils.addUtil.overrideChildrenComponentsDataRecursively(
      pageStructure.mobileComponents,
    );
  }
};

export const isCreateSectionWithAiEnabled = () =>
  experiment.isOpen('se_createSectionWithAi');

export { getPagesExternalPresetOptions } from './addPage/addPagePanelUtil';

export const setupAiSectionCreatorFeedbackLoop = (
  editorAPI: EditorAPI,
  compRef: CompRef,
): void => {
  const TIMEOUT_DELAY = 30_000;
  enum FeedbackTriggerTypes {
    SectionDeleted = 'SectionDeleted',
    UserStayedOnPage = 'UserStackOnPage',
    None = 'None',
  }
  const triggerEventMap = {
    [FeedbackTriggerTypes.SectionDeleted]: 'ai-section-deleted',
    [FeedbackTriggerTypes.UserStayedOnPage]: 'user-stayed-on-page',
  };

  let componentDeletedUnsubscriber: any;
  let pageNavigateUnsubscriber: () => void;
  let timeoutRef: any;

  const userStayedOnPagePromise = new Promise((resolve) => {
    timeoutRef = setTimeout(
      () => resolve(FeedbackTriggerTypes.UserStayedOnPage),
      TIMEOUT_DELAY,
    );
  });

  const sectionDeletedPromise = new Promise((resolve) => {
    componentDeletedUnsubscriber =
      editorAPI.components.hooks.componentDeleted.tap(
        ({ compType, compRefs }: { compType: string; compRefs: CompRef[] }) => {
          if (
            compType === constants.COMP_TYPES.SECTION &&
            compRefs.some(
              (deletedComp: CompRef) => deletedComp.id === compRef.id,
            )
          ) {
            resolve(FeedbackTriggerTypes.SectionDeleted);
          }
        },
      );
  });

  const beforePageNavigatePromise = new Promise((resolve) => {
    pageNavigateUnsubscriber = editorAPI.pages.hooks.beforePageNavigate.tap(
      async (_payload: any) => {
        resolve(FeedbackTriggerTypes.None);
      },
    );
  });

  Promise.race([
    userStayedOnPagePromise,
    sectionDeletedPromise,
    beforePageNavigatePromise,
  ]).then((eventTrigger) => {
    if (
      eventTrigger === FeedbackTriggerTypes.SectionDeleted ||
      eventTrigger === FeedbackTriggerTypes.UserStayedOnPage
    ) {
      editorAPI.mediaServices.userFeedback.showQuestion(
        getAiSectionCreatorFeedbackModel(triggerEventMap[eventTrigger]),
      );
      editorAPI.store.dispatch(
        stateManagement.userPreferences.actions.setGlobalUserPreferences(
          AI_SECTION_FEEDBACK_ALREADY_SHOWN,
          true,
        ),
      );
    }

    clearTimeout(timeoutRef);
    componentDeletedUnsubscriber();
    pageNavigateUnsubscriber();
  });
};
