import _ from 'lodash';
import type { EditorAPI } from '@/editorAPI';
import constants from '@/constants';
const { ACTIONS } = constants.ROOT_COMPS.GFPP;
import type { GFPPDataRaw } from '@wix/editor-component-types';
import type * as DS from '@wix/document-services-types';
import { DataBindingApiKey } from '@/apis';
import experiment from 'experiment';
import * as util from '@/util';

export default function (
  editorAPI: EditorAPI,
  compRef: DS.CompRef | DS.CompRef[],
  compGfppData: GFPPDataRaw,
) {
  const enabledByExperiment = experiment.isOpen(
    'se_enableDatabindingForAppStudio',
  );

  if (!enabledByExperiment) {
    return;
  }

  const dataBindingApi = editorAPI.host.getAPI(DataBindingApiKey);
  const componentsIneligibleForConnect = util.array
    .asArray(compRef)
    .filter((compRef) => !dataBindingApi.enabled(compRef));

  if (componentsIneligibleForConnect.length == 0) {
    return;
  }

  for (const compRef of componentsIneligibleForConnect) {
    const componentType = editorAPI.components.getType(compRef);

    if (componentType === 'platform.components.AppController') {
      // @ts-expect-error TS fails to correctly resolve compGfppData.mainActions
      compGfppData.mainActions = _.reject(compGfppData.mainActions, {
        id: ACTIONS.SETTINGS,
      });

      break;
    }
  }

  if (
    typeof compGfppData.presetActions != 'function' &&
    compGfppData.presetActions?.[ACTIONS.CONNECT]
  ) {
    delete compGfppData.presetActions[ACTIONS.CONNECT];
  }

  compGfppData.enabledActions = _.reject(
    compGfppData.enabledActions,
    ACTIONS.CONNECT,
  );
}
